import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';

const FundingRoundsTable = ({ values, setFieldValue, currency, targetRoundData, foundingYear }) => {
    const { t } = useTranslation();
    const [editingField, setEditingField] = useState(null);

    // Helper function to sort funding rounds chronologically
    const sortRoundsChronologically = (rounds) => {
        return [...rounds].sort((a, b) => {
            // Compare years first
            if (a.time.year !== b.time.year) {
                return a.time.year - b.time.year;
            }
            // If years are equal, compare months
            return a.time.month - b.time.month;
        });
    };

    useEffect(() => {
        // Initialize fixed funding rounds if they don't exist
        const initialCapitalExists = values.fundingRounds.some(round => round.isInitialCapital);
        const targetRoundExists = values.fundingRounds.some(round => round.isTargetRound);

        let updatedRounds = [...values.fundingRounds];

        if (!initialCapitalExists) {
            updatedRounds.unshift({
                id: 'initial-capital',
                time: { month: 1, year: foundingYear },
                type: 'Initial Capital',
                milestones: 'Founding',
                preMoneyValuation: 0,
                investmentAmount: 0,
                isInitialCapital: true
            });
        }

        if (!targetRoundExists && targetRoundData) {
            updatedRounds.push({
                id: 'target-round',
                time: { ...targetRoundData.targetTime },
                type: targetRoundData.targetRound,
                milestones: '',
                preMoneyValuation: 0,
                investmentAmount: targetRoundData.targetAmount,
                isTargetRound: true
            });
        }

        if (!initialCapitalExists || !targetRoundExists) {
            // Sort rounds chronologically before setting
            const sortedRounds = sortRoundsChronologically(updatedRounds);
            setFieldValue('fundingRounds', sortedRounds);
        }
    }, [targetRoundData, foundingYear]);

    useEffect(() => {
        if (!values.fundingRounds?.length || !targetRoundData?.targetAmount) return;

        const targetRound = values.fundingRounds.find(round => round.isTargetRound);

        if (targetRound && (
            targetRound.investmentAmount !== targetRoundData.targetAmount ||
            targetRound.type !== targetRoundData.targetRound
        )) {
            const updatedRounds = values.fundingRounds.map(round => {
                if (round.isTargetRound) {
                    return {
                        ...round,
                        investmentAmount: targetRoundData.targetAmount,
                        type: targetRoundData.targetRound
                    };
                }
                return round;
            });
            // Sort rounds chronologically before setting
            const sortedRounds = sortRoundsChronologically(updatedRounds);
            setFieldValue('fundingRounds', sortedRounds);
        }
    }, [values.fundingRounds, targetRoundData?.targetAmount, targetRoundData?.targetRound, setFieldValue]);

    const deleteFundingRound = (roundId) => {
        // Only delete custom rounds, not initial or target round
        const updatedRounds = values.fundingRounds.filter(round =>
            round.id !== roundId && !round.isInitialCapital && !round.isTargetRound
        );
        const sortedRounds = sortRoundsChronologically(updatedRounds);
        setFieldValue('fundingRounds', sortedRounds);
    };

    const formatCurrency = (value) => {
        if (!value && value !== 0) return '';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency || 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    const handleFocus = (e) => {
        setEditingField(e.target.name);
    };

    const handleBlur = () => {
        setEditingField(null);
    };

    const getInputValue = (field, index, value) => {
        if (editingField === `${field}-${index}`) {
            // When editing, show plain number
            return value === 0 ? '' : value.toString();
        }
        // When not editing, show formatted number without currency symbol
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };

    const addFundingRound = () => {
        let updatedFundingRounds = [...values.fundingRounds];
        const lastRound = updatedFundingRounds[updatedFundingRounds.length - 1];

        let newMonth = lastRound.time.month + 1;
        let newYear = lastRound.time.year;
        if (newMonth > 12) {
            newMonth = 1;
            newYear += 1;
        }

        const lastPostMoneyValuation = lastRound.preMoneyValuation + lastRound.investmentAmount;

        updatedFundingRounds.push({
            id: Date.now(),
            time: {
                month: newMonth,
                year: newYear
            },
            type: 'Pre-Seed',
            milestones: '',
            preMoneyValuation: lastPostMoneyValuation,
            investmentAmount: 0,
            isCustomRound: true
        });

        // Sort rounds chronologically before setting
        const sortedRounds = sortRoundsChronologically(updatedFundingRounds);
        setFieldValue('fundingRounds', sortedRounds);
    };

    const fundingTypes = ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Series C', 'Series C+', 'Other'];

    // Helper function to determine if a field should be disabled
    const isFieldDisabled = (round, fieldType) => {
        if (round.isInitialCapital) {
            // For initial capital, disable everything except investment amount
            return fieldType !== 'investmentAmount';
        }
        if (round.isTargetRound) {
            // For target round, disable everything except milestones/preMoneyValuation
            return !['milestones', 'preMoneyValuation'].includes(fieldType);
        }
        return false;
    };

    // ---- NEW LOGIC: Calculate difference between total rounds and Cashflow needs ----
    const totalInvestmentNeeded = values?.cashflow?.reduce((sum, row) => sum + row.investments, 0) || 0;
    const totalRoundInvestments = values.fundingRounds.reduce((sum, round) => sum + round.investmentAmount, 0);
    const difference = totalRoundInvestments - totalInvestmentNeeded;

    let differenceText = '';
    let differenceColor = 'text-black';

    if (difference === 0) {
        differenceText = t('All necessary investments (based on Cashflow) allocated');
        differenceColor = 'text-black';
    } else if (difference < 0) {
        differenceText = `${formatCurrency(Math.abs(difference))} ${t('less funding than needed (based on Cashflow).')}`;
        differenceColor = 'text-red-600';
    } else {
        differenceText = `${formatCurrency(difference)} ${t('more funding than needed (based on Cashflow).')}`;
        differenceColor = 'text-blue-600';
    }
    // -------------------------------------------------------------------------------

    return (
        <div className="space-y-4">
            {/* ---------- TABLE ---------- */}
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Time')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Type')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Pre-Money-Valuation')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Investment Amount')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Post-Money-Valuation')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {sortRoundsChronologically(values.fundingRounds).map((round, index) => (
                            <tr key={round.id} className={round.isTargetRound ? 'bg-blue-50' : ''}>
                                {/* TIME */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex gap-2">
                                        <select
                                            className="border rounded px-2 py-1 text-sm"
                                            value={round.time.month}
                                            onChange={(e) => setFieldValue(`fundingRounds.${index}.time.month`, Number(e.target.value))}
                                            disabled={isFieldDisabled(round, 'time')}
                                        >
                                            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                                                <option key={month} value={month}>{month}</option>
                                            ))}
                                        </select>
                                        <select
                                            className="border rounded px-2 py-1 text-sm"
                                            value={round.time.year}
                                            onChange={(e) => setFieldValue(`fundingRounds.${index}.time.year`, Number(e.target.value))}
                                            disabled={isFieldDisabled(round, 'time')}
                                        >
                                            {Array.from({ length: 31 }, (_, i) => 2020 + i).map(year => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>

                                {/* TYPE */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <select
                                        className="border rounded px-2 py-1 text-sm"
                                        value={round.type}
                                        onChange={(e) => setFieldValue(`fundingRounds.${index}.type`, e.target.value)}
                                        disabled={isFieldDisabled(round, 'type')}
                                    >
                                        {round.isInitialCapital ? (
                                            <option value="Initial Capital">Initial Capital</option>
                                        ) : round.isTargetRound ? (
                                            <option value={round.type}>{round.type}</option>
                                        ) : (
                                            fundingTypes.map(type => (
                                                <option key={type} value={type}>{type}</option>
                                            ))
                                        )}
                                    </select>
                                    {round.isTargetRound && (
                                        <span className="ml-2 text-xs text-blue-600">{t('Target Round')}</span>
                                    )}
                                </td>

                                {/* PRE-MONEY VALUATION */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                                            {(() => {
                                                switch (currency) {
                                                    case 'USD': return '$';
                                                    case 'EUR': return '€';
                                                    case 'JPY': return '¥';
                                                    case 'GBP': return '£';
                                                    case 'AUD': return 'A$';
                                                    case 'BRL': return 'R$';
                                                    case 'CAD': return 'C$';
                                                    case 'CHF': return 'Fr';
                                                    case 'CNY': return '¥';
                                                    case 'HKD': return 'HK$';
                                                    case 'INR': return '₹';
                                                    case 'KRW': return '₩';
                                                    case 'MXN': return 'Mex$';
                                                    case 'NOK': return 'kr';
                                                    case 'NZD': return 'NZ$';
                                                    case 'RUB': return '₽';
                                                    case 'SEK': return 'kr';
                                                    case 'SGD': return 'S$';
                                                    case 'TRY': return '₺';
                                                    case 'ZAR': return 'R';
                                                    default: return '€';
                                                }
                                            })()}
                                        </span>
                                        {editingField === `preMoneyValuation-${index}` ? (
                                            <input
                                                type="number"
                                                name={`preMoneyValuation-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={round.preMoneyValuation || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.-]/g, '');
                                                    setFieldValue(`fundingRounds.${index}.preMoneyValuation`, Number(value));
                                                }}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                disabled={isFieldDisabled(round, 'preMoneyValuation')}
                                                step="1000"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={`preMoneyValuation-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm"
                                                value={new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(round.preMoneyValuation)}
                                                onFocus={handleFocus}
                                                readOnly
                                            />
                                        )}
                                    </div>
                                </td>

                                {/* INVESTMENT AMOUNT */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                                            {(() => {
                                                switch (currency) {
                                                    case 'USD': return '$';
                                                    case 'EUR': return '€';
                                                    case 'JPY': return '¥';
                                                    case 'GBP': return '£';
                                                    case 'AUD': return 'A$';
                                                    case 'BRL': return 'R$';
                                                    case 'CAD': return 'C$';
                                                    case 'CHF': return 'Fr';
                                                    case 'CNY': return '¥';
                                                    case 'HKD': return 'HK$';
                                                    case 'INR': return '₹';
                                                    case 'KRW': return '₩';
                                                    case 'MXN': return 'Mex$';
                                                    case 'NOK': return 'kr';
                                                    case 'NZD': return 'NZ$';
                                                    case 'RUB': return '₽';
                                                    case 'SEK': return 'kr';
                                                    case 'SGD': return 'S$';
                                                    case 'TRY': return '₺';
                                                    case 'ZAR': return 'R';
                                                    default: return '€';
                                                }
                                            })()}
                                        </span>
                                        {editingField === `investmentAmount-${index}` ? (
                                            <input
                                                type="number"
                                                name={`investmentAmount-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={round.investmentAmount || ''}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.-]/g, '');
                                                    setFieldValue(`fundingRounds.${index}.investmentAmount`, Number(value));
                                                }}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                disabled={isFieldDisabled(round, 'investmentAmount')}
                                                step="1000"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={`investmentAmount-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm"
                                                value={new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(round.investmentAmount)}
                                                onFocus={handleFocus}
                                                readOnly
                                            />
                                        )}
                                    </div>
                                </td>

                                {/* POST-MONEY VALUATION */}
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    {formatCurrency(round.preMoneyValuation + round.investmentAmount)}
                                </td>

                                {/* DELETE BUTTON */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {!round.isInitialCapital && !round.isTargetRound && (
                                        <button
                                            type="button"
                                            onClick={() => deleteFundingRound(round.id)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex items-center justify-between mt-4">
                <button
                    type="button"
                    onClick={addFundingRound}
                    className="px-4 py-2 bg-secondary-color text-white rounded hover:bg-secondary-color/80 focus:outline-none focus:ring-2 focus:ring-secondary inline-flex items-center gap-2"
                >
                    <PlusIcon className="h-5 w-5" />
                    {t('Add Funding Round')}
                </button>
                <div className={`font-semibold ${differenceColor}`}>
                    {differenceText}
                </div>
            </div>
        </div>
    );
};

FundingRoundsTable.propTypes = {
    values: PropTypes.shape({
        fundingRounds: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                time: PropTypes.shape({
                    month: PropTypes.number.isRequired,
                    year: PropTypes.number.isRequired,
                }).isRequired,
                type: PropTypes.string.isRequired,
                milestones: PropTypes.string.isRequired,
                preMoneyValuation: PropTypes.number.isRequired,
                investmentAmount: PropTypes.number.isRequired,
                isInitialCapital: PropTypes.bool,
                isTargetRound: PropTypes.bool,
                isCustomRound: PropTypes.bool,
            })
        ).isRequired,
        // We also use values.cashflow to calculate total needs
        cashflow: PropTypes.arrayOf(
            PropTypes.shape({
                year: PropTypes.number,
                investments: PropTypes.number,
            })
        )
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    foundingYear: PropTypes.number.isRequired,
    targetRoundData: PropTypes.shape({
        targetRound: PropTypes.string,
        targetAmount: PropTypes.number,
        targetTime: PropTypes.shape({
            month: PropTypes.number,
            year: PropTypes.number
        })
    })
};

export default FundingRoundsTable;