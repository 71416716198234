import React, { useState } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from 'react-icons/io5';
import { UserIcon, BuildingOfficeIcon } from '@heroicons/react/20/solid';
import CountrySelector from '../../components/CountrySelector';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FounderCard = ({
  founder = {
    fullName: '',
    dateOfBirth: '',
    nationality: null,
    address: { street: '', streetAdditional: '', zip: '', place: '', country: '' },
    email: '',
    phone: '',
    isManagingDirector: false,
    sharePercentage: 0,
    capitalInvested: 0,
  },
  index,
  onRemove,
  setFieldValue,
  values,
  totalShareCapital,
  errors,
  hasAttemptedSubmit
}) => {
  const { t } = useTranslation();
  const [isEditingCapital, setIsEditingCapital] = useState(false);

  const formatNumber = (number, decimalPlaces = 2) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(number);
  };

  const handleCapitalInvestedChange = (event) => {
    let value = event.target.value.replace(/[^\d.]/g, '');
    let newCapitalInvested = parseFloat(value);
    if (isNaN(newCapitalInvested) || newCapitalInvested < 0) {
      newCapitalInvested = 0;
    }
    if (newCapitalInvested > totalShareCapital) {
      newCapitalInvested = totalShareCapital;
    }
    const newSharePercentage = (newCapitalInvested / totalShareCapital) * 100;
    setFieldValue(`founders[${index}].capitalInvested`, newCapitalInvested);
    setFieldValue(`founders[${index}].sharePercentage`, newSharePercentage);
  };

  const handleSharePercentageChange = (event) => {
    const newSharePercentage = parseFloat(event.target.value) || 0;
    const newCapitalInvested = (newSharePercentage / 100) * totalShareCapital;
    setFieldValue(`founders[${index}].sharePercentage`, newSharePercentage);
    setFieldValue(`founders[${index}].capitalInvested`, newCapitalInvested);
  };

  const handleFieldChange = (fieldName, value) => {
    setFieldValue(`founders[${index}].${fieldName}`, value);
  };

  const handleAddressChange = (fieldName, value) => {
    setFieldValue(`founders[${index}].address.${fieldName}`, value);
  };

  const handleFounderTypeChange = (newType) => {
    if (newType === 'natural') {
      // Clear juridical fields
      setFieldValue(`founders[${index}].legalName`, '');
      setFieldValue(`founders[${index}].seat`, '');
      setFieldValue(`founders[${index}].registry`, '');
      setFieldValue(`founders[${index}].registryNumber`, '');
      setFieldValue(`founders[${index}].hasBeneficialOwners`, 'no');
      setFieldValue(`founders[${index}].beneficialOwners`, []);
      setFieldValue(`founders[${index}].contactPerson`, {
        name: '',
        role: '',
        email: '',
        phone: ''
      });
    } else {
      // Clear natural person fields
      setFieldValue(`founders[${index}].fullName`, '');
      setFieldValue(`founders[${index}].dateOfBirth`, '');
      setFieldValue(`founders[${index}].nationality`, null);
    }
    setFieldValue(`founders[${index}].type`, newType);
  };

  return (
    <div className="border rounded-lg p-4 mb-4 bg-white">
      {/* Founder Type Selection */}
      <div className="flex w-full">
        <button
          type="button"
          className={`flex-1 flex items-center justify-center gap-2 px-4 py-2 rounded-l-md ${founder.type === 'natural' ? 'bg-secondary-color text-white' : 'bg-gray-300'
            }`}
          onClick={() => handleFounderTypeChange('natural')}
        >
          <UserIcon className="w-5 h-5" />
          {t('Natural Person')}
        </button>

        <button
          type="button"
          className={`flex-1 flex items-center justify-center gap-2 px-4 py-2 rounded-r-md ${founder.type === 'juridical' ? 'bg-secondary-color text-white' : 'bg-gray-300'
            }`}
          onClick={() => handleFounderTypeChange('juridical')}
        >
          <BuildingOfficeIcon className="w-5 h-5" />
          {t('Juridical Person')}
        </button>
      </div>

      {/* Founder Name Display */}
      <div className="flex justify-between items-center mt-2">
        <h3 className="text-lg font-semibold">
          {founder.type === 'natural'
            ? founder?.fullName || `${t('Founder')} ${index + 1}`
            : founder?.legalName || `${t('Company')} ${index + 1}`}
        </h3>
        <button
          type="button"
          className="text-red-500 hover:text-red-700 transition-colors duration-200"
          onClick={onRemove}
          aria-label={t('Remove')}
        >
          <IoTrashOutline className="w-5 h-5" />
        </button>
      </div>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">

        {/* Dynamic Fields Based on Type */}
        {founder.type === 'natural' ? (
          <>
            {/* Full Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Full Name')}</label>
              <Field
                name={`founders[${index}].fullName`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                onChange={(e) => handleFieldChange('fullName', e.target.value)}
              />
              {hasAttemptedSubmit && errors?.founders?.[index]?.fullName && (
                <div className="text-red-500 mt-1">{errors.founders[index].fullName}</div>
              )}
            </div>

            {/* Date of Birth */}
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Date of Birth')}</label>
              <Field
                name={`founders[${index}].dateOfBirth`}
                type="date"
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                onChange={(e) => handleFieldChange('dateOfBirth', e.target.value)}
              />
              {hasAttemptedSubmit && errors?.founders?.[index]?.dateOfBirth && (
                <div className="text-red-500 mt-1">{errors.founders[index].dateOfBirth}</div>
              )}
            </div>

            {/* Nationality */}
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Nationality')}</label>
              <CountrySelector
                value={founder?.nationality || null}
                onChange={(selectedCountry) => {
                  handleFieldChange('nationality', selectedCountry);
                }}
              />
              {hasAttemptedSubmit && errors?.founders?.[index]?.nationality && (
                <div className="text-red-500 mt-1">{errors.founders[index].nationality}</div>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Juridical Person Fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Legal Name of Corporation')}</label>
              <Field
                name={`founders[${index}].legalName`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Seat of the Corporation')}</label>
              <Field
                name={`founders[${index}].seat`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Registry')}</label>
              <Field
                name={`founders[${index}].registry`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('Registry Number')}</label>
              <Field
                name={`founders[${index}].registryNumber`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              />
            </div>

            {/* Beneficial Owners Selection */}
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                {t('Any Beneficial Owners? (more than 25% voting rights or shares?)')}
              </label>
              <Field
                as="select"
                name={`founders[${index}].beneficialOwnersCount`}
                className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                onChange={(e) => {
                  const count = parseInt(e.target.value, 10);

                  setFieldValue(`founders[${index}].hasBeneficialOwners`, count > 0 ? 'yes' : 'no');
                  setFieldValue(`founders[${index}].beneficialOwnersCount`, count);

                  const newBeneficialOwners = count > 0
                    ? Array(count).fill({
                      fullName: '',
                      nationality: '',
                      dateOfBirth: '',
                      residencePlace: '',
                      residenceCountry: ''
                    })
                    : [];

                  setFieldValue(`founders[${index}].beneficialOwners`, newBeneficialOwners);
                }}
              >
                <option value="0">{t('No')}</option>
                <option value="1">{t('Yes: 1')}</option>
                <option value="2">{t('Yes: 2')}</option>
                <option value="3">{t('Yes: 3')}</option>
                <option value="4">{t('Yes: 4')}</option>
              </Field>
            </div>

            {/* Render Beneficial Owners Dynamically */}
            {Array.from({ length: founder?.beneficialOwnersCount || 0 }).map((_, boIndex) => (
              <div key={boIndex} className="mt-4 p-4 border rounded-lg bg-gray-50">
                <h4 className="text-md font-medium">{t(`Beneficial Owner ${boIndex + 1}`)}</h4>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('Full Name')}</label>
                  <Field
                    name={`founders[${index}].beneficialOwners[${boIndex}].fullName`}
                    className="mt-1 block w-full border-gray-300 roundhandleAddressChanged-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                  />
                </div>

                {/* Nationality using the same Listbox UI */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('Nationality')}</label>
                  <CountrySelector
                    value={( founder?.beneficialOwners?.[boIndex]?.nationality) || null}
                    onChange={(selectedCountry) =>
                      setFieldValue(`founders[${index}].beneficialOwners[${boIndex}].nationality`, selectedCountry)
                    }
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('Date of Birth')}</label>
                  <Field
                    name={`founders[${index}].beneficialOwners[${boIndex}].dateOfBirth`}
                    type="date"
                    className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('Residence (Place)')}</label>
                  <Field
                    name={`founders[${index}].beneficialOwners[${boIndex}].residencePlace`}
                    className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                  />
                </div>

                {/* Residence Country using the same Listbox UI */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('Residence (Country)')}</label>
                  <CountrySelector
                    value={(founder?.beneficialOwners?.[boIndex]?.residenceCountry) || null}
                    onChange={(selectedCountry) =>
                      setFieldValue(`founders[${index}].beneficialOwners[${boIndex}].residenceCountry`, selectedCountry)
                    }
                  />
                </div>
              </div>
            ))}

            {/* Contact Person */}
            <div className="col-span-2 mt-4">
              <h4 className="text-md font-medium">{t('Contact Person')}</h4>

              {/* Name */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">{t('Name')}</label>
                <Field
                  name={`founders[${index}].contactPerson.name`}
                  className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                />
              </div>

              {/* Role in Corporation */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">{t('Role')}</label>
                <Field
                  name={`founders[${index}].contactPerson.role`}
                  className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {/* Email */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Email')}</label>
        <Field
          name={`founders[${index}].email`}
          type="email"
          className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
          onChange={(e) => handleFieldChange('email', e.target.value)}
        />
        {hasAttemptedSubmit && errors?.founders?.[index]?.email && (
          <div className="text-red-500 mt-1">{errors.founders[index].email}</div>
        )}
      </div>
      {/* Phone */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Phone')}</label>
        <Field
          name={`founders[${index}].phone`}
          type="tel"
          className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
          onChange={(e) => handleFieldChange('phone', e.target.value)}
        />
        {hasAttemptedSubmit && errors?.founders?.[index]?.phone && (
          <div className="text-red-500 mt-1">{errors.founders[index].phone}</div>
        )}
      </div>
      {/* Address */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Address')}</label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          {/* Street */}
          <div className="md:col-span-1">
            <Field
              name={`founders[${index}].address.street`}
              placeholder={t('Street')}
              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              onChange={(e) => handleAddressChange('street', e.target.value)}
            />
            {hasAttemptedSubmit && errors?.founders?.[index]?.address?.street && (
              <div className="text-red-500 mt-1">{errors.founders[index].address.street}</div>
            )}
          </div>

          {/* Additional */}
          <div className="md:col-span-1">
            <Field
              name={`founders[${index}].address.streetAdditional`}
              placeholder={t('Additional')}
              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              onChange={(e) => handleAddressChange('streetAdditional', e.target.value)}
            />
          </div>

          {/* ZIP */}
          <div className="md:col-span-1 mt-4">
            <Field
              name={`founders[${index}].address.zip`}
              placeholder={t('ZIP')}
              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              onChange={(e) => handleAddressChange('zip', e.target.value)}
            />
            {hasAttemptedSubmit && errors?.founders?.[index]?.address?.zip && (
              <div className="text-red-500 mt-1">{errors.founders[index].address.zip}</div>
            )}
          </div>

          {/* Place */}
          <div className="md:col-span-1 mt-4">
            <Field
              name={`founders[${index}].address.place`}
              placeholder={t('Place')}
              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
              onChange={(e) => handleAddressChange('place', e.target.value)}
            />
            {hasAttemptedSubmit && errors?.founders?.[index]?.address?.place && (
              <div className="text-red-500 mt-1">{errors.founders[index].address.place}</div>
            )}
          </div>

          {/* Country */}
          <div className="md:col-span-1 mt-4">
            <CountrySelector
              value={ (founder?.address?.country) || null}
              onChange={(selectedCountry) => {
                handleAddressChange('country', selectedCountry);
              }}
            />
            {hasAttemptedSubmit && errors?.founders?.[index]?.address?.country && (
              <div className="text-red-500 mt-1">{errors.founders[index].address.country}</div>
            )}
          </div>
        </div>
      </div>
      {/* Managing Director Checkbox */}
      <div className="mt-6">
        <label className="flex items-center">
          <Field
            type="checkbox"
            name={`founders[${index}].isManagingDirector`}
            className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded mr-3"
            onChange={(e) => handleFieldChange('isManagingDirector', e.target.checked)}
          />
          <span className="text-normal font-medium text-gray-700">{t('Will be managing director?')}</span>
        </label>
      </div>
      {/* Share Percentage */}
      <div className="mt-6">
        <label className="block text-normal font-medium text-gray-700">{t('Share Percentage')}</label>
        <div className="flex items-center">
          <input
            type="range"
            min="0"
            max="100"
            step="0.1"
            value={founder?.sharePercentage || 0}
            onChange={handleSharePercentageChange}
            className="w-full mr-4 h-2 rounded-lg appearance-none cursor-pointer relative custom-slider
    [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#2A9D8F] [&::-webkit-slider-thumb]:border-0 [&::-webkit-slider-thumb]:translate-y-[-25%] [&::-webkit-slider-thumb]:relative
    [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-[#2A9D8F] [&::-moz-range-thumb]:border-0 [&::-moz-range-thumb]:translate-y-[-25%]
    [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-gray-200
    [&::-moz-range-track]:h-2 [&::-moz-range-track]:rounded-full [&::-moz-range-track]:bg-gray-200
    [&::-webkit-slider-runnable-track]:bg-gradient-to-r [&::-webkit-slider-runnable-track]:from-[#2A9D8F] [&::-webkit-slider-runnable-track]:to-[#2A9D8F] [&::-webkit-slider-runnable-track]:bg-[length:var(--range-progress)] [&::-webkit-slider-runnable-track]:bg-no-repeat
    [&::-moz-range-track]:bg-gradient-to-r [&::-moz-range-track]:from-[#2A9D8F] [&::-moz-range-track]:to-[#2A9D8F] [&::-moz-range-track]:bg-[length:var(--range-progress)] [&::-moz-range-track]:bg-no-repeat
    focus:outline-none focus-visible:ring focus-visible:ring-[#2A9D8F] focus-visible:ring-opacity-50"
            style={{
              '--range-progress': `${founder?.sharePercentage || 0}%`,
              WebkitAppearance: 'none',
              MozAppearance: 'none'
            }}
          />
          <span>{formatNumber(founder?.sharePercentage || 0)}%</span>
        </div>
      </div>
      {/* Capital Invested */}
      <div className="mt-6">
        <label className="block text-normal font-medium text-gray-700">{t('Capital Invested')}</label>
        {isEditingCapital ? (
          <input
            type="number"
            value={founder?.capitalInvested || 0}
            onChange={handleCapitalInvestedChange}
            onBlur={() => setIsEditingCapital(false)}
            className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
            autoFocus
          />
        ) : (
          <div
            onClick={() => setIsEditingCapital(true)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 cursor-text"
          >
            {formatNumber(founder?.capitalInvested || 0)} €
          </div>
        )}
      </div>
    </div >
  );
};

export default FounderCard;
