// /components/EnhancedTableControls.js
import React, { useState } from 'react';
import { EditorState, AtomicBlockUtils } from 'draft-js';
import { useTranslation } from 'react-i18next';

const EnhancedTableControls = (props) => {
    const [showTableDialog, setShowTableDialog] = useState(false);
    const [rows, setRows] = useState(3);
    const [cols, setCols] = useState(3);

    // This function gets the current editorState and onChange function 
    // from props injected by react-draft-wysiwyg
    const insertTable = () => {
        const { editorState, onChange } = props;

        if (!editorState || !onChange) {
            console.warn('Table insertion failed: Missing editor state or onChange function');
            return;
        }

        try {
            // Create table data with better default content
            const tableData = [];

            // Create a header row
            const headerRow = [];
            for (let j = 0; j < cols; j++) {
                headerRow.push(`Header ${j + 1}`);
            }
            tableData.push(headerRow);

            // Create data rows
            for (let i = 1; i < rows; i++) {
                const rowData = [];
                for (let j = 0; j < cols; j++) {
                    rowData.push(`Cell ${i}.${j + 1}`);
                }
                tableData.push(rowData);
            }

            // Create entity
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                'TABLE',
                'IMMUTABLE',
                { tableData }
            );

            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

            // Insert atomic block
            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                EditorState.set(editorState, { currentContent: contentStateWithEntity }),
                entityKey,
                ' '
            );

            onChange(newEditorState);
            setShowTableDialog(false);
        } catch (error) {
            console.error('Error inserting table:', error);
            setShowTableDialog(false);
        }
    };

    // A cleaner UI that works better with react-draft-wysiwyg
    return (
        <div>
            <div
                className="rdw-option-wrapper"
                onClick={() => setShowTableDialog(!showTableDialog)}
                title="Insert Table"
            >
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <rect x="3" y="3" width="18" height="18" rx="2" />
                    <line x1="3" y1="9" x2="21" y2="9" />
                    <line x1="3" y1="15" x2="21" y2="15" />
                    <line x1="9" y1="3" x2="9" y2="21" />
                    <line x1="15" y1="3" x2="15" y2="21" />
                </svg>
            </div>

            {showTableDialog && (
                <div className="p-3 border border-gray-300 rounded mt-2 bg-white shadow-sm absolute z-50" style={{ minWidth: '200px' }}>
                    <div className="flex items-center mb-2">
                        <label className="mr-2 text-sm">Rows:</label>
                        <input
                            type="number"
                            min="1"
                            max="10"
                            value={rows}
                            onChange={(e) => setRows(parseInt(e.target.value) || 2)}
                            className="border border-gray-300 rounded px-2 py-1 w-16 text-sm"
                        />
                    </div>
                    <div className="flex items-center mb-3">
                        <label className="mr-2 text-sm">Columns:</label>
                        <input
                            type="number"
                            min="1"
                            max="10"
                            value={cols}
                            onChange={(e) => setCols(parseInt(e.target.value) || 2)}
                            className="border border-gray-300 rounded px-2 py-1 w-16 text-sm"
                        />
                    </div>
                    <div className="flex space-x-2">
                        <button
                            type="button"
                            onClick={insertTable}
                            className="px-3 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
                        >
                            Insert
                        </button>
                        <button
                            type="button"
                            onClick={() => setShowTableDialog(false)}
                            className="px-3 py-1 bg-gray-200 rounded text-sm hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default EnhancedTableControls;  