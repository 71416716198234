import React, { useState } from 'react';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

const NewTaskList = ({ onAdd }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [newTaskName, setNewTaskName] = useState('');
    const { t } = useTranslation();
  
    const handleAddTask = async () => {
      if (newTaskName.trim() === '') return;
  
      try {
        await axios.post('/api/v1/tasks', {
          name: newTaskName,
          status: 'backlog',
          priority: 'normal',
          area: 'other'
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        setNewTaskName('');
        setIsAdding(false);
        onAdd();
      } catch (error) {
        console.error('Error adding new task:', error);
      }
    };
  
    if (!isAdding) {
      return (
        <div className="mt-4 flex justify-center">
          <button
            className="inline-flex mb-10 items-center px-4 py-2 rounded-md bg-gray-200 outline-dashed hover:outline outline-gray-400 hover:outline-white text-gray-400 rounded hover:bg-white hover:text-black transition-colors"
            onClick={() => setIsAdding(true)}
          >
            {t('newTask')}
          </button>
        </div>
      );
    }
  
    return (
      <div className="mt-4 bg-white rounded-lg shadow">
        <div className="p-4 flex items-center space-x-4">
          <div className="flex-grow">
            <input
              type="text"
              value={newTaskName}
              onChange={(e) => setNewTaskName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddTask();
                } else if (e.key === 'Escape') {
                  setIsAdding(false);
                }
              }}
              placeholder={t('enterTaskName')}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color text-sm"
              autoFocus
            />
          </div>
          <button
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 text-sm transition-colors"
            onClick={() => setIsAdding(false)}
          >
            {t('cancel')}
          </button>
          <button
            className="px-4 py-2 bg-primary-color text-white rounded-md hover:bg-secondary-color text-sm transition-colors"
            onClick={handleAddTask}
          >
            {t('addTask')}
          </button>
        </div>
      </div>
    );
  };

export default NewTaskList;