// TableComponent.js
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modifier } from 'draft-js';
import { useTranslation } from 'react-i18next';

// Helper function to strip HTML tags
const stripHtmlTags = (html) => {
    if (!html) return '';

    // Create a DOMParser to safely parse the HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Return just the text content
    return doc.body.textContent || '';
};

// SimpleTableEditor component
const SimpleTableEditor = ({ initialData, onSave, onCancel }) => {
    const { t } = useTranslation();

    // Process the initial data to strip HTML tags
    const processedInitialData = initialData.map(row =>
        row.map(cell => stripHtmlTags(cell))
    );

    const [tableData, setTableData] = useState(processedInitialData || []);

    const handleChange = (rowIndex, colIndex, value) => {
        setTableData(prev => {
            const newData = prev.map(row => [...row]);
            newData[rowIndex][colIndex] = value;
            return newData;
        });
    };

    const handleSave = () => {
        console.log('SimpleTableEditor saving data:', tableData);
        onSave(tableData);
    };

    return (
        <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">{t('TableComponent.editTable')}</h3>
            <table className="w-full border-collapse mb-6">
                <thead>
                    {tableData.length > 0 && (
                        <tr>
                            {tableData[0].map((cell, colIndex) => (
                                <th
                                    key={`header-${colIndex}`}
                                    className="border border-gray-300 p-3 bg-gray-100"
                                >
                                    <input
                                        type="text"
                                        value={cell || ''}
                                        onChange={(e) => handleChange(0, colIndex, e.target.value)}
                                        className="w-full p-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-color"
                                    />
                                </th>
                            ))}
                        </tr>
                    )}
                </thead>
                <tbody>
                    {tableData.slice(1).map((row, rowIndex) => (
                        <tr key={`row-${rowIndex}`} className={rowIndex % 2 === 0 ? '' : 'bg-gray-50'}>
                            {row.map((cell, colIndex) => (
                                <td key={`cell-${rowIndex}-${colIndex}`} className="border border-gray-300 p-3">
                                    <input
                                        type="text"
                                        value={cell || ''}
                                        onChange={(e) => handleChange(rowIndex + 1, colIndex, e.target.value)}
                                        className="w-full p-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-color"
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-end space-x-3 mt-6">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-4 py-2 text-sm rounded bg-white border border-gray-300 shadow-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
                >
                    {t('TableComponent.cancel')}
                </button>
                <button
                    type="button"
                    onClick={handleSave}
                    className="px-4 py-2 text-sm rounded text-white bg-secondary-color hover:opacity-80 transition-opacity duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-color shadow-sm"
                >
                    {t('TableComponent.updateTable')}
                </button>
            </div>
        </div>
    );
};

// Improved Modal with rounded corners
const Modal = ({ children, onClose }) => {
    return ReactDOM.createPortal(
        <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={(e) => {
                // Close on backdrop click
                if (e.target === e.currentTarget) onClose();
            }}
        >
            <div
                className="relative bg-white rounded-lg max-w-4xl max-h-[90vh] overflow-auto shadow-xl"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    type="button"
                    onClick={onClose}
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 bg-transparent text-2xl font-semibold"
                    aria-label="Close"
                >
                    &times;
                </button>
                {children}
            </div>
        </div>,
        document.body
    );
};

// Main TableComponent 
const TableComponent = (props) => {
    const { t } = useTranslation();
    const { block, contentState, blockProps } = props;
    const [showEditorModal, setShowEditorModal] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const originalTableData = useRef(null);

    useEffect(() => {
        if (showEditorModal && blockProps && blockProps.getEditorState) {
            const captured = blockProps.getEditorState();
            console.log('TableComponent: Captured EditorState on modal open:', captured);
            console.log('TableComponent: Current role:', blockProps.currentRole);

            // Save it to a global fallback
            window.capturedEditorState = captured;
            window.capturedEditorRole = blockProps.currentRole;
        }
    }, [showEditorModal, blockProps]);

    const entityKey = block.getEntityAt(0);
    if (!entityKey) {
        console.warn('TableComponent: No entity key in block');
        return <div>[{t('TableComponent.missingEntityKey')}]</div>;
    }

    let entity;
    try {
        entity = contentState.getEntity(entityKey);
    } catch (error) {
        console.error('TableComponent: Error fetching entity', error);
        return <div>[{t('TableComponent.tableError')}]</div>;
    }

    if (entity.getType() !== 'TABLE') {
        console.warn('TableComponent: Entity is not a table:', entity.getType());
        return <div>[{t('TableComponent.notTableEntity')}]</div>;
    }

    const entityData = entity.getData();
    const tableData = entityData.tableData || [];

    // Generic close modal function - just closes the UI
    const closeModal = () => {
        setShowEditorModal(false);

        // Reset readonly flag
        if (blockProps && blockProps.setEditorReadOnly) {
            blockProps.setEditorReadOnly(false);
        }
    };

    // Capture the editor state when opening the modal
    const openModal = () => {
        if (blockProps && blockProps.getEditorState) {
            const editorState = blockProps.getEditorState();
            // Store it in a global variable as a backup
            window.capturedEditorState = editorState;
            console.log('TableComponent: Captured EditorState:', editorState);
        }

        // Store the original table data for cancel functionality
        originalTableData.current = JSON.parse(JSON.stringify(tableData));

        setShowEditorModal(true);

        if (blockProps && blockProps.setEditorReadOnly) {
            blockProps.setEditorReadOnly(true);
        }
    };

    // Handle save with updated data
    const handleSave = (newTableData) => {
        console.log('TableComponent: saving updated table data:', newTableData);
        console.log('TableComponent: with role:', blockProps.currentRole || window.capturedEditorRole);

        if (blockProps && blockProps.onUpdateEntityData) {
            blockProps.onUpdateEntityData(entityKey, { tableData: newTableData });
        } else {
            console.warn('TableComponent: onUpdateEntityData not provided in blockProps');
        }
        closeModal();
    };

    // Handle cancel by forcing an entity update with the original data
    const handleCancel = () => {
        console.log('TableComponent: canceling edit, restoring original data');

        if (blockProps && blockProps.onUpdateEntityData && originalTableData.current) {
            // Force an update with the original data to ensure the editor is refreshed
            blockProps.onUpdateEntityData(entityKey, { tableData: originalTableData.current });
        }

        closeModal();
    };

    // Process table data to remove HTML tags for display
    const processedTableData = tableData.map(row =>
        row.map(cell => {
            // Remove common HTML tags for display
            let processed = cell || '';
            processed = processed.replace(/<\/?strong>/g, '');
            processed = processed.replace(/<\/?em>/g, '');
            processed = processed.replace(/<\/?b>/g, '');
            processed = processed.replace(/<\/?i>/g, '');
            return processed;
        })
    );

    const renderTable = () => {
        if (processedTableData && processedTableData.length > 0) {
            const hasHeader = processedTableData.length > 1;
            return (
                <div
                    className="relative cursor-pointer group"
                    onClick={openModal}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    {/* Use visible border that remains when not hovering + rounded corners */}
                    <div className="absolute inset-0 border border-gray-300 rounded-lg pointer-events-none" />
                    <table className="w-full border-collapse my-4 border border-gray-400 rounded-lg overflow-hidden">
                        {hasHeader && (
                            <thead>
                                <tr>
                                    {processedTableData[0].map((cell, i) => (
                                        <th
                                            key={`header-${i}`}
                                            className="border border-gray-300 p-2 bg-gray-100 text-left text-sm font-bold"
                                            dangerouslySetInnerHTML={{ __html: cell }}
                                        />
                                    ))}
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {processedTableData.slice(hasHeader ? 1 : 0).map((row, rowIndex) => (
                                <tr key={`row-${rowIndex}`} className={rowIndex % 2 === 0 ? '' : 'bg-gray-50'}>
                                    {row.map((cell, colIndex) => (
                                        <td
                                            key={`cell-${rowIndex}-${colIndex}`}
                                            className="border border-gray-300 p-2 text-left text-sm"
                                            dangerouslySetInnerHTML={{ __html: cell }}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Edit overlay that appears on hover */}
                    <div className={`absolute inset-0 flex items-center justify-center rounded-lg bg-gray-800 bg-opacity-40 transition-opacity ${isHovering ? 'opacity-100' : 'opacity-0'} pointer-events-none group-hover:opacity-100`}>
                        <button
                            type="button"
                            className="bg-white text-primary-color px-4 py-2 rounded shadow hover:bg-gray-100 font-medium text-sm pointer-events-auto"
                            onClick={openModal}
                        >
                            {t('TableComponent.editTable')}
                        </button>
                    </div>
                </div>
            );
        }
        return <div>[{t('TableComponent.noTableData')}]</div>;
    };

    return (
        <div>
            {renderTable()}
            {showEditorModal && (
                <Modal onClose={handleCancel}>
                    <SimpleTableEditor initialData={tableData} onSave={handleSave} onCancel={handleCancel} />
                </Modal>
            )}
        </div>
    );
};

export default TableComponent;