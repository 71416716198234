// src/components/wizard_components/DataPrivacyWizard/RiskMatrix.js
import React from 'react';
import { useTranslation } from 'react-i18next';

// Likelihood options
const likelihoodOptions = [
  { id: 'rare', value: 1, key: 'rare' },
  { id: 'unlikely', value: 2, key: 'unlikely' },
  { id: 'possible', value: 3, key: 'possible' },
  { id: 'likely', value: 4, key: 'likely' },
  { id: 'almost_certain', value: 5, key: 'almostCertain' }
];

// Severity options
const severityOptions = [
  { id: 'negligible', value: 1, key: 'negligible' },
  { id: 'minor', value: 2, key: 'minor' },
  { id: 'moderate', value: 3, key: 'moderate' },
  { id: 'major', value: 4, key: 'major' },
  { id: 'severe', value: 5, key: 'severe' }
];

// Potential harms (from RiskDetailsForm.js)
const potentialHarms = [
  { id: 'discrimination', key: 'discrimination' },
  { id: 'identity_theft', key: 'identityTheft' },
  { id: 'financial_loss', key: 'financialLoss' },
  { id: 'reputational_damage', key: 'reputationalDamage' },
  { id: 'confidentiality_loss', key: 'confidentialityLoss' },
  { id: 'social_disadvantage', key: 'socialDisadvantage' },
  { id: 'economic_disadvantage', key: 'economicDisadvantage' },
  { id: 'psychological_impact', key: 'psychologicalImpact' },
  { id: 'physical_harm', key: 'physicalHarm' }
];

// Risk scenarios data - for helping with built-in risk names
const riskScenarios = [
  { id: 'unauthorized_access', key: 'unauthorizedAccess' },
  { id: 'data_breach', key: 'dataBreach' },
  { id: 'excessive_collection', key: 'excessiveCollection' },
  { id: 'unauthorized_sharing', key: 'unauthorizedSharing' },
  { id: 'data_loss', key: 'dataLoss' },
  { id: 'inaccurate_processing', key: 'inaccurateProcessing' },
  { id: 'rights_hindrance', key: 'rightsHindrance' },
  { id: 'beyond_purpose', key: 'beyondPurpose' },
  { id: 'improper_legal_basis', key: 'improperLegalBasis' },
  { id: 'excessive_retention', key: 'excessiveRetention' }
];

const RiskMatrix = ({ 
  selectedRisks, 
  originalRisks, 
  showMovement = false 
}) => {
  const { t } = useTranslation();

  // Map each risk's original position by risk.id
  const originalRiskPositions = React.useMemo(() => {
    if (!originalRisks || !showMovement) return {};
    const positions = {};
    originalRisks.forEach(risk => {
      positions[risk.id] = {
        likelihood: risk.likelihood,
        severity: risk.severity
      };
    });
    return positions;
  }, [originalRisks, showMovement]);

  // Get display name for a risk
  const getRiskName = (riskId) => {
    const builtInRisk = riskScenarios.find(risk => risk.id === riskId);
    if (builtInRisk) {
      return t(`DataPrivacyWizard.dpia.riskAssessment.risks.${builtInRisk.key}`);
    }
    if (riskId && riskId.startsWith('custom_')) {
      const customRisk = selectedRisks.find(risk => risk.id === riskId);
      if (customRisk && customRisk.customName) {
        return customRisk.customName;
      }
    }
    return riskId;
  };

  // Determine if a risk has moved from its original position
  const hasRiskMoved = (riskId) => {
    if (!showMovement || !originalRiskPositions[riskId]) return false;
    const current = selectedRisks.find(risk => risk.id === riskId);
    if (!current) return false;
    const original = originalRiskPositions[riskId];
    return current.likelihood !== original.likelihood || current.severity !== original.severity;
  };

  // Get the original position for a risk
  const getOriginalPosition = (riskId) => {
    if (!showMovement || !originalRiskPositions[riskId]) return null;
    return originalRiskPositions[riskId];
  };

  // Track the risk that is currently hovered over
  const [hoveredRiskId, setHoveredRiskId] = React.useState(null);

  return (
    <div className="p-6">
      <div className="flex justify-center mb-2">
        <div className="text-sm text-gray-500 transform -rotate-90 flex items-center justify-center w-8">
          {t('DataPrivacyWizard.dpia.riskAssessment.likelihood')}
        </div>

        {/* Main risk matrix */}
        <div className="flex flex-col w-full max-w-2xl">
          {/* Severity labels */}
          <div className="flex items-center mb-1">
            <div className="w-6"></div>
            <div className="grid grid-cols-5 w-full gap-1">
              {severityOptions.map(option => (
                <div key={option.id} className="h-8 flex items-center justify-center text-xs font-medium">
                  {option.value}
                </div>
              ))}
            </div>
          </div>

          {/* Matrix rows */}
          {[...likelihoodOptions].reverse().map(likelihood => (
            <div key={likelihood.id} className="flex items-center mb-1">
              <div className="w-6 text-xs font-medium text-center mr-1">{likelihood.value}</div>
              <div className="grid grid-cols-5 w-full gap-1">
                {severityOptions.map(severity => {
                  const score = likelihood.value * severity.value;
                  let bgColor = 'bg-green-100';
                  if (score >= 15) bgColor = 'bg-red-100';
                  else if (score >= 8) bgColor = 'bg-yellow-100';

                  // Get all risks in this cell
                  const risksAtPosition = selectedRisks.filter(
                    risk => risk.likelihood === likelihood.value && risk.severity === severity.value
                  );

                  // Debug info (optional)
                  const debugInfo = risksAtPosition.length > 0 
                    ? `${risksAtPosition.map(r => r.id).join(',')}` 
                    : '';

                  return (
                    <div
                      key={severity.id}
                      className={`h-16 ${bgColor} rounded relative`}
                      data-risks={debugInfo}
                    >
                      <span className="text-xs font-medium">{score}</span>

                      {/* Always render the container for risk dots */}
                      <div className="absolute top-0 left-0 w-full h-full">
                        <div className="p-2 flex flex-wrap items-start justify-start">
                          {risksAtPosition.map((risk, index) => (
                            <div 
                              key={index} 
                              className="relative group m-1"
                              onMouseEnter={() => setHoveredRiskId(risk.id)}
                              onMouseLeave={() => setHoveredRiskId(null)}
                            >
                              <div
                                className="w-3 h-3 bg-gray-800 rounded-full cursor-pointer hover:scale-125 transition-transform"
                                aria-label={`Risk ${index + 1}`}
                              />
                              <div className="invisible group-hover:visible absolute z-50 w-64 p-3 bg-white shadow-lg rounded-md border border-gray-200 text-left -translate-x-1/2 left-1/2 bottom-full mb-2">
                                <div className="text-xs font-semibold text-gray-900 mb-1">
                                  {getRiskName(risk.id)}
                                </div>
                                {hasRiskMoved(risk.id) && showMovement && (
                                  <div className="text-xs text-gray-600">
                                    <span>{t('DataPrivacyWizard.dpia.riskReassessment.movedFrom', 'Moved from')} </span>
                                    <span className="font-medium">
                                      {getOriginalPosition(risk.id).likelihood} x {getOriginalPosition(risk.id).severity} = {getOriginalPosition(risk.id).likelihood * getOriginalPosition(risk.id).severity}
                                    </span>
                                  </div>
                                )}
                                {risk.notes && (
                                  <div className="text-xs text-gray-700 mb-1">{risk.notes}</div>
                                )}
                                {risk.harms && risk.harms.length > 0 && (
                                  <div className="mt-1">
                                    <span className="text-xs font-medium text-gray-700">
                                      {t('DataPrivacyWizard.dpia.riskAssessment.potentialHarms')}:
                                    </span>
                                    <div className="text-xs text-gray-600 mt-1">
                                      {risk.harms.slice(0, 2).map((harmId, i) => {
                                        const harmObj = potentialHarms.find(h => h.id === harmId);
                                        const harmKey = harmObj ? harmObj.key : harmId;
                                        return (
                                          <div key={i} className="truncate">
                                            • {t(`DataPrivacyWizard.dpia.riskAssessment.harms.${harmKey}`)}
                                          </div>
                                        );
                                      })}
                                      {risk.harms.length > 2 && (
                                        <div>
                                          • +{risk.harms.length - 2} {t('DataPrivacyWizard.dpia.riskAssessment.harms.more')}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          {/* Render the blue outlined "original position" circle in this cell */}
                          {hoveredRiskId && (() => {
                            const original = getOriginalPosition(hoveredRiskId);
                            if (
                              original &&
                              original.likelihood === likelihood.value &&
                              original.severity === severity.value &&
                              hasRiskMoved(hoveredRiskId)
                            ) {
                              return (
                                <div className="m-1">
                                  <div className="w-3 h-3 border-2 border-gray-600 rounded-full"></div>
                                </div>
                              );
                            }
                            return null;
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}

          {/* Severity label */}
          <div className="flex justify-center mt-2">
            <div className="text-sm text-gray-500">
              {t('DataPrivacyWizard.dpia.riskAssessment.severity')}
            </div>
          </div>
        </div>
      </div>

      {/* Legend */}
      <div className="flex justify-center space-x-4 mt-4 mb-6">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-green-100 rounded mr-1"></div>
          <span className="text-xs">{t('DataPrivacyWizard.dpia.riskAssessment.riskLevel.low')}</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-yellow-100 rounded mr-1"></div>
          <span className="text-xs">{t('DataPrivacyWizard.dpia.riskAssessment.riskLevel.medium')}</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-red-100 rounded mr-1"></div>
          <span className="text-xs">{t('DataPrivacyWizard.dpia.riskAssessment.riskLevel.high')}</span>
        </div>
        {showMovement && (
          <div className="flex items-center">
            <div className="relative w-4 h-4 mr-1">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-2 h-2 rounded-full border-2 border-gray-600"></div>
              </div>
            </div>
            <span className="text-xs">
              {t('DataPrivacyWizard.dpia.riskReassessment.originalPosition', 'Original position')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiskMatrix;
