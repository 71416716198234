// src/components/FlowChart/hooks/useFlowChart.js
import { useContext, useCallback } from 'react';
import { FlowChartContext } from '../FlowChartProvider';
import * as flowChartApi from '../api/flowChartApi';

export const useFlowChart = () => {
  const context = useContext(FlowChartContext);

  const loadFlowChart = useCallback(async (id) => {
    try {
      return await flowChartApi.getFlowChartById(id);
    } catch (error) {
      console.error('Error loading flowchart:', error);
      return null;
    }
  }, []);

  const saveFlowChart = useCallback(async (chartData) => {
    if (!chartData) {
      console.error('Invalid chart data provided');
      return null;
    }
    try {
      if (chartData.id) {
        return await flowChartApi.updateFlowChart(chartData.id, chartData);
      } else {
        return await flowChartApi.createFlowChart(chartData);
      }
    } catch (error) {
      console.error('Error saving flowchart:', error);
      return null;
    }
  }, []);

  const deleteFlowChart = useCallback(async (id) => {
    try {
      return await flowChartApi.deleteFlowChart(id);
    } catch (error) {
      console.error('Error deleting flowchart:', error);
      return false;
    }
  }, []);

  if (!context) {
    // Allow hook to be used outside provider for individual component usage
    // In this case, provide standalone functions
    return {
      loadFlowChart,
      saveFlowChart,
      deleteFlowChart,
      flowCharts: [],
      currentFlowChart: null,
      loading: false,
      error: null
    };
  }

  // Return the full context if used within provider
  return context;
};

export default useFlowChart;