// src/pages/wizards/DataPrivacyWizard/components/ComplianceMechanismField.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

const ComplianceMechanismField = ({
  transfer,
  index,
  formik,
  isEditing,
  fieldPrefix,
  toggleDrawer,
  userLang
}) => {
  const { t } = useTranslation();
  
  // Define compliance options with translations
  const complianceOptions = [
    { 
      value: 'bcr', 
      label: t('DataPrivacyWizard.complianceOptions.bcr')
    },
    { 
      value: 'scc', 
      label: t('DataPrivacyWizard.complianceOptions.scc')
    },
    { 
      value: 'adequacy', 
      label: t('DataPrivacyWizard.complianceOptions.adequacy')
    },
    { 
      value: 'consent', 
      label: t('DataPrivacyWizard.complianceOptions.consent')
    },
    { 
      value: 'derogation', 
      label: t('DataPrivacyWizard.complianceOptions.derogation')
    },
    { 
      value: 'none', 
      label: t('DataPrivacyWizard.complianceOptions.none')
    }
  ];
  
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
        {t('DataPrivacyWizard.complianceMechanism')}
        <button
          type="button"
          onClick={() => toggleDrawer(
            t('DataPrivacyWizard.complianceMechanismInfo.title'),
            t('DataPrivacyWizard.complianceMechanismInfo.content')
          )}
          className="ml-1 text-gray-400 hover:text-gray-500"
        >
          <QuestionMarkCircleIcon className="h-4 w-4" />
        </button>
      </label>
      
      {isEditing ? (
        <div className="mt-1">
          <select
            value={transfer.complianceMechanism}
            onChange={(e) => {
              formik.setFieldValue(`${fieldPrefix}[${index}].complianceMechanism`, e.target.value);
            }}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-color focus:border-primary-color sm:text-sm rounded-md"
          >
            {complianceOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          
          <div className="mt-2">
            <label htmlFor={`${fieldPrefix}-compliance-notes-${index}`} className="block text-sm font-medium text-gray-700">
              {t('DataPrivacyWizard.complianceNotes')}
            </label>
            <textarea
              id={`${fieldPrefix}-compliance-notes-${index}`}
              value={transfer.complianceNotes || ''}
              onChange={(e) => {
                formik.setFieldValue(`${fieldPrefix}[${index}].complianceNotes`, e.target.value);
              }}
              rows={2}
              className="shadow-sm focus:ring-primary-color focus:border-primary-color mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      ) : (
        <div className="mt-1 text-sm text-gray-900">
          {complianceOptions.find(opt => opt.value === transfer.complianceMechanism)?.label || 
           t('DataPrivacyWizard.notSpecified')}
          
          {transfer.complianceNotes && (
            <div className="mt-1 text-sm text-gray-600 italic">
              {transfer.complianceNotes}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ComplianceMechanismField;