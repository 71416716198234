import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { useTheme } from '@mui/material/styles';
import { getCountriesBySubregion } from '../constants/countryData';

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const MarketMap = ({ subregions }) => {
  const theme = useTheme();
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  
  const getCountriesForSubregions = (subregions) => {
    return subregions.flatMap(subregion => 
      getCountriesBySubregion(subregion)
    ).map(country => country.codenumeric);
  };

  const highlightedCountries = getCountriesForSubregions(subregions);

  const handleMouseMove = (event) => {
    setTooltipPosition({
      x: event.clientX,
      y: event.clientY
    });
  };

  const handleMouseEnter = (geo) => {
    setTooltipContent(geo.properties.name);
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <div 
      className="rounded-lg overflow-hidden" 
      style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '50%' }}
      onMouseMove={handleMouseMove}
    >
      <ComposableMap 
        projection="geoMercator"
        projectionConfig={{ 
          scale: 120,
          center: [0, -25]
        }}
        style={{ backgroundColor: '#e8f8fa' }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedCountries.includes(geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={isHighlighted ? "#2A9D8F" : '#D6D6DA'}
                  stroke="#FFFFFF"
                  strokeWidth={0.5}
                  onMouseEnter={() => handleMouseEnter(geo)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: {
                      outline: 'none'
                    },
                    hover: {
                      fill: isHighlighted ? "#1A7F73" : '#C0C0C4',
                      outline: 'none'
                    },
                    pressed: {
                      outline: 'none'
                    }
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      
      {tooltipContent && (
        <div 
          style={{
            position: 'fixed',
            top: tooltipPosition.y + 10,
            left: tooltipPosition.x + 10,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '14px',
            pointerEvents: 'none',
            zIndex: 1000
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default MarketMap;