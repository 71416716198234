// src/components/wizard_components/DataPrivacyWizard/riskAssessment/RiskSummaryTable.js
import React from 'react';
import { useTranslation } from 'react-i18next';

// Likelihood options
const likelihoodOptions = [
    { id: 'rare', value: 1, key: 'rare' },
    { id: 'unlikely', value: 2, key: 'unlikely' },
    { id: 'possible', value: 3, key: 'possible' },
    { id: 'likely', value: 4, key: 'likely' },
    { id: 'almost_certain', value: 5, key: 'almostCertain' }
];

// Severity options
const severityOptions = [
    { id: 'negligible', value: 1, key: 'negligible' },
    { id: 'minor', value: 2, key: 'minor' },
    { id: 'moderate', value: 3, key: 'moderate' },
    { id: 'major', value: 4, key: 'major' },
    { id: 'severe', value: 5, key: 'severe' }
];

// Risk scenarios data
const riskScenarios = [
    { id: 'unauthorized_access', key: 'unauthorizedAccess' },
    { id: 'data_breach', key: 'dataBreach' },
    { id: 'excessive_collection', key: 'excessiveCollection' },
    { id: 'unauthorized_sharing', key: 'unauthorizedSharing' },
    { id: 'data_loss', key: 'dataLoss' },
    { id: 'inaccurate_processing', key: 'inaccurateProcessing' },
    { id: 'rights_hindrance', key: 'rightsHindrance' },
    { id: 'beyond_purpose', key: 'beyondPurpose' },
    { id: 'improper_legal_basis', key: 'improperLegalBasis' },
    { id: 'excessive_retention', key: 'excessiveRetention' }
];

// Potential harms
const potentialHarms = [
    { id: 'discrimination', key: 'discrimination' },
    { id: 'identity_theft', key: 'identityTheft' },
    { id: 'financial_loss', key: 'financialLoss' },
    { id: 'reputational_damage', key: 'reputationalDamage' },
    { id: 'confidentiality_loss', key: 'confidentialityLoss' },
    { id: 'social_disadvantage', key: 'socialDisadvantage' },
    { id: 'economic_disadvantage', key: 'economicDisadvantage' },
    { id: 'psychological_impact', key: 'psychologicalImpact' },
    { id: 'physical_harm', key: 'physicalHarm' }
];

// Function to determine risk level
const calculateRiskLevel = (likelihood, severity) => {
    const riskScore = likelihood * severity;

    if (riskScore >= 15) return { level: 'high', color: 'bg-red-100 text-red-800' };
    if (riskScore >= 8) return { level: 'medium', color: 'bg-yellow-100 text-yellow-800' };
    return { level: 'low', color: 'bg-green-100 text-green-800' };
};

const RiskSummaryTable = ({ selectedRisks, customRisks }) => {
    const { t } = useTranslation();

    // Get the name of a risk
    const getRiskName = (riskId) => {
        // First check custom risks
        const customRisk = customRisks?.find(risk => risk.id === riskId && risk.isCustom);
        if (customRisk) return customRisk.name;

        // Then check built-in risks
        const builtInRisk = riskScenarios.find(risk => risk.id === riskId);
        if (builtInRisk) return t(`DataPrivacyWizard.dpia.riskAssessment.risks.${builtInRisk.key}`);

        // Return the risk ID if not found
        return riskId;
    };

    // Get the name of a harm
    const getHarmName = (harmId) => {
        const harm = potentialHarms.find(harm => harm.id === harmId);
        return harm ? t(`DataPrivacyWizard.dpia.riskAssessment.harms.${harm.key}`) : harmId;
    };

    // Calculate risk statistics
    const highRisks = selectedRisks.filter(
        risk => calculateRiskLevel(risk.likelihood, risk.severity).level === 'high'
    ).length;

    const mediumRisks = selectedRisks.filter(
        risk => calculateRiskLevel(risk.likelihood, risk.severity).level === 'medium'
    ).length;

    const lowRisks = selectedRisks.filter(
        risk => calculateRiskLevel(risk.likelihood, risk.severity).level === 'low'
    ).length;

    return (
        <div className="mb-4">
            <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('DataPrivacyWizard.dpia.riskAssessment.riskScenario')}
                            </th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('DataPrivacyWizard.dpia.riskAssessment.potentialHarms')}
                            </th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('DataPrivacyWizard.dpia.riskAssessment.likelihood')}
                            </th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('DataPrivacyWizard.dpia.riskAssessment.severity')}
                            </th>
                            <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('DataPrivacyWizard.dpia.riskAssessment.riskLevel.title')}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {/* Sort risks by risk level (high to low) */}
                        {[...selectedRisks]
                            .sort((a, b) => (b.likelihood * b.severity) - (a.likelihood * a.severity))
                            .map(risk => {
                                const riskLevel = calculateRiskLevel(risk.likelihood, risk.severity);
                                const likelihoodLabel = likelihoodOptions.find(opt => opt.value === risk.likelihood)?.key || '';
                                const severityLabel = severityOptions.find(opt => opt.value === risk.severity)?.key || '';

                                return (
                                    <tr key={risk.id}>
                                        <td className="px-3 py-4 text-sm text-gray-900">
                                            {getRiskName(risk.id)}
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500">
                                            {risk.harms.length > 0 ? (
                                                <ul className="list-disc list-inside">
                                                    {risk.harms.map(harmId => (
                                                        <li key={harmId} className="text-xs">
                                                            {getHarmName(harmId)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <span className="text-gray-400 italic text-xs">
                                                    {t('DataPrivacyWizard.dpia.riskAssessment.noHarmsIdentified')}
                                                </span>
                                            )}
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500">
                                            {t(`DataPrivacyWizard.dpia.riskAssessment.likelihoodOptions.${likelihoodLabel}`)}
                                        </td>
                                        <td className="px-3 py-4 text-sm text-gray-500">
                                            {t(`DataPrivacyWizard.dpia.riskAssessment.severityOptions.${severityLabel}`)}
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${riskLevel.color}`}>
                                                {t(`DataPrivacyWizard.dpia.riskAssessment.riskLevel.${riskLevel.level}`)}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>

            {/* Risk statistics */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* High risks */}
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="text-lg font-bold text-red-700">
                        {highRisks}
                    </div>
                    <div className="text-sm text-red-700">
                        {t('DataPrivacyWizard.dpia.riskAssessment.highRisks')}
                    </div>
                </div>

                {/* Medium risks */}
                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                    <div className="text-lg font-bold text-yellow-700">
                        {mediumRisks}
                    </div>
                    <div className="text-sm text-yellow-700">
                        {t('DataPrivacyWizard.dpia.riskAssessment.mediumRisks')}
                    </div>
                </div>

                {/* Low risks */}
                <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                    <div className="text-lg font-bold text-green-700">
                        {lowRisks}
                    </div>
                    <div className="text-sm text-green-700">
                        {t('DataPrivacyWizard.dpia.riskAssessment.lowRisks')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskSummaryTable;