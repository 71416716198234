// src/components/FlowChart/components/NodePalette.js
import React from 'react';
import { Panel } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { 
  ArrowPathIcon, 
  DocumentIcon, 
  DocumentPlusIcon, 
  ServerIcon, 
  UserCircleIcon 
} from '@heroicons/react/24/solid';

const NodePalette = ({ disabled }) => {
  const { t } = useTranslation();

  const onDragStart = (event, nodeType) => {
    if (disabled) return;
    
    // This line is crucial - setting the data to be transferred
    // It's important that this exact MIME type matches what is checked in onDrop
    event.dataTransfer.setData('application/reactflow', nodeType);
    
    // These settings are important for drag to work properly
    event.dataTransfer.effectAllowed = 'move';
    
    // Add a dragging class for visual feedback
    event.currentTarget.classList.add('dragging');
    
    console.log(`Started dragging node type: ${nodeType}`);
  };
  
  const onDragEnd = (event) => {
    event.currentTarget.classList.remove('dragging');
    console.log('Drag ended');
  };

  return (
    <Panel position="top-left" className="bg-white p-2 rounded shadow-md border border-gray-200">
      <div className="text-sm font-medium mb-2">
        {t('FlowChart.nodePalette', 'Node Types')}
      </div>
      <div className="flex flex-col space-y-2">
        {/* Activity Node - Subject + Verb + Object */}
        <div 
          className={`bg-blue-50 border border-blue-300 rounded p-2 ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-grab hover:shadow-md hover:border-blue-500 active:cursor-grabbing'
          }`}
          onDragStart={(e) => onDragStart(e, 'activityNode')}
          onDragEnd={onDragEnd}
          draggable={!disabled}
        >
          <div className="flex items-center">
            <ArrowPathIcon className="h-4 w-4 text-blue-700 mr-1" />
            <span>{t('FlowChart.activity', 'Activity')}</span>
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Subject → Action → Object
          </div>
        </div>
        
        {/* Input Node */}
        <div 
          className={`bg-green-50 border border-green-300 rounded p-2 ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-grab hover:shadow-md hover:border-green-500 active:cursor-grabbing'
          }`}
          onDragStart={(e) => onDragStart(e, 'inputNode')}
          onDragEnd={onDragEnd}
          draggable={!disabled}
        >
          <div className="flex items-center">
            <DocumentIcon className="h-4 w-4 text-green-700 mr-1" />
            <span>{t('FlowChart.input', 'Input')}</span>
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Input data asset
          </div>
        </div>
        
        {/* Output Node */}
        <div 
          className={`bg-indigo-50 border border-indigo-300 rounded p-2 ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-grab hover:shadow-md hover:border-indigo-500 active:cursor-grabbing'
          }`}
          onDragStart={(e) => onDragStart(e, 'outputNode')}
          onDragEnd={onDragEnd}
          draggable={!disabled}
        >
          <div className="flex items-center">
            <DocumentPlusIcon className="h-4 w-4 text-indigo-700 mr-1" />
            <span>{t('FlowChart.output', 'Output')}</span>
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Output data asset
          </div>
        </div>
        
        {/* System Node */}
        <div 
          className={`bg-green-50 border border-green-300 rounded p-2 ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-grab hover:shadow-md hover:border-green-500 active:cursor-grabbing'
          }`}
          onDragStart={(e) => onDragStart(e, 'systemNode')}
          onDragEnd={onDragEnd}
          draggable={!disabled}
        >
          <div className="flex items-center">
            <ServerIcon className="h-4 w-4 text-green-700 mr-1" />
            <span>{t('FlowChart.system', 'System')}</span>
          </div>
        </div>
        
        {/* Actor Node */}
        <div 
          className={`bg-purple-50 border border-purple-300 rounded p-2 ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-grab hover:shadow-md hover:border-purple-500 active:cursor-grabbing'
          }`}
          onDragStart={(e) => onDragStart(e, 'actorNode')}
          onDragEnd={onDragEnd}
          draggable={!disabled}
        >
          <div className="flex items-center">
            <UserCircleIcon className="h-4 w-4 text-purple-700 mr-1" />
            <span>{t('FlowChart.actor', 'Actor')}</span>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default NodePalette;