// cenedril_frontend/src/components/DashboardLayout.js
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import MobileNavigation from './MobileNavigation';
import Breadcrumbs from './Breadcrumbs';
import AIChatWrapper from './wizard_components/AIChatWrapper';
import './DashboardLayout.css';

export default function DashboardLayout() {
  const location = useLocation();
  
  // Check if we're on a wizard page
  const isWizardPage = [
    '/funding', 
    '/startup-wizard', 
    '/isms', 
    '/data-privacy-wizard'
  ].some(path => location.pathname.startsWith(path));

  return (
    <div className="flex h-full min-h-screen">
      <Sidebar className="hidden md:block" />
      <div className="flex-1 flex flex-col">
        <Breadcrumbs />
        <main className="flex-1 content-area p-0 md:p-6 bg-gray-100 wizard-content">
          <Outlet />
        </main>
        {/* Only render the AI Chat component on wizard pages */}
        {isWizardPage && <AIChatWrapper />}
      </div>
      <MobileNavigation className="block md:hidden" />
    </div>
  );
}