import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon, MagnifyingGlassIcon, CheckIcon } from '@heroicons/react/24/outline';

/**
 * Component for displaying and selecting from existing assets
 * 
 * @param {Object} props
 * @param {Function} props.onSelect - Callback when an asset is selected
 * @param {Array} props.selectedAssets - Currently selected assets
 * @param {Function} props.setSelectedAssets - Function to update selected assets
 * @param {Array} props.existingAssets - Assets already associated with the activity
 * @param {Array} props.allAssets - All available assets (already filtered by parent)
 * @param {Function} props.setError - Function to set error messages
 * @param {string} props.userLang - User's language preference (default: 'en')
 * @param {Function} props.getCategoryIcon - Function to get category icon path
 * @param {boolean} props.multiSelect - Enable multiple selection
 * @param {boolean} props.loading - Loading state passed from parent
 */
const ExistingAssetsList = ({
    onSelect,
    selectedAssets = [],
    setSelectedAssets,
    existingAssets = [], // Assets already in the activity
    allAssets = [], // All available assets (already filtered by parent)
    setError,
    userLang = 'en',
    getCategoryIcon,
    multiSelect = true,
    loading = false
}) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    
    // Filter assets by search query
    const displayedAssets = useMemo(() => {
        if (!searchQuery) return allAssets;
        
        const query = searchQuery.toLowerCase();
        return allAssets.filter(asset =>
            (asset.name || '').toLowerCase().includes(query) ||
            (asset.description || '').toLowerCase().includes(query) ||
            (asset.asset_type || '').toLowerCase().includes(query) ||
            (asset.asset_class || '').toLowerCase().includes(query) ||
            (asset.asset_category || '').toLowerCase().includes(query) ||
            (asset.werttyp || '').toLowerCase().includes(query) ||
            (asset.wertklasse || '').toLowerCase().includes(query) ||
            (asset.wertkategorie || '').toLowerCase().includes(query)
        );
    }, [allAssets, searchQuery]);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle asset selection (with multiSelect support)
    const handleAssetClick = (asset) => {
        if (multiSelect) {
            // For multi-select mode
            const isAlreadySelected = selectedAssets.some(item => String(item.id) === String(asset.id));
            
            if (isAlreadySelected) {
                // If already selected, remove from selection
                setSelectedAssets(selectedAssets.filter(item => String(item.id) !== String(asset.id)));
            } else {
                // If not selected, add to selection
                setSelectedAssets([...selectedAssets, asset]);
            }
        } else {
            // For single-select mode
            onSelect?.(asset);
        }
    };

    // Check if an asset is selected in the current modal selection
    const isAssetSelected = (assetId) => {
        return selectedAssets.some(asset => String(asset.id) === String(assetId));
    };

    // Check if an asset is already part of the activity
    const isAssetInExisting = (assetId) => {
        return existingAssets.some(asset => String(asset.id) === String(assetId));
    };

    // Check if an asset will be disconnected (is in existing but not selected)
    const willBeDisconnected = (assetId) => {
        return isAssetInExisting(assetId) && !isAssetSelected(assetId);
    };

    if (loading) {
        return (
            <div className="p-6 text-center">
                <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 border-secondary-color mr-2"></div>
                <span className="text-sm text-gray-600">{t("Loading...")}</span>
            </div>
        );
    }

    return (
        <div className="p-6">
            {/* Search bar */}
            <div className="mb-4 relative">
                <div className="relative">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder={t('Search assets...')}
                        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-secondary-color focus:border-secondary-color"
                    />
                    <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    {searchQuery && (
                        <button
                            onClick={() => setSearchQuery('')}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    )}
                </div>
            </div>

            {/* Assets list */}
            <div className="max-h-96 overflow-y-auto border rounded-md">
                {allAssets.length === 0 ? (
                    <div className="p-4 text-center text-gray-500">
                        {t('asset.selection.noAssetsFound', 'No assets found')}
                    </div>
                ) : displayedAssets.length === 0 ? (
                    <div className="p-4 text-center text-gray-500">
                        {t('No assets match your search')}
                    </div>
                ) : (
                    displayedAssets.map(asset => {
                        const isSelected = isAssetSelected(asset.id);
                        const isDisconnecting = willBeDisconnected(asset.id);
                        
                        return (
                            <div
                                key={asset.id}
                                className={`p-3 border-b last:border-b-0 cursor-pointer hover:bg-secondary-color/10 flex items-start 
                                    ${isSelected ? 'bg-secondary-color/20' : ''}
                                    ${isDisconnecting ? 'bg-red-100' : ''}`}
                                onClick={() => handleAssetClick(asset)}
                            >
                                {/* Category icon */}
                                <div className="mr-3 flex-shrink-0">
                                    {getCategoryIcon && asset.category_icon ? (
                                        <img
                                            src={getCategoryIcon({ icons: asset.category_icon })}
                                            alt=""
                                            className="w-12 h-12"
                                        />
                                    ) : (
                                        <div className="w-12 h-12 bg-purple-200 rounded-md"></div>
                                    )}
                                </div>

                                <div className="flex-1 min-w-0">
                                    <div className="font-medium flex items-center">
                                        <span>{asset.name}</span>
                                        {isDisconnecting && (
                                            <span className="ml-2 text-xs text-red-700 bg-red-50 px-2 py-0.5 rounded">
                                                {userLang === 'de' ? 'Nicht mehr assoziiert' : 'Not associated anymore'}
                                            </span>
                                        )}
                                    </div>
                                    {asset.description && (
                                        <div className="text-sm text-gray-500 truncate">{asset.description}</div>
                                    )}

                                    {/* Breadcrumb for asset type hierarchy */}
                                    <div className="text-xs text-gray-500 mt-1 flex items-center flex-wrap">
                                        {/* Check if we have any meaningful categorization data */}
                                        {(asset.asset_category || asset.wertkategorie ||
                                            asset.asset_class || asset.wertklasse ||
                                            asset.asset_type || asset.werttyp) ? (
                                            <>
                                                {/* Render the full breadcrumb with available data */}
                                                {(asset.asset_category || asset.wertkategorie) && (
                                                    <>
                                                        <span>
                                                            {userLang === 'de' && asset.wertkategorie ? asset.wertkategorie : asset.asset_category}
                                                        </span>
                                                        {(asset.asset_class || asset.wertklasse || asset.asset_type || asset.werttyp) && <span className="mx-1">›</span>}
                                                    </>
                                                )}

                                                {(asset.asset_class || asset.wertklasse) && (
                                                    <>
                                                        <span>
                                                            {userLang === 'de' && asset.wertklasse ? asset.wertklasse : asset.asset_class}
                                                        </span>
                                                        {(asset.asset_type || asset.werttyp) && <span className="mx-1">›</span>}
                                                    </>
                                                )}

                                                {(asset.asset_type || asset.werttyp) && (
                                                    <span>
                                                        {userLang === 'de' && asset.werttyp ? asset.werttyp : asset.asset_type}
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            // If we don't have categorization data, try to determine if it's a custom asset
                                            <span>
                                                {asset.custom_type ? `${t('asset.selection.custom', 'Custom')}: ${asset.custom_type}` : t('asset.selection.other', 'Other')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                
                                {/* Selection indicator (checkmark) on the right side */}
                                {multiSelect && (
                                    <div className="ml-3 flex-shrink-0 flex items-center h-full">
                                        {isSelected && (
                                            <CheckIcon className="h-5 w-5 text-secondary-color" />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>

            {/* Helper text */}
            <div className="mt-3 text-xs text-gray-500">
                {t('Select assets to add or remove them from your activity')}
            </div>
        </div>
    );
};

export default ExistingAssetsList;