import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from "react-icons/io5";
import CountrySelector from '../../components/CountrySelector';
import { useCountryData } from '../../constants/countryData';

const OfficeCard = ({ office, index, onRemove, setFieldValue, companyCountry }) => {
  const { t } = useTranslation();
  const { data: countries, loading } = useCountryData();

  const disabledInputClass =
    "mt-1 block w-full border-gray-300 rounded-md bg-gray-100 text-gray-500 cursor-not-allowed";
  const enabledInputClass =
    "mt-1 block w-full border-gray-300 rounded-md";

  // Compute the sorted countries from the loaded data (if available)
  const sortedCountries = countries ? [...countries].sort((a, b) => a.name.localeCompare(b.name)) : [];

  // Helper function: given companyCountry (which could be a code or a name),
  // returns a best-effort translated name for the disabled field.
  const getDisplayNameForCompanyCountry = (countryValue) => {
    if (!countryValue) {
      return '';
    }

    // 1) Try matching as code
    let found = sortedCountries.find((c) => c.code === countryValue);

    // 2) If not found, try matching as name
    if (!found) {
      found = sortedCountries.find((c) => c.name === countryValue);
    }

    if (found) {
      const translationKey = `Countries.${found.name}`;
      const translated = t(translationKey);
      if (!translated || translated.startsWith('Countries.')) {
        return found.name;
      }
      return translated;
    } else {
      const translationKey = `Countries.${countryValue}`;
      let fallback = t(translationKey);
      if (!fallback || fallback.startsWith('Countries.')) {
        fallback = countryValue;
      }
      return fallback;
    }
  };

  // Get the display name for the company's country
  const displayedCompanyCountry = getDisplayNameForCompanyCountry(companyCountry);

  // Handle employee number changes
  const handleEmployeeChange = (e, field) => {
    const value = e.target.value;
    if (value === '') {
      field.onChange({ target: { name: field.name, value: 0 } });
      return;
    }
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue >= 0) {
      field.onChange({ target: { name: field.name, value: numValue } });
    }
  };

  return (
    <div className="border rounded-lg p-4 mb-4 bg-white">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">
          {index === 0
            ? t('Remote Workers')
            : office.location || `${t('Office')} ${index}`
          }
        </h3>
        {index > 1 && (
          <button
            type="button"
            className="text-red-500 hover:text-red-700 transition-colors duration-200"
            onClick={onRemove}
            aria-label={t('Remove')}
          >
            <IoTrashOutline className="w-5 h-5" />
          </button>
        )}
      </div>

      {/* Location */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">
          {t('Location')}
        </label>
        {index === 0 ? (
          <input
            value={t('Remote Workers')}
            className={disabledInputClass}
            disabled
          />
        ) : (
          <Field
            name={`offices.${index}.location`}
            className={enabledInputClass}
          />
        )}
      </div>

      {/* Country */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">
          {t('Country')}
        </label>
        {index <= 1 ? (
          <input
            value={displayedCompanyCountry}
            className={disabledInputClass}
            disabled
          />
        ) : (
          <CountrySelector
            value={office.country} // e.g. "CH" or "Switzerland"
            onChange={(newCode) => setFieldValue(`offices.${index}.country`, newCode)}
          />
        )}
      </div>

      {/* Number of Employees */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">
          {t('Number of employees')}
        </label>
        <Field name={`offices.${index}.employees`}>
          {({ field }) => (
            <input
              {...field}
              type="number"
              min="0"
              value={field.value}
              onChange={(e) => handleEmployeeChange(e, field)}
              onBlur={(e) => {
                if (e.target.value === '' || isNaN(parseInt(e.target.value, 10))) {
                  field.onChange({ target: { name: field.name, value: 0 } });
                }
                field.onBlur(e);
              }}
              className={enabledInputClass}
            />
          )}
        </Field>
      </div>
    </div>
  );
};

export default OfficeCard;
