import React from 'react';

const PulsatingNewBadge = () => {
    return (
        <div className="absolute -top-1 -right-1">
            <div className="relative">
                <div className="absolute inset-0 animate-ping rounded-full bg-[#2A9D8F] opacity-30"></div>
                <div className="relative rounded-full bg-[#2A9D8F] px-2 py-0">
                    <span className="text-xs font-semibold text-white">Now available!</span>
                </div>
            </div>
        </div>
    );
};

export default PulsatingNewBadge;