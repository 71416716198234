// src/components/wizard_components/DataPrivacyWizard/DPIARiskAssessment.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import risk assessment subcomponents
import RiskScenarioList from './RiskScenarioList';
import RiskDetailsForm from './RiskDetailsForm';

const DPIARiskAssessment = ({ formik, toggleDrawer }) => {
  const { t } = useTranslation();
  const [selectedRiskId, setSelectedRiskId] = useState(null);

  // Initialize risk data in formik if needed
  useEffect(() => {
    // Initialize riskScenarios array if it doesn't exist
    if (!formik.values.riskScenarios || !Array.isArray(formik.values.riskScenarios)) {
      formik.setFieldValue('riskScenarios', []);
    }
    
    // Initialize selectedRisks array if it doesn't exist
    if (!formik.values.selectedRisks || !Array.isArray(formik.values.selectedRisks)) {
      formik.setFieldValue('selectedRisks', []);
    }
  }, [formik]);

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold">
        {t('DataPrivacyWizard.dpia.riskAssessment.title')}
      </h2>
      
      <p className="text-gray-600 mb-6">
        {t('DataPrivacyWizard.dpia.riskAssessment.description')}
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Risk Scenarios Selection */}
        <div className="md:col-span-1">
          <RiskScenarioList
            formik={formik}
            selectedRiskId={selectedRiskId}
            setSelectedRiskId={setSelectedRiskId}
          />
        </div>

        {/* Risk Details Form */}
        <div className="md:col-span-2">
          <RiskDetailsForm
            formik={formik}
            selectedRiskId={selectedRiskId}
            setSelectedRiskId={setSelectedRiskId}
          />
        </div>
      </div>
    </div>
  );
};

export default DPIARiskAssessment;