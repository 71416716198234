import React, { useState, useEffect } from 'react';
import { Menu, Dialog } from '@headlessui/react';
import {
  LockClosedIcon,
  UserIcon,
  BuildingOfficeIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  TrashIcon,
  LinkIcon,
  CircleStackIcon,
  PlayIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
  HeartIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import WizardInstructions from './WizardInstructions';
import Notification from './Notification';

const areas = [
  { id: 'cybersecurity', icon: LockClosedIcon },
  { id: 'data_privacy', icon: UserIcon },
  { id: 'organization', icon: BuildingOfficeIcon },
  { id: 'culture', icon: HeartIcon },
  { id: 'finance', icon: CurrencyDollarIcon },
  { id: 'other', icon: EllipsisHorizontalIcon },
];

const priorities = [
  { id: 'normal', color: 'bg-gray-100 text-gray-800' },
  { id: 'high', color: 'bg-orange-100 text-orange-800' },
  { id: 'very_high', color: 'bg-red-100 text-red-800' },
];

const statuses = [
  { id: 'backlog' },
  { id: 'current' },
  { id: 'approval' },
  { id: 'done' },
];

// Status icon mapping
const statusIcons = {
  backlog: CircleStackIcon,
  current: PlayIcon,
  approval: ClipboardDocumentCheckIcon,
  done: CheckCircleIcon
};

const UserInitials = ({ firstName, lastName }) => {
  const initials = `${firstName?.charAt(0) || '?'}${lastName?.charAt(0) || '?'}`.toUpperCase();
  return (
    <div className="h-10 w-10 rounded-full bg-secondary-color flex items-center justify-center mr-2">
      <span className="text-white text-xs font-bold">{initials}</span>
    </div>
  );
};

const formatTimeAgo = (date, t, i18n) => {
  const now = new Date();
  const diff = now - new Date(date);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));

  if (days > 7) {
    return new Date(date).toLocaleDateString(i18n.language, { day: '2-digit', month: '2-digit', year: '2-digit' });
  } else if (days > 0) {
    return `${days} ${days === 1 ? t('day ago') : t('days ago')}`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? t('hour ago') : t('hours ago')}`;
  } else {
    return `${minutes} ${minutes === 1 ? t('minute ago') : t('minutes ago')}`;
  }
};

const TaskDetailModal = ({ task, users, onUpdate, onClose, isOpen }) => {
  const { t, i18n } = useTranslation();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    type: 'success',
    message: '',
    errors: []
  });
  const [localName, setLocalName] = useState(task.name);

  // Add loading state
  const [isReady, setIsReady] = useState(false);

  // Check if we have all required data
  useEffect(() => {
    if (task && users) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [task, users]);

  useEffect(() => {
    setLocalName(task.name);
  }, [task]);

  // Early return if not ready
  if (!isReady) {
    return (
      <Dialog open={isOpen} onClose={onClose} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }

  // Get current user
  const currentUser = JSON.parse(localStorage.getItem('user'));

  // Function to check if status change is allowed
  const canChangeToStatus = (newStatus) => {
    if (newStatus === 'done') {
      return task.approved_by_id === currentUser.id;
    } else {
      return task.assigned_to_id === currentUser.id;
    }
  };


  // Handle all updates (including status)
  const handleUpdate = async (field, value) => {
    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { [field]: value } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      onUpdate();  // This should now be fetchTasks from KanbanBoard
      setNotificationProps({
        type: 'success',
        message: t('Update successful'),
        errors: []
      });
      setShowNotification(true);
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to update task'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  const handleUpdateName = async () => {
    if (localName !== task.name) {
      try {
        await axios.put(`/api/v1/tasks/${task.id}`,
          { task: { name: localName } },
          { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
        );
        onUpdate();
      } catch (error) {
        setNotificationProps({
          type: 'error',
          message: t('Failed to update task'),
          errors: [error.message]
        });
        setShowNotification(true);
      }
    }
  };

  const handleAssignedToUpdate = async (userId) => {
    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { assigned_to_id: userId } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      onUpdate();
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to update assigned user'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  const handleApprovalByUpdate = async (userId) => {
    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { approved_by_id: userId } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      onUpdate();
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to update approval user'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  // Modified handleUpdate function for status changes
  const handleStatusUpdate = async (newStatus) => {
    if (!canChangeToStatus(newStatus)) {
      setNotificationProps({
        type: 'error',
        message: t("You don't have permission to change this task's status."),
        errors: []
      });
      setShowNotification(true);
      return;
    }

    try {
      await axios.put(`/api/v1/tasks/${task.id}`,
        { task: { status: newStatus } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      onUpdate('status', newStatus);
      setNotificationProps({
        type: 'success',
        message: t('Status updated successfully'),
        errors: []
      });
      setShowNotification(true);
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to update status'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  // Get available status options based on permissions
  const getAvailableStatuses = () => {
    const statuses = ['backlog', 'current', 'approval', 'done'];
    return statuses.filter(status => {
      if (status === task.status) return true; // Always show current status
      return canChangeToStatus(status);
    });
  };

  const getApprovedDate = () => {
    if (task?.status === 'done' && task?.status_changes_history) {
      const doneChange = task.status_changes_history.find(change => change.to_status === 'done');
      return doneChange ? new Date(doneChange.changed_at) : null;
    }
    return null;
  };

  const assignedUser = users.find(user => user.id === task.assigned_to_id);
  {
    assignedUser?.avatar_url ? (
      <img
        src={assignedUser.avatar_url}
        alt={`${assignedUser.first_name} ${assignedUser.last_name}`}
        className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
      />
    ) : (
      <UserInitials
        firstName={assignedUser?.first_name}
        lastName={assignedUser?.last_name}
      />
    )
  }

  const approvedByUser = users.find(user => user.id === task.approved_by_id);
  {
    approvedByUser?.avatar_url ? (
      <img
        src={approvedByUser.avatar_url}
        alt={`${approvedByUser.first_name} ${approvedByUser.last_name}`}
        className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
      />
    ) : (
      <UserInitials
        firstName={approvedByUser?.first_name}
        lastName={approvedByUser?.last_name}
      />
    )
  }

  const creationUser = users.find(user => user.id === task.created_by_id);

  const assignorUser = users.find(user => user.id === task.assigned_by_id);

  const approvedDate = getApprovedDate();

  const handleArchive = async () => {
    try {
      await axios.post(`/api/v1/tasks/${task.id}/archive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate(); // Call the callback to refresh the tasks
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to archive task'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  const handleUnarchive = async () => {
    try {
      await axios.post(`/api/v1/tasks/${task.id}/unarchive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to unarchive task'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };

  const handlePermanentDelete = async () => {
    try {
      onClose(); // Close modal first
      await axios.delete(`/api/v1/tasks/${task.id}/permanent_delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      setNotificationProps({
        type: 'error',
        message: t('Failed to delete task'),
        errors: [error.message]
      });
      setShowNotification(true);
    }
  };


  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className={`mx-auto max-w-2xl w-full rounded-xl shadow-lg ${task.archived ? 'bg-red-50' : 'bg-white'}`}>
          <div className="p-6 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-start">
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>

              {/* Add Archive/Delete Controls */}
              <div className="flex flex-col items-end space-y-2">
                {task.archived ? (
                  <>
                    <button
                      onClick={handleUnarchive}
                      className="text-red-400 hover:text-gray-600 flex items-center"
                      title={t('Unarchive')}
                    >
                      <ArrowUturnLeftIcon className="w-5 h-5" />
                    </button>
                    <button
                      onClick={handlePermanentDelete}
                      className="text-red-600 hover:text-red-700 flex items-center"
                      title={t('Delete Permanently')}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleArchive}
                    className="text-red-400 hover:text-gray-600 flex items-center"
                    title={t('Archive')}
                  >
                    <ArchiveBoxIcon className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>

            <div className="mt-6 space-y-6">
              {/* Editable Fields with Dropdowns */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-xs font-medium text-gray-500">{t('area')}</label>
                  <Menu>
                    <Menu.Button className="mt-1 text-base flex items-center">
                      {React.createElement(areas.find(a => a.id === task?.area)?.icon || EllipsisHorizontalIcon, { className: 'w-5 h-5 mr-2' })}
                      {t(`area_${task?.area || 'other'}`)}
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {areas.map((area) => (
                          <Menu.Item key={area.id}>
                            {({ active }) => (
                              <button
                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                onClick={() => handleUpdate('area', area.id)}
                              >
                                {React.createElement(area.icon, { className: 'w-5 h-5 mr-3' })}
                                {t(`area_${area.id}`)}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>

                <div>
                  <label className="block text-xs font-medium text-gray-500">{t('priority')}</label>
                  <Menu>
                    <Menu.Button className="mt-1 flex items-center">
                      <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priorities.find(p => p.id === task.priority)?.color}`}>
                        {t(`priority_${task.priority}`)}
                      </span>
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {priorities.map((priority) => (
                          <Menu.Item key={priority.id}>
                            {({ active }) => (
                              <button
                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                onClick={() => handleUpdate('priority', priority.id)}
                              >
                                <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priority.color}`}>
                                  {t(`priority_${priority.id}`)}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              </div>

              {/* Name field */}
              <div className="mt-4">
                <label className="block text-xs font-medium text-gray-500">{t('name')}</label>
                <input
                  type="text"
                  value={localName}
                  onChange={(e) => setLocalName(e.target.value)}
                  onBlur={handleUpdateName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdateName();
                    }
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color text-base font-bold"
                />
              </div>

              {/* Add Link Display for System Tasks */}
              {task.other_data?.origin === 'system' && task.other_data?.link && (
                <div className="mt-2">
                  <label className="block text-xs font-medium text-gray-500">{t('related_page')}</label>
                  <a
                    href={task.other_data.link}
                    className="text-primary-color hover:text-secondary-color text-base font-medium inline-flex items-center gap-1 mt-1"
                  >
                    <LinkIcon className="w-4 h-4" />
                    <span>{t('go_to_wizard_page')}</span>
                  </a>
                </div>
              )}

              {/* Status field with icons */}
              <div className="mt-4">
                <label className="block text-xs font-medium text-gray-500">{t('taskstatus')}</label>
                <Menu>
                  <Menu.Button className="mt-1 text-base flex items-center">
                    {React.createElement(statusIcons[task.status], { className: 'h-5 w-5 mr-2' })}
                    {t(`status_${task.status}`)}
                  </Menu.Button>
                  <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {getAvailableStatuses().map((status) => (
                        <Menu.Item key={status}>
                          {({ active }) => (
                            <button
                              className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                              onClick={() => handleUpdate('status', status)}
                            >
                              {React.createElement(statusIcons[status], { className: 'h-5 w-5 mr-2' })}
                              {t(`status_${status}`)}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              </div>

              {/* Assigned to & Approval By with Avatar */}
              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center space-x-2">
                  {assignedUser?.avatar_url ? (
                    <img
                      src={assignedUser.avatar_url}
                      alt={`${assignedUser.first_name} ${assignedUser.last_name}`}
                      className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
                    />
                  ) : (
                    <UserInitials
                      firstName={assignedUser?.first_name}
                      lastName={assignedUser?.last_name}
                    />
                  )}
                  <div className="flex flex-col">
                    <label className="block text-xs text-gray-500">{t('assigned_to')}</label>
                    <Menu as="div" className="relative inline-block text-left">
                      <Menu.Button className="flex items-center">
                        <span className="block text-sm font-medium">{task.assigned_to_name || t('unknown')}</span>
                      </Menu.Button>
                      <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {users.map((user) => (
                            <Menu.Item key={user.id}>
                              {({ active }) => (
                                <button
                                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    } group flex w-full items-center px-4 py-2 text-sm`}
                                  onClick={() => handleAssignedToUpdate(user.id)}
                                >
                                  {user.avatar_url ? (
                                    <img
                                      src={user.avatar_url}
                                      alt={`${user.first_name} ${user.last_name}`}
                                      className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
                                    />
                                  ) : (
                                    <UserInitials firstName={user.first_name} lastName={user.last_name} />
                                  )}
                                  {user.first_name} {user.last_name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  {approvedByUser?.avatar_url ? (
                    <img
                      src={approvedByUser.avatar_url}
                      alt={`${approvedByUser.first_name} ${approvedByUser.last_name}`}
                      className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
                    />
                  ) : (
                    <UserInitials
                      firstName={approvedByUser?.first_name}
                      lastName={approvedByUser?.last_name}
                    />
                  )}
                  <div className="flex flex-col">
                    <label className="block text-xs text-gray-500">{t('approval_by')}</label>
                    <Menu as="div" className="relative inline-block text-left">
                      <Menu.Button className="flex items-center">
                        <span className="block text-sm font-medium">{task.approved_by_name || t('unknown')}</span>
                      </Menu.Button>
                      <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {users.map((user) => (
                            <Menu.Item key={user.id}>
                              {({ active }) => (
                                <button
                                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    } group flex w-full items-center px-4 py-2 text-sm`}
                                  onClick={() => handleApprovalByUpdate(user.id)}
                                >
                                  {user.avatar_url ? (
                                    <img
                                      src={user.avatar_url}
                                      alt={`${user.first_name} ${user.last_name}`}
                                      className="w-10 h-10 rounded-full mr-2  bg-secondary-color object-cover object-center"
                                    />
                                  ) : (
                                    <UserInitials firstName={user.first_name} lastName={user.last_name} />
                                  )}
                                  {user.first_name} {user.last_name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Menu>
                    {approvedDate && (
                      <span className="block text-xs text-gray-500 mt-1">{t('approved')} {formatTimeAgo(approvedDate, t, i18n)}</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Created by and Date Information */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-xs font-medium text-gray-500">{t('created_by')}</label>
                  <div className="mt-1 text-sm">{creationUser ? `${creationUser.first_name} ${creationUser.last_name}` : t('unknown')}</div>
                  <div className="text-sm text-gray-500">
                    {formatTimeAgo(task.created_at, t, i18n)}
                  </div>
                </div>

                {task.assigned_by_id && (
                  <div>
                    <label className="block text-xs font-medium text-gray-500">{t('assigned_by')}</label>
                    <div className="mt-1 text-sm">{assignorUser ? `${assignorUser.first_name} ${assignorUser.last_name}` : t('unknown')}</div>
                    <div className="text-sm text-gray-500">
                      {task.assignment_date && formatTimeAgo(task.assignment_date, t, i18n)}
                    </div>
                  </div>
                )}
              </div>

              {/* Other Data */}

              {/* Add Wizard Instructions */}
              {task.other_data?.wizardInstructions && (
                <WizardInstructions section={task.other_data.section} />
              )}

              {task.other_data?.origin === 'system' && (
                <div className="bg-gray-50 p-4 rounded-md">
                  <p className="text-sm text-gray-600">
                    {t('automatic_generated_task')}
                  </p>
                </div>
              )}

              <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

              {/* Status Change History */}
              <div>
                <h3 className="text-sm font-bold text-gray-700 mb-2">{t('status_change_history')}</h3>
                <div className="space-y-2">
                  {task.status_changes_history?.map((change, index) => {
                    const changeUser = users.find(user => user.id === change.changed_by_id);
                    return (
                      <div key={index} className="text-sm">
                        <span className="text-gray-600">
                          {new Date(change.changed_at).toLocaleString()} {t('by')} {changeUser ? `${changeUser.first_name} ${changeUser.last_name}` : t('unknown')}:
                        </span>{' '}
                        {t('changed_from')} {t(`status_${change.from_status}`)} {t('changed_to')} {t(`status_${change.to_status}`)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const TaskCard = ({ task = {}, users, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(() => task?.name || '');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const { t, i18n } = useTranslation();

  const handleUpdate = async (field, value) => {
    try {
      const updateData = { [field]: value };
      await axios.put(`/api/v1/tasks/${task.id}`, updateData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleArchive = async () => {
    try {
      await axios.post(`/api/v1/tasks/${task.id}/archive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      console.error('Error archiving task:', error);
    }
  };

  const handleUnarchive = async () => {
    try {
      await axios.post(`/api/v1/tasks/${task.id}/unarchive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      console.error('Error unarchiving task:', error);
    }
  };

  const handlePermanentDelete = async () => {
    try {
      await axios.delete(`/api/v1/tasks/${task.id}/permanent_delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      onUpdate();
    } catch (error) {
      console.error('Error deleting task permanently:', error);
      alert(t('Failed to delete task. Please try again.'));
    }
  };

  const assignedUser = users.find(user => user.id === task.assigned_to_id);
  {
    assignedUser?.avatar_url ? (
      <img
        src={assignedUser.avatar_url}
        alt={`${assignedUser.first_name} ${assignedUser.last_name}`}
        className="w-10 h-10 rounded-full mr-2  bg-secondary-color object-cover object-center"
      />
    ) : (
      <UserInitials
        firstName={assignedUser?.first_name}
        lastName={assignedUser?.last_name}
      />
    )
  }

  const approvedByUser = users.find(user => user.id === task.approved_by_id);
  {
    approvedByUser?.avatar_url ? (
      <img
        src={approvedByUser.avatar_url}
        alt={`${approvedByUser.first_name} ${approvedByUser.last_name}`}
        className="w-10 h-10 rounded-full mr-2  bg-secondary-color object-cover object-center"
      />
    ) : (
      <UserInitials
        firstName={approvedByUser?.first_name}
        lastName={approvedByUser?.last_name}
      />
    )
  }

  return (
    <>
      <div className={`p-3 mb-3 rounded shadow ${task.archived ? 'bg-red-100' : 'bg-white'}`}>
        <div className="flex items-center justify-between mb-1">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="text-xs text-gray-500 flex items-center">
              {React.createElement(areas.find(a => a.id === task.area)?.icon, { className: 'w-3 h-3 mr-1' })}
              {t(`area_${task.area}`)}
            </Menu.Button>
            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {areas.map((area) => (
                  <Menu.Item key={area.id}>
                    {({ active }) => (
                      <button
                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                        onClick={() => handleUpdate('area', area.id)}
                      >
                        {React.createElement(area.icon, { className: 'w-5 h-5 mr-3' })}
                        {t(`area_${area.id}`)}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>

          {/* Conditional rendering for Archive/Unarchive */}
          {task.archived ? (
            <button
              onClick={handleUnarchive}
              className="text-red-400 hover:text-gray-600"
            >
              <ArrowUturnLeftIcon className="w-4 h-4" />
            </button>
          ) : (
            <button
              onClick={handleArchive}
              className="text-red-400 hover:text-gray-600"
            >
              <ArchiveBoxIcon className="w-4 h-4" />
            </button>
          )}
        </div>

        {isEditing ? (
          <input
            type="text"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleUpdate('name', editedName);
                setIsEditing(false);
              }
            }}
            onBlur={() => {
              handleUpdate('name', editedName);
              setIsEditing(false);
            }}
            className="text-sm rounded-md focus:border-secondary-color focus:ring-secondary-color font-bold mb-2 w-full"
            autoFocus
          />
        ) : (
          <h3 className="text-sm font-bold mb-2 cursor-pointer" onClick={() => setIsEditing(true)}>
            {task.name}
          </h3>
        )}

        <div className="mb-2 flex items-start space-x-2">
          <div className="flex items-center">
            {assignedUser?.avatar_url ? (
              <img
                src={assignedUser.avatar_url}
                alt={`${assignedUser.first_name} ${assignedUser.last_name}`}
                className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
              />
            ) : (
              <UserInitials
                firstName={assignedUser?.first_name}
                lastName={assignedUser?.last_name}
              />
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-xs text-gray-500">{t('assigned_to')}</label>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex items-center">
                <span className="block text-sm font-medium">{task.assigned_to_name || t('unknown')}</span>
              </Menu.Button>
              <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {users.map((user) => (
                    <Menu.Item key={user.id}>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                            } group flex w-full items-center px-4 py-2 text-sm`}
                          onClick={() => handleUpdate('assigned_to_id', user.id)}
                        >
                          {user.avatar_url ? (
                            <img
                              src={user.avatar_url}
                              alt={`${user.first_name} ${user.last_name}`}
                              className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
                            />
                          ) : (
                            <UserInitials firstName={user.first_name} lastName={user.last_name} />
                          )}
                          {user.first_name} {user.last_name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>

        <div className="mb-2 flex items-start space-x-2">
          <div className="flex items-center">
            {approvedByUser?.avatar_url ? (
              <img
                src={approvedByUser.avatar_url}
                alt={`${approvedByUser.first_name} ${approvedByUser.last_name}`}
                className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
              />
            ) : (
              <UserInitials
                firstName={approvedByUser?.first_name}
                lastName={approvedByUser?.last_name}
              />
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-xs text-gray-500">{t('approval_by')}</label>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex items-center">
                <span className="block text-sm font-medium">{task.approved_by_name || t('unknown')}</span>
              </Menu.Button>
              <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {users.map((user) => (
                    <Menu.Item key={user.id}>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                            } group flex w-full items-center px-4 py-2 text-sm`}
                          onClick={() => handleUpdate('approved_by_id', user.id)}
                        >
                          {user.avatar_url ? (
                            <img
                              src={user.avatar_url}
                              alt={`${user.first_name} ${user.last_name}`}
                              className="w-10 h-10 rounded-full mr-2 bg-secondary-color object-cover object-center"
                            />
                          ) : (
                            <UserInitials firstName={user.first_name} lastName={user.last_name} />
                          )}
                          {user.first_name} {user.last_name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>

        <div className="mb-2 flex items-center">
          <label className="text-xs text-gray-500 mr-2">{t('priority')}</label>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center">
              <span className={`text-xs font-medium px-2 py-0.5 rounded ${priorities.find(p => p.id === task.priority)?.color}`}>{t(`priority_${task.priority}`)}</span>
            </Menu.Button>
            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {priorities.map((priority) => (
                  <Menu.Item key={priority.id}>
                    {({ active }) => (
                      <button
                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                        onClick={() => handleUpdate('priority', priority.id)}
                      >
                        <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priority.color}`}>
                          {t(`priority_${priority.id}`)}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>

        {/* Delete Permanently Button for Archived Tasks */}
        {task.archived && (
          <button
            onClick={handlePermanentDelete}
            className="mt-2 mb-2 flex items-center bg-red-600 text-white text-xs px-3 py-1 rounded-md hover:bg-red-700"
          >
            <TrashIcon className="w-4 h-4 mr-1" />
            {t('delete_permanently')}
          </button>
        )}

        <button
          className="mt-2 text-xs font-bold text-secondary-color hover:text-secondary-color/80"
          onClick={() => setShowDetailModal(true)}
        >
          {t('see_details')}
        </button>
      </div>

      <TaskDetailModal
        task={task}
        users={users}
        onUpdate={onUpdate}
        isOpen={showDetailModal}
        onClose={() => setShowDetailModal(false)}
      />
    </>
  );
};

export default TaskCard;
export { TaskDetailModal };