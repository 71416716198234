import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import Notification from '../../../components/Notification';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import CashflowTable from '../../../components/wizard_components/CashFlowTable';
import FundingRoundsTable from '../../../components/wizard_components/FundingRoundsTable';
import CashflowChart from '../../../components/wizard_components/CashFlowChart';
import ValuationChart from '../../../components/wizard_components/ValuationChart';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardFundingRounds = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');
    const currentYear = new Date().getFullYear();
    const foundingYear = userData?.entity?.year_founded || currentYear;

    // Get the currency from investment page
    const fundingCurrency = userData?.entity?.know_yourself_response?.published?.fundingCurrency ||
        userData?.entity?.know_yourself_response?.draft?.fundingCurrency || 'EUR';

    // Get target funding round info from investment page
    const targetRound = userData?.entity?.know_yourself_response?.published?.targetFundingRound ||
        userData?.entity?.know_yourself_response?.draft?.targetFundingRound;
    const targetAmount = userData?.entity?.know_yourself_response?.published?.capitalRequired ||
        userData?.entity?.know_yourself_response?.draft?.capitalRequired || 0;

    const [initialValues, setInitialValues] = useState({
        cashflow: [],
        fundingRounds: [],
        currency: fundingCurrency || 'EUR'
    });

    useEffect(() => {
        if (userData?.entity?.know_yourself_response) {
            const { published, draft } = userData.entity.know_yourself_response;
            const relevantDraftData = draft?.fundingRounds || {};
            const relevantPublishedData = published?.fundingRounds || {};

            // Get the target funding time from investment page
            const targetFundingTime = published?.fundingTime || draft?.fundingTime || {
                month: new Date().getMonth() + 1,
                year: currentYear
            };

            // Determine the end year (either current year or funding round year, whichever is later)
            const endYear = Math.max(currentYear, targetFundingTime.year);

            // Generate cashflow entries for all years
            const initialCashflow = [];
            for (let year = foundingYear; year <= endYear; year++) {
                initialCashflow.push({
                    year,
                    expenses: 0,
                    revenue: 0,
                    investments: year === targetFundingTime.year ? targetAmount : 0,
                    profitLoss: 0,
                    cashflow: 0,
                    capital: 0
                });
            }

            // Create default funding rounds array with initial capital
            const defaultFundingRounds = [
                {
                    id: 'initial-capital',
                    time: {
                        month: 1,
                        year: foundingYear
                    },
                    type: 'Initial Capital',
                    milestones: 'Founding',
                    preMoneyValuation: 0,
                    investmentAmount: 0,
                    isInitialCapital: true
                }
            ];

            // Add target funding round if we have the information
            if (targetRound && targetAmount && targetFundingTime) {
                defaultFundingRounds.push({
                    id: 'target-round',
                    time: {
                        month: targetFundingTime.month || 1,
                        year: targetFundingTime.year || currentYear
                    },
                    type: targetRound,
                    milestones: '',
                    preMoneyValuation: 0,
                    investmentAmount: targetAmount,
                    isTargetRound: true
                });
            }

            const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);
            const isDataEmpty = (data) =>
                !data ||
                Object.keys(data).length === 0 ||
                Object.values(data).every(value =>
                    value === '' ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'object' && Object.keys(value).length === 0)
                );

            // Process and validate funding rounds
            const processFundingRounds = (rounds = []) => {
                if (!Array.isArray(rounds)) return defaultFundingRounds;

                return rounds.map(round => {
                    if (!round) return null;  // Skip null/undefined entries

                    // Ensure time object is properly structured
                    const time = {
                        month: round.time?.month || 1,
                        year: round.time?.year || foundingYear
                    };

                    return {
                        id: round.id || Date.now().toString(),
                        time,
                        type: round.type || 'Other',
                        milestones: round.milestones || '',
                        preMoneyValuation: Number(round.preMoneyValuation) || 0,
                        investmentAmount: Number(round.investmentAmount) || 0,
                        isInitialCapital: round.isInitialCapital || false,
                        isTargetRound: round.isTargetRound || false
                    };
                }).filter(Boolean);  // Remove any null entries
            };

            // Process and validate cashflow data
            const processCashflow = (cashflow = []) => {
                return cashflow.map(entry => ({
                    year: Number(entry.year) || foundingYear,
                    expenses: Number(entry.expenses) || 0,
                    revenue: Number(entry.revenue) || 0,
                    investments: Number(entry.investments) || 0,
                    profitLoss: Number(entry.profitLoss) || 0,
                    cashflow: Number(entry.cashflow) || 0,
                    capital: Number(entry.capital) || 0
                }));
            };

            const defaultData = {
                cashflow: initialCashflow,
                currency: fundingCurrency || 'EUR',
                fundingRounds: defaultFundingRounds
            };

            if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
                setDataStatus('draft');
                const processedData = {
                    ...defaultData,  // Start with defaults
                    ...relevantDraftData,
                    currency: fundingCurrency,
                    cashflow: relevantDraftData.cashflow?.length
                        ? processCashflow(relevantDraftData.cashflow)
                        : initialCashflow,
                    fundingRounds: relevantDraftData.fundingRounds?.length
                        ? processFundingRounds(relevantDraftData.fundingRounds)
                        : defaultFundingRounds,
                };
                setInitialValues(processedData);
            } else if (!isDataEmpty(relevantPublishedData)) {
                setDataStatus('published');
                const processedData = {
                    ...relevantPublishedData,
                    currency: fundingCurrency,
                    cashflow: relevantPublishedData.cashflow?.length
                        ? processCashflow(relevantPublishedData.cashflow)
                        : initialCashflow,
                    fundingRounds: relevantPublishedData.fundingRounds?.length
                        ? processFundingRounds(relevantPublishedData.fundingRounds)
                        : defaultFundingRounds,
                };
                setInitialValues(processedData);
            } else {
                const processedData = {
                    cashflow: initialCashflow || [],
                    currency: fundingCurrency || 'EUR',
                    fundingRounds: defaultFundingRounds || [],
                };
                setInitialValues(processedData);
            }
        }
    }, [userData, currentYear, foundingYear, fundingCurrency, targetRound, targetAmount]);

    const processValuationData = (cashflowData, fundingRounds) => {
        // Ensure the data exists
        if (!cashflowData?.length || !fundingRounds?.length) {
            return {
                processedCashflow: [],
                processedFundingRounds: []
            };
        }

        // Process cashflow data to ensure all required fields
        const processedCashflow = cashflowData.map(row => ({
            year: row.year,
            revenue: row.revenue || 0,
            expenses: row.expenses || 0,
            investments: row.investments || 0
        }));

        // Process funding rounds data to ensure all required fields
        const processedFundingRounds = fundingRounds.map(round => ({
            time: {
                month: round.time.month,
                year: round.time.year
            },
            preMoneyValuation: round.preMoneyValuation || 0,
            investmentAmount: round.investmentAmount || 0,
            type: round.type || '',
        }));

        return {
            processedCashflow,
            processedFundingRounds
        };
    };

    const validationSchema = Yup.object({
        cashflow: Yup.array().of(
            Yup.object({
                year: Yup.number().required(),
                expenses: Yup.number().min(0),
                revenue: Yup.number().min(0),
                investments: Yup.number().min(0)
            })
        ),
        fundingRounds: Yup.array().of(
            Yup.object({
                time: Yup.object({
                    month: Yup.number().min(1).max(12).required(),
                    year: Yup.number().min(1900).max(2050).required()
                }),
                type: Yup.string().required(),
                milestones: Yup.string(),
                preMoneyValuation: Yup.number().min(0).required(),
                investmentAmount: Yup.number().min(0).required()
            })
        )
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // Wrap the values in a 'captable' object to match the expected structure
            await handleSubmit({ responses: { fundingRounds: values } });
            setNotification({
                show: true,
                type: 'success',
                message: t('Data saved and published successfully!')
            });
            setDataStatus('published');

            // Also save as draft to maintain consistency
            await handleDraftSubmit({ responses: { fundingRounds: values } });

            // Only navigate after both operations are complete
            setTimeout(() => {
                navigate('/funding/termSheet');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Error saving data'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            // Maintain the same nested structure for drafts
            await handleDraftSubmit({ responses: { fundingRounds: values } });
            setNotification({
                show: true,
                type: 'success',
                message: t('Draft saved successfully')
            });
            setDataStatus('draft');
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Error saving draft'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>

            <h2 className="text-primary-color">{t('KnowYourselfWizard.financials')}</h2>
            <p className="mb-8">{t('KnowYourselfWizard.fundingRoundsIntro')}</p>

            {/* Funding Rounds Recommendations */}
            <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                    <div className="flex items-center">
                        <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                        <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                        className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">
                        <div className="space-y-6 text-gray-700">
                            {/* Future Cashflow Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('fundingRoundsRecommendations.futureCashflow.title')}
                                </h4>
                                <p className="text-sm">{t('fundingRoundsRecommendations.futureCashflow.content1')}</p>
                                <p className="text-sm">{t('fundingRoundsRecommendations.futureCashflow.content2')}</p>
                                <p className="text-sm">{t('fundingRoundsRecommendations.futureCashflow.content3')}</p>
                            </div>
                            {/* Funding Plan Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('fundingRoundsRecommendations.fundingPlan.title')}
                                </h4>
                                <p className="text-sm">{t('fundingRoundsRecommendations.fundingPlan.content1')}</p>
                                <p className="text-sm">{t('fundingRoundsRecommendations.fundingPlan.content2')}</p>
                            </div>
                            {/* Valuation Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('fundingRoundsRecommendations.valuation.title')}
                                </h4>
                                <p className="text-sm">{t('fundingRoundsRecommendations.valuation.content1')}</p>
                                <p className="text-sm">{t('fundingRoundsRecommendations.valuation.content2')}</p>
                                <p className="text-sm">{t('fundingRoundsRecommendations.valuation.content3')}</p>
                            </div>
                            {/* Iterative Refinement Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('fundingRoundsRecommendations.iterative.title')}
                                </h4>
                                <p className="text-sm">{t('fundingRoundsRecommendations.iterative.content')}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue, isSubmitting, validateForm }) => {
                    const handleSubmit = async (e) => {
                        e.preventDefault();
                        const errors = await validateForm(values);

                        if (Object.keys(errors).length === 0) {
                            onSubmit(values, { setSubmitting: () => { } });
                        } else {
                            const errorMessages = [];

                            // Handle cashflow errors
                            if (errors.cashflow) {
                                errors.cashflow.forEach((yearError, index) => {
                                    if (typeof yearError === 'object') {
                                        const year = values.cashflow[index]?.year;
                                        Object.entries(yearError).forEach(([field, message]) => {
                                            const fieldTranslations = {
                                                expenses: t('KnowYourselfWizard.expenses'),
                                                revenue: t('KnowYourselfWizard.revenue'),
                                                investments: t('KnowYourselfWizard.investments')
                                            };
                                            errorMessages.push(`${t('KnowYourselfWizard.year')} ${year} - ${fieldTranslations[field]}: ${message}`);
                                        });
                                    }
                                });
                            }

                            // Handle funding rounds errors
                            if (errors.fundingRounds) {
                                errors.fundingRounds.forEach((roundError, index) => {
                                    if (typeof roundError === 'object') {
                                        const roundNumber = index + 1;
                                        Object.entries(roundError).forEach(([field, message]) => {
                                            if (field === 'time') {
                                                Object.entries(message).forEach(([timeField, timeMessage]) => {
                                                    errorMessages.push(
                                                        `${t('KnowYourselfWizard.fundingRound')} ${roundNumber} - ${timeField}: ${timeMessage}`
                                                    );
                                                });
                                            } else {
                                                const fieldTranslations = {
                                                    type: t('KnowYourselfWizard.roundType'),
                                                    milestones: t('KnowYourselfWizard.milestones'),
                                                    preMoneyValuation: t('KnowYourselfWizard.preMoneyValuation'),
                                                    investmentAmount: t('KnowYourselfWizard.investmentAmount')
                                                };
                                                errorMessages.push(
                                                    `${t('KnowYourselfWizard.fundingRound')} ${roundNumber} - ${fieldTranslations[field]}: ${message}`
                                                );
                                            }
                                        });
                                    }
                                });
                            }

                            setNotification({
                                show: true,
                                type: 'error',
                                message: t('Failed to save data due to validation errors:'),
                                errors: errorMessages
                            });
                        }
                    };

                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="flex flex-col gap-y-10">
                                <div>
                                    <h2 className="text-xl font-semibold text-gray-900 mb-0 mt-4">
                                        {t('Cashflow Overview')}
                                    </h2>
                                    <CashflowTable
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        currency={values.currency}
                                        foundingYear={foundingYear}
                                        currentYear={currentYear}
                                    />
                                </div>
                                <div>
                                    <div>
                                        <h2 className="text-xl font-semibold text-gray-900 mb-0 mt-4">
                                            {t('Financials Overview')}
                                        </h2>
                                        <CashflowChart
                                            data={values.cashflow}
                                            currency={values.currency}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <h2 className="text-xl font-semibold text-gray-900 mb-0 mt-4">
                                        {t('Funding Rounds')}
                                    </h2>
                                    <FundingRoundsTable
                                        values={{
                                            ...values,
                                            fundingRounds: values.fundingRounds || []  // Ensure it's never undefined
                                        }}
                                        setFieldValue={setFieldValue}
                                        currency={values.currency || 'EUR'}
                                        targetRoundData={{
                                            targetRound: targetRound || '',
                                            targetAmount: targetAmount || 0,
                                            targetTime: userData?.entity?.know_yourself_response?.published?.fundingTime ||
                                                userData?.entity?.know_yourself_response?.draft?.fundingTime || {
                                                month: new Date().getMonth() + 1,
                                                year: currentYear
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    {values.cashflow?.length > 0 && values.fundingRounds?.length > 0 && (
                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-900 mb-0 mt-4">
                                                {t('Valuation Overview')}
                                            </h2>
                                            <ValuationChart
                                                cashflowData={values.cashflow}
                                                fundingRounds={values.fundingRounds.map(round => ({
                                                    ...round,
                                                    time: {
                                                        month: round.time?.month || 1,
                                                        year: round.time?.year || foundingYear
                                                    }
                                                }))}
                                                currency={values.currency}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="flex justify-end gap-x-4">
                                    <button
                                        type="button"
                                        onClick={() => navigate('/funding')}
                                        className="py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    >
                                        {t('cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                        disabled={isSubmitting}
                                        className="px-4 py-2 text-sm font-semibold text-white bg-gray-500 rounded-md hover:bg-gray-600"
                                    >
                                        {t('Save as Draft')}
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => onSubmit(values, { setSubmitting: () => { } })}
                                        disabled={isSubmitting}
                                        className="px-4 py-2 text-sm font-semibold text-white bg-primary-color rounded-md hover:bg-secondary-color"
                                    >
                                        {isSubmitting ? t('Saving...') : t('Save')}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            </div>
        </>
    );
};

export default KnowYourselfWizardFundingRounds;