import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleSection from './CollapsibleSection';
import CategoryItem from './CategoryItem';

const AssetCategoriesSection = ({
  categories,
  selectedCategory,
  handleCategorySelect,
  expandedSections,
  toggleSection,
  loading,
  getCategoryIcon,
  userLang = 'en',
  getCategoryValue,
  disabled = false
}) => {
  const { t } = useTranslation();
  
  if (loading && categories.length === 0) {
    return (
      <div className="p-3 text-center">
        <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 secondary-color-600 mr-2"></div>
        <span className="text-sm text-gray-600">{t("Loading...")}</span>
      </div>
    );
  }

  // Get selected value with localization support
  const selectedValue = selectedCategory ? getCategoryValue(selectedCategory) : null;

  return (
    <CollapsibleSection
      title={t('Asset Category')}
      isExpanded={expandedSections.categories}
      onToggle={() => toggleSection('categories')}
      selectedValue={selectedValue}
      disabled={disabled}
    >
      {categories.map(category => (
        <CategoryItem
          key={category.id}
          category={category}
          selected={selectedCategory?.id === category.id}
          onSelect={handleCategorySelect}
          getIcon={getCategoryIcon}
          userLang={userLang}
          getCategoryValue={getCategoryValue}
        />
      ))}
      
      {/* Other option */}
      <CategoryItem
        category={{ 
          id: 'other', 
          asset_categories: userLang === 'de' ? 'Andere' : 'Other',
          wertkategorie: userLang === 'de' ? 'Andere' : 'Other',
          description: userLang === 'de' ? 'Benutzerdefinierte Asset-Kategorie' : 'Custom asset category',
          beschreibung: userLang === 'de' ? 'Benutzerdefinierte Asset-Kategorie' : 'Custom asset category'
        }}
        selected={selectedCategory?.id === 'other'}
        onSelect={handleCategorySelect}
        userLang={userLang}
        getCategoryValue={getCategoryValue}
      />
    </CollapsibleSection>
  );
};

export default AssetCategoriesSection;