import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleIcon,
  XCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  getCategoryIcon,
  getComplianceStatus,
  getDpaStatusBadge,
  getCountryComplianceStatus,
  getCountryName,
} from './transfersHelpers';
import InternalRecipientDetails from './InternalRecipientDetails';
import ExternalRecipientDetails from './ExternalRecipientDetails';
import { useUserData } from '../../../hooks/useUserData';

const RecipientRow = ({
  type,
  transfer,
  recipientData,
  index,
  formik,
  isExpanded,
  isEditing,
  toggleRow,
  toggleEditMode,
  countries,
  gdprCountries,
  isThirdCountry,
  processorRoles,
  dpaStatusOptions,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const complianceStatus = getComplianceStatus(transfer, type, countries);

  const { userData } = useUserData();
  const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

  // Determine country compliance status (GDPR, GDPR-like, third country)
  const countryComplianceStatus = transfer.country
    ? getCountryComplianceStatus(transfer.country, countries)
    : null;

  // Get localized data type
  const getLocalizedDataType = () => {
    if (!recipientData) return t('DataPrivacyWizard.notSpecified');

    if (userLang === 'de') {
      return recipientData.werttyp || recipientData.asset_type || t('DataPrivacyWizard.notSpecified');
    }

    return recipientData.asset_type || recipientData.werttyp || t('DataPrivacyWizard.notSpecified');
  };

  const countryName = getCountryName(transfer.country, countries) || '';
  const translationKey = `Countries.${countryName}`;
  const translatedCountryName = countryName ? t(translationKey) : '';

  return (
    <React.Fragment>
      <tr className={isExpanded ? 'bg-gray-50' : ''}>
        {/* Recipient Column */}
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {recipientData.category_icon ? (
                <img
                  src={getCategoryIcon({ icons: recipientData.category_icon })}
                  alt=""
                  className="h-10 w-10"
                />
              ) : (
                <div className="h-10 w-10 rounded-full bg-gray-200"></div>
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {transfer.name}
              </div>
              <div className="text-sm text-gray-500">
                {getLocalizedDataType()}
              </div>
            </div>
          </div>
        </td>

        {/* External Only - Processor Role Column */}
        {type === 'external' && (
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {processorRoles?.find(role => role.value === transfer.processorRole)?.label ||
                t('DataPrivacyWizard.notSpecified')}
            </div>
          </td>
        )}

        {/* Connected Activities (Internal) or Country (External) Column */}
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {transfer.multipleCountries ? (
              <div>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {t('DataPrivacyWizard.multipleCountries')}
                </span>
                {transfer.thirdCountries && (
                  <div className="mt-1">
                    <span className="font-medium text-amber-600 text-xs">
                      {t('DataPrivacyWizard.includesThirdCountries')}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {translatedCountryName}
                </div>

                {transfer.country && countryComplianceStatus && (
                  <div className="mt-0">
                    {countryComplianceStatus === 'gdpr' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'gdpr-like' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprEquivalentCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'third-country' && (
                      <span className="font-medium text-amber-600 text-xs">
                        {t('DataPrivacyWizard.nonGdprCountry')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </td>

        {/* Compliance Status Column */}
        <td className="px-6 py-4 whitespace-nowrap">
          {complianceStatus === 'compliant' && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              <CheckCircleIcon className="h-4 w-4 mr-1" />
              {t('DataPrivacyWizard.compliant')}
            </span>
          )}
          {complianceStatus === 'non-compliant' && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              <XCircleIcon className="h-4 w-4 mr-1" />
              {t('DataPrivacyWizard.nonCompliant')}
            </span>
          )}
          {complianceStatus === 'pending' && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              {t('DataPrivacyWizard.pending')}
            </span>
          )}
        </td>

        {/* Actions Column */}
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
          <button
            type="button"
            onClick={toggleRow}
            className="text-primary-color hover:text-secondary-color mr-3"
          >
            {isExpanded ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </button>
        </td>
      </tr>

      {/* Expanded Row for Details */}
      {isExpanded && (
        <tr>
          <td colSpan={type === 'internal' ? 4 : 5} className="px-6 py-4 bg-white">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 flex items-center">
                {t('DataPrivacyWizard.transferDetails')}
              </h3>
              <button
                type="button"
                onClick={toggleEditMode}
                className={`${isEditing ? 'bg-secondary-color hover:opacity-80' :
                  'bg-secondary-color hover:opacity-80'} text-white py-1 px-3 rounded-md`}
              >
                {isEditing ? t('DataPrivacyWizard.done') : t('DataPrivacyWizard.edit')}
              </button>
            </div>

            {type === 'internal' ? (
              <InternalRecipientDetails
                transfer={transfer}
                recipientData={recipientData}
                index={index}
                formik={formik}
                isEditing={isEditing}
                countries={countries}
                gdprCountries={gdprCountries}
                isThirdCountry={isThirdCountry}
                toggleDrawer={toggleDrawer}
                userLang={userLang}
              />
            ) : (
              <ExternalRecipientDetails
                transfer={transfer}
                recipientData={recipientData}
                index={index}
                formik={formik}
                isEditing={isEditing}
                countries={countries}
                gdprCountries={gdprCountries}
                isThirdCountry={isThirdCountry}
                processorRoles={processorRoles}
                dpaStatusOptions={dpaStatusOptions}
                toggleDrawer={toggleDrawer}
                userLang={userLang}
              />
            )}
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default RecipientRow;