import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon, PaintBrushIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import Notification from '../../../components/Notification';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import { saveData } from '../../../components/wizard_components/SaveData';
import CreateSystemTaskButton from '../../../components/CreateSystemTaskButton';

const StartupWizardOverview = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizardData, updateWizardData, wizardName, refetchData } = useWizard();

  console.log('Initial wizardData:', wizardData);

  const pages = useMemo(() => [
    { id: "legalForm", name: t('StartupWizard.legalForm'), fields: ['legalForm', 'legalFormReason'] },
    { id: "company", name: t('StartupWizard.company'), fields: ['companyName', 'companyAddress', 'shareCapital'] },
    { id: "founders", name: t('StartupWizard.founders'), fields: ['founders'] },
    { id: "articlesOfAssociation", name: t('StartupWizard.articlesOfAssociation'), fields: ['articlesOfAssociation'] },
    { id: "shareholdersAgreement", name: t('StartupWizard.shareholdersAgreement'), fields: ['shareholdersAgreement'] },
    { id: "notaryAndBank", name: t('StartupWizard.notaryAndBank'), fields: ['notaryAndBankDetails'] },
    { id: "final", name: t('StartupWizard.final'), fields: ['final'] },
  ], [t]);

  const isValueEmpty = useCallback((value) => {
    console.log('Checking if value is empty:', value);
    if (value === undefined || value === null || value === '') return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
  }, []);

  const isPageEmpty = useCallback((data) => {
    console.log('Checking if page is empty:', data);
    if (!data) return true;
    return Object.values(data).every(isValueEmpty);
  }, [isValueEmpty]);

  const getStepStatus = useCallback((page) => {
    const publishedData = wizardData.published[page.id] || {};
    const draftData = wizardData.draft[page.id] || {};

    console.log(`Detailed data for page ${page.id}:`);
    console.log('Published data:', JSON.stringify(publishedData, null, 2));
    console.log('Draft data:', JSON.stringify(draftData, null, 2));

    const isPublishedEmpty = isPageEmpty(publishedData);
    const isDraftEmpty = isPageEmpty(draftData);
    const isDraftDifferent = JSON.stringify(publishedData) !== JSON.stringify(draftData);

    console.log('Is published empty:', isPublishedEmpty);
    console.log('Is draft empty:', isDraftEmpty);
    console.log('Is draft different:', isDraftDifferent);

    if (isPublishedEmpty && isDraftEmpty) {
      return 'empty';
    } else if (isDraftDifferent) {
      return 'draft';
    } else {
      return 'complete';
    }
  }, [wizardData, isPageEmpty]);

  const handleDelete = useCallback((page) => {
    console.log('Attempting to delete page:', page);
    if (page.status !== 'empty') {
      setPageToDelete(page);
      setDeleteModalOpen(true);
    }
  }, []);

  const confirmDelete = useCallback(async () => {
    if (pageToDelete) {
      console.log('Confirming delete for page:', pageToDelete);
      try {
        const resetData = null;

        const result = await saveData(wizardName, pageToDelete.id, resetData);
        console.log('Delete result:', result);
        if (result.success) {
          updateWizardData(pageToDelete.id, resetData, false);
          updateWizardData(pageToDelete.id, resetData, true);

          console.log('Refetching data after delete');
          await refetchData();

          setNotification({
            show: true,
            type: 'success',
            message: t('Data reset successfully')
          });

          setTimeout(() => {
            setNotification(prev => ({ ...prev, show: false }));
          }, 3000);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error('Error resetting data:', error);
        setNotification({
          show: true,
          type: 'error',
          message: t('Error resetting data. Please try again.')
        });
      }
      setDeleteModalOpen(false);
      setPageToDelete(null);
    }
  }, [pageToDelete, wizardName, updateWizardData, refetchData, t]);

  const renderStepIcon = useCallback((status) => {
    console.log('Rendering step icon for status:', status);
    switch (status) {
      case 'complete':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        );
      case 'draft':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-[rgb(254,249,195)]">
            <PaintBrushIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </span>
        );
      default:
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500 text-xl"></span>
          </span>
        );
    }
  }, []);

  useEffect(() => {
    console.log('Recalculating page statuses');
    const newPageStatuses = pages.map(page => ({
      ...page,
      status: getStepStatus(page)
    }));
    console.log('New page statuses:', newPageStatuses);
    setPageStatuses(newPageStatuses);
  }, [wizardData, pages, getStepStatus]);

  const [pageStatuses, setPageStatuses] = useState([]);

  console.log('Final page statuses:', pageStatuses);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
        <Notification
          show={notification.show}
          setShow={(show) => setNotification(prev => ({ ...prev, show }))}
          type={notification.type}
          message={notification.message}
        />
        <h1 className="text-2xl font-bold mb-4">{t('StartupWizard.overview')}</h1>
        <p className="text-normal mb-8">{t('startupText')}</p>
        <div className="bg-white shadow rounded-lg">
          <nav aria-label="Progress">
            <ol role="list">
              {pageStatuses.map((page, pageIdx) => (
                <li key={page.id} className={pageIdx !== pageStatuses.length - 1 ? 'border-b border-gray-200' : ''}>
                  <div className="w-full text-left md:px-6 px-3 md:py-5 py-3 flex items-center justify-between">
                    <button
                      onClick={() => navigate(`/startup-wizard/${page.id}`)}
                      className="flex items-center flex-grow"
                    >
                      <span className="flex-shrink-0">
                        {renderStepIcon(page.status)}
                      </span>
                      <span className="md:ml-6 ml-3 flex flex-col text-left">
                        <span className="text-sm font-medium text-gray-900">{page.name}</span>
                        <span className="text-sm font-medium text-gray-500">
                          {page.status === 'complete' ? t("StartupWizard.pagePublished") :
                            page.status === 'draft' ? t("StartupWizard.draftOngoing") :
                              t("StartupWizard.noData")}
                        </span>
                      </span>
                    </button>
                    <div className="flex items-center">
                      <CreateSystemTaskButton 
                        taskName={`Complete "${page.name}" in Startup Wizard`}
                        section="startup_wizard"
                        additionalData={{
                          link: `/startup-wizard/${page.id}`
                        }}
                      />
                      <button
                        onClick={() => navigate(`/startup-wizard/${page.id}`)}
                        className="text-primary-color hover:text-secondary-color ml-4 mr-4"
                        aria-label={t("StartupWizard.edit")}
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(page)}
                        className={`text-red-600 hover:text-red-800 ${page.status === 'empty' ? 'opacity-0 cursor-default' : ''}`}
                        aria-label={t("StartupWizard.delete")}
                        disabled={page.status === 'empty'}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>

      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDeleteModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          {t("StartupWizard.deleteData")}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {t("StartupWizard.deleteConfirmation")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={confirmDelete}
                    >
                      {t("StartupWizard.delete")}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      {t("StartupWizard.cancel")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default StartupWizardOverview;
