import React, { useState, useEffect, Fragment } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';

export default function TeamManagement() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [recommendedMembers, setRecommendedMembers] = useState([]);
  const [newMember, setNewMember] = useState({ firstName: '', lastName: '', email: '' });
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isConfirmOwnerModalOpen, setIsConfirmOwnerModalOpen] = useState(false);
  const [selectedMemberForOwnership, setSelectedMemberForOwnership] = useState(null);
  const [sentInvites, setSentInvites] = useState({});

  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      const userData = response.data;

      setTeamMembers(userData.entity.users);
      setCurrentUserId(userData.id);
      setCurrentUserRole(userData.role);

      const founders = userData.entity.know_yourself_response.published.founders || [];
      const keyTeamMembers = userData.entity.know_yourself_response.published.keyTeamMembers || [];

      const allMembers = [...founders, ...keyTeamMembers];

      const recommendedMembers = allMembers.filter(
        (member) => !userData.entity.users.some((user) => user.email === member.email)
      );

      setRecommendedMembers(recommendedMembers);
    } catch (error) {
      console.error('Error fetching team data:', error);
    }
  };

  const inviteMember = async (member, role) => {
    try {
      const response = await axios.post(
        '/api/v1/live_data/invite_member',
        {
          user: {
            first_name: member.firstName,
            last_name: member.lastName,
            email: member.email,
            role: role,
          },
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );

      if (response.status === 200) {
        fetchTeamData();
        setNewMember({ firstName: '', lastName: '', email: '' });
      }
    } catch (error) {
      console.error('Error inviting member:', error);
      // Show error message to the user
    }
  };

  // Update the resendInvitation function
  const resendInvitation = async (userId) => {
    try {
      const response = await axios.post(
        `/api/v1/live_data/resend_invitation/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
      if (response.status === 200) {
        setSentInvites(prev => ({
          ...prev,
          [userId]: true
        }));
      }
    } catch (error) {
      console.error('Error resending invitation:', error);
    }
  };

  // Create a component for the resend button to keep the code DRY
  const ResendInviteButton = ({ userId }) => {
    const buttonDisabled = sentInvites[userId];

    return (
      <span className="text-gray-400">
        (
        <button
          onClick={() => resendInvitation(userId)}
          disabled={buttonDisabled}
          className={`${buttonDisabled
            ? 'text-gray-400 cursor-default'
            : 'text-secondary-color hover:text-secondary-color-dark'
            }`}
        >
          {buttonDisabled ? t('invite_sent') : t('resend_invite')}
        </button>
        )
      </span>
    );
  };

  const deleteMember = async (userId) => {
    try {
      await axios.delete(`/api/v1/live_data/delete_member/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      fetchTeamData();
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const changeRole = async (userId, newRole) => {
    try {
      await axios.put(
        `/api/v1/live_data/change_role/${userId}`,
        {
          role: newRole,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
      fetchTeamData();
    } catch (error) {
      console.error('Error changing role:', error);
    }
  };

  const transferOwnership = async (userId) => {
    try {
      await axios.put(
        `/api/v1/live_data/change_role/${userId}`,
        {
          role: 'owner',
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
      // Demote current user to 'admin' after transferring ownership
      await axios.put(
        `/api/v1/live_data/change_role/${currentUserId}`,
        {
          role: 'admin',
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
      setIsConfirmOwnerModalOpen(false);
      fetchTeamData();
    } catch (error) {
      console.error('Error transferring ownership:', error);
    }
  };

  const AddMemberModal = () => {
    const [localNewMember, setLocalNewMember] = useState({ firstName: '', lastName: '', email: '' });

    useEffect(() => {
      if (isAddMemberModalOpen) {
        setLocalNewMember(newMember);
      }
    }, [isAddMemberModalOpen, newMember]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setLocalNewMember((prev) => ({ ...prev, [name]: value }));
    };

    const handleInvite = (role) => {
      inviteMember(localNewMember, role);
      setIsAddMemberModalOpen(false);
    };

    return (
      <Transition.Root show={isAddMemberModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsAddMemberModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-primary-color">
                        {t('add_team_member')}
                      </Dialog.Title>
                      <div className="mt-4">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                  {t('first_name')}
                                </label>
                                <input
                                  type="text"
                                  name="firstName"
                                  id="firstName"
                                  value={localNewMember.firstName}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                />
                              </div>
                              <div>
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                  {t('last_name')}
                                </label>
                                <input
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  value={localNewMember.lastName}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t('email')}
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={localNewMember.email}
                                onChange={handleInputChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-secondary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color sm:col-start-2"
                      onClick={() => handleInvite('admin')}
                    >
                      {t('invite_as_admin')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => handleInvite('user')}
                    >
                      {t('invite_as_member')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const ConfirmOwnerModal = () => {
    return (
      <Transition.Root show={isConfirmOwnerModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsConfirmOwnerModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/* Modal Panel */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-primary-color">
                        {t('confirm_transfer_ownership')}
                      </Dialog.Title>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">
                          {t('are_you_sure_transfer_ownership_1')}
                          <b> {selectedMemberForOwnership?.first_name} {selectedMemberForOwnership?.last_name} </b>
                          {t('are_you_sure_transfer_ownership_2')}
                        </p>
                        <p className="text-sm font-bold text-red-600 mt-2">{t('you_will_lose_ownership_warning')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => transferOwnership(selectedMemberForOwnership.id)}
                    >
                      {t('confirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setIsConfirmOwnerModalOpen(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (

    <div className="px-4 sm:px-6 lg:mt-12 mt-22 md:mt-22 mb-8">
      <h1 className="text-2xl font-bold lg:mt-0 md:mt-8 mt-20">
        {t('Team')}
      </h1>
      <div className="mt-8 bg-teal-100 border-l-4 border-secondary-color p-2 mb-8">
        <div className="flex">
          <div className="ml-3">
            <p className="text-sm text-secondary-color">
              {t('team.subscriptionDisclaimer')}
            </p>
          </div>
        </div>
      </div>

      {/* Team Members Card */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:px-6">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h2 className="text-lg font-semibold leading-6 text-gray-900 pb-0">{t('team_members')}</h2>
              <p className="text-sm text-gray-700">{t('team_members_description')}</p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => setIsAddMemberModalOpen(true)}
                className="block rounded-md bg-secondary-color px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
              >
                {t('add_member')}
              </button>
            </div>
          </div>
        </div>
        {/* Team Members Section */}
        <div className="border-t border-gray-200 ml-6 mr-6 mb-8">
          {/* Desktop Table - Hidden on mobile */}
          <div className="hidden md:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t('name')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('email')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('role')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('last_login')}
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">{t('actions')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {teamMembers.map((member) => (
                  <tr key={member.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {member.first_name} {member.last_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{member.email}</p>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {member.role === 'owner'
                        ? t('owner')
                        : member.role === 'admin'
                          ? t('admin')
                          : t('member')}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {member.last_otp_at && new Date(member.last_otp_at) > new Date('2024-01-01') ? (
                        new Date(member.last_otp_at).toLocaleString()
                      ) : (
                        <span>
                          {t('never')}{' '}
                          <ResendInviteButton userId={member.id} />
                        </span>
                      )}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      {member.id !== currentUserId && (
                        <>
                          {/* Show 'Make Owner' only to current owner */}
                          {currentUserRole === 'owner' && member.role !== 'owner' && (
                            <button
                              onClick={() => {
                                setSelectedMemberForOwnership(member);
                                setIsConfirmOwnerModalOpen(true);
                              }}
                              className="text-secondary-color hover:text-secondary-color mr-4"
                            >
                              {t('make_owner')}
                            </button>
                          )}

                          {/* Allow role changes except for owner */}
                          {member.role !== 'owner' && (
                            <button
                              onClick={() =>
                                changeRole(member.id, member.role === 'admin' ? 'user' : 'admin')
                              }
                              className="text-secondary-color hover:text-secondary-color mr-4"
                            >
                              {member.role === 'admin' ? t('make_member') : t('make_admin')}
                            </button>
                          )}

                          {/* Allow deletion except for owner */}
                          {member.role !== 'owner' && (
                            <button
                              onClick={() => deleteMember(member.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              {t('delete')}
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Mobile Cards - Shown only on mobile */}
          <div className="md:hidden space-y-4 mb-8">
            {teamMembers.map((member) => (
              <div key={member.email} className="bg-white rounded-lg my-5 shadow p-4 space-y-3">
                <div className="flex justify-between items-start">
                  <h3 className="text-sm font-medium text-gray-900">
                    {member.first_name} {member.last_name}
                  </h3>
                  <div className="text-xs font-medium text-white">
                    <span
                      className={`inline-flex rounded-full px-2 py-1 ${member.role === 'owner'
                        ? 'bg-primary-color'
                        : member.role === 'admin'
                          ? 'bg-secondary-color'
                          : 'bg-gray-500'
                        }`}
                    >
                      {member.role === 'owner'
                        ? t('owner')
                        : member.role === 'admin'
                          ? t('admin')
                          : t('member')}
                    </span>
                  </div>
                </div>

                <div className="text-xs text-gray-500">
                  <div className="flex items-center gap-1">
                    <span className="font-medium">{t('email')}:</span>
                    {member.email}
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <span className="font-medium">{t('last_login')}:</span>
                    {member.last_otp_at && new Date(member.last_otp_at) > new Date('2024-01-01') ? (
                      new Date(member.last_otp_at).toLocaleString()
                    ) : (
                      <span>
                        {t('never')}{' '}
                        <ResendInviteButton userId={member.id} />
                      </span>
                    )}
                  </div>
                </div>

                {member.id !== currentUserId && (
                  <div className="flex flex-wrap gap-2 pt-2 border-t border-gray-200">
                    {currentUserRole === 'owner' && member.role !== 'owner' && (
                      <button
                        onClick={() => {
                          setSelectedMemberForOwnership(member);
                          setIsConfirmOwnerModalOpen(true);
                        }}
                        className="text-xs text-secondary-color hover:text-secondary-color-dark"
                      >
                        {t('make_owner')}
                      </button>
                    )}
                    {member.role !== 'owner' && (
                      <>
                        <button
                          onClick={() =>
                            changeRole(member.id, member.role === 'admin' ? 'user' : 'admin')
                          }
                          className="text-xs text-secondary-color hover:text-secondary-color-dark"
                        >
                          {member.role === 'admin' ? t('make_member') : t('make_admin')}
                        </button>
                        <button
                          onClick={() => deleteMember(member.id)}
                          className="text-xs text-red-600 hover:text-red-900"
                        >
                          {t('delete')}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Recommended Members Card */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-semibold leading-6 text-gray-900 pb-0">{t('recommended_members')}</h2>
          <p className="text-sm text-gray-700">{t('recommended_members_description')}</p>
        </div>
        {/* Recommended Members Section */}
        <div className="border-t border-gray-200 ml-6 mr-6">
          {/* Desktop Table - Hidden on mobile */}
          <div className="hidden md:block overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t('name')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('email')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('role')}
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">{t('actions')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {recommendedMembers.map((person) => (
                  <tr key={person.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {person.firstName} {person.lastName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => inviteMember(person, 'user')}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
                      >
                        {t('invite_as_member')}
                      </button>
                      <button
                        onClick={() => inviteMember(person, 'admin')}
                        className="rounded-md bg-secondary-color px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                      >
                        {t('invite_as_admin')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Mobile Cards - Shown only on mobile */}
          <div className="md:hidden space-y-4 mb-8">
            {recommendedMembers.map((person) => (
              <div key={person.email} className="bg-white rounded-lg shadow p-4 space-y-3">
                <div className="flex justify-between items-start">
                  <h3 className="text-sm font-medium text-gray-900">
                    {person.firstName} {person.lastName}
                  </h3>
                  <div className="text-xs text-gray-500">
                    {person.role}
                  </div>
                </div>

                <div className="text-xs text-gray-500">
                  <div className="flex items-center gap-1">
                    <span className="font-medium">{t('email')}:</span>
                    {person.email}
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 pt-2 border-t border-gray-200">
                  <button
                    onClick={() => inviteMember(person, 'user')}
                    className="text-xs rounded-md bg-white px-2.5 py-1.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {t('invite_as_member')}
                  </button>
                  <button
                    onClick={() => inviteMember(person, 'admin')}
                    className="text-xs rounded-md bg-secondary-color px-2.5 py-1.5 font-semibold text-white shadow-sm hover:bg-secondary-color-dark"
                  >
                    {t('invite_as_admin')}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modals */}
      <AddMemberModal />
      <ConfirmOwnerModal />
    </div>
  );
}