import { useState, useEffect } from 'react';
import axios from '../axios';

export const useSchema = () => {
  const [schema, setSchema] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const response = await axios.get('/api/v1/wizards/know_yourself', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        setSchema(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching schema:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchSchema();
  }, []);

  return { schema, loading, error };
};