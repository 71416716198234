import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';

const KnowYourselfWizardTermSheet = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/termSheet');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [dataStatus, setDataStatus] = useState('new');

    const [initialValues, setInitialValues] = useState({
        boardSeats: 1,
        votingRights: 'simple_majority',
        liquidationPreference: 1,
        participationRights: 'non_participating',
        antiDilution: 'weighted_average',
        dragAlong: true,
        dragAlongThreshold: 75,
        tagAlong: true,
        rightOfFirstRefusal: true,
        informationRights: true,
        vetoRights: [],
        lockupPeriod: 12,
        priorRounds: [] // Will be populated from funding rounds
    });

    const vetoRightOptions = [
        { value: 'sale', label: 'Sale of Company' },
        { value: 'ip', label: 'IP Transactions' },
        { value: 'debt', label: 'Taking on Significant Debt' },
        { value: 'shares', label: 'New Share Issues' },
        { value: 'business', label: 'Major Business Changes' }
    ];

    useEffect(() => {
        if (userData?.entity?.know_yourself_response) {
            const { published, draft } = userData.entity.know_yourself_response;
            const relevantDraftData = draft?.termSheet || {};
            const relevantPublishedData = published?.termSheet || {};

            const isDataEmpty = (data) => {
                if (!data) return true;
                return Object.keys(data).length === 0 || (
                    data.boardSeats === 1 &&
                    data.votingRights === 'simple_majority' &&
                    data.liquidationPreference === 1 &&
                    data.participationRights === 'non_participating' &&
                    data.antiDilution === 'weighted_average' &&
                    data.dragAlong === true &&
                    data.dragAlongThreshold === 75 &&
                    data.tagAlong === true &&
                    data.rightOfFirstRefusal === true &&
                    data.informationRights === true &&
                    data.vetoRights?.length === 0 &&
                    data.lockupPeriod === 12 &&
                    data.priorRounds?.length === 0
                );
            };

            if (!isDataEmpty(relevantDraftData) &&
                JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData)) {
                setDataStatus('draft');
                setInitialValues(relevantDraftData);
            } else if (!isDataEmpty(relevantPublishedData)) {
                setDataStatus('published');
                setInitialValues(relevantPublishedData);
            }
        }
    }, [userData]);

    const validationSchema = Yup.object({
        boardSeats: Yup.number().min(0).max(5).required(),
        votingRights: Yup.string().required(),
        liquidationPreference: Yup.number().min(1).max(3).required(),
        participationRights: Yup.string().required(),
        antiDilution: Yup.string().required(),
        dragAlong: Yup.boolean(),
        dragAlongThreshold: Yup.number().min(50).max(100).test(
            'required-if-drag-along',
            'Required when drag-along is enabled',
            function (value) {
                return !this.parent.dragAlong || value;
            }
        ),
        tagAlong: Yup.boolean(),
        rightOfFirstRefusal: Yup.boolean(),
        informationRights: Yup.boolean(),
        vetoRights: Yup.array().of(Yup.string()),
        lockupPeriod: Yup.number().min(0).max(60)
    });

    const generateTermSheet = (values) => {
        return `TERM SHEET
        
Investment in ${userData?.entity?.name}

1. Investor Rights
   - Board Representation: ${values.boardSeats} seat(s)
   - Voting Rights: ${values.votingRights.replace(/_/g, ' ')}
   - Liquidation Preference: ${values.liquidationPreference}x
   - Participation: ${values.participationRights.replace(/_/g, ' ')}
   
2. Protection Provisions
   - Anti-dilution: ${values.antiDilution.replace(/_/g, ' ')}
   - Drag-along: ${values.dragAlong ? `Yes, at ${values.dragAlongThreshold}%` : 'No'}
   - Tag-along: ${values.tagAlong ? 'Yes' : 'No'}
   - Right of First Refusal: ${values.rightOfFirstRefusal ? 'Yes' : 'No'}
   
3. Veto Rights
   ${values.vetoRights.map(right =>
            `- ${vetoRightOptions.find(o => o.value === right)?.label}`
        ).join('\n   ')}
   
4. Restrictions
   - Lock-up Period: ${values.lockupPeriod} months
   - Information Rights: ${values.informationRights ? 'Full financial and strategic updates' : 'Limited'}`;
    };

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await handleSubmit({ responses: { termSheet: values } });
            setNotification({
                show: true,
                type: 'success',
                message: t('Data saved and published successfully!')
            });
            setDataStatus('published');
            await handleDraftSubmit({ responses: { termSheet: values } });

            setTimeout(() => {
                navigate('/funding');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save data'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: { termSheet: values } });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save draft'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>
            <h2 className="text-primary-color">{t('Term Sheet')}</h2>
            <p className="mb-8">{t('KnowYourselfWizard.termSheetIntro')}</p>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form className="space-y-6">
                        <div className="bg-white shadow rounded-lg p-6 space-y-6">
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                    {t('Investor Rights')}
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Board Seats')}
                                        </label>
                                        <select
                                            value={values.boardSeats}
                                            onChange={(e) => setFieldValue('boardSeats', Number(e.target.value))}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        >
                                            {[0, 1, 2, 3, 4, 5].map(num => (
                                                <option key={num} value={num}>{num}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Voting Rights')}
                                        </label>
                                        <select
                                            value={values.votingRights}
                                            onChange={(e) => setFieldValue('votingRights', e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        >
                                            <option value="simple_majority">{t('Simple Majority')}</option>
                                            <option value="qualified_majority">{t('Qualified Majority (75%)')}</option>
                                            <option value="super_majority">{t('Super Majority (90%)')}</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Liquidation Preference')}
                                        </label>
                                        <select
                                            value={values.liquidationPreference}
                                            onChange={(e) => setFieldValue('liquidationPreference', Number(e.target.value))}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        >
                                            <option value={1}>1x</option>
                                            <option value={1.5}>1.5x</option>
                                            <option value={2}>2x</option>
                                            <option value={3}>3x</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Participation Rights')}
                                        </label>
                                        <select
                                            value={values.participationRights}
                                            onChange={(e) => setFieldValue('participationRights', e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        >
                                            <option value="non_participating">{t('Non-participating')}</option>
                                            <option value="participating">{t('Full participation')}</option>
                                            <option value="capped_participating">{t('Capped participation')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <hr className="border-gray-300" />

                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                    {t('Protection Provisions')}
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Anti-dilution Protection')}
                                        </label>
                                        <select
                                            value={values.antiDilution}
                                            onChange={(e) => setFieldValue('antiDilution', e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        >
                                            <option value="none">{t('None')}</option>
                                            <option value="full_ratchet">{t('Full Ratchet')}</option>
                                            <option value="weighted_average">{t('Weighted Average')}</option>
                                        </select>
                                    </div>

                                    <div>
                                        <div className="flex items-center mb-4">
                                            <input
                                                type="checkbox"
                                                checked={values.dragAlong}
                                                onChange={(e) => setFieldValue('dragAlong', e.target.checked)}
                                                className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                            />
                                            <label className="ml-2 block text-sm text-gray-900">
                                                {t('Drag-along Rights')}
                                            </label>
                                        </div>
                                        {values.dragAlong && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                    {t('Threshold')}
                                                </label>
                                                <select
                                                    value={values.dragAlongThreshold}
                                                    onChange={(e) => setFieldValue('dragAlongThreshold', Number(e.target.value))}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                >
                                                    {[50, 60, 75, 90].map(num => (
                                                        <option key={num} value={num}>{num}%</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={values.tagAlong}
                                            onChange={(e) => setFieldValue('tagAlong', e.target.checked)}
                                            className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                        />
                                        <label className="ml-2 block text-sm text-gray-900">
                                            {t('Tag-along Rights')}
                                        </label>
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={values.rightOfFirstRefusal}
                                            onChange={(e) => setFieldValue('rightOfFirstRefusal', e.target.checked)}
                                            className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                        />
                                        <label className="ml-2 block text-sm text-gray-900">
                                            {t('Right of First Refusal')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <hr className="border-gray-300" />

                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                    {t('Veto Rights')}
                                </h3>
                                <div className="space-y-2">
                                    {vetoRightOptions.map((option) => (
                                        <div key={option.value} className="flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={values.vetoRights.includes(option.value)}
                                                onChange={(e) => {
                                                    const newVetoRights = e.target.checked
                                                        ? [...values.vetoRights, option.value]
                                                        : values.vetoRights.filter(r => r !== option.value);
                                                    setFieldValue('vetoRights', newVetoRights);
                                                }}
                                                className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                            />
                                            <label className="ml-2 block text-sm text-gray-900">
                                                {t(option.label)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <hr className="border-gray-300" />

                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                                    {t('Other Terms')}
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            {t('Lock-up Period (months)')}
                                        </label>
                                        <input
                                            type="number"
                                            min="0"
                                            max="60"
                                            value={values.lockupPeriod}
                                            onChange={(e) => setFieldValue('lockupPeriod', Number(e.target.value))}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={values.informationRights}
                                            onChange={(e) => setFieldValue('informationRights', e.target.checked)}
                                            className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                        />
                                        <label className="ml-2 block text-sm text-gray-900">
                                            {t('Information Rights')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-md mt-8">
                                <h3 className="text-lg font-medium text-gray-900 mb-4">
                                    {t('Generated Term Sheet')}
                                </h3>
                                <pre className="whitespace-pre-wrap text-sm text-gray-700">
                                    {generateTermSheet(values)}
                                </pre>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="button"
                                className="text-sm font-normal leading-6 text-gray-900"
                                onClick={() => navigate('/funding')}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                disabled={isSubmitting}
                                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600"
                            >
                                {t('Save as Draft')}
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
                            >
                                {isSubmitting ? t('saving') : t('save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
        </>
    );
};

export default KnowYourselfWizardTermSheet;