// src/components/wizard_components/DataPrivacyWizard/RiskScenarioList.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XCircleIcon, PlusIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

// Risk scenarios data
const riskScenarios = [
  { id: 'unauthorized_access', key: 'unauthorizedAccess' },
  { id: 'data_breach', key: 'dataBreach' },
  { id: 'excessive_collection', key: 'excessiveCollection' },
  { id: 'unauthorized_sharing', key: 'unauthorizedSharing' },
  { id: 'data_loss', key: 'dataLoss' },
  { id: 'inaccurate_processing', key: 'inaccurateProcessing' },
  { id: 'rights_hindrance', key: 'rightsHindrance' },
  { id: 'beyond_purpose', key: 'beyondPurpose' },
  { id: 'improper_legal_basis', key: 'improperLegalBasis' },
  { id: 'excessive_retention', key: 'excessiveRetention' }
];

// Potential harms
const potentialHarms = [
  { id: 'discrimination', key: 'discrimination' },
  { id: 'identity_theft', key: 'identityTheft' },
  { id: 'financial_loss', key: 'financialLoss' },
  { id: 'reputational_damage', key: 'reputationalDamage' },
  { id: 'confidentiality_loss', key: 'confidentialityLoss' },
  { id: 'social_disadvantage', key: 'socialDisadvantage' },
  { id: 'economic_disadvantage', key: 'economicDisadvantage' },
  { id: 'psychological_impact', key: 'psychologicalImpact' },
  { id: 'physical_harm', key: 'physicalHarm' }
];

// Function to determine risk level
const calculateRiskLevel = (likelihood, severity) => {
  const riskScore = likelihood * severity;
  
  if (riskScore >= 15) return { 
    level: 'high', 
    bgLight: 'bg-red-50', 
    bgSelected: 'bg-red-100', 
    border: 'border-red-400',
    icon: ExclamationCircleIcon,
    iconColor: 'text-red-600'
  };
  if (riskScore >= 8) return { 
    level: 'medium', 
    bgLight: 'bg-yellow-50', 
    bgSelected: 'bg-yellow-100', 
    border: 'border-yellow-400',
    icon: ExclamationCircleIcon,
    iconColor: 'text-yellow-600'
  };
  return { 
    level: 'low', 
    bgLight: 'bg-green-50', 
    bgSelected: 'bg-green-100', 
    border: 'border-green-400',
    icon: CheckCircleIcon,
    iconColor: 'text-green-600'
  };
};

const RiskScenarioList = ({ formik, selectedRiskId, setSelectedRiskId }) => {
  const { t } = useTranslation();
  const [showAddRiskForm, setShowAddRiskForm] = useState(false);
  const [newCustomRisk, setNewCustomRisk] = useState('');

  // Add a risk scenario to the selection
  const handleSelectRisk = (riskId) => {
    // Check if this risk is already selected
    const isAlreadySelected = formik.values.selectedRisks.some(risk => risk.id === riskId);
    
    if (!isAlreadySelected) {
      const newRisk = {
        id: riskId,
        harms: [],
        likelihood: 3, // Default to "possible"
        severity: 3,   // Default to "moderate"
        notes: ''
      };
      
      formik.setFieldValue('selectedRisks', [...formik.values.selectedRisks, newRisk]);
      setSelectedRiskId(riskId);
    } else {
      // If already selected, just focus on it
      setSelectedRiskId(riskId);
    }
  };

  // Remove a risk from the selection
  const handleRemoveRisk = (riskId, e) => {
    e.stopPropagation(); // Prevent triggering the parent click event
    const updatedRisks = formik.values.selectedRisks.filter(risk => risk.id !== riskId);
    formik.setFieldValue('selectedRisks', updatedRisks);
    
    if (selectedRiskId === riskId) {
      setSelectedRiskId(updatedRisks.length > 0 ? updatedRisks[0].id : null);
    }
  };

  // Add a custom risk
  const handleAddCustomRisk = () => {
    if (newCustomRisk.trim()) {
      const customRiskId = `custom_${Date.now()}`;
      
      // Add to risk scenarios
      const newRiskScenario = {
        id: customRiskId,
        name: newCustomRisk,
        isCustom: true
      };
      
      formik.setFieldValue('riskScenarios', [...formik.values.riskScenarios, newRiskScenario]);
      
      // Add to selected risks
      const newRisk = {
        id: customRiskId,
        harms: [],
        likelihood: 3,
        severity: 3,
        notes: '',
        customName: newCustomRisk
      };
      
      formik.setFieldValue('selectedRisks', [...formik.values.selectedRisks, newRisk]);
      setSelectedRiskId(customRiskId);
      setNewCustomRisk('');
      setShowAddRiskForm(false);
    }
  };

  // Get the name of a risk
  const getRiskName = (riskId) => {
    // First check custom risks in formik values
    const customRisk = formik.values.riskScenarios?.find(risk => risk.id === riskId && risk.isCustom);
    if (customRisk) return customRisk.name;
    
    // Then check built-in risks
    const builtInRisk = riskScenarios.find(risk => risk.id === riskId);
    if (builtInRisk) return t(`DataPrivacyWizard.dpia.riskAssessment.risks.${builtInRisk.key}`);
    
    // Return the risk ID if not found
    return riskId;
  };

  // Generate list of risks not yet selected
  const availableRisks = riskScenarios.filter(risk => 
    !formik.values.selectedRisks.some(selectedRisk => selectedRisk.id === risk.id)
  );

  return (
    <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
      <div className="px-4 py-5 bg-gray-50 border-b border-gray-200">
        <h3 className="text-base font-medium text-gray-900">
          {t('DataPrivacyWizard.dpia.riskAssessment.selectRisks')}
        </h3>
      </div>
      
      <div className="p-4">
        {/* Selected risks */}
        {formik.values.selectedRisks && formik.values.selectedRisks.length > 0 && (
          <div className="mb-6">
            <h4 className="font-medium text-sm text-gray-700 mb-3 flex items-center">
              <span className="w-4 h-4 inline-flex items-center justify-center rounded-full bg-primary-color text-white text-xs mr-2">
                {formik.values.selectedRisks.length}
              </span>
              {t('DataPrivacyWizard.dpia.riskAssessment.selectedRisks')}
            </h4>
            <ul className="space-y-2">
              {formik.values.selectedRisks.map(risk => {
                const riskLevel = calculateRiskLevel(risk.likelihood, risk.severity);
                const RiskIcon = riskLevel.icon;
                
                return (
                  <li 
                    key={risk.id} 
                    onClick={() => setSelectedRiskId(risk.id)}
                    className={`rounded-lg transition-all cursor-pointer text-sm
                                ${selectedRiskId === risk.id 
                                  ? `${riskLevel.bgSelected} border ${riskLevel.border}` 
                                  : `${riskLevel.bgLight} border border-white`}`}
                  >
                    <div className="p-3 relative">
                      <button
                        type="button"
                        onClick={(e) => handleRemoveRisk(risk.id, e)}
                        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors"
                        aria-label={t('DataPrivacyWizard.remove')}
                      >
                        <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                      <div className="flex items-center mt-1">
                        <RiskIcon className={`w-5 h-5 ${riskLevel.iconColor} flex-shrink-0`} />
                        <div className="ml-3 flex-grow">
                          <div className="font-medium text-gray-900">{getRiskName(risk.id)}</div>
                          
                          {risk.harms && risk.harms.length > 0 && (
                            <div className="text-xs text-gray-600 mt-1">
                              {risk.harms.slice(0, 2).map((harmId) => {
                                // Find the harm key from the potential harms array
                                const harmKey = potentialHarms.find(harm => harm.id === harmId)?.key || harmId;
                                return (
                                  <div key={harmId} className="text-xs text-gray-600">
                                    {t(`DataPrivacyWizard.dpia.riskAssessment.harms.${harmKey}`)}
                                  </div>
                                );
                              })}
                              {risk.harms.length > 2 && (
                                <div className="text-xs text-gray-500">
                                  {`+${risk.harms.length - 2} ${t('DataPrivacyWizard.dpia.riskAssessment.harms.more')}`}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {/* Available risks */}
        {availableRisks.length > 0 && (
          <div className="mb-4">
            <h4 className="font-medium text-sm text-gray-700 mb-3">
              {t('DataPrivacyWizard.dpia.riskAssessment.availableRisks')}
            </h4>
            <div className="space-y-2">
              {availableRisks.map(risk => (
                <button
                  key={risk.id}
                  type="button"
                  onClick={() => handleSelectRisk(risk.id)}
                  className="w-full p-3 text-left text-sm rounded-md border border-gray-200 hover:bg-gray-50 hover:border-gray-300 transition-all flex items-center"
                >
                  <PlusIcon className="h-4 w-4 text-primary-color flex-shrink-0 mr-2" />
                  <span>{t(`DataPrivacyWizard.dpia.riskAssessment.risks.${risk.key}`)}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Add custom risk */}
        {showAddRiskForm ? (
          <div className="p-4 border border-gray-200 rounded-lg bg-gray-50">
            <h4 className="font-medium text-sm text-gray-700 mb-3">
              {t('DataPrivacyWizard.dpia.riskAssessment.addCustomRisk')}
            </h4>
            <input
              type="text"
              value={newCustomRisk}
              onChange={(e) => setNewCustomRisk(e.target.value)}
              placeholder={t('DataPrivacyWizard.dpia.riskAssessment.enterCustomRisk')}
              className="w-full p-2 border border-gray-300 rounded-md text-sm mb-3 focus:ring-primary-color focus:border-primary-color"
              autoFocus
            />
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={handleAddCustomRisk}
                disabled={!newCustomRisk.trim()}
                className="px-3 py-2 bg-primary-color text-white text-sm rounded-md disabled:opacity-50"
              >
                {t('DataPrivacyWizard.add')}
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowAddRiskForm(false);
                  setNewCustomRisk('');
                }}
                className="px-3 py-2 bg-gray-200 text-gray-700 text-sm rounded-md"
              >
                {t('DataPrivacyWizard.cancel')}
              </button>
            </div>
          </div>
        ) : (
          <button
            type="button"
            onClick={() => setShowAddRiskForm(true)}
            className="w-full p-3 text-center text-sm font-medium rounded-md border border-dashed border-gray-300 text-primary-color hover:bg-gray-50 transition-colors"
          >
            <PlusIcon className="h-4 w-4 inline-block mr-1" />
            {t('DataPrivacyWizard.dpia.riskAssessment.addCustomRisk')}
          </button>
        )}
      </div>
    </div>
  );
};

export default RiskScenarioList;