import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'flowbite-react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';

const RiskControlGlossary = ({ 
  children, 
  riskControls = null
}) => {
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [loadedRiskControls, setLoadedRiskControls] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Method to get the user's language
  const getUserLanguage = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData?.language ? userData.language.slice(0, 2) : 'en';
  };

  const userLang = getUserLanguage();

  // Fetch risk controls if not provided
  useEffect(() => {
    const fetchRiskControls = async () => {
      if (riskControls) {
        setLoadedRiskControls(riskControls);
        return;
      }
      
      try {
        setIsLoading(true);
        const response = await axios.get('/api/v1/static_data/risk_controls');
        setLoadedRiskControls(response.data);
      } catch (error) {
        console.error('Error fetching risk controls:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRiskControls();
  }, [riskControls]);

  // Regex pattern to match risk control IDs (RC and PC prefixed)
  const riskControlPattern = /\b(RC\d+|PC\d+)\b/g;

  const processContent = useCallback(() => {
    if (!containerRef.current || !loadedRiskControls || !Array.isArray(loadedRiskControls)) return;

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE && !node.processed) {
        const fragment = document.createDocumentFragment();
        let lastIndex = 0;
        const text = node.textContent;
        
        let hasReplacements = false;
        let match;

        // Reset regex search
        riskControlPattern.lastIndex = 0;
        
        while ((match = riskControlPattern.exec(text)) !== null) {
          const controlId = match[0];
          const control = loadedRiskControls.find(c => c.id === controlId);
          
          // Only process if we have the control info
          if (control) {
            hasReplacements = true;
            const index = match.index;
            
            if (index > lastIndex) {
              fragment.appendChild(document.createTextNode(text.slice(lastIndex, index)));
            }

            const span = document.createElement('span');
            span.textContent = controlId;
            span.className = 'risk-control-term';
            span.style.borderBottom = '1px dotted black';
            span.style.cursor = 'help';

            // Determine background color based on control type
            let bgColor = '#F0F0F0'; // Default gray
            let textColor = '#000000'; // Default black
            
            if (controlId.startsWith('RC')) {
              bgColor = '#B0E199'; // Green for RC controls
              textColor = '#207425';
            } else if (controlId.startsWith('PC')) {
              bgColor = '#AADEEA'; // Blue for PC controls
              textColor = '#1B7387';
            }

            const popoverContent = (
              <Popover
                trigger="hover"
                placement="top"
                animation="duration-300"
                content={
                  <div className="w-64 text-sm">
                    <div 
                      className="px-3 py-2 border-b border-gray-200 rounded-t-lg flex items-center gap-2"
                      style={{ backgroundColor: bgColor }}
                    >
                      {control.icons && (
                        <img
                          src={`/is_icons/${control.icons}`}
                          alt={control.id}
                          className="h-6 w-6"
                        />
                      )}
                      <p 
                        className="font-semibold text-sm"
                        style={{ color: textColor }}
                      >
                        {userLang === 'de' ? control.control_name_de : control.control_name}
                      </p>
                    </div>
                    <div className="px-3 py-2">
                      <p className="text-gray-700 text-xs">
                        {userLang === 'de' ? control.control_definition_d : control.control_definition}
                      </p>
                    </div>
                  </div>
                }
              >
                <span>{controlId}</span>
              </Popover>
            );

            const root = createRoot(span);
            root.render(popoverContent);

            fragment.appendChild(span);
            lastIndex = index + controlId.length;
          }
        }

        if (hasReplacements) {
          if (lastIndex < text.length) {
            fragment.appendChild(document.createTextNode(text.slice(lastIndex)));
          }
          node.parentNode.replaceChild(fragment, node);
        } else {
          node.processed = true;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && 
                !node.classList.contains('risk-control-term') &&
                !node.tagName.match(/^(BUTTON|INPUT|TEXTAREA|SELECT|OPTION|SCRIPT|STYLE)$/i)) {
        // Skip processing inside form elements and other interactive elements
        Array.from(node.childNodes).forEach(processNode);
      }
    };

    Array.from(containerRef.current.childNodes).forEach(processNode);
  }, [loadedRiskControls, userLang]);

  useEffect(() => {
    if (loadedRiskControls) {
      processContent();
    }
  }, [loadedRiskControls, processContent]);
  
  useEffect(() => {
    // Set up mutation observer to handle dynamic content changes
    const observer = new MutationObserver(() => {
      processContent();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
    };
  }, [processContent]);

  if (isLoading) {
    return <div className="p-4 text-gray-500">{t('RiskControlGlossary.loading', 'Loading glossary...')}</div>;
  }

  return (
    <div ref={containerRef} className="risk-control-glossary">
      {children}
    </div>
  );
};

export default RiskControlGlossary;