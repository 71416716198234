import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'flowbite-react';
import { createRoot } from 'react-dom/client';
import { glossaryTerms } from './constants/glossaryTerms';

const Glossary = ({ children }) => {
  const containerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  // Method to get the user's language
  const getUserLanguage = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData?.language ? userData.language.slice(0, 2) : 'en';
  };

  const userLang = getUserLanguage();

  const processContent = useCallback(() => {
    if (containerRef.current) {
      const processNode = (node) => {
        // Skip processing for editor content
        if (
          node.nodeType === Node.ELEMENT_NODE && 
          (
            // Skip Draft.js editor elements
            node.classList.contains('DraftEditor-root') ||
            node.classList.contains('public-DraftEditor-content') ||
            node.classList.contains('editor-class') ||
            node.classList.contains('wrapper-class') ||
            // Also check for ancestors with these classes
            node.closest('.DraftEditor-root') ||
            node.closest('.public-DraftEditor-content') ||
            node.closest('.editor-class') ||
            node.closest('.wrapper-class')
          )
        ) {
          return;
        }

        if (node.nodeType === Node.TEXT_NODE && !node.processed) {
          const fragment = document.createDocumentFragment();
          let lastIndex = 0;
          const text = node.textContent;
          const fallbackLang = i18n.language || 'en';
          const currentLang = userLang;
          const terms = Object.keys(glossaryTerms[currentLang] || glossaryTerms[fallbackLang] || {});

          let hasReplacements = false;

          terms.forEach(term => {
            const regex = new RegExp(`\\b${term}\\b`, 'gi');
            let match;
            while ((match = regex.exec(text)) !== null) {
              hasReplacements = true;
              const index = match.index;
              if (index > lastIndex) {
                fragment.appendChild(document.createTextNode(text.slice(lastIndex, index)));
              }

              const span = document.createElement('span');
              span.textContent = match[0];
              span.className = 'glossary-term';
              span.style.borderBottom = '1px dotted black';
              span.style.cursor = 'help';

              const popoverContent = (
                <Popover
                  trigger="hover"
                  placement="top"
                  animation="duration-300"
                  content={
                    <div className="w-64 text-xs">
                      <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                        <p className="font-semibold text-gray-900 dark:text-white">{term}</p>
                      </div>
                      <div className="px-3 py-2">
                        <p className="font-normal text-sm">
                          {(glossaryTerms[currentLang] && glossaryTerms[currentLang][term]) ||
                            (glossaryTerms[fallbackLang] && glossaryTerms[fallbackLang][term]) ||
                            'Definition not available'}
                        </p>
                      </div>
                    </div>
                  }
                >
                  <span>{match[0]}</span>
                </Popover>
              );

              const root = createRoot(span);
              root.render(popoverContent);

              fragment.appendChild(span);
              lastIndex = index + match[0].length;
            }
          });

          if (hasReplacements) {
            if (lastIndex < text.length) {
              fragment.appendChild(document.createTextNode(text.slice(lastIndex)));
            }
            node.parentNode.replaceChild(fragment, node);
          } else {
            node.processed = true;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE && !node.classList.contains('glossary-term')) {
          Array.from(node.childNodes).forEach(processNode);
        }
      };

      Array.from(containerRef.current.childNodes).forEach(processNode);
    }
  }, [i18n.language]);

  useEffect(() => {
    const processInterval = setInterval(() => {
      processContent();
    }, 1000); // Re-process every second

    return () => clearInterval(processInterval);
  }, [processContent]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      processContent();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
    };
  }, [containerRef, processContent]);


  return <div ref={containerRef}>{children}</div>;
};

export default Glossary;