// src/constants/glossaryTerms.js

export const glossaryTerms = {
    'en': {
      'Pitch deck': 'A presentation used to showcase a business idea to potential investors.',
      'Ordinary resolutions': 'Decisions made by the shareholders of a company that require a simple or smaller majority vote, because they are routine.',
      'Extraordinary resolutions': 'Decisions made by the shareholders of a company that require a higher majority vote than ordinary resolutions, because they are more significant.',
      'Call-option': 'An agreement that gives the buyer (e.g. other existing shareholders) the preferential right to buy an asset (generally shares of the company) at a specified price within a specific time period.',
      'Handelsregister': 'The German commercial register is the public directory that contains information about companies and their legal representatives.',
      'VESOP': 'A virtual employee stock option plan (VESOP) is a plan that allows employees to earn virtual shares of the company.',
      'backing of shares': 'Every virtual share must be backed by a real share in the company to ensure that the virtual share has value.',
      'PII': 'Personally Identifiable Information (PII) is any information that can be used to identify an individual.',
      'GPDR': 'The General Data Protection Regulation (GDPR) is a regulation in EU law on data protection and privacy in the European Union and the European Economic Area.',
      'DSGVO': 'The General Data Protection Regulation (GDPR, in german: DSGVO) is a regulation in EU law on data protection and privacy in the European Union and the European Economic Area.',
      'PIPEDA': 'The Personal Information Protection and Electronic Documents Act (PIPEDA) is a Canadian law relating to data privacy.',
      'FISMA': 'The Federal Information Security Management Act (FISMA) is a United States federal law that defines a comprehensive framework to protect government information, operations and assets against data breaches.',
      'implicit consent is not sufficient': 'Consent to process this personal data must be voluntary and explicit, meaning it must be given expressly in relation to that data.',
      'ISO 27001': 'ISO 27001 is an international standard on how to manage information security. It details requirements for establishing, implementing, maintaining and continually improving an information security management system (ISMS).',
      'ISO 27002': 'ISO 27002 is an international standard that provides guidelines and general principles for initiating, implementing, maintaining, and improving information security management in an organization.',
      'ISO 27701': 'ISO 27701 is an international standard that provides guidance for establishing, implementing, maintaining and continually improving a Privacy Information Management System (PIMS).',
      'ISO 27018': 'ISO 27018 is an international standard that provides guidance for protecting personal data in the cloud.',
      'ISO 29100': 'ISO 29100 is an international standard that provides guidance for privacy information management.',
      'ROPA': 'Record of Processing Activities (ROPA) is a document that contains information about the processing of personal data within an organization.',
      'DPIA': 'Data Protection Impact Assessment (DPIA) is a process to help organizations identify and minimize the data protection risks of a project.',
      'Data Breach': 'A data breach is an incident where unauthorized access to sensitive, protected, or confidential data occurs.',
      'Data Subject': 'A data subject is an individual whose personal data is being processed by an organization.',
      'TOMs': 'Technical and Organizational Measures (TOMs) are security measures that organizations implement to protect personal data.'
    },
    'de': {
      'Pitch Deck': 'Eine Präsentation, die verwendet wird, um eine Geschäftsidee potenziellen Investoren vorzustellen.',
      'Ordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine einfache oder kleinere Mehrheitsentscheidung erfordern, weil sie routinemäßig sind.',
      'Außerordentliche Beschlüsse': 'Entscheidungen, die von den Aktionären eines Unternehmens getroffen werden und eine höhere Mehrheitsentscheidung als ordentliche Beschlüsse erfordern, weil sie bedeutender sind.',
      'Call-Option': 'Eine Vereinbarung, die dem Käufer (z. B. anderen bestehenden Aktionären) das Vorzugsrecht zum Kauf eines Vermögenswerts (in der Regel Aktien des Unternehmens) zu einem festgelegten Preis innerhalb eines bestimmten Zeitraums einräumt.',
      'Handelsregister': 'Das deutsche Handelsregister ist das öffentliche Verzeichnis, das Informationen über Unternehmen und ihre gesetzlichen Vertreter enthält.',
      'VESOP': 'Ein virtueller Mitarbeiteraktienoptionsplan (VESOP) ist ein Plan, der es Mitarbeitern ermöglicht, virtuelle Aktien des Unternehmens zu verdienen.',
      'Hinterlegung der Anteile': 'Jeder virtueller Anteil muss durch einen realen Anteil im Unternehmen abgesichert sein, um sicherzustellen, dass der virtuelle Anteil einen Wert hat.',
      'PII': 'Persönlich Identifizierbare Informationen (PII) bzw. personenbezogene Daten sind Informationen, die verwendet werden können, um eine Person zu identifizieren.',
      'DSGVO': 'Die Datenschutz-Grundverordnung (DSGVO) ist eine Verordnung im EU-Recht zum Datenschutz und zur Privatsphäre in der Europäischen Union und im Europäischen Wirtschaftsraum.',
      'GDPR': 'Die Datenschutz-Grundverordnung (DSGVO, auf Englisch: GDPR) ist eine Verordnung im EU-Recht zum Datenschutz und zur Privatsphäre in der Europäischen Union und im Europäischen Wirtschaftsraum.',
      'PIPEDA': 'Das Gesetz zum Schutz personenbezogener Informationen und elektronischer Dokumente (PIPEDA) ist ein kanadisches Gesetz zum Datenschutz.',
      'FISMA': 'Das Bundesgesetz zum Informationsicherheitsmanagement (FISMA) ist ein US-Bundesgesetz, das einen umfassenden Rahmen zur Sicherung von Regierungsinformationen, -operationen und -vermögenswerten gegen Datenverstöße definiert.',
      'konkludente Zustimmung reicht nicht aus': 'Die Zustimmung zur Verarbeitung dieser personenbezogenen Daten muss freiwillig und explizit, also ausdrücklich im Bezug auf diese Daten, sein.',
      'ISO 27001': 'ISO 27001 ist ein internationaler Standard zur Verwaltung der Informationssicherheit. Er enthält Anforderungen für die Einrichtung, Implementierung, Aufrechterhaltung und kontinuierliche Verbesserung eines Informationssicherheitsmanagementsystems (ISMS).',
      'ISO 27002': 'ISO 27002 ist ein internationaler Standard, der Richtlinien und allgemeine Grundsätze für die Initiierung, Implementierung, Aufrechterhaltung und Verbesserung des Informationssicherheitsmanagements in einer Organisation bietet.',
      'ISO 27701': 'ISO 27701 ist ein internationaler Standard, der Anleitung zur Einrichtung, Implementierung, Aufrechterhaltung und kontinuierlichen Verbesserung eines Datenschutzmanagementsystems (DSMS/PIMS) bietet.',
      'ISO 27018': 'ISO 27018 ist ein internationaler Standard, der Anleitung zum Schutz personenbezogener Daten in der Cloud bietet.',
      'ISO 29100': 'ISO 29100 ist ein internationaler Standard, der Anleitung zur Verwaltung von Datenschutzinformationen bietet.',
      'ROPA': 'Das Verzeichnis von Verarbeitungstätigkeiten (ROPA) ist ein Dokument, das Informationen über die Verarbeitung personenbezogener Daten innerhalb einer Organisation enthält.',
      'VVZ': 'Das Verzeichnis von Verarbeitungstätigkeiten (VVZ) ist ein Dokument, das Informationen über die Verarbeitung personenbezogener Daten innerhalb einer Organisation enthält.',
      'DPIA': 'Eine Datenschutz-Folgenabschätzung (DPIA) ist ein Verfahren, um Organisationen zu helfen, die Risiken für den Datenschutz eines Projekts zu identifizieren und zu minimieren.',
      'DSFA': 'Eine Datenschutz-Folgenabschätzung (DSFA) ist ein Verfahren, um Organisationen zu helfen, die Risiken für den Datenschutz eines Projekts zu identifizieren und zu minimieren.',
      'Datenpanne': 'Eine Datenpanne ist ein Vorfall, bei dem unbefugter Zugriff auf sensible, geschützte oder vertrauliche Daten erfolgt.',
      'Betroffene Person': 'Eine betroffene Person ist eine Person, deren personenbezogene Daten von einer Organisation verarbeitet werden.',
      'TOMs': 'Technische und organisatorische Maßnahmen (TOMs) sind Sicherheitsmaßnahmen, die Organisationen implementieren, um personenbezogene Daten zu schützen.'
    },
  };