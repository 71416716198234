import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccountSetupSuccess = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const loadLordIcon = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.lordicon.com/lusqsztk.js';
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const script = loadLordIcon();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <div className="flex justify-center mb-6">
          <span className="inline-flex text-green-700">
            <lord-icon
              src="https://cdn.lordicon.com/ohcuigqh.json"
              trigger="loop"
              delay="1000"
              colors="primary:#264653,secondary:#2a9d8f"
              style={{ width: '128px', height: '128px' }}
            />
          </span>
        </div>
        
        <h2 className="text-2xl font-bold mb-4 text-gray-900">
          {t('registration_successful')}
        </h2>
        
        <p className="text-gray-600 mb-8">
          {t('welcome_message')}
        </p>

        <div className="flex justify-center">
          <Link
            to="/login"
            className="rounded-md bg-primary-color px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
          >
            {t('go_to_login')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupSuccess;