import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RiskMatrix from './RiskMatrix';
import RiskSummaryTableAfterTreatment from './RiskSummaryTableAfterTreatment';

const DPIARiskReAssessment = ({ 
  formik, 
  toggleDrawer, 
  selectedRisks,
  riskScenarios,
  activityId,
  dpiaIndex,
  treatmentStarted  // new prop to indicate if treatment has started
}) => {
  const { t } = useTranslation();
  const [reassessedRisks, setReassessedRisks] = useState([]);

  // Always call hooks regardless of treatmentStarted state
  useEffect(() => {
    if (treatmentStarted && selectedRisks && selectedRisks.length > 0) {
      // Check if there are already reassessed risks saved in formik
      const existingReassessedRisks = formik.values.activities?.[dpiaIndex]?.reassessedRisks;
      if (existingReassessedRisks && existingReassessedRisks.length > 0) {
        setReassessedRisks(existingReassessedRisks);
      } else {
        // Initialize with copies of the original risks
        setReassessedRisks([...selectedRisks]);
      }
    }
  }, [treatmentStarted, selectedRisks, formik.values.activities, dpiaIndex]);

  // Now, after hooks have been called, conditionally render the UI
  if (!treatmentStarted) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="border-t border-gray-200 my-10"></div>
      <div className="flex justify-between items-center mb-2">
        <h4 className="font-medium text-lg text-gray-900">
          {t('DataPrivacyWizard.dpia.riskReassessment.title', 'Risk Reassessment')}
        </h4>
      </div>

      <div className="mb-6 font-medium text-sm text-gray-700">
          {t('DataPrivacyWizard.dpia.descriptionRiskReassessment')}
        </div>
      
      <RiskSummaryTableAfterTreatment
        selectedRisks={selectedRisks}
        customRisks={riskScenarios}
        onUpdateRiskAssessment={(updatedRisks) => {
          setReassessedRisks(updatedRisks);
          if (dpiaIndex !== -1) {
            formik.setFieldValue(`activities[${dpiaIndex}].reassessedRisks`, updatedRisks);
          }
        }}
      />
      
      {reassessedRisks.length > 0 && (
        <div className="mt-8">
          <h5 className="font-medium text-base text-gray-900 mb-4">
            {t('DataPrivacyWizard.dpia.riskReassessment.matrixAfterTreatment', 'Risk Matrix After Treatment')}
          </h5>
          <RiskMatrix
            selectedRisks={reassessedRisks}
            originalRisks={selectedRisks}
            showMovement={true}
          />
            <RiskReductionSummary 
              originalRisks={selectedRisks} 
              reassessedRisks={reassessedRisks} 
            />
        </div>
      )}
    </div>
  );
};

const RiskReductionSummary = ({ originalRisks, reassessedRisks }) => {
  const { t } = useTranslation();
  
  if (!reassessedRisks || reassessedRisks.length === 0) return null;
  
  const countOriginalHighRisks = originalRisks.filter(
    risk => risk.likelihood * risk.severity >= 15
  ).length;
  
  const countOriginalMediumRisks = originalRisks.filter(risk => {
    const score = risk.likelihood * risk.severity;
    return score >= 8 && score < 15;
  }).length;
  
  const countOriginalLowRisks = originalRisks.filter(
    risk => risk.likelihood * risk.severity < 8
  ).length;
  
  const countReassessedHighRisks = reassessedRisks.filter(
    risk => risk.likelihood * risk.severity >= 15
  ).length;
  
  const countReassessedMediumRisks = reassessedRisks.filter(risk => {
    const score = risk.likelihood * risk.severity;
    return score >= 8 && score < 15;
  }).length;
  
  const countReassessedLowRisks = reassessedRisks.filter(
    risk => risk.likelihood * risk.severity < 8
  ).length;
  
  const originalAvgScore = originalRisks.reduce(
    (sum, risk) => sum + (risk.likelihood * risk.severity), 0
  ) / originalRisks.length;
  
  const reassessedAvgScore = reassessedRisks.reduce(
    (sum, risk) => sum + (risk.likelihood * risk.severity), 0
  ) / reassessedRisks.length;
  
  const reductionPercentage = ((originalAvgScore - reassessedAvgScore) / originalAvgScore * 100).toFixed(1);
  
  return (
    <div>
      {/* Risk Level Summary */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="text-lg font-bold text-red-700">
            {countOriginalHighRisks} → {countReassessedHighRisks}
          </div>
          <div className="text-sm text-red-700">
            {t('DataPrivacyWizard.dpia.riskAssessment.highRisks')}
          </div>
        </div>
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
          <div className="text-lg font-bold text-yellow-700">
            {countOriginalMediumRisks} → {countReassessedMediumRisks}
          </div>
          <div className="text-sm text-yellow-700">
            {t('DataPrivacyWizard.dpia.riskAssessment.mediumRisks')}
          </div>
        </div>
        <div className="bg-green-50 border border-green-200 rounded-lg p-4">
          <div className="text-lg font-bold text-green-700">
            {countOriginalLowRisks} → {countReassessedLowRisks}
          </div>
          <div className="text-sm text-green-700">
            {t('DataPrivacyWizard.dpia.riskAssessment.lowRisks')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DPIARiskReAssessment;