import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import TextField from '../../../components/wizard_components/TextField';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const MarketSizeVisualization = ({ tam, sam, som }) => {
    const maxSize = Math.max(tam || 0, sam || 0, som || 0);
    const tamSize = maxSize > 0 ? Math.sqrt(tam / maxSize) * 300 : 300;
    const samSize = maxSize > 0 ? Math.sqrt(sam / maxSize) * 300 : 200;
    const somSize = maxSize > 0 ? Math.sqrt(som / maxSize) * 300 : 100;

    const samPercentage = tam > 0 ? ((sam / tam) * 100).toFixed(1) : 0;
    const somPercentage = sam > 0 ? ((som / sam) * 100).toFixed(1) : 0;

    return (
        <div className="relative h-96 w-full flex items-center justify-center">
            <div className="relative">
                {/* TAM Circle */}
                <div
                    className="absolute rounded-full bg-secondary-color/20"
                    style={{
                        width: `${tamSize}px`,
                        height: `${tamSize}px`,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <div
                        className="absolute text-sm font-medium text-primary-color text-center w-full"
                        style={{
                            top: `${(tamSize + samSize) / 2}px`
                        }}
                    >
                        TAM
                    </div>
                </div>

                {/* SAM Circle */}
                <div
                    className="absolute rounded-full bg-secondary-color/40"
                    style={{
                        width: `${samSize}px`,
                        height: `${samSize}px`,
                        left: '50%',
                        top: '50%',
                        transform: `translate(-50%, calc(-50% - ${(tamSize - samSize) / 2}px))`
                    }}
                >
                    <div
                        className="absolute text-sm font-medium text-primary-color text-center w-full"
                        style={{
                            top: `${(samSize + somSize) / 2}px`
                        }}
                    >
                        {samPercentage}% of TAM
                    </div>
                </div>

                {/* SOM Circle */}
                <div
                    className="absolute rounded-full bg-secondary-color/60"
                    style={{
                        width: `${somSize}px`,
                        height: `${somSize}px`,
                        left: '50%',
                        top: '50%',
                        transform: `translate(-50%, calc(-50% - ${(tamSize - somSize) / 2}px))`
                    }}
                >
                    <div
                        className="absolute text-sm font-medium text-primary-color text-center w-full"
                        style={{
                            top: `${somSize / 2}px`
                        }}
                    >
                        {somPercentage}% of SAM
                    </div>
                </div>
            </div>
        </div>
    );
};

const NumberInput = ({ label, name, value, onChange, currency, description }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleFocus = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const getCurrencySymbol = (currency) => {
        switch (currency) {
            case 'USD': return '$';
            case 'EUR': return '€';
            case 'JPY': return '¥';
            case 'GBP': return '£';
            case 'AUD': return 'A$';
            case 'BRL': return 'R$';
            case 'CAD': return 'C$';
            case 'CHF': return 'Fr.';
            case 'CNY': return '¥';
            case 'HKD': return 'HK$';
            case 'INR': return '₹';
            case 'KRW': return '₩';
            case 'MXN': return 'Mex$';
            case 'NOK': return 'kr';
            case 'NZD': return 'NZ$';
            case 'RUB': return '₽';
            case 'SEK': return 'kr';
            case 'SGD': return 'S$';
            case 'TRY': return '₺';
            case 'ZAR': return 'R';
            default: return currency;
        }
    };

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
            </label>
            <div className="flex">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                    {getCurrencySymbol(currency)}
                </span>
                {isEditing ? (
                    <input
                        type="number"
                        name={name}
                        value={value || ''}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        step="1000000"
                        className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                    />
                ) : (
                    <input
                        type="text"
                        name={name}
                        value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }).format(value || 0)}
                        onFocus={handleFocus}
                        readOnly
                        className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                    />
                )}
            </div>
            {description && (
                <p className="mt-1 text-sm text-gray-500">{description}</p>
            )}
        </div>
    );
};

const PercentageInput = ({ label, name, value, onChange, description }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleFocus = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
            </label>
            <div className="flex">
                {isEditing ? (
                    <input
                        type="number"
                        name={name}
                        value={value || ''}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        step="0.1"
                        className="block w-full rounded-l-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                    />
                ) : (
                    <input
                        type="text"
                        name={name}
                        value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1
                        }).format(value || 0)}
                        onFocus={handleFocus}
                        readOnly
                        className="block w-full rounded-l-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                    />
                )}
                <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                    %
                </span>
            </div>
            {description && (
                <p className="mt-1 text-sm text-gray-500">{description}</p>
            )}
        </div>
    );
};

const KnowYourselfWizardMarket = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData, loading: userDataLoading } = useUserData();
    const { schema, loading: schemaLoading } = useSchema();
    const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/funding/competition');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('published');
    const [initialValues, setInitialValues] = useState({
        totalAddressableMarket: '',
        serviceableAddressableMarket: '',
        serviceableObtainableMarket: '',
        marketGrowth: '',
        marketDevelopment: '',
        currency: 'USD'
    });

    useEffect(() => {
        if (userData?.entity?.know_yourself_response) {
            const { published, draft } = userData.entity.know_yourself_response;
            const relevantDraftData = {
                totalAddressableMarket: draft?.totalAddressableMarket || '',
                serviceableAddressableMarket: draft?.serviceableAddressableMarket || '',
                serviceableObtainableMarket: draft?.serviceableObtainableMarket || '',
                marketGrowth: draft?.marketGrowth || '',
                marketDevelopment: draft?.marketDevelopment || ''
            };
            const relevantPublishedData = {
                totalAddressableMarket: published?.totalAddressableMarket || '',
                serviceableAddressableMarket: published?.serviceableAddressableMarket || '',
                serviceableObtainableMarket: published?.serviceableObtainableMarket || '',
                marketGrowth: published?.marketGrowth || '',
                marketDevelopment: published?.marketDevelopment || ''
            };

            const isDataEmpty = (data) => {
                return Object.values(data).every(value => value === '');
            };

            const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

            if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
                setDataStatus('draft');
                setInitialValues(relevantDraftData);
            } else if (!isDataEmpty(relevantPublishedData)) {
                setDataStatus('published');
                setInitialValues(relevantPublishedData);
            } else {
                setDataStatus('new');
            }
        }
    }, [userData]);

    if (userDataLoading || schemaLoading) {
        return <div>{t('Loading...')}</div>;
    }

    const validationSchema = Yup.object({
        totalAddressableMarket: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive(t('KnowYourselfWizard.marketSizePositive'))
            .required(t('KnowYourselfWizard.tamRequired')),
        serviceableAddressableMarket: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive(t('KnowYourselfWizard.marketSizePositive'))
            .max(Yup.ref('totalAddressableMarket'), t('KnowYourselfWizard.samLessThanTam'))
            .required(t('KnowYourselfWizard.samRequired')),
        serviceableObtainableMarket: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .positive(t('KnowYourselfWizard.marketSizePositive'))
            .max(Yup.ref('serviceableAddressableMarket'), t('KnowYourselfWizard.somLessThanSam'))
            .required(t('KnowYourselfWizard.somRequired')),
        marketGrowth: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t('KnowYourselfWizard.marketGrowthRequired')),
        marketDevelopment: Yup.string()
            .max(2000, t('KnowYourselfWizard.marketDevelopmentMaxLength'))
            .required(t('KnowYourselfWizard.marketDevelopmentRequired'))
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await handleSubmit({ responses: values });
            setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
            setDataStatus('published');
            await handleDraftSubmit({ responses: values });

            setTimeout(() => {
                navigate('/funding/competition');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: 'Failed to save data',
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: values });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values, isSubmitting, setFieldValue, validateForm }) => (
                    <Form>
                        <div>
                            <h2 className="text-primary-color">{t('Market')}</h2>
                            <p className="mb-8">{t('KnowYourselfWizard.marketIntro')}</p>
                            {/* Recommendations Section */}
                            <div className="bg-gray-50 rounded-b-lg border border-gray-200 mb-6">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setRecommendationsOpen(!recommendationsOpen);
                                    }}
                                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                                >
                                    <div className="flex items-center">
                                        <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                                        <span className="text-primary-color">{t('Recommendations')}</span>
                                    </div>
                                    <svg
                                        className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>

                                {recommendationsOpen && (
                                    <div className="p-4 bg-white rounded-lg border-t">
                                        <div className="space-y-2 text-gray-700">
                                        <h3 className="text-base font-semibold text-primary-color">
                                        {t('marketRecommendations.recommendations.tamExplanation')}
                                            </h3>
                                            <p className="text-sm">{t('marketRecommendations.recommendations.tamGuidance')}</p>
                                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                                {t('marketRecommendations.recommendations.tamResearchTitle')}
                                            </h4>
                                            <ul className="text-sm list-disc list-inside">
                                                <li>{t('marketRecommendations.recommendations.tamResearchTips.industryReports')}</li>
                                                <li>{t('marketRecommendations.recommendations.tamResearchTips.googleResearch')}</li>
                                                <li>{t('marketRecommendations.recommendations.tamResearchTips.talkToExperts')}</li>
                                                <li>{t('marketRecommendations.recommendations.tamResearchTips.checkCompetitors')}</li>
                                            </ul>

                                            <h3 className="text-base font-semibold text-primary-color pt-4">
                                                {t('marketRecommendations.recommendations.samExplanation')}
                                            </h3>
                                            <p className="text-sm">{t('marketRecommendations.recommendations.samGuidance')}</p>
                                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                                {t('marketRecommendations.recommendations.samResearchTitle')}
                                            </h4>
                                            <ul className="text-sm list-disc list-inside">
                                                <li>{t('marketRecommendations.recommendations.samResearchTips.defineTarget')}</li>
                                                <li>{t('marketRecommendations.recommendations.samResearchTips.geographicLimits')}</li>
                                                <li>{t('marketRecommendations.recommendations.samResearchTips.marketAccessibility')}</li>
                                                <li>{t('marketRecommendations.recommendations.samResearchTips.technicalCapabilities')}</li>
                                            </ul>

                                            <h3 className="text-base font-semibold text-primary-color pt-4">
                                                {t('marketRecommendations.recommendations.somExplanation')}
                                            </h3>
                                            <p className="text-sm">{t('marketRecommendations.recommendations.somGuidance')}</p>
                                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                                {t('marketRecommendations.recommendations.somResearchTitle')}
                                            </h4>
                                            <ul className="text-sm list-disc list-inside">
                                                <li>{t('marketRecommendations.recommendations.somResearchTips.initialCustomers')}</li>
                                                <li>{t('marketRecommendations.recommendations.somResearchTips.salesEffort')}</li>
                                                <li>{t('marketRecommendations.recommendations.somResearchTips.competitiveAdvantage')}</li>
                                                <li>{t('marketRecommendations.recommendations.somResearchTips.marketPenetration')}</li>
                                            </ul>

                                            <h3 className="text-base font-semibold text-primary-color pt-4">
                                                {t('marketRecommendations.recommendations.cagrExplanation')}
                                            </h3>
                                            <p className="text-sm">{t('marketRecommendations.recommendations.cagrGuidance')}</p>
                                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                                {t('marketRecommendations.recommendations.cagrResearchTitle')}
                                            </h4>
                                            <ul className="text-sm list-disc list-inside">
                                                <li>{t('marketRecommendations.recommendations.cagrResearchTips.industryReports')}</li>
                                                <li>{t('marketRecommendations.recommendations.cagrResearchTips.governmentData')}</li>
                                                <li>{t('marketRecommendations.recommendations.cagrResearchTips.talkToMentors')}</li>
                                                <li>{t('marketRecommendations.recommendations.cagrResearchTips.followIndustryNews')}</li>
                                            </ul>

                                            <h3 className="text-base font-semibold text-primary-color pt-4">
                                                {t('marketRecommendations.recommendations.marketDevelopmentExplanation')}
                                            </h3>
                                            <p className="text-sm">{t('marketRecommendations.recommendations.marketDevelopmentGuidance')}</p>
                                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                                {t('marketRecommendations.recommendations.marketDevelopmentTitle')}
                                            </h4>
                                            <ul className="text-sm list-disc list-inside">
                                                <li>{t('marketRecommendations.recommendations.marketDevelopmentTips.customerNeeds')}</li>
                                                <li>{t('marketRecommendations.recommendations.marketDevelopmentTips.marketTrends')}</li>
                                                <li>{t('marketRecommendations.recommendations.marketDevelopmentTips.competitiveAnalysis')}</li>
                                                <li>{t('marketRecommendations.recommendations.marketDevelopmentTips.futureOutlook')}</li>
                                            </ul>

                                            <p className="text-sm italic text-gray-600 pt-2">
                                                {t('marketRecommendations.recommendations.selectionTip')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="bg-white p-6 rounded-lg shadow mb-4">
                                <h3 className="text-primary-color mb-4">{t('KnowYourselfWizard.marketSize')}</h3>
                                <p className="text-gray-600 mb-6">{t('KnowYourselfWizard.marketSizeDescription')}</p>

                                <div className="mb-6">
                                    <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-2">
                                        {t('Select Currency')}
                                    </label>
                                    <select
                                        name="currency"
                                        value={values.currency}
                                        onChange={(e) => setFieldValue('currency', e.target.value)}
                                        className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                    >
                                        <option value="USD">💵 USD - US Dollar</option>
                                        <option value="EUR">💶 EUR - Euro</option>
                                        <option value="JPY">💴 JPY - Japanese Yen</option>
                                        <option value="GBP">💷 GBP - British Pound</option>
                                        <option disabled>──────────</option>
                                        <option value="AUD">AUD - Australian Dollar</option>
                                        <option value="BRL">BRL - Brazilian Real</option>
                                        <option value="CAD">CAD - Canadian Dollar</option>
                                        <option value="CHF">CHF - Swiss Franc</option>
                                        <option value="CNY">CNY - Chinese Yuan/Renminbi</option>
                                        <option value="HKD">HKD - Hong Kong Dollar</option>
                                        <option value="INR">INR - Indian Rupee</option>
                                        <option value="KRW">KRW - South Korean Won</option>
                                        <option value="MXN">MXN - Mexican Peso</option>
                                        <option value="NOK">NOK - Norwegian Krone</option>
                                        <option value="NZD">NZD - New Zealand Dollar</option>
                                        <option value="RUB">RUB - Russian Ruble</option>
                                        <option value="SEK">SEK - Swedish Krona</option>
                                        <option value="SGD">SGD - Singapore Dollar</option>
                                        <option value="TRY">TRY - Turkish Lira</option>
                                        <option value="ZAR">ZAR - South African Rand</option>
                                    </select>
                                </div>

                                <div className="flex flex-col md:flex-row gap-8">
                                    <div className="md:w-1/2">
                                        <MarketSizeVisualization
                                            tam={Number(values.totalAddressableMarket)}
                                            sam={Number(values.serviceableAddressableMarket)}
                                            som={Number(values.serviceableObtainableMarket)}
                                            currency={values.currency}
                                        />
                                    </div>
                                    <div className="md:w-1/2">
                                        <div className="flex flex-col gap-6">
                                            <NumberInput
                                                label={t('KnowYourselfWizard.tam')}
                                                name="totalAddressableMarket"
                                                value={values.totalAddressableMarket}
                                                onChange={(e) => setFieldValue('totalAddressableMarket', Number(e.target.value))}
                                                currency={values.currency}
                                                description={t('KnowYourselfWizard.tamDescription')}
                                            />
                                            <NumberInput
                                                label={t('KnowYourselfWizard.sam')}
                                                name="serviceableAddressableMarket"
                                                value={values.serviceableAddressableMarket}
                                                onChange={(e) => setFieldValue('serviceableAddressableMarket', Number(e.target.value))}
                                                currency={values.currency}
                                                description={t('KnowYourselfWizard.samDescription')}
                                            />
                                            <NumberInput
                                                label={t('KnowYourselfWizard.som')}
                                                name="serviceableObtainableMarket"
                                                value={values.serviceableObtainableMarket}
                                                onChange={(e) => setFieldValue('serviceableObtainableMarket', Number(e.target.value))}
                                                currency={values.currency}
                                                description={t('KnowYourselfWizard.somDescription')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-6 rounded-lg shadow mt-8">
                                <h3 className="text-primary-color mb-4">{t('KnowYourselfWizard.marketGrowth')}</h3>
                                <p className="text-gray-600 mb-6">{t('KnowYourselfWizard.marketGrowthDescription')}</p>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <PercentageInput
                                        label={t('KnowYourselfWizard.cagr')}
                                        name="marketGrowth"
                                        value={values.marketGrowth}
                                        onChange={(e) => setFieldValue('marketGrowth', Number(e.target.value))}
                                        description={t('KnowYourselfWizard.cagrDescription')}
                                    />
                                </div>
                            </div>

                            <div className="bg-white p-6 rounded-lg shadow mt-8">
                                <h3 className="text-primary-color mb-4">{t('KnowYourselfWizard.marketDevelopment')}</h3>
                                <p className="text-gray-600 mb-6">{t('KnowYourselfWizard.marketDevelopmentDescription')}</p>

                                <TextField
                                    name="marketDevelopment"
                                    type="textarea"
                                    rows={4}
                                />
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button
                                    type="button"
                                    className="text-sm text-gray-900"
                                    onClick={() => navigate('/funding')}
                                >
                                    {t('cancel')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                    disabled={isSubmitting}
                                    className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                >
                                    {t('Save as Draft')}
                                </button>
                                <button
                                    type="submit"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        const errors = await validateForm(values);
                                        if (Object.keys(errors).length === 0) {
                                            // No validation errors, proceed with submission
                                            onSubmit(values, { setSubmitting: () => { } });
                                        } else {
                                            // Show validation errors in notification
                                            const errorMessages = Object.entries(errors).map(([field, message]) => {
                                                // Map field names to their translated versions
                                                const fieldTranslations = {
                                                    totalAddressableMarket: t('KnowYourselfWizard.tam'),
                                                    serviceableAddressableMarket: t('KnowYourselfWizard.sam'),
                                                    serviceableObtainableMarket: t('KnowYourselfWizard.som'),
                                                    marketGrowth: t('KnowYourselfWizard.cagr'),
                                                    marketDevelopment: t('KnowYourselfWizard.marketDevelopment')
                                                };
                                                const fieldName = fieldTranslations[field] || field;
                                                return `${fieldName}: ${message}`;
                                            });
                                            setNotification({
                                                show: true,
                                                type: 'error',
                                                message: t('Failed to save data due to validation errors:'),
                                                errors: errorMessages
                                            });
                                        }
                                    }}
                                    disabled={isSubmitting}
                                    className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                                >
                                    {isSubmitting ? t('saving') : t('save')}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
        </>
    );
};

export default KnowYourselfWizardMarket;