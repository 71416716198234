import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { getTranslatedValue } from '../../../utils/templateTranslations';
import axios from '../../../axios';
import DataAnalysisSection from './DataAnalysisSection';

const BarChartComparison = ({ question, collection, template }) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState({});
    const [userLang, setUserLang] = useState('en');

    // User and language fetching (from BoxPlotChart)
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('/api/v1/live_data/user_data', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
                });

                if (response.data.language) {
                    setUserLang(response.data.language.slice(0, 2));
                }

                if (response.data.entity?.users) {
                    const userMap = {};
                    response.data.entity.users.forEach(user => {
                        userMap[user.id] = `${user.first_name} ${user.last_name}`;
                    });
                    setUsers(userMap);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    // Helper functions from BoxPlotChart
    const findQuestionInTemplate = (questionId) => {
        if (!template) return null;
        for (const part of template.parts) {
            const question = part.questions.find(q => q.id === questionId);
            if (question) {
                return { question, part };
            }
        }
        return null;
    };

    const getPartName = (partId) => {
        const part = template.parts.find(p => p.id === partId);
        return part ? getTranslatedValue(part.name, userLang) : partId;
    };

    const getUserName = (userId) => {
        return users[userId] || `User ${userId}`;
    };

    const getQuestionText = (questionId) => {
        if (!questionId || !template) return '';
    
        const [mainQuestionId, itemId] = questionId.split('.');
        const found = findQuestionInTemplate(mainQuestionId);
    
        if (found && found.question.items) {
          const item = found.question.items.find((i) => i.id === itemId);
          if (item && item.text) {
            return getTranslatedValue(item.text, userLang);
          }
        }
        return questionId;
      };

    // Color calculation function (from BarChart)
    const getColor = (value, range, reverse = false) => {
        const [min, max] = range;
        const range_size = max - min;
        
        const redThreshold = min + (range_size * 0.5);
        const greenThreshold = max - (range_size * 0.5);
        
        let colorValue = reverse ? max - (value - min) : value;
        
        if (colorValue <= redThreshold) {
            const t = (colorValue - min) / (redThreshold - min);
            const r = 255;
            const g = Math.round(69 + (107 * t));
            const b = Math.round(96 + (-71 * t));
            return `rgb(${r},${g},${b})`;
        } else if (colorValue >= greenThreshold) {
            const t = (colorValue - greenThreshold) / (max - greenThreshold);
            const r = Math.round(254 - (212 * t));
            const g = Math.round(176 - (19 * t));
            const b = Math.round(25 + (118 * t));
            return `rgb(${r},${g},${b})`;
        } else {
            const t = (colorValue - redThreshold) / (greenThreshold - redThreshold);
            const r = 254;
            const g = Math.round(176);
            const b = Math.round(25 + (t * 25));
            return `rgb(${r},${g},${b})`;
        }
    };

    // Chart data processing
    const chartData = React.useMemo(() => {
        if (!collection?.responses || !template) return null;

        // Find the question in template to get rating_reverse flag
        const templateQuestion = findQuestionInTemplate(question.id)?.question;
        const isReversed = templateQuestion?.rating_reverse || false;

        // Get all unique question IDs from all responses
        const questionIds = new Set();
        collection.responses.forEach(response => {
            Object.keys(response.response_data).forEach(id => questionIds.add(id));
        });

        const targetGroup = findQuestionInTemplate(question.id)?.question?.comparison_group;
        const relatedQuestionIds = Array.from(questionIds).filter((id) => {
            const foundQuestion = findQuestionInTemplate(id)?.question;
            return foundQuestion?.comparison_group === targetGroup;
        });

        const partAverages = {};

        relatedQuestionIds.forEach(questionId => {
            const partId = questionId.split('_')[0];
            const partName = getPartName(partId);

            // Find the latest non-draft response for this part
            const partResponse = collection.responses
                .filter(r => !r.is_draft && r.response_data[questionId])
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

            if (partResponse) {
                const values = partResponse.response_data[questionId];
                if (values) {
                    const sum = Object.values(values).reduce((acc, val) => 
                        acc + (typeof val === 'number' ? val : 0), 0);
                    const count = Object.values(values)
                        .filter(val => typeof val === 'number').length;

                    partAverages[partName] = {
                        value: count > 0 ? sum / count : 0,
                        editor: `${partResponse.user_first_name} ${partResponse.user_last_name}`,
                        submittedAt: partResponse.created_at
                    };
                }
            }
        });

        const sortedData = Object.entries(partAverages)
            .map(([partName, data]) => ({
                partName,
                ...data,
                color: getColor(data.value, question.range || [-3, 3], isReversed)
            }))
            .sort((a, b) => isReversed ? a.value - b.value : b.value - a.value);

        return {
            labels: sortedData.map(d => d.partName),
            colors: sortedData.map(d => d.color),
            series: [{
                name: t('Rating'),
                data: sortedData.map(d => d.value)
            }],
            editors: sortedData.map(d => d.editor),
            isReversed
        };
    }, [collection, question, template, userLang, t]);

    if (!chartData) return null;

    // Chart options (from BarChart)
    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false }
        },
        legend: { show: false },
        title: {
            text: getTranslatedValue(question.comparison_group_name, userLang),
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: '500'
            }
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                borderRadius: 10,
                borderRadiusApplication: 'end',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'center'
                },
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                colors: ['#fff'],
                fontSize: '12px',
                fontWeight: '500'
            },
            background: {
                enabled: true,
                foreColor: '#4b5563',
                padding: 4,
                opacity: 0.5,
            },
            formatter: function(val, opt) {
                const editor = chartData.editors[opt.dataPointIndex];
                return `${opt.w.globals.labels[opt.dataPointIndex]} (${val.toFixed(1)})`;
            },
            offsetX: 0,
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: chartData.labels,
            min: question.range[0],
            max: question.range[1],
            tickAmount: Math.abs(question.range[1] - question.range[0]),
            labels: {
                show: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit'
                }
            }
        },
        yaxis: {
            labels: { show: false }
        },
        colors: chartData.colors,
        tooltip: {
            theme: 'light',
            x: { show: false },
            y: {
                formatter: value => value.toFixed(1)
            }
        }
    };

    // Add reference line for 0 if range includes negative values
    if (question.range[0] < 0) {
        options.annotations = {
            xaxis: [{
                x: 0,
                strokeDashArray: 0,
                borderColor: '#718096',
                label: {
                    borderColor: '#718096',
                    style: {
                        color: '#fff',
                        background: '#718096'
                    }
                }
            }]
        };
    }

    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <Chart
                options={options}
                series={chartData.series}
                type="bar"
                height={Math.max(250, chartData.labels.length * 50)}
            />
            <div className="mt-2 text-xs text-gray-500 text-center">
                {t('Comparison of ratings from different parts')}
            </div>

            <div className="mt-6">
                <DataAnalysisSection
                    responses={collection.responses}
                    question={question}
                    template={template}
                    getQuestionText={getQuestionText}
                    getUserName={getUserName}
                    t={t}
                    isReversed={chartData.isReversed}
                    range={question.range || [-3, 3]}
                    collectionType={collection.collection_type}
                />
            </div>
        </div>
    );
};

export default BarChartComparison;