// src/pages/wizards/DataPrivacyWizard/DataPrivacyWizardOverview.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import Notification from '../../../components/Notification';
import CreateSystemTaskButton from '../../../components/CreateSystemTaskButton';

const DataPrivacyWizardOverview = () => {
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizardData, updateWizardData, wizardName, refetchData } = useWizard();

  const modules = [
    { 
      id: "introduction", 
      name: t('DataPrivacyWizard.introduction'),
      description: t('DataPrivacyWizard.introductionDescription'),
      pages: ["assessment", "principles", "dpo"]
    },
    { 
      id: "ropa", 
      name: t('DataPrivacyWizard.ropa'),
      description: t('DataPrivacyWizard.ropaDescription'),
      pages: ["dataMapping", "processingDetails", "riskClassification"]
    },
    { 
      id: "policies", 
      name: t('DataPrivacyWizard.policies'),
      description: t('DataPrivacyWizard.policiesDescription'),
      pages: ["dataProtection", "retention", "breach", "dataSubjectRights"]
    },
    { 
      id: "thirdParties", 
      name: t('DataPrivacyWizard.thirdParties'),
      description: t('DataPrivacyWizard.thirdPartiesDescription'),
      pages: ["vendorAssessment", "dpas", "internationalTransfers"]
    },
    { 
      id: "dpia", 
      name: t('DataPrivacyWizard.dpiaTitle'),
      description: t('DataPrivacyWizard.dpiaDescription'),
      pages: ["dpiaAssessment", "dpiaProcess", "mitigationMeasures"]
    },
    { 
      id: "transparency", 
      name: t('DataPrivacyWizard.transparency'),
      description: t('DataPrivacyWizard.transparencyDescription'),
      pages: ["privacyPolicy", "cookiePolicy", "privacyNotices"]
    },
    { 
      id: "implementation", 
      name: t('DataPrivacyWizard.implementation'),
      description: t('DataPrivacyWizard.implementationDescription'),
      pages: ["training", "checklist", "monitoring"]
    }
  ];

  const getModuleStatus = (moduleId) => {
    // Logic to determine if module is complete, in progress, or not started
    const moduleData = wizardData?.published?.[moduleId];
    const moduleDraft = wizardData?.draft?.[moduleId];
    
    if (!moduleData && !moduleDraft) return 'empty';
    if (JSON.stringify(moduleData) !== JSON.stringify(moduleDraft)) return 'draft';
    return 'complete';
  };

  const renderModuleIcon = (status) => {
    switch (status) {
      case 'complete':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        );
      case 'draft':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-600">
            <PencilSquareIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        );
      default:
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500 text-xl"></span>
          </span>
        );
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />
      
      <h1 className="text-2xl font-bold mb-4">{t('DataPrivacyWizard.title')}</h1>
      <p className="text-normal mb-8">{t('DataPrivacyWizard.overview')}</p>
      
      <div className="bg-white shadow rounded-lg">
        <nav aria-label="Progress">
          <ol role="list">
            {modules.map((module, index) => (
              <li key={module.id} className={index !== modules.length - 1 ? 'border-b border-gray-200' : ''}>
                <div className="w-full text-left px-6 py-5 flex items-center justify-between">
                  <button
                    onClick={() => navigate(`/data-privacy-wizard/${module.id}`)}
                    className="flex items-center flex-grow"
                  >
                    <span className="flex-shrink-0">
                      {renderModuleIcon(getModuleStatus(module.id))}
                    </span>
                    <span className="ml-6 flex flex-col text-left">
                      <span className="text-sm font-medium text-gray-900">{module.name}</span>
                      <span className="text-sm font-medium text-gray-500">
                        {module.description}
                      </span>
                    </span>
                  </button>
                  <div className="flex items-center">
                    <CreateSystemTaskButton 
                      taskName={`Complete "${module.name}" in Data Privacy Wizard`}
                      section="data_privacy_wizard"
                      additionalData={{
                        link: `/data-privacy-wizard/${module.id}`
                      }}
                    />
                    <button
                      onClick={() => navigate(`/data-privacy-wizard/${module.id}`)}
                      className="text-primary-color hover:text-secondary-color ml-4"
                      aria-label={t("Edit")}
                    >
                      <PencilSquareIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default DataPrivacyWizardOverview;