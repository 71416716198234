import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import axios from '../axios';
import { useNavigate, useLocation } from 'react-router-dom';
import SessionTimeoutModal from '../components/SessionTimeoutModal';

// Constants for timing
const TOKEN_LIFETIME = 24 * 60 * 60; // 24 hours in seconds
const WARNING_BEFORE_EXPIRY = 30 * 60; // 30 minutes before expiry to show modal
const CHECK_INTERVAL = 60; // Check token status every minute

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [modalCountdown, setModalCountdown] = useState(WARNING_BEFORE_EXPIRY);

  const checkTimerRef = useRef(null);
  const interceptorRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Logout function
  const logout = useCallback(() => {
    console.log('Logging out user');
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiresAt');
    localStorage.removeItem('email');

    // Clear any active timers
    if (checkTimerRef.current) {
      clearInterval(checkTimerRef.current);
    }
  }, []);

  // Function to refresh the token
  // In AuthContext.js, update the refreshToken function to use the new endpoint

  const refreshToken = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) return false;

      console.log('Attempting to refresh token...');

      // Use the new endpoint path
      const response = await axios.post(
        '/api/v1/tokens/refresh',
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data && response.data.token_expires_at) {
        const expirationTime = Math.floor(new Date(response.data.token_expires_at).getTime() / 1000);
        localStorage.setItem('tokenExpiresAt', expirationTime.toString());
        console.log('Token refreshed successfully. New expiration:', new Date(response.data.token_expires_at).toLocaleString());
        return true;
      }
      console.log('Token refresh response did not contain expected data:', response.data);
      return false;
    } catch (error) {
      console.error('Error refreshing token:', error.response?.data || error.message);
      return false;
    }
  }, []);

  // Handle continue session from modal
  const handleContinueSession = useCallback(async () => {
    const success = await refreshToken();
    if (success) {
      setShowTimeoutModal(false);
    } else {
      logout();
      navigate('/session-expired');
    }
  }, [refreshToken, logout, navigate]);

  // Handle logout from modal
  const handleModalLogout = useCallback(() => {
    // Make sure to close modal first
    setShowTimeoutModal(false);
    logout();
    navigate('/logout-success');
  }, [logout, navigate]);

  // Check token expiration
  const checkTokenExpiration = useCallback(() => {
    const token = localStorage.getItem('authToken');
    const expiration = localStorage.getItem('tokenExpiresAt');

    if (!token || !expiration) {
      return;
    }

    const expirationTime = parseInt(expiration, 10);
    const now = Math.floor(Date.now() / 1000);
    const timeRemaining = expirationTime - now;

    // If token has expired
    if (timeRemaining <= 0) {
      console.log('Token has expired. Logging out.');
      // Make sure to close the modal if it's open
      setShowTimeoutModal(false);
      logout();
      navigate('/session-expired');
      return;
    }

    // If token is about to expire, show modal
    if (timeRemaining <= WARNING_BEFORE_EXPIRY && !showTimeoutModal) {
      console.log('Token about to expire. Showing warning modal.');
      setModalCountdown(timeRemaining);
      setShowTimeoutModal(true);
    }
  }, [logout, navigate, showTimeoutModal]);

  // Set up axios interceptor to add token to all requests
  useEffect(() => {
    // Eject existing interceptor if there is one
    if (interceptorRef.current !== null) {
      axios.interceptors.request.eject(interceptorRef.current);
    }

    // Add a new interceptor
    interceptorRef.current = axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('authToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // Clean up on unmount
    return () => {
      if (interceptorRef.current !== null) {
        axios.interceptors.request.eject(interceptorRef.current);
      }
    };
  }, []);

  // Handle sync across tabs
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'authToken') {
        // If another tab cleared the token (logged out)
        if (!event.newValue) {
          setUser(null);
          navigate('/session-expired');
        }
        // If another tab set the token (logged in)
        else if (event.newValue !== localStorage.getItem('authToken')) {
          const userData = JSON.parse(localStorage.getItem('user') || 'null');
          setUser(userData);
        }
      } else if (event.key === 'tokenExpiresAt') {
        // If expiration was updated in another tab (token refreshed)
        if (showTimeoutModal) {
          setShowTimeoutModal(false);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate, showTimeoutModal]);

  // Setup token expiration check
  useEffect(() => {
    // Start token check timer
    checkTimerRef.current = setInterval(checkTokenExpiration, CHECK_INTERVAL * 1000);

    return () => {
      // Clear timer
      if (checkTimerRef.current) {
        clearInterval(checkTimerRef.current);
      }
    };
  }, [checkTokenExpiration]);

  // Initial authentication check
  useEffect(() => {
    const checkAndFetchUser = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await axios.get('/api/v1/live_data/user_data');
          // No need to manually add headers here because the interceptor will do it

          if (response.data) {
            setUser(response.data);
            localStorage.setItem('user', JSON.stringify(response.data));

            // Immediately check token expiration after fetching user
            checkTokenExpiration();
          }
        } catch (error) {
          console.error('Error fetching user:', error);
          // If the error is due to authorization, clear the token
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            logout();
            navigate('/session-expired');
          }
        }
      }
      setLoading(false);
    };

    checkAndFetchUser();
  }, [logout, navigate, checkTokenExpiration]);

  const login = (userData) => {
    // Convert expiration time string to unix timestamp if it's not already
    let expirationTime;
    if (userData.token_expires_at) {
      expirationTime = Math.floor(new Date(userData.token_expires_at).getTime() / 1000);
    } else {
      expirationTime = Math.floor(Date.now() / 1000) + TOKEN_LIFETIME; // 24 hours from now in seconds
    }

    console.log('Logging in user. Token expires at:', new Date(expirationTime * 1000).toLocaleString());
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('authToken', userData.authentication_token);
    localStorage.setItem('tokenExpiresAt', expirationTime.toString());
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, refreshToken }}>
      {children}

      {/* Session Timeout Modal */}
      <SessionTimeoutModal
        isOpen={showTimeoutModal}
        onClose={() => setShowTimeoutModal(false)}
        onContinue={handleContinueSession}
        onLogout={handleModalLogout}
        remainingTime={modalCountdown}
      />
    </AuthContext.Provider>
  );
}