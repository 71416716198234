import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleSection from './CollapsibleSection';
import CategoryItem from './CategoryItem';

const AssetClassesSection = ({
  selectedCategory,
  classes,
  selectedClass,
  handleClassSelect,
  expandedSections,
  toggleSection,
  loading,
  userLang = 'en',
  getClassValue,
  getCategoryValue,
  disabled = false
}) => {
  const { t } = useTranslation();
  
  if (!selectedCategory) return null;
  
  if (loading && classes.length === 0 && expandedSections.classes) {
    return (
      <div className="p-3 text-center">
        <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 secondary-color-600 mr-2"></div>
        <span className="text-sm text-gray-600">{t("Loading...")}</span>
      </div>
    );
  }

  // Get selected value with localization support
  const selectedValue = selectedClass ? getClassValue(selectedClass) : null;

  return (
    <CollapsibleSection
      title={t('Asset Class')}
      isExpanded={expandedSections.classes}
      onToggle={() => toggleSection('classes')}
      selectedValue={selectedValue}
      disabled={disabled}
    >
      {classes.length === 0 ? (
        <div className="p-3 text-center text-gray-500">
          {t('No asset classes found for this category')}
        </div>
      ) : (
        classes.map(assetClass => (
          <CategoryItem
            key={assetClass.id}
            category={assetClass}
            selected={selectedClass?.id === assetClass.id}
            onSelect={handleClassSelect}
            userLang={userLang}
            getClassValue={getClassValue}
          />
        ))
      )}
      
      {/* Other option - only shown if selected category is not "Other" */}
      {selectedCategory && selectedCategory.id !== 'other' && (
        <CategoryItem
          category={{ 
            id: 'other', 
            asset_class: userLang === 'de' ? 'Andere' : 'Other',
            wertklasse: userLang === 'de' ? 'Andere' : 'Other',
            description: userLang === 'de' ? 'Benutzerdefinierte Asset-Klasse' : 'Custom asset class',
            beschreibung: userLang === 'de' ? 'Benutzerdefinierte Asset-Klasse' : 'Custom asset class',
            asset_category: getCategoryValue ? getCategoryValue(selectedCategory) : selectedCategory.asset_categories
          }}
          selected={selectedClass?.id === 'other'}
          onSelect={handleClassSelect}
          userLang={userLang}
          getClassValue={getClassValue}
        />
      )}
    </CollapsibleSection>
  );
};

export default AssetClassesSection;