// src/pages/wizards/DataPrivacyWizard/DataPrivacyWizardTOMs.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
    CheckIcon,
    XMarkIcon,
    ShieldCheckIcon,
    LockClosedIcon,
    WrenchScrewdriverIcon,
    ArrowsRightLeftIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/24/solid';
import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { useUserData } from '../../../hooks/useUserData';
import RiskControlModal from '../../../components/wizard_components/DataPrivacyWizard/RiskControlModal';

function debounce(func, wait) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }
    debounced.cancel = () => {
        clearTimeout(timeout);
    };
    return debounced;
}

const DataPrivacyWizardTOMs = () => {
    // Basic hooks and state initialization
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
    const { userData } = useUserData();
    const { data: tomsData, dataStatus, loading: tomsLoading, error: tomsError } = useFetchData(wizardName, 'toms');
    const { data: ropaData, loading: ropaLoading, error: ropaError } = useFetchData(wizardName, 'ropa');
    const { data: dpiaData, loading: dpiaLoading, error: dpiaError } = useFetchData(wizardName, 'dpia');

    // Notification state from original
    const [notification, setNotification] = useState({ show: false, type: '', message: '' });

    const [riskControls, setRiskControls] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
    const [filterCategory, setFilterCategory] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const tableRef = useRef(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoveredCol, setHoveredCol] = useState(null);
    const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

    // State for initial values; we add exclusionReasons along with controlsMatrix and riskControlOverrides.
    const [initialValues, setInitialValues] = useState({
        controlsMatrix: {},
        riskControlOverrides: {},
        exclusionReasons: {}
    });

    // Risk control modal state (and fine-tune mode)
    const [riskControlModalOpen, setRiskControlModalOpen] = useState(false);
    const [selectedRiskControl, setSelectedRiskControl] = useState(null);
    const [riskModalMode, setRiskModalMode] = useState('fineTune');
    const [initialRiskOverrides, setInitialRiskOverrides] = useState({});

    // Define auto-exclusion reasons – these will disable toggling.
    const autoExclusionReasons = ['notProcessor', 'notController', 'notJointArrangement', 'noConsent', 'noTransfer', 'noDPIA'];

    // Set the current section on mount.
    useEffect(() => {
        setCurrentSection('toms');
    }, [setCurrentSection]);

    // Fetch risk controls data.
    useEffect(() => {
        const fetchRiskControls = async () => {
            try {
                const response = await axios.get('/api/v1/static_data/risk_controls');
                setRiskControls(response.data);
            } catch (error) {
                console.error('Error fetching risk controls:', error);
            }
        };
        fetchRiskControls();
    }, []);

    // Build initial values (controlsMatrix, riskControlOverrides, exclusionReasons) once data loads.
    useEffect(() => {
        if (!ropaLoading && !dpiaLoading && !tomsLoading && ropaData && riskControls.length > 0) {
            const processingActivities = ropaData.processingActivities || [];
            let matrix = {};
            let overrides = {};
            let exclusions = {};
            
            if (tomsData && tomsData.controlsMatrix) {
                matrix = { ...tomsData.controlsMatrix };
            }
            if (tomsData && tomsData.riskControlOverrides) {
                overrides = { ...tomsData.riskControlOverrides };
            }
            if (tomsData && tomsData.exclusionReasons) {
                exclusions = { ...tomsData.exclusionReasons };
            }

            // Helper to get DPIA selected controls for an activity.
            const getSelectedControlsForActivity = (activityId) => {
                if (!dpiaData || !dpiaData.activities) return [];
                const dpiaActivity = dpiaData.activities.find(a => a.processingActivityId === activityId);
                if (!dpiaActivity) return [];
                if (dpiaActivity.treatmentStarted && Array.isArray(dpiaActivity.selectedControls)) {
                    return dpiaActivity.selectedControls;
                }
                return [];
            };

            // Helper: whether a privacy control should be applied.
            const shouldApplyPrivacyControl = (controlId, activity) => {
                const processorType = activity.processingRole;
                const lawfulBasis = activity.lawfulBasis || {};
                const externalRecipients = activity.externalRecipients || [];

                if (controlId.startsWith('PC7')) {
                    if (processorType !== 'Controller' && processorType !== 'Co-Controller') {
                        return false;
                    }
                    switch (controlId) {
                        case 'PC7203':
                        case 'PC7204':
                            return lawfulBasis?.consent === true;
                        case 'PC7206':
                        case 'PC7307':
                            return Array.isArray(externalRecipients) && externalRecipients.length > 0;
                        case 'PC7207':
                            return processorType === 'Co-Controller';
                        case 'PC7409':
                            return false;
                        default:
                            return true;
                    }
                }

                if (controlId.startsWith('PC8')) {
                    if (processorType !== 'Processor' && processorType !== 'Co-Processor') {
                        return false;
                    }
                    switch (controlId) {
                        case 'PC8507':
                        case 'PC8508':
                            return Array.isArray(externalRecipients) && externalRecipients.length > 0;
                        case 'PC8403':
                            return false;
                        default:
                            return true;
                    }
                }
                return false;
            };

            processingActivities.forEach((activity) => {
                const activityId = activity.id;
                if (!matrix[activityId]) matrix[activityId] = {};
                if (!exclusions[activityId]) exclusions[activityId] = {};
                const dpiaSelectedControls = getSelectedControlsForActivity(activityId);
                const hasDpiaSelectedControls = dpiaSelectedControls.length > 0;

                riskControls.forEach(control => {
                    const controlId = control.id;
                    // Set the applied flag if not set yet.
                    if (matrix[activityId][controlId] === undefined) {
                        if (hasDpiaSelectedControls) {
                            matrix[activityId][controlId] = dpiaSelectedControls.includes(controlId);
                        } else if (controlId.startsWith('PC')) {
                            matrix[activityId][controlId] = shouldApplyPrivacyControl(controlId, activity);
                        } else {
                            matrix[activityId][controlId] = false;
                        }
                    }
                    // For PC controls that are not applied, set the default exclusion reason if missing.
                    if (controlId.startsWith('PC') && !matrix[activityId][controlId] && !exclusions[activityId][controlId]) {
                        let defaultReason = '';
                        if (control.id.startsWith('PC8') && (activity.processingRole === 'Controller' || activity.processingRole === 'Co-Controller')) {
                            defaultReason = 'notProcessor';
                        } else if (control.id.startsWith('PC7')) {
                            if (activity.processingRole === 'Processor' || activity.processingRole === 'Co-Processor') {
                                defaultReason = 'notController';
                            } else if (control.id === 'PC7207' && activity.processingRole === 'Controller' && activity.processingRole !== 'Co-Controller') {
                                defaultReason = 'notJointArrangement';
                            } else if ((control.id === 'PC7203' || control.id === 'PC7204') &&
                                (!activity.lawfulBasis || !activity.lawfulBasis.consent)) {
                                defaultReason = 'noConsent';
                            } else {
                                defaultReason = 'other';
                            }
                        } else if (['PC7501', 'PC7502', 'PC8501', 'PC8502'].includes(control.id)) {
                            if (activity.externalRecipients && activity.externalRecipients.length > 0) {
                                defaultReason = 'noTransfer';
                            } else {
                                defaultReason = 'other';
                            }
                        } else if (control.id === 'PC7205') {
                            defaultReason = 'noDPIA';
                        } else {
                            defaultReason = 'other';
                        }
                        exclusions[activityId][controlId] = { reason: defaultReason, otherText: '' };
                    }
                });
            });
            setInitialValues({
                controlsMatrix: matrix,
                riskControlOverrides: overrides,
                exclusionReasons: exclusions
            });
        }
    }, [ropaData, dpiaData, tomsData, riskControls, ropaLoading, dpiaLoading, tomsLoading]);

    // ---------------------------------------------------------------------
    // Now that initialValues has been declared and built, we can initialize Formik.
    // (enableReinitialize ensures it updates when initialValues changes.)
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const result = await saveData(wizardName, 'toms', values);
                if (result.success) {
                    setNotification({
                        show: true,
                        type: 'success',
                        message: t('DataPrivacyWizard.dataSaved'),
                    });
                    updateWizardData('toms', values, false);
                    updateWizardData('toms', values, true);
                    setTimeout(() => {
                        navigate('/data-privacy-wizard/policies');
                    }, 1000);
                } else {
                    throw new Error(result.message || 'Error saving data');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                setNotification({
                    show: true,
                    type: 'error',
                    message: t('DataPrivacyWizard.errorSaving'),
                    errors: error.message ? [error.message] : []
                });
            } finally {
                setSubmitting(false);
            }
        }
    });
    // ---------------------------------------------------------------------

    // --- Helper functions that use Formik (now safe to reference formik) ---

    // Handler for toggling a control; auto-excluded controls (per exclusionReasons) are not toggleable.
    const handleToggleControl = (activityId, controlId) => {
        const exclusionData = formik.values.exclusionReasons?.[activityId]?.[controlId];
        if (controlId.startsWith('PC') && exclusionData && autoExclusionReasons.includes(exclusionData.reason)) {
            return; // Prevent toggling if auto-excluded.
        }
        const currentValue = formik.values.controlsMatrix?.[activityId]?.[controlId] === true;
        const newMatrix = { ...formik.values.controlsMatrix };
        if (!newMatrix[activityId]) newMatrix[activityId] = {};
        newMatrix[activityId][controlId] = !currentValue;
        formik.setFieldValue('controlsMatrix', newMatrix);
    };

    // "Select All" handler per control.
    const handleSelectAllForControl = (controlId) => {
        const activities = ropaData?.processingActivities || [];
        const newMatrix = { ...formik.values.controlsMatrix };
        const allSelected = activities.every(activity => {
            const activityId = activity.id;
            return !!newMatrix?.[activityId]?.[controlId];
        });
        const newValue = !allSelected;
        activities.forEach(activity => {
            const activityId = activity.id;
            if (!newMatrix[activityId]) newMatrix[activityId] = {};
            newMatrix[activityId][controlId] = newValue;
        });
        formik.setFieldValue('controlsMatrix', newMatrix);
    };

    const handleFineTuneControl = (controlId) => {
        setSelectedRiskControl(controlId);
        setRiskModalMode('fineTune');
        setRiskControlModalOpen(true);
    };

    const isAllSelected = (controlId) => {
        const activities = ropaData?.processingActivities || [];
        if (!activities || activities.length === 0) return false;
        return activities.every(activity => {
            const activityId = activity.id;
            return formik.values.controlsMatrix?.[activityId]?.[controlId] === true;
        });
    };

    const getControlCompliancePercentage = (controlId) => {
        const activities = ropaData?.processingActivities || [];
        if (!activities || activities.length === 0) return 0;
        const checkedCount = activities.reduce((count, activity) => {
            const activityId = activity.id;
            return formik.values.controlsMatrix?.[activityId]?.[controlId] === true ? count + 1 : count;
        }, 0);
        return Math.round((checkedCount / activities.length) * 100);
    };

    // Filter controls based on category and search term.
    const getFilteredControls = () => {
        if (!riskControls || riskControls.length === 0) return [];
        let filtered = [...riskControls];
        if (filterCategory === 'privacy') {
            filtered = filtered.filter(control => control.id.startsWith('PC'));
        } else if (filterCategory === 'security') {
            filtered = filtered.filter(control => control.id.startsWith('RC'));
        }
        if (searchTerm) {
            const lowerSearchTerm = searchTerm.toLowerCase();
            filtered = filtered.filter(control =>
                control.id.toLowerCase().includes(lowerSearchTerm) ||
                control.control_name.toLowerCase().includes(lowerSearchTerm) ||
                (userLang === 'de' && control.control_name_de?.toLowerCase().includes(lowerSearchTerm))
            );
        }
        filtered.sort((a, b) => {
            const groupA = a.id.startsWith('PC') ? 1 : a.id.startsWith('RC') ? 2 : 3;
            const groupB = b.id.startsWith('PC') ? 1 : b.id.startsWith('RC') ? 2 : 3;
            if (groupA !== groupB) return groupA - groupB;
            const numA = parseInt(a.id.replace(/[^\d]/g, ''), 10);
            const numB = parseInt(b.id.replace(/[^\d]/g, ''), 10);
            return numA - numB;
        });
        return filtered;
    };

    // Exclusion helpers: options and update handlers.
    const getExclusionOptions = (control, activity) => {
        let options = [];
        if (control.id === 'PC7207' && activity.processingRole === 'Controller') {
            options.push({ value: 'notJointArrangement', label: t('DataPrivacyWizard.exclusion.notJointArrangement') });
        }
        if ((control.id === 'PC7203' || control.id === 'PC7204') &&
            (!activity.lawfulBasis || !activity.lawfulBasis.consent)) {
            options.push({ value: 'noConsent', label: t('DataPrivacyWizard.exclusion.noConsent') });
        }
        if (['PC7501', 'PC7502', 'PC8501', 'PC8502'].includes(control.id)) {
            options.push({ value: 'noTransfer', label: t('DataPrivacyWizard.exclusion.noTransfer') });
        }
        if (control.id === 'PC7205') {
            options.push({ value: 'noDPIA', label: t('DataPrivacyWizard.exclusion.noDPIA') });
        }
        options.push({ value: 'alternativeControl', label: t('DataPrivacyWizard.exclusion.alternativeControl') });
        options.push({ value: 'lowRisk', label: t('DataPrivacyWizard.exclusion.lowRisk') });
        options.push({ value: 'notFeasible', label: t('DataPrivacyWizard.exclusion.notFeasible') });
        options.push({ value: 'doesNotApply', label: t('DataPrivacyWizard.exclusion.doesNotApply') });
        options.push({ value: 'exemptByLaw', label: t('DataPrivacyWizard.exclusion.exemptByLaw') });
        options.push({ value: 'other', label: t('DataPrivacyWizard.exclusion.other') });
        return options;
    };

    const handleExclusionChange = (activityId, controlId, value) => {
        const current = formik.values.exclusionReasons;
        const updated = { ...current };
        if (!updated[activityId]) updated[activityId] = {};
        updated[activityId][controlId] = { ...updated[activityId][controlId], reason: value };
        formik.setFieldValue('exclusionReasons', updated);
    };

    const hasActivityDPIA = (activityId) => {
        if (!dpiaData || !dpiaData.activities) return false;
        return dpiaData.activities.some(activity => activity.processingActivityId === activityId);
    };

    const handleOtherTextChange = (activityId, controlId, text) => {
        const current = formik.values.exclusionReasons;
        const updated = { ...current };
        if (!updated[activityId]) updated[activityId] = {};
        updated[activityId][controlId] = { ...updated[activityId][controlId], otherText: text };
        formik.setFieldValue('exclusionReasons', updated);
    };

    // Auto-save draft logic.
    const autoSaveDraft = useCallback(async () => {
        if (liveMode) {
            const result = await saveAsDraft(wizardName, 'toms', formik.values);
            if (result.success) {
                updateWizardData('toms', formik.values, true);
            } else {
                console.error('Failed to save draft:', result.error);
            }
        }
    }, [liveMode, formik.values, wizardName, updateWizardData]);

    const debouncedAutoSaveRef = useRef();
    useEffect(() => {
        debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
    }, [autoSaveDraft]);

    useEffect(() => {
        if (liveMode) {
            debouncedAutoSaveRef.current();
            return () => debouncedAutoSaveRef.current.cancel();
        }
    }, [liveMode, formik.values]);

    const handleDraftSubmit = async () => {
        try {
            const result = await saveAsDraft(wizardName, 'toms', formik.values);
            if (result.success) {
                setNotification({
                    show: true,
                    type: 'success',
                    message: t('DataPrivacyWizard.draftSaved'),
                });
                updateWizardData('toms', formik.values, true);
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Error saving draft:', error);
            setNotification({
                show: true,
                type: 'error',
                message: t('DataPrivacyWizard.errorSavingDraft'),
                errors: [error.message],
            });
        }
    };

    const toggleDrawer = (title, content) => {
        if (drawerOpen && drawerContent.title === title) {
            setDrawerOpen(false);
        } else {
            setDrawerContent({
                title,
                content: `<div class="prose">${content}</div>`
            });
            setDrawerOpen(true);
        }
    };

    // Scroll synchronization for fixed headers (same as original).
    useEffect(() => {
        const handleScroll = () => {
            const table = tableRef.current;
            if (!table) return;
            const headerRow = table.querySelector('.header-row');
            const firstCol = table.querySelector('.first-col');
            if (headerRow) {
                headerRow.style.transform = `translateY(${table.scrollTop}px)`;
            }
            if (firstCol) {
                firstCol.style.transform = `translateX(${table.scrollLeft}px)`;
            }
        };
        const tableElement = tableRef.current;
        if (tableElement) {
            tableElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    if (ropaLoading || dpiaLoading || tomsLoading) return <div>{t('Loading...')}</div>;
    if (ropaError) return <div>Error loading ROPA data: {ropaError.message}</div>;
    if (dpiaError) return <div>Error loading DPIA data: {dpiaError.message}</div>;
    if (tomsError) return <div>Error loading TOMs data: {tomsError.message}</div>;

    const activities = ropaData?.processingActivities || [];
    const filteredControls = getFilteredControls();

    return (
        <div className="container mx-auto p-4">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <LiveModeToggle onToggle={toggleLiveMode} />
                <div className="mt-10">
                    <DataPrivacyWizardProgress />
                </div>
                <Notification
                    show={notification.show}
                    setShow={(show) => setNotification(prev => ({ ...prev, show }))}
                    type={notification.type}
                    message={notification.message}
                />
                <DataStatusBanner status={dataStatus} />

                <h1 className="text-3xl font-bold mb-2">
                    {t('DataPrivacyWizard.technicalOrganizationalMeasures')}
                </h1>
                <p className="text-gray-600 mb-6">
                    {t('DataPrivacyWizard.tomsDescription')}
                </p>

                <FormikProvider value={formik}>
                    <Form>
                        {/* Filter Controls */}
                        <div className="mb-6 bg-white p-4 rounded-lg shadow-sm border border-gray-200 flex flex-wrap gap-4 items-center justify-between">
                            <div className="flex gap-2">
                                <button
                                    type="button"
                                    onClick={() => setFilterCategory('all')}
                                    className={`px-3 py-1.5 rounded-md text-sm font-medium ${filterCategory === 'all'
                                        ? 'bg-primary-color text-white'
                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                        }`}
                                >
                                    {t('DataPrivacyWizard.allControls')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setFilterCategory('privacy')}
                                    className={`px-3 py-1.5 rounded-md text-sm font-medium flex items-center ${filterCategory === 'privacy'
                                        ? 'bg-primary-color text-white'
                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                        }`}
                                >
                                    <ShieldCheckIcon className="h-4 w-4 mr-1" />
                                    {t('DataPrivacyWizard.privacyControls')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setFilterCategory('security')}
                                    className={`px-3 py-1.5 rounded-md text-sm font-medium flex items-center ${filterCategory === 'security'
                                        ? 'bg-primary-color text-white'
                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                        }`}
                                >
                                    <LockClosedIcon className="h-4 w-4 mr-1" />
                                    {t('DataPrivacyWizard.securityControls')}
                                </button>
                            </div>
                            <div className="flex gap-4 items-center">
                                <div className="relative">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder={t('DataPrivacyWizard.searchControls')}
                                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white 
                      placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 
                      focus:ring-primary-color focus:border-primary-color sm:text-sm"
                                    />
                                    {searchTerm && (
                                        <button
                                            type="button"
                                            onClick={() => setSearchTerm('')}
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                                        >
                                            <XMarkIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Controls Matrix Table */}
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                            <div
                                ref={tableRef}
                                className="overflow-auto max-h-[calc(100vh-300px)]"
                                style={{ position: 'relative' }}
                            >
                                <table className="min-w-full divide-y divide-gray-200 table-fixed">
                                    <thead>
                                        <tr className="header-row bg-gray-50" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                                            <th
                                                className="first-col px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-b border-r border-gray-200"
                                                style={{
                                                    position: 'sticky',
                                                    left: 0,
                                                    zIndex: 20,
                                                    minWidth: '260px',
                                                    maxWidth: '260px'
                                                }}
                                            >
                                                {t('DataPrivacyWizard.controls')} ({filteredControls.length})
                                            </th>
                                            {activities.map((activity, index) => {
                                                const activityId = activity.id;
                                                return (
                                                    <th
                                                        key={activityId}
                                                        className={`px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50 border-b border-r border-gray-200 ${hoveredCol === index ? 'bg-gray-100' : ''}`}
                                                        style={{
                                                            minWidth: '180px',
                                                            maxWidth: '180px'
                                                        }}
                                                        onMouseEnter={() => setHoveredCol(index)}
                                                        onMouseLeave={() => setHoveredCol(null)}
                                                    >
                                                        <div className="flex flex-col items-center justify-center">
                                                            <span className="whitespace-normal">
                                                                {activity.name}
                                                            </span>
                                                            {hasActivityDPIA(activity.id) && (
                                                                <span className="mt-1 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                                                                    {t('DataPrivacyWizard.dpiaCompleted')}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredControls.map((control, rowIndex) => {
                                            const controlId = control.id;
                                            const controlTypeClass = controlId.startsWith('PC') ? 'bg-blue-50 text-sky-700' : (controlId.startsWith('RC') ? 'bg-green-50 text-green-700' : '');
                                            return (
                                                <tr
                                                    key={controlId}
                                                    className={hoveredRow === rowIndex ? 'bg-gray-50' : ''}
                                                    onMouseEnter={() => setHoveredRow(rowIndex)}
                                                    onMouseLeave={() => setHoveredRow(null)}
                                                >
                                                    <td
                                                        className={`first-col px-3 py-2 text-sm font-medium text-gray-900 border-r border-gray-200 ${controlTypeClass}`}
                                                        style={{
                                                            position: 'sticky',
                                                            left: 0,
                                                            background: hoveredRow === rowIndex ? '#F9FAFB' : '#fff',
                                                            minWidth: '300px',
                                                            maxWidth: '350px'
                                                        }}
                                                    >
                                                        <div className="flex items-center justify-between">
                                                            <div
                                                                className="flex flex-col cursor-pointer hover:text-primary-color"
                                                                onClick={() => setSelectedRiskControl(controlId) || setRiskControlModalOpen(true)}
                                                            >
                                                                <div className="flex items-center gap-2 overflow-hidden">
                                                                    {control.icons ? (
                                                                        <img
                                                                            src={`/is_icons/${control.icons}`}
                                                                            alt=""
                                                                            className="h-5 w-5"
                                                                        />
                                                                    ) : (
                                                                        <span className="h-5 w-5 bg-gray-200 rounded-full flex items-center justify-center text-xs font-medium">
                                                                            {controlId.substring(0, 1)}
                                                                        </span>
                                                                    )}
                                                                    <span className="text-sm font-medium mr-2">
                                                                        {userLang === 'de' ? control.control_name_de : control.control_name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center space-x-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleFineTuneControl(controlId)}
                                                                    className={`flex items-center justify-center w-7 h-7 rounded focus:outline-none focus:ring-2 ${formik.values.riskControlOverrides?.[controlId]?.length > 0
                                                                        ? 'bg-primary-color border border-primary-color text-white hover:bg-primary-color/90'
                                                                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                                                                        }`}
                                                                    title={t('RiskControlModal.fineTune')}
                                                                >
                                                                    <WrenchScrewdriverIcon className="h-4 w-4" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleSelectAllForControl(controlId)}
                                                                    className={`flex items-center justify-center w-7 h-7 rounded focus:outline-none focus:ring-2 ${isAllSelected(controlId)
                                                                        ? 'bg-primary-color text-white border border-primary-color'
                                                                        : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                                                                        }`}
                                                                    title={t('DataPrivacyWizard.selectAllActivities')}
                                                                >
                                                                    <img
                                                                        src="/ux_icons/seen.svg"
                                                                        alt="Select all"
                                                                        className="h-4 w-4"
                                                                        style={{ filter: isAllSelected(controlId) ? 'brightness(0) invert(1)' : 'none' }}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {activities.map((activity, colIndex) => {
                                                        const activityId = activity.id;
                                                        const isChecked = formik.values.controlsMatrix?.[activityId]?.[controlId] === true;
                                                        const exclusionData = formik.values.exclusionReasons?.[activityId]?.[controlId];
                                                        const isAutoExcluded = controlId.startsWith('PC') && exclusionData && autoExclusionReasons.includes(exclusionData.reason);
                                                        return (
                                                            <td
                                                                key={`${activityId}-${controlId}`}
                                                                className={`px-3 py-2 text-sm text-gray-500 text-center border-r border-gray-200 ${hoveredCol === colIndex ? 'bg-gray-100' : ''}`}
                                                                style={{ minWidth: '180px', maxWidth: '180px' }}
                                                            >
                                                                {controlId.startsWith('PC') && isAutoExcluded ? (
                                                                    <span className="inline-flex items-center px-2 py-0.5 mt-1 rounded text-xs font-medium bg-gray-100 text-gray-500">
                                                                        {t(`DataPrivacyWizard.exclusion.${exclusionData.reason}`)}
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleToggleControl(activityId, controlId)}
                                                                            className={`relative inline-flex items-center justify-center w-6 h-6 rounded ${isChecked
                                                                                ? 'bg-primary-color border border-primary-color text-white hover:bg-primary-color/90'
                                                                                : 'bg-white border border-gray-300 text-gray-400 hover:bg-gray-50'
                                                                                }`}
                                                                            title={isChecked ? t('DataPrivacyWizard.uncheck') : t('DataPrivacyWizard.check')}
                                                                            style={isChecked && formik.values.riskControlOverrides?.[controlId]?.length > 0
                                                                                ? { backgroundImage: 'linear-gradient(45deg, rgba(81, 110, 157, 0.8) 25%, transparent 25%, transparent 50%, rgba(81, 110, 157, 0.8) 50%, rgba(81, 110, 157, 0.8) 75%, transparent 75%, transparent 100%)', backgroundSize: '10px 10px' }
                                                                                : {}
                                                                            }
                                                                        >
                                                                            {isChecked && <CheckIcon className="h-4 w-4" />}
                                                                        </button>
                                                                        {!isChecked && (
                                                                            <div className="mt-1">
                                                                                <select
                                                                                    value={exclusionData ? exclusionData.reason : ''}
                                                                                    onChange={(e) => handleExclusionChange(activityId, controlId, e.target.value)}
                                                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color text-xs"
                                                                                    style={{
                                                                                        whiteSpace: 'normal',
                                                                                        overflowWrap: 'break-word',
                                                                                        overflow: 'visible'
                                                                                    }}
                                                                                >
                                                                                    <option value="" disabled>{t('DataPrivacyWizard.selectReason')}</option>
                                                                                    {getExclusionOptions(control, activity).map(option => (
                                                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {exclusionData && exclusionData.reason === 'other' && (
                                                                                    <input
                                                                                        type="text"
                                                                                        value={exclusionData.otherText}
                                                                                        onChange={(e) => handleOtherTextChange(activityId, controlId, e.target.value)}
                                                                                        placeholder={t('DataPrivacyWizard.exclusion.otherPlaceholder')}
                                                                                        className="mt-1 block w-full border border-gray-300 rounded-md text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mt-8">
                            <ActionButtons
                                onDraft={handleDraftSubmit}
                                onSubmit={formik.handleSubmit}
                                isSubmitting={formik.isSubmitting}
                                currentPage="toms"
                            />
                        </div>
                    </Form>
                </FormikProvider>
            </div>

            <WizardDrawer
                isOpen={drawerOpen}
                title={drawerContent.title}
                content={drawerContent.content}
                onClose={() => setDrawerOpen(false)}
            />

            <RiskControlModal
                isOpen={riskControlModalOpen}
                onClose={() => { setRiskControlModalOpen(false); setSelectedRiskControl(null); }}
                controlId={selectedRiskControl}
                riskControls={riskControls}
                fineTuneMode={true}
                riskControlOverrides={formik.values.riskControlOverrides}
                onOverrideChange={(newOverrides) => {
                    const updatedOverrides = {
                        ...formik.values.riskControlOverrides,
                        [selectedRiskControl]: newOverrides
                    };
                    formik.setFieldValue('riskControlOverrides', updatedOverrides);
                }}
            />
        </div>
    );
};

export default DataPrivacyWizardTOMs;
