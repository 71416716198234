import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import { useTranslation } from 'react-i18next';
import { getCountriesBySubregion } from '../../../constants/countryData';
import { useUserData } from '../../../hooks/useUserData';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';

function debounce(func, wait) {
  let timeout;
  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
  debounced.cancel = () => {
    clearTimeout(timeout);
  };
  return debounced;
}

const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
  const autoSaveDraft = useCallback(async () => {
    if (liveMode && values) {
      const result = await saveAsDraft(wizardName, 'introduction', values, true);
      if (result.success) {
        updateWizardData('introduction', values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, values, wizardName, updateWizardData]);

  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};

const DataPrivacyWizardIntroduction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'introduction');
  const { userData } = useUserData();
  const [page, setPage] = useState(1);
  const totalPages = 3;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
  const [openPrinciple, setOpenPrinciple] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    type: '',
    message: '',
    errors: []
  });

  const [showDpoQuestion, setShowDpoQuestion] = useState(false);
  const [showDpoDetailsQuestion, setShowDpoDetailsQuestion] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

const initialValues = useMemo(() => {
  const defaultValues = {
    dpoStatus: '', 
    disclaimerChecked: false,
    privacyResponsiblePerson: '',
    privacyResponsibleCustom: '',
    dpoName: ''
  };

  if (data && Object.keys(data).length > 0) {
    
    return {
      dpoStatus: data.dpoStatus || defaultValues.dpoStatus, 
      disclaimerChecked: Boolean(data.disclaimerChecked),
      privacyResponsiblePerson: data.privacyResponsiblePerson || defaultValues.privacyResponsiblePerson,
      privacyResponsibleCustom: data.privacyResponsibleCustom || defaultValues.privacyResponsibleCustom,
      dpoName: data.dpoName || defaultValues.dpoName
    };
  }
  
  return defaultValues;
}, [data, userData]);

  useEffect(() => {
    setCurrentSection('introduction');
  }, [setCurrentSection]);

  // Fetch team members on component mount and when page 3 is selected
  useEffect(() => {
    if (page === 3) {
      fetchTeamMembers();

      // Set the appropriate visibility of follow-up questions based on current form values
      if (initialValues.privacyResponsiblePerson) {
        setShowDpoQuestion(true);
        if (initialValues.dpoStatus === 'other') {
          setShowDpoDetailsQuestion(true);
        }
      }
    }
  }, [page, initialValues.privacyResponsiblePerson, initialValues.dpoStatus]);

  const togglePrinciple = (principle) => {
    setOpenPrinciple(openPrinciple === principle ? null : principle);
  };

  // Function to watch for changes in the privacy responsible person field
  const handlePrivacyResponsibleChange = (e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue('privacyResponsiblePerson', value);

    // Show DPO question if a valid selection is made
    if (value) {
      setShowDpoQuestion(true);
    } else {
      setShowDpoQuestion(false);
      setShowDpoDetailsQuestion(false);
      setFieldValue('dpoStatus', '');
      setFieldValue('dpoName', '');
    }
  };

  // Function to handle changes in DPO status
  const handleDpoStatusChange = (e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue('dpoStatus', value);

    // If "other" is selected, show the DPO details question
    if (value === 'other') {
      setShowDpoDetailsQuestion(true);
    } else {
      setShowDpoDetailsQuestion(false);
      setFieldValue('dpoName', '');
    }
  };

  useEffect(() => {
    if (page === 3) {
      fetchTeamMembers();

      if (initialValues.privacyResponsiblePerson) {
        setShowDpoQuestion(true);
        if (initialValues.dpoStatus === 'other') {
          setShowDpoDetailsQuestion(true);
        }
      }
    }
  }, [page, initialValues.privacyResponsiblePerson, initialValues.dpoStatus]);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      const userData = response.data;
      if (userData?.entity?.users) {
        setTeamMembers(userData.entity.users);
      }
    } catch (error) {
      console.error('Error fetching team data:', error);
    }
  };

  const validationSchema = Yup.object({
    disclaimerChecked: Yup.boolean().oneOf([true], t('DataPrivacyWizard.validation.disclaimerRequired'))
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await saveData(wizardName, 'introduction', values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('DataPrivacyWizard.dataSaved'),
        });
        updateWizardData('introduction', values, false);
        updateWizardData('introduction', values, true);
        setTimeout(() => {
          navigate('/data-privacy-wizard/ropa');
        }, 1000);
      } else {
        throw new Error(result.message || 'Error saving data');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('DataPrivacyWizard.errorSaving'),
        errors: error.message ? [error.message] : []
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDraftSubmit = async (values) => {
    try {
      const result = await saveAsDraft(wizardName, 'introduction', values, false);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('DataPrivacyWizard.draftSaved'),
        });
        updateWizardData('introduction', values, true);
      } else {
        throw new Error(result.message || 'Error saving draft');
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('DataPrivacyWizard.errorSaving'),
        errors: error.message ? [error.message] : []
      });
    }
  };

  const toggleDrawer = (title, content) => {
    if (drawerOpen && drawerContent.title === title) {
      setDrawerOpen(false);
    } else {
      setDrawerContent({
        title,
        content: `<div class="prose">${content}</div>`
      });
      setDrawerOpen(true);
    }
  };

  // This function checks the published regions (from the KnowYourself/Funding wizard)
  // and returns a highlighted field if any selected subregion includes countries with GDPR, FISMA, or PIPEDA set to true.
  const renderLegalObligations = () => {
    // Retrieve published regions data from the funding wizard
    const fundingData = userData?.entity?.know_yourself_response?.published;
    if (!fundingData) return null;
    const subregions = fundingData.subregions;
    if (!subregions || subregions.length === 0) return null;

    // Use sets to avoid duplicates
    const obligations = { GDPR: new Set(), FISMA: new Set(), PIPEDA: new Set() };

    subregions.forEach((subregion) => {
      const countries = getCountriesBySubregion(subregion);
      countries.forEach((country) => {
        // Explicitly check if the flag equals "true" (case-insensitive)
        if (String(country.gdpr).toLowerCase() === 'true') {
          obligations.GDPR.add(country.name);
        }
        if (String(country.fisma).toLowerCase() === 'true') {
          obligations.FISMA.add(country.name);
        }
        if (String(country.pipeda).toLowerCase() === 'true') {
          obligations.PIPEDA.add(country.name);
        }
      });
    });

    const gdprCountries = Array.from(obligations.GDPR);
    const fismaCountries = Array.from(obligations.FISMA);
    const pipedaCountries = Array.from(obligations.PIPEDA);

    // Only render if at least one obligation applies
    if (gdprCountries.length === 0 && fismaCountries.length === 0 && pipedaCountries.length === 0) {
      return null;
    }

    return (
      <div className="bg-tertiary-color/30 border-l-4 border-secondary-color text-gray-900 p-4 my-4">
        <p>
          {t('DataPrivacyWizard.legalObligationsIntroPrefix')}
          <a href="/funding/regions" className="text-secondary-color" target="_blank" rel="noopener noreferrer">
            {t('DataPrivacyWizard.legalObligationsIntroLinkText')}
          </a>
          {t('DataPrivacyWizard.legalObligationsIntroSuffix')}
        </p>
        {gdprCountries.length > 0 && (
          <p>
            {t('DataPrivacyWizard.legalObligationsGDPR', {
              countries: gdprCountries
                .map(country => t(`Countries.${country}`))
                .join(', ')
            })}
          </p>
        )}
        {fismaCountries.length > 0 && (
          <p>
            {t('DataPrivacyWizard.legalObligationsFISMA', {
              countries: fismaCountries
                .map(country => t(`Countries.${country}`))
                .join(', ')
            })}
          </p>
        )}
        {pipedaCountries.length > 0 && (
          <p>
            {t('DataPrivacyWizard.legalObligationsPIPEDA', {
              countries: pipedaCountries
                .map(country => t(`Countries.${country}`))
                .join(', ')
            })}
          </p>
        )}
      </div>
    );
  };

  // Page 1: Introduction with three text blocks (text1, text2, text3)
  const renderPage1 = () => (
    <div>
      <h2 className="text-xl font-bold mb-0">{t('DataPrivacyWizard.page1.heading')}</h2>
      <p className="mb-4 mt-0">{t('DataPrivacyWizard.page1.text1')}</p>
      <p className="mb-4">{t('DataPrivacyWizard.page1.text2')}</p>
      {/* Highlighted legal obligations field inserted below text2 */}
      {renderLegalObligations()}
      <p className="mb-4">{t('DataPrivacyWizard.page1.text3')}</p>
    </div>
  );

  // Page 2: GDPR principles with updated collapsible implementation
  const renderPage2 = () => {
    // Icons for each principle
    const principleIcons = {
      lawfulness: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
        </svg>
      ),
      purposeLimitation: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
        </svg>
      ),
      dataMinimization: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
        </svg>
      ),
      accuracy: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
      ),
      storageLimit: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      integrity: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
      )
    };

    const principleKeys = ['lawfulness', 'purposeLimitation', 'dataMinimization', 'accuracy', 'storageLimit', 'integrity'];

    return (
      <div>
        <h2 className="text-xl font-bold mb-0">{t('DataPrivacyWizard.page2.heading')}</h2>
        <p className="mb-6 mt-0">{t('DataPrivacyWizard.page2.intro')}</p>

        {/* Render all six principles as collapsibles */}
        {principleKeys.map((key) => (
          <div key={key} className="mb-3 border bg-white border-gray-200 rounded-lg overflow-hidden">
            <button
              type="button"
              onClick={() => togglePrinciple(key)}
              className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center">
                {principleIcons[key]}
                <span className="ml-3">{t(`DataPrivacyWizard.page2.principles.${key}.title`)}</span>
              </div>
              <svg
                className={`h-5 w-5 transform transition-transform ${openPrinciple === key ? 'rotate-180' : ''}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>

            {openPrinciple === key && (
              <div className="p-4 bg-white border-t border-gray-200">
                <p className="text-gray-700">
                  {t(`DataPrivacyWizard.page2.principles.${key}.description`)}
                </p>
              </div>
            )}
          </div>
        ))}

        {/* Conclusion and Acknowledgement Card */}
        <div className="bg-white border-4 border-secondary-color p-6 rounded-lg shadow">
          <label className="flex items-center">
            <Field
              type="checkbox"
              name="disclaimerChecked"
              className="h-6 w-6 text-secondary-color rounded focus:ring-secondary-color"
            />
            <span className="ml-5 text-secondary-color font-semibold">{t('DataPrivacyWizard.page2.acknowledgement')}</span>
          </label>
        </div>
      </div>
    );
  };

  // Page 3: Data processing register & DPO details
  const renderPage3 = () => {
    return (
      <div>
        <h2 className="text-xl font-bold">{t('DataPrivacyWizard.page3.heading')}</h2>
        <p className="mb-4 mt-0">{t('DataPrivacyWizard.page3.text1')}</p>
        <p className="mb-6">{t('DataPrivacyWizard.page3.text2')}</p>

        {/* Question 1: Who is responsible for data privacy */}
        <div className="bg-white p-6 rounded-lg shadow mb-6">
          <label className="block text-sm font-medium text-gray-900 mb-2">
            {t('DataPrivacyWizard.page3.responsibilityQuestion')}
          </label>

          <Field name="privacyResponsiblePerson">
            {({ field, form }) => (
              <div>
                <select
                  {...field}
                  className="mt-1 block w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color text-base"
                  onChange={(e) => handlePrivacyResponsibleChange(e, form.setFieldValue)}
                >
                  <option value="">{t('DataPrivacyWizard.page3.selectPerson')}</option>

                  {/* Option group for team members */}
                  {teamMembers.length > 0 && (
                    <optgroup label={t('DataPrivacyWizard.page3.teamMembers')}>
                      {teamMembers.map((member) => (
                        <option key={member.id} value={`${member.first_name} ${member.last_name}`}>
                          {member.first_name} {member.last_name}
                        </option>
                      ))}
                    </optgroup>
                  )}

                  {/* Option for other person */}
                  <option value="other">{t('DataPrivacyWizard.page3.otherPerson')}</option>
                </select>

                {/* Show text field if "other" is selected */}
                {field.value === 'other' && (
                  <div className="mt-3">
                    <label className="block text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.page3.specifyPerson')}
                    </label>
                    <Field
                      type="text"
                      name="privacyResponsibleCustom"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                      placeholder={t('DataPrivacyWizard.page3.enterName')}
                    />
                  </div>
                )}

                {form.errors.privacyResponsiblePerson && form.touched.privacyResponsiblePerson && (
                  <p className="mt-2 text-sm text-red-600">{form.errors.privacyResponsiblePerson}</p>
                )}
              </div>
            )}
          </Field>
        </div>

        {/* Question 2: Is this person the official DPO per GDPR */}
        {showDpoQuestion && (
          <div className="bg-white p-6 rounded-lg shadow mb-6">
            <label className="block text-sm font-medium text-gray-900 mb-4">
              {t('DataPrivacyWizard.page3.dpoQuestion')}
            </label>

            <Field name="dpoStatus">
              {({ field, form }) => (
                <div className="space-y-3">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="dpo-official"
                      {...field}
                      value="official"
                      checked={field.value === 'official'}
                      onChange={(e) => handleDpoStatusChange(e, form.setFieldValue)}
                      className="h-4 w-4 text-primary-color border-gray-300 focus:ring-primary-color"
                    />
                    <label htmlFor="dpo-official" className="ml-3 block text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.page3.dpoOptions.official')}
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="dpo-other"
                      {...field}
                      value="other"
                      checked={field.value === 'other'}
                      onChange={(e) => handleDpoStatusChange(e, form.setFieldValue)}
                      className="h-4 w-4 text-primary-color border-gray-300 focus:ring-primary-color"
                    />
                    <label htmlFor="dpo-other" className="ml-3 block text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.page3.dpoOptions.other')}
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="dpo-notNeeded"
                      {...field}
                      value="notNeeded"
                      checked={field.value === 'notNeeded'}
                      onChange={(e) => handleDpoStatusChange(e, form.setFieldValue)}
                      className="h-4 w-4 text-primary-color border-gray-300 focus:ring-primary-color"
                    />
                    <label htmlFor="dpo-notNeeded" className="ml-3 block text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.page3.dpoOptions.notNeeded')}
                    </label>
                    <button
                      type="button"
                      onClick={() => toggleDrawer(
                        t('DataPrivacyWizard.page3.dpoMoreInfo'),
                        t('DataPrivacyWizard.page3.dpoMoreInfoContent')
                      )}
                      className="ml-1 text-gray-400 hover:text-gray-500"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </button>
                  </div>

                  {form.errors.dpoStatus && form.touched.dpoStatus && (
                    <p className="mt-2 text-sm text-red-600">{form.errors.dpoStatus}</p>
                  )}
                </div>
              )}
            </Field>
          </div>
        )}

        {/* Question 3: Who is your DPO (only if "other" was selected) */}
        {showDpoDetailsQuestion && (
          <div className="bg-white p-6 rounded-lg shadow">
            <label className="block text-sm font-medium text-gray-900 mb-2">
              {t('DataPrivacyWizard.page3.dpoNameQuestion')}
            </label>

            <Field name="dpoName">
              {({ field, form }) => (
                <div>
                  <input
                    type="text"
                    {...field}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                    placeholder={t('DataPrivacyWizard.page3.dpoNamePlaceholder')}
                  />

                  {form.errors.dpoName && form.touched.dpoName && (
                    <p className="mt-2 text-sm text-red-600">{form.errors.dpoName}</p>
                  )}
                </div>
              )}
            </Field>
          </div>
        )}
      </div>
    );
  };

  const renderPagination = (isSubmitting) => (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-8">
      <div className="-mt-px flex w-0 flex-1">
        <button
          type="button"
          onClick={() => setPage(prev => Math.max(prev - 1, 1))}
          disabled={page === 1 || isSubmitting}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          {t('Previous')}
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            type="button"
            onClick={() => setPage(index + 1)}
            disabled={isSubmitting}
            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${page === index + 1
              ? 'border-primary-color text-primary-color'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {page !== totalPages && (
          <button
            type="button"
            onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
            disabled={isSubmitting}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
          >
            {t('Next')}
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        )}
      </div>
    </nav>
  );

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
        <LiveModeToggle onToggle={toggleLiveMode} />
        <div className="mt-10">
          <DataPrivacyWizardProgress />
        </div>
        <Notification
          show={notification.show}
          setShow={show => setNotification(prev => ({ ...prev, show }))}
          type={notification.type}
          message={notification.message}
          errors={notification.errors}
        />
        <DataStatusBanner status={dataStatus} />
        <h1 className="text-3xl text-primary-color font-bold mb-6">{t('DataPrivacyWizard.introduction')}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, isSubmitting, handleSubmit, submitForm, setFieldValue }) => (
            <Form>
              <AutoSave
                values={values}
                liveMode={liveMode}
                wizardName={wizardName}
                updateWizardData={updateWizardData}
              />
              {page === 1 && renderPage1()}
              {page === 2 && renderPage2()}
              {page === 3 && renderPage3()}
              {renderPagination(isSubmitting)}
              {page === totalPages && (
                <ActionButtons
                  onDraft={() => handleDraftSubmit(values)}
                  onSubmit={() => {
                    setHasAttemptedSubmit(true);
                    // Validate form manually before submission
                    if (page === 3) {
                      const pageErrors = {};

                      if (!values.privacyResponsiblePerson) {
                        pageErrors.privacyResponsiblePerson = t('DataPrivacyWizard.validation.privacyResponsibleRequired');
                      }

                      if (!values.dpoStatus) {
                        pageErrors.dpoStatus = t('DataPrivacyWizard.validation.dpoStatusRequired');
                      }

                      if (values.dpoStatus === 'other' && !values.dpoName) {
                        pageErrors.dpoName = t('DataPrivacyWizard.validation.dpoNameRequired');
                      }

                      // If there are validation errors, display them
                      if (Object.keys(pageErrors).length > 0) {
                        setNotification({
                          show: true,
                          type: 'error',
                          message: t('Please correct the following errors:'),
                          errors: Object.values(pageErrors)
                        });
                        return;
                      }
                    } else if (page === 2 && !values.disclaimerChecked) {
                      setNotification({
                        show: true,
                        type: 'error',
                        message: t('Please correct the following errors:'),
                        errors: [t('DataPrivacyWizard.validation.disclaimerRequired')]
                      });
                      return;
                    }

                    // Proceed with form submission
                    submitForm();
                  }}
                  isSubmitting={isSubmitting}
                  currentPage="introduction"
                  disabled={isSubmitting || (page === 2 && !values.disclaimerChecked)}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
      <WizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

export default DataPrivacyWizardIntroduction;