import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import VestingScheduleChart from '../../../components/wizard_components/VestingScheduleChart';
import { ArrowRightCircleIcon, AcademicCapIcon } from '@heroicons/react/24/outline';

const AllocationUpdater = ({ totalPostMoneyShares }) => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        // Only auto-distribute if 'all' is chosen
        if (values?.shareholderType !== 'all') return;

        if (!values?.selectedShareholders?.length) return;

        // Compute distribution if 'all' is selected.
        const targetPoolSize = Math.round((totalPostMoneyShares * values.poolPercentage) / 100);
        const computed = values.selectedShareholders.map(sh => {
            const raw = (sh.shares * values.poolPercentage) / 100;
            const floorVal = Math.floor(raw);
            const fraction = raw - floorVal;
            return {
                ...sh,
                raw,
                floorVal,
                fraction,
                allocation: floorVal,
            };
        });

        const sumFloor = computed.reduce((acc, sh) => acc + sh.floorVal, 0);
        let remainder = targetPoolSize - sumFloor;

        if (remainder > 0) {
            const sortedIndices = computed
                .map((sh, index) => ({ index, fraction: sh.fraction }))
                .sort((a, b) => b.fraction - a.fraction)
                .map(item => item.index);

            for (let i = 0; i < remainder && i < sortedIndices.length; i++) {
                computed[sortedIndices[i]].allocation += 1;
            }
        } else if (remainder < 0) {
            const sortedIndices = computed
                .map((sh, index) => ({ index, fraction: sh.fraction }))
                .sort((a, b) => a.fraction - b.fraction)
                .map(item => item.index);
            for (let i = 0; i < Math.abs(remainder) && i < sortedIndices.length; i++) {
                computed[sortedIndices[i]].allocation -= 1;
            }
        }

        const finalAllocations = computed.map(({ raw, floorVal, fraction, ...rest }) => rest);

        // Avoid re-setting if no actual change
        if (JSON.stringify(finalAllocations) !== JSON.stringify(values.selectedShareholders)) {
            setFieldValue('selectedShareholders', finalAllocations);
        }
    }, [
        values.poolPercentage,
        values.shareholderType,
        totalPostMoneyShares,
        values.selectedShareholders,
        setFieldValue
    ]);

    return null;
};

const KnowYourselfWizardVESOP = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/exitStrategy');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');
    const [totalPostMoneyShares, setTotalPostMoneyShares] = useState(0);

    // Now we have both poolPercentage and poolShares
    const baseInitialValues = {
        hasVESOPPool: true,
        poolName: 'New VESOP Pool',
        poolPercentage: 10,
        poolShares: 0,
        shareholderType: 'all',
        selectedShareholders: [],
        vestingAcceleration: true,
        vestingCliff: {
            months: 12,
            percentage: 25,
        },
        vestingSchedule: {
            totalDuration: 48,
            vestingCadence: 3,
            remainingPercentage: 75,
        },
    };

    const [initialValues, setInitialValues] = useState(baseInitialValues);

    const isDefaultValue = (value, defaultValue) => {
        if (value === defaultValue) return true;
        if (Array.isArray(value) && Array.isArray(defaultValue)) {
            return value.length === defaultValue.length;
        }
        if (typeof value === 'object' && value !== null && typeof defaultValue === 'object') {
            return Object.keys(value).every(key => isDefaultValue(value[key], defaultValue[key]));
        }
        return false;
    };

    const isDataEmpty = (data, baseInitialValues) => {
        if (!data || Object.keys(data).length === 0) return true;

        return Object.keys(baseInitialValues).every(key =>
            isDefaultValue(data[key], baseInitialValues[key])
        );
    };

    useEffect(() => {
        if (!userData?.entity?.know_yourself_response) return;

        const { published, draft } = userData.entity.know_yourself_response;
        const captableData = draft?.captable || published?.captable || {};
        const currentShareholders = captableData.currentShareholders || [];
        const targetedShareholders = captableData.targetedShareholders || [];

        const totalCurrent = currentShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
        const totalTarget = targetedShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
        setTotalPostMoneyShares(totalCurrent + totalTarget);

        // We'll set up a fallback computed array if there's no saved selectedShareholders
        const fallbackComputed = [...currentShareholders, ...targetedShareholders].map(sh => ({
            ...sh,
            allocation: Math.round((sh.shares * 10) / 100) || 0,
        }));

        const vesopDraft = draft?.vesop || {};
        const vesopPublished = published?.vesop || {};

        // Compare drafts/published minus selectedShareholders for "emptiness" checks
        const draftForComparison = { ...vesopDraft };
        const publishedForComparison = { ...vesopPublished };
        delete draftForComparison.selectedShareholders;
        delete publishedForComparison.selectedShareholders;

        const baseInitialValuesForComparison = { ...baseInitialValues };
        delete baseInitialValuesForComparison.selectedShareholders;

        const isDraftDifferent = JSON.stringify(draftForComparison) !== JSON.stringify(publishedForComparison);
        const hasValidDraftData = !isDataEmpty(draftForComparison, baseInitialValuesForComparison);
        const hasValidPublishedData = !isDataEmpty(publishedForComparison, baseInitialValuesForComparison);

        // Decide which data to load
        if (hasValidDraftData && isDraftDifferent) {
            console.log('Setting status to draft');
            setDataStatus('draft');

            // Use draft's data, but if draft doesn't have selectedShareholders, fallback
            setInitialValues({
                ...baseInitialValues,
                ...vesopDraft,
                selectedShareholders: vesopDraft?.selectedShareholders?.length
                    ? vesopDraft.selectedShareholders
                    : fallbackComputed,
            });
        } else if (hasValidPublishedData) {
            console.log('Setting status to published');
            setDataStatus('published');

            // Use published's data, but if published doesn't have selectedShareholders, fallback
            setInitialValues({
                ...baseInitialValues,
                ...vesopPublished,
                selectedShareholders: vesopPublished?.selectedShareholders?.length
                    ? vesopPublished.selectedShareholders
                    : fallbackComputed,
            });
        } else {
            console.log('Setting status to new');
            setDataStatus('new');

            // No valid data in draft or published => use base + fallback
            setInitialValues({
                ...baseInitialValues,
                selectedShareholders: fallbackComputed,
            });
        }
    }, [userData]);

    // Now we also validate poolShares
    const validationSchema = Yup.object().shape({
        hasVESOPPool: Yup.boolean(),
        poolName: Yup.string()
            .test('conditional', 'Required', function (value) {
                return !this.parent.hasVESOPPool || value;
            })
            .test('not-default-name', 'Please give your VESOP pool a fitting name', function (value) {
                return !this.parent.hasVESOPPool || (value !== 'New VESOP Pool' && value?.trim() !== '');
            }),
        poolPercentage: Yup.number()
            .test('conditional', 'Required', function (value) {
                return !this.parent.hasVESOPPool || (value >= 0 && value <= 100);
            })
            .nullable(true),
        poolShares: Yup.number()
            .min(0)
            .test('conditionalShares', 'Cannot exceed total shares', function (value) {
                const { hasVESOPPool } = this.parent;
                return !hasVESOPPool || (value <= totalPostMoneyShares);
            })
            .nullable(true),
        shareholderType: Yup.string(),
        selectedShareholders: Yup.array().of(
            Yup.object({
                allocation: Yup.number().min(0),
            })
        ).test(
            'allocation-matches-target',
            'Total allocated shares must match the target pool size',
            function (shareholders) {
                // Only validate if VESOP pool is enabled
                if (!this.parent.hasVESOPPool) {
                    return true;
                }

                // Calculate target pool size
                const targetPoolSize = Math.round(
                    (totalPostMoneyShares * this.parent.poolPercentage) / 100
                );

                // Sum actual allocation
                const currentlyAllocated = shareholders.reduce(
                    (sum, s) => sum + (s.allocation || 0),
                    0
                );

                // Must match exactly
                return currentlyAllocated === targetPoolSize;
            }
        ),
        vestingAcceleration: Yup.boolean(),
        vestingCliff: Yup.object({
            months: Yup.number().min(0).max(60),
            percentage: Yup.number().min(0).max(100),
        }),
        vestingSchedule: Yup.object({
            totalDuration: Yup.number().min(12).max(120),
            remainingPercentage: Yup.number().min(0).max(100),
            vestingCadence: Yup.number().min(1).max(12),
        }),
    });

    // Final check to distribute if 'all'; otherwise keep user's input
    const finalizeAllocations = (formValues, setFieldValue) => {
        if (formValues.shareholderType === 'all' && formValues.selectedShareholders.length > 0) {
            const targetPoolSize = Math.round(
                (totalPostMoneyShares * formValues.poolPercentage) / 100
            );
            const computed = formValues.selectedShareholders.map(sh => {
                const raw = (sh.shares * formValues.poolPercentage) / 100;
                const floorVal = Math.floor(raw);
                const fraction = raw - floorVal;
                return { ...sh, raw, floorVal, fraction, allocation: floorVal };
            });
            const sumFloor = computed.reduce((acc, sh) => acc + sh.floorVal, 0);
            let remainder = targetPoolSize - sumFloor;
            if (remainder > 0) {
                const sortedIndices = computed
                    .map((sh, index) => ({ index, fraction: sh.fraction }))
                    .sort((a, b) => b.fraction - a.fraction)
                    .map(item => item.index);
                for (let i = 0; i < remainder; i++) {
                    computed[sortedIndices[i]].allocation += 1;
                }
            } else if (remainder < 0) {
                const sortedIndices = computed
                    .map((sh, index) => ({ index, fraction: sh.fraction }))
                    .sort((a, b) => a.fraction - b.fraction)
                    .map(item => item.index);
                for (let i = 0; i < Math.abs(remainder); i++) {
                    computed[sortedIndices[i]].allocation -= 1;
                }
            }
            const finalAllocations = computed.map(({ raw, floorVal, fraction, ...rest }) => rest);
            return finalAllocations;
        }
        // If "selected", just return them as is
        return formValues.selectedShareholders;
    };

    const onSubmit = async (values, { setSubmitting, setFieldValue }) => {
        try {
            const updatedShareholders = finalizeAllocations(values, setFieldValue);
            // Explicitly ensure these fields are included
            const dataToSubmit = {
                hasVESOPPool: values.hasVESOPPool,
                poolName: values.poolName,
                ...values,
                selectedShareholders: updatedShareholders
            };

            // Save & Publish
            await handleSubmit({
                responses: {
                    vesop: dataToSubmit
                }
            });
            setNotification({
                show: true,
                type: 'success',
                message: t('Data saved and published successfully!'),
            });
            setDataStatus('published');

            // Also store it as draft for consistency, with the same explicit fields
            await handleDraftSubmit({
                responses: {
                    vesop: dataToSubmit
                }
            });

            setTimeout(() => {
                navigate('/funding/exitStrategy');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save data'),
                errors: [error.message],
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting, setFieldValue }) => {
        try {
            const updatedShareholders = finalizeAllocations(values, setFieldValue);
            // Explicitly ensure these fields are included
            const dataToSubmit = {
                hasVESOPPool: values.hasVESOPPool,
                poolName: values.poolName,
                ...values,
                selectedShareholders: updatedShareholders
            };

            await handleDraftSubmit({
                responses: {
                    vesop: dataToSubmit
                }
            });
            setNotification({
                show: true,
                type: 'success',
                message: t('Draft saved successfully')
            });
            setDataStatus('draft');
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save draft'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const pulseAnimation = `
    @keyframes customPulse {
        0%, 100% {
            transform: scale(1);
            color: rgb(75, 85, 99);
        }
        50% {
            transform: scale(1.03);
            color: var(--secondary-color);
        }
    }
`;

    useEffect(() => {
        // Insert the keyframes animation into the document
        const style = document.createElement('style');
        style.textContent = pulseAnimation;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const ToggleSwitch = ({ checked, onChange }) => {
        return (
            <div className="flex items-center mb-6 gap-4 justify-between">
                <div className="flex items-center">
                    <Switch
                        checked={!checked}
                        onChange={newValue => onChange(!newValue)}
                        className={`${!checked ? 'bg-primary-color' : 'bg-gray-200'}
                relative inline-flex h-8 w-14 items-center rounded-full transition-colors`}
                    >
                        <span
                            className={`${!checked ? 'translate-x-7' : 'translate-x-1'
                                } inline-block h-6 w-6 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                    <span className="text-lg font-medium text-gray-900 ml-3">
                        {!checked ? t('Disable VESOP Pool') : t('Enable VESOP Pool')}
                    </span>
                </div>

                {/* Conditionally show Manage VESOP button if dataStatus = 'published' */}
                {dataStatus === 'published' && !checked && (
                    <Link
                        to="/funding/vesop-management"
                        className="inline-flex items-center gap-2 text-gray-600 hover:text-secondary-color transition-all duration-300 group animate-[customPulse_5s_ease-in-out_infinite]"
                    >
                        <span className="">{t('Distribute Virtual Shares')}</span>
                        <ArrowRightCircleIcon className="w-5 h-5 transition-transform duration-300 group-hover:scale-110 animate-[customPulse_5s_ease-in-out_infinite]" />
                    </Link>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
                <Notification
                    show={notification.show}
                    setShow={show => setNotification({ ...notification, show })}
                    type={notification.type}
                    message={notification.message}
                    errors={notification.errors}
                />
                <Progress />
                <div className="data-status-banner mt-4 mb-4">
                    {dataStatus === 'published' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t('This is the last saved and published version.')}
                        </span>
                    )}
                    {dataStatus === 'draft' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t(
                                'This is a draft version. Some of the information on this page might not have been published yet.'
                            )}
                        </span>
                    )}
                    {dataStatus === 'new' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t(
                                'There is no prior version of this page. Please manually save or save as draft below.'
                            )}
                        </span>
                    )}
                </div>

                <h2 className="text-primary-color">{t('Virtual Employee Stock Option Pool')}</h2>
                <p className="mb-8">{t('KnowYourselfWizard.vesopIntro')}</p>

                {/* VESOP Recommendations */}
                <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setRecommendationsOpen(!recommendationsOpen);
                        }}
                        className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                    >
                        <div className="flex items-center">
                            <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                            <span className="text-primary-color">{t('Recommendations')}</span>
                        </div>
                        <svg
                            className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    {recommendationsOpen && (
                        <div className="p-4 bg-white rounded-b-lg border-t">
                            <div className="space-y-6 text-gray-700">
                                {/* VESOP Pool Percentage */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('vesopRecommendations.poolPercentage.title')}
                                    </h4>
                                    <p className="text-sm">
                                        {t('vesopRecommendations.poolPercentage.content')}
                                    </p>
                                </div>
                                {/* VESOP Backing */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('vesopRecommendations.backing.title')}
                                    </h4>
                                    <p className="text-sm">
                                        {t('vesopRecommendations.backing.content')}
                                    </p>
                                </div>
                                {/* Cliff Period and Cliff Percentage */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('vesopRecommendations.cliff.title')}
                                    </h4>
                                    <p className="text-sm">
                                        {t('vesopRecommendations.cliff.content')}
                                    </p>
                                </div>
                                {/* Total Vesting Duration */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('vesopRecommendations.duration.title')}
                                    </h4>
                                    <p className="text-sm">
                                        {t('vesopRecommendations.duration.content')}
                                    </p>
                                </div>
                                {/* Vesting Cadence */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('vesopRecommendations.cadence.title')}
                                    </h4>
                                    <p className="text-sm">
                                        {t('vesopRecommendations.cadence.content')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({ values, errors, touched, setFieldValue, isSubmitting, validateForm }) => {
                        const handleSubmit = async (e) => {
                            e.preventDefault();
                            const errors = await validateForm(values);
                            if (Object.keys(errors).length === 0) {
                                onSubmit(values, { setSubmitting: () => { }, setFieldValue });
                            } else {
                                const errorMessages = Object.entries(errors).map(([field, message]) => {
                                    const fieldTranslations = {
                                        poolName: t('Pool Name'),
                                        poolPercentage: t('Pool Size'),
                                        poolShares: t('Pool Shares'),
                                        selectedShareholders: t('Share Distribution'),
                                        vestingCliff: t('Vesting Cliff'),
                                        vestingSchedule: t('Vesting Schedule')
                                    };
                                    const fieldName = fieldTranslations[field] || field;
                                    return `${fieldName}: ${message}`;
                                });
                                setNotification({
                                    show: true,
                                    type: 'error',
                                    message: t('Failed to save data due to validation errors:'),
                                    errors: errorMessages
                                });
                            }
                        };
                        return (
                            <Form onSubmit={handleSubmit}>
                                <div className="space-y-6">
                                    <AllocationUpdater totalPostMoneyShares={totalPostMoneyShares} />

                                    {/* Toggle switch & optional Manage button */}
                                    <ToggleSwitch
                                        checked={!values.hasVESOPPool}
                                        onChange={checked => setFieldValue('hasVESOPPool', !checked)}
                                    />

                                    {values.hasVESOPPool && (
                                        <div className="bg-white shadow rounded-lg p-6">
                                            <>
                                                {/* Pool Settings */}
                                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                                    {/* Pool Name */}
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            {t('Pool Name')}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={values.poolName}
                                                            onChange={e => setFieldValue('poolName', e.target.value)}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                        />
                                                    </div>

                                                    {/* Pool Percentage (%) */}
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            {t('Pool Size (%)')}
                                                        </label>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <input
                                                                type="number"
                                                                value={values.poolPercentage}
                                                                onChange={e => {
                                                                    const newPercentage = e.target.value ? Number(e.target.value) : 0;
                                                                    setFieldValue('poolPercentage', newPercentage);

                                                                    // Recalculate poolShares
                                                                    const newPoolShares = Math.round(
                                                                        (totalPostMoneyShares * newPercentage) / 100
                                                                    );
                                                                    setFieldValue('poolShares', newPoolShares);
                                                                }}
                                                                className="block w-full rounded-md border-gray-300 pr-12 focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                placeholder="10"
                                                                min="0"
                                                                max="100"
                                                            />
                                                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                                <span className="text-gray-500 sm:text-sm">%</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Pool Shares */}
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            {t('Pool Shares')}
                                                        </label>
                                                        <input
                                                            type="number"
                                                            value={values.poolShares}
                                                            onChange={e => {
                                                                const newShares = e.target.value ? Number(e.target.value) : 0;
                                                                setFieldValue('poolShares', newShares);

                                                                // Recalculate poolPercentage
                                                                if (totalPostMoneyShares > 0) {
                                                                    const newPercentage = (newShares / totalPostMoneyShares) * 100;
                                                                    // round to two decimals
                                                                    setFieldValue('poolPercentage', Math.round(newPercentage * 100) / 100);
                                                                }
                                                            }}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                            min="0"
                                                            max={totalPostMoneyShares}
                                                        />
                                                        {/* Moved the label to this field */}
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            {t('of')}{' '}{totalPostMoneyShares} {t('total shares')}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Share distribution of the backing of shares */}
                                                <div className="mt-6">
                                                    <label className="block text-normal font-semibold text-gray-700 mb-2">
                                                        {t('Share distribution of the backing of shares')}
                                                    </label>
                                                    <div className="space-y-2">
                                                        <div className="flex items-center">
                                                            <input
                                                                type="radio"
                                                                checked={values.shareholderType === 'all'}
                                                                onChange={() => setFieldValue('shareholderType', 'all')}
                                                                className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300"
                                                            />
                                                            <label className="ml-2 block text-sm text-gray-900">
                                                                {t('All shareholders')}
                                                            </label>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <input
                                                                type="radio"
                                                                checked={values.shareholderType === 'selected'}
                                                                onChange={() => setFieldValue('shareholderType', 'selected')}
                                                                className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300"
                                                            />
                                                            <label className="ml-2 block text-sm text-gray-900">
                                                                {t('Selected shareholders')}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {/* The table, allowing free input if 'selected' */}
                                                    <div className="mt-4 space-y-4">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                        {t('Shareholder')}
                                                                    </th>
                                                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                        {t('Current Shares')}
                                                                    </th>
                                                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                        {t('Shares to VESOP')}
                                                                    </th>
                                                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                        {t('% of Own Shares')}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200">
                                                                {values.selectedShareholders.map((shareholder, index) => {
                                                                    const shareCount = shareholder.shares || 0;
                                                                    const allocCount = shareholder.allocation || 0;
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                                                                {shareholder.name}
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                {shareCount}
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                <input
                                                                                    type="number"
                                                                                    value={allocCount}
                                                                                    onChange={e => {
                                                                                        const newAllocation = Number(e.target.value) || 0;
                                                                                        // Only allow direct edit if user chose 'selected'
                                                                                        if (values.shareholderType === 'selected') {
                                                                                            const newShareholders = [...values.selectedShareholders];
                                                                                            newShareholders[index].allocation = newAllocation;
                                                                                            setFieldValue('selectedShareholders', newShareholders);
                                                                                        }
                                                                                    }}
                                                                                    className="w-24 rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                                    disabled={values.shareholderType === 'all'}
                                                                                    min="0"
                                                                                    max={shareCount}
                                                                                />
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                {shareCount
                                                                                    ? ((allocCount / shareCount) * 100).toFixed(2)
                                                                                    : '0.00'}
                                                                                %
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* Summation info / target pool size */}
                                                    {(() => {
                                                        // Calculate target pool size from the total post-money shares.
                                                        const targetPoolSize = Math.round(
                                                            (totalPostMoneyShares * values.poolPercentage) / 100
                                                        );
                                                        // Sum actual allocation from the table
                                                        const currentlyAllocated = values.selectedShareholders.reduce(
                                                            (sum, s) => sum + (s.allocation || 0),
                                                            0
                                                        );

                                                        const isExact = targetPoolSize === currentlyAllocated;
                                                        return (
                                                            <div
                                                                className={`mt-4 p-4 rounded-md ${isExact ? 'bg-green-50 text-green-700' : 'bg-yellow-50 text-yellow-700'
                                                                    }`}
                                                            >
                                                                <p className="text-sm">
                                                                    {t('Target pool size:')} {targetPoolSize} {t('shares')}
                                                                    <br />
                                                                    {t('Currently allocated:')} {currentlyAllocated} {t('shares')}
                                                                </p>
                                                            </div>
                                                        );
                                                    })()}
                                                </div>

                                                {/* Vesting Schedule */}
                                                <div className="border-t border-gray-200 pt-6 mt-6">
                                                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                                                        {t('Vesting Schedule')}
                                                    </h3>
                                                    <div className="space-y-6">
                                                        <div className="flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={values.vestingAcceleration}
                                                                onChange={e => setFieldValue('vestingAcceleration', e.target.checked)}
                                                                className="h-4 w-4 text-primary-color focus:ring-primary-color border-gray-300 rounded"
                                                            />
                                                            <label className="ml-2 block text-sm text-gray-900">
                                                                {t('Vesting acceleration at exit')}
                                                            </label>
                                                        </div>

                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                    {t('Cliff Period (months)')}
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    value={values.vestingCliff.months}
                                                                    onChange={e =>
                                                                        setFieldValue('vestingCliff.months', Number(e.target.value))
                                                                    }
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                    min="0"
                                                                    max="60"
                                                                />
                                                            </div>

                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                    {t('Cliff Percentage')}
                                                                </label>
                                                                <div className="mt-1 relative rounded-md shadow-sm">
                                                                    <input
                                                                        type="number"
                                                                        value={values.vestingCliff.percentage}
                                                                        onChange={e =>
                                                                            setFieldValue('vestingCliff.percentage', Number(e.target.value))
                                                                        }
                                                                        className="block w-full rounded-md border-gray-300 pr-12 focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                        min="0"
                                                                        max="100"
                                                                    />
                                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                                        <span className="text-gray-500 sm:text-sm">%</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                    {t('Total Vesting Duration (months)')}
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    value={values.vestingSchedule.totalDuration}
                                                                    onChange={e =>
                                                                        setFieldValue('vestingSchedule.totalDuration', Number(e.target.value))
                                                                    }
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                    min="12"
                                                                    max="120"
                                                                />
                                                            </div>

                                                            <div>
                                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                    {t('Vesting Cadence (months)')}
                                                                </label>
                                                                <select
                                                                    value={values.vestingSchedule.vestingCadence}
                                                                    onChange={e =>
                                                                        setFieldValue('vestingSchedule.vestingCadence', Number(e.target.value))
                                                                    }
                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                                                >
                                                                    <option value={1}>{t('Monthly')}</option>
                                                                    <option value={3}>{t('Quarterly')}</option>
                                                                    <option value={6}>{t('Semi-annually')}</option>
                                                                    <option value={12}>{t('Annually')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <VestingScheduleChart
                                                            vestingCliff={values.vestingCliff}
                                                            vestingSchedule={values.vestingSchedule}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    )}

                                    {/* Bottom buttons */}
                                    <div className="flex items-center justify-end gap-x-6">
                                        <button
                                            type="button"
                                            className="text-sm font-normal leading-6 text-gray-900"
                                            onClick={() => navigate('/funding')}
                                        >
                                            {t('cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                            disabled={isSubmitting}
                                            className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600"
                                        >
                                            {t('Save as Draft')}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
                                        >
                                            {isSubmitting ? t('saving') : t('save')}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default KnowYourselfWizardVESOP;