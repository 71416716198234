// src/components/FlowChart/components/ControlPanel.js
import { Panel } from 'reactflow';
import { ChevronDownIcon, ArrowPathIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const ControlPanel = ({ 
  onAutoLayout, 
  autoLayoutRunning, 
  onSave, 
  isSaving, 
  hasUnsavedChanges,
  onExport,
  nodeCount
}) => {
  return (
    <Panel position="top-right" className="bg-white p-2 rounded shadow-md border border-gray-200">
      <div className="flex flex-col space-y-2">
        <button
          className="bg-primary-color text-white px-3 py-1 rounded text-sm disabled:opacity-50 flex items-center"
          onClick={onAutoLayout}
          disabled={autoLayoutRunning || nodeCount < 2}
        >
          <ArrowPathIcon className={`h-4 w-4 mr-1 ${autoLayoutRunning ? 'animate-spin' : ''}`} />
          {autoLayoutRunning ? 'Running...' : 'Auto Layout'}
        </button>
        
        {onSave && (
          <button
            className={`${hasUnsavedChanges ? 'bg-blue-600' : 'bg-blue-500'} 
                      text-white px-3 py-1 rounded text-sm disabled:opacity-50 flex items-center`}
            onClick={onSave}
            disabled={isSaving || !hasUnsavedChanges}
          >
            <ArrowDownTrayIcon className="h-4 w-4 mr-1" />
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        )}
        
        {onExport && (
          <div className="relative group">
            <button
              className="bg-gray-700 text-white px-3 py-1 rounded text-sm flex items-center"
              onClick={() => {}}
            >
              Export <ChevronDownIcon className="h-4 w-4 ml-1" />
            </button>
            <div className="absolute right-0 mt-1 w-36 bg-white border border-gray-200 rounded shadow-lg hidden group-hover:block z-10">
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => onExport('png')}
              >
                Export as PNG
              </button>
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => onExport('svg')}
              >
                Export as SVG
              </button>
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default ControlPanel;