// src/components/wizard_components/DataPrivacyWizard/AdditionalControls.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Popover } from 'flowbite-react';

const AdditionalControls = ({
    additionalControls,
    selectedControls,
    onToggleSelection,
    onViewDetails,
    riskControls,
    userLang
}) => {
    const { t } = useTranslation();
    const [riskControlsModalOpen, setRiskControlsModalOpen] = useState(false);

    // Get the selected additional controls (from the additional controls list)
    const selectedAdditionalControls = selectedControls.filter(id =>
        additionalControls.some(control => control.id === id)
    ).map(id => riskControls.find(control => control.id === id)).filter(Boolean);

    // Function to toggle risk control selection
    const toggleRiskControl = (control) => {
        onToggleSelection(control.id);
    };

    // Render method for risk controls modal
    const renderRiskControlsModal = () => {
        return (
            riskControlsModalOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl relative">
                            {/* Close button */}
                            <button
                                type="button"
                                onClick={() => setRiskControlsModalOpen(false)}
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
                            >
                                <span className="sr-only">{t('DataPrivacyWizard.close')}</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                                    {t('DataPrivacyWizard.riskControls')}
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    {additionalControls.map(control => (
                                        <div
                                            key={control.id}
                                            className={`border rounded-lg p-4 relative cursor-pointer ${selectedControls.includes(control.id)
                                                ? 'border-primary-color bg-blue-50'
                                                : 'border-gray-300'
                                                }`}
                                            onClick={() => toggleRiskControl(control)}
                                        >
                                            <div className="flex justify-between items-start">
                                                <div className="flex items-center space-x-2">
                                                    {control.icons && (
                                                        <img
                                                            src={`/is_icons/${control.icons}`}
                                                            alt={control.id}
                                                            className="h-8 w-8"
                                                        />
                                                    )}
                                                    <span className="text-sm font-semibold text-[#207425]">{control.id}</span>
                                                </div>
                                                <div
                                                    className="flex items-center h-5"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent parent div's onClick
                                                        toggleRiskControl(control);
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedControls.includes(control.id)}
                                                        onChange={() => { }} // No-op since we're handling change via onClick
                                                        className="h-4 w-4 text-primary-color border-gray-300 rounded cursor-pointer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <p className="text-sm font-semibold text-gray-900">
                                                    {userLang === 'de' ? control.control_name_de : control.control_name}
                                                </p>
                                                <p className="mt-1 text-xs text-gray-600">
                                                    {userLang === 'de' ? control.control_definition_d : control.control_definition}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={() => setRiskControlsModalOpen(false)}
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-color text-base font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    {t('DataPrivacyWizard.done')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    return (
        <>
            {/* Additional Controls */}
            <div>
                {selectedAdditionalControls.length === 0 ? (
                    <button
                        type="button"
                        onClick={() => setRiskControlsModalOpen(true)}
                        className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center hover:border-primary-color"
                    >
                        {t('DataPrivacyWizard.addRiskControls')}
                    </button>
                ) : (
                    <div className="flex flex-wrap gap-4 items-center">
                        {selectedAdditionalControls.map(control => (
                            <Popover
                                key={control.id}
                                trigger="hover"
                                placement="top"
                                content={
                                    <div className="w-64 text-sm">
                                        <div className="px-3 py-2 bg-[#B0E199] border-b border-gray-200 rounded-t-lg">
                                            <p className="font-semibold text-sm text-[#207425]">
                                                {userLang === 'de' ? control.control_name_de : control.control_name}
                                            </p>
                                        </div>
                                        <div className="px-3 py-2">
                                            <p className="text-gray-700 text-xs">
                                                {userLang === 'de' ? control.control_definition_d : control.control_definition}
                                            </p>
                                        </div>
                                    </div>
                                }
                            >
                                <div className="relative group cursor-help">
                                    {control.icons && (
                                        <img
                                            src={`/is_icons/${control.icons}`}
                                            alt={control.id}
                                            className="h-10 w-10 hover:scale-110 transition-transform"
                                        />
                                    )}
                                </div>
                            </Popover>
                        ))}
                        <button
                            type="button"
                            onClick={() => setRiskControlsModalOpen(true)}
                            className="h-10 px-3 flex items-center justify-center rounded-md bg-gray-100 text-gray-700 hover:bg-secondary-color hover:text-white transition-colors ml-1 text-sm font-medium"
                        >
                            {t('DataPrivacyWizard.editRiskControls')}
                        </button>
                    </div>
                )}
            </div>

            {/* Render the modal */}
            {renderRiskControlsModal()}
        </>
    );
};

export default AdditionalControls;