import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AlertsContext } from '../context/AlertsContext';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import {
  CheckCircleIcon,
  CalendarIcon,
  ClockIcon,
  BellIcon,
  ArrowPathIcon,
  TrashIcon,
  UserIcon,
  ChevronDownIcon,
  PlusCircleIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getTranslatedValue } from '../utils/templateTranslations';
import axios from '../axios';

export default function Alerts() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.slice(0, 2);

  const { user } = useContext(AuthContext);
  const {
    alerts,
    fetchAlerts,
    acknowledgeAlert,
    preAcknowledgeAlert,
    deleteAlert,
    resetAlerts
  } = useContext(AlertsContext);

  const [error, setError] = useState(null);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showRecipientDropdown, setShowRecipientDropdown] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isEmailSettingsModalOpen, setIsEmailSettingsModalOpen] = useState(false);
  const [emailSettings, setEmailSettings] = useState({
    individual_alerts: true,
    daily_summary: true,
    weekly_summary: true
  });
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);
  const openEmailSettingsModal = () => {
    setIsEmailSettingsModalOpen(true);
    fetchEmailSettings();
  };
  const closeEmailSettingsModal = () => setIsEmailSettingsModalOpen(false);

  // Form data for creating a new alert
  const [newAlertData, setNewAlertData] = useState({
    name: '',
    alert_time: '',
    repetition_type: '',
    recipients: user ? [user.id] : [],
    searchTerm: ''
  });

  useEffect(() => {
    fetchAlerts(true);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        const usersFromBackend = response.data.entity?.users || [];
        setCompanyUsers(usersFromBackend);

        // Set email notification settings if they exist
        if (response.data.user && response.data.user.email_notification_settings) {
          setEmailSettings(response.data.user.email_notification_settings);
        }
      } catch (err) {
        console.error('Error fetching company users:', err);
      }
    };
    fetchUsers();
    fetchEmailSettings()
  }, []);

  const handleEmailSettingsChange = (setting) => {
    setEmailSettings(prev => ({
      ...prev,
      [setting]: !prev[setting]
    }));
  };

  const saveEmailSettings = async () => {
    setIsUpdatingSettings(true);
    try {
      // Change the endpoint from '/api/v1/users/update_email_settings' to '/api/v1/alerts/update_email_settings'
      await axios.patch('/api/v1/alerts/update_email_settings', {
        email_notification_settings: emailSettings
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      closeEmailSettingsModal();
      // Optionally show success message
    } catch (error) {
      console.error('Error saving email settings:', error);
      setError(t('Failed to update email settings'));
    } finally {
      setIsUpdatingSettings(false);
    }
  };

  const fetchEmailSettings = async () => {
    try {
      const response = await axios.get('/api/v1/alerts/email_settings', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      if (response.data && response.data.email_notification_settings) {
        setEmailSettings(response.data.email_notification_settings);
      }
    } catch (error) {
      console.error('Error fetching email settings:', error);
    }
  };

  const loadAlerts = async () => {
    try {
      await fetchAlerts(true);
      setError(null);
      resetAlerts();
    } catch (err) {
      console.error('Error loading alerts:', err);
      setError(t('Failed to load alerts'));
    }
  };

  const handleAcknowledge = async (alertId) => {
    try {
      const result = await acknowledgeAlert(alertId);
      if (!result) {
        setError(t('Failed to acknowledge alert'));
      } else {
        await fetchAlerts(true);
      }
    } catch (err) {
      console.error('Error acknowledging alert:', err);
      setError(t('Failed to acknowledge alert'));
    }
  };

  const handlePreAcknowledge = async (alertId) => {
    try {
      const result = await preAcknowledgeAlert(alertId);
      if (!result) {
        setError(t('Failed to pre-acknowledge alert'));
      } else {
        await fetchAlerts(true);
      }
    } catch (err) {
      console.error('Error pre-acknowledging alert:', err);
      setError(t('Failed to pre-acknowledge alert'));
    }
  };

  const handleDelete = async (alertId) => {
    try {
      const result = await deleteAlert(alertId);
      if (!result) {
        setError(t('Failed to delete alert'));
      } else {
        loadAlerts();
      }
    } catch (err) {
      console.error('Error deleting alert:', err);
      setError(t('Failed to delete alert'));
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(currentLang, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(date);
  };

  // Improved relative time function
  const getRelativeTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = date - now;

    // Get absolute time difference in minutes for better precision
    const diffMinutes = Math.floor(Math.abs(diffMs) / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    // For past dates (alert already happened)
    if (diffMs < 0) {
      if (diffMinutes < 60) {
        return t('{{minutes}} minutes ago', { minutes: diffMinutes || 1 });
      } else if (diffHours < 24) {
        return t('{{hours}} hours ago', { hours: diffHours });
      } else if (diffDays === 1) {
        return t('Yesterday');
      } else {
        return t('{{days}} days ago', { days: diffDays });
      }
    }
    // For future dates (alert will happen)
    else {
      if (diffMinutes < 60) {
        return t('In {{minutes}} minutes', { minutes: diffMinutes || 1 });
      } else if (diffHours < 24) {
        return t('In {{hours}} hours', { hours: diffHours });
      } else if (diffDays === 1) {
        return t('Tomorrow');
      } else {
        return t('In {{days}} days', { days: diffDays });
      }
    }
  };

  const renderAlertIcon = (alert) => {
    if (alert.repeats || alert.repetition_type || alert.alert_type === 'repeating_data_collection') {
      return <ArrowPathIcon className="h-5 w-5 text-primary-color" />;
    }
    return <BellIcon className="h-5 w-5 text-primary-color" />;
  };

  const renderAlertName = (alert) => {
    if (alert.alert_type === 'repeating_data_collection') {
      const repData = alert.repetition_data || {};
      let templateName = '';
      if (repData.template_name && typeof repData.template_name === 'object') {
        templateName = getTranslatedValue(repData.template_name, currentLang);
      } else if (repData.template_name) {
        templateName = repData.template_name;
      }

      return (
        <>
          {t('data_collection_alert')} {templateName} {repData.period_value ? ' - ' + repData.period_value : ''}
        </>
      );
    }
    return getTranslatedValue(alert.name, currentLang);
  };

  const renderAlertList = (alertsList, showAcknowledge = false) => {
    if (!alertsList || alertsList.length === 0) {
      return (
        <div className="text-center py-10 text-gray-500">
          {t('No alerts')}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {alertsList.map((alert) => (
          <div key={alert.id} className="bg-white rounded-lg shadow p-4 w-full">
            <div className="flex justify-between items-start mb-2 pt-1">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {renderAlertIcon(alert)}
                </div>
                <h3 className="ml-2 text-sm font-medium text-gray-900">
                  {renderAlertName(alert)}
                </h3>
              </div>

              <button
                onClick={() => handleDelete(alert.id)}
                className="text-red-600 hover:text-red-800 p-1"
                title={t('Delete')}
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>

            <div className="flex justify-between items-center pb-1">
              <div className="flex items-center space-x-4">
                <div className="flex items-center text-sm text-gray-500">
                  <CalendarIcon className="mr-1.5 h-4 w-4 text-gray-400" />
                  <span>{formatDate(alert.alert_time)}</span>
                </div>

                <div className="flex items-center text-sm text-gray-500">
                  <ClockIcon className="mr-1.5 h-4 w-4 text-gray-400" />
                  <span>{getRelativeTime(alert.alert_time)}</span>
                </div>

                {alert.creator && (
                  <div className="flex items-center text-sm text-gray-500">
                    <UserIcon className="mr-1.5 h-4 w-4 text-gray-400" />
                    <span>{alert.creator}</span>
                  </div>
                )}

                {alert.acknowledged_at && (
                  <div className="flex items-center text-sm text-secondary-color">
                    <CheckCircleIcon className="mr-1.5 h-4 w-4" />
                    <span>
                      {t('Acknowledged')} {formatDate(alert.acknowledged_at)}
                    </span>
                  </div>
                )}

                {alert.repetition_type && (
                  <div className="flex items-center text-sm text-gray-500">
                    <ArrowPathIcon className="mr-1.5 h-4 w-4 text-gray-400" />
                    <span>{(() => {
                      return t(alert.repetition_type);
                    })()}</span>
                  </div>
                )}
              </div>

              <div className="flex space-x-2">
                {alert.status === 'future' && !alert.acknowledged_at && (
                  <button
                    onClick={() => handlePreAcknowledge(alert.id)}
                    className="inline-flex items-center px-3 py-1 border border-secondary-color text-secondary-color text-sm font-medium rounded hover:bg-secondary-color hover:text-white"
                  >
                    {t('Pre-acknowledge')}
                  </button>
                )}

                {alert.source_link && (
                  <Link
                    to={alert.source_link}
                    className="inline-flex items-center px-3 py-1 border border-primary-color text-primary-color text-sm font-medium rounded hover:bg-primary-color hover:text-white"
                  >
                    {t('View')}
                  </Link>
                )}

                {showAcknowledge && !alert.acknowledged_at && alert.status === 'active' && (
                  <button
                    onClick={() => handleAcknowledge(alert.id)}
                    className="inline-flex items-center px-3 py-1 border border-secondary-color text-secondary-color text-sm font-medium rounded hover:bg-secondary-color hover:text-white"
                  >
                    {t('Acknowledge')}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const handleCreateAlertChange = (field, value) => {
    setNewAlertData((prev) => ({ ...prev, [field]: value }));
  };

  const handleRecipientToggle = (userId) => {
    setNewAlertData((prev) => {
      const recipients = prev.recipients.includes(userId)
        ? prev.recipients.filter((id) => id !== userId)
        : [...prev.recipients, userId];
      return { ...prev, recipients };
    });
  };

  const filteredRecipients = companyUsers.filter((u) => {
    const fullName = `${u.first_name} ${u.last_name}`.toLowerCase();
    return (
      fullName.includes(newAlertData.searchTerm.toLowerCase()) ||
      u.email.toLowerCase().includes(newAlertData.searchTerm.toLowerCase())
    );
  });

  const handleCreateAlertSubmit = async (e) => {
    e.preventDefault();

    // Validate
    const errors = {};
    if (!newAlertData.name.trim()) {
      errors.name = t('Alert name is required');
    }
    if (!newAlertData.alert_time) {
      errors.alert_time = t('Alert time is required');
    }
    if (newAlertData.recipients.length === 0) {
      errors.recipients = t('At least one recipient is required');
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});

    // Handle timezone
    let alertTime = newAlertData.alert_time;
    if (alertTime) {
      const dateObj = new Date(alertTime);
      alertTime = dateObj.toISOString();
    }

    const payload = {
      name: newAlertData.name,
      alert_time: alertTime,
      repetition_type: newAlertData.repetition_type || null,
      repeats: !!newAlertData.repetition_type,
      recipients: newAlertData.recipients,
      alert_type: 'manual',
      source_link: '',
      email_reminder: false
    };

    try {
      await axios.post('/api/v1/alerts', payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      closeCreateModal();
      await fetchAlerts(true);
    } catch (err) {
      console.error('Error creating alert:', err);
      setError(t('Failed to create alert'));
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Page Header - updated to include Email Settings button */}
      <div className="flex items-center justify-between mb-6 lg:pt-6 pt-16">
        <h1 className="text-2xl font-bold">
          {t('Alerts')}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={openEmailSettingsModal}
            className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            <img src={`${process.env.PUBLIC_URL}/ux_icons/mailsettings.svg`} alt="email settings" className="h-5 w-5 fill-current text-primary-color" />
          </button>
          <button
            onClick={openCreateModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-secondary-color focus:outline-none"
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            {t('Create Alert')}
          </button>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4 text-red-700">
          {error}
        </div>
      )}

      {/* Tabs - updated styling to match reports page */}
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1 mb-4">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none ${selected
                ? 'bg-white font-semibold text-secondary-color'
                : 'text-gray-500 hover:bg-gray-300 hover:text-gray-600'
              }`
            }
          >
            {t('Active')} ({alerts.active ? alerts.active.length : 0})
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none ${selected
                ? 'bg-white font-semibold text-secondary-color'
                : 'text-gray-500 hover:bg-gray-300 hover:text-gray-600'
              }`
            }
          >
            {t('Upcoming')} ({alerts.upcoming ? alerts.upcoming.length : 0})
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none ${selected
                ? 'bg-white font-semibold text-secondary-color'
                : 'text-gray-500 hover:bg-gray-300 hover:text-gray-600'
              }`
            }
          >
            {t('Past')} ({alerts.past ? alerts.past.length : 0})
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {renderAlertList(alerts.active, true)}
          </Tab.Panel>
          <Tab.Panel>
            {renderAlertList(alerts.upcoming)}
          </Tab.Panel>
          <Tab.Panel>
            {renderAlertList(alerts.past)}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* EMAIL SETTINGS MODAL */}
      {isEmailSettingsModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25 flex items-center justify-center">
          <div className="bg-white w-full max-w-md rounded-2xl p-6 shadow-xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('Email Notification Settings')}
              </h3>
            </div>

            <div className="space-y-4">
              <p className="text-sm text-gray-500">
                {t('Choose how you would like to receive alert notifications via email.')}
              </p>

              <div className="space-y-3">
                {/* Individual Alerts Checkbox */}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="individual_alerts"
                      name="individual_alerts"
                      type="checkbox"
                      checked={emailSettings.individual_alerts}
                      onChange={() => handleEmailSettingsChange('individual_alerts')}
                      className="h-4 w-4 text-secondary-color border-gray-300 rounded focus:ring-secondary-color"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="individual_alerts" className="font-medium text-gray-700">
                      {t('Individual Alerts')}
                    </label>
                    <p className="text-gray-500 text-xs">
                      {t('Receive an email notification each time an alert becomes active.')}
                    </p>
                  </div>
                </div>

                {/* Daily Summary Checkbox */}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="daily_summary"
                      name="daily_summary"
                      type="checkbox"
                      checked={emailSettings.daily_summary}
                      onChange={() => handleEmailSettingsChange('daily_summary')}
                      className="h-4 w-4 text-secondary-color border-gray-300 rounded focus:ring-secondary-color"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="daily_summary" className="font-medium text-gray-700">
                      {t('Daily Summary')}
                    </label>
                    <p className="text-gray-500 text-xs">
                      {t('Receive a daily summary of your alerts at the end of each day.')}
                    </p>
                  </div>
                </div>

                {/* Weekly Summary Checkbox */}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="weekly_summary"
                      name="weekly_summary"
                      type="checkbox"
                      checked={emailSettings.weekly_summary}
                      onChange={() => handleEmailSettingsChange('weekly_summary')}
                      className="h-4 w-4 text-secondary-color border-gray-300 rounded focus:ring-secondary-color"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="weekly_summary" className="font-medium text-gray-700">
                      {t('Weekly Summary')}
                    </label>
                    <p className="text-gray-500 text-xs">
                      {t('Receive a weekly summary of your alerts at the end of the week.')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Buttons */}
            <div className="flex justify-end space-x-2 mt-6">
              <button
                type="button"
                onClick={closeEmailSettingsModal}
                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                onClick={saveEmailSettings}
                disabled={isUpdatingSettings}
                className="px-4 py-2 text-sm font-medium text-white bg-secondary-color hover:bg-secondary-color/80 rounded disabled:opacity-50"
              >
                {isUpdatingSettings ? t('Saving...') : t('Save Settings')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* CREATE ALERT MODAL */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-25 flex items-center justify-center">
          <div className="bg-white w-full max-w-md rounded-2xl p-6 shadow-xl">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              {t('Create Alert')}
            </h3>
            <form onSubmit={handleCreateAlertSubmit} className="space-y-4">
              {/* Alert Name */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t('Alert Name')}
                </label>
                <input
                  type="text"
                  value={newAlertData.name}
                  onChange={(e) => handleCreateAlertChange('name', e.target.value)}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm ${validationErrors.name ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
                    }`}
                  required
                />
                {validationErrors.name && (
                  <p className="mt-1 text-sm text-red-600">{validationErrors.name}</p>
                )}
              </div>

              {/* Alert Time */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t('Alert Time')}
                </label>
                <input
                  type="datetime-local"
                  value={newAlertData.alert_time}
                  onChange={(e) => handleCreateAlertChange('alert_time', e.target.value)}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm ${validationErrors.alert_time ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
                    }`}
                  required
                />
                {validationErrors.alert_time && (
                  <p className="mt-1 text-sm text-red-600">{validationErrors.alert_time}</p>
                )}
              </div>

              {/* Repetition */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t('Repetition')}
                </label>
                <div className="relative">
                  <select
                    value={newAlertData.repetition_type}
                    onChange={(e) => handleCreateAlertChange('repetition_type', e.target.value)}
                    className="block w-full rounded-md border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm appearance-none"
                  >
                    <option value="">{t('None')}</option>
                    <option value="monthly">{t('Monthly')}</option>
                    <option value="quarterly">{t('Quarterly')}</option>
                    <option value="yearly">{t('Yearly')}</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
                    <ChevronDownIcon className="h-4 w-4" />
                  </div>
                </div>
              </div>

              {/* Recipients */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t('Recipients')}
                </label>
                <div className="relative mt-1">
                  <button
                    type="button"
                    onClick={() => setShowRecipientDropdown(!showRecipientDropdown)}
                    className="flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-color"
                  >
                    <span>{t('Select recipients')} ({newAlertData.recipients.length} {t('selected')})</span>
                    <ChevronDownIcon className="h-4 w-4" />
                  </button>

                  {showRecipientDropdown && (
                    <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
                      <div className="p-2">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            placeholder={t('Search user')}
                            value={newAlertData.searchTerm}
                            onChange={(e) => handleCreateAlertChange('searchTerm', e.target.value)}
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-color focus:border-secondary-color"
                          />
                        </div>
                      </div>

                      <ul className="max-h-48 py-1 overflow-y-auto text-base">
                        {filteredRecipients.length === 0 && (
                          <li className="px-3 py-2 text-sm text-gray-500">{t('No users found')}</li>
                        )}
                        {filteredRecipients.map((user) => (
                          <li key={user.id} className="relative">
                            <div className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer">
                              <input
                                type="checkbox"
                                id={`user-${user.id}`}
                                checked={newAlertData.recipients.includes(user.id)}
                                onChange={() => handleRecipientToggle(user.id)}
                                className="h-4 w-4 text-secondary-color border-gray-300 rounded focus:ring-secondary-color"
                              />
                              <label htmlFor={`user-${user.id}`} className="w-full ml-2 text-sm text-gray-700">
                                {user.first_name} {user.last_name} ({user.email})
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {validationErrors.recipients && (
                  <p className="mt-1 text-sm text-red-600">{validationErrors.recipients}</p>
                )}
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-2 pt-4">
                <button
                  type="button"
                  onClick={closeCreateModal}
                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded"
                >
                  {t('Cancel')}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-secondary-color hover:bg-secondary-color/80 rounded"
                >
                  {t('Create')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}