// CollapsibleSection.js
import React from 'react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const CollapsibleSection = ({ 
  title, 
  isExpanded, 
  onToggle, 
  children, 
  selectedValue = null,
  noMaxHeight = false,
  disabled = false // Add this new prop
}) => {
  return (
    <div className="border rounded-md mb-4">
      <div 
        className={`flex justify-between items-center p-3 bg-gray-50 border-b ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        onClick={disabled ? undefined : onToggle}
      >
        <h3 className="text-base font-medium text-gray-700 flex items-center">
          {title}
          {selectedValue && (
            <span className="ml-2 text-secondary-color">
              {selectedValue}
            </span>
          )}
        </h3>
        {!disabled && (isExpanded ? (
          <ChevronDownIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronRightIcon className="h-5 w-5 text-gray-500" />
        ))}
      </div>
      
      {isExpanded && (
        <div className={`${noMaxHeight ? '' : 'max-h-64 overflow-y-auto'}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsibleSection;