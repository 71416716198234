import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ActionButtons = ({ onDraft, onSubmit, isSubmitting, currentPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/startup-wizard');
  };

  return (
    <div className="mt-8 flex justify-end space-x-4">
      <button
        type="button"
        onClick={handleCancel}
        className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
      >
        {t('Cancel')}
      </button>
      <button
        type="button"
        onClick={onDraft}
        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
      >
        {t('Save as Draft')}
      </button>
      <button
        type="button" 
        onClick={onSubmit}
        disabled={isSubmitting}
        className="px-4 py-2 text-sm font-medium text-white bg-primary-color border border-transparent rounded-md shadow-sm hover:bg-secondary-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
      >
        {isSubmitting ? t('Saving...') : t('Save')}
      </button>
    </div>
  );
};

export default ActionButtons;