import React, { useState, useEffect, useMemo } from 'react';
import { UserIcon, BuildingOfficeIcon, PlusIcon } from '@heroicons/react/24/outline';
import * as ShareUtils from '../../../utils/share-utils';
import ShareholderModal from '../../../components/wizard_components/ShareholderModal';
import TransactionForm from '../../../components/wizard_components/TransactionForm';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';  // if you use a custom hook
import Notification from '../../../components/Notification';
import { useTranslation } from 'react-i18next';

/**
 * If you want a minimal check for existing cap table, define your own logic.
 * We'll say: "2 or more shareholders, 2 or more transactions => skip wizard."
 */
function hasMinimumCapTable(captable) {
  if (!captable) return false;
  const { shareholders = [], transactions = [] } = captable;
  return shareholders.length >= 2 && transactions.length >= 2;
}

const KnowYourselfWizardCapTableHistory = () => {
  const { t } = useTranslation();
  // Basic local defaults
  const basicInitialValues = {
    shareholders: [
      {
        id: 'company',
        type: 'company',
        name: 'Company', // updated from userData eventually
        entryDate: new Date().toISOString(),
        entryRound: 'initial',
        status: 'complete',
        shareNumbers: '',
      },
    ],
    transactions: [],
    totalShares: 25000,
  };

  const [shareholders, setShareholders] = useState(basicInitialValues.shareholders);
  const [transactions, setTransactions] = useState(basicInitialValues.transactions);
  const [totalShares, setTotalShares] = useState(basicInitialValues.totalShares);

  const [showAddShareholder, setShowAddShareholder] = useState(false);
  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const [selectedShareholder, setSelectedShareholder] = useState(null);
  const [didLoadWizardData, setDidLoadWizardData] = useState(false);
  const [error, setError] = useState('');

  const { userData } = useUserData();

  // If you have a custom hook for saving, similar to VESOP:
  const { handleSubmit } = useFormSubmit('/wizards/know_yourself');

  useEffect(() => {
    const loadLordIcon = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.lordicon.com/lusqsztk.js';
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const script = loadLordIcon();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * CAP TABLE SUMMARY logic
   */
  const calculateShareholderStats = () => {
    const stats = new Map();
    // Initialize
    shareholders.forEach((sh) => {
      stats.set(sh.id, { totalShares: 0, shareBlocks: [] });
    });

    // Tally up transactions
    transactions.forEach((tx) => {
      if (tx.type === 'created') {
        const buyerStat = stats.get(tx.buyerId);
        if (buyerStat) {
          buyerStat.totalShares += tx.shares;
          buyerStat.shareBlocks.push(tx.shareNumberGroup);
        }
      } else {
        // allocated or transferred
        const sellerStat = stats.get(tx.sellerId);
        if (sellerStat) {
          sellerStat.totalShares -= tx.shares;
          const { remaining } = ShareUtils.extractShareBlocks(
            sellerStat.shareBlocks.join(', '),
            tx.shares
          );
          sellerStat.shareBlocks = remaining.split(', ').filter(Boolean);
        }
        const buyerStat = stats.get(tx.buyerId);
        if (buyerStat) {
          buyerStat.totalShares += tx.shares;
          buyerStat.shareBlocks.push(tx.shareNumberGroup);
        }
      }
    });

    // remove 0-share folks
    for (const [id, stat] of stats.entries()) {
      if (stat.totalShares <= 0) {
        stats.delete(id);
      }
    }
    return stats;
  };
  const shareholderStats = useMemo(() => calculateShareholderStats(), [shareholders, transactions]);

  /**
   * Calculate share # range
   */
  const calculateInitialShareNumbers = (founderIndex, capitalInvested, totalCapital, numberOfShareholders) => {
    const startNumber = founderIndex * capitalInvested + 1;
    const endNumber = startNumber + capitalInvested - 1;
    return `${Math.max(startNumber, 1)} - ${Math.max(endNumber, 0)}`;
  };

  /**
   * 1) If we already have enough data in know_yourself_response.captablehistory, use it.
   * 2) Otherwise, load from wizard the first time, store it here, persist it, never do it again.
   */
  useEffect(() => {
    if (!userData?.entity) return;

    // 1) Check existing know_yourself data
    const kyResponse = userData.entity.know_yourself_response || {};
    const existing = kyResponse.published?.captablehistory || kyResponse.draft?.captablehistory;
    if (hasMinimumCapTable(existing) && !didLoadWizardData) {
      // We have enough data—just use it
      setDidLoadWizardData(true);
      setShareholders(existing.shareholders || []);
      setTransactions(existing.transactions || []);
      return;
    }

    if (didLoadWizardData) return; // Already loaded or used wizard data

    // 2) Otherwise, fetch from startupWizard once
    const { published: kyPub, draft: kyDraft } = kyResponse;
    const company = kyPub?.company || {};
    const fundingRounds = kyPub?.fundingRounds?.fundingRounds || kyDraft?.fundingRounds?.fundingRounds || [];
    const initialCapitalRound = fundingRounds.find((r) => r.type === 'Initial Capital');

    // Grab the wizard data for founders etc.
    const swPub = userData.entity.startup_wizard_response?.published;
    const swDraft = userData.entity.startup_wizard_response?.draft;
    const wizardData = swPub || swDraft;

    if (!wizardData) {
      console.log('[DEBUG] No wizard data found at all');
      return;
    }

    // Build "company" as first shareholder
    const initialCompany = {
      id: 'company',
      type: 'company',
      // We'll store the full fields, if you like
      fullName: '',
      legalName: userData.entity.name || 'Company', // for display
      name: userData.entity.name || 'Company',

      entryDate: initialCapitalRound
        ? `${initialCapitalRound.time.year}-${String(initialCapitalRound.time.month).padStart(2, '0')}-01`
        : new Date().toISOString(),
      entryRound: 'Founding',
      status: 'complete',
      shareNumbers: '',
    };

    // If we have founders
    if (wizardData?.founders?.founders) {
      const totalShareCapital = wizardData.shareCapital || 25000;

      // We'll build a local array of founder objects with all fields
      const founderShareholders = wizardData.founders.founders.map((founder, i) => {
        const founderId = founder.id || `founder-${i}`;

        const displayName = founder.type === 'natural' ? founder.fullName : founder.legalName;
        return {
          id: founderId,
          type: founder.type || 'natural',
          name: displayName,

          // store everything
          fullName: founder.fullName || '',
          legalName: founder.legalName || '',
          dateOfBirth: founder.dateOfBirth || '',
          nationality: founder.nationality || '',
          email: founder.email || '',
          phone: founder.phone || '',
          seat: founder.seat || '',
          registry: founder.registry || '',
          registryNumber: founder.registryNumber || '',
          address: { ...founder.address } || {},  // or an empty object fallback
          beneficialOwners: founder.beneficialOwners || [],
          beneficialOwnersCount: (founder.beneficialOwners || []).length,
          contactPerson: founder.contactPerson || {},

          entryDate: initialCapitalRound
            ? `${initialCapitalRound.time.year}-${String(initialCapitalRound.time.month).padStart(2, '0')}-01`
            : new Date().toISOString(),
          entryRound: 'Founding',
          status: 'complete',

          shareNumbers: calculateInitialShareNumbers(
            i,
            founder.capitalInvested || 0,
            totalShareCapital,
            wizardData.founders.founders.length + 1
          ),
        };
      });

      const allShareholders = [initialCompany, ...founderShareholders];

      // Build "creation" + "allocation" transactions
      const transactionDate = initialCapitalRound
        ? `${initialCapitalRound.time.year}-${String(initialCapitalRound.time.month).padStart(2, '0')}-01`
        : new Date().toISOString().split('T')[0];

      const creationTx = {
        id: 'creation-0',
        date: transactionDate,
        type: 'created',
        sellerId: '-',
        buyerId: 'company',
        shares: totalShareCapital,
        shareNumberGroup: `1 - ${totalShareCapital}`,
      };
      const founderTxs = wizardData.founders.founders.map((f, i) => {
        const founderId = f.id || `founder-${i}`;
        return {
          id: `initial-${i}`,
          date: transactionDate,
          type: 'allocated',
          sellerId: 'company',
          buyerId: founderId,
          shares: f.capitalInvested || 0,
          shareNumberGroup: calculateInitialShareNumbers(
            i,
            f.capitalInvested || 0,
            totalShareCapital,
            wizardData.founders.founders.length + 1
          ),
        };
      });

      console.log('[DEBUG] Pulling from wizard, building local captable...');
      setShareholders(allShareholders);
      setTransactions([creationTx, ...founderTxs]);
      setTotalShares(totalShareCapital);
      setDidLoadWizardData(true);

      // Now we persist that data so next time we reload, the DB has it.
      // Using handleSubmit if you prefer:
      handleSubmit({
        responses: {
          captablehistory: {
            shareholders: allShareholders,
            transactions: [creationTx, ...founderTxs],
          }
        }
      }).catch((err) => {
        console.error('Failed to persist wizard-based cap table:', err);
        setError(err.message);
      });
    }
  }, [userData, didLoadWizardData, handleSubmit]);

  /**
   * handleAddShareholder & handleSaveShareholderDraft:
   * We store all fields in local state, then also persist to the DB.
   */
  async function handleAddShareholder(values) {
    try {
      const entryDateString = new Date(values.entryYear, values.entryMonth - 1, 1).toISOString();
      const displayName = values.type === 'natural' ? values.fullName : values.legalName;

      let nextShareholders;
      if (selectedShareholder) {
        // editing
        nextShareholders = shareholders.map((s) =>
          s.id === selectedShareholder.id
            ? {
              ...s,
              type: values.type,
              name: displayName,

              fullName: values.fullName,
              legalName: values.legalName,
              dateOfBirth: values.dateOfBirth,
              nationality: values.nationality,
              email: values.email,
              phone: values.phone,
              seat: values.seat,
              registry: values.registry,
              registryNumber: values.registryNumber,
              contactPerson: {
                ...s.contactPerson,
                ...values.contactPerson,
              },
              address: {
                ...s.address,
                ...values.address,
              },
              beneficialOwners: values.type === 'juridical' ? values.beneficialOwners : [],
              beneficialOwnersCount: values.type === 'juridical'
                ? values.beneficialOwnersCount
                : 0,

              entryDate: entryDateString,
              entryRound: values.entryRound,
              status: 'complete',
            }
            : s
        );
      } else {
        // new
        const newId = `shareholder-${Date.now()}`;
        nextShareholders = [
          ...shareholders,
          {
            id: newId,
            type: values.type,
            name: displayName,

            fullName: values.fullName,
            legalName: values.legalName,
            dateOfBirth: values.dateOfBirth,
            nationality: values.nationality,
            email: values.email,
            phone: values.phone,
            seat: values.seat,
            registry: values.registry,
            registryNumber: values.registryNumber,
            contactPerson: { ...values.contactPerson },
            address: { ...values.address },
            beneficialOwners: values.type === 'juridical' ? values.beneficialOwners : [],
            beneficialOwnersCount: values.type === 'juridical'
              ? values.beneficialOwnersCount
              : 0,

            entryDate: entryDateString,
            entryRound: values.entryRound,
            status: 'complete',
            shareNumbers: '',
          }
        ];
      }

      setShowAddShareholder(false);
      setSelectedShareholder(null);
      setShareholders(nextShareholders);

      // Also persist
      await handleSubmit({
        responses: {
          captablehistory: {
            shareholders: nextShareholders,
            transactions,
          }
        }
      });

    } catch (err) {
      console.error('Error in handleAddShareholder:', err);
      setError(err.message);
    }
  }

  async function handleSaveShareholderDraft(values) {
    try {
      const entryDateString = new Date(values.entryYear, values.entryMonth - 1, 1).toISOString();
      const displayName = values.type === 'natural' ? values.fullName : values.legalName;

      let nextShareholders;
      if (selectedShareholder) {
        nextShareholders = shareholders.map((s) =>
          s.id === selectedShareholder.id
            ? {
              ...s,
              type: values.type,
              name: displayName,

              fullName: values.fullName,
              legalName: values.legalName,
              dateOfBirth: values.dateOfBirth,
              nationality: values.nationality,
              email: values.email,
              phone: values.phone,
              seat: values.seat,
              registry: values.registry,
              registryNumber: values.registryNumber,
              contactPerson: {
                ...s.contactPerson,
                ...values.contactPerson,
              },
              address: {
                ...s.address,
                ...values.address,
              },
              beneficialOwners: values.type === 'juridical' ? values.beneficialOwners : [],
              beneficialOwnersCount: values.type === 'juridical'
                ? values.beneficialOwnersCount
                : 0,

              entryDate: entryDateString,
              entryRound: values.entryRound,
              status: 'draft',
            }
            : s
        );
      } else {
        // new
        const newId = `shareholder-${Date.now()}`;
        nextShareholders = [
          ...shareholders,
          {
            id: newId,
            type: values.type,
            name: displayName,

            fullName: values.fullName,
            legalName: values.legalName,
            dateOfBirth: values.dateOfBirth,
            nationality: values.nationality,
            email: values.email,
            phone: values.phone,
            seat: values.seat,
            registry: values.registry,
            registryNumber: values.registryNumber,
            contactPerson: { ...values.contactPerson },
            address: { ...values.address },
            beneficialOwners: values.type === 'juridical' ? values.beneficialOwners : [],
            beneficialOwnersCount: values.type === 'juridical'
              ? values.beneficialOwnersCount
              : 0,

            entryDate: entryDateString,
            entryRound: values.entryRound,
            status: 'draft',
            shareNumbers: '',
          }
        ];
      }

      setShowAddShareholder(false);
      setSelectedShareholder(null);
      setShareholders(nextShareholders);

      // Also persist
      await handleSubmit({
        responses: {
          captablehistory: {
            shareholders: nextShareholders,
            transactions,
          }
        }
      });

    } catch (err) {
      console.error('Error in handleSaveShareholderDraft:', err);
      setError(err.message);
    }
  }

  /**
   * handleAddTransaction
   */
  const handleAddTransaction = async (tx) => {
    try {
      const newTx = { id: `tx-${transactions.length + 1}`, ...tx };
      const nextTransactions = [...transactions, newTx];

      // If you maintain "shareNumbers"
      if (tx.type === 'created') {
        const buyer = shareholders.find((sh) => sh.id === tx.buyerId);
        if (buyer) {
          buyer.shareNumbers = ShareUtils.shareBlocksToString([
            ...ShareUtils.parseShareBlocks(buyer.shareNumbers || ''),
            ShareUtils.parseShareBlock(tx.shareNumberGroup),
          ]);
        }
      } else {
        const seller = shareholders.find((sh) => sh.id === tx.sellerId);
        const buyer = shareholders.find((sh) => sh.id === tx.buyerId);
        if (seller) {
          const { remaining } = ShareUtils.extractShareBlocks(
            seller.shareNumbers,
            tx.shares
          );
          seller.shareNumbers = remaining;
        }
        if (buyer) {
          buyer.shareNumbers = ShareUtils.shareBlocksToString([
            ...ShareUtils.parseShareBlocks(buyer.shareNumbers || ''),
            ShareUtils.parseShareBlock(tx.shareNumberGroup),
          ]);
        }
      }

      const nextShareholders = [...shareholders];
      setTransactions(nextTransactions);
      setShowAddTransaction(false);

      // Persist
      await handleSubmit({
        responses: {
          captablehistory: {
            shareholders: nextShareholders,
            transactions: nextTransactions,
          }
        }
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
      {/* Example error display */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-800 rounded-md p-3 mb-4">
          <p>{error}</p>
        </div>
      )}

      <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">{t('Cap Table')}</h2>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Shareholder')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Total Shares')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Share Numbers')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[...shareholderStats.entries()].map(([shId, stat]) => {
                const sh = shareholders.find((x) => x.id === shId);
                if (!sh) return null;
                return (
                  <tr key={shId}>
                    <td className="px-6 py-4 whitespace-nowrap">{sh.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{stat.totalShares}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {stat.shareBlocks.join(', ')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-red-50 border-2 border-[#e83a30] rounded-md p-3 mb-4 flex items-center justify-between">
        <span className="">
          <lord-icon
            src="https://cdn.lordicon.com/bztwpqlk.json"
            trigger="loop"
            delay="4000"
            colors="primary:#911710,secondary:#e83a30"
            style={{ width: '200px', height: '100px' }}
          >
          </lord-icon>
        </span>
        <p className="mr-4 text-[#911710]">
          {t('This tool is meant as a permanent history of your Cap Table. Added Shareholders and Transactions')}
          <strong> {t('cannot')} </strong> {t('be deleted, to prevent future tampering. Only add shareholders that are (or have been) part of your organization and transactions that have actually occurred.')}
        </p>
      </div>


      {/* Shareholders */}
      <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t('Shareholders')}</h2>
          <button
            onClick={() => setShowAddShareholder(true)}
            className="bg-secondary-color hover:bg-secondary-color/80 text-white px-4 py-2 rounded-md flex items-center"
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            {t('Add Shareholder')}
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Type')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Name')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Entry Date')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Entry Round')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Data Status')}
                </th>
                <th className="px-6 py-3" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {shareholders.map((sh) => (
                <tr key={sh.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {sh.type === 'natural' ? (
                      <UserIcon className="w-5 h-5 text-gray-500" />
                    ) : (
                      <BuildingOfficeIcon className="w-5 h-5 text-gray-500" />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{sh.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(sh.entryDate).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{sh.entryRound}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${sh.status === 'draft'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-green-100 text-green-800'
                        }`}
                    >
                      {sh.status === 'draft' ? t('Draft') : t('Complete')}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {sh.id !== 'company' && (
                      <button
                        onClick={() => {
                          // We do NOT fetch wizard data again.
                          // We rely on local data only.
                          // So we just set the existing local object for the modal:
                          setSelectedShareholder(sh);
                          setShowAddShareholder(true);
                        }}
                        className="text-secondary-color hover:text-secondary-color/80"
                      >
                        {t('Edit')}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Transactions */}
      <div className="bg-white shadow sm:rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t('Transactions')}</h2>
          <button
            onClick={() => setShowAddTransaction(true)}
            className="bg-secondary-color hover:bg-secondary-color/80 text-white px-4 py-2 rounded-md flex items-center"
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            {t('Add Transaction')}
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Date')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Type')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Seller')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Buyer')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Shares')}
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  {t('Share Numbers')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transactions.map((tx) => {
                const seller = shareholders.find((sh) => sh.id === tx.sellerId);
                const buyer = shareholders.find((sh) => sh.id === tx.buyerId);

                return (
                  <tr key={tx.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(tx.date).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {tx.type === 'bought'
                        ? t('Shares sold & bought')
                        : tx.type === 'allocated'
                          ? t('Shares allocated')
                          : t('Shares created')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{seller ? seller.name : tx.sellerId}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{buyer ? buyer.name : tx.buyerId}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{tx.shares}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{tx.shareNumberGroup}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* SHAREHOLDER MODAL */}
      <ShareholderModal
        isOpen={showAddShareholder}
        onClose={() => {
          setShowAddShareholder(false);
          setSelectedShareholder(null);
        }}
        onSubmit={handleAddShareholder}
        onSaveAsDraft={handleSaveShareholderDraft}
        initialData={selectedShareholder}
      />

      {/* TRANSACTION MODAL */}
      <TransactionForm
        isOpen={showAddTransaction}
        onClose={() => setShowAddTransaction(false)}
        shareholders={shareholders}
        onSubmit={handleAddTransaction}
        companyName={
          userData?.entity?.know_yourself_response?.published?.company?.companyName ||
          'Company'
        }
      />
    </div>
  );
};

export default KnowYourselfWizardCapTableHistory;