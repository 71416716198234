import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const DistributionCharts = ({ currentShareholders, targetedShareholders, totalShares, t }) => {
  // Prepare data for current distribution
  const currentData = currentShareholders.map(shareholder => ({
    name: shareholder.name || t('Unnamed Shareholder'),
    shares: shareholder.shares
  }));

  // Prepare data for combined distribution
  const combinedData = [
    ...currentShareholders.map(shareholder => ({
      name: shareholder.name || t('Unnamed Shareholder'),
      shares: shareholder.shares
    })),
    ...targetedShareholders.map(shareholder => ({
      name: shareholder.name || t('New Shareholder'),
      shares: shareholder.shares
    }))
  ];

  // Common chart options template
  const getChartOptions = (title, data) => ({
    chart: {
      type: 'donut',
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 600
      }
    },
    labels: data.map(item => item.name),
    colors: ['#2A9D8F', '#264653', '#E9C46A', '#F4A261', '#E76F51', 
             '#277DA1', '#577590', '#4D908E', '#43AA8B', '#90BE6D'],
    legend: {
      position: 'bottom',
      formatter: function(val, opts) {
        const index = opts.seriesIndex;
        return `${val}`;
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
        startAngle: -90,
        endAngle: 270
      },
    },
    tooltip: {
      y: {
        formatter: function(value, { seriesIndex }) {
          return `${value.toLocaleString()} shares`;
        }
      }
    },
    dataLabels: {
      formatter: function(val) {
        return Math.round(val) + '%';
      }
    }
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <Chart
          options={getChartOptions(t('Current Distribution'), currentData)}
          series={currentData.map(item => item.shares)}
          type="donut"
          height={350}
        />
      </div>
      <div>
        <Chart
          options={getChartOptions(t('Target Distribution'), combinedData)}
          series={combinedData.map(item => item.shares)}
          type="donut"
          height={350}
        />
      </div>
    </div>
  );
};

DistributionCharts.propTypes = {
  currentShareholders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      shares: PropTypes.number.isRequired
    })
  ).isRequired,
  targetedShareholders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      shares: PropTypes.number.isRequired
    })
  ).isRequired,
  totalShares: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

export default DistributionCharts;