// src/components/FlowChart/nodes/DataFlowNode.js
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const DataFlowNode = ({ data, selected }) => {
  return (
    <div 
      className={`bg-blue-50 border rounded-md p-3 w-44 shadow-sm transition-all 
        ${selected ? 'border-blue-500 ring-2 ring-blue-200' : 'border-blue-300'}`}
    >
      <Handle 
        type="target" 
        position={Position.Top} 
        className="!bg-blue-500 !border-blue-700"
      />
      
      <div className="flex items-center">
        <ArrowPathIcon className="h-4 w-4 text-blue-700 mr-1 flex-shrink-0" />
        <div className="font-medium text-sm text-blue-900 truncate">
          {data.label || 'Process'}
        </div>
      </div>
      
      {data.description && (
        <div className="text-xs text-blue-700 mt-1 truncate">
          {data.description}
        </div>
      )}
      
      <Handle 
        type="source" 
        position={Position.Bottom} 
        className="!bg-blue-500 !border-blue-700"
      />
      
      <Handle 
        type="source" 
        position={Position.Right} 
        className="!bg-blue-500 !border-blue-700"
      />
      
      <Handle 
        type="target" 
        position={Position.Left} 
        className="!bg-blue-500 !border-blue-700"
      />
    </div>
  );
};

export default memo(DataFlowNode);