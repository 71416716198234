import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import * as Yup from 'yup';

const questions = [
  {
    id: 'capital',
    questionKey: 'legalForm.questions.capital.question',
    options: [
      {
        labelKey: 'legalForm.questions.capital.options.little',
        value: 'little',
        score: { UG: 3 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.capital.little.forUG',
          GmbH: 'legalForm.recommendations.capital.little.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.capital.options.more',
        value: 'more',
        score: { GmbH: 3 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.capital.more.forUG',
          GmbH: 'legalForm.recommendations.capital.more.forGmbH'
        }
      },
    ],
  },
  {
    id: 'profits',
    questionKey: 'legalForm.questions.profits.question',
    options: [
      {
        labelKey: 'legalForm.questions.profits.options.yes',
        value: 'yes',
        score: { GmbH: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.profits.yes.forUG',
          GmbH: 'legalForm.recommendations.profits.yes.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.profits.options.no',
        value: 'no',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.profits.no.forUG',
          GmbH: 'legalForm.recommendations.profits.no.forGmbH'
        }
      },
    ],
  },
  {
    id: 'scope',
    questionKey: 'legalForm.questions.scope.question',
    options: [
      {
        labelKey: 'legalForm.questions.scope.options.small',
        value: 'small',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.scope.small.forUG',
          GmbH: 'legalForm.recommendations.scope.small.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.scope.options.large',
        value: 'large',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.scope.large.forUG',
          GmbH: 'legalForm.recommendations.scope.large.forGmbH'
        }
      },
    ],
  },
  {
    id: 'priority',
    questionKey: 'legalForm.questions.priority.question',
    options: [
      {
        labelKey: 'legalForm.questions.priority.options.growth',
        value: 'growth',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.priority.growth.forUG',
          GmbH: 'legalForm.recommendations.priority.growth.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.priority.options.bootstrap',
        value: 'bootstrap',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.priority.bootstrap.forUG',
          GmbH: 'legalForm.recommendations.priority.bootstrap.forGmbH'
        }
      },
    ],
  },
  {
    id: 'scaling',
    questionKey: 'legalForm.questions.scaling.question',
    options: [
      {
        labelKey: 'legalForm.questions.scaling.options.fast',
        value: 'fast',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.scaling.fast.forUG',
          GmbH: 'legalForm.recommendations.scaling.fast.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.scaling.options.slow',
        value: 'slow',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.scaling.slow.forUG',
          GmbH: 'legalForm.recommendations.scaling.slow.forGmbH'
        }
      },
    ],
  },
  {
    id: 'milestone',
    questionKey: 'legalForm.questions.milestone.question',
    options: [
      {
        labelKey: 'legalForm.questions.milestone.options.less',
        value: 'less',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.milestone.less.forUG',
          GmbH: 'legalForm.recommendations.milestone.less.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.milestone.options.more',
        value: 'more',
        score: { GmbH: 3 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.milestone.more.forUG',
          GmbH: 'legalForm.recommendations.milestone.more.forGmbH'
        }
      },
    ],
  },
  {
    id: 'financing',
    questionKey: 'legalForm.questions.financing.question',
    options: [
      {
        labelKey: 'legalForm.questions.financing.options.medium',
        value: 'medium',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.financing.medium.forUG',
          GmbH: 'legalForm.recommendations.financing.medium.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.financing.options.small',
        value: 'small',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.financing.small.forUG',
          GmbH: 'legalForm.recommendations.financing.small.forGmbH'
        }
      },
    ],
  },
  {
    id: 'risk',
    questionKey: 'legalForm.questions.risk.question',
    options: [
      {
        labelKey: 'legalForm.questions.risk.options.low',
        value: 'low',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.risk.low.forUG',
          GmbH: 'legalForm.recommendations.risk.low.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.risk.options.moderate',
        value: 'moderate',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.risk.moderate.forUG',
          GmbH: 'legalForm.recommendations.risk.moderate.forGmbH'
        }
      },
    ],
  },
  {
    id: 'founderRole',
    questionKey: 'legalForm.questions.founderRole.question',
    options: [
      {
        labelKey: 'legalForm.questions.founderRole.options.small',
        value: 'small',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.founderRole.small.forUG',
          GmbH: 'legalForm.recommendations.founderRole.small.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.founderRole.options.mid',
        value: 'mid',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.founderRole.mid.forUG',
          GmbH: 'legalForm.recommendations.founderRole.mid.forGmbH'
        }
      },
    ],
  },
  {
    id: 'adminEffort',
    questionKey: 'legalForm.questions.adminEffort.question',
    options: [
      {
        labelKey: 'legalForm.questions.adminEffort.options.minimal',
        value: 'minimal',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.adminEffort.minimal.forUG',
          GmbH: 'legalForm.recommendations.adminEffort.minimal.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.adminEffort.options.moderate',
        value: 'moderate',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.adminEffort.moderate.forUG',
          GmbH: 'legalForm.recommendations.adminEffort.moderate.forGmbH'
        }
      },
    ],
  },
  {
    id: 'market',
    questionKey: 'legalForm.questions.market.question',
    options: [
      {
        labelKey: 'legalForm.questions.market.options.local',
        value: 'local',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.market.local.forUG',
          GmbH: 'legalForm.recommendations.market.local.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.market.options.national',
        value: 'national',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.market.national.forUG',
          GmbH: 'legalForm.recommendations.market.national.forGmbH'
        }
      },
    ],
  },
  {
    id: 'founderCount',
    questionKey: 'legalForm.questions.founderCount.question',
    options: [
      {
        labelKey: 'legalForm.questions.founderCount.options.few',
        value: 'few',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.founderCount.few.forUG',
          GmbH: 'legalForm.recommendations.founderCount.few.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.founderCount.options.many',
        value: 'many',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.founderCount.many.forUG',
          GmbH: 'legalForm.recommendations.founderCount.many.forGmbH'
        }
      },
    ],
  },
  {
    id: 'profitDistribution',
    questionKey: 'legalForm.questions.profitDistribution.question',
    options: [
      {
        labelKey: 'legalForm.questions.profitDistribution.options.yes',
        value: 'yes',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.profitDistribution.yes.forUG',
          GmbH: 'legalForm.recommendations.profitDistribution.yes.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.profitDistribution.options.no',
        value: 'no',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.profitDistribution.no.forUG',
          GmbH: 'legalForm.recommendations.profitDistribution.no.forGmbH'
        }
      },
    ],
  },
  {
    id: 'credibility',
    questionKey: 'legalForm.questions.credibility.question',
    options: [
      {
        labelKey: 'legalForm.questions.credibility.options.important',
        value: 'important',
        score: { GmbH: 2 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.credibility.important.forUG',
          GmbH: 'legalForm.recommendations.credibility.important.forGmbH'
        }
      },
      {
        labelKey: 'legalForm.questions.credibility.options.notVery',
        value: 'notVery',
        score: { UG: 1 },
        recommendationTextKeys: {
          UG: 'legalForm.recommendations.credibility.notVery.forUG',
          GmbH: 'legalForm.recommendations.credibility.notVery.forGmbH'
        }
      },
    ],
  },
];

const legalForms = [
  {
    name: 'UG',
    fullNameKey: 'legalForm.types.UG.fullName',
    minCapitalKey: 'legalForm.types.UG.minCapital',
    prosKeys: ['legalForm.types.UG.pros.1', 'legalForm.types.UG.pros.2', 'legalForm.types.UG.pros.3', 'legalForm.types.UG.pros.4'],
    consKeys: ['legalForm.types.UG.cons.1', 'legalForm.types.UG.cons.2', 'legalForm.types.UG.cons.3', 'legalForm.types.UG.cons.4'],
  },
  {
    name: 'GmbH',
    fullNameKey: 'legalForm.types.GmbH.fullName',
    minCapitalKey: 'legalForm.types.GmbH.minCapital',
    prosKeys: ['legalForm.types.GmbH.pros.1', 'legalForm.types.GmbH.pros.2', 'legalForm.types.GmbH.pros.3'],
    consKeys: ['legalForm.types.GmbH.cons.1'],
  },
/*  {
    name: 'AG',
    fullNameKey: 'legalForm.types.AG.fullName',
    minCapitalKey: 'legalForm.types.AG.minCapital',
    prosKeys: ['legalForm.types.AG.pros.1', 'legalForm.types.AG.pros.2', 'legalForm.types.AG.pros.3', 'legalForm.types.AG.pros.4'],
    consKeys: ['legalForm.types.AG.cons.1', 'legalForm.types.AG.cons.2', 'legalForm.types.AG.cons.3', 'legalForm.types.AG.cons.4'],
  },*/
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Updated debounce function with cancel functionality
function debounce(func, wait) {
  let timeout;

  function debouncedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  // Add a cancel method to the debounced function
  debouncedFunction.cancel = () => {
    clearTimeout(timeout);
  };

  return debouncedFunction;
}

export default function LegalFormWizard() {
  const { wizardName, updateWizardData, liveMode, toggleLiveMode } = useWizard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [selectedLegalForm, setSelectedLegalForm] = useState(null);
  const [scores, setScores] = useState({ UG: 0, GmbH: 0, AG: 0 });
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const [validationError, setValidationError] = useState('');
  const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  const { data: legalFormData, dataStatus, loading: legalFormLoading, error: legalFormError } = useFetchData(wizardName, 'legalForm');

  const validationSchema = Yup.object().shape({
    selectedLegalForm: Yup.string()
      .required(t('legalForm.validation.selectLegalForm', 'Please choose a legal form'))
  });

  // Effect to initialize data from fetch
  useEffect(() => {
    if (legalFormData) {
      setAnswers(legalFormData.answers || {});
      setSelectedLegalForm(legalFormData.selectedLegalForm);
    }
  }, [legalFormData]);

  useEffect(() => {
    calculateScores();
  }, [answers]);

  const calculateScores = () => {
    const newScores = { UG: 0, GmbH: 0, AG: 0 };
    Object.entries(answers).forEach(([questionId, answer]) => {
      const question = questions.find(q => q.id === questionId);
      const option = question.options.find(o => o.value === answer);
      if (option && option.score) {
        Object.entries(option.score).forEach(([form, score]) => {
          newScores[form] += score;
        });
      }
    });
    setScores(newScores);
  };

  const getRecommendedLegalForm = () => {
    return Object.entries(scores).reduce((a, b) => a[1] > b[1] ? a : b)[0];
  };


  // Check if all questions are answered
  useEffect(() => {
    const answeredCount = Object.keys(answers).length;
    setAllQuestionsAnswered(answeredCount === questions.length);
  }, [answers]);

  const calculateRecommendation = () => {
    const newScores = { UG: 0, GmbH: 0 };
  
    Object.entries(answers).forEach(([questionId, answer]) => {
      const question = questions.find(q => q.id === questionId);
      if (!question) return; // Ensure question exists
  
      const option = question.options.find(o => o.value === answer);
      if (!option || !option.score) return; // Ensure option exists
  
      Object.entries(option.score).forEach(([form, score]) => {
        newScores[form] += score;
      });
    });
  
    const recommendedForm = newScores.UG > newScores.GmbH ? 'UG' : 'GmbH';
  
    const recommendationText = questions
      .map(question => {
        const answer = answers[question.id];
        if (!answer) return ''; // Ensure an answer exists
  
        const option = question.options.find(o => o.value === answer);
        if (!option || !option.recommendationTextKeys) return ''; // Ensure option and text keys exist
  
        return t(option.recommendationTextKeys[recommendedForm]);
      })
      .filter(Boolean)
      .join(' ');
  
    return {
      scores: newScores,
      recommendedForm,
      text: recommendationText
    };
  };  

  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode) {
      const data = {
        answers,
        selectedLegalForm,
      };

      const result = await saveAsDraft(wizardName, 'legalForm', data, true);
      if (result.success) {
        updateWizardData('legalForm', data, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, answers, selectedLegalForm, wizardName, updateWizardData]);

  // Create debounced auto-save reference
  const debouncedAutoSaveRef = React.useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when relevant data changes
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => {
        if (debouncedAutoSaveRef.current?.cancel) {
          debouncedAutoSaveRef.current.cancel();
        }
      };
    }
  }, [liveMode, answers, selectedLegalForm]);

  const validateData = async (data) => {
    try {
      await validationSchema.validate({ selectedLegalForm: data.selectedLegalForm }, { abortEarly: false });
      return { isValid: true, error: null };
    } catch (err) {
      return { isValid: false, error: err.errors[0] };
    }
  };

  const handleSave = async () => {
    setHasAttemptedSave(true);

    const data = {
      answers,
      selectedLegalForm,
    };

    const validation = await validateData(data);
    if (!validation.isValid) {
      setValidationError(validation.error);
      setNotification({
        show: true,
        type: 'error',
        message: `${t('legalForm.notifications.errorSaving')}: ${validation.error}`
      });
      return;
    }

    const result = await saveData(wizardName, 'legalForm', data);
    if (result.success) {
      setValidationError('');
      setNotification({
        show: true,
        type: 'success',
        message: t('legalForm.notifications.dataSaved')
      });
      updateWizardData('legalForm', data, false);
      updateWizardData('legalForm', data, true);
      setTimeout(() => navigate('/startup-wizard/company'), 1000);
    } else {
      setNotification({
        show: true,
        type: 'error',
        message: t('legalForm.notifications.errorSaving')
      });
    }
  };

  const handleSaveAsDraft = async () => {
    const data = {
      answers,
      selectedLegalForm,
    };

    const result = await saveAsDraft(wizardName, 'legalForm', data, false);
    if (result.success) {
      setNotification({
        show: true,
        type: 'success',
        message: t('legalForm.notifications.savedAsDraft')
      });
      updateWizardData('legalForm', data, true);
    } else {
      setNotification({
        show: true,
        type: 'error',
        message: t('legalForm.notifications.errorSavingDraft')
      });
    }
  };

  if (legalFormLoading) { return <div>{t('common.loading')}</div>; }
  if (legalFormError) { return <div>{t('common.error', { error: legalFormError?.message })}</div>; }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-left mt-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          {t('legalForm.title')}
        </p>
        <p className="mt-4 text-lg text-gray-600">
          {t('legalForm.description')}
        </p>
      </div>

      <Disclosure as="div" className="border border-gray-200 rounded-lg mt-8">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                "flex w-full justify-between px-4 py-4 text-left text-lg font-bold",
                "focus:outline-none focus:ring-2 focus:ring-[#2A9D8F]/50",
                open ? "rounded-t-lg text-white bg-[#2A9D8F] hover:bg-[#2A9D8F]/80" : "rounded-lg text-[#2A9D8F] hover:bg-[#2A9D8F]/10 bg-white"  // Only round top corners if open
              )}
            >
              <span>{t('legalForm.questionnaire.title')}</span>
              <ChevronDownIcon
                className={classNames(
                  "h-7 w-7 transition-transform duration-200",
                  open ? "rotate-180 transform text-white" : "text-[#2A9D8F]"
                )}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="px-6 py-6 border-t border-gray-200 bg-[#2A9D8F]/5">
              <div className="space-y-8">
                {questions.map((question) => (
                  <div key={question.id} className="space-y-4">
                    <h3 className="text-sm font-medium leading-6 text-gray-900">
                      {t(question.questionKey)}
                    </h3>
                    <RadioGroup
                      value={answers[question.id]}
                      onChange={(value) => setAnswers({ ...answers, [question.id]: value })}
                    >
                      <div className="space-y-4">
                        {question.options.map((option) => (
                          <RadioGroup.Option
                            key={option.value}
                            value={option.value}
                            className={({ checked }) =>
                              classNames(
                                'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                                checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200 hover:bg-gray-50'
                              )
                            }
                          >
                            {({ checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                    checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300'
                                  )}
                                  aria-hidden="true"
                                >
                                  {checked && (
                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                  )}
                                </span>
                                <div className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      'block text-sm font-medium',
                                      checked ? 'text-[#2A9D8F]' : 'text-gray-900'
                                    )}
                                  >
                                    {t(option.labelKey)}
                                  </RadioGroup.Label>
                                </div>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                ))}

                {allQuestionsAnswered && (
                  <div className="mt-8 rounded-lg bg-[#2A9D8F] p-6">
                    <h3 className="text-xl font-bold text-white mb-4">
                      {t('legalForm.recommendation.title', {
                        form: calculateRecommendation().recommendedForm
                      })}
                    </h3>
                    <p className="text-white">
                      {calculateRecommendation().text}
                    </p>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mt-16">
        <div className="grid gap-8 lg:grid-cols-2">
          {legalForms.map((form) => {
            const isSelected = selectedLegalForm === form.name;
            const isRecommended = form.name === (calculateRecommendation().recommendedForm);
            return (
              <button
                key={form.name}
                onClick={() => setSelectedLegalForm(form.name)}
                className={classNames(
                  'flex flex-col justify-start items-start rounded-lg p-8 text-left',
                  isSelected ? 'bg-[#2A9D8F]' : 'bg-white',
                  isRecommended ? 'ring-2 ring-[#2A9D8F]' : 'ring-1 ring-gray-200',
                  'transition-all duration-200 ease-in-out hover:shadow-lg'
                )}
              >
                <div className="flex items-center justify-between w-full">
                  <h3 className={classNames(
                    'text-2xl font-semibold',
                    isSelected ? 'text-white' : 'text-[#2A9D8F]'
                  )}>
                    {form.name}
                  </h3>
                  {isRecommended && (
                    <p className={classNames(
                      "rounded-full px-2.5 py-1 text-xs font-semibold",
                      isSelected ? "bg-white text-[#2A9D8F]" : "bg-[#2A9D8F]/10 text-[#2A9D8F]"
                    )}>
                      {t('legalForm.recommended')}
                    </p>
                  )}
                </div>
                <p className={classNames(
                  "mt-0 text-sm",
                  isSelected ? 'text-white' : 'text-gray-600'
                )}>
                  {t(form.fullNameKey)}
                </p>
                <p className={classNames(
                  "mt-4 text-xl font-semibold",
                  isSelected ? 'text-white' : 'text-gray-900'
                )}>
                  {t(form.minCapitalKey)}
                </p>
                <p className={classNames(
                  "text-sm mt-0",
                  isSelected ? 'text-white' : 'text-gray-600'
                )}>
                  {t('legalForm.minimumCapital')}
                </p>
                <div
                  className={classNames(
                    'mt-6 w-full rounded-md px-3 py-2 text-sm font-semibold text-center',
                    isSelected
                      ? 'bg-white text-[#2A9D8F] ring-2 ring-white'
                      : 'bg-white text-[#2A9D8F] ring-1 ring-inset ring-[#2A9D8F]'
                  )}
                >
                  {isSelected ? t('legalForm.selected') : t('legalForm.select')}
                </div>
                <ul className="mt-8 space-y-3 text-sm">
                  {form.prosKeys.map((proKey) => (
                    <li key={proKey} className="flex gap-x-3">
                      <HandThumbUpIcon className={classNames(
                        "h-5 w-5 flex-shrink-0",
                        isSelected ? "text-white" : "text-green-500"
                      )} aria-hidden="true" />
                      <span className={isSelected ? 'text-white' : 'text-black'}>{t(proKey)}</span>
                    </li>
                  ))}
                  {form.consKeys.map((conKey) => (
                    <li key={conKey} className="flex gap-x-3">
                      <HandThumbDownIcon className={classNames(
                        "h-5 w-5 flex-shrink-0",
                        isSelected ? "text-white" : "text-red-500"
                      )} aria-hidden="true" />
                      <span className={isSelected ? 'text-white' : 'text-black'}>{t(conKey)}</span>
                    </li>
                  ))}
                </ul>
              </button>
            );
          })}
        </div>
        {hasAttemptedSave && validationError && (
          <p className="mt-2 text-sm text-red-600">
            {validationError}
          </p>
        )}
      </div>

      <ActionButtons
        onCancel={() => navigate('/startup-wizard')}
        onDraft={handleSaveAsDraft}
        onSubmit={handleSave}
        isSubmitting={false}
        currentPage="legalForm"
      />

      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />
    </div>
  );
}