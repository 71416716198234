import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const steps = [
  { id: 'StartupWizard.section1', name: 'StartupWizard.legalForm', route: '/startup-wizard/legalForm' },
  { id: 'StartupWizard.section2', name: 'StartupWizard.company', route: '/startup-wizard/company' },
  { id: 'StartupWizard.section3', name: 'StartupWizard.founders', route: '/startup-wizard/founders' },
  { id: 'StartupWizard.section4', name: 'StartupWizard.articlesOfAssociation', route: '/startup-wizard/articlesOfAssociation' },
  { id: 'StartupWizard.section5', name: 'StartupWizard.shareholdersAgreement', route: '/startup-wizard/shareholdersAgreement' },
  { id: 'StartupWizard.section6', name: 'StartupWizard.notaryAndBank', route: '/startup-wizard/notaryAndBank' },
  { id: 'StartupWizard.section7', name: 'StartupWizard.final', route: '/startup-wizard/final' },
];

const StartupWizardProgress = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [expandedStep, setExpandedStep] = useState(null);

  const handleStepClick = (step) => {
    if (step.route === location.pathname) return;

    if (expandedStep === step.name) {
      // Do nothing, as Link component will handle navigation
      setExpandedStep(null);
    } else {
      // If step is not expanded, expand it
      setExpandedStep(step.name);
    }
  };

  return (
    <nav aria-label="Progress" className="overflow-x-auto">
      {/* Desktop version */}
      <ol role="list" className="hidden lg:flex lg:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="lg:flex-1">
            <Link
              to={step.route}
              className={`group flex flex-col border-t-4 pb-6 pt-4 ${location.pathname === step.route
                  ? 'border-primary-color'
                  : 'border-gray-200 hover:border-gray-300'
                }`}
              aria-current={location.pathname === step.route ? 'step' : undefined}
            >
              <span className={`text-xs font-medium ${location.pathname === step.route
                  ? 'text-primary-color'
                  : 'text-gray-500 group-hover:text-primary-color'
                }`}>
                {t(step.id)}
              </span>
              <span className={`text-xs font-bold ${location.pathname === step.route
                  ? 'text-black'
                  : 'text-gray-500 group-hover:text-gray-700'
                }`}>
                {t(step.name)}
              </span>
            </Link>
          </li>
        ))}
      </ol>

      {/* Mobile version */}
      <ol role="list" className="lg:hidden flex items-center px-2 mt-8">
        {steps.map((step, index) => {
          const isActive = location.pathname === step.route;
          const isExpanded = expandedStep === step.name;

          return (
            <li key={step.name} className="flex-shrink-0">
              <motion.div
                className="flex items-center"
                initial={false}
                animate={{ width: isActive || isExpanded ? 'auto' : '20px' }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className={`flex items-center ${isActive ? 'cursor-default' : 'cursor-pointer'
                    }`}
                  onClick={() => handleStepClick(step)}
                >
                  {/* Dot or active/expanded section */}
                  <div
                    className={`flex items-center rounded-full transition-colors ${isActive
                        ? 'h-5 bg-primary-color mr-2' //active section
                        : isExpanded
                          ? 'h-5 bg-gray-400 hover:bg-gray-300 mr-2' //other section
                          : 'w-3 h-3 bg-gray-400 hover:bg-gray-300' //dot
                      }`}
                  >
                    <AnimatePresence>
                      {(isActive || isExpanded) && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="flex items-center space-x-2 px-3"
                        >
                          <Link
                            to={step.route}
                            className={`text-xs font-bold whitespace-nowrap ${isActive ? 'text-white' : 'text-gray-700'
                              }`}
                          >
                            {t(step.name)}
                          </Link>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default StartupWizardProgress;