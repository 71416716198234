// src/components/wizard_components/DataPrivacyWizard/RecommendedControls.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../hooks/useUserData';

const RecommendedControls = ({
  recommendedControls,
  selectedControls,
  onToggleSelection,
  onViewDetails,
  riskControls,
  selectedRisks,
  processingActivity,
  onGenerateRecommendations
}) => {
  const { t } = useTranslation();
  const { userData } = useUserData();

  // Get user language
  const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';
  
  return (
    <div>
      {recommendedControls.length === 0 ? (
        <div className="p-4 border-2 border-dashed border-gray-300 rounded-lg text-center">
          <p className="text-sm text-gray-500">
            {t('DataPrivacyWizard.dpia.riskTreatment.noRecommendedControls', 'No recommended controls available.')}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {recommendedControls.map(control => {
            const isSelected = selectedControls.includes(control.id);
            
            return (
              <div
                key={control.id}
                className={`border rounded-lg p-4 relative cursor-pointer ${isSelected
                  ? 'border-primary-color bg-blue-50'
                  : 'border-gray-300'}`}
                onClick={() => onToggleSelection(control.id)}
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-center space-x-2">
                    {control.icons && (
                      <img
                        src={`/is_icons/${control.icons}`}
                        alt={control.id}
                        className="h-8 w-8"
                      />
                    )}
                    <span className="text-sm font-semibold text-[#207425]">{control.id}</span>
                  </div>
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => {}} // No-op since we're handling change via onClick
                      className="h-4 w-4 text-primary-color border-gray-300 rounded cursor-pointer"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-sm font-semibold text-gray-900">
                    {userLang === 'de' ? control.control_name_de : control.control_name}
                  </p>
                  <p className="mt-1 text-xs text-gray-600">
                    {userLang === 'de' ? control.control_definition_d : control.control_definition}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RecommendedControls;