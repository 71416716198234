import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

const SearchSection = ({ 
  handleSearchResultSelect, 
  currentAsset,
  filter,
  userLang = 'en',
  categories = [],
  assetClasses = [],
  allAssetTypes = [] // This should already be loaded by AssetDataLoader
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [shouldRender, setShouldRender] = useState(true);
  
  // Check if we should render the component
  useEffect(() => {
    setShouldRender(!currentAsset && !filter);
  }, [currentAsset, filter]);

  // Search through loaded asset types whenever query changes
  useEffect(() => {
    if (!searchQuery || !searchQuery.trim() || !allAssetTypes.length) {
      setSearchResults([]);
      return;
    }
    
    const query = searchQuery.toLowerCase();
    
    // Search in both English and German fields
    const matchingTypes = allAssetTypes.filter(type => {
      // Search in type name (English and German)
      const matchesType = 
        (type.asset_type && type.asset_type.toLowerCase().includes(query)) || 
        (type.werttyp && type.werttyp.toLowerCase().includes(query));
      
      // Search in class name (English and German)
      const matchesClass = 
        (type.asset_class && type.asset_class.toLowerCase().includes(query)) ||
        (type.wertklasse && type.wertklasse.toLowerCase().includes(query));
      
      // Search in category name (English and German)
      const matchesCategory = 
        (type.asset_category && type.asset_category.toLowerCase().includes(query)) ||
        (type.wertkategorie && type.wertkategorie.toLowerCase().includes(query));
      
      return matchesType || matchesClass || matchesCategory;
    });
    
    // Enrich results if needed
    const enrichedResults = matchingTypes.map(type => {
      let enriched = { ...type };
      
      // Enrich with missing German fields if necessary
      if (!enriched.wertkategorie && enriched.asset_category) {
        const matchingCategory = categories.find(cat => 
          cat.asset_categories === enriched.asset_category
        );
        if (matchingCategory) {
          enriched.wertkategorie = matchingCategory.wertkategorie;
        }
      }
      
      if (!enriched.wertklasse && enriched.asset_class) {
        const matchingClass = assetClasses.find(cls => 
          cls.asset_class === enriched.asset_class
        );
        if (matchingClass) {
          enriched.wertklasse = matchingClass.wertklasse;
        }
      }
      
      return enriched;
    });
    
    setSearchResults(enrichedResults);
  }, [searchQuery, allAssetTypes, categories, assetClasses]);

  // Handle result selection and clear search
  const handleResultClick = (result) => {
    handleSearchResultSelect(result);
    setSearchQuery('');
    setSearchResults([]);
  };

  // Get the display value in the correct language
  const getLocalizedField = (item, enField, deField) => {
    if (userLang === 'de' && item[deField]) {
      return item[deField];
    }
    return item[enField] || '';
  };

  // If we shouldn't render, return null
  if (!shouldRender) {
    return null;
  }

  return (
    <div className="px-4 py-3 border-b">
      <div className="relative">
        <input
          type="text"
          placeholder={t('Search for asset types...')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pr-10 pl-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-secondary-color focus:border-secondary-color"
        />
        <div className="absolute inset-y-0 right-0 px-3 flex items-center">
          {searchQuery ? (
            <button 
              type="button" 
              onClick={() => {
                setSearchQuery('');
                setSearchResults([]);
              }}
              className="text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          ) : (
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>
      
      {searchResults.length > 0 && (
        <div className="mt-2 border rounded max-h-40 overflow-y-auto">
          {searchResults.map(result => {
            // Get localized values
            const typeName = getLocalizedField(result, 'asset_type', 'werttyp');
            const categoryName = getLocalizedField(result, 'asset_category', 'wertkategorie');
            const className = getLocalizedField(result, 'asset_class', 'wertklasse');
            
            return (
              <div
                key={result.id}
                className="p-2 hover:bg-gray-50 cursor-pointer border-b last:border-b-0 flex justify-between items-center"
                onClick={() => handleResultClick(result)}
              >
                <div>
                  <div className="font-medium">
                    {typeName}
                  </div>
                  <div className="text-xs text-gray-500">
                    {/* Display breadcrumb in correct language */}
                    {categoryName}
                    {categoryName && className ? ' > ' : ''}
                    {className}
                  </div>
                </div>
                <button 
                  className="text-secondary-color hover:text-secondary-color-dark text-sm font-medium"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleResultClick(result);
                  }}
                >
                  {userLang === 'de' ? 'Auswählen' : 'Select'}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchSection;