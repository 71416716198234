import { useState, useEffect } from 'react';
import axios from '../../axios';

const useFetchData = (wizardName, section) => {
  const [data, setData] = useState(null);
  const [dataStatus, setDataStatus] = useState('new');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });

        const wizardResponse = response.data.entity[`${wizardName}_wizard_response`] || {};
        const { published, draft } = wizardResponse;
        
        const publishedData = published?.[section] || {};
        const draftData = draft?.[section] || {};

        const isDataEmpty = (data) => {
          return Object.values(data).every(value => 
            value === undefined ||
            value === null || 
            value === '' || 
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0)
          );
        };

        const isDraftDifferent = JSON.stringify(draftData) !== JSON.stringify(publishedData);

        if (!isDataEmpty(draftData) && isDraftDifferent) {
          setDataStatus('draft');
          setData(draftData);
        } else if (!isDataEmpty(publishedData)) {
          setDataStatus('published');
          setData(publishedData);
        } else {
          setDataStatus('new');
          setData({});
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [wizardName, section]);

  return { data, dataStatus, loading, error };
};

export default useFetchData;