// src/components/FlowChart/index.js
import React from 'react';
import { ReactFlowProvider } from 'reactflow';
import FlowChart from './FlowChart';
import { FlowChartProvider } from './FlowChartProvider';
import useFlowChart from './hooks/useFlowChart';

// Export the FlowChart component wrapped with ReactFlowProvider for standalone use
const FlowChartWithProvider = (props) => (
  <ReactFlowProvider>
    <FlowChart {...props} />
  </ReactFlowProvider>
);

// Export the main component
export default FlowChartWithProvider;

// Also export the provider and hook for more advanced usage
export { FlowChartProvider, useFlowChart };

// Export node types in case they need to be used elsewhere
export { default as DataFlowNode } from './nodes/DataFlowNode';
export { default as SystemNode } from './nodes/SystemNode';
export { default as ActorNode } from './nodes/ActorNode';

// Export components that might be useful elsewhere
export { default as NodePalette } from './components/NodePalette';
export { default as NodeProperties } from './components/NodeProperties';
export { default as ControlPanel } from './components/ControlPanel';