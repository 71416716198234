// src/utils/templateTranslations.js

export const getTranslatedValue = (obj, language, fallbackLang = 'en') => {
    
    if (!obj) {
      return '';
    }
    
    // If it's a simple string, return it
    if (typeof obj === 'string') {
      return obj;
    }
    
    // If it's a translation object
    if (typeof obj === 'object') {
      // Try requested language
      if (obj[language]) {
        return obj[language];
      }
      
      // Try fallback language
      if (obj[fallbackLang]) {
        return obj[fallbackLang];
      }
      
      // If all else fails, return the first available translation
      const firstAvailable = Object.values(obj)[0];
      return firstAvailable || '';
    }
    
    return '';
  };
  
  export const getTranslatedArray = (obj, language, fallbackLang = 'en') => {
    if (!obj) return [];
    
    // If it's already an array, return it
    if (Array.isArray(obj)) return obj;
    
    // If it's a translation object with arrays
    if (typeof obj === 'object') {
      // Try requested language
      if (Array.isArray(obj[language])) return obj[language];
      
      // Try fallback language
      if (Array.isArray(obj[fallbackLang])) return obj[fallbackLang];
      
      // If all else fails, return the first available array
      const firstAvailable = Object.values(obj).find(Array.isArray);
      return firstAvailable || [];
    }
    
    return [];
  };