import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import RainbowButton from '../RainbowButton';
import axios from '../../axios';
import { useTranslation } from 'react-i18next';

const ConclusionHandler = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [publishedData, setPublishedData] = useState(null);
    const [draftData, setDraftData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const loadLordIcon = () => {
            const script = document.createElement('script');
            script.src = 'https://cdn.lordicon.com/lusqsztk.js';
            script.async = true;
            document.body.appendChild(script);
            return script;
        };

        const script = loadLordIcon();
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // New useEffect for fetching data when modal opens
    useEffect(() => {
        const fetchData = async () => {
            if (isOpen) {
                try {
                    const token = localStorage.getItem('authToken');
                    const { data: userData } = await axios.get('/api/v1/live_data/user_data', {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    const currentData = userData.entity.know_yourself_response;
                    setPublishedData(currentData.published || {});
                    setDraftData(currentData.draft || {});
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [isOpen]);

    const handleConclude = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('authToken');
            
            // 1. Get current data
            const { data: userData } = await axios.get('/api/v1/live_data/user_data', {
                headers: { Authorization: `Bearer ${token}` }
            });

            const currentData = userData.entity.know_yourself_response;
            const publishedData = currentData.published || {};
            const draftData = currentData.draft || {};

            // 2. Find next funding round and update investment page
            const currentRounds = publishedData.fundingRounds?.fundingRounds || 
                              draftData.fundingRounds?.fundingRounds || [];

            const currentTargetRound = currentRounds.find(r => r.isTargetRound);
            const sortedFutureRounds = currentRounds
                .filter(r => {
                    if (!currentTargetRound) return !r.isInitialCapital;
                    return !r.isInitialCapital && (
                        r.time.year > currentTargetRound.time.year ||
                        (r.time.year === currentTargetRound.time.year && r.time.month > currentTargetRound.time.month)
                    );
                })
                .sort((a, b) => {
                    if (a.time.year !== b.time.year) return a.time.year - b.time.year;
                    return a.time.month - b.time.month;
                });

            const nextRound = sortedFutureRounds[0];

            if (nextRound) {
                const currentBurnRate = publishedData.avgCashBurn || draftData.avgCashBurn || 50000;
                const newPlanningHorizon = Math.ceil(nextRound.investmentAmount / currentBurnRate);

                // Update investment page with next round's details
                await axios.post('/api/v1/wizards/know_yourself/draft', {
                    responses: {
                        targetFundingRound: nextRound.type,
                        planningHorizon: newPlanningHorizon,
                        avgCashBurn: currentBurnRate,
                        capitalRequired: nextRound.investmentAmount,
                        fundingCurrency: publishedData.fundingCurrency || draftData.fundingCurrency,
                        fundingTime: nextRound.time
                    }
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            // 3. Update funding rounds while preserving all data
            if (currentTargetRound) {
                const updatedRounds = currentRounds.map(round => {
                    if (round.isTargetRound) {
                        return { ...round, isTargetRound: false };
                    }
                    if (round === nextRound) {
                        return { ...round, isTargetRound: true };
                    }
                    return round;
                });

                // Get all existing data from the published/draft fundingRounds object
                const existingData = publishedData.fundingRounds || draftData.fundingRounds || {};
                const existingCashflow = existingData.cashflow || [];

                await axios.post('/api/v1/wizards/know_yourself/draft', {
                    responses: {
                        fundingRounds: {
                            currency: publishedData.fundingRounds?.currency || draftData.fundingRounds?.currency,
                            fundingRounds: updatedRounds,
                            cashflow: existingCashflow  // Preserve the cashflow data
                        }
                    }
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            // 4. Update cap table - Calculate share capital correctly
            const currentCaptable = publishedData.captable || draftData.captable;
            if (currentCaptable) {
                const nominalValue = currentCaptable.nominalShareValue || 1;
                
                // Calculate total number of shares after merger
                const totalShares = [
                    ...currentCaptable.currentShareholders,
                    ...currentCaptable.targetedShareholders
                ].reduce((sum, s) => sum + (s.shares || 0), 0);
                
                // Calculate new share capital based on total shares
                const newShareCapital = totalShares * nominalValue;

                const updatedCaptable = {
                    ...currentCaptable,
                    shareCapital: newShareCapital,  // Set the new share capital
                    currentShareholders: [
                        ...currentCaptable.currentShareholders,
                        ...currentCaptable.targetedShareholders
                    ],
                    targetedShareholders: [],
                    targetedNewShareholderCount: 1
                };

                await axios.post('/api/v1/wizards/know_yourself/draft', {
                    responses: {
                        captable: updatedCaptable
                    }
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            setLoading(false);
            setIsOpen(false);
            window.location.reload();
        } catch (error) {
            console.error('Error concluding round:', error);
            setLoading(false);
        }
    };

    const modalStyles = `
    :root {
      --rainbow-1: hsl(0, 100%, 50%);
      --rainbow-2: hsl(60, 100%, 50%);
      --rainbow-3: hsl(120, 100%, 50%);
      --rainbow-4: hsl(180, 100%, 50%);
      --rainbow-5: hsl(240, 100%, 50%);
      --rainbow-6: hsl(300, 100%, 50%);
    }
  
    @keyframes rainbow-glow {
      0% { filter: hue-rotate(0deg); }
      100% { filter: hue-rotate(360deg); }
    }
  
    @keyframes wipe {
      0% { mask-position: 200% center; }
      100% { mask-position: 0% center; }
    }
  
    .modal-outline {
      position: relative;
      isolation: isolate;
      overflow: visible;
      border-radius: 0.5rem;
    }
  
    .modal-shimmer {
      position: absolute;
      inset: -8px;  /* Increased from -4px */
      mix-blend-mode: plus-lighter;
      pointer-events: none;
      border-radius: 0.75rem;  /* Slightly larger radius */
      mask-image: linear-gradient(
        90deg, 
        transparent 15%, 
        black 45%, 
        black 55%, 
        transparent 85%
      );
      mask-size: 200% 200%;
      mask-position: center;
      animation: 
        wipe 5s linear infinite both -0.5s,
        rainbow-glow 5s linear infinite;
    }
  
    .modal-shimmer::before,
    .modal-shimmer::after {
      content: "";
      position: absolute;
      inset: 8px;  /* Increased from 4px */
      border-radius: 0.75rem;  /* Slightly larger radius */
      animation: rainbow-glow 5s linear infinite;
    }
  
    .modal-shimmer::before {
      box-shadow: 
        0 0 15px 50px var(--rainbow-1),   /* Much larger glow */
        0 0 25px 100px var(--rainbow-2),
        0 0 35px 150px var(--rainbow-3),
        0 0 45px 200px var(--rainbow-4);
    }
  
    .modal-shimmer::after {
      box-shadow: 
        inset 0 0 0 2px var(--rainbow-5),  /* Thicker border */
        inset 0 0 15px 5px var(--rainbow-6),
        inset 0 0 25px 10px var(--rainbow-1);
    }
  `;

  return (
    <div className="mt-4">
        <style>{modalStyles}</style>

        <RainbowButton
            onClick={() => setIsOpen(true)}
            className="w-full"
        >
            <span className="text-lg font-bold">
                {t('Conclude Funding Round')}
            </span>
        </RainbowButton>

        <Transition.Root show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="modal-outline relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                <span className="modal-shimmer"></span>
                                <div className="bg-white rounded-lg px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="absolute right-0 top-0 pr-4 pt-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            <span className="sr-only">{t('Close')}</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div className="sm:flex sm:items-start mt-4">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                            <div className="flex items-start">
                                                <div>
                                                    <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/yvgmrqny.json"
                                                        trigger="loop"
                                                        delay="1000"
                                                        colors="primary:#2a9d8f,secondary:#2a9d8f"
                                                        style={{ width: '100px', height: '100px' }}>
                                                    </lord-icon>
                                                </div>

                                                <div className="ml-6">
                                                    <h3 className="text-xl font-medium leading-6 text-gray-900">
                                                        {t('Is it that time to cheer?')}
                                                    </h3>
                                                    <h3 className="text-xl font-semibold leading-6 text-gray-900">
                                                        {t('Your funding round has been closed?')}
                                                    </h3>
                                                    <p className="text-2xl font-semibold text-green-600">
                                                        {t('If so: CONGRATULATIONS!')} 🎉
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="mt-2 space-y-4">
                                                <div className="bg-gray-50 p-4 rounded-lg">
                                                    <p className="font-medium text-sm text-gray-900 mb-2">
                                                        {t('By clicking the button below, the following will happen:')}
                                                    </p>
                                                    <ul className="list-disc pl-5 space-y-2 text-sm text-gray-600">
                                                        <li>{t('Target Round will be set to the next projected/planned funding round, if one has already been set. Target Round-Page will be saved as draft.')}</li>
                                                        <li>{t('The funding round you just concluded will be set as a previous funding round in the Financials. Financials-Page will be saved as draft.')}</li>
                                                        <li>{t('The current Cap Table will be set to the Cap Table situation after the just concluded funding round. Cap Table-Page will be saved as draft.')}</li>
                                                        <li>{t('The Cap Table History will NOT be automatically updated.')}</li>
                                                    </ul>
                                                </div>

                                                <TransitionDetails userData={publishedData || draftData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-gray-100 rounded-b-lg px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color sm:ml-3 sm:w-auto disabled:opacity-50"
                                        onClick={handleConclude}
                                        disabled={loading}
                                    >
                                        {loading ? t('Processing...') : t('Conclude the round!')}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t('Cancel')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </div>
);
};

const TransitionDetails = ({ userData }) => {
const { t } = useTranslation();
if (!userData) return null;

const captable = userData.captable;
if (!captable) return null;

const currentShares = captable.currentShareholders?.reduce((sum, s) => sum + (s.shares || 0), 0) || 0;
const targetShares = (captable.targetedShareholders?.reduce((sum, s) => sum + (s.shares || 0), 0) || 0) + currentShares;

const fundingRounds = userData.fundingRounds?.fundingRounds || [];
const targetRound = fundingRounds.find(r => r.isTargetRound);
const sharePrice = targetRound ? targetRound.investmentAmount / (targetShares - currentShares) : 0;

return (
    <div className="mt-6 bg-green-50 p-4 rounded-lg">
        <h4 className="font-sm text-green-700">{t('Please verify these changes:')}</h4>

        <div className="space-y-2">
            <p className="text-sm text-green-600">
                {t('Total Share Amount goes from {{currentShares}} to {{targetShares}}', {
                    currentShares: currentShares.toLocaleString(),
                    targetShares: targetShares.toLocaleString()
                })}
            </p>

            <div className="space-y-2">
                {captable.targetedShareholders?.map((shareholder, idx) => (
                    <p key={idx} className="text-sm text-green-600">
                        {t('{{shareholderName}} has invested {{investment}} to hold {{percentage}}% of the shares', {
                            shareholderName: shareholder.name || t('NewShareholder {{number}}', { number: idx + 1 }),
                            investment: new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: userData.fundingRounds?.currency || 'EUR'
                            }).format(shareholder.shares * sharePrice),
                            percentage: ((shareholder.shares / targetShares) * 100).toFixed(2)
                        })}
                    </p>
                ))}
            </div>
        </div>
    </div>
);
};

export default ConclusionHandler;