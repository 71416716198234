// src/components/wizard_components/DataPrivacyWizard/ControlDetailsModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../hooks/useUserData';

const ControlDetailsModal = ({ 
  isOpen, 
  onClose, 
  controlId, 
  control, 
  isSelected, 
  onToggleSelection 
}) => {
  const { t } = useTranslation();
  const { userData } = useUserData();

  // Get user language
  const getUserLanguage = () => {
    return userData?.language ? userData.language.slice(0, 2) : 'en';
  };

  // Get control name based on language
  const getControlName = (control) => {
    if (!control) return '';
    return getUserLanguage() === 'de' ? control['Control name (de)'] : control['Control name'];
  };

  if (!isOpen || !control) return null;
  
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  {getControlName(control)}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {control.id}
                  </p>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.definition')}
                    </h4>
                    <p className="mt-1 text-sm text-gray-600">
                      {getUserLanguage() === 'de' ? control['Control definition (d)'] : control['Control definition']}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.purpose')}
                    </h4>
                    <p className="mt-1 text-sm text-gray-600">
                      {getUserLanguage() === 'de' ? control['Purpose (d)'] : control['Purpose']}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.characteristics')}
                    </h4>
                    <div className="mt-1 grid grid-cols-2 gap-2">
                      {control.Preventive === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.preventive')}
                        </span>
                      )}
                      {control.Detective === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.detective')}
                        </span>
                      )}
                      {control.Corrective === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.corrective')}
                        </span>
                      )}
                      {control.C === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.confidentiality')}
                        </span>
                      )}
                      {control.I === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.integrity')}
                        </span>
                      )}
                      {control.A === 'x' && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {t('DataPrivacyWizard.dpia.riskTreatment.filters.availability')}
                        </span>
                      )}
                    </div>
                  </div>
                  
                  {/* Related Standards */}
                  {control['ISO 27002:2022 Chapter'] && (
                    <div className="mt-4">
                      <h4 className="text-sm font-medium text-gray-700">
                        {t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.relatedStandards')}
                      </h4>
                      <p className="mt-1 text-sm text-gray-600">
                        {control['ISO 27002:2022 Chapter']}
                      </p>
                      {control['Additional standards'] && (
                        <p className="mt-1 text-sm text-gray-600">
                          {control['Additional standards']}
                        </p>
                      )}
                    </div>
                  )}
                  
                  {/* Explanation in simple language if available */}
                  {(control['ELI13'] || control['ELI13 (d)']) && (
                    <div className="mt-4 bg-blue-50 p-3 rounded-md">
                      <h4 className="text-sm font-medium text-blue-700">
                        {t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.inSimpleTerms')}
                      </h4>
                      <p className="mt-1 text-sm text-blue-600">
                        {getUserLanguage() === 'de' ? control['ELI13 (d)'] : control['ELI13']}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className={`inline-flex justify-center rounded-md border ${
                isSelected
                  ? 'border-transparent bg-primary-color hover:bg-opacity-90 text-white'
                  : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
              } shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:text-sm`}
              onClick={() => {
                onToggleSelection(controlId);
                onClose();
              }}
            >
              {isSelected
                ? t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.removeControl')
                : t('DataPrivacyWizard.dpia.riskTreatment.controlDetails.selectControl')}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              {t('DataPrivacyWizard.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDetailsModal;