import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import Notification from '../../../components/Notification';
import OneSlider from '../../../components/wizard_components/OneSlider';
import LongTextField from '../../../components/wizard_components/LongTextField';
import CircleChart from '../../../components/wizard_components/CircleChart';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import { ExclamationTriangleIcon, CheckIcon, AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardFundingNeeds = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData, refetch } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/fundingNeeds');

    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');

    // Get both capital required and currency from the previous page's data
    const capitalRequired = userData?.entity?.know_yourself_response?.published?.capitalRequired ||
        userData?.entity?.know_yourself_response?.draft?.capitalRequired || 0;
    const currency = userData?.entity?.know_yourself_response?.published?.fundingCurrency ||
        userData?.entity?.know_yourself_response?.draft?.fundingCurrency || 'USD';

    const [initialValues, setInitialValues] = useState({
        useOfFunds: {
            productDevelopment: 20,
            marketingSales: 20,
            operationsHiring: 20,
            researchDevelopment: 20,
            other: 20,
        },
        descriptions: {
            productDevelopment: '',
            marketingSales: '',
            operationsHiring: '',
            researchDevelopment: '',
            other: '',
        },
    });

    // Improved initial values loading mechanism
    useEffect(() => {
        if (!userData?.entity?.know_yourself_response) return;

        const { published, draft } = userData.entity.know_yourself_response;
        const relevantDraftData = draft?.fundingNeeds || {};
        const relevantPublishedData = published?.fundingNeeds || {};

        // Default initial values
        const defaultInitialValues = {
            useOfFunds: {
                productDevelopment: 20,
                marketingSales: 20,
                operationsHiring: 20,
                researchDevelopment: 20,
                other: 20,
            },
            descriptions: {
                productDevelopment: '',
                marketingSales: '',
                operationsHiring: '',
                researchDevelopment: '',
                other: '',
            },
        };

        // Helper function to check if data is effectively empty or default
        const isDataEmpty = (data) => {
            if (!data || Object.keys(data).length === 0) return true;

            // Check if useOfFunds is the default 20% distribution
            if (data.useOfFunds) {
                const defaultFundsCheck = Object.values(data.useOfFunds).every(val => val === 20);
                const defaultDescriptionsCheck = !data.descriptions ||
                    Object.values(data.descriptions).every(val => val === '');

                return defaultFundsCheck && defaultDescriptionsCheck;
            }

            return false;
        };

        // Determine data status and initial values
        if (!isDataEmpty(relevantPublishedData)) {
            // Published data exists and is not empty
            setDataStatus('published');
            setInitialValues({
                ...defaultInitialValues,
                ...relevantPublishedData
            });

            // Check if draft is different from published
            if (!isDataEmpty(relevantDraftData) &&
                JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData)) {
                setDataStatus('draft');
                setInitialValues({
                    ...defaultInitialValues,
                    ...relevantDraftData
                });
            }
        } else if (!isDataEmpty(relevantDraftData)) {
            // No published data, but draft exists
            setDataStatus('draft');
            setInitialValues({
                ...defaultInitialValues,
                ...relevantDraftData
            });
        } else {
            // Completely new state
            setDataStatus('new');
            setInitialValues(defaultInitialValues);
        }
    }, [userData]);

    const validationSchema = Yup.object({
        useOfFunds: Yup.object({
            productDevelopment: Yup.number().min(0).max(100),
            marketingSales: Yup.number().min(0).max(100),
            operationsHiring: Yup.number().min(0).max(100),
            researchDevelopment: Yup.number().min(0).max(100),
            other: Yup.number().min(0).max(100),
        }).test(
            'sum-equals-100',
            t('KnowYourselfWizard.totalAllocationError'),
            (values) => {
                const total = Object.values(values).reduce((sum, val) => sum + val, 0);
                return Math.abs(100 - total) <= 0.1; // 0.1% tolerance for rounding errors
            }
        ),
        descriptions: Yup.object({
            productDevelopment: Yup.string().max(2000, t('KnowYourselfWizard.descriptionTooLong')),
            marketingSales: Yup.string().max(2000, t('KnowYourselfWizard.descriptionTooLong')),
            operationsHiring: Yup.string().max(2000, t('KnowYourselfWizard.descriptionTooLong')),
            researchDevelopment: Yup.string().max(2000, t('KnowYourselfWizard.descriptionTooLong')),
            other: Yup.string().max(2000, t('KnowYourselfWizard.descriptionTooLong'))
        })
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // First save as a draft
            await handleDraftSubmit({ responses: { fundingNeeds: values } });

            // Then publish
            await handleSubmit({ responses: { fundingNeeds: values } });

            setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
            setDataStatus('published');

            // Navigate to next page after successful save
            setTimeout(() => {
                navigate('/funding/fundingRounds');
            }, 1000);
        } catch (error) {
            setNotification({ show: true, type: 'error', message: 'Failed to save data', errors: [error.message] });
        }
        setSubmitting(false);
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: { fundingNeeds: values } });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
        }
        setSubmitting(false);
    };

    const adjustPercentages = (values, field, newValue, setFieldValue) => {
        let updatedFunds = { ...values.useOfFunds, [field]: newValue };
        const totalPercentage = Object.values(updatedFunds).reduce((sum, val) => sum + val, 0);

        if (totalPercentage > 100) {
            const excess = totalPercentage - 100;
            const otherFields = Object.keys(updatedFunds).filter((key) => key !== field);
            for (let key of otherFields) {
                if (updatedFunds[key] > 0) {
                    const reduction = Math.min(updatedFunds[key], excess);
                    updatedFunds[key] -= reduction;
                    break;
                }
            }
        }

        setFieldValue('useOfFunds', updatedFunds);
    };

    const fundingCategories = [
        'productDevelopment',
        'marketingSales',
        'operationsHiring',
        'researchDevelopment',
        'other'
    ];

    const useOfFundsLabels = {
        productDevelopment: t('Product Development'),
        marketingSales: t('Marketing & Sales'),
        operationsHiring: t('Operations & Hiring'),
        researchDevelopment: t('Research & Development'),
        other: t('Other'),
    };

    const useOfFundsDescriptions = {
        productDevelopment: t('Explain if you plan to hire additional engineers or designers, invest in new product features, or build out your technical infrastructure.'),
        marketingSales: t('Include spending on advertising campaigns, public relations, sales team expansion, or growth experiments to acquire and retain customers.'),
        operationsHiring: t('Clarify your plans to build operational efficiency—this can include adding key leadership roles, human resources, or other core team members.'),
        researchDevelopment: t('If you are in a sector that requires R&D (like biotech or hardware), highlight how funding will help accelerate prototypes, clinical trials, or new product lines.'),
        other: t('If you have important expenses that are not covered by other categories, you can place them here (e.g., IT costs, administration, etc.).'),
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue, isSubmitting, validateForm }) => (
                    <Form onSubmit={async (e) => {
                        e.preventDefault();
                        const errors = await validateForm(values);
                        if (Object.keys(errors).length === 0) {
                            onSubmit(values, { setSubmitting: () => { } });
                        } else {
                            const errorMessages = [];

                            // Handle useOfFunds allocation error
                            if (errors.useOfFunds) {
                                if (typeof errors.useOfFunds === 'string') {
                                    // This is our custom allocation error
                                    const total = Object.values(values.useOfFunds).reduce((sum, val) => sum + val, 0);
                                    if (total < 100) {
                                        errorMessages.push(t('KnowYourselfWizard.underAllocation', {
                                            remaining: (100 - total).toFixed(1),
                                            amount: Math.round(((100 - total) / 100) * capitalRequired).toLocaleString(),
                                            currency
                                        }));
                                    } else if (total > 100) {
                                        errorMessages.push(t('KnowYourselfWizard.overAllocation', {
                                            excess: (total - 100).toFixed(1),
                                            amount: Math.round(((total - 100) / 100) * capitalRequired).toLocaleString(),
                                            currency
                                        }));
                                    }
                                }
                            }

                            // Handle description length errors
                            if (errors.descriptions) {
                                Object.entries(errors.descriptions).forEach(([field, message]) => {
                                    const fieldTranslations = {
                                        productDevelopment: t('KnowYourselfWizard.productDevelopment'),
                                        marketingSales: t('KnowYourselfWizard.marketingSales'),
                                        operationsHiring: t('KnowYourselfWizard.operationsHiring'),
                                        researchDevelopment: t('KnowYourselfWizard.researchDevelopment'),
                                        other: t('KnowYourselfWizard.other')
                                    };
                                    errorMessages.push(`${fieldTranslations[field]}: ${message}`);
                                });
                            }

                            setNotification({
                                show: true,
                                type: 'error',
                                message: t('Failed to save data due to validation errors:'),
                                errors: errorMessages
                            });
                        }
                    }}>
                        <h2 className="text-primary-color">{t('KnowYourselfWizard.fundingNeeds')}</h2>
                        <p className="mb-8">{t('KnowYourselfWizard.fundingNeedsIntro')}</p>

                        {/* Investment Needs Recommendations */}
                        <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setRecommendationsOpen(!recommendationsOpen);
                                }}
                                className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                            >
                                <div className="flex items-center">
                                    <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                                    <span className="text-primary-color">{t('Recommendations')}</span>
                                </div>
                                <svg
                                    className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            {recommendationsOpen && (
                                <div className="p-4 bg-white rounded-b-lg border-t">
                                    <div className="space-y-4 text-gray-700">
                                        {/* Funding Allocation Assessment */}
                                        <div>
                                            <h4 className="text-sm font-semibold text-gray-900">
                                                {t('fundingNeedsRecommendations.allocationAssessment.title')}
                                            </h4>
                                            <p className="text-sm">
                                                {t('fundingNeedsRecommendations.allocationAssessment.content')}
                                            </p>
                                        </div>
                                        {/* Allocation Percentage Guidelines */}
                                        <div>
                                            <h4 className="text-sm font-semibold text-gray-900">
                                                {t('fundingNeedsRecommendations.percentageGuidelines.title')}
                                            </h4>
                                            <p className="text-sm">
                                                {t('fundingNeedsRecommendations.percentageGuidelines.content')}
                                            </p>
                                        </div>
                                        {/* Budgeting and Cost Estimation */}
                                        <div>
                                            <h4 className="text-sm font-semibold text-gray-900">
                                                {t('fundingNeedsRecommendations.budgeting.title')}
                                            </h4>
                                            <p className="text-sm">
                                                {t('fundingNeedsRecommendations.budgeting.content')}
                                            </p>
                                            <ul className="list-disc list-inside text-sm">
                                                <li>{t('fundingNeedsRecommendations.budgeting.bullet1')}</li>
                                                <li>{t('fundingNeedsRecommendations.budgeting.bullet2')}</li>
                                                <li>{t('fundingNeedsRecommendations.budgeting.bullet3')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div className="bg-white p-6 mb-6 rounded-lg shadow">
                            <div className="font-semibold text-xl">
                                {t('Total Capital Required')}: {capitalRequired.toLocaleString()} {currency}
                            </div>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <div className="mb-6">
                                        <h3>{t('Use of Funds')}</h3>
                                        <div className="text-sm text-gray-500">
                                            {t('Distribute the capital required among the following categories:')}
                                        </div>
                                    </div>
                                    {fundingCategories.map((key) => {
                                        const cashValue = Math.round((values.useOfFunds[key] / 100) * capitalRequired);
                                        return (
                                            <div key={key} className="mb-6">
                                                <div className="flex justify-between font-semibold">
                                                    <span>{useOfFundsLabels[key]}</span>
                                                    <span>
                                                        {values.useOfFunds[key]}% (apx. {cashValue.toLocaleString()} {currency})
                                                    </span>
                                                </div>
                                                <OneSlider
                                                    name={`useOfFunds.${key}`}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    value={values.useOfFunds[key]}
                                                    onChange={(val) => adjustPercentages(values, key, val, setFieldValue)}
                                                />
                                                <LongTextField
                                                    name={`descriptions.${key}`}
                                                    placeholder={useOfFundsDescriptions[key]}
                                                    rows={4}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <CircleChart
                                        data={fundingCategories.map(key => values.useOfFunds[key])}
                                        labels={fundingCategories.map(key => useOfFundsLabels[key])}
                                        capitalRequired={capitalRequired}
                                        currency={currency}
                                    />

                                    {(() => {
                                        const total = Object.values(values.useOfFunds).reduce((sum, val) => sum + val, 0);
                                        const tolerance = 0.1; // 0.1% tolerance for rounding errors
                                        const difference = Math.abs(100 - total);

                                        if (difference <= tolerance) {
                                            return (
                                                <div className="mt-4 rounded-md bg-green-50 p-4">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-3">
                                                            <h3 className="text-sm font-medium text-green-800">
                                                                {t('Funds correctly allocated')}
                                                            </h3>
                                                            <div className="mt-2 text-sm text-green-700">
                                                                <p>
                                                                    {t('All funds have been correctly allocated among categories')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        if (total < 100) {
                                            const remaining = 100 - total;
                                            return (
                                                <div className="mt-4 rounded-md bg-yellow-50 p-4">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-3">
                                                            <h3 className="text-sm font-medium text-yellow-800">
                                                                {t('Funds not fully allocated')}
                                                            </h3>
                                                            <div className="mt-2 text-sm text-yellow-700">
                                                                <p>
                                                                    {t('{{remaining}}% of funds ({{amount}} {{currency}}) still need to be allocated', {
                                                                        remaining: remaining.toFixed(1),
                                                                        amount: Math.round((remaining / 100) * capitalRequired).toLocaleString(),
                                                                        currency
                                                                    })}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        const excess = total - 100;
                                        return (
                                            <div className="mt-4 rounded-md bg-red-50 p-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-red-800">
                                                            {t('Too much funds allocated')}
                                                        </h3>
                                                        <div className="mt-2 text-sm text-red-700">
                                                            <p>
                                                                {t('You have allocated {{excess}}% too much funds ({{amount}} {{currency}})', {
                                                                    excess: excess.toFixed(1),
                                                                    amount: Math.round((excess / 100) * capitalRequired).toLocaleString(),
                                                                    currency
                                                                })}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="button"
                                className="text-sm text-gray-900"
                                onClick={() => navigate('/funding/investment')}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                disabled={isSubmitting}
                                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm"
                            >
                                {t('Save as Draft')}
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm"
                            >
                                {t('save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
        </>
    );
};

export default KnowYourselfWizardFundingNeeds;