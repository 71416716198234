// src/components/wizard_components/DataPrivacyWizard/SOPPrivacyImpactAssessmentDE.js
import React from 'react';

// Hilfsfunktion zur Prüfung, ob eine Maßnahme angewendet wird
const hasControlApplied = (controlsMatrix, controlId) => {
    if (!controlsMatrix || typeof controlsMatrix !== 'object') {
        return false;
    }
    return Object.values(controlsMatrix).some(activityControls => {
        if (!activityControls || typeof activityControls !== 'object') {
            return false;
        }
        return activityControls[controlId] === true;
    });
};

// Hilfsfunktion zur Prüfung, ob ein Ausführungskriterium überschrieben ist
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);
    if (!isControlApplied) {
        // Wenn die Maßnahme selbst nicht angewendet wird, ist das Kriterium irrelevant/effektiv überschrieben
        return true;
    }
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        return riskControlOverrides[controlId].includes(criteriaId);
    }
    return false; // Standardmäßig nicht überschrieben, wenn die Maßnahme aktiv ist und keine Überschreibung vorliegt
};

const SOPPrivacyImpactAssessmentDE = {

    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo, role) => {
        const orgName = entityInfo.name || '[Name Ihrer Organisation]';

        // Diese SOP richtet sich primär an die Rolle des Verantwortlichen, basierend auf Titel und Inhalt der Quell-HTML
        const isController = role === 'Controller'; // In German: Verantwortlicher

        // --- Spezifische Maßnahmen & Kriterien für Verantwortliche ---
        const hasPC7205 = isController && hasControlApplied(controlsMatrix, 'PC7205'); // Datenschutz-Folgenabschätzung
        const isPC72051aOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1a'); // Auslöser: automatisierte Entscheidungsfindung
        const isPC72051bOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1b'); // Auslöser: umfangreiche Verarbeitung besonderer Kategorien
        const isPC72051cOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1c'); // Auslöser: systematische Überwachung öffentlicher Bereiche

        // --- Dynamische Nummerierung ---
        let sectionCounter = 0;
        let subSectionCounters = {};

        const renderSectionHeading = (title) => {
            sectionCounter++;
            subSectionCounters[sectionCounter] = 0;
            // Verwenden von H2 für Hauptabschnitte
            return `<h2 style="font-size: 1.5rem; margin-top: 1.5rem;">${sectionCounter}. ${title}</h2>`;
        };

        const renderSubSectionHeading = (title) => {
            subSectionCounters[sectionCounter]++;
             // Verwenden von H3 für Unterabschnitte
            return `<h3 style="font-size: 1.25rem; margin-top: 1.25rem;">${sectionCounter}.${subSectionCounters[sectionCounter]} ${title}</h3>`;
        };

        // Schritt-Rendering
        const renderStep = (title, content) => {
             if (!title) return content || '';
             // Verwendung eines einfachen <p><strong> Wrappers für Schritt-Titel gemäß Quell-HTML-Struktur
             return `<p><strong>${title}</strong></p>${content || ''}`;
        };

        // Generiere Inhalt nur, wenn die Rolle 'Verantwortlicher' ist und die relevante Maßnahme aktiv ist
        if (!isController || !hasPC7205) {
            return ''; // Leeren String zurückgeben, falls nicht zutreffend
        }

        // Initialer HTML-Inhalt mit vereinfachtem Titel
        let htmlContent = `<h1>SOP: Datenschutz-Folgenabschätzung (DSFA / PIA)</h1>`;

        // --- Abschnitt 1: Rechtliche / Normative Grundlagen ---
        let legalBasisContent = `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSGVO Artikel 35 – Datenschutz-Folgenabschätzung (DSFA):</strong> Verpflichtet Verantwortliche zur Durchführung einer Abschätzung der Folgen der vorgesehenen Verarbeitungsvorgänge, wenn eine Verarbeitung voraussichtlich ein hohes Risiko für die Rechte und Freiheiten natürlicher Personen zur Folge hat. Dies gilt insbesondere für die systematische und umfassende Bewertung persönlicher Aspekte auf Grundlage automatisierter Verarbeitung, die umfangreiche Verarbeitung besonderer Kategorien von Daten oder die systematische umfangreiche Überwachung öffentlich zugänglicher Bereiche.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSGVO Artikel 35 Absatz 7:</strong> Legt Mindestanforderungen an eine DSFA fest, einschließlich einer systematischen Beschreibung der Verarbeitungsvorgänge, einer Bewertung der Notwendigkeit und Verhältnismäßigkeit, einer Bewertung der Risiken für die Rechte und Freiheiten der betroffenen Personen sowie der zur Bewältigung der Risiken geplanten Abhilfemaßnahmen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSGVO Artikel 36 – Vorherige Konsultation:</strong> Ergibt eine DSFA ein hohes Risiko, das nicht durch Maßnahmen gemindert werden kann, muss der Verantwortliche vor der Verarbeitung die Aufsichtsbehörde konsultieren.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2019 – PC7205 (Privacy Impact Assessment):</strong> Fordert von Organisationen, „die Notwendigkeit einer Datenschutz-Folgenabschätzung zu bewerten und diese gegebenenfalls durchzuführen, wann immer eine neue Verarbeitung von PII oder Änderungen an einer bestehenden Verarbeitung von PII geplant sind.“</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 29134:</strong> Bietet weitere Leitlinien für Datenschutz-Folgenabschätzungen im Zusammenhang mit der Verarbeitung personenbezogener Daten.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSGVO Artikel 5 Absatz 2 – Rechenschaftspflicht:</strong> Verlangt von Verantwortlichen, die Einhaltung der Datenschutzgrundsätze nachweisen zu können. Eine DSFA ist ein wichtiges Instrument der Rechenschaftspflicht.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSGVO Artikel 24 Absatz 1 – Verantwortung des Verantwortlichen:</strong> Erfordert die Umsetzung geeigneter technischer und organisatorischer Maßnahmen, um die Einhaltung der DSGVO sicherzustellen und nachweisen zu können, wobei DSFAs eine Schlüsselkomponente darstellen.</li></ul>
            <p><strong>Begründung:</strong> Diese SOP etabliert einen systematischen Ansatz zur Identifizierung, Bewertung und Minderung von Datenschutzrisiken, bevor eine Verarbeitung beginnt oder wenn wesentliche Änderungen an bestehenden Verarbeitungen vorgenommen werden. Durch die Durchführung von Datenschutz-Folgenabschätzungen kann ${orgName} Datenschutzrisiken frühzeitig erkennen und adressieren, die Einhaltung von Datenschutzvorschriften nachweisen und den Grundsatz des Datenschutzes durch Technikgestaltung (Privacy by Design) in alle Verarbeitungsaktivitäten integrieren.</p>`;
        htmlContent += renderSectionHeading('Rechtliche / Normative Grundlagen des Verfahrens') + legalBasisContent;

        // --- Abschnitt 2: Zweck ---
        let purposeContent = `
            <p>Der Zweck dieser SOP ist die Definition eines strukturierten Prozesses zur Durchführung von Datenschutz-Folgenabschätzungen (DSFAs), der Datenschutzrisiken identifiziert und adressiert, bevor Verarbeitungsaktivitäten mit personenbezogenen Daten beginnen oder wenn wesentliche Änderungen an bestehenden Verarbeitungen geplant sind. Dieses Verfahren zielt darauf ab:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Datenschutzrisiken frühzeitig identifizieren und mindern:</strong> Eine systematische Methode zur Identifizierung, Bewertung und Minderung von Datenschutzrisiken in den frühesten Phasen eines Projekts oder einer Änderung, die personenbezogene Daten betrifft, zu etablieren und dadurch den Grundsatz des Datenschutzes durch Technikgestaltung anzuwenden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Einhaltung gesetzlicher Vorschriften sicherstellen:</strong> Die Anforderungen von DSGVO Artikel 35 und anderen anwendbaren Gesetzen zu erfüllen, indem bestimmt wird, wann eine DSFA (oder DPIA gemäß DSGVO) erforderlich ist, und sichergestellt wird, dass sie alle erforderlichen Elemente abdeckt.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Informierte Entscheidungsfindung ermöglichen:</strong> Dem Management und den Projektteams ausreichende Informationen über Datenschutzrisiken zur Verfügung zu stellen, um fundierte Entscheidungen darüber zu treffen, ob und wie mit Verarbeitungsaktivitäten personenbezogener Daten fortgefahren werden soll.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Rechenschaftspflicht dokumentieren:</strong> Eine umfassende Dokumentation der Datenschutzrisikobewertungen zu erstellen und zu pflegen, um das Engagement der Organisation für den Datenschutz und die Einhaltung des Rechenschaftsprinzips nachzuweisen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Bewertung von Dienstleistern unterstützen:</strong> Die Auswirkungen auf den Datenschutz bei der Beauftragung von Drittanbietern oder Auftragsverarbeitern zu bewerten, die personenbezogene Daten im Auftrag von ${orgName} verarbeiten werden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Risikobewertung standardisieren:</strong> Konsistente Kriterien für die Bewertung von Datenschutzrisiken in der gesamten Organisation festzulegen, um sicherzustellen, dass alle Bewertungen die gleichen Schwellenwerte und Methoden verwenden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Integration in das Projektmanagement:</strong> Sicherzustellen, dass Datenschutzrisikobewertungen in die Projektmanagementmethodik und die Änderungsmanagementprozesse der Organisation integriert sind, sodass Datenschutzaspekte in jeder geeigneten Phase berücksichtigt werden.</li></ul>
            <p>Dieses Verfahren dient als umfassender Leitfaden für alle Mitarbeiter bei ${orgName}, die an der Implementierung oder Änderung von Systemen, Anwendungen, Diensten oder Prozessen beteiligt sind, die personenbezogene Daten beinhalten, und stellt sicher, dass Datenschutzrisiken in der gesamten Organisation identifiziert, bewertet und gemindert werden.</p>`;
        htmlContent += renderSectionHeading('Zweck des Verfahrens') + purposeContent;

        // --- Abschnitt 3: KPIs ---
        let kpisContent = `
            <p>Um die Wirksamkeit dieses Verfahrens zur Datenschutz-Folgenabschätzung zu messen, werden die folgenden Leistungskennzahlen (KPIs) festgelegt:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSFA-Abschlussrate:</strong> Prozentsatz neuer Projekte oder wesentlicher Änderungen an bestehenden Projekten mit personenbezogenen Daten, für die vor der Implementierung eine DSFA abgeschlossen wurde. <em>Ziel: 100%</em> – Kein Projekt mit personenbezogenen Daten sollte ohne eine angemessene Datenschutzrisikobewertung live gehen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSFA-Rechtzeitigkeit:</strong> Prozentsatz der DSFAs, die während der Planungs- oder Entwurfsphase von Projekten initiiert wurden (statt kurz vor der Implementierung). <em>Ziel: ≥90%</em> – DSFAs sollten frühzeitig in den Entwicklungsprozess integriert werden, um einen effektiven Datenschutz durch Technikgestaltung zu ermöglichen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Umsetzungsrate von Minderungsmaßnahmen:</strong> Prozentsatz der identifizierten hohen und mittleren Datenschutzrisiken, für die vor Beginn der Verarbeitung Minderungsmaßnahmen implementiert wurden. <em>Ziel: 100%</em> für hohe Risiken und ≥90% für mittlere Risiken – Sicherstellung, dass die größten Risiken adressiert werden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Konsultationen der Aufsichtsbehörde:</strong> Anzahl der Fälle, in denen aufgrund eines hohen Restrisikos eine Konsultation der Aufsichtsbehörde erforderlich war (gemäß DSGVO Artikel 36). <em>Ziel: <5%</em> der abgeschlossenen DSFAs – Dies zeigt an, dass die meisten Risiken intern gemindert werden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>DSFA-Qualitätsbewertung:</strong> Basierend auf internen Überprüfungen oder Auditergebnissen hinsichtlich der Gründlichkeit und Genauigkeit abgeschlossener DSFAs. <em>Ziel: ≥85%</em> Einhaltung der Qualitätskriterien – Sicherstellung, dass die Bewertungen umfassend und nützlich sind.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Wirksamkeit der Risikoidentifizierung:</strong> Anzahl der Datenschutzvorfälle oder -verletzungen, die bei Verarbeitungsaktivitäten auftraten, die einer DSFA unterzogen wurden, bei denen das Risiko jedoch nicht identifiziert wurde. <em>Ziel: Null</em> – Dies ist ein nachlaufender Indikator, der zeigt, ob DSFAs relevante Risiken effektiv erfassen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Abdeckung der Mitarbeiterschulungen:</strong> Prozentsatz der relevanten Mitarbeiter (Projektmanager, Systemverantwortliche, Entwickler usw.), die zu DSFA-Anforderungen und -Methoden geschult wurden. <em>Ziel: 100%</em> – Sicherstellung, dass alle relevanten Personen verstehen, wann und wie DSFAs durchzuführen sind.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Effizienz des DSFA-Prozesses:</strong> Durchschnittliche Zeit bis zum Abschluss einer DSFA von der Initiierung bis zur endgültigen Genehmigung. <em>Ziel: <20 Arbeitstage</em> für Standard-DSFAs – Ausgewogenheit zwischen Gründlichkeit und geschäftlichen Anforderungen an zeitnahe Entscheidungen.</li></ul>
            <p>Diese KPIs werden vierteljährlich vom Datenschutzkomitee und jährlich von der Geschäftsleitung überprüft, um sicherzustellen, dass der DSFA-Prozess wirksam ist und kontinuierlich verbessert wird.</p>`;
        htmlContent += renderSectionHeading('Leistungskennzahlen (KPIs)') + kpisContent;

        // --- Abschnitt 4: Verantwortlichkeiten ---
        let responsibilitiesContent = `
            <p>Klare Verantwortlichkeiten werden zugewiesen, um sicherzustellen, dass dieses Verfahren effektiv umgesetzt wird:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Datenschutzbeauftragter (DSB) / Privacy Officer:</strong> Der DSB ist der <strong>Verfahrensverantwortliche</strong>. Er/Sie gibt Leitlinien vor, wann eine DSFA erforderlich ist, überprüft abgeschlossene DSFAs auf Qualität und Vollständigkeit, genehmigt abschließende Bewertungen und führt das DSFA-Register. Der DSB konsultiert bei Bedarf die Aufsichtsbehörden (gemäß Artikel 36) und berät zu Strategien zur Risikominderung. Er/Sie überprüft und aktualisiert zudem regelmäßig die DSFA-Methodik und -Vorlagen, um sich entwickelnden Best Practices und regulatorischen Leitlinien Rechnung zu tragen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Projektmanager / Verantwortliche für Geschäftsinitiativen:</strong> Sind dafür verantwortlich zu erkennen, wann ein Projekt oder eine Änderung eine DSFA erfordern könnte, und den Prozess mit dem DSB einzuleiten. Sie stellen sicher, dass Datenschutzaspekte in die Projektplanung integriert werden und dass Ressourcen für die Durchführung von DSFAs und die Umsetzung von Minderungsmaßnahmen bereitgestellt werden. Sie sind auch dafür rechenschaftspflichtig, dass identifizierte Minderungsmaßnahmen vor Beginn der Verarbeitung umgesetzt werden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Systemverantwortliche / Prozessverantwortliche:</strong> Stellen detaillierte Informationen über die Verarbeitungsaktivitäten, Datenflüsse und vorhandenen Kontrollen für ihre jeweiligen Systeme oder Prozesse bereit. Sie arbeiten mit dem DSFA-Team zusammen, um aktuelle und geplante Verarbeitungsvorgänge genau zu dokumentieren und helfen bei der Umsetzung empfohlener Minderungsmaßnahmen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Datenschutzteam / Datenschutzkoordinatoren:</strong> Führen DSFAs in Zusammenarbeit mit Projektteams durch oder moderieren diese. Sie helfen bei der Informationsbeschaffung, dokumentieren Verarbeitungsaktivitäten, identifizieren Risiken und schlagen geeignete Minderungsmaßnahmen vor. Sie können auch Schulungen und Unterstützung für Geschäftsbereiche zum DSFA-Prozess anbieten.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Informationssicherheitsteam:</strong> Bewertet technische Sicherheitsmaßnahmen und -kontrollen während des DSFA-Prozesses. Sie unterstützen bei der Identifizierung technischer Risiken und geeigneter Sicherheitsminderungsmaßnahmen, insbesondere in Bezug auf Vertraulichkeit, Integrität und Verfügbarkeit. Sie validieren auch, dass technische Maßnahmen wie empfohlen umgesetzt werden.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Rechtsabteilung:</strong> Berät zu rechtlichen Aspekten der DSFA, einschließlich geeigneter Rechtsgrundlagen für die Verarbeitung, Vertragsanforderungen und Auslegung anwendbarer Datenschutzgesetze. Sie überprüft DSFAs mit hohem Risiko und unterstützt bei der Entscheidung, wann eine Konsultation der Aufsichtsbehörde erforderlich ist.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>IT-Architektur / Technologieteams:</strong> Stellen technische Informationen über Systeme, Datenflüsse und Sicherheitsmaßnahmen bereit. Sie helfen bei der Identifizierung datenschutzfreundlicher Technologien und Architekturen, die identifizierte Risiken mindern können.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Geschäftsleitung / Datenschutz-Lenkungsausschuss:</strong> Überprüft DSFAs mit hohem Risiko und trifft endgültige Entscheidungen über die Akzeptanz von Risiken oder die Zuweisung von Ressourcen für Minderungsmaßnahmen. Sie stellen das organisatorische Engagement zur Adressierung von Datenschutzrisiken sicher und überwachen das DSFA-Programm. Sie sind auch für die Abwägung von Datenschutzrisiken gegen Geschäftsziele verantwortlich.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Beschaffung / Lieferantenmanagement:</strong> Stellt bei DSFAs, die Drittanbieter oder Auftragsverarbeiter betreffen, sicher, dass eine angemessene Due Diligence durchgeführt wird und entsprechende Vertragsklauseln vorhanden sind. Sie koordinieren sich mit Lieferanten, um notwendige Informationen für die DSFA zu erhalten und kommunizieren Anforderungen an Datenschutzkontrollen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktenverwaltung / Data Governance Team:</strong> Stellt Informationen über Datenaufbewahrungsrichtlinien, Datenverzeichnisse und Klassifizierungsschemata zur Verfügung, um eine genaue Dokumentation von Datenflüssen und Verarbeitungsaktivitäten zu unterstützen.</li></ul>`;
        htmlContent += renderSectionHeading('Verantwortlichkeiten (Rollen & Abteilungen)') + responsibilitiesContent;


        // --- Abschnitt 5: Detaillierte Prozesse ---
        let processesHtml = '';
        let processSectionRendered = false; // Flag, um zu prüfen, ob Inhalt für Abschnitt 5 vorhanden ist

        // Prozess 5.1: Feststellung der Notwendigkeit einer DSFA
        processesHtml += renderSubSectionHeading('Feststellung der Notwendigkeit einer DSFA');
        processesHtml += `<p>Dieser Prozess legt Kriterien fest, um zu identifizieren, wann eine Datenschutz-Folgenabschätzung durchgeführt werden sollte.</p>`;
        processesHtml += renderStep('Schritt 1: DSFA-Screening-Bewertung (Vorprüfung)', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Initiale Projektbeschreibung oder Änderungsantrag für jede Initiative, die personenbezogene Daten beinhaltet. Dies kann aus Projektaufträgen, Systemarchitekturdokumenten, Prozessänderungsanträgen oder Beschaffungsplänen für neue Lieferantendienste stammen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Der Projektmanager oder Geschäftsverantwortliche füllt einen <strong>DSFA-Screening-Fragebogen</strong> aus, um festzustellen, ob eine DSFA erforderlich ist. Der Fragebogen enthält typischerweise Fragen zur Identifizierung:
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob die Initiative die Erhebung, Nutzung oder Weitergabe personenbezogener Daten beinhaltet</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Der Kategorien und des Volumens der betroffenen personenbezogenen Daten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob sensible oder besondere Kategorien von Daten betroffen sind</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Des Umfangs und der Reichweite der Verarbeitung (Anzahl der betroffenen Personen, geografische Reichweite)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob eine automatisierte Entscheidungsfindung mit erheblichen Auswirkungen stattfindet</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob eine systematische Überwachung oder Verfolgung von Personen stattfindet</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob neue Technologien oder innovative Anwendungen eingesetzt werden</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob Daten international übermittelt werden</li></ul>
                <p>Der ausgefüllte Fragebogen wird dem DSB/Datenschutzbüro zur Überprüfung vorgelegt. Der DSB bewertet die Antworten, um festzustellen, ob eine DSFA erforderlich ist und welches Bewertungsniveau angemessen ist (z. B. vollständige DSFA, vereinfachte Bewertung oder keine formale Bewertung erforderlich). Der DSB konsultiert bei Bedarf die Rechtsabteilung und die Informationssicherheit für diese Entscheidung.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Feststellung der DSFA-Erfordernis</strong> – Eine dokumentierte Entscheidung darüber, ob eine DSFA erforderlich ist und auf welchem Niveau. Diese wird dem Projektmanager zusammen mit den nächsten Schritten mitgeteilt. Die Feststellung wird im DSFA-Register des Datenschutzbüros erfasst. Wenn eine DSFA erforderlich ist, geht der Prozess zu Schritt 2 über. Andernfalls dokumentiert der Projektmanager die vom DSB bereitgestellte Begründung für die Ausnahme und fährt mit dem Projekt fort, wobei Standard-Datenschutzpraktiken angewendet werden.</li></ul>`);
        processesHtml += renderStep('Schritt 2: Prüfung verpflichtender DSFA-Auslöser', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Kriterien gemäß DSGVO Artikel 35 Absatz 3, organisationsspezifische verpflichtende DSFA-Auslöser und Informationen aus dem Screening-Fragebogen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSB/Datenschutzteam prüft, ob das Projekt einen der <strong>verpflichtenden Auslöser</strong> für eine DSFA erfüllt. Gemäß DSGVO Artikel 35 Absatz 3 ist eine DSFA erforderlich, wenn die Verarbeitung Folgendes beinhaltet:
                ${!isPC72051aOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Systematische und umfassende Bewertung persönlicher Aspekte natürlicher Personen, die sich auf automatisierte Verarbeitung einschließlich Profiling gründet und Rechtswirkungen für die natürliche Person entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt</li></ul>' : ''}
                ${!isPC72051bOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Umfangreiche Verarbeitung besonderer Kategorien von Daten (gemäß Artikel 9 DSGVO) oder von Daten über strafrechtliche Verurteilungen und Straftaten (gemäß Artikel 10 DSGVO)</li></ul>' : ''}
                ${!isPC72051cOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Systematische umfangreiche Überwachung öffentlich zugänglicher Bereiche</li></ul>' : ''}
                <p>Zusätzlich kann ${orgName} eigene verpflichtende Auslöser definiert haben, basierend auf Leitlinien der Aufsichtsbehörde oder internen Risikorichtlinien (z.B. gemäß einer Blacklist der zuständigen Aufsichtsbehörde). Beispiele hierfür könnten sein:</p>
                <ul class="bulleted-list"><li style="list-style-type:circle">Verarbeitung von Kinderdaten in großem Umfang oder für Zwecke wie Profiling oder Direktmarketing</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Verarbeitung im Zusammenhang mit der systematischen Überwachung oder Bewertung von Mitarbeitern</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Umfangreicher Einsatz biometrischer Daten zur eindeutigen Identifizierung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Kombination oder Abgleich von Datensätzen aus verschiedenen Quellen, die zu unerwarteten Erkenntnissen über Personen führen könnten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Verarbeitung, die betroffene Personen an der Ausübung ihrer Rechte oder der Nutzung einer Dienstleistung oder eines Vertrags hindern könnte</li></ul>
                <p>Wenn ein verpflichtender Auslöser erfüllt ist, ist eine vollständige DSFA erforderlich, unabhängig von anderen Faktoren. Wenn kein verpflichtender Auslöser erfüllt ist, prüft der DSB dennoch, ob eine DSFA aufgrund des Gesamtrisikoprofils aus Schritt 1 ratsam ist (z.B. wenn zwei oder mehr Kriterien aus einer Whitelist der Aufsichtsbehörde erfüllt sind).</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Bestätigung der DSFA-Erfordernis</strong> – Eine endgültige Feststellung, ob eine vollständige DSFA aufgrund regulatorischer Anforderungen oder Organisationsrichtlinien verpflichtend ist. Das Ergebnis wird im DSFA-Register erfasst und dem Projektmanager zusammen mit dem/den spezifischen zutreffenden Auslöser(n) mitgeteilt. Bei Projekten mit hohem Risiko informiert der DSB zu diesem Zeitpunkt auch die Geschäftsleitung. Wenn eine DSFA erforderlich ist, geht der Prozess zum nächsten Prozess (5.2) über.</li></ul>`);
        processSectionRendered = true; // Markiere Abschnitt 5 als mit Inhalt versehen

        // Prozess 5.2: Planung und Festlegung des DSFA-Umfangs
        processesHtml += renderSubSectionHeading('Planung und Festlegung des DSFA-Umfangs');
        processesHtml += `<p>Dieser Prozess beschreibt, wie eine Datenschutz-Folgenabschätzung vorbereitet und ihr Umfang definiert wird, nachdem ihre Notwendigkeit festgestellt wurde.</p>`;
        processesHtml += renderStep('Schritt 1: Zusammenstellung des DSFA-Teams', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Feststellung der DSFA-Erfordernis (aus Prozess 5.1), Projektdetails und organisatorische Ressourcen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Der DSB/das Datenschutzteam identifiziert in Zusammenarbeit mit dem Projektmanager die geeigneten <strong>Stakeholder (Beteiligten)</strong>, die an der DSFA beteiligt werden sollten. Dies umfasst typischerweise:
                <ul class="bulleted-list"><li style="list-style-type:circle">Einen leitenden Bewerter aus dem Datenschutzteam</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Den Projektmanager oder Geschäftsverantwortlichen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Den System- oder Prozessverantwortlichen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Einen Vertreter der Informationssicherheit</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Einen technischen Architekten oder Entwickler (für Technologieprojekte)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Einen Rechtsvertreter (insbesondere bei Bewertungen mit hohem Risiko)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Vertreter relevanter Geschäftsbereiche, die das System/den Prozess nutzen werden</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Gegebenenfalls Vertreter der betroffenen Personen oder deren Interessenvertretungen (z.B. Betriebsrat)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Fachexperten nach Bedarf (z. B. für spezifische Technologien)</li></ul>
                <p>Der DSB legt den erforderlichen Grad der Beteiligung jedes Stakeholders fest und erstellt einen DSFA-Zeitplan mit wichtigen Meilensteinen. Für komplexe Projekte wird ein formelles DSFA-Kick-off-Meeting angesetzt, um sicherzustellen, dass alle Teammitglieder den Prozess, ihre Rollen und den Zeitrahmen verstehen. Bei einfacheren Projekten kann die Koordination per E-Mail oder über bestehende Projektmeetings erfolgen.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>DSFA-Team-Zusammensetzung und Zeitplan</strong> – Eine dokumentierte Liste der DSFA-Teammitglieder, ihrer Rollen und Verantwortlichkeiten sowie ein Zeitplan für den Abschluss der Bewertung. Diese wird allen Stakeholdern mitgeteilt und mit der Projektdokumentation gespeichert. Der Output umfasst auch geplante Meetings für die Informationsbeschaffung und Risikobewertungs-Workshops.</li></ul>`);
        processesHtml += renderStep('Schritt 2: Definition von DSFA-Umfang und Methodik', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Projektdokumentation, Systemarchitektur, Datenflussdiagramme (falls vorhanden), Geschäftsanforderungen und Informationen aus der Screening-Bewertung.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSFA-Team definiert den <strong>Umfang und die Grenzen</strong> der Bewertung, um sicherzustellen, dass alle relevanten Verarbeitungsaktivitäten eingeschlossen sind, während die Bewertung fokussiert und handhabbar bleibt. Dies beinhaltet:
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifizierung der spezifischen zu bewertenden Verarbeitungsaktivitäten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Festlegung der Systemgrenzen und Schnittstellen zu anderen Systemen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Klärung, welche Aspekte des Verarbeitungslebenszyklus einbezogen werden (Erhebung, Nutzung, Speicherung, Weitergabe, Aufbewahrung, Löschung)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifizierung relevanter Stakeholder und betroffener Personen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Festlegung der geeigneten DSFA-Methodik und der zu verwendenden Werkzeuge (z. B. Risikobewertungsansatz, Workshop-Format, Standard-DSFA-Vorlage)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifizierung von Abhängigkeiten von anderen Bewertungen oder parallelen Prozessen (z. B. Sicherheitsrisikobewertungen, Lieferantenbewertungen)</li></ul>
                <p>Bei großen oder komplexen Projekten kann die DSFA in logische Komponenten oder Phasen unterteilt werden. Das Team legt auch fest, welche Dokumentation für den Abschluss der Bewertung erforderlich ist und wer sie bereitstellt. Der DSB stellt sicher, dass der Umfang den regulatorischen Anforderungen (insb. Artikel 35 Abs. 7 DSGVO) und Organisationsrichtlinien entspricht.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>DSFA-Umfangsdokument</strong> – Eine klare Definition dessen, was in der Bewertung enthalten ist (und was ausgeschlossen wird), zusammen mit der vereinbarten Methodik. Das Dokument spezifiziert:
                <ul class="bulleted-list"><li style="list-style-type:circle">Die zu bewertenden Verarbeitungsaktivitäten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Systeme, Anwendungen und Datenflüsse im Geltungsbereich</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Kategorien personenbezogener Daten und betroffener Personen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Der Bewertungsansatz und die zu verwendenden Werkzeuge</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentationsanforderungen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Rollen und Verantwortlichkeiten für die Durchführung jedes Aspekts der DSFA</li></ul>
                <p>Dieses Dokument wird vom DSB und dem Projektmanager genehmigt und dient als Roadmap für die Durchführung der DSFA. Es stellt sicher, dass alle Stakeholder ein gemeinsames Verständnis der Grenzen und Ziele der Bewertung haben.</p></li></ul>`);
        processesHtml += renderStep('Schritt 3: Sammlung initialer Dokumentation und Informationen', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Vorhandene Dokumentation über das Projekt oder die Änderung, wie z. B. Geschäftsanforderungen, funktionale Spezifikationen, Datenflussdiagramme, Systemarchitekturdokumente und bestehende Datenschutzhinweise.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSFA-Team sammelt und prüft alle relevanten Dokumentationen, um die geplante Verarbeitung zu verstehen. Typischerweise beinhaltet dies:
                <ul class="bulleted-list"><li style="list-style-type:circle">Anforderung spezifischer Dokumente von Projektteams und Systemverantwortlichen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Durchführung von Interviews oder Workshops mit Schlüssel-Stakeholdern, um Informationslücken zu schließen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Erstellung oder Aktualisierung von Datenflussdiagrammen, die zeigen, wie personenbezogene Daten durch das System/den Prozess fließen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Katalogisierung der beteiligten Kategorien personenbezogener Daten und betroffener Personen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentation des/der Verarbeitungszweck(e) und der Rechtsgrundlage(n) (gemäß Artikel 6 und ggf. 9 DSGVO)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifizierung geplanter Aufbewahrungsfristen und Löschmechanismen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Abbildung aller Dritten (Empfänger, Auftragsverarbeiter), die die Daten erhalten werden</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentation bestehender oder geplanter technischer und organisatorischer Maßnahmen (TOMs)</li></ul>
                <p>Die Informationssammlung wird vom DSFA-Leiter koordiniert, der sicherstellt, dass alle notwendigen Details gesammelt werden, bevor zur Bewertungsphase übergegangen wird. Bei komplexen Projekten kann dies mehrere Informationssammlungssitzungen oder Workshops erfordern.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>DSFA-Informationspaket</strong> – Eine Zusammenstellung aller Informationen, die zur Durchführung der Bewertung benötigt werden, einschließlich Datenflussdiagrammen, Datenverzeichnissen und Dokumentation der Verarbeitungszwecke und Rechtsgrundlagen. Dieses Paket dient als Grundlage für die Risikobewertung und wird mit der DSFA-Dokumentation gespeichert. Alle in diesem Schritt identifizierten Informationslücken werden zur Nachverfolgung dokumentiert. Dieses umfassende Informationspaket stellt sicher, dass die nachfolgende Risikobewertung auf einem genauen und vollständigen Verständnis der Verarbeitungsaktivitäten basiert.</li></ul>`);
        processSectionRendered = true;

        // Prozess 5.3: Durchführung der DSFA-Bewertung
        processesHtml += renderSubSectionHeading('Durchführung der DSFA-Bewertung');
        processesHtml += `<p>Dieser Prozess beschreibt detailliert, wie Datenschutzrisiken systematisch bewertet werden, nachdem die notwendigen Informationen gesammelt wurden.</p>`;
        processesHtml += renderStep('Schritt 1: Detaillierte Beschreibung der Verarbeitungsvorgänge', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Das DSFA-Informationspaket aus Prozess 5.2, einschließlich Datenflussdiagrammen, Projektdokumentation und Informationen aus Stakeholder-Interviews.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSFA-Team erstellt eine <strong>systematische Beschreibung der geplanten Verarbeitungsvorgänge</strong>, wie von DSGVO Artikel 35 Absatz 7 Buchstabe a gefordert. Diese Beschreibung beinhaltet typischerweise:
                <ul class="bulleted-list"><li style="list-style-type:circle">Detaillierte Darstellung des Datenflusses (ggf. mit Diagrammen), der zeigt, wie personenbezogene Daten durch das System/den Prozess bewegt werden</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Vollständiges Verzeichnis der verarbeiteten personenbezogenen Datenelemente, einschließlich besonderer Kategorien</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Klare Beschreibung der Zwecke der Verarbeitung und der Rechtsgrundlage(n) für jeden Zweck</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifizierung der betroffenen Personen und Kategorien von betroffenen Personen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentation der Datenerhebungsmethoden und -quellen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Beschreibung der Verarbeitungsvorgänge (automatisiert und manuell)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Details zu Datenspeicherorten, Sicherheitsmaßnahmen bei der Speicherung und geplanten Aufbewahrungsfristen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Informationen über Empfänger der Daten (intern und extern, einschließlich Auftragsverarbeitern)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Beschreibung etwaiger internationaler Übermittlungen und der dafür vorgesehenen Garantien (z.B. Standardvertragsklauseln, Angemessenheitsbeschluss)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentation der Schnittstellen, über die betroffene Personen mit dem System interagieren (z.B. zur Ausübung ihrer Rechte)</li></ul>
                <p>Das Team überprüft diese Beschreibung gemeinsam auf Genauigkeit und Vollständigkeit. Dieser Schritt soll ein klares Bild davon ergeben, "was mit den Daten geschieht", das alle Stakeholder verstehen und bestätigen. Bei komplexen Prozessen kann die Beschreibung nach Verarbeitungsphase oder Kategorie betroffener Personen unterteilt werden.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Dokumentation der Verarbeitungsbeschreibung</strong> – Ein umfassendes Dokument, das alle Aspekte der geplanten Verarbeitungsvorgänge gemäß Art. 35 Abs. 7 lit. a DSGVO beschreibt. Dieses Dokument bildet einen Kernbestandteil des abschließenden DSFA-Berichts, liefert die Grundlage für die Risikobewertung und dient als Nachweis für die Erfüllung der gesetzlichen Anforderungen. Die Richtigkeit wird vom System-/Prozessverantwortlichen bestätigt, bevor mit der nächsten Phase fortgefahren wird.</li></ul>`);
        processesHtml += renderStep('Schritt 2: Bewertung der Notwendigkeit und Verhältnismäßigkeit', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Die Dokumentation der Verarbeitungsbeschreibung aus Schritt 1, zusammen mit Geschäftsanforderungen und rechtlichem/regulatorischem Kontext.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSFA-Team bewertet, ob die geplante Verarbeitung in Bezug auf die Zwecke <strong>notwendig und verhältnismäßig</strong> ist, wie von DSGVO Artikel 35 Absatz 7 Buchstabe b gefordert. Diese Bewertung berücksichtigt:
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob jedes erhobene Datenelement für den/die angegebenen Zweck(e) tatsächlich erforderlich ist (Datenminimierung)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob der Umfang und die Art der Verarbeitungsvorgänge im Verhältnis zum verfolgten Zweck stehen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Ob die gleichen Ziele mit weniger Daten oder weniger eingreifenden Methoden erreicht werden könnten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Angemessenheit der geplanten Aufbewahrungsfristen (Speicherbegrenzung)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Gültigkeit und Angemessenheit der herangezogenen Rechtsgrundlage(n)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Wie die Rechte der betroffenen Personen (Auskunft, Berichtigung, Löschung etc.) gewährleistet und erleichtert werden</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die geplanten Transparenzmaßnahmen (Information der betroffenen Personen gemäß Art. 13/14 DSGVO)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Maßnahmen zur Sicherstellung der Datenqualität und -richtigkeit</li></ul>
                <p>Dieser Schritt erfordert oft eine intensive Diskussion im DSFA-Team, wobei die Rechtsabteilung die rechtliche Notwendigkeit prüft und Geschäftsvertreter die betrieblichen Erfordernisse darlegen. Bedenken hinsichtlich der Notwendigkeit oder Verhältnismäßigkeit werden für die Risikobewertung und mögliche Anpassungen (Minderungsmaßnahmen) festgehalten.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Bewertung der Notwendigkeit und Verhältnismäßigkeit</strong> – Eine dokumentierte Analyse, inwieweit die Verarbeitung die Anforderungen an Notwendigkeit und Verhältnismäßigkeit erfüllt. Sie enthält spezifische Feststellungen zu Aspekten, die möglicherweise übermäßig sind oder einer besseren Rechtfertigung bedürfen. Diese Bewertung kann Empfehlungen zur Datenminimierung, kürzeren Aufbewahrungsfristen, Anpassung der Rechtsgrundlage oder verbesserten Transparenzmaßnahmen enthalten. Der Output wird Teil des abschließenden DSFA-Berichts und informiert die Risikobewertung im nächsten Schritt.</li></ul>`);
        processesHtml += renderStep('Schritt 3: Identifizierung und Bewertung der Risiken für Rechte und Freiheiten', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Die Dokumentation der Verarbeitungsbeschreibung, die Bewertung der Notwendigkeit und Verhältnismäßigkeit sowie die Risikobewertungsmethodik und -kriterien der Organisation (z.B. Risikomatrix mit Definitionen für Eintrittswahrscheinlichkeit und Schadensausmaß).</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Das DSFA-Team <strong>identifiziert und bewertet systematisch die Risiken</strong> für die Rechte und Freiheiten der betroffenen Personen, die aus der Verarbeitung resultieren können (gemäß DSGVO Artikel 35 Absatz 7 Buchstabe c). Dies beinhaltet typischerweise:
                <ul class="bulleted-list"><li style="list-style-type:circle">Durchführung eines strukturierten Risikoidentifikations-Workshops oder Brainstormings</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Anwendung eines konsistenten Risikobewertungsrahmens, der Eintrittswahrscheinlichkeit und potenzielles Ausmaß des Schadens für die betroffene Person berücksichtigt (z.B. physisch, materiell oder immateriell)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung von Risiken bezogen auf die Datenschutzgrundsätze (z. B. unrechtmäßige Verarbeitung, mangelnde Transparenz, Zweckentfremdung, übermäßige Datenerhebung, fehlerhafte Daten, unbegrenzte Speicherung, Verletzung von Vertraulichkeit oder Integrität)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung von Risiken bezüglich der Rechte der betroffenen Personen (z.B. Erschwerung oder Verhinderung von Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerspruch)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Berücksichtigung spezifischer Risiken für besonders schutzbedürftige Personengruppen (z. B. Kinder, Mitarbeiter, Patienten)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung von Sicherheitsrisiken, die zu Datenschutzverletzungen führen können (z.B. unbefugter Zugriff, Offenlegung, Verlust, Zerstörung)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung von Risiken im Zusammenhang mit der Weitergabe an Dritte oder internationalen Übermittlungen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Betrachtung von Risiken der Zweckänderung ("Function Creep") oder unbeabsichtigter Folgen der Verarbeitung im Zeitverlauf</li></ul>
                <p>Jedes identifizierte Risiko wird dokumentiert, einschließlich seiner Ursache, der potenziellen Auswirkungen auf betroffene Personen, der Eintrittswahrscheinlichkeit und des Schadensausmaßes. Daraus ergibt sich eine Risikostufe (z.B. Niedrig/Mittel/Hoch) vor Berücksichtigung von Minderungsmaßnahmen (Bruttorisiko). Der DSB stellt sicher, dass die Risikobewertungsmethodik konsistent angewendet wird.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Datenschutz-Risikoregister</strong> – Ein Verzeichnis der identifizierten Datenschutzrisiken, das Folgendes enthält:
                <ul class="bulleted-list"><li style="list-style-type:circle">Beschreibung des Risikos und des potenziellen Schadens für Betroffene</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Betroffener Datenschutzgrundsatz oder Recht der betroffenen Person</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung des Bruttorisikos (Eintrittswahrscheinlichkeit und Schadensausmaß)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bereits geplante oder vorhandene Maßnahmen zur Risikobehandlung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung des Nettorisikos (verbleibendes Risiko nach Berücksichtigung vorhandener Maßnahmen)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Erste Vorschläge für zusätzliche Abhilfemaßnahmen</li></ul>
                <p>Dieses Risikoregister ist ein zentraler Bestandteil des DSFA-Berichts und bildet die Grundlage für die Entwicklung von Minderungsmaßnahmen im nächsten Schritt. Die Risiken werden nach ihrer Nettorisikostufe priorisiert, um die Anstrengungen zur Minderung auf die bedeutendsten Bedenken zu konzentrieren.</p></li></ul>`);
        processesHtml += renderStep('Schritt 4: Identifizierung und Bewertung von Abhilfemaßnahmen', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Das Datenschutz-Risikoregister aus Schritt 3, Informationen über verfügbare Sicherheits- und Datenschutzmaßnahmen (TOMs), organisatorische Fähigkeiten und Ressourcenbeschränkungen.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Für jedes identifizierte Risiko (insbesondere mittlere und hohe Risiken) <strong>entwickelt und bewertet</strong> das DSFA-Team <strong>Abhilfemaßnahmen</strong>, um die Risiken zu behandeln (gemäß DSGVO Artikel 35 Absatz 7 Buchstabe d). Dies beinhaltet:
                <ul class="bulleted-list"><li style="list-style-type:circle">Brainstorming möglicher technischer, organisatorischer und prozeduraler Maßnahmen für jedes Risiko</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung der Wirksamkeit und Durchführbarkeit der vorgeschlagenen Maßnahmen (Kosten-Nutzen-Abwägung)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Konsultation mit technischen, rechtlichen und geschäftlichen Stakeholdern, um die Praktikabilität der Maßnahmen sicherzustellen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Festlegung, wer für die Umsetzung jeder Maßnahme verantwortlich ist</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Schätzung von Umsetzungszeitplänen und Ressourcenbedarf</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Berücksichtigung der Auswirkungen der Maßnahmen auf Benutzerfreundlichkeit und Geschäftsabläufe</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Prüfung, ob die Maßnahmen möglicherweise neue Risiken einführen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Dokumentation, welche Risiken durch welche Maßnahmen adressiert werden</li></ul>
                <p>Das Team priorisiert Maßnahmen für hohe und mittlere Risiken und strebt an, diese auf ein akzeptables Niveau zu reduzieren. Für jede Maßnahme bewertet das Team, wie sie die Eintrittswahrscheinlichkeit oder das Schadensausmaß des Risikos reduziert, was zu einer neuen "Ziel-Risikostufe" führt (erwartetes Restrisiko nach Umsetzung).</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Maßnahmenplan zur Risikominderung</strong> – Ein detaillierter Plan, der Folgendes dokumentiert:
                <ul class="bulleted-list"><li style="list-style-type:circle">Spezifische Abhilfemaßnahmen für jedes identifizierte Risiko, das behandelt werden soll</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die erwartete Auswirkung auf die Risikostufe nach der Umsetzung (Ziel-Risikostufe)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Verantwortlichkeit für die Umsetzung jeder Maßnahme</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Zeitplan und Meilensteine für die Umsetzung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Benötigte Ressourcen und Abhängigkeiten</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Wie die Wirksamkeit der Maßnahme überprüft wird</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Alle Risiken, die nicht ausreichend gemindert werden können und bewusst akzeptiert werden (Restrisiken), mit Begründung</li></ul>
                <p>Dieser Plan wird vom DSB und relevanten Stakeholdern überprüft, um sicherzustellen, dass er die Risiken angemessen adressiert und umsetzbar ist. Der Plan wird Teil des abschließenden DSFA-Berichts und dient als Fahrplan für die Umsetzung. Alle hohen Restrisiken werden zur Überprüfung durch die Geschäftsleitung und für eine mögliche Konsultation der Aufsichtsbehörde (gemäß Art. 36 DSGVO) gekennzeichnet.</p></li></ul>`);
        processSectionRendered = true;

        // Prozess 5.4: Dokumentation, Genehmigung und Umsetzung
        processesHtml += renderSubSectionHeading('DSFA-Dokumentation, Genehmigung und Umsetzung');
        processesHtml += `<p>Dieser Prozess umfasst die Finalisierung des DSFA-Berichts, die Einholung notwendiger Genehmigungen und die Umsetzung der Abhilfemaßnahmen.</p>`;
        processesHtml += renderStep('Schritt 1: Erstellung des DSFA-Berichts', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Alle Dokumentationen und Ergebnisse aus den vorherigen Prozessen, einschließlich der Verarbeitungsbeschreibung, Bewertung der Notwendigkeit und Verhältnismäßigkeit, des Risikoregisters und des Maßnahmenplans. Gegebenenfalls Stellungnahme der betroffenen Personen oder ihrer Vertreter.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Der DSFA-Leiter stellt einen <strong>umfassenden DSFA-Bericht</strong> zusammen, der den gesamten Bewertungsprozess und die Schlussfolgerungen dokumentiert. Der Bericht enthält typischerweise:
                <ul class="bulleted-list"><li style="list-style-type:circle">Zusammenfassung (Management Summary) mit den wichtigsten Ergebnissen und Empfehlungen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Projektbeschreibung und Umfang der Bewertung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Systematische Beschreibung der Verarbeitungsvorgänge (gemäß Schritt 5.3.1)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bewertung der Notwendigkeit und Verhältnismäßigkeit (gemäß Schritt 5.3.2)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Angewandte Methodik zur Risikobewertung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Detaillierte Risikoanalyse und Ergebnisse (Risikoregister, gemäß Schritt 5.3.3)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Vorgeschlagene Abhilfemaßnahmen zur Risikobehandlung (gemäß Schritt 5.3.4)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Maßnahmenplan mit Verantwortlichkeiten und Zeitplänen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Gegebenenfalls eingeholte Stellungnahme des DSB</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Gegebenenfalls eingeholte Stellungnahme der betroffenen Personen oder ihrer Vertreter</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Schlussfolgerungen und Empfehlungen (z.B. Freigabe unter Auflagen, Notwendigkeit der Konsultation der Aufsichtsbehörde)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Anhänge mit unterstützender Dokumentation (z. B. Datenflussdiagramme)</li></ul>
                <p>Der Bericht folgt einem von ${orgName} festgelegten standardisierten Format, um Konsistenz über verschiedene Bewertungen hinweg sicherzustellen. Der DSB überprüft den Berichtsentwurf auf Vollständigkeit, Klarheit und Übereinstimmung mit den gesetzlichen Anforderungen (insb. Art. 35 Abs. 7 DSGVO). Feedback wird eingearbeitet, bevor das Dokument finalisiert wird.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Finaler DSFA-Bericht</strong> – Ein umfassendes Dokument, das als offizieller Nachweis der Bewertung, der Ergebnisse und der Empfehlungen dient. Der Bericht wird im Dokumentenmanagementsystem der Organisation gespeichert und mit dem DSFA-Register verknüpft. Dieses Dokument dient als Nachweis der Einhaltung von DSGVO Artikel 35 und als Referenz für die Umsetzung, zukünftige Überprüfungen und potenzielle behördliche Anfragen.</li></ul>`);
        processesHtml += renderStep('Schritt 2: Überprüfungs- und Genehmigungsprozess', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Der Finale DSFA-Bericht und die organisatorischen Genehmigungsverfahren.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Die DSFA durchläuft einen <strong>formalen Überprüfungs- und Genehmigungsprozess</strong>, der dem Risikoniveau angemessen ist:
                <ul class="bulleted-list"><li style="list-style-type:circle">Der DSB gibt eine fachliche Stellungnahme zur DSFA ab (Prüfung auf Qualität, Vollständigkeit und Einhaltung der Anforderungen).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bei DSFAs mit geringem Restrisiko kann die Genehmigung durch den Projekt-/Prozessverantwortlichen nach positiver Stellungnahme des DSB ausreichend sein.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bei mittlerem Restrisiko ist typischerweise zusätzlich die Genehmigung durch den zuständigen Abteilungsleiter oder eine höhere Managementebene erforderlich.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Bei hohem Restrisiko ist eine Genehmigung durch die Geschäftsleitung, die Rechtsabteilung und möglicherweise das Datenschutz-Lenkungskomitee erforderlich.</li></ul>
                <p>Die Prüfer bewerten, ob:</p>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Bewertung gründlich ist und die Verarbeitungsvorgänge korrekt erfasst</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Risikobewertung nachvollziehbar und umfassend ist</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die vorgeschlagenen Abhilfemaßnahmen angemessen und ausreichend sind</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Umsetzungspläne realistisch und ausreichend mit Ressourcen ausgestattet sind</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Alle verbleibenden (akzeptierten) Risiken angemessen bewertet und ordnungsgemäß dokumentiert sind</li></ul>
                <p>Wenn Prüfer Bedenken oder Lücken identifizieren, nimmt das DSFA-Team notwendige Überarbeitungen vor und legt den Bericht erneut zur Genehmigung vor. Bei Verarbeitungen mit hohem Restrisiko, bei denen die Risiken nicht ausreichend gemindert werden können, entscheidet der DSB (in Absprache mit der Rechtsabteilung und Geschäftsleitung), ob eine vorherige Konsultation der Aufsichtsbehörde gemäß DSGVO Artikel 36 erforderlich ist. Falls ja, wird der Konsultationsprozess vor der endgültigen internen Genehmigung eingeleitet.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Genehmigte DSFA mit Unterschriften/Freigaben</strong> – Der finale DSFA-Bericht mit formalen Genehmigungen aller erforderlichen Stakeholder, die ihre Zustimmung zu den Ergebnissen und dem Maßnahmenplan dokumentieren. Genehmigungen können über elektronische Signaturen, Genehmigungs-Workflow-Systeme oder dokumentierte E-Mail-Bestätigungen erfolgen, je nach den Praktiken von ${orgName}. Das genehmigte Dokument wird mit entsprechender Versionskontrolle gespeichert. Falls eine Konsultation der Aufsichtsbehörde erforderlich war, werden deren Antwort und etwaige zusätzliche Anforderungen in das endgültig genehmigte Dokument aufgenommen oder als Anhang beigefügt.</li></ul>`);
        processesHtml += renderStep('Schritt 3: Umsetzung der Abhilfemaßnahmen', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Die genehmigte DSFA mit dem Maßnahmenplan zur Risikominderung und zugewiesenen Verantwortlichkeiten.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Der Projektmanager stellt in Zusammenarbeit mit den zugewiesenen Verantwortlichen die <strong>Umsetzung der vereinbarten Abhilfemaßnahmen</strong> sicher:
                <ul class="bulleted-list"><li style="list-style-type:circle">Der Maßnahmenplan wird in spezifische Arbeitspakete und Aufgaben übersetzt.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Aufgaben werden verantwortlichen Personen mit klaren Fristen zugewiesen.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Umsetzung wird über das Projektmanagementsystem oder andere geeignete Tools verfolgt.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Technische Maßnahmen werden von der IT umgesetzt und vom Sicherheitsteam überprüft.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Organisatorische Maßnahmen (Richtlinien, Verfahren) werden von den zuständigen Abteilungen entwickelt und kommuniziert.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Schulungs- und Sensibilisierungsmaterialien werden bei Bedarf erstellt und durchgeführt.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Der DSB oder das Datenschutzteam bieten bei Bedarf Anleitung und Unterstützung während der Umsetzung.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Regelmäßige Status-Updates werden an die wichtigsten Stakeholder gegeben.</li></ul>
                <p>Die Umsetzung sollte vor Beginn der Verarbeitung (bei neuen Initiativen) oder gemäß einem vereinbarten Zeitplan (bei Änderungen an bestehenden Verarbeitungen) erfolgen. Der Projektmanager stellt sicher, dass alle kritischen Maßnahmen vor dem Go-Live implementiert sind und verfolgt alle zurückgestellten Punkte. Der DSB überwacht den Umsetzungsfortschritt und eskaliert Verzögerungen bei kritischen Maßnahmen an die entsprechenden Managementebenen.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Umsetzungsnachweis / Verifizierungsbericht</strong> – Dokumentation, die bestätigt, dass die Abhilfemaßnahmen wie geplant umgesetzt wurden, gegebenenfalls mit Nachweisen (z. B. Screenshots von Systemsteuerungen, Richtliniendokumente, Schulungsnachweise). Abweichungen vom ursprünglichen Plan werden mit Begründung und alternativen Ansätzen dokumentiert. Diese Verifizierung dient als Nachweis für das Engagement von ${orgName} für Datenschutz durch Technikgestaltung und Rechenschaftspflicht. Der Projektmanager und der DSB bestätigen diese Verifizierung, bevor die Verarbeitung beginnt oder Änderungen bereitgestellt werden.</li></ul>`);
        processesHtml += renderStep('Schritt 4: Überprüfung nach der Implementierung und laufende Überwachung', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input (Eingabe):</strong> Implementiertes System oder Prozess mit Datenschutzkontrollen, der ursprüngliche DSFA-Bericht und die Überwachungsverfahren der Organisation.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Aktivität (Tätigkeit):</strong> Der DSB/das Datenschutzteam führt in Zusammenarbeit mit dem System-/Prozessverantwortlichen <strong>Überprüfungen nach der Implementierung durch und etabliert eine laufende Überwachung</strong>:
                <ul class="bulleted-list"><li style="list-style-type:circle">Eine Überprüfung nach der Implementierung wird typischerweise 3-6 Monate nach der Bereitstellung angesetzt, um zu verifizieren, dass die Kontrollen wie beabsichtigt funktionieren.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Wirksamkeit der Maßnahmen wird anhand der ursprünglichen Risikobewertung evaluiert.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Etwaige neue oder geänderte Risiken, die seit der DSFA aufgetreten sind, werden identifiziert und bewertet.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Die Einhaltung der ursprünglichen Verarbeitungsbeschreibung wird überprüft (z.B. keine unbemerkte Zweckänderung).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Feedback von operativen Teams und Benutzern wird gesammelt und analysiert.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Leistungskennzahlen im Zusammenhang mit Datenschutzkontrollen werden festgelegt und überwacht.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Regelmäßige Überprüfungen der DSFA werden basierend auf dem Risikoniveau geplant (z. B. jährlich für Verarbeitungen mit hohem Risiko, oder bei wesentlichen Änderungen).</li></ul>
                <p>Die DSFA wird als lebendes Dokument behandelt, das aktualisiert werden sollte, wenn wesentliche Änderungen an den Verarbeitungsaktivitäten, dem organisatorischen Kontext oder dem regulatorischen Umfeld auftreten. Der System-/Prozessverantwortliche ist dafür verantwortlich, den DSB über alle wesentlichen Änderungen zu informieren, die eine Neubewertung auslösen könnten.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output (Ergebnis):</strong> <strong>Überwachungs- und Überprüfungsrahmen</strong> – Ein dokumentierter Ansatz für die laufende Aufsicht über die Verarbeitungsaktivität, einschließlich:
                <ul class="bulleted-list"><li style="list-style-type:circle">Zeitplan für periodische Überprüfungen der DSFA</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Metriken und Überwachungsmechanismen</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Verantwortlichkeiten für die kontinuierliche Einhaltung</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Auslösende Ereignisse, die eine Neubewertung erfordern</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Prozess für den Umgang mit Änderungen und Aktualisierungen der DSFA</li></ul>
                <p>Dieser Rahmen wird in das umfassendere Datenschutzmanagementprogramm und die Governance-Struktur von ${orgName} integriert. Regelmäßige Statusberichte werden im Rahmen der Datenschutzberichterstattung an die Geschäftsleitung übermittelt. Das DSFA-Register wird mit Überprüfungsdaten und Statusinformationen aktualisiert, um eine kontinuierliche Transparenz über alle bewerteten Verarbeitungsaktivitäten zu gewährleisten.</p></li></ul>`);
        processSectionRendered = true;


        // --- Abschnitt 5 Überschrift und Inhalt hinzufügen, falls zutreffend ---
        if (processSectionRendered) {
            htmlContent += renderSectionHeading('Detaillierte Prozesse');
            htmlContent += `<p>Die folgenden Prozesse beschreiben detailliert, wie die Aktivitäten der Datenschutz-Folgenabschätzung Schritt für Schritt durchgeführt werden. Jeder Prozess umfasst mehrere Schritte, und <strong>jeder Schritt wird mit seinem Input (Eingabe), seiner Aktivität (Tätigkeit) und seinem Output (Ergebnis) beschrieben</strong>, um die Durchführung des Verfahrens zu verdeutlichen und die Nachvollziehbarkeit der Compliance-Anforderungen zu gewährleisten:</p>`;
            htmlContent += processesHtml;
        }


        return htmlContent; // Den final kombinierten HTML-Code zurückgeben
    },

    shouldShow: (controlsMatrix, role) => {
        // Zeige diese SOP an, wenn die Organisation als Verantwortlicher ('Controller') agiert und PC7205 aktiviert ist.
        const relevantControl = 'PC7205';
        if (role !== 'Controller') {
             return false; // Nur für Verantwortliche relevant
        }
        if (!controlsMatrix || typeof controlsMatrix !== 'object') {
            return false;
        }
        // Prüfen, ob PC7205 für *irgendeine* Aktivität aktiviert ist
        return Object.values(controlsMatrix).some(activityControls =>
             activityControls && typeof activityControls === 'object' && activityControls[relevantControl] === true
        );
    },


    getIntroText: () => {
        // Kurze Einleitung für die SOP
        return 'Diese Standardarbeitsanweisung (SOP) definiert den Prozess zur Durchführung von Datenschutz-Folgenabschätzungen (DSFAs) für Verantwortliche.';
    }
};

export default SOPPrivacyImpactAssessmentDE;