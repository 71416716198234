import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Chart from 'react-apexcharts';
import Notification from '../../../components/Notification';
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const VESOPManagement = () => {
    const { t } = useTranslation();
    const { userData } = useUserData();
    const { handleSubmit } = useFormSubmit('/funding/vesopManagement');
    const [notification, setNotification] = useState({ show: false, type: '', message: '' });
    const [selectedPerson, setSelectedPerson] = useState('');
    const [allocatedPeople, setAllocatedPeople] = useState([]);
    const [poolStats, setPoolStats] = useState({
        totalShares: 0,
        allocatedShares: 0,
        remainingShares: 0
    });

    // State for new person form
    const [newPerson, setNewPerson] = useState({
        name: '',
        role: '',
        vestingStart: '',
        shareAmount: 0
    });

    useEffect(() => {
        if (!userData?.entity?.know_yourself_response) return;

        const { published, draft } = userData.entity.know_yourself_response;
        const vesopData = draft?.vesop || published?.vesop || {};
        const vesopManagementData = draft?.vesopManagement || published?.vesopManagement || { allocatedPeople: [] };

        // Get captable data
        const captableData = draft?.captable || published?.captable || {};
        const currentShareholders = captableData.currentShareholders || [];
        const targetedShareholders = captableData.targetedShareholders || [];

        // Calculate total shares
        const totalCurrent = currentShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
        const totalTarget = targetedShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
        const totalPostMoneyShares = totalCurrent + totalTarget;

        // Calculate pool shares based on percentage
        const totalShares = Math.round((totalPostMoneyShares * (vesopData.poolPercentage || 0)) / 100);
        // Important: Ensure we're getting numeric values and properly summing them
        const allocatedShares = vesopManagementData.allocatedPeople.reduce((sum, person) =>
            sum + (Number(person.shareAmount) || 0), 0);

        // Make sure we don't end up with negative numbers
        const remainingShares = Math.max(0, totalShares - allocatedShares);

        setPoolStats({
            totalShares,
            allocatedShares,
            remainingShares
        });

        setAllocatedPeople(vesopManagementData.allocatedPeople);

        if (vesopManagementData.allocatedPeople.length > 0) {
            setSelectedPerson(vesopManagementData.allocatedPeople[0].name);
        }
    }, [userData]);

    const calculateVestedShares = (person, date) => {
        if (!person || !date) return 0;

        const vestingStart = new Date(person.vestingStart);
        const currentDate = new Date(date);
        const monthsDiff = (currentDate.getFullYear() - vestingStart.getFullYear()) * 12 +
            (currentDate.getMonth() - vestingStart.getMonth());

        const vestingData = userData?.entity?.know_yourself_response?.published?.vesop ||
            userData?.entity?.know_yourself_response?.draft?.vesop;

        if (!vestingData) return 0;

        const { vestingCliff, vestingSchedule } = vestingData;

        if (monthsDiff < vestingCliff.months) return 0;

        if (monthsDiff >= vestingSchedule.totalDuration) return person.shareAmount;

        let vestedShares = 0;
        if (monthsDiff >= vestingCliff.months) {
            // Add cliff percentage
            vestedShares = Math.floor(person.shareAmount * (vestingCliff.percentage / 100));

            // Add linear vesting for remaining period
            const remainingMonths = monthsDiff - vestingCliff.months;
            const monthlyVesting = (person.shareAmount * (1 - vestingCliff.percentage / 100)) /
                (vestingSchedule.totalDuration - vestingCliff.months);

            vestedShares += Math.floor(monthlyVesting * remainingMonths);
        }

        return Math.min(vestedShares, person.shareAmount);
    };

    const generateVestingChartData = (person) => {
        if (!person) return [];

        const vestingStart = new Date(person.vestingStart);
        const vestingEnd = new Date(vestingStart);
        vestingEnd.setMonth(vestingEnd.getMonth() +
            userData?.entity?.know_yourself_response?.published?.vesop?.vestingSchedule.totalDuration ||
            userData?.entity?.know_yourself_response?.draft?.vesop?.vestingSchedule.totalDuration || 48);

        const data = [];
        let currentDate = new Date(vestingStart);

        while (currentDate <= vestingEnd) {
            const vestedShares = calculateVestedShares(person, currentDate);
            data.push({
                date: currentDate.toLocaleDateString('en-GB', { month: '2-digit', year: '2-digit' }),
                shares: vestedShares
            });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return data;
    };

    const generateValueChartData = (person) => {
        if (!person) return [];
        const { published, draft } = userData?.entity?.know_yourself_response || {};
        console.log("DEBUG: published=", published, "draft=", draft);

        // 1. Normalize funding rounds.
        const normalizeFundingRounds = (roundData) => {
            if (!roundData) return [];
            if (Array.isArray(roundData)) return roundData;
            if (roundData.fundingRounds && Array.isArray(roundData.fundingRounds)) {
                return roundData.fundingRounds;
            }
            return [];
        };
        const allRoundsDraft = normalizeFundingRounds(draft?.fundingRounds);
        const allRoundsPublished = normalizeFundingRounds(published?.fundingRounds);
        const allRounds = allRoundsDraft.length ? allRoundsDraft : allRoundsPublished;
        console.log("DEBUG: allRounds normalized=", allRounds);
        if (!allRounds.length) return [];

        // 2. Get exit strategy and append an exit event.
        const exitStrategy = draft?.exitStrategy || published?.exitStrategy;
        if (!exitStrategy || !exitStrategy.targetExitYear || !exitStrategy.targetExitValue) {
            console.log("DEBUG: Missing exit strategy info.");
            return [];
        }
        allRounds.push({
            time: { month: 12, year: exitStrategy.targetExitYear },
            type: 'Exit',
            preMoneyValuation: 0,
            investmentAmount: 0,
            postMoneyValuation: exitStrategy.targetExitValue,
            isExitStrategy: true,
        });
        console.log("DEBUG: allRounds with exit appended=", allRounds);

        // 3. Sort rounds chronologically.
        const sortedRounds = allRounds.sort((a, b) => {
            if (a.time.year !== b.time.year) return a.time.year - b.time.year;
            return a.time.month - b.time.month;
        });
        console.log("DEBUG: sortedRounds=", sortedRounds);

        // 4. Identify the target round.
        const targetRoundIndex = sortedRounds.findIndex(r => r.isTargetRound);
        if (targetRoundIndex === -1) return [];
        const previousRounds = sortedRounds.slice(0, targetRoundIndex);
        const futureRounds = sortedRounds.slice(targetRoundIndex);
        console.log("DEBUG: targetRoundIndex=", targetRoundIndex);
        console.log("DEBUG: previousRounds=", previousRounds);
        console.log("DEBUG: futureRounds=", futureRounds);
        const targetRoundDate = new Date(
            sortedRounds[targetRoundIndex].time.year,
            sortedRounds[targetRoundIndex].time.month - 1
        );
        console.log("DEBUG: targetRoundDate=", targetRoundDate);

        // 5. Get captable data for share counts.
        const captableData = draft?.captable || published?.captable || {};
        const currentShareholders = captableData.currentShareholders || [];
        // The captable's currentShareholders total is the post-dilution share count.
        const totalCurrent = currentShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
        console.log("DEBUG: totalCurrent (current shareholders) =", totalCurrent);

        // 6. Reverse-dilute the previous rounds to compute the share count before any dilution.
        // That is: starting with currentShareholders, go backward over previous rounds.
        let initialPreDilutionCount = totalCurrent;
        for (let i = previousRounds.length - 1; i >= 0; i--) {
            const round = previousRounds[i];
            // Only consider rounds with preMoneyValuation > 0 (skip rounds like initial-capital).
            if (!round.isExitStrategy && round.preMoneyValuation > 0) {
                const preVal = round.preMoneyValuation;
                const inv = round.investmentAmount || 0;
                // Reverse the dilution: shareCountBefore = shareCountAfter / (1 + inv/preVal)
                initialPreDilutionCount = initialPreDilutionCount / (1 + (inv / preVal));
                console.log(`DEBUG: Reverse round ${i}: preVal=${preVal}, inv=${inv}, initialPreDilutionCount=${initialPreDilutionCount}`);
            }
        }
        console.log("DEBUG: initialPreDilutionCount (pre-dilution) =", initialPreDilutionCount);

        // 7. Now simulate forward through previous rounds to get share count at target.
        let forwardShareCount = initialPreDilutionCount;
        for (let i = 0; i < previousRounds.length; i++) {
            const round = previousRounds[i];
            if (!round.isExitStrategy && round.preMoneyValuation > 0) {
                const preVal = round.preMoneyValuation;
                const inv = round.investmentAmount || 0;
                const newShares = Math.floor((inv * forwardShareCount) / preVal);
                console.log(`DEBUG: Forward round ${i}: preVal=${preVal}, inv=${inv}, newShares=${newShares}, shareCount before=${forwardShareCount}`);
                forwardShareCount += newShares;
                console.log(`DEBUG: Forward round ${i}: shareCount after=${forwardShareCount}`);
            }
        }
        const R_target = forwardShareCount;
        console.log("DEBUG: R_target (share count at target) =", R_target);

        // 8. Backward-simulate through previous rounds to compute pre-round share prices.
        let currentShareCount = R_target;
        const backwardRecords = [];
        for (let i = previousRounds.length - 1; i >= 0; i--) {
            const round = previousRounds[i];
            if (round.isExitStrategy || round.preMoneyValuation === 0) continue;
            const preVal = round.preMoneyValuation;
            const inv = round.investmentAmount || 0;
            const shareCountBefore = currentShareCount / (1 + (inv / preVal));
            const sharePrice = preVal / (shareCountBefore || 1);
            const roundDate = new Date(round.time.year, round.time.month - 1);
            backwardRecords.unshift({ roundDate, shareCountBefore, sharePrice });
            console.log(`DEBUG: Backward round ${i}: roundDate=${roundDate}, shareCountBefore=${shareCountBefore}, sharePrice=${sharePrice}`);
            currentShareCount = shareCountBefore;
        }
        const earliestSharePrice = backwardRecords.length ? backwardRecords[0].sharePrice : 1;
        console.log("DEBUG: backwardRecords=", backwardRecords);
        console.log("DEBUG: earliestSharePrice=", earliestSharePrice);

        // 9. Build a monthly timeline from vesting start to exit.
        const vestingStart = new Date(person.vestingStart);
        const exitDate = new Date(exitStrategy.targetExitYear, 11);
        let currentDate = new Date(vestingStart);
        const monthArray = [];
        while (currentDate <= exitDate) {
            monthArray.push({ year: currentDate.getFullYear(), month: currentDate.getMonth() + 1 });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        const labels = monthArray.map(m =>
            `${m.month < 10 ? `0${m.month}` : m.month}/${String(m.year).slice(-2)}`
        );
        console.log("DEBUG: monthArray length=", monthArray.length);
        console.log("DEBUG: labels=", labels);

        // 10. Set up forward simulation for months on/after target.
        let forwardRunningShareCount = R_target;
        const targetRoundObj = sortedRounds[targetRoundIndex];
        let forwardValuation = targetRoundObj.preMoneyValuation + targetRoundObj.investmentAmount;
        const timelineMap = {};
        monthArray.forEach((m, idx) => {
            timelineMap[`${m.year}-${m.month}`] = idx;
        });
        const forwardRoundIndices = futureRounds.map(round => ({
            round,
            index: timelineMap[`${round.time.year}-${round.time.month}`] ?? -1
        })).filter(r => r.index !== -1).sort((a, b) => a.index - b.index);
        console.log("DEBUG: forwardRoundIndices=", forwardRoundIndices);

        // 11. For each month, decide which simulation to use.
        const data = [];
        for (let i = 0; i < monthArray.length; i++) {
            const m = monthArray[i];
            const monthDate = new Date(m.year, m.month - 1);
            let applicablePrice;
            if (monthDate < targetRoundDate) {
                // Use backward simulation.
                applicablePrice = earliestSharePrice;
                for (let rec of backwardRecords) {
                    if (monthDate >= rec.roundDate) {
                        applicablePrice = rec.sharePrice;
                    } else {
                        break;
                    }
                }
                console.log(`DEBUG: Month ${monthDate.toLocaleDateString()} uses backward price: ${applicablePrice}`);
            } else {
                // Use forward simulation.
                for (let fr of forwardRoundIndices) {
                    const roundDate = new Date(fr.round.time.year, fr.round.time.month - 1);
                    if (monthDate.getTime() === roundDate.getTime()) {
                        forwardValuation = fr.round.preMoneyValuation + fr.round.investmentAmount;
                        if (!fr.round.isInitialCapital && fr.round.preMoneyValuation > 0) {
                            const newShares = Math.floor((fr.round.investmentAmount * forwardRunningShareCount) / fr.round.preMoneyValuation);
                            forwardRunningShareCount += newShares;
                            console.log(`DEBUG: Forward round at ${monthDate.toLocaleDateString()}: newShares=${newShares}, forwardRunningShareCount=${forwardRunningShareCount}`);
                        }
                    }
                }
                applicablePrice = forwardValuation / forwardRunningShareCount;
                console.log(`DEBUG: Month ${monthDate.toLocaleDateString()} uses forward price: ${applicablePrice}`);
            }
            const vestedShares = calculateVestedShares(person, monthDate);
            data.push({
                date: monthDate.toLocaleDateString('en-GB', { month: '2-digit', year: '2-digit' }),
                value: Math.round(vestedShares * applicablePrice),
            });
        }
        console.log("DEBUG: Final generated data=", data);
        return data;
    };

    const handleAddPerson = async () => {
        if (newPerson.shareAmount > poolStats.remainingShares) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Not enough shares remaining in the pool')
            });
            return;
        }

        const updatedPeople = [...allocatedPeople, {
            ...newPerson
        }];

        try {
            await handleSubmit({
                responses: {
                    vesopManagement: {
                        allocatedPeople: updatedPeople
                    }
                }
            });

            setAllocatedPeople(updatedPeople);
            setSelectedPerson(newPerson.name);

            setNewPerson({
                name: '',
                role: '',
                vestingStart: '',
                shareAmount: 0
            });

            setPoolStats(prev => ({
                ...prev,
                allocatedShares: prev.allocatedShares + Number(newPerson.shareAmount),
                remainingShares: prev.remainingShares - Number(newPerson.shareAmount)
            }));

            setNotification({
                show: true,
                type: 'success',
                message: t('Person added successfully')
            });
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to add person')
            });
        }
    };

    const handleDeletePerson = async (personIndex) => {
        const person = allocatedPeople[personIndex];
        const updatedPeople = allocatedPeople.filter((_, index) => index !== personIndex);

        try {
            await handleSubmit({
                responses: {
                    vesopManagement: {
                        allocatedPeople: updatedPeople
                    }
                }
            });

            setAllocatedPeople(updatedPeople);

            setPoolStats(prev => ({
                ...prev,
                allocatedShares: prev.allocatedShares - person.shareAmount,
                remainingShares: prev.remainingShares + person.shareAmount
            }));

            if (person.name === selectedPerson) {
                setSelectedPerson(updatedPeople[0]?.name || '');
            }

            setNotification({
                show: true,
                type: 'success',
                message: t('Person deleted successfully')
            });
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to delete person')
            });
        }
    };

    const calculateVestingEndDate = (startDate) => {
        const vestingData = userData?.entity?.know_yourself_response?.published?.vesop ||
            userData?.entity?.know_yourself_response?.draft?.vesop;
        const duration = vestingData?.vestingSchedule?.totalDuration || 48;

        const start = new Date(startDate);
        const endDate = new Date(start);
        endDate.setDate(1); // Set to first of month to avoid date overflow
        endDate.setMonth(endDate.getMonth() + duration);
        endDate.setDate(start.getDate()); // Restore original day

        return endDate;
    };

    const selectedPersonData = allocatedPeople.find(p => p.name === selectedPerson);

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification(prev => ({ ...prev, show }))}
                type={notification.type}
                message={notification.message}
            />

            <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4">
                    {(() => {
                        const { published, draft } = userData?.entity?.know_yourself_response || {};
                        const vesopData = draft?.vesop || published?.vesop || {};
                        return `${vesopData.poolName || t('VESOP Pool')} (${vesopData.poolPercentage || 0}% ${t('of Company Shares')})`;
                    })()}
                </h2>
                <div className="grid grid-cols-3 gap-4">
                    <div className="text-center">
                        <p className="text-sm text-gray-500">{t('Total VESOP-Shares')}</p>
                        <p className="text-2xl font-semibold">{poolStats.totalShares}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">{t('Allocated Shares')}</p>
                        <p className="text-2xl font-semibold">{poolStats.allocatedShares}</p>
                        <p className="text-sm text-gray-500">
                            {poolStats.totalShares > 0
                                ? ((poolStats.allocatedShares / poolStats.totalShares) * 100).toFixed(1)
                                : '0.0'}%
                        </p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">{t('Remaining Shares')}</p>
                        <p className="text-2xl font-semibold">{poolStats.remainingShares}</p>
                        <p className="text-sm text-gray-500">
                            {poolStats.totalShares > 0
                                ? ((poolStats.remainingShares / poolStats.totalShares) * 100).toFixed(1)
                                : '0.0'}%
                        </p>
                    </div>
                </div>
                <div className="text-xs text-gray-500 mt-4 italic text-center">
                    <p className="text-xs text-gray-500 italic text-center">{t('Please be aware: Share amounts are based on the projected situation after the planned target round.')}</p>
                    <p className="text-xs text-gray-500 italic text-center">{t('VESOP Pool share totals are calculated with the shares that are created once the target round is closed.')}</p>
                    <Link
                        to="/funding/vesop"
                        className="inline-flex items-center gap-2 text-gray-600 hover:text-secondary-color transition-all duration-300 group animate-[customPulse_5s_ease-in-out_infinite]"
                    >
                        <span className="">{t('Change Configuration of VESOP-Pool')}</span>
                        <ArrowRightCircleIcon className="w-5 h-5 transition-transform duration-300 group-hover:scale-110 animate-[customPulse_5s_ease-in-out_infinite]" />
                    </Link>
                </div>
            </div>

            <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
                <h3 className="text-lg font-semibold mb-4">{t('Add New Person')}</h3>
                <div className="flex gap-4 items-start">
                    <div className="grid grid-cols-4 gap-4 flex-grow">
                        <div>
                            <label className="block text-sm font-medium text-gray-600 mb-1">{t('Name')}</label>
                            <input
                                type="text"
                                placeholder={t('John Doe')}
                                value={newPerson.name}
                                onChange={(e) => setNewPerson(prev => ({ ...prev, name: e.target.value }))}
                                className="w-full rounded-md border-gray-300"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600 mb-1">{t('Role')}</label>
                            <input
                                type="text"
                                placeholder={t('CEO')}
                                value={newPerson.role}
                                onChange={(e) => setNewPerson(prev => ({ ...prev, role: e.target.value }))}
                                className="w-full rounded-md border-gray-300"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600 mb-1">{t('Vesting Start')}</label>
                            <input
                                type="date"
                                value={newPerson.vestingStart}
                                onChange={(e) => setNewPerson(prev => ({ ...prev, vestingStart: e.target.value }))}
                                className="w-full rounded-md border-gray-300"
                                placeholder={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-600 mb-1">{t('Share Amount')}</label>
                            <input
                                type="number"
                                placeholder="0"
                                value={newPerson.shareAmount}
                                onChange={(e) => setNewPerson(prev => ({ ...prev, shareAmount: e.target.value }))}
                                min="0"
                                className="w-full rounded-md border-gray-300"
                            />
                        </div>
                    </div>
                    <button
                        onClick={handleAddPerson}
                        disabled={!newPerson.name || !newPerson.role || !newPerson.vestingStart || !newPerson.shareAmount || Number(newPerson.shareAmount) <= 0}
                        className={`p-2 rounded-md mt-6 ${!newPerson.name || !newPerson.role || !newPerson.vestingStart || !newPerson.shareAmount || Number(newPerson.shareAmount) <= 0
                            ? 'bg-gray-200 cursor-not-allowed'
                            : 'bg-secondary-color hover:bg-secondary-color/80'}`}
                    >
                        <CheckCircleIcon className="h-6 w-6 text-white" />
                    </button>
                </div>

                {/* Potential VESOP Recipients Section */}
                {userData?.entity?.know_yourself_response && (
                    <div className="mt-6 border-t border-gray-200 pt-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">{t('Potential recipients of VESOP:')}</h4>
                        {(() => {
                            const { published, draft } = userData.entity.know_yourself_response;
                            const teamData = draft || published;
                            const keyTeamMembers = teamData?.keyTeamMembers || [];
                            const currentVesopRecipients = allocatedPeople.map(p => p.name.toLowerCase());

                            const potentialRecipients = keyTeamMembers.filter(member => {
                                const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
                                return !currentVesopRecipients.includes(fullName);
                            });

                            if (potentialRecipients.length === 0) {
                                return <p className="text-sm text-gray-500">{t('All key team members already have VESOP allocations')}</p>;
                            }

                            return (
                                <div className="space-y-2">
                                    {potentialRecipients.map((member, index) => (
                                        <div
                                            key={index}
                                            className="text-sm text-gray-600 p-2 bg-[#2A9D8F]/10 rounded-md cursor-pointer hover:bg-[#2A9D8F]/20"
                                            onClick={() => {
                                                setNewPerson(prev => ({
                                                    ...prev,
                                                    name: `${member.firstName} ${member.lastName}`,
                                                    role: member.role
                                                }));
                                            }}
                                        >
                                            {member.firstName} {member.lastName} ({member.role})
                                        </div>
                                    ))}
                                </div>
                            );
                        })()}
                    </div>
                )}
            </div>

            <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
                <h3 className="text-lg font-semibold mb-4">{t('Allocated People')}</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Name')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Role')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Vesting Start')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Vesting End')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Share Amount')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {t('Shares Vested So Far')}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {allocatedPeople.map((person, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{person.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.role}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {new Date(person.vestingStart).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {calculateVestingEndDate(person.vestingStart).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.shareAmount}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {calculateVestedShares(person, new Date())}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <button
                                            onClick={() => handleDeletePerson(index)}
                                            className="text-red-600 hover:text-red-900"
                                            title={t('Delete Person')}
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="border-t border-gray-300 mb-8 mt-10"></div>

            {allocatedPeople.length > 0 && (
                <>
                    <div className="mb-6">
                        <label className="block text-sm font-semibold text-xl text-gray-700 mb-2">
                            {t('Simulate Vesting for Selected Person:')}
                        </label>
                        <select
                            value={selectedPerson}
                            onChange={(e) => setSelectedPerson(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-color focus:border-primary-color sm:text-sm rounded-md"
                        >
                            {allocatedPeople.map((person, index) => (
                                <option key={index} value={person.name}>
                                    {person.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedPersonData && (
                        <>
                            <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
                                <h3 className="text-lg font-semibold mb-4">{t('Vesting Progress')}</h3>
                                <div className="h-[350px]">
                                    <Chart
                                        options={{
                                            chart: {
                                                type: 'bar',
                                                toolbar: { show: false },
                                                animations: {
                                                    enabled: true,
                                                    easing: 'easeinout',
                                                    speed: 800,
                                                }
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '100%',
                                                    distributed: true
                                                }
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            annotations: {
                                                xaxis: [{
                                                    x: generateVestingChartData(selectedPersonData)[0]?.date,
                                                    x2: generateVestingChartData(selectedPersonData)[
                                                        userData?.entity?.know_yourself_response?.published?.vesop?.vestingCliff?.months ||
                                                        userData?.entity?.know_yourself_response?.draft?.vesop?.vestingCliff?.months || 12
                                                    ]?.date,
                                                    fillColor: '#DDC958',
                                                    opacity: 0.2,
                                                    label: {
                                                        text: 'Cliff Period',
                                                        style: {
                                                            fontSize: '12px',
                                                            color: '#2A9D8F'
                                                        }
                                                    }
                                                }]
                                            },
                                            xaxis: {
                                                categories: generateVestingChartData(selectedPersonData).map(d => d.date),
                                                title: { text: 'Month' },
                                                labels: { rotate: 315, hideOverlappingLabels: false }
                                            },
                                            yaxis: {
                                                title: { text: t('Shares') },
                                                labels: {
                                                    formatter: (val) => Math.round(val)
                                                }
                                            },
                                            legend: { show: false },
                                            tooltip: {
                                                y: {
                                                    formatter: (val) => Math.round(val)
                                                },
                                                x: {
                                                    formatter: (val) => val
                                                }
                                            },
                                            colors: generateVestingChartData(selectedPersonData).map((d, index, array) => {
                                                const today = new Date().toLocaleDateString('en-GB', { month: '2-digit', year: '2-digit' });
                                                const isLastBar = index === array.length - 1;
                                                const todayDate = new Date();
                                                const currentDate = new Date('20' + d.date.split('/')[1], parseInt(d.date.split('/')[0]) - 1);

                                                // If it's the last bar and today is past the vesting end date, OR if it's today's bar
                                                if ((isLastBar && todayDate > currentDate) || d.date === today) {
                                                    return '#81D573';
                                                }
                                                return '#2A9D8F';
                                            })
                                        }}
                                        series={[{
                                            name: t('Vested Shares'),
                                            data: generateVestingChartData(selectedPersonData).map(d => d.shares)
                                        }]}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>

                            <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
                                <h3 className="text-lg font-semibold mb-4">{t('Value of Vested Shares')}</h3>
                                <div className="h-96">
                                    <Chart
                                        options={{
                                            chart: {
                                                type: 'area',
                                                animations: { enabled: true },
                                                toolbar: { show: true }
                                            },
                                            xaxis: {
                                                categories: generateValueChartData(selectedPersonData).map(d => d.date),
                                                tickAmount: Math.max(Math.floor(generateValueChartData(selectedPersonData).length / 5), 1),
                                                labels: { rotate: -45 }
                                            },
                                            dataLabels: { enabled: false },
                                            yaxis: {
                                                min: 0,
                                                labels: {
                                                    formatter: (val) => new Intl.NumberFormat('de-DE', {
                                                        style: 'currency',
                                                        currency: userData?.entity?.know_yourself_response?.published?.fundingCurrency || 'EUR',
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    }).format(val)
                                                },
                                                title: { text: userData?.entity?.know_yourself_response?.published?.fundingCurrency || 'EUR' },
                                                max: (maxValue) => Math.ceil(maxValue * 1.1) // Add 10% padding
                                            },
                                            tooltip: {
                                                shared: true,
                                                intersect: false,
                                                y: {
                                                    formatter: (val) => {
                                                        const formattedValue = new Intl.NumberFormat('de-DE', {
                                                            style: 'currency',
                                                            currency: userData?.entity?.know_yourself_response?.published?.fundingCurrency || 'EUR',
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }).format(val);
                                                        return `${formattedValue}`;
                                                    }
                                                }
                                            },
                                            stroke: {
                                                curve: 'straight',
                                                width: 3
                                            },
                                            fill: {
                                                type: 'gradient',
                                                gradient: {
                                                    shadeIntensity: 0.4,
                                                    opacityFrom: 0.7,
                                                    opacityTo: 0.3,
                                                    stops: [30, 100]
                                                }
                                            },
                                            legend: {
                                                position: 'bottom',
                                                horizontalAlign: 'center'
                                            }
                                        }}
                                        series={[{
                                            name: t('Projected Value of Vested Shares'),
                                            data: generateValueChartData(selectedPersonData).map(d => d.value)
                                        }]}
                                        type="area"
                                        height="100%"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default VESOPManagement;