// src/components/FlowChart/nodes/InputOutputNode.js
import React, { useState, useEffect, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { DocumentIcon, DocumentPlusIcon } from '@heroicons/react/24/solid';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import axios from 'axios';

const InputOutputNode = ({ data, selected, isConnectable }) => {
  const [asset, setAsset] = useState(data.asset || null);
  const [assetTypes, setAssetTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const isInput = data.isInput || false;
  
  // Fetch asset types on component mount
  useEffect(() => {
    fetchAssetTypes();
  }, []);

  // Update node data when selections change
  useEffect(() => {
    if (data.updateNodeData) {
      data.updateNodeData({
        asset,
        isInput
      });
    }
  }, [asset, isInput, data]);

  // Fetch all asset types
  const fetchAssetTypes = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/v1/static_data/asset_types');
      
      if (response.data && response.data.length > 0) {
        console.log("Asset types loaded:", response.data.length);
        setAssetTypes(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching asset types:', err);
      setLoading(false);
    }
  };

  // Debounced search for potentially large lists
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      // If the query is empty, don't perform search
      if (!searchQuery.trim()) return;
    }, 300),
    [assetTypes]
  );

  // Handle query changes and trigger search
  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    debouncedSearch(newQuery);
  };

  // Filter the asset types list based on the query
  const filteredAssetTypes = query === ''
    ? assetTypes
    : assetTypes.filter((assetType) => {
        return assetType.asset_type.toLowerCase().includes(query.toLowerCase());
      });

  // Determine node color based on input or output
  const bgColor = isInput ? 'bg-green-50' : 'bg-indigo-50';
  const borderColor = isInput 
    ? (selected ? 'border-green-500 ring-2 ring-green-200' : 'border-green-300')
    : (selected ? 'border-indigo-500 ring-2 ring-indigo-200' : 'border-indigo-300');
  const textColor = isInput ? 'text-green-900' : 'text-indigo-900';
  const iconColor = isInput ? 'text-green-700' : 'text-indigo-700';
  const handleColor = isInput ? '!bg-green-500 !border-green-700' : '!bg-indigo-500 !border-indigo-700';
  const highlightBg = isInput ? 'bg-green-100' : 'bg-indigo-100';
  const highlightText = isInput ? 'text-green-800' : 'text-indigo-800';
  const inputLabel = isInput ? 'Input Data' : 'Output Data';
  const placeholder = isInput ? 'Select an input asset type' : 'Select an output asset type';
  const Icon = isInput ? DocumentIcon : DocumentPlusIcon;
  
  const focusRing = isInput 
    ? 'focus:border-green-500 focus:ring-green-500' 
    : 'focus:border-indigo-500 focus:ring-indigo-500';
  
  const comboActiveClass = isInput 
    ? 'bg-green-600 text-white' 
    : 'bg-indigo-600 text-white';
  
  const comboSelectedClass = isInput 
    ? 'text-green-600' 
    : 'text-indigo-600';

  return (
    <div 
      className={`${bgColor} border rounded-md p-3 w-64 shadow-sm transition-all ${borderColor}`}
    >
      <Handle 
        type="target" 
        position={Position.Top} 
        className={handleColor}
        isConnectable={isConnectable}
      />
      
      <div className="flex items-center mb-2">
        <Icon className={`h-4 w-4 ${iconColor} mr-1 flex-shrink-0`} />
        <div className={`font-medium text-sm ${textColor} truncate`}>
          {data.label || inputLabel}
        </div>
      </div>
      
      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Asset Type
        </label>
        <Combobox
          as="div"
          value={asset}
          onChange={(selectedAsset) => {
            setAsset(selectedAsset);
            setQuery('');
            setIsOpen(false); // Close dropdown after selection
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <div className="relative">
            <ComboboxInput
              className={`block w-full rounded-md border-gray-300 shadow-sm 
                         ${focusRing} text-xs py-1 px-2`}
              onChange={(event) => handleQueryChange(event.target.value)}
              displayValue={(assetType) => assetType?.asset_type || ''}
              placeholder={placeholder}
              onFocus={() => setIsOpen(true)}
            />
            
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center px-2">
              <ChevronUpDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
            
            {filteredAssetTypes.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 
                                         text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                {filteredAssetTypes.map((assetType) => (
                  <ComboboxOption
                    key={assetType.id}
                    value={assetType}
                    onClick={(e) => {
                      e.stopPropagation(); // Stop event propagation
                      setAsset(assetType);
                      setQuery('');
                      setIsOpen(false); // Ensure dropdown closes
                    }}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                        active ? comboActiveClass : 'text-gray-900'
                      }`
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span className={`truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {assetType.asset_type}
                        </span>
                        {selected && (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                              active ? 'text-white' : comboSelectedClass
                            }`}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
      </div>

      {/* Display selected asset info */}
      {asset && (
        <div className={`mt-3 text-xs ${highlightText} ${highlightBg} p-2 rounded`}>
          <p>
            <span className="font-semibold">Type:</span> {asset.asset_type}
          </p>
          {asset.description && (
            <p className="mt-1">
              <span className="font-semibold">Description:</span> {asset.description}
            </p>
          )}
        </div>
      )}
      
      <Handle 
        type="source" 
        position={Position.Bottom} 
        className={handleColor}
        isConnectable={isConnectable}
      />
      
      <Handle 
        type="source" 
        position={Position.Right} 
        className={handleColor}
        isConnectable={isConnectable}
      />
      
      <Handle 
        type="target" 
        position={Position.Left} 
        className={handleColor}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default React.memo(InputOutputNode);