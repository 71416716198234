import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import { useTranslation } from 'react-i18next';
import {
  DocumentChartBarIcon,
  BellAlertIcon,
  ShieldCheckIcon,
  LockClosedIcon,
  ChevronRightIcon,
  ClipboardIcon,
  ClipboardDocumentCheckIcon,
  EyeIcon,
  EyeSlashIcon,
  ClipboardDocumentListIcon,
  UserIcon,
  BuildingOfficeIcon,
  EllipsisHorizontalIcon,
  CurrencyDollarIcon,
  HeartIcon,
  RocketLaunchIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';
import { Popover } from 'flowbite-react';
import { getTranslatedValue } from '../utils/templateTranslations';
import { useContext } from 'react';
import { AlertsContext } from '../context/AlertsContext';

const CircularProgress = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalPercentage = publishedPercentage + draftPercentage;

  return (
    <div className="relative w-16 h-16">
      <svg className="w-full h-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200" strokeWidth="3"></circle>
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-yellow-400"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - totalPercentage}
          strokeLinecap="round">
        </circle>
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-green-500"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - publishedPercentage}
          strokeLinecap="round">
        </circle>
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <span className="text-center text-sm font-bold text-green-500">{Math.round(publishedPercentage)}%</span>
      </div>
    </div>
  );
};

const CompanyDashboard = () => {
  const [companyData, setCompanyData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [imageError, setImageError] = useState(false);
  const { t, i18n } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [pendingCollections, setPendingCollections] = useState([]);
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const userLang = userData?.language?.slice(0, 2) || 'en';
  const { alerts } = useContext(AlertsContext);

  useEffect(() => {
    fetchCompanyData();
    fetchAllActivities();
    fetchDataCollections();
  }, []);

  // Add this function to get the oldest active alert
  const getOldestActiveAlert = () => {
    if (!alerts.active || alerts.active.length === 0) return null;

    // Sort by alert_time (ascending) to get the oldest
    return [...alerts.active].sort((a, b) =>
      new Date(a.alert_time) - new Date(b.alert_time)
    )[0];
  };

  // Use this variable to access the oldest alert
  const oldestActiveAlert = getOldestActiveAlert();

  const fetchCompanyData = async () => {
    try {
      const userDataResponse = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setCompanyData(userDataResponse.data.entity);
      setUserData(userDataResponse.data); // Store user data
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

// Update your useEffect at the top of the component
useEffect(() => {
  fetchCompanyData();
  fetchAllActivities();
  fetchDataCollections();
}, []);

// New function to fetch all activities in the correct order
const fetchAllActivities = async () => {
  try {
    const tasks = await fetchTaskData();
    const wizardActivities = await fetchWizardActivitiesData();
    
    // Combine both types of activities
    const combinedActivities = [...tasks, ...wizardActivities]
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 20);
    
    console.log('Final combined activities:', combinedActivities);
    setActivities(combinedActivities);
  } catch (error) {
    console.error('Error fetching all activities:', error);
  }
};

// Modified to fetch task data without setting activities state
const fetchTaskData = async () => {
  try {
    const [tasksResponse, usersResponse] = await Promise.all([
      axios.get('/api/v1/tasks', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      }),
      axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      })
    ]);

    const tasks = tasksResponse.data;
    const users = usersResponse.data.entity.users;
    
    // Save tasks for other parts of the UI
    setTasks(tasks);

    // Create a map of user IDs to names
    const userMap = users.reduce((map, user) => {
      map[user.id] = `${user.first_name} ${user.last_name}`;
      return map;
    }, {});

    // Map status numbers to string representations
    const statusMap = {
      0: 'backlog',
      1: 'current',
      2: 'approval',
      3: 'done'
    };

    const taskActivities = tasks.flatMap(task => [
      {
        type: 'creation',
        task: task,
        date: new Date(task.created_at),
        user: userMap[task.created_by_id] || 'Unknown'
      },
      {
        type: 'assignment',
        task: task,
        date: new Date(task.assignment_date),
        user: userMap[task.assigned_by_id] || 'Unknown',
        assignedTo: userMap[task.assigned_to_id] || 'No one',
        approvalBy: userMap[task.approved_by_id] || 'No one'
      },
      ...(task.status_changes_history || []).map(change => ({
        type: 'status_change',
        task: task,
        date: new Date(change.changed_at),
        user: userMap[change.changed_by_id] || 'Unknown',
        fromStatus: change.from_status,
        toStatus: change.to_status
      }))
    ]);

    console.log('Task activities:', taskActivities);
    
    // Return activities instead of setting state
    return taskActivities;
  } catch (error) {
    console.error('Error fetching task data:', error);
    return []; // Return empty array on error
  }
};

// Modified to fetch wizard activities without setting activities state
const fetchWizardActivitiesData = async () => {
  try {
    console.log('Fetching wizard activities...');
    const response = await axios.get('/api/v1/wizards/startup/activities', {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
    });
    
    console.log('Wizard activities received:', response.data);
    
    if (!response.data || response.data.length === 0) {
      console.log('No wizard activities found');
      return [];
    }
    
    const wizardActivities = response.data.map(activity => ({
      type: 'wizard_activity',
      activity_type: activity.activity_type,
      wizard_name: activity.wizard_name,
      section: activity.section,
      date: new Date(activity.created_at),
      user: activity.user_name,
      is_auto_save: activity.is_auto_save
    }));
    
    console.log('Formatted wizard activities:', wizardActivities);
    
    // Return activities instead of setting state
    return wizardActivities;
  } catch (error) {
    console.error('Error fetching wizard activities:', error);
    return []; // Return empty array on error
  }
};

// Keep this function for backward compatibility, but it should no longer be called directly
const fetchTasks = async () => {
  const taskActivities = await fetchTaskData();
  // This maintains backward compatibility if this function is called elsewhere
  setActivities(taskActivities.slice(0, 10));
};

// Keep this function for backward compatibility, but it should no longer be called directly
const fetchWizardActivities = async () => {
  const wizardActivities = await fetchWizardActivitiesData();
  
  // Get current activities and combine them - this maintains backward compatibility
  setActivities(prevActivities => {
    const combined = [...prevActivities, ...wizardActivities]
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 20);
    return combined;
  });
};

  const fetchDataCollections = async () => {
    try {
      // Only proceed if we have userData
      if (!userData?.id) return;

      const response = await axios.get('/api/v1/data_collections', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      // Filter for collections where the current user needs to respond
      const pending = response.data.active_collections.filter(collection => {
        const userRespondent = collection.respondents.find(r => r.id === userData.id);
        return userRespondent && !userRespondent.responded;
      });

      setPendingCollections(pending);
      setReports(response.data.reports);
    } catch (error) {
      console.error('Error fetching data collections:', error);
    }
  };

  // Add dependency on userData to useEffect
  useEffect(() => {
    fetchDataCollections();
  }, [userData]);  // Re-fetch when userData changes

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('Good morning');
    if (hour < 18) return t('Good afternoon');
    return t('Good evening');
  };

  const TaskStatusIcon = ({ task, userData }) => {
    const isAssigned = task.assigned_to_id === userData?.id;
    const needsApproval = task.approved_by_id === userData?.id;

    let icon, text, textColor;
    if (isAssigned && needsApproval) {
      icon = (
        <>
          <ClipboardIcon className="h-5 w-5 text-blue-500 mr-1" aria-hidden="true" />
          <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
        </>
      );
      text = t('Assigned to you and needs your approval');
      textColor = 'text-blue-500';
    } else if (isAssigned) {
      icon = <ClipboardIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />;
      text = t('Assigned to you');
      textColor = 'text-blue-500';
    } else if (needsApproval) {
      icon = <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />;
      text = t('Needs your approval');
      textColor = 'text-green-500';
    }

    return (
      <Popover
        content={
          <div className="p-3 bg-white rounded-lg shadow-lg max-w-xs">
            <p className={`text-sm ${textColor} text-center whitespace-normal break-words`}>{text}</p>
          </div>
        }
        arrow={false}
        trigger="hover"
        placement="top"
      >
        <div className="flex items-center cursor-help">
          {icon}
        </div>
      </Popover>
    );
  };

  const UserAvatar = ({ user }) => {
    if (!user) return null;
    return user.avatar_url ? (
      <img
        src={user.avatar_url}
        alt={`${user.first_name} ${user.last_name}`}
        className="h-6 w-6 rounded-full mr-2  bg-secondary-color object-cover object-center"
      />
    ) : (
      <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center mr-2  bg-secondary-color object-cover object-center">
        <span className="text-xs font-medium text-gray-700">
          {user.first_name[0]}{user.last_name[0]}
        </span>
      </div>
    );
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));

    if (days > 7) {
      return new Date(date).toLocaleDateString(i18n.language, { day: '2-digit', month: '2-digit', year: '2-digit' });
    } else if (days > 0) {
      return `${days} ${days === 1 ? t('day ago') : t('days ago')}`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? t('hour ago') : t('hours ago')}`;
    } else {
      return `${minutes} ${minutes === 1 ? t('minute ago') : t('minutes ago')}`;
    }
  };

  const latestReport = reports
    ?.filter(collection =>
      collection.viewers &&
      collection.viewers.some(viewer => viewer.id === userData?.id)
    )
    .sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at))
  [0];

  const getDeadlineStyles = (deadline) => {
    if (!deadline) return '';

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const deadlineDate = new Date(deadline);
    deadlineDate.setHours(0, 0, 0, 0);

    // Calculate difference in days
    const diffDays = Math.floor((deadlineDate - today) / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return 'text-red-600 font-medium'; // Past deadline
    } else if (diffDays <= 1) {
      return 'text-amber-600 font-medium'; // Today or tomorrow
    }
    return 'text-gray-500'; // Default style
  };

  function UserInitials({ firstName, lastName }) {
    const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
    return (
      <div className="h-14 w-14 rounded-full bg-secondary-color flex items-center justify-center">
        <span className="text-white font-bold text-xl">{initials}</span>
      </div>
    );
  }

  const getAreaIcon = (area) => {
    switch (area) {
      case 'cybersecurity':
        return <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'data_privacy':
        return <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'organization':
        return <BuildingOfficeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'culture':
        return <HeartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'finance':
        return <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    }
  };

  const getWizardIcon = (wizardName) => {
    switch (wizardName) {
      case 'startup_wizard':
      case 'startup':
        return <RocketLaunchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'know_yourself':
        return <BanknotesIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'isms':
        return <ShieldCheckIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    }
  };

  const renderDataCollectionsSection = () => {
    if (!userData) return null;

    const userLang = userData.language
      ? userData.language.slice(0, 2)
      : 'en';

    return (
      <>
        <h2 className="text-lg leading-6 font-medium text-gray-900 mt-12 pb-0">
          {t('Pending Data Collections')}
        </h2>
        <div className="mt-2">
          {/* Desktop Table */}
          <div className="hidden lg:block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Area')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Collection')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Type')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Deadline')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Created by')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Action')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {pendingCollections.length > 0 ? (
                      pendingCollections.map((collection) => (
                        <tr key={collection.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {getAreaIcon(collection.area)}
                              <span className="ml-2 text-sm text-gray-900">{t(`area_${collection.area}`)}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <span className="text-sm text-gray-900 text-left">
                                {getTranslatedValue(collection.data, userLang)} ({collection.period_value})
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {collection.is_anonymous ? (
                                <EyeSlashIcon className="h-5 w-5 text-gray-400 mr-2" />
                              ) : (
                                <EyeIcon className="h-5 w-5 text-gray-400 mr-2" />
                              )}
                              <span className="text-sm text-gray-500">
                                {collection.is_anonymous ? t('Anonymous') : t('Named')}
                              </span>
                            </div>
                          </td>
                          <td className={`px-6 py-4 whitespace-nowrap text-sm ${getDeadlineStyles(collection.ends_at)}`}>
                            {collection.ends_at ? new Date(collection.ends_at).toLocaleDateString(userLang) : t('No deadline')}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <span className="text-sm text-gray-900">{collection.created_by_name}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-normal break-words">
                            <button
                              onClick={() => navigate(`/reports/respond/${collection.access_token}`)}
                              className="text-secondary-color hover:text-secondary-color/80 text-sm font-medium"
                            >
                              {t('Provide Data')}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="px-6 py-4 text-sm text-gray-500 text-center">
                          {t('No pending data collections')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Mobile Card Layout */}
          <div className="lg:hidden">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {pendingCollections.length > 0 ? (
                pendingCollections.map((collection) => (
                  <div key={collection.id} className="bg-white p-4 border-b border-gray-200 last:border-b-0">
                    <div className="space-y-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <ClipboardDocumentListIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <span className="text-sm font-medium text-gray-900">
                            {getTranslatedValue(collection.data, userLang)} ({collection.period_value})
                          </span>
                        </div>
                        {collection.is_anonymous ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-400" />
                        )}
                      </div>
                      {collection.description && (
                        <p className="text-sm text-gray-500">
                          {getTranslatedValue(collection.description, userLang)}
                        </p>
                      )}
                      <div className="flex items-center">
                        <span className="text-sm text-gray-500">
                          {t('Created by')} {collection.created_by_name}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className={`text-sm ${getDeadlineStyles(collection.ends_at)}`}>
                          {collection.ends_at ? new Date(collection.ends_at).toLocaleDateString(userLang) : t('No deadline')}
                        </span>
                        <button
                          onClick={() => navigate(`/reports/respond/${collection.access_token}`)}
                          className="text-secondary-color hover:text-secondary-color text-sm font-medium"
                        >
                          {t('Provide Data')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 text-sm text-gray-500 text-center">
                  {t('No pending data collections')}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {/* User info section - Not fixed on mobile, no left padding */}
      <div className="bg-white shadow md:fixed md:top-11 mt-11 md:mt-0 right-0 left-0 md:left-[256px] z-30 border-b border-gray-200">
        <div className="mx-0 md:mx-auto w-full max-w-screen-xl px-0 md:px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center py-6">
            <div className="flex items-center px-4 md:px-0">
              {userData?.avatar_url && !imageError ? (
                <img
                  className="h-14 w-14 rounded-full bg-secondary-color object-cover object-center"
                  src={userData.avatar_url}
                  alt={`${userData.first_name} ${userData.last_name}`}
                  onError={() => setImageError(true)}
                />
              ) : (
                <UserInitials firstName={userData?.first_name} lastName={userData?.last_name} />
              )}
              <div className="ml-4 flex flex-col">
                <h1 className="text-2xl font-bold text-gray-900 leading-tight">
                  {getGreeting()}, {userData?.first_name}
                </h1>
                <p className="text-sm text-gray-500 mt-1">{companyData?.name}</p>
              </div>
            </div>
            {/* SHARE COMPANY DATA (TODO)
            <div className="mt-4 md:mt-0 px-4 md:px-0">
              <button
                type="button"
                disabled
                className="w-full md:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 opacity-50 cursor-not-allowed"
              >
                <img src={`${process.env.PUBLIC_URL}/ux_icons/link.svg`} alt="icon" className="h-4 w-4 mr-3 fill-current text-white" />
                {t('Share Company data (coming soon)')}
              </button>
            </div>
            */}
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 md:pt-40">
        {/* Overview Cards */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 pb-2">{t('Overview')}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
          {/* Latest Report Card */}
          <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
            <div className="p-5 flex-grow">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-2">
                  <DocumentChartBarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">{t('Latest Report')}</dt>
                    <dd>
                      {latestReport ? (
                        <button
                          onClick={() => navigate(`/reports/report/${latestReport.access_token}`)}
                          className="text-lg font-medium text-gray-900 hover:text-primary-color whitespace-normal break-all text-left"
                        >
                          {/* Add userLang and use getTranslatedValue */}
                          {getTranslatedValue(latestReport.data, userLang)} ({latestReport.period_value})
                        </button>
                      ) : (
                        <div className="text-lg font-medium text-gray-900">{t('Not available yet')}</div>
                      )}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <button
                  onClick={() => navigate('/reports')}
                  className="font-medium text-cyan-700 hover:text-cyan-900"
                >
                  {t('View all')}
                </button>
              </div>
            </div>
          </div>

          {/* Most Pressing Alert Card */}
          <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
            <div className="p-5 flex-grow">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-2">
                  <BellAlertIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">{t('Most Pressing Alert')}</dt>
                    <dd>
                      {oldestActiveAlert ? (
                        <button
                          onClick={() => navigate('/alerts')}
                          className="text-lg font-medium text-gray-900 hover:text-primary-color whitespace-normal break-all text-left"
                        >
                          {oldestActiveAlert.alert_type === 'repeating_data_collection'
                            ? `${t('data_collection_alert')} ${getTranslatedValue(oldestActiveAlert.repetition_data?.template_name, userLang)} ${oldestActiveAlert.repetition_data?.period_value ? ' - ' + oldestActiveAlert.repetition_data.period_value : ''}`
                            : getTranslatedValue(oldestActiveAlert.name, userLang)
                          }
                        </button>
                      ) : (
                        <div className="text-lg font-medium text-gray-900">{t('No active alerts')}</div>
                      )}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              <div className="text-sm">
                <button
                  onClick={() => navigate('/alerts')}
                  className="font-medium text-cyan-700 hover:text-cyan-900"
                >
                  {t('View all')}
                </button>
              </div>
            </div>
          </div>

          {/* Cybersecurity and Data Privacy Cards */}
          <div className="grid grid-cols-2 gap-5">
            {/* Cybersecurity Card */}
            <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
              <div className="p-5 flex-grow flex flex-col justify-center">
                <dl className="flex flex-col items-center">
                  <dt className="text-sm font-medium text-gray-500 mb-2">{t('Cybersecurity')}</dt>
                  <dd>
                    <CircularProgress published={0} draft={0} total={100} />
                  </dd>
                </dl>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                    {t('Not available yet')}
                  </a>
                </div>
              </div>
            </div>

            {/* Data Privacy Card */}
            <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col">
              <div className="p-5 flex-grow flex flex-col justify-center">
                <dl className="flex flex-col items-center">
                  <dt className="text-sm font-medium text-gray-500 mb-2">{t('Data Privacy')}</dt>
                  <dd>
                    <CircularProgress published={0} draft={0} total={100} />
                  </dd>
                </dl>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900">
                    {t('Not available yet')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tasks Table */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 mt-12 pb-0">{t("To Do's")}</h2>
        <div className="mt-2">
          {/* Desktop Table - Hidden on mobile */}
          <div className="hidden lg:block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Area')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Task')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Assigned by')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Assignment date')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {tasks.length > 0 ? (
                      tasks.filter(task => {
                        const isAssignedTo = task.assigned_to_id === userData?.id;
                        const isApprovalBy = task.approved_by_id === userData?.id;
                        return (isApprovalBy && task.status === 'approval') ||
                          (isAssignedTo && (task.status === 'backlog' || task.status === 'current'));
                      }).map((task) => (
                        <tr key={task.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {getAreaIcon(task.area)}
                              <span className="ml-2 text-sm text-gray-900">{t(`areas.${task.area}`)}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-wrap">
                            <div className="flex items-center">
                              <TaskStatusIcon task={task} userData={userData} />
                              <span className="ml-2 text-sm text-gray-900">{task.name}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-wrap">
                            <div className="flex items-center">
                              <UserAvatar user={task.assigned_by} />
                              <span className="text-sm text-gray-900">{task.assigned_by_name}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatTimeAgo(task.assignment_date)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-sm text-gray-500 text-center">
                          {t('No tasks available')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Mobile Card Layout - Shown only on mobile */}
          <div className="lg:hidden">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {tasks.length > 0 ? (
                tasks.filter(task => {
                  const isAssignedTo = task.assigned_to_id === userData?.id;
                  const isApprovalBy = task.approved_by_id === userData?.id;
                  return (isApprovalBy && task.status === 'approval') ||
                    (isAssignedTo && (task.status === 'backlog' || task.status === 'current'));
                }).map((task) => (
                  <div key={task.id} className="bg-white p-4 border-b border-gray-200 last:border-b-0">
                    <div className="space-y-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          {getAreaIcon(task.area)}
                          <span className="ml-2 text-sm font-medium text-gray-900">{t(`areas.${task.area}`)}</span>
                        </div>
                        <TaskStatusIcon task={task} userData={userData} />
                      </div>
                      <div className="text-sm text-gray-900 font-medium">{task.name}</div>
                      <div className="flex items-center">
                        <UserAvatar user={task.assigned_by} />
                        <span className="text-sm text-gray-500">{t('Assigned by')} {task.assigned_by_name}</span>
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatTimeAgo(task.assignment_date)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 text-sm text-gray-500 text-center">
                  {t('No tasks available')}
                </div>
              )}
            </div>
          </div>
        </div>

        {renderDataCollectionsSection()}

        {/* Recent Activity Table */}
        <h2 className="text-lg leading-6 font-medium text-gray-900 mt-12 pb-0">
          {t('Recent activity in your organisation')}
        </h2>
        <div className="mt-2">
          {/* Desktop Table - Hidden on mobile */}
          <div className="hidden lg:block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Area')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Activity')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('Timestamp')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {activities.length > 0 ? (
                      activities.map((activity, index) => (
                        <tr key={`${activity.type}-${activity.type === 'wizard_activity' ? activity.wizard_name + '-' + index : activity.task.id}-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {activity.type === 'wizard_activity'
                                ? getWizardIcon(activity.wizard_name)
                                : getAreaIcon(activity.task.area)}
                              <span className="ml-2 text-sm text-gray-900">
                                {activity.type === 'wizard_activity'
                                  ? t(activity.wizard_name)
                                  : t(`areas.${activity.task.area}`)}
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-normal">
                            <span className="text-sm text-gray-900">
                              {activity.type === 'creation'
                                ? t('taskCreationActivity', { user: activity.user, taskName: activity.task.name })
                                : activity.type === 'assignment'
                                  ? t('assignmentChangeActivity', {
                                    user: activity.user,
                                    taskName: activity.task.name,
                                    assignedTo: activity.assignedTo || t('NoOne'),
                                    approvalBy: activity.approvalBy || t('NoOne')
                                  })
                                  : activity.type === 'status_change'
                                    ? t('statusChangeActivity', {
                                      user: activity.user,
                                      taskName: activity.task.name,
                                      fromStatus: t(`status.${activity.fromStatus}`),
                                      toStatus: t(`status.${activity.toStatus}`)
                                    })
                                    : activity.type === 'wizard_activity'
                                      ? activity.is_auto_save
                                        ? t('wizardAutoSaveActivity', {
                                          user: activity.user,
                                          section: t(`StartupWizard.${activity.section}`),
                                          wizard: t(activity.wizard_name)
                                        })
                                        : activity.activity_type === 'save'
                                          ? t('wizardSaveActivity', {
                                            user: activity.user,
                                            section: t(`StartupWizard.${activity.section}`),
                                            wizard: t(activity.wizard_name)
                                          })
                                          : t('wizardDraftActivity', {
                                            user: activity.user,
                                            section: t(`StartupWizard.${activity.section}`),
                                            wizard: t(activity.wizard_name)
                                          })
                                      : t('unknownActivity')
                              }
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatTimeAgo(activity.date)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="px-6 py-4 text-sm text-gray-500 text-center" colSpan="3">
                          {t('No recent activity')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Mobile Card Layout - Shown only on mobile */}
          <div className="lg:hidden">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {activities.length > 0 ? (
                activities.map((activity, index) => (
                  <div
                    key={`${activity.type}-${activity.type === 'wizard_activity' ?
                      `wizard-${activity.wizard_name}-${index}` :
                      `task-${activity.task?.id || 'unknown'}-${index}`}`}
                    className="bg-white p-4 border-b border-gray-200 last:border-b-0"
                  >
                    <div className="space-y-3">
                      <div className="flex items-center">
                        {activity.type === 'wizard_activity'
                          ? getWizardIcon(activity.wizard_name)
                          : getAreaIcon(activity.task.area)}
                        <span className="ml-2 text-sm font-medium text-gray-900">
                          {activity.type === 'wizard_activity'
                            ? t(activity.wizard_name)
                            : t(`areas.${activity.task.area}`)}
                        </span>
                      </div>
                      <div className="text-sm text-gray-900">
                        {activity.type === 'creation'
                          ? t('taskCreationActivity', { user: activity.user, taskName: activity.task.name })
                          : activity.type === 'assignment'
                            ? t('assignmentChangeActivity', {
                              user: activity.user,
                              taskName: activity.task.name,
                              assignedTo: activity.assignedTo || t('NoOne'),
                              approvalBy: activity.approvalBy || t('NoOne')
                            })
                            : activity.type === 'status_change'
                              ? t('statusChangeActivity', {
                                user: activity.user,
                                taskName: activity.task.name,
                                fromStatus: t(`status.${activity.fromStatus}`),
                                toStatus: t(`status.${activity.toStatus}`)
                              })
                              : activity.type === 'wizard_activity'
                                ? activity.is_auto_save
                                  ? t('wizardAutoSaveActivity', {
                                    user: activity.user,
                                    section: t(`StartupWizard.${activity.section}`),
                                    wizard: t(activity.wizard_name)
                                  })
                                  : activity.activity_type === 'save'
                                    ? t('wizardSaveActivity', {
                                      user: activity.user,
                                      section: t(`StartupWizard.${activity.section}`),
                                      wizard: t(activity.wizard_name)
                                    })
                                    : t('wizardDraftActivity', {
                                      user: activity.user,
                                      section: t(`StartupWizard.${activity.section}`),
                                      wizard: t(activity.wizard_name)
                                    })
                                : t('unknownActivity')
                        }
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatTimeAgo(activity.date)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="px-6 py-4 text-sm text-gray-500 text-center">
                  {t('No recent activity')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDashboard;