import { Navigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

function AdminRoute({ children }) {
  const { user, loading } = useContext(AuthContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setIsCheckingAuth(false);
    } else if (user !== null) {
      setIsCheckingAuth(false);
    }
  }, [user]);

  if (loading || isCheckingAuth) {
    return <div>{t('Loading...')}</div>;
  }

  if (!user && user.role === 'owner' || user.role === 'admin') {
    // Save the current location for redirect after login
    localStorage.setItem('redirectAfterLogin', location.pathname + location.search);
    return <Navigate to="/login" />;
  }

  return children;
}

export default AdminRoute;
