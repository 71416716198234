import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { CalendarIcon, ClockIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import axios from '../../../axios';

const TimingStep = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(formData.deadline || null);
  const [deadlineType, setDeadlineType] = useState(selectedDate ? 'deadline' : 'no-deadline');
  const [template, setTemplate] = useState(null);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const formatRelativeDate = (date) => {
    if (!date) return '';
    const diff = Math.floor((new Date(date) - today) / (1000 * 60 * 60 * 24));
    return t('{{days}} days from now', { days: diff });
  };

  const getNextRepetitionDate = (periodType) => {
    const reminderDate = new Date();
    reminderDate.setDate(reminderDate.getDate() - 7); // Set to a week earlier

    switch (periodType) {
      case 'monthly':
        reminderDate.setMonth(reminderDate.getMonth() + 1);
        break;
      case 'quarterly':
        reminderDate.setMonth(reminderDate.getMonth() + 3);
        break;
      case 'yearly':
        reminderDate.setFullYear(reminderDate.getFullYear() + 1);
        break;
      default:
        return null;
    }
    return reminderDate.toLocaleDateString();
  };

  const getAvailableReminders = (deadline) => {
    if (!deadline) return [];

    const deadlineDate = new Date(deadline);
    const diffDays = Math.floor((deadlineDate - today) / (1000 * 60 * 60 * 24));

    return [
      { id: 'month', days: 30, label: t('1 month before'), available: diffDays >= 30, remaining: Math.max(0, diffDays - 30) },
      { id: 'week', days: 7, label: t('1 week before'), available: diffDays >= 7, remaining: Math.max(0, diffDays - 7) },
      { id: 'day', days: 1, label: t('24 hours before'), available: diffDays >= 1, remaining: Math.max(0, diffDays - 1) }
    ];
  };

  useEffect(() => {
    if (!selectedDate) {
      setFormData({ ...formData, reminders: [] });
    } else {
      const availableReminders = getAvailableReminders(selectedDate);
      const validReminders = formData.reminders.filter(reminder =>
        availableReminders.some(r => r.id === reminder && r.available)
      );
      setFormData({ ...formData, reminders: validReminders });
    }
  }, [selectedDate]);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!formData.templateId) return;

      try {
        const response = await axios.get(`/api/v1/data_collections/templates/${formData.templateId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        console.log('Template data:', response.data); // Debug log
        setTemplate(response.data);

        // If recommended period type exists and no period type is selected yet
        if (response.data.recommended_period_type && !formData.periodType) {
          setFormData(prev => ({
            ...prev,
            periodType: response.data.recommended_period_type
          }));
        }
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [formData.templateId]);

  const handleReminderToggle = (reminderId) => {
    const newReminders = formData.reminders.includes(reminderId)
      ? formData.reminders.filter(r => r !== reminderId)
      : [...formData.reminders, reminderId];
    setFormData({ ...formData, reminders: newReminders });
  };

  const handleDeadlineChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, deadline: date });
  };

  const handleDeadlineTypeChange = (type) => {
    setDeadlineType(type);
    setFormData({ ...formData, deadlineType: type });
    if (type === 'no-deadline') {
      handleDeadlineChange(null);
    }
  };

  // Add these state helpers at the top of the component:
  const getCurrentPeriodValue = (type) => {
    const now = new Date();
    switch (type) {
      case 'monthly':
        return {
          month: now.getMonth() + 1,
          year: now.getFullYear()
        };
      case 'quarterly':
        return {
          quarter: Math.floor(now.getMonth() / 3) + 1,
          year: now.getFullYear()
        };
      case 'yearly':
        return {
          year: now.getFullYear()
        };
      default:
        return null;
    }
  };

  const handlePeriodValueChange = (values) => {
    let periodValue = '';
    switch (formData.periodType) {
      case 'monthly':
        // Convert full year to two digits, handling both 1900s and 2000s
        const yearStr = values.year >= 2000
          ? (values.year - 2000).toString().padStart(2, '0')
          : (values.year - 1900).toString().padStart(2, '0');
        periodValue = `${String(values.month).padStart(2, '0')}/${yearStr}`;
        break;
      case 'quarterly':
        const qYearStr = values.year >= 2000
          ? (values.year - 2000).toString().padStart(2, '0')
          : (values.year - 1900).toString().padStart(2, '0');
        periodValue = `Q${values.quarter}/${qYearStr}`;
        break;
      case 'yearly':
        periodValue = values.year.toString();
        break;
    }
    setFormData({ ...formData, periodValue });
  };

  return (
    <div className="space-y-8">
      {/* Deadline Section */}
      <div className="bg-white rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Deadline')}</h3>

        <RadioGroup value={deadlineType} onChange={handleDeadlineTypeChange}>
          <div className="space-y-4">
            <RadioGroup.Option
              value="no-deadline"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                        {t('No Deadline')}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                        {t('Collection will remain open until finished or manually closed')}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                </div>
              )}
            </RadioGroup.Option>

            <RadioGroup.Option
              value="deadline"
              className={({ checked }) =>
                `${checked
                  ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                  : 'bg-white text-gray-900 border-gray-300'
                } relative rounded-lg border p-4 cursor-pointer focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label as="p" className={`font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                          {t('Set Deadline')}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="p" className={`text-sm ${checked ? 'text-gray-200' : 'text-gray-500'}`}>
                          {t('Collection will close on the specified date')}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && <CheckCircleIcon className="h-5 w-5 text-white" />}
                  </div>
                  {checked && (
                    <div className="mt-4">
                      <input
                        type="date"
                        min={today.toISOString().split('T')[0]}
                        value={selectedDate || ''}
                        onChange={(e) => handleDeadlineChange(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm text-gray-800 focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {selectedDate && (
                        <p className="mt-2 text-sm text-white">{formatRelativeDate(selectedDate)}</p>
                      )}
                    </div>
                  )}
                </>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>

      {/* Reminders Section */}
      {selectedDate && (
        <div className="bg-white rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Reminders')}</h3>
          <div className="space-y-4">
            {getAvailableReminders(selectedDate).map(({ id, label, available }) => {
              // Calculate reminder date if available
              let reminderDate = null;
              if (available) {
                reminderDate = new Date(selectedDate);
                switch (id) {
                  case 'month':
                    reminderDate.setDate(reminderDate.getDate() - 30);
                    break;
                  case 'week':
                    reminderDate.setDate(reminderDate.getDate() - 7);
                    break;
                  case 'day':
                    reminderDate.setDate(reminderDate.getDate() - 1);
                    break;
                }
              }

              return (
                <div key={id} className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      checked={formData.periodType !== null}
                      onChange={(e) => {
                        const newPeriodType = e.target.checked ? (template?.recommended_period_type || 'monthly') : null;
                        // Reset period value and set new period type
                        const defaultValue = newPeriodType ? getCurrentPeriodValue(newPeriodType) : null;
                        if (defaultValue) {
                          handlePeriodValueChange(defaultValue);
                        } else {
                          setFormData({
                            ...formData,
                            periodType: newPeriodType,
                            periodValue: ''
                          });
                        }
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-secondary-color focus:ring-secondary-color"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label className={`font-medium ${available ? 'text-gray-700' : 'text-gray-400'}`}>
                      {label}
                      {available ? (
                        <span className="ml-2 text-gray-500">
                          {t('(reminder on {{date}})', {
                            date: reminderDate.toLocaleDateString()
                          })}
                        </span>
                      ) : (
                        <span className="ml-2 text-gray-400 italic">
                          {t('(deadline is closer than reminder)')}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Repetition Section */}
      <div className="bg-white rounded-lg p-6">
        <div className="flex items-start">
          <div className="flex h-5 items-center">
            <input
              type="checkbox"
              checked={formData.periodType !== null}
              onChange={(e) => setFormData({
                ...formData,
                periodType: e.target.checked ?
                  (template?.recommended_period_type || 'monthly') :
                  null
              })}
              className="h-4 w-4 rounded border-gray-300 text-secondary-color focus:ring-secondary-color"
            />
          </div>
          <div className="ml-3">
            <label className="text-lg font-medium text-gray-900">
              <div className="flex items-center">
                {t('Repeat this data collection')}
                {template?.recommended_period_type && (
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                    <LightBulbIcon className="h-3 w-3 mr-1" />
                    {t('Recommended: {{period}}', { period: t(template.recommended_period_type) })}
                  </span>
                )}
              </div>
            </label>
            <p className="text-sm text-gray-500">
              {t('You will be reminded to repeat this collection periodically')}
            </p>
          </div>
        </div>

        {formData.periodType !== null && (
          <div className="mt-4 pl-4">
            <RadioGroup
              value={formData.periodType}
              onChange={(newPeriodType) => {
                // Get default period value for the new period type
                const now = new Date();
                let newPeriodValue = '';

                switch (newPeriodType) {
                  case 'monthly':
                    newPeriodValue = `${String(now.getMonth() + 1).padStart(2, '0')}/${now.getFullYear().toString().slice(-2)}`;
                    break;
                  case 'quarterly':
                    newPeriodValue = `Q${Math.floor(now.getMonth() / 3) + 1}/${now.getFullYear().toString().slice(-2)}`;
                    break;
                  case 'yearly':
                    newPeriodValue = now.getFullYear().toString();
                    break;
                }

                // Update both period type and value together
                setFormData({
                  ...formData,
                  periodType: newPeriodType,
                  periodValue: newPeriodValue
                });
              }}
            >
              <div className="space-y-4">
                {['monthly', 'quarterly', 'yearly'].map((period) => (
                  <RadioGroup.Option
                    key={period}
                    value={period}
                    className={({ checked }) =>
                      `${checked ? 'bg-secondary-color border-secondary-color text-white' : 'bg-white border-gray-300'
                      } relative rounded-lg px-4 py-2 cursor-pointer border flex focus:outline-none`
                    }
                  >
                    {({ checked }) => (
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <RadioGroup.Label className={`text-sm font-medium ${checked ? 'text-white' : 'text-gray-900'}`}>
                            {t(period)}
                          </RadioGroup.Label>
                          <span className={`text-xs ${checked ? 'text-gray-100' : 'text-gray-500'}`}>
                            {t('You will be reminded on: {{date}}', {
                              date: getNextRepetitionDate(period)
                            })}
                          </span>
                        </div>
                        {checked && <CheckCircleIcon className="h-5 w-5" />}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}
      </div>

      {formData.periodType !== null && (
        <>
          <div className="mt-4 pl-4">
            <RadioGroup
              value={formData.periodType}
              onChange={(newPeriodType) => {
                // Reset to default values for the new period type
                const defaultValue = getCurrentPeriodValue(newPeriodType);
                handlePeriodValueChange(defaultValue);
                setFormData(prev => ({
                  ...prev,
                  periodType: newPeriodType
                }));
              }}
            >
              {/* Existing period type radio buttons */}
            </RadioGroup>
          </div>

          {/* Updated Period Value Selection with proper styling */}
          <div className="mt-6 bg-white rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              {t('Select concerned period')}
            </h3>

            <div className="flex gap-4">
              {formData.periodType === 'monthly' && (
                <div className="w-1/3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('Month')}
                  </label>
                  <select
                    className="block w-full rounded-md border-gray-300 bg-white py-2 pl-3 pr-10 text-sm leading-5 focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                    value={parseInt(formData.periodValue?.split('/')[0]) || getCurrentPeriodValue('monthly').month}
                    onChange={(e) => {
                      // Get the current year from period value, or use default if not set
                      const currentYearStr = formData.periodValue?.split('/')[1];
                      const currentYear = currentYearStr
                        ? (parseInt(currentYearStr) < 70 ? 2000 + parseInt(currentYearStr) : 1900 + parseInt(currentYearStr))
                        : getCurrentPeriodValue('monthly').year;

                      handlePeriodValueChange({
                        month: parseInt(e.target.value),
                        year: currentYear
                      });
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                      <option key={month} value={month}>
                        {new Date(2000, month - 1).toLocaleString('default', { month: 'long' })}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {formData.periodType === 'quarterly' && (
                <div className="w-1/3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('Quarter')}
                  </label>
                  <select
                    className="block w-full rounded-md border-gray-300 bg-white py-2 pl-3 pr-10 text-sm leading-5 focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                    value={parseInt(formData.periodValue?.split('/')[0]?.replace('Q', '')) || getCurrentPeriodValue('quarterly').quarter}
                    onChange={(e) => {
                      // Get the current year from period value, or use default if not set
                      const currentYearStr = formData.periodValue?.split('/')[1];
                      const currentYear = currentYearStr
                        ? (parseInt(currentYearStr) < 70 ? 2000 + parseInt(currentYearStr) : 1900 + parseInt(currentYearStr))
                        : getCurrentPeriodValue('quarterly').year;

                      handlePeriodValueChange({
                        quarter: parseInt(e.target.value),
                        year: currentYear
                      });
                    }}
                  >
                    {[1, 2, 3, 4].map(quarter => (
                      <option key={quarter} value={quarter}>
                        {t('Q{{quarter}}', { quarter })}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {formData.periodType && (
                <div className="w-1/3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('Year')}
                  </label>
                  <select
                    className="block w-full rounded-md border-gray-300 bg-white py-2 pl-3 pr-10 text-sm leading-5 focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                    value={
                      formData.periodType === 'yearly'
                        ? (parseInt(formData.periodValue) || getCurrentPeriodValue('yearly').year)
                        : (() => {
                          // For monthly and quarterly, handle years properly
                          const yearStr = formData.periodValue?.split('/')[1];
                          if (!yearStr) return getCurrentPeriodValue(formData.periodType).year;

                          // If year is two digits
                          const yearNum = parseInt(yearStr);
                          // Handle years 00-69 as 2000-2069, 70-99 as 1970-1999
                          return yearNum < 70 ? 2000 + yearNum : 1900 + yearNum;
                        })()
                    }
                    onChange={(e) => {
                      const year = parseInt(e.target.value);
                      if (formData.periodType === 'monthly') {
                        handlePeriodValueChange({
                          month: parseInt(formData.periodValue?.split('/')[0]) || getCurrentPeriodValue('monthly').month,
                          year: year
                        });
                      } else if (formData.periodType === 'quarterly') {
                        handlePeriodValueChange({
                          quarter: parseInt(formData.periodValue?.split('/')[0]?.replace('Q', '')) || getCurrentPeriodValue('quarterly').quarter,
                          year: year
                        });
                      } else {
                        handlePeriodValueChange({ year: year });
                      }
                    }}
                  >
                    {Array.from({ length: 2050 - 1970 + 1 }, (_, i) => 1970 + i).map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TimingStep;