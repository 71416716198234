import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon, PaintBrushIcon, PencilSquareIcon, TrashIcon, GlobeAsiaAustraliaIcon, LightBulbIcon, BanknotesIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import Notification from '../../../components/Notification';
import RainbowButton from '../../../components/RainbowButton';
import PulsatingNewBadge from '../../../components/PulsatingNewBadge';
import CreateSystemTaskButton from '../../../components/CreateSystemTaskButton';
import ConclusionHandler from '../../../components/wizard_components/ConclusionHandler';

const CircularProgress = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalPercentage = publishedPercentage + draftPercentage;

  return (
    <div className="relative w-10 h-10">
      <svg className="w-full h-full -rotate-90" viewBox="0 0 36 36">
        <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200" strokeWidth="3" />
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-yellow-400"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - totalPercentage}
          strokeLinecap="round"
        />
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-green-500"
          strokeWidth="3"
          strokeDasharray="100"
          strokeDashoffset={100 - publishedPercentage}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <span className="text-center text-xs font-bold text-green-500">{Math.round(publishedPercentage)}%</span>
      </div>
    </div>
  );
};

const TotalProgressBar = ({ published, draft, total }) => {
  const publishedPercentage = (published / total) * 100;
  const draftPercentage = (draft / total) * 100;
  const totalCompletedPercentage = publishedPercentage + draftPercentage;
  const remainingPercentage = 100 - totalCompletedPercentage;
  const { t } = useTranslation();

  return (
    <div className="space-y-3">
      <div className="flex justify-between mb-2">
        <div className="inline-block py-0.5 px-1.5 bg-green-50 border border-green-200 text-xs font-medium text-green-600 rounded-lg">
          {t('Published')}: {publishedPercentage.toFixed(1)}%
        </div>
        <div className="inline-block py-0.5 px-1.5 bg-yellow-50 border border-yellow-200 text-xs font-medium text-yellow-600 rounded-lg">
          {t('Draft')}: {draftPercentage.toFixed(1)}%
        </div>
        <div className="inline-block py-0.5 px-1.5 bg-gray-50 border border-gray-200 text-xs font-medium text-gray-600 rounded-lg">
          {t('Remaining')}: {remainingPercentage.toFixed(1)}%
        </div>
      </div>
      <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
        <div className="absolute top-0 left-0 h-full bg-yellow-400 transition-all duration-500" style={{ width: `${totalCompletedPercentage}%` }} />
        <div className="absolute top-0 left-0 h-full bg-green-600 transition-all duration-500" style={{ width: `${publishedPercentage}%` }} />
      </div>
    </div>
  );
};

const KnowYourselfOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expandedSections, setExpandedSections] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [knowYourselfResponse, setKnowYourselfResponse] = useState({});
  const [knowYourselfDraftResponse, setKnowYourselfDraftResponse] = useState({});
  const [company, setCompany] = useState(null);
  const [notification, setNotification] = useState({ show: false, type: 'success', message: '' });

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        setCompany(response.data.entity);
        setKnowYourselfResponse(response.data.entity.know_yourself_response?.published || {});
        setKnowYourselfDraftResponse(response.data.entity.know_yourself_response?.draft || {});
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, []);

  const toggleSection = useCallback((sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  }, []);

  const sections = useMemo(() => [
    {
      id: "environment",
      name: "KnowYourselfProgress.environment",
      icon: GlobeAsiaAustraliaIcon,
      pages: [
        {
          id: "industry",
          name: t('KnowYourselfProgress.industry'),
          fields: ['industry', 'otherIndustry', 'sector', 'otherSector', 'industryNotes']
        },
        {
          id: "regions",
          name: t('KnowYourselfProgress.regions'),
          fields: ['regions', 'subregions', 'marketNotes']
        },
        {
          id: "market",
          name: t('KnowYourselfProgress.market'),
          fields: ['totalAddressableMarket', 'serviceableAddressableMarket', 'serviceableObtainableMarket', 'marketGrowth', 'marketDevelopment', 'currency']
        },
        {
          id: "competition",
          name: t('KnowYourselfProgress.competition'),
          fields: ['competition.aspects', 'competition.competitors', 'competition.competitionNotes']
        }
      ]
    },
    {
      id: "organization",
      name: "KnowYourselfProgress.organization",
      icon: LightBulbIcon,
      pages: [
        {
          id: "business-model",
          name: t('KnowYourselfProgress.businessModel'),
          fields: ['clientele', 'businessSectors', 'clienteleAgeGroup', 'clienteleGenders', 'clienteleIncome', 'revenueCategories', 'modelNotes']
        },
        {
          id: "product",
          name: t('KnowYourselfProgress.productsServices'),
          fields: ['products']
        },
        {
          id: "team",
          name: t('KnowYourselfProgress.team'),
          fields: ['founders', 'keyTeamMembers', 'responsibilities', 'offices']
        },
        {
          id: "story",
          name: t('KnowYourselfProgress.story'),
          fields: ['companyLogo', 'slogan', 'mission', 'vision', 'specialSauce', 'swot']
        }
      ]
    },
    {
      id: "financing",
      name: "KnowYourselfProgress.financing",
      icon: BanknotesIcon,
      pages: [
        {
          id: "investment",
          name: t('KnowYourselfProgress.funding'),
          fields: ['targetFundingRound', 'fundingTime', 'fundingCurrency', 'planningHorizon', 'avgCashBurn', 'capitalRequired', 'investmentNotes']
        },
        {
          id: "fundingNeeds",
          name: t('KnowYourselfProgress.fundingNeeds'),
          fields: [
            'fundingNeeds.useOfFunds',
            'fundingNeeds.descriptions'
          ]
        },
        {
          id: "fundingRounds",
          name: t('KnowYourselfProgress.fundingRounds'),
          fields: [
            'fundingRounds.cashflow',
            'fundingRounds.fundingRounds'
          ]
        },
        {
          id: "termSheet",
          name: t('KnowYourselfProgress.termSheet'),
          fields: [
            'termSheet.boardSeats',
            'termSheet.votingRights',
            'termSheet.liquidationPreference',
            'termSheet.participationRights',
            'termSheet.antiDilution',
            'termSheet.dragAlong',
            'termSheet.dragAlongThreshold',
            'termSheet.tagAlong',
            'termSheet.rightOfFirstRefusal',
            'termSheet.informationRights',
            'termSheet.vetoRights',
            'termSheet.lockupPeriod',
            'termSheet.priorRounds'
          ]
        }
      ]
    },
    {
      id: "shareholder",
      name: "KnowYourselfProgress.shareholder",
      icon: UserGroupIcon,
      pages: [
        {
          id: "captable",
          name: t('KnowYourselfProgress.captable'),
          fields: [
            'captable.shareCapital',
            'captable.nominalShareValue',
            'captable.currentShareholders',
            'captable.targetedNewShareholderCount',
            'captable.targetedShareholders',
            'captable.shareCurrency'
          ]
        },
        {
          id: "vesop",
          name: t('KnowYourselfProgress.esop'),
          fields: [
            'vesop.hasVESOPPool',
            'vesop.poolName',
            'vesop.poolPercentage',
            'vesop.poolShares',
            'vesop.shareholderType',
            'vesop.selectedShareholders',
            'vesop.vestingAcceleration',
            'vesop.vestingCliff',
            'vesop.vestingSchedule'
          ]
        },
        {
          id: "exitStrategy",
          name: t('KnowYourselfProgress.exitStrategy'),
          fields: [
            'exitStrategy.exitStrategies',
            'exitStrategy.targetExitYear',
            'exitStrategy.targetExitValue',
            'exitStrategy.exitReadinessChecklist',
            'exitStrategy.exitNotes'
          ]
        }
      ]
    }
  ], [t]);

  const initialValues = {
    // Environment
    industry: '', otherIndustry: '', sector: '', otherSector: '', industryNotes: '',
    regions: [], subregions: [], marketNotes: '',
    totalAddressableMarket: '',
    serviceableAddressableMarket: '',
    serviceableObtainableMarket: '',
    marketGrowth: '',
    marketDevelopment: '',
    currency: 'USD',
    competition: {
      aspects: ['', '', '', ''],
      competitors: [
        {
          name: company?.name || '',
          ratings: [3, 3, 3, 3],
          isCompany: true
        }
      ],
      competitionNotes: ''
    },

    // Organization
    clientele: [], businessSectors: [], clienteleAgeGroup: [0, 100], clienteleGenders: '',
    clienteleIncome: [],
    revenueCategories: {
      SalesBasedModels: [],
      ServiceBasedModels: [],
      RecurringRevenueModels: [],
      AlternativeRevenueModels: []
    },
    modelNotes: '',
    products: [],
    founders: [], keyTeamMembers: [], responsibilities: {}, offices: [],
    companyLogo: '', slogan: '', mission: '', vision: '',
    specialSauce: '',
    swot: {
      strengths: '',
      weaknesses: '',
      opportunities: '',
      threats: ''
    },

    // Financing
    targetFundingRound: '',
    fundingTime: { month: new Date().getMonth() + 1, year: new Date().getFullYear() },
    fundingCurrency: 'USD',
    planningHorizon: 12,
    avgCashBurn: 50000,
    capitalRequired: 600000,
    investmentNotes: '',
    fundingNeeds: {
      useOfFunds: {
        productDevelopment: 20,
        marketingSales: 20,
        operationsHiring: 20,
        researchDevelopment: 20,
        other: 20,
      },
      descriptions: {
        productDevelopment: '',
        marketingSales: '',
        operationsHiring: '',
        researchDevelopment: '',
        other: '',
      }
    },
    fundingRounds: {
      cashflow: [],
      fundingRounds: [{
        id: 'initial',
        time: { month: 1, year: new Date().getFullYear() },
        type: 'Initial Capital',
        milestones: '',
        preMoneyValuation: 0,
        investmentAmount: 0
      }]
    },
    boardSeats: 1,
    votingRights: 'simple_majority',
    liquidationPreference: 1,
    participationRights: 'non_participating',
    antiDilution: 'weighted_average',
    dragAlong: true,
    dragAlongThreshold: 75,
    tagAlong: true,
    rightOfFirstRefusal: true,
    informationRights: true,
    vetoRights: [],
    lockupPeriod: 12,
    priorRounds: [],

    // Shareholder Management
    captable: {
      shareCapital: 25000,
      nominalShareValue: 1,
      currentShareholders: [],
      targetedNewShareholderCount: 1,
      targetedShareholders: [],
      shareCurrency: 'EUR'
    },
    vesop: {
      hasVESOPPool: true,
      poolName: 'New VESOP Pool',
      poolPercentage: 10,
      shareholderType: 'all',
      selectedShareholders: '',
      vestingAcceleration: true,
      vestingCliff: {
        months: 12,
        percentage: 25
      },
      vestingSchedule: {
        totalDuration: 48,
        remainingPercentage: 75,
        vestingCadence: 3
      }
    },
    exitStrategies: [],
    targetExitYear: new Date().getFullYear() + 5,
    targetExitValue: '',
    exitReadinessChecklist: {
      revenueGrowth: false,
      profitability: false,
      marketPosition: false,
      customerBase: false,
      intellectualProperty: false,
      management: false,
      financialReporting: false,
      compliance: false,
      scalableProcesses: false,
      cleanCapTable: false
    },
    exitNotes: ''
  };

  const isEqual = (value1, value2) => {
    return JSON.stringify(value1) === JSON.stringify(value2);
  };

  const getValue = (obj, field) => {
    if (field === 'aspects' || field === 'competitors') {
      const competition = obj?.competition || {};
      let value = competition[field] || [];

      // If this is the initialValues check, use the actual initialValues instead
      if (obj === initialValues) {
        value = initialValues[field];
      }

      return value;
    }
    if (field === 'competitionNotes') {
      const value = obj?.competition?.competitionNotes || '';
      return value;
    }

    if (field.includes('.')) {
      const [section, subfield] = field.split('.');
      const value = obj?.[section]?.[subfield];
      return value;
    }
    return obj?.[field];
  };

  const getStepStatus = (page) => {
    const draftCoalesced = {};
    const publishedCoalesced = {};
    const initObj = {};

    page.fields.forEach(field => {
      // The default from your "initialValues":
      const defaultVal = getValue(initialValues, field);

      // If draft is undefined, treat it as the default
      let dVal = getValue(knowYourselfDraftResponse, field);
      if (dVal === undefined) dVal = defaultVal;

      // If published is undefined, treat it as the default
      let pVal = getValue(knowYourselfResponse, field);
      if (pVal === undefined) pVal = defaultVal;

      draftCoalesced[field] = dVal;
      publishedCoalesced[field] = pVal;
      initObj[field] = defaultVal;
    });

    // Special handling for funding needs
    if (draftCoalesced['fundingNeeds.useOfFunds']) {
      const defaultFunds = {
        productDevelopment: 20,
        marketingSales: 20,
        operationsHiring: 20,
        researchDevelopment: 20,
        other: 20
      };

      const isDefaultFunds = Object.keys(defaultFunds).every(
        key => draftCoalesced['fundingNeeds.useOfFunds'][key] === defaultFunds[key]
      );

      const isDefaultDescriptions = Object.keys(defaultFunds).every(
        key => (draftCoalesced['fundingNeeds.descriptions'] || {})[key] === ''
      );

      if (isDefaultFunds && isDefaultDescriptions) {
        return 'empty';
      }
    }

    const isDraftEmpty = isEqual(draftCoalesced, initObj);
    const isPublishedEmpty = isEqual(publishedCoalesced, initObj);

    if (isDraftEmpty && isPublishedEmpty) return 'empty';   // truly no data
    if (!isEqual(draftCoalesced, publishedCoalesced)) return 'draft';
    return 'complete';
  };


  const isDraft = (page) => {
    return page.fields.some(field => {
      const draftValue = getValue(knowYourselfDraftResponse, field);
      const publishedValue = getValue(knowYourselfResponse, field);
      const initialValue = getValue(initialValues, field);

      if (field === 'fundingNeeds.useOfFunds') {
        // Check if use of funds is different from the initial 20% distribution
        return !isEqual(draftValue, {
          productDevelopment: 20,
          marketingSales: 20,
          operationsHiring: 20,
          researchDevelopment: 20,
          other: 20
        });
      }

      if (field === 'fundingNeeds.descriptions') {
        // Check if descriptions are different from initial empty strings
        return !isEqual(draftValue, {
          productDevelopment: '',
          marketingSales: '',
          operationsHiring: '',
          researchDevelopment: '',
          other: ''
        });
      }

      if (field.startsWith('fundingNeeds.') || field.startsWith('fundingRounds.')) {
        return !isEqual(draftValue, publishedValue) && !isEqual(draftValue, initialValue);
      }

      if (field === 'competitors' || field === 'aspects') {
        const draftData = getValue(knowYourselfDraftResponse, 'competition') || {};
        const publishedData = getValue(knowYourselfResponse, 'competition') || {};
        return !isEqual(draftData[field], publishedData[field]);
      }

      if (field === 'exitStrategy.targetExitYear') {
        return false; // Ensure this field never counts as a draft trigger
      }

      if (field === 'exitStrategy.exitReadinessChecklist') {
        const checklist = draftValue || {};
        if (Object.values(checklist).some(value => value === true)) {
          return false; // If at least one is true, ignore this as draft
        }
      }

      if (field === 'exitStrategy.exitStrategies') {
        if (Array.isArray(draftValue) && draftValue.length > 0) {
          return false; // If an exit strategy is selected, don't mark as draft
        }
      }

      if (field === 'termSheet.priorRounds' && Array.isArray(draftValue) && draftValue.length === 0) {
        return false;
      }

      if (field === 'termSheet.boardSeats' && draftValue === 1) return false;
      if (field === 'termSheet.votingRights' && draftValue === 'simple_majority') return false;
      if (field === 'termSheet.liquidationPreference' && draftValue === 1) return false;
      if (field === 'termSheet.participationRights' && draftValue === 'non_participating') return false;
      if (field === 'termSheet.antiDilution' && draftValue === 'weighted_average') return false;
      if (field === 'termSheet.dragAlong' && draftValue === true) return false;
      if (field === 'termSheet.dragAlongThreshold' && draftValue === 75) return false;
      if (field === 'termSheet.tagAlong' && draftValue === true) return false;
      if (field === 'termSheet.rightOfFirstRefusal' && draftValue === true) return false;
      if (field === 'termSheet.informationRights' && draftValue === true) return false;
      if (field === 'termSheet.vetoRights' && (!Array.isArray(draftValue) || draftValue.length === 0)) return false;
      if (field === 'termSheet.lockupPeriod' && draftValue === 12) return false;

      if (field === 'vesop.hasVESOPPool' && draftValue === true) return false;
      if (field === 'vesop.poolName' && draftValue === 'New VESOP Pool') return false;
      if (field === 'vesop.poolPercentage' && draftValue === 10) return false;
      if (field === 'vesop.poolShares' && draftValue === 0) return false;
      if (field === 'vesop.shareholderType' && draftValue === 'all') return false;
      if (field === 'vesop.vestingAcceleration' && draftValue === true) return false;
      if (field === 'vesop.vestingCliff' && isEqual(draftValue, { months: 12, percentage: 25 })) return false;
      if (field === 'vesop.vestingSchedule' && isEqual(draftValue, { totalDuration: 48, remainingPercentage: 75, vestingCadence: 3 })) return false;

      return !isEqual(draftValue, publishedValue) && !isEqual(draftValue, initialValue);
    });
  };

  const isPublished = (page) => {
    return page.fields.some(field => {
      const publishedValue = getValue(knowYourselfResponse, field);
      const initialValue = getValue(initialValues, field);

      if (field === 'fundingNeeds.useOfFunds') {
        // Check if use of funds is different from the initial 20% distribution
        return !isEqual(publishedValue, {
          productDevelopment: 20,
          marketingSales: 20,
          operationsHiring: 20,
          researchDevelopment: 20,
          other: 20
        });
      }

      if (field === 'fundingNeeds.descriptions') {
        // Check if descriptions are different from initial empty strings
        return !isEqual(publishedValue, {
          productDevelopment: '',
          marketingSales: '',
          operationsHiring: '',
          researchDevelopment: '',
          other: ''
        });
      }

      if (field.startsWith('fundingRounds.')) {
        if (field === 'fundingRounds.cashflow') {
          return Array.isArray(publishedValue) && publishedValue.length > 0;
        }
        if (field === 'fundingRounds.fundingRounds') {
          const publishedRounds = publishedValue || [];
          return publishedRounds.length > 1 ||
            (publishedRounds.length === 1 &&
              (publishedRounds[0].investmentAmount !== 0 ||
                publishedRounds[0].preMoneyValuation !== 0));
        }
      }

      // Special field checks
      if (field === 'targetFundingRound') return publishedValue && publishedValue.length > 0;
      if (field === 'avgCashBurn') return publishedValue && publishedValue !== 50000;
      if (field === 'planningHorizon') return publishedValue && publishedValue !== 12;
      if (field === 'capitalRequired') return publishedValue && publishedValue !== 600000;
      if (field === 'fundingTime') {
        const defaultTime = {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear()
        };
        return !isEqual(publishedValue, defaultTime);
      }

      if (field === 'competitors') {
        const publishedCompetitors = publishedValue || [];
        // Check if company (first competitor) has non-default ratings
        return publishedCompetitors.length > 0 &&
          !publishedCompetitors[0]?.ratings?.every(r => r === 3);
      }

      if (field === 'exitStrategy.targetExitYear') {
        // Skip the comparison for targetExitYear since it has a default value
        return false;
      }

      if (field === 'exitStrategy.exitReadinessChecklist') {
        const checklist = publishedValue || {};
        // Check if any checkbox is actually checked
        return Object.values(checklist).some(value => value === true);
      }

      if (field === 'exitStrategy.exitStrategies') {
        // Check if there are any selected strategies
        return Array.isArray(publishedValue) && publishedValue.length > 0;
      }

      if (field === 'termSheet.priorRounds' && Array.isArray(publishedValue) && publishedValue.length === 0) {
        return false;
      }
      if (field === 'termSheet.boardSeats' && publishedValue === 1) return false;
      if (field === 'termSheet.votingRights' && publishedValue === 'simple_majority') return false;
      if (field === 'termSheet.liquidationPreference' && publishedValue === 1) return false;
      if (field === 'termSheet.participationRights' && publishedValue === 'non_participating') return false;
      if (field === 'termSheet.antiDilution' && publishedValue === 'weighted_average') return false;
      if (field === 'termSheet.dragAlong' && publishedValue === true) return false;
      if (field === 'termSheet.dragAlongThreshold' && publishedValue === 75) return false;
      if (field === 'termSheet.tagAlong' && publishedValue === true) return false;
      if (field === 'termSheet.rightOfFirstRefusal' && publishedValue === true) return false;
      if (field === 'termSheet.informationRights' && publishedValue === true) return false;
      if (field === 'termSheet.vetoRights' && (!Array.isArray(publishedValue) || publishedValue.length === 0)) return false;
      if (field === 'termSheet.lockupPeriod' && publishedValue === 12) return false;

      if (field === 'vesop.hasVESOPPool' && publishedValue === true) return false;
      if (field === 'vesop.poolName' && publishedValue === 'New VESOP Pool') return false;
      if (field === 'vesop.poolPercentage' && publishedValue === 10) return false;
      if (field === 'vesop.shareholderType' && publishedValue === 'all') return false;
      if (field === 'vesop.vestingAcceleration' && publishedValue === true) return false;
      if (field === 'vesop.vestingCliff' && isEqual(publishedValue, { months: 12, percentage: 25 })) return false;
      if (field === 'vesop.vestingSchedule' && isEqual(publishedValue, { totalDuration: 48, vestingCadence: 3, remainingPercentage: 75 })) return false;

      return publishedValue !== undefined &&
        publishedValue !== null &&
        publishedValue !== '' &&
        !isEqual(publishedValue, initialValue);
    });
  };

  const renderStepIcon = (status) => {
    switch (status) {
      case 'complete':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-green-600">
            <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        );
      case 'draft':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full bg-[rgb(254,249,195)]">
            <PaintBrushIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
          </span>
        );
      default:
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500 text-xl"></span>
          </span>
        );
    }
  };

  const handleDelete = (page) => {
    const status = getStepStatus(page);
    if (status !== 'empty') {
      setPageToDelete(page);
      setDeleteModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (pageToDelete) {
      try {
        await axios.post('/api/v1/wizards/know_yourself/reset', {
          pageId: pageToDelete.id,
          isNested: pageToDelete.id === 'fundingNeeds' ||
            pageToDelete.id === 'fundingRounds' ||
            pageToDelete.id === 'vesop'
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        let resetData = {};
        if (pageToDelete.id === 'fundingRounds') {
          resetData = {
            fundingRounds: {
              cashflow: [],
              fundingRounds: [{
                id: 'initial',
                time: { month: 1, year: new Date().getFullYear() },
                type: 'Initial Capital',
                milestones: '',
                preMoneyValuation: 0,
                investmentAmount: 0
              }]
            }
          };
        } else if (pageToDelete.id === 'fundingNeeds') {
          resetData = {
            fundingNeeds: { ...initialValues.fundingNeeds }
          };
        } else if (pageToDelete.id === 'vesop') {
          resetData = {
            vesop: { ...initialValues.vesop }
          };
        } else {
          resetData = pageToDelete.fields.reduce((acc, field) => {
            acc[field] = initialValues[field];
            return acc;
          }, {});
        }

        setKnowYourselfResponse(prev => ({ ...prev, ...resetData }));
        setKnowYourselfDraftResponse(prev => ({ ...prev, ...resetData }));

        setNotification({
          show: true,
          type: 'success',
          message: t('Data reset successfully')
        });

        setTimeout(() => setNotification(prev => ({ ...prev, show: false })), 3000);
      } catch (error) {
        console.error('Error resetting data:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
        setNotification({
          show: true,
          type: 'error',
          message: t('Error resetting data. Please try again.')
        });
      }
      setDeleteModalOpen(false);
      setPageToDelete(null);
    }
  };

  const getSectionProgress = useCallback((pages) => {
    let published = 0;
    let draft = 0;
    let total = 0;

    pages.forEach(page => {
      if (page.fields.length > 0) {
        total++;
        const status = getStepStatus(page);
        if (status === 'complete') published++;
        else if (status === 'draft') draft++;
      }
    });

    return { published, draft, total };
  }, [getStepStatus]);

  const getTotalProgress = useCallback(() => {
    return sections.reduce((acc, section) => {
      const progress = getSectionProgress(section.pages);
      return {
        published: acc.published + progress.published,
        draft: acc.draft + progress.draft,
        total: acc.total + progress.total
      };
    }, { published: 0, draft: 0, total: 0 });
  }, [sections, getSectionProgress]);

  const totalProgress = getTotalProgress();

  const areAllPagesPublished = useCallback(() => {
    return sections.every(section =>
      section.pages.every(page =>
        page.fields.length === 0 || getStepStatus(page) === 'complete'
      )
    );
  }, [sections, getStepStatus]);

  if (!company) {
    return <div>{t('Loading...')}</div>;
  }

  const generalInfo = {
    'Company Name': company.name,
    'Website': company.website,
    'Country': company.country,
    'Year Founded': company.year_founded
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
        <Notification
          show={notification.show}
          setShow={(show) => setNotification({ ...notification, show })}
          type={notification.type}
          message={notification.message}
          errors={notification.errors}
        />
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-4">{t('Know Yourself Wizard')}</h1>
          <p className="text-normal mb-8">{t('fundingText')}</p>
          <div className="mb-8">
            <TotalProgressBar
              published={totalProgress.published}
              draft={totalProgress.draft}
              total={totalProgress.total}
            />
          </div>
          <div className="space-y-4">
            {sections.map((section) => {
              const progress = getSectionProgress(section.pages);
              return (
                <div key={section.id} className="bg-white shadow rounded-lg overflow-hidden">
                  <button
                    className="w-full text-left px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100"
                    onClick={() => toggleSection(section.id)}
                  >
                    <div className="flex items-center">
                      <CircularProgress
                        published={progress.published}
                        draft={progress.draft}
                        total={progress.total}
                      />
                      <section.icon className="h-7 w-7 ml-4" aria-hidden="true" />
                      <span className="text-lg font-medium ml-2">{t(section.name)}</span>
                    </div>
                    {expandedSections[section.id] ? (
                      <ChevronDownIcon className="h-5 w-5" />
                    ) : (
                      <ChevronRightIcon className="h-5 w-5" />
                    )}
                  </button>
                  {expandedSections[section.id] && (
                    <div>
                      {section.pages.map((page) => {
                        const status = getStepStatus(page);
                        const canNavigate = page.fields.length > 0;

                        return (
                          <div key={page.id} className="w-full text-left md:px-6 px-3 md:py-5 py-3 flex items-center justify-between border-t border-gray-200">
                            <button
                              onClick={() => navigate(`/funding/${page.id}`)}
                              className="flex items-center flex-grow"
                              disabled={!canNavigate}
                            >
                              <span className="flex-shrink-0">
                                {renderStepIcon(status)}
                              </span>
                              <span className="md:ml-6 ml-3 flex flex-col text-left">
                                <span className="text-sm font-medium text-gray-900">{page.name}</span>
                                <span className="text-sm font-medium text-gray-500">
                                  {status === 'complete' ? t("pagePublished") :
                                    status === 'draft' ? t("draftOngoing") :
                                      t("noData")}
                                </span>
                              </span>
                            </button>
                            <div className="flex items-center">
                              {canNavigate && (
                                <>
                                  <CreateSystemTaskButton
                                    taskName={`Complete "${page.name}" in Funding Wizard`}
                                    section="know_yourself"
                                    additionalData={{
                                      link: `/funding/${page.id}`
                                    }}
                                  />
                                  <button
                                    onClick={() => navigate(`/funding/${page.id}`)}
                                    className="text-primary-color hover:text-secondary-color ml-4 mr-4"
                                    aria-label={t("Edit")}
                                  >
                                    <PencilSquareIcon className="h-5 w-5" />
                                  </button>
                                  <button
                                    onClick={() => handleDelete(page)}
                                    className={`text-red-600 hover:text-red-800 ${status === 'empty' ? 'opacity-0 cursor-default' : ''}`}
                                    aria-label={t("Delete")}
                                    disabled={status === 'empty'}
                                  >
                                    <TrashIcon className="h-5 w-5" />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                  }
                </div>
              );
            })}

            {/* VESOP and Cap Table Management Buttons */}
            <div className="mt-4 grid grid-cols-2 gap-4">
              <button
                onClick={() => navigate('/funding/vesop-management')}
                className={`w-full px-6 py-4 rounded-lg shadow relative ${(isPublished({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] }) || isDraft({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] })) &&
                  getValue(knowYourselfDraftResponse, 'vesop.hasVESOPPool') === true
                  ? 'text-primary-color hover:text-white bg-white hover:bg-secondary-color'
                  : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                  }`}
                disabled={(!isPublished({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] }) && !isDraft({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] })) ||
                  getValue(knowYourselfDraftResponse, 'vesop.hasVESOPPool') === false}
              >
                <div className="text-center">
                  <span className="text-lg font-medium block">{t('VESOP Pool Management')}</span>
                  {(!isPublished({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] }) && !isDraft({ fields: ['vesop.hasVESOPPool', 'vesop.poolName'] })) ? (
                    <span className="text-sm block mt-1">({t('available after saving VESOP-page in Wizard')})</span>
                  ) : getValue(knowYourselfDraftResponse, 'vesop.hasVESOPPool') === false ? (
                    <span className="text-sm block mt-1">({t('VESOP Pool disabled on VESOP-page in Wizard')})</span>
                  ) : null}
                </div>
                {(knowYourselfResponse?.vesopManagement?.allocatedPeople?.length === 0 &&
                  getValue(knowYourselfDraftResponse, 'vesop.hasVESOPPool') === true) && (
                    <PulsatingNewBadge />
                  )}
              </button>

              <button
                onClick={() => navigate('/funding/captable-history')}
                className={`w-full px-6 py-4 rounded-lg shadow relative ${isPublished({ fields: ['captable.shareCapital', 'captable.currentShareholders'] }) ||
                  isDraft({ fields: ['captable.shareCapital', 'captable.currentShareholders'] })
                  ? 'text-primary-color hover:text-white bg-white hover:bg-secondary-color'
                  : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                  }`}
                disabled={!isPublished({ fields: ['captable.shareCapital', 'captable.currentShareholders'] }) &&
                  !isDraft({ fields: ['captable.shareCapital', 'captable.currentShareholders'] })}
              >
                <div className="text-center">
                  <span className="text-lg font-medium block">{t('Cap Table History')}</span>
                  {!isPublished({ fields: ['captable.shareCapital', 'captable.currentShareholders'] }) &&
                    !isDraft({ fields: ['captable.shareCapital', 'captable.currentShareholders'] }) && (
                      <span className="text-sm block mt-1">({t('available after saving Cap Table-page in Wizard')})</span>
                    )}
                </div>
                {(!knowYourselfResponse?.captablehistory?.shareholders?.length ||
                  !knowYourselfResponse?.captablehistory?.transactions?.length) &&
                  isPublished({ fields: ['captable.shareCapital', 'captable.currentShareholders'] }) && (
                    <PulsatingNewBadge />
                  )}
              </button>
            </div>

            {/* Summary section */}
            <div className="mt-4 bg-white shadow rounded-lg overflow-hidden">
              <button
                onClick={() => navigate('/funding/summary')}
                className={`w-full ${areAllPagesPublished()
                  ? 'text-primary-color hover:text-white bg-white hover:bg-secondary-color'
                  : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                  }`}
                disabled={!areAllPagesPublished()}
              >
                <div className="px-6 py-4 text-center">
                  <span className="text-lg font-medium text-center text-middle">
                    {areAllPagesPublished()
                      ? t('KnowYourselfProgress.summary')
                      : t('KnowYourselfProgress.summaryDisabled')}
                  </span>
                </div>
              </button>
            </div>

            {/* Conclusion section */}
            {areAllPagesPublished() && <ConclusionHandler />}
          </div>

          {/* Delete confirmation modal */}
          <Transition.Root show={deleteModalOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setDeleteModalOpen}>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            {t("Delete data")}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {t("Are you sure you want to delete all data for this page? This action cannot be undone.")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          onClick={confirmDelete}
                        >
                          {t("Delete")}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setDeleteModalOpen(false)}
                        >
                          {t("Cancel")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div >
      </div >
    </>
  );
};

export default KnowYourselfOverview;