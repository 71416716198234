// src/components/wizard_components/DataPrivacyWizard/riskAssessment/RiskDetailsForm.js
import React from 'react';
import { useTranslation } from 'react-i18next';

// Potential harms
const potentialHarms = [
    { id: 'discrimination', key: 'discrimination' },
    { id: 'identity_theft', key: 'identityTheft' },
    { id: 'financial_loss', key: 'financialLoss' },
    { id: 'reputational_damage', key: 'reputationalDamage' },
    { id: 'confidentiality_loss', key: 'confidentialityLoss' },
    { id: 'social_disadvantage', key: 'socialDisadvantage' },
    { id: 'economic_disadvantage', key: 'economicDisadvantage' },
    { id: 'psychological_impact', key: 'psychologicalImpact' },
    { id: 'physical_harm', key: 'physicalHarm' }
];

// Likelihood options
const likelihoodOptions = [
    { id: 'rare', value: 1, key: 'rare' },
    { id: 'unlikely', value: 2, key: 'unlikely' },
    { id: 'possible', value: 3, key: 'possible' },
    { id: 'likely', value: 4, key: 'likely' },
    { id: 'almost_certain', value: 5, key: 'almostCertain' }
];

// Severity options
const severityOptions = [
    { id: 'negligible', value: 1, key: 'negligible' },
    { id: 'minor', value: 2, key: 'minor' },
    { id: 'moderate', value: 3, key: 'moderate' },
    { id: 'major', value: 4, key: 'major' },
    { id: 'severe', value: 5, key: 'severe' }
];

// Function to determine risk level
const calculateRiskLevel = (likelihood, severity) => {
    const riskScore = likelihood * severity;

    if (riskScore >= 15) return { level: 'high', color: 'bg-red-100 text-red-800' };
    if (riskScore >= 8) return { level: 'medium', color: 'bg-yellow-100 text-yellow-800' };
    return { level: 'low', color: 'bg-green-100 text-green-800' };
};

// Risk scenarios data - for helping with built-in risk names
const riskScenarios = [
    { id: 'unauthorized_access', key: 'unauthorizedAccess' },
    { id: 'data_breach', key: 'dataBreach' },
    { id: 'excessive_collection', key: 'excessiveCollection' },
    { id: 'unauthorized_sharing', key: 'unauthorizedSharing' },
    { id: 'data_loss', key: 'dataLoss' },
    { id: 'inaccurate_processing', key: 'inaccurateProcessing' },
    { id: 'rights_hindrance', key: 'rightsHindrance' },
    { id: 'beyond_purpose', key: 'beyondPurpose' },
    { id: 'improper_legal_basis', key: 'improperLegalBasis' },
    { id: 'excessive_retention', key: 'excessiveRetention' }
];

const RiskDetailsForm = ({ formik, selectedRiskId, setSelectedRiskId }) => {
    const { t } = useTranslation();

    // Find the currently selected risk
    const selectedRisk = selectedRiskId
        ? formik.values.selectedRisks.find(risk => risk.id === selectedRiskId)
        : null;

    // Get the name of a risk
    const getRiskName = (riskId) => {
        // First check custom risks in formik values
        const customRisk = formik.values.riskScenarios.find(risk => risk.id === riskId && risk.isCustom);
        if (customRisk) return customRisk.name;

        // Then check built-in risks
        const builtInRisk = riskScenarios.find(risk => risk.id === riskId);
        if (builtInRisk) return t(`DataPrivacyWizard.dpia.riskAssessment.risks.${builtInRisk.key}`);

        // Return the risk ID if not found
        return riskId;
    };

    // Toggle a potential harm for the selected risk
    const handleToggleHarm = (harmId) => {
        const riskIndex = formik.values.selectedRisks.findIndex(risk => risk.id === selectedRiskId);

        if (riskIndex !== -1) {
            const updatedRisks = [...formik.values.selectedRisks];
            const risk = { ...updatedRisks[riskIndex] };

            // Toggle the harm
            if (risk.harms.includes(harmId)) {
                risk.harms = risk.harms.filter(id => id !== harmId);
            } else {
                risk.harms = [...risk.harms, harmId];
            }

            updatedRisks[riskIndex] = risk;
            formik.setFieldValue('selectedRisks', updatedRisks);
        }
    };

    // Update likelihood or severity
    const handleRiskPropertyChange = (property, value) => {
        const riskIndex = formik.values.selectedRisks.findIndex(risk => risk.id === selectedRiskId);

        if (riskIndex !== -1) {
            const updatedRisks = [...formik.values.selectedRisks];
            updatedRisks[riskIndex] = {
                ...updatedRisks[riskIndex],
                [property]: value
            };

            formik.setFieldValue('selectedRisks', updatedRisks);
        }
    };

    // Update risk notes
    const handleNotesChange = (e) => {
        const riskIndex = formik.values.selectedRisks.findIndex(risk => risk.id === selectedRiskId);

        if (riskIndex !== -1) {
            const updatedRisks = [...formik.values.selectedRisks];
            updatedRisks[riskIndex] = {
                ...updatedRisks[riskIndex],
                notes: e.target.value
            };

            formik.setFieldValue('selectedRisks', updatedRisks);
        }
    };

    if (!selectedRisk) {
        return (
            <div className="bg-white border border-gray-200 rounded-lg p-6 flex flex-col items-center justify-center h-full">
                <p className="text-gray-500 text-center mb-4">
                    {formik.values.selectedRisks?.length > 0
                        ? t('DataPrivacyWizard.dpia.riskAssessment.selectRiskFromList')
                        : t('DataPrivacyWizard.dpia.riskAssessment.noRisksSelected')}
                </p>
                {formik.values.selectedRisks?.length === 0 && (
                    <button
                        type="button"
                        onClick={() => {
                            // Add the first risk from the list if no risks are selected
                            if (riskScenarios.length > 0) {
                                const firstRisk = riskScenarios[0];
                                const newRisk = {
                                    id: firstRisk.id,
                                    harms: [],
                                    likelihood: 3,
                                    severity: 3,
                                    notes: ''
                                };
                                formik.setFieldValue('selectedRisks', [newRisk]);
                                setSelectedRiskId(firstRisk.id);
                            }
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-primary-color/90"
                    >
                        {t('DataPrivacyWizard.dpia.riskAssessment.addFirstRisk')}
                    </button>
                )}
            </div>
        );
    }

    return (
        <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
            <div className="px-4 py-5 bg-gray-50 border-b border-gray-200">
                <h3 className="text-base font-medium text-gray-900">
                    {getRiskName(selectedRisk.id)}
                </h3>
            </div>
            <div className="px-4 py-5">
                {/* Potential Harms */}
                <div className="mb-6">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                        {t('DataPrivacyWizard.dpia.riskAssessment.potentialHarms')}
                    </h4>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
                            {potentialHarms.map(harm => (
                                <div key={harm.id} className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id={`harm-${harm.id}`}
                                            type="checkbox"
                                            checked={selectedRisk.harms.includes(harm.id)}
                                            onChange={() => handleToggleHarm(harm.id)}
                                            className="h-4 w-4 text-primary-color border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor={`harm-${harm.id}`} className="font-medium text-gray-700">
                                            {t(`DataPrivacyWizard.dpia.riskAssessment.harms.${harm.key}`)}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Likelihood Assessment */}
                <div className="mb-6">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                        {t('DataPrivacyWizard.dpia.riskAssessment.likelihood')}
                    </h4>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <div className="space-y-2">
                            {likelihoodOptions.map(option => (
                                <button
                                    key={option.id}
                                    type="button"
                                    onClick={() => handleRiskPropertyChange('likelihood', option.value)}
                                    className={`flex items-center w-full px-3 py-2 rounded-md text-sm transition-colors
                              ${selectedRisk.likelihood === option.value
                                            ? 'bg-primary-color border border-gray-300 text-white font-medium'
                                            : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'}`}
                                >
                                    <div className="w-5 h-5 flex items-center justify-center mr-2">
                                        <span className="text-xs font-mono bg-white bg-opacity-20 px-1.5 rounded">
                                            {option.value}
                                        </span>
                                    </div>
                                    {t(`DataPrivacyWizard.dpia.riskAssessment.likelihoodOptions.${option.key}`)}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Severity Assessment */}
                <div className="mb-6">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                        {t('DataPrivacyWizard.dpia.riskAssessment.severity')}
                    </h4>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <div className="space-y-2">
                            {severityOptions.map(option => (
                                <button
                                    key={option.id}
                                    type="button"
                                    onClick={() => handleRiskPropertyChange('severity', option.value)}
                                    className={`flex items-center w-full px-3 py-2 rounded-md text-sm transition-colors
                              ${selectedRisk.severity === option.value
                                            ? 'bg-primary-color border border-gray-300 text-white font-medium'
                                            : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'}`}
                                >
                                    <div className="w-5 h-5 flex items-center justify-center mr-2">
                                        <span className="text-xs font-mono bg-white bg-opacity-20 px-1.5 rounded">
                                            {option.value}
                                        </span>
                                    </div>
                                    {t(`DataPrivacyWizard.dpia.riskAssessment.severityOptions.${option.key}`)}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Notes */}
                <div className="mb-6">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                        {t('DataPrivacyWizard.dpia.riskAssessment.notes')}
                    </h4>
                    <textarea
                        value={selectedRisk.notes || ''}
                        onChange={handleNotesChange}
                        rows="3"
                        className="shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('DataPrivacyWizard.dpia.riskAssessment.notesPlaceholder')}
                    />
                </div>

                {/* Risk Level Summary */}
                <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                        {t('DataPrivacyWizard.dpia.riskAssessment.riskLevel.title')}
                    </h4>
                    <div className={`${calculateRiskLevel(selectedRisk.likelihood, selectedRisk.severity).color} p-4 rounded-md`}>
                        <p className={`${calculateRiskLevel(selectedRisk.likelihood, selectedRisk.severity).color} text-lg font-bold justify-center flex items-center`}>
                            <span>{selectedRisk.likelihood * selectedRisk.severity}</span>
                            <span className="ml-2">({t(`DataPrivacyWizard.dpia.riskAssessment.riskLevel.${calculateRiskLevel(selectedRisk.likelihood, selectedRisk.severity).level}`)})</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskDetailsForm;