import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExistingAssetsList from './ExistingAssetsList';
import { useExistingAssets } from './AssetDataLoader';
import { enrichAssetsWithTypeInfo, filterAssetsByHierarchy } from './AssetFilterUtils';

/**
 * Component for displaying and selecting from existing assets
 * 
 * @param {Object} props
 * @param {Array} props.existingAssets - Assets already associated with the activity
 * @param {Array} props.selectedAssets - Currently selected assets
 * @param {Function} props.setSelectedAssets - Function to update selected assets
 * @param {Function} props.onConfirm - Callback when selection is confirmed
 * @param {Function} props.onCancel - Callback when selection is canceled
 * @param {Function} props.setError - Function to set error messages
 * @param {Object} props.filter - Optional filter configuration
 * @param {string} props.userLang - User's language preference (default: 'en')
 * @param {Function} props.getCategoryIcon - Function to get category icon path
 * @param {boolean} props.multiSelect - Enable multiple selection (default: true)
 */
const AssetSelectionView = ({
    existingAssets = [],
    selectedAssets,
    setSelectedAssets,
    onConfirm,
    onCancel,
    setError,
    filter = null,
    userLang = 'en',
    getCategoryIcon,
    multiSelect = true,
    allAssetTypes = [],
    allAssetClasses = [],
    allAssetCategories = []
}) => {
    const { t } = useTranslation();
    const {
        assets: allAssets,
        loading
    } = useExistingAssets(true); // Load assets on mount

    const [filteredAssets, setFilteredAssets] = useState([]);

    // Apply filtering when assets or filter changes
    useEffect(() => {
        if (allAssets.length > 0) {
            console.log("Processing assets for filtering:", allAssets.length);

            // First, enrich assets with type information
            const enrichedAssets = enrichAssetsWithTypeInfo(
                allAssets,
                allAssetTypes,
                allAssetClasses,
                allAssetCategories
            );

            console.log("Assets after enrichment:", enrichedAssets.length);

            // Apply filtering based on the filter prop
            let filtered = enrichedAssets;
            if (filter) {
                filtered = filterAssetsByHierarchy(enrichedAssets, filter);
                console.log(`Filtered assets from ${enrichedAssets.length} to ${filtered.length} based on filter:`, filter);
            }

            setFilteredAssets(filtered);

            // Initialize selectedAssets with existingAssets if provided
            if (existingAssets && existingAssets.length > 0 && selectedAssets.length === 0) {
                // Find the full asset objects that match the IDs in existingAssets
                const initialSelection = filtered.filter(asset =>
                    existingAssets.some(existing => String(existing.id) === String(asset.id))
                );

                console.log("Setting initial selection:", initialSelection.length, "assets");
                setSelectedAssets(initialSelection);
            }
        }
    }, [
        allAssets,
        filter,
        allAssetTypes,
        allAssetClasses,
        allAssetCategories,
        existingAssets,
        // Don't include selectedAssets here to avoid infinite loop
    ]);

    const handleConfirmSelection = () => {
        // Instead of requiring at least one asset, allow confirming an empty selection
        // to indicate that all assets should be disassociated

        // Compare current selection with existing assets to determine what was added/removed
        const existingIds = existingAssets.map(asset => String(asset.id));
        const selectedIds = selectedAssets.map(asset => String(asset.id));

        console.log("Confirming selection:", {
            existingCount: existingAssets.length,
            selectedCount: selectedAssets.length,
            existingIds,
            selectedIds
        });

        // Call the confirm callback with selected assets
        onConfirm(selectedAssets);
    };

    return (
        <div>
            <ExistingAssetsList
                selectedAssets={selectedAssets}
                setSelectedAssets={setSelectedAssets}
                existingAssets={existingAssets}
                allAssets={filteredAssets}
                setError={setError}
                userLang={userLang}
                getCategoryIcon={getCategoryIcon}
                multiSelect={multiSelect}
                loading={loading}
            />

            <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-3 border-t mt-auto">
                <button
                    type="button"
                    onClick={onCancel || (() => { })}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                    {userLang === 'de' ? 'Zurück' : 'Back'}
                </button>

                <button
                    type="button"
                    onClick={handleConfirmSelection}
                    className={`px-4 py-2 text-sm font-medium text-white rounded-md shadow-sm ${selectedAssets.length === 0
                            ? 'bg-gray-300' // Keep different styling but remove cursor-not-allowed
                            : 'bg-secondary-color hover:opacity-80'
                        }`}
                >
                    {selectedAssets.length === 0
                        ? (userLang === 'de' ? 'Keine Assets verknüpfen' : 'Associate No Assets')
                        : selectedAssets.length === 1
                            ? (userLang === 'de' ? 'Asset verknüpfen' : 'Associate Asset')
                            : (userLang === 'de'
                                ? `${selectedAssets.length} Assets verknüpfen`
                                : `Associate ${selectedAssets.length} Assets`)}
                </button>
            </div>
        </div>
    );
};

export default AssetSelectionView;