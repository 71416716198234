// src/components/FlowChart/api/flowChartApi.js
import axios from 'axios';

const API_URL = '/api/v1/flow_charts';

export const getFlowCharts = async (params = {}) => {
  const response = await axios.get(API_URL, { params });
  return response.data;
};

export const getFlowChartById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

export const createFlowChart = async (data) => {
  const response = await axios.post(API_URL, { flow_chart: data });
  return response.data;
};

export const updateFlowChart = async (id, data) => {
  const response = await axios.put(`${API_URL}/${id}`, { flow_chart: data });
  return response.data;
};

export const deleteFlowChart = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};

export const cloneFlowChart = async (id, newName, referenceType, referenceId) => {
  const response = await axios.post(`${API_URL}/${id}/clone`, {
    name: newName,
    reference_type: referenceType,
    reference_id: referenceId
  });
  return response.data;
};