// src/pages/wizards/DataPrivacyWizard/components/InternalRecipientDetails.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import CountrySelector from '../../../components/CountrySelector';
import ComplianceMechanismField from './ComplianceMechanismField';
import { getCountryName, getCountryComplianceStatus } from './transfersHelpers';

const InternalRecipientDetails = ({
  transfer,
  recipientData,
  index,
  formik,
  isEditing,
  countries,
  gdprCountries,
  isThirdCountry,
  toggleDrawer,
  userLang
}) => {
  const { t } = useTranslation();
  
  // Determine country compliance status (GDPR, GDPR-like, third country)
  const countryComplianceStatus = transfer.country 
    ? getCountryComplianceStatus(transfer.country, countries)
    : null;

  const countryName = getCountryName(transfer.country, countries) || '';
  const translationKey = `Countries.${countryName}`; 
  const translatedCountryName = countryName ? t(translationKey) : '';
    
  return (
    <div className="space-y-4">
      {/* Country Information */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.processingCountry')}
          <button
            type="button"
            onClick={() => toggleDrawer(
              t('DataPrivacyWizard.processingCountryInfo.title'),
              t('DataPrivacyWizard.processingCountryInfo.content')
            )}
            className="ml-1 text-gray-400 hover:text-gray-500"
          >
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </button>
        </label>
        
        {isEditing ? (
          <>
            <div className="flex items-start mb-2">
              <input
                type="checkbox"
                id={`internal-multiple-${index}`}
                checked={transfer.multipleCountries}
                onChange={(e) => {
                  formik.setFieldValue(`internalTransfers[${index}].multipleCountries`, e.target.checked);
                  if (!e.target.checked) {
                    formik.setFieldValue(`internalTransfers[${index}].thirdCountries`, false);
                  }
                }}
                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
              />
              <label
                htmlFor={`internal-multiple-${index}`}
                className="ml-2 block text-sm text-gray-700"
              >
                {t('DataPrivacyWizard.multipleCountriesOption')}
              </label>
            </div>
            
            {!transfer.multipleCountries ? (
              <div className="mt-1">
                <CountrySelector
                  value={transfer.country}
                  onChange={(value) => {
                    formik.setFieldValue(`internalTransfers[${index}].country`, value);
                  }}
                  className="w-full"
                />
              </div>
            ) : (
              <div className="mt-3 pl-6">
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id={`internal-third-countries-${index}`}
                    checked={transfer.thirdCountries}
                    onChange={(e) => {
                      formik.setFieldValue(`internalTransfers[${index}].thirdCountries`, e.target.checked);
                    }}
                    className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                  />
                  <div className="ml-2">
                    <label
                      htmlFor={`internal-third-countries-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('DataPrivacyWizard.includesThirdCountries')}
                    </label>
                    <p className="mt-1 text-xs text-gray-500">
                      {t('DataPrivacyWizard.thirdCountriesExplanation')}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => toggleDrawer(
                      t('DataPrivacyWizard.thirdCountriesInfo.title'),
                      t('DataPrivacyWizard.thirdCountriesInfo.content', countries, userLang)
                    )}
                    className="ml-1 text-gray-400 hover:text-gray-500"
                  >
                    <QuestionMarkCircleIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="mt-1 text-sm text-gray-900">
            {transfer.multipleCountries ? (
              <div>
                <span>{t('DataPrivacyWizard.multipleCountries')}</span>
                {transfer.thirdCountries && (
                  <div className="mt-1">
                    <span className="font-medium text-amber-600">
                      {t('DataPrivacyWizard.includesThirdCountries')}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  { translatedCountryName }
                </div>
                
                {transfer.country && countryComplianceStatus && (
                  <div className="mt-0">
                    {countryComplianceStatus === 'gdpr' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'gdpr-like' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprEquivalentCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'third-country' && (
                      <span className="font-medium text-amber-600 text-xs">
                        {t('DataPrivacyWizard.nonGdprCountry')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      
      {/* Compliance Mechanism - Show only for third countries */}
      {((transfer.multipleCountries && transfer.thirdCountries) || 
         isThirdCountry(transfer.country, countries)) && (
        <ComplianceMechanismField
          transfer={transfer}
          index={index}
          formik={formik}
          isEditing={isEditing}
          fieldPrefix="internalTransfers"
          toggleDrawer={toggleDrawer}
          userLang={userLang}
        />
      )}
      
      {/* Connected Activities */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.connectedProcessingActivities')}
        </h4>
        <ul className="mt-1 divide-y divide-gray-200 border rounded-md overflow-hidden">
          {recipientData.connectedActivities && recipientData.connectedActivities.length > 0 ? (
            recipientData.connectedActivities.map((activity) => (
              <li key={activity.id} className="px-4 py-3 text-sm text-gray-900 bg-white hover:bg-gray-50">
                {activity.name}
              </li>
            ))
          ) : (
            <li className="px-4 py-3 text-sm text-gray-500 bg-white">
              {t('DataPrivacyWizard.noConnectedActivities')}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default InternalRecipientDetails;