import React, { useState, useEffect } from 'react';
import { Dialog, Listbox } from '@headlessui/react';
import { XMarkIcon, ChevronUpDownIcon, CheckIcon, UserIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import CountrySelector from '../CountrySelector';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Validation schema (unchanged)
const validationSchema = Yup.object().shape({
  type: Yup.string().oneOf(['natural', 'juridical']).required('Type is required'),

  // Natural person fields
  fullName: Yup.string().when('type', {
    is: 'natural',
    then: () => Yup.string().required('Full Name is required'),
  }),
  dateOfBirth: Yup.date().when('type', {
    is: 'natural',
    then: () => Yup.date().required('Date of Birth is required'),
  }),
  nationality: Yup.string().when('type', {
    is: 'natural',
    then: () => Yup.string().required('Nationality is required'),
  }),

  // Juridical person fields
  legalName: Yup.string().when('type', {
    is: 'juridical',
    then: () => Yup.string().required('Legal Name is required'),
  }),
  seat: Yup.string().when('type', {
    is: 'juridical',
    then: () => Yup.string().required('Seat is required'),
  }),

  // Common fields
  address: Yup.object({
    street: Yup.string().required('Street is required'),
    zip: Yup.string().required('ZIP is required'),
    place: Yup.string().required('Place is required'),
    country: Yup.string().required('Country is required'),
  }),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
});


/**
 * ShareholderModal
 * 
 * - If `initialData?.id` exists, we treat it as "edit" mode.
 * - Otherwise, it's "create" mode.
 * - We also store `type` in local state so we can toggle natural vs. juridical UI.
 */
const ShareholderModal = ({ isOpen, onClose, onSubmit, onSaveAsDraft, initialData }) => {
  const { t } = useTranslation();
  // We'll store the type in a local state so the buttons are highlighted correctly.
  const [type, setType] = useState(initialData?.type || 'natural');

  // If `initialData` changes while the modal is open, update the local `type`.
  useEffect(() => {
    if (initialData?.type) {
      setType(initialData.type);
    }
  }, [initialData]);

  // Build the form's initial values from either existing data or defaults
  const initialValues = {
    // We'll store the shareholder's ID. If it doesn't exist, it means "new shareholder."
    id: initialData?.id || '',
    type: initialData?.type || 'natural',

    // Natural
    fullName: initialData?.fullName || '',
    dateOfBirth: initialData?.dateOfBirth || '',
    nationality: initialData?.nationality || '',

    // Juridical
    legalName: initialData?.legalName || '',
    seat: initialData?.seat || '',
    registry: initialData?.registry || '',
    registryNumber: initialData?.registryNumber || '',
    contactPerson: {
      name: initialData?.contactPerson?.name || '',
      role: initialData?.contactPerson?.role || ''
    },

    // Beneficial Owners
    beneficialOwnersCount: initialData?.beneficialOwnersCount || 0,
    beneficialOwners: initialData?.beneficialOwners || [],

    // Common
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    address: {
      street: initialData?.address?.street || '',
      streetAdditional: initialData?.address?.streetAdditional || '',
      zip: initialData?.address?.zip || '',
      place: initialData?.address?.place || '',
      country: initialData?.address?.country || '',
    },

    // Entry Data
    entryMonth: initialData?.entryMonth || 1,
    entryYear: initialData?.entryYear || new Date().getFullYear(),
    entryRound: initialData?.entryRound || 'Founding',
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg">
          <div className="p-6 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-start">
              <Dialog.Title className="text-lg font-semibold">
                {initialData?.id ? 'Edit Shareholder' : 'Add New Shareholder'}
              </Dialog.Title>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                // "values" includes `.id`
                await onSubmit(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form className="mt-4 space-y-4">
                  {/* Type Selection */}
                  <div className="flex w-full">
                    <button
                      type="button"
                      className={classNames(
                        'flex-1 flex items-center justify-center gap-2 px-4 py-2 rounded-l-md transition-colors',
                        values.type === 'natural' ? 'bg-secondary-color text-white' : 'bg-gray-300'
                      )}
                      onClick={() => {
                        setType('natural');
                        setFieldValue('type', 'natural');
                      }}
                    >
                      <UserIcon className="w-5 h-5" />
                      {t('Natural Person')}
                    </button>

                    <button
                      type="button"
                      className={classNames(
                        'flex-1 flex items-center justify-center gap-2 px-4 py-2 rounded-r-md transition-colors',
                        values.type === 'juridical' ? 'bg-secondary-color text-white' : 'bg-gray-300'
                      )}
                      onClick={() => {
                        setType('juridical');
                        setFieldValue('type', 'juridical');
                      }}
                    >
                      <BuildingOfficeIcon className="w-5 h-5" />
                      {t('Juridical Person')}
                    </button>
                  </div>

                  {/* Fields for Natural Person */}
                  {values.type === 'natural' ? (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Full Name')}</label>
                        <Field
                          name="fullName"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                        {errors.fullName && touched.fullName && (
                          <div className="text-red-500 text-sm mt-1">{errors.fullName}</div>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Date of Birth')}</label>
                        <Field
                          name="dateOfBirth"
                          type="date"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                        {errors.dateOfBirth && touched.dateOfBirth && (
                          <div className="text-red-500 text-sm mt-1">{errors.dateOfBirth}</div>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Nationality')}</label>
                        <CountrySelector
                          value={values.nationality}
                          onChange={(newCode) => setFieldValue('nationality', newCode)}
                        />
                        {errors.nationality && touched.nationality && (
                          <div className="text-red-500 text-sm mt-1">{errors.nationality}</div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Fields for Juridical Person */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Legal Name')}</label>
                        <Field
                          name="legalName"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                        {errors.legalName && touched.legalName && (
                          <div className="text-red-500 text-sm mt-1">{errors.legalName}</div>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Seat')}</label>
                        <Field
                          name="seat"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                        {errors.seat && touched.seat && (
                          <div className="text-red-500 text-sm mt-1">{errors.seat}</div>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Registry')}</label>
                        <Field
                          name="registry"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">{t('Registry Number')}</label>
                        <Field
                          name="registryNumber"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                        />
                      </div>

                      {/* Beneficial Owners Section */}
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          {t('Any Beneficial Owners? (more than 25% voting rights or shares?)')}
                        </label>
                        <Field
                          as="select"
                          name="beneficialOwnersCount"
                          className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:ring-secondary-color"
                          onChange={(e) => {
                            const count = parseInt(e.target.value, 10);
                            setFieldValue('beneficialOwnersCount', count);
                            const newBeneficialOwners =
                              count > 0
                                ? Array(count).fill({
                                  fullName: '',
                                  nationality: '',
                                  dateOfBirth: '',
                                  residencePlace: '',
                                  residenceCountry: ''
                                })
                                : [];
                            setFieldValue('beneficialOwners', newBeneficialOwners);
                          }}
                        >
                          <option value="0">{t('0 (No beneficial owners over 25%)')}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </Field>
                      </div>

                      {/* Render each beneficial owner */}
                      {Array.from({ length: values.beneficialOwnersCount }).map((_, boIndex) => (
                        <div key={boIndex} className="mt-4 p-4 border rounded-lg bg-gray-50">
                          <h4 className="text-md font-medium">Beneficial Owner {boIndex + 1}</h4>
                      
                          <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">{t('Full Name')}</label>
                            <Field
                              name={`beneficialOwners[${boIndex}].fullName`}
                              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:ring-secondary-color"
                            />
                          </div>

                          {/* BO Nationality */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">{t('Nationality')}</label>
                            <CountrySelector
                              value={values.beneficialOwners?.[boIndex]?.nationality}
                              onChange={(newCode) =>
                                setFieldValue(`beneficialOwners[${boIndex}].nationality`, newCode)
                              }
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">{t('Date of Birth')}</label>
                            <Field
                              name={`beneficialOwners[${boIndex}].dateOfBirth`}
                              type="date"
                              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                            />
                          </div>

                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">{t('Residence (Place)')}</label>
                            <Field
                              name={`beneficialOwners[${boIndex}].residencePlace`}
                              className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                            />
                          </div>

                          {/* Residence Country */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">{t('Residence (Country)')}</label>
                            <CountrySelector
                              value={values.beneficialOwners?.[boIndex]?.residenceCountry}
                              onChange={(newCode) =>
                                setFieldValue(`beneficialOwners[${boIndex}].residenceCountry`, newCode)
                              }
                            />
                          </div>
                        </div>
                      ))}
                      {/* Contact Person for Juridical */}
                      <div className="col-span-2 mt-4">
                        <h4 className="text-md font-medium">{t('Contact Person')}</h4>
                        {/* Name */}
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">{t('Name')}</label>
                          <Field
                            name="contactPerson.name"
                            className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                          />
                        </div>
                        {/* Role */}
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">{t('Role')}</label>
                          <Field
                            name="contactPerson.role"
                            className="mt-1 block w-full border-gray-300 rounded-md focus:border-secondary-color focus:outline-none focus:ring-1 focus:ring-secondary-color"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* Common Fields */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Email')}</label>
                      <Field
                        name="email"
                        type="email"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {errors.email && touched.email && (
                        <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Phone')}</label>
                      <Field
                        name="phone"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {errors.phone && touched.phone && (
                        <div className="text-red-500 text-sm mt-1">{errors.phone}</div>
                      )}
                    </div>
                  </div>

                  {/* Address Fields */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Street')}</label>
                      <Field
                        name="address.street"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {errors.address?.street && touched.address?.street && (
                        <div className="text-red-500 text-sm mt-1">{errors.address.street}</div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Additional')}</label>
                      <Field
                        name="address.streetAdditional"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('ZIP')}</label>
                      <Field
                        name="address.zip"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {errors.address?.zip && touched.address?.zip && (
                        <div className="text-red-500 text-sm mt-1">{errors.address.zip}</div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Place')}</label>
                      <Field
                        name="address.place"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                      {errors.address?.place && touched.address?.place && (
                        <div className="text-red-500 text-sm mt-1">{errors.address.place}</div>
                      )}
                    </div>

                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700">{t('Country')}</label>
                      <CountrySelector
                        value={values.address?.country}
                        onChange={(newCode) => setFieldValue('address.country', newCode)}
                      />
                      {errors.address?.country && touched.address?.country && (
                        <div className="text-red-500 text-sm mt-1">{errors.address.country}</div>
                      )}
                    </div>
                  </div>

                  {/* Entry Month / Entry Year */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Entry Month')}</label>
                      <Field
                        name="entryMonth"
                        as="select"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      >
                        {[...Array(12)].map((_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('Entry Year')}</label>
                      <Field
                        name="entryYear"
                        type="number"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                      />
                    </div>
                  </div>

                  {/* Entry Round */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">{t('Entry Round')}</label>
                    <Field
                      name="entryRound"
                      as="select"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
                    >
                      <option value="Founding">Founding</option>
                      <option value="Pre-Seed">Pre-Seed</option>
                      <option value="Seed">Seed</option>
                      <option value="Series A">Series A</option>
                      <option value="Series B">Series B</option>
                      <option value="Series C">Series C</option>
                      <option value="Series C+">Series C+</option>
                    </Field>
                  </div>

                  {/* Buttons: Save as Draft or Save */}
                  <div className="flex justify-end space-x-2 pt-4">
                    <button
                      type="button"
                      onClick={() => onSaveAsDraft(values)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-color"
                    >
                      {t('Save as Draft')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="px-4 py-2 text-sm font-medium text-white bg-secondary-color border border-transparent rounded-md hover:bg-secondary-color/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-color disabled:opacity-50"
                    >
                      {t('Save')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ShareholderModal;