import React, { useState, useEffect } from 'react';
import { Dialog, Listbox } from '@headlessui/react';
import {
  XMarkIcon,
  ChevronUpDownIcon,
  CalendarIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
// Make sure you import the updated utils below:
import {
  getNextShareNumber,
  validateShareTransfer,
  extractShareBlocks,
  shareBlocksToString,
  parseShareBlocks
} from '../../utils/share-utils';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/**
 * TransactionForm
 */
const TransactionForm = ({
  isOpen,
  onClose,
  shareholders,
  onSubmit,
  companyName
}) => {
  const [type, setType] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [shares, setShares] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  // Reset when opened
  useEffect(() => {
    if (!isOpen) return;
    setType('');
    setSellerId('');
    setBuyerId('');
    setShares('');
    setDate(new Date().toISOString().split('T')[0]);
    setError('');
  }, [isOpen]);

  // Auto-assign for certain transaction types
  useEffect(() => {
    if (type === 'created') {
      // from nowhere => to company
      setSellerId('');
      setBuyerId('company');
    } else if (type === 'allocated') {
      // from company => to chosen
      setSellerId('company');
      setBuyerId('');
    } else if (type === 'bought') {
      // user picks both
      setSellerId('');
      setBuyerId('');
    }
  }, [type]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Basic validation
    const numShares = parseInt(shares, 10);
    if (isNaN(numShares) || numShares <= 0) {
      setError(t('Please enter a valid number of shares'));
      return;
    }

    try {
      let shareNumberGroup = '';

      if (type === 'created') {
        const buyer = shareholders.find((s) => s.id === buyerId);
        if (!buyer) {
          setError(t('Could not find the company shareholder record.'));
          return;
        }
        // Build new share range
        const allBlocks = shareholders
          .map((sh) => sh.shareNumbers)
          .filter(Boolean)
          .join(', '); // combine them
        const nextStart = getNextShareNumber(allBlocks); // from updated utils
        const end = nextStart + numShares - 1;
        shareNumberGroup = `${nextStart}-${end}`;
      }

      else if (type === 'allocated') {
        if (!buyerId || buyerId === 'company') {
          setError(t('Please select a valid buyer (not the company).'));
          return;
        }
        const seller = shareholders.find((sh) => sh.id === sellerId);
        const buyer = shareholders.find((sh) => sh.id === buyerId);
        if (!seller || !buyer) {
          setError(t('Could not find seller or buyer in the list.'));
          return;
        }

        // Validate that the company (seller) has enough shares
        const result = validateShareTransfer({
          sellerName: seller.name,
          sellerBlocks: seller.shareNumbers,
          buyerName: buyer.name,
          numberOfShares: numShares,
          t
        });
        if (!result.isValid) {
          setError(result.error);
          return;
        }

        // Extract from company
        const { extracted } = extractShareBlocks(seller.shareNumbers, numShares);
        shareNumberGroup = extracted;
      }

      else if (type === 'bought') {
        if (!sellerId) {
          setError(t('Please select a seller'));
          return;
        }
        if (!buyerId) {
          setError(t('Please select a buyer'));
          return;
        }
        if (sellerId === buyerId) {
          setError(t('Seller and buyer cannot be the same'));
          return;
        }
        const seller = shareholders.find((sh) => sh.id === sellerId);
        const buyer = shareholders.find((sh) => sh.id === buyerId);
        if (!seller || !buyer) {
          setError(t('Could not find seller or buyer in shareholders list'));
          return;
        }

        // Validate
        const result = validateShareTransfer({
          sellerName: seller.name,
          sellerBlocks: seller.shareNumbers,
          buyerName: buyer.name,
          numberOfShares: numShares,
          t
        });
        if (!result.isValid) {
          setError(result.error);
          return;
        }

        const { extracted } = extractShareBlocks(seller.shareNumbers, numShares);
        shareNumberGroup = extracted;
      }

      else {
        setError(t('Please select a transaction type'));
        return;
      }

      // All good => pass to parent
      await onSubmit({
        id: '', // up to you
        type,
        sellerId,
        buyerId,
        shares: numShares,
        date,
        shareNumberGroup
      });

      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const getShareholderName = (shareholderId) => {
    const sh = shareholders.find((s) => s.id === shareholderId);
    return sh ? sh.name : '';
  };

  const possibleSellers = shareholders;
  const possibleBuyers = shareholders;

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* BACKDROP */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* DIALOG CONTENT */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg">
          <div className="p-6 max-h-[80vh] overflow-y-auto">
            {/* Title + Close */}
            <div className="flex justify-between items-start">
              <Dialog.Title className="text-lg font-semibold">
                {t('Add New Transaction')}
              </Dialog.Title>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {/* FORM */}
            <form onSubmit={handleSubmit} className="mt-4 space-y-4">
              {/* Error Message */}
              {error && (
                <div className="bg-red-50 border border-red-200 text-red-800 rounded-md p-3">
                  {error}
                </div>
              )}

              {/* Transaction Type */}
              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">
                  {t('Transaction Type')}
                </label>
                <Listbox value={type} onChange={setType}>
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                      <span className="block truncate">
                        {type
                          ? type === 'created'
                            ? t('Shares created')
                            : type === 'allocated'
                              ? t('Shares allocated')
                              : t('Shares sold & bought')
                          : t('Select transaction type')}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        value="created"
                        className={({ focus }) =>
                          classNames(
                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                      >
                        {t('Shares created')}
                      </Listbox.Option>
                      <Listbox.Option
                        value="allocated"
                        className={({ focus }) =>
                          classNames(
                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                      >
                        {t('Shares allocated')}
                      </Listbox.Option>
                      <Listbox.Option
                        value="bought"
                        className={({ focus }) =>
                          classNames(
                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                      >
                        {t('Shares sold & bought')}
                      </Listbox.Option>
                    </Listbox.Options>
                  </div>
                </Listbox>
              </div>

              {/* Seller (only for bought) */}
              {type === 'bought' && (
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Seller')}
                  </label>
                  <Listbox value={sellerId} onChange={setSellerId}>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                        <span className="block truncate">
                          {sellerId
                            ? getShareholderName(sellerId)
                            : t('Select seller')}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {possibleSellers.map((s) => (
                          <Listbox.Option
                            key={s.id}
                            value={s.id}
                            className={({ focus }) =>
                              classNames(
                                focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9'
                              )
                            }
                          >
                            {({ selected, focus }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {s.name}
                                </span>
                                {selected && (
                                  <span
                                    className={classNames(
                                      focus ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                )}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
              )}

              {/* Buyer (for allocated or bought) */}
              {(type === 'allocated' || type === 'bought') && (
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Buyer')}
                  </label>
                  <Listbox value={buyerId} onChange={setBuyerId}>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                        <span className="block truncate">
                          {buyerId
                            ? getShareholderName(buyerId)
                            : t('Select buyer')}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {possibleBuyers
                          .filter((s) => s.id !== sellerId)
                          .map((s) => (
                            <Listbox.Option
                              key={s.id}
                              value={s.id}
                              className={({ focus }) =>
                                classNames(
                                  focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                              }
                            >
                              {({ selected, focus }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected ? 'font-semibold' : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {s.name}
                                  </span>
                                  {selected && (
                                    <span
                                      className={classNames(
                                        focus ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
              )}

              {/* No dropdown for "created" => auto company */}

              {/* Number of Shares */}
              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">
                  {t('Share Amount')}
                </label>
                <input
                  type="number"
                  value={shares}
                  onChange={(e) => setShares(e.target.value)}
                  min="1"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Date */}
              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">
                  {t('Date')}
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <CalendarIcon className="absolute right-3 top-2.5 h-4 w-4 text-gray-500" />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-2 pt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Cancel')}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] border border-transparent rounded-md hover:bg-[#2A9D8F]/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('Add Transaction')}
                </button>
              </div>
            </form>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default TransactionForm;