const CollectionDetailsCard = ({ collection, template, userLang, t }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };
 
  const getAllUsers = () => {
 
    if (collection.collection_type === 'collaborative') {
      // Get active users from current assignments
      const activeUsers = new Set();
      Object.values(collection.part_assignments || {}).forEach(assignment => {
        (assignment.editors || []).forEach(editor => activeUsers.add(editor));
        (assignment.submitters || []).forEach(submitter => activeUsers.add(submitter));
      });
 
      // Convert active users to array format with names
      const activeUsersList = Array.from(activeUsers).map(userId => {
        const user = collection.respondents.find(r => r.id === userId);
        return user ? {
          id: userId,
          user_name: user.user_name,
          was_deleted: false
        } : null;
      }).filter(Boolean);
 
      // Get deleted users from deleted_assignments
      const deletedUsers = [];
      Object.values(collection.deleted_assignments || {}).forEach(assignments => {
        ['editors', 'submitters'].forEach(role => {
          (assignments?.[role] || []).forEach(user => {
            if (!deletedUsers.some(u => u.id === user.id)) {
              deletedUsers.push({
                id: user.id,
                user_name: user.name,
                was_deleted: true
              });
            }
          });
        });
      });
 
      return [...activeUsersList, ...deletedUsers];
    } else {
      // For individual collections
      // Get active users from respondents
      const activeUsers = collection.respondents.map(user => ({
        id: user.id,
        user_name: user.user_name,
        was_deleted: false
      }));
 
      // Get deleted users
      const deletedUsers = Object.values(collection.deleted_assignments || {})
        .flatMap(assignments => assignments.assignees || [])
        .map(user => ({
          id: user.id,
          user_name: user.name,
          was_deleted: true
        }));

      return [...activeUsers, ...deletedUsers];
    }
  };
 
  const allUsers = getAllUsers();
 
  return (
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      {/* Basic Collection Info */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500">{t('Collection Period')}</h3>
          <p className="mt-1 text-base text-gray-900">{collection.period_value}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500">{t('Started')}</h3>
          <p className="mt-1 text-base text-gray-900">{formatDate(collection.starts_at)}</p>
        </div>
      </div>
 
      {/* Viewers & Respondents */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-t border-gray-200 pt-6">
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Report Viewers')}</h3>
          <ul className="space-y-1">
            {collection.viewers.map((viewer) => (
              <li key={viewer.id} className="text-sm text-gray-600">
                {viewer.user_name}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Data Sources')}</h3>
          <ul className="space-y-1">
            {allUsers.map((user) => (
              <li 
                key={user.id} 
                className={`text-sm ${user.was_deleted ? 'text-gray-500 italic' : 'text-gray-600'}`}
              >
                {user.user_name}{user.was_deleted ? ` (${t('deleted')})` : ''}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
 };
 
 export default CollectionDetailsCard;