// src/components/FlowChart/nodes/ActorNode.js
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ActorNode = ({ data, selected }) => {
    
  return (
    <div 
      className={`bg-purple-50 border rounded-md p-3 w-44 shadow-sm transition-all 
        ${selected ? 'border-purple-500 ring-2 ring-purple-200' : 'border-purple-300'}`}
    >
      <Handle 
        type="target" 
        position={Position.Top} 
        className="!bg-purple-500 !border-purple-700"
      />
      
      <div className="flex items-center">
        <UserCircleIcon className="h-5 w-5 text-purple-700 mr-1 flex-shrink-0" />
        <div className="font-medium text-sm text-purple-900 truncate">
          {data.label || 'Actor'}
        </div>
      </div>
      
      {data.description && (
        <div className="text-xs text-purple-700 mt-1 truncate">
          {data.description}
        </div>
      )}
      
      <Handle 
        type="source" 
        position={Position.Bottom} 
        className="!bg-purple-500 !border-purple-700"
      />
      
      <Handle 
        type="source" 
        position={Position.Right} 
        className="!bg-purple-500 !border-purple-700"
      />
      
      <Handle 
        type="target" 
        position={Position.Left} 
        className="!bg-purple-500 !border-purple-700"
      />
    </div>
  );
};

export default memo(ActorNode);