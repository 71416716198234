import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const LongTextField = ({ label = '', placeholder = '', rows = 6, maxWidth = '100%', ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="col-span-full" style={{ maxWidth }}>
      {label && (
        <label htmlFor={props.id || props.name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          {...field}
          {...props}
          rows={rows}
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6 resize-none"
          style={{ overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        ></textarea>
      </div>
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

LongTextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  maxWidth: PropTypes.string,
};

export default LongTextField;
