import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const CashflowChart = ({ data, currency }) => {
  const years = data.map(item => item.year);
  const { t } = useTranslation();
  
  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: true
      }
    },
    stroke: {
      width: [0, 0, 0, 4], // Three bars (0 width) and one area curve
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    fill: {
      opacity: [0.85, 0.85, 0.85, 0.25],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: years,
    markers: {
      size: 0
    },
    xaxis: {
      type: 'category',
      title: {
        text: 'Year'
      }
    },
    yaxis: {
      title: {
        text: currency,
      },
      min: 0,
      labels: {
        formatter: (value) => {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value);
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value);
        }
      }
    },
    colors: ['#00e396', '#ff4560', '#feb019', '#008ffb'], // green, red, yellow, blue
    legend: {
      position: 'top'
    }
  };

  const series = [
    {
      name: t('Revenue'),
      type: 'column',
      data: data.map(item => item.revenue)
    },
    {
      name: t('Expenses'),
      type: 'column',
      data: data.map(item => item.expenses)
    },
    {
      name: t('Investments'),
      type: 'column',
      data: data.map(item => item.investments)
    },
    {
      name: t('Capital'),
      type: 'area',
      data: data.map(item => item.capital)
    }
  ];

  return (
    <div className="w-full bg-white rounded-lg shadow p-4">
    <div className="w-full">
      <Chart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
    </div>
  );
};

CashflowChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      expenses: PropTypes.number.isRequired,
      revenue: PropTypes.number.isRequired,
      investments: PropTypes.number.isRequired,
      capital: PropTypes.number.isRequired,
    })
  ).isRequired,
  currency: PropTypes.string.isRequired,
};

export default CashflowChart;