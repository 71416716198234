import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import TextField from '../../../components/wizard_components/TextField';
import Radiothumbs from '../../../components/wizard_components/Radiothumbs';
import Checkmark from '../../../components/wizard_components/Checkmark';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { IoTrashOutline } from "react-icons/io5";
import { IoBagAddSharp } from "react-icons/io5";
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/funding/team');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);
  const [dataStatus, setDataStatus] = useState('new');
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({
    products: [
      {
        name: '',
        description: '',
        usp: [],
        devStage: '',
        month: 'January',
        year: 2024,
      },
    ],
  });

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        products: draft?.products || []
      };
      const relevantPublishedData = {
        products: published?.products || []
      };

      const isDataEmpty = (data) => {
        return Object.keys(data).length === 0 ||
          Object.values(data).every(value =>
            value === '' ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0)
          );
      };

      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues(relevantDraftData);
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues(relevantPublishedData);
      } else {
        setDataStatus('new');
        setInitialValues({
          products: [
            {
              name: '',
              description: '',
              usp: [],
              devStage: '',
              month: 'January',
              year: new Date().getFullYear(),
            },
          ],
        });
      }
    }
  }, [userData]);

  if (userDataLoading || schemaLoading) {
    return <div>{t('Loading...')}</div>;
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const years = Array.from({ length: 61 }, (_, i) => i + 1970);

  const devStageSvgs = {
    "Product/Services in development": {
      default: '/thumbs/InDevelopment_devstage.svg',
      hover: '/thumbs/InDevelopment_devstage_hover.svg',
      active: '/thumbs/InDevelopment_devstage_active.svg'
    },
    "Proof of concept for Product/Services launched": {
      default: '/thumbs/ProofOfConcept_devstage.svg',
      hover: '/thumbs/ProofOfConcept_devstage_hover.svg',
      active: '/thumbs/ProofOfConcept_devstage_active.svg'
    },
    "Product/Services fully on-the-market": {
      default: '/thumbs/OnMarket_devstage.svg',
      hover: '/thumbs/OnMarket_devstage_hover.svg',
      active: '/thumbs/OnMarket_devstage_active.svg'
    }
  };

  const validationSchema = Yup.object({
    products: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('KnowYourselfWizard.productNameRequired')),
        description: Yup.string()
          .required(t('KnowYourselfWizard.descriptionRequired')),
        usp: Yup.array()
          .min(1, t('KnowYourselfWizard.uspRequired'))
          .required(t('KnowYourselfWizard.uspRequired')),
        devStage: Yup.string()
          .required(t('KnowYourselfWizard.devStageRequired')),
        month: Yup.string()
          .required(t('KnowYourselfWizard.monthRequired')),
        year: Yup.number()
          .required(t('KnowYourselfWizard.yearRequired'))
      })
    ).min(1, t('KnowYourselfWizard.minimumOneProduct'))
  });

  const getTranslatedUSPs = () => {
    return schema.properties.usp.items.enum.map((usp) => ({
      value: usp,
      label: t(`KnowYourselfWizard.${usp}`),
      description: t(`KnowYourselfWizard.${usp}Description`)
    }));
  };

  const getTranslatedDevStages = () => {
    return schema.properties.devStage.enum.map((devStage) => ({
      value: devStage,
      label: t(`KnowYourselfWizard.${devStage}`),
      svg: devStageSvgs[devStage]
    }));
  };

  const handleProductExpand = (index) => {
    setExpandedIndex(index);
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: values });

      setTimeout(() => {
        navigate('/funding/team');
      }, 1000);
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: 'Failed to save data',
        errors: [error.message]
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
      <Notification
        show={notification.show}
        setShow={(show) => setNotification({ ...notification, show })}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <Progress />
      <div className="data-status-banner mt-4 mb-4">
        {dataStatus === 'published' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is the last saved and published version.')}
          </span>
        )}
        {dataStatus === 'draft' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
          </span>
        )}
        {dataStatus === 'new' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('There is no prior version of this page. Please manually save or save as draft below.')}
          </span>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, validateForm }) => {
          const handleSubmit = async (e) => {
            e.preventDefault();
            const errors = await validateForm(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values, { setSubmitting: () => { } });
            } else {
              // Transform nested errors into flat error messages
              const errorMessages = [];

              // Handle products array errors
              if (errors.products) {
                errors.products.forEach((productError, index) => {
                  if (typeof productError === 'object') {
                    Object.entries(productError).forEach(([field, message]) => {
                      const fieldTranslations = {
                        name: t('KnowYourselfWizard.productName'),
                        description: t('KnowYourselfWizard.productDescription'),
                        usp: t('KnowYourselfWizard.usp'),
                        devStage: t('KnowYourselfWizard.developmentStage'),
                        month: t('KnowYourselfWizard.month'),
                        year: t('KnowYourselfWizard.year')
                      };

                      const fieldName = fieldTranslations[field] || field;
                      errorMessages.push(`${t('KnowYourselfWizard.product')} ${index + 1} - ${fieldName}: ${message}`);
                    });
                  }
                });
              }

              setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save data due to validation errors:'),
                errors: errorMessages
              });
            }
          };

          return (
            <Form onSubmit={handleSubmit}>
              <h2 className="text-primary-color">{t('Products and Services')}</h2>
              <p className="mt-2 mb-6 text-gray-600">
                {t("Please provide information about your main product groups and service categories. This is not a sales page - you don't have to provide detailed information for each and every product. If you have a big portfolio of products and services, group them for easier management.")}
              </p>
              {/* Products and Services Recommendations */}
              <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setRecommendationsOpen(!recommendationsOpen);
                  }}
                  className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center">
                    <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                    <span className="text-primary-color">{t('Recommendations')}</span>
                  </div>
                  <svg
                    className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {recommendationsOpen && (
                  <div className="p-4 bg-white rounded-b-lg border-t">
                    <div className="space-y-4 text-gray-700">
                      <div>
                        <h4 className="text-sm font-semibold text-gray-900">
                          {t('productsRecommendations.sections.customerInsightsTitle')}
                        </h4>
                        <p className="text-sm">
                          {t('productsRecommendations.sections.customerInsightsContent')}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-semibold text-gray-900">
                          {t('productsRecommendations.sections.productGroupingTitle')}
                        </h4>
                        <p className="text-sm">
                          {t('productsRecommendations.sections.productGroupingContent')}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-semibold text-gray-900">
                          {t('productsRecommendations.sections.productReadinessTitle')}
                        </h4>
                        <p className="text-sm">
                          {t('productsRecommendations.sections.productReadinessContent')}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-semibold text-gray-900">
                          {t('productsRecommendations.sections.timelinesTitle')}
                        </h4>
                        <p className="text-sm">
                          {t('productsRecommendations.sections.timelinesContent')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {values.products.map((product, index) => (
                <div key={index} className={`border rounded-lg p-8 mb-4 ${expandedIndex === index ? 'bg-white' : 'bg-white'}`}>
                  {expandedIndex === index ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold">{product.name || t('Product/Service (Group) Name')}</h3>
                        <button
                          type="button"
                          className="text-red-500 hover:text-red-700 transition-colors duration-200"
                          aria-label={t('Remove')}
                          onClick={() => {
                            const newProducts = values.products.filter((_, i) => i !== index);
                            setFieldValue('products', newProducts);
                          }}
                        >
                          <IoTrashOutline className="w-5 h-5" />
                        </button>
                      </div>
                      <div className="mt-8">
                        <label className="block text-sm font-medium text-gray-700">{t('Product/Service (Group) Name')}</label>
                        <Field name={`products.${index}.name`} className="mt-1 block w-full border-gray-300 rounded-md" />
                      </div>
                      <div className="mt-8">
                        <label className="block text-sm font-medium text-gray-700">{t('Description of Product/Service (of this Group)')}</label>
                        <Field as="textarea" name={`products.${index}.description`} className="mt-1 block w-full border-gray-300 rounded-md" />
                      </div>
                      <div className="mt-8">
                        <label className="block text-sm font-medium text-gray-700 mb-4">{t('Unique Selling Proposition (USP)')}</label>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {getTranslatedUSPs().map(option => (
                            <Checkmark
                              key={option.value}
                              id={option.value}
                              name={`products.${index}.usp`}
                              label={option.label}
                              description={option.description}
                              checked={values.products[index].usp?.includes(option.value) ?? false}
                              onChange={() => {
                                const newUSP = values.products[index].usp?.includes(option.value)
                                  ? values.products[index].usp.filter(val => val !== option.value)
                                  : [...(values.products[index].usp ?? []), option.value];
                                setFieldValue(`products.${index}.usp`, newUSP);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="mt-10">
                        <label className="block text-sm font-medium text-gray-700">{t('Development Stage')}</label>
                        <Radiothumbs
                          name={`products.${index}.devStage`}
                          options={getTranslatedDevStages()}
                          value={values.products[index].devStage}
                          onChange={(event) => {
                            setFieldValue(`products.${index}.devStage`, event.target.value);
                          }}
                        />
                      </div>
                      <label className="mt-6 block text-sm font-medium text-gray-700">{t('Release Date')}</label>
                      <div className="mt-2 mb-4 grid grid-cols-4 gap-4">
                        <div>
                          <label className="block text-xs font-medium text-gray-700">{t('Month')}</label>
                          <Field as="select" name={`products.${index}.month`} className="mt-1 block w-full border-gray-300 rounded-md">
                            {months.map(month => (
                              <option key={month} value={month}>{t(month)}</option>
                            ))}
                          </Field>
                        </div>
                        <div>
                          <label className="block text-xs font-medium text-gray-700">{t('Year')}</label>
                          <Field as="select" name={`products.${index}.year`} className="mt-1 block w-full border-gray-300 rounded-md">
                            {years.map(year => (
                              <option key={year} value={year}>{year}</option>
                            ))}
                          </Field>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="cursor-pointer" onClick={() => handleProductExpand(index)}>
                      <h3 className="text-lg font-semibold">{product.name || `${t('Product/Service (Group) Name')} ${index + 1}`}</h3>
                    </div>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="group relative flex flex-col items-center justify-center w-full h-[200px] rounded-lg border-2 border-dashed border-gray-300 bg-white text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-secondary-color focus:ring-offset-2"
                onClick={() => {
                  const newProductIndex = values.products.length;
                  setFieldValue('products', [
                    ...values.products,
                    { name: '', description: '', usp: [], devStage: '', month: 'January', year: 2022 }
                  ]);
                  handleProductExpand(newProductIndex);
                }}
              >
                <IoBagAddSharp className="text-5xl text-primary-color group-hover:text-secondary-color transition-colors duration-200 mb-2" />
                <span className="block text-sm font-semibold text-primary-color group-hover:text-secondary-color transition-colors duration-200">
                  {t('Create a new product/service')}
                </span>
              </button>
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  className="text-sm text-gray-900"
                  onClick={() => navigate('/funding')}
                >
                  {t('cancel')}
                </button>
                <button
                  type="button"
                  onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                  disabled={isSubmitting}
                  className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  {t('Save as Draft')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                >
                  {isSubmitting ? t('saving') : t('save')}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
    </>
  );
};

export default KnowYourselfWizardProduct;