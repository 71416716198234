import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from '../axios';

// Create the context
export const AlertsContext = createContext();

// Create the provider component
export const AlertsProvider = ({ children }) => {
  const [activeAlerts, setActiveAlerts] = useState([]);
  const [alerts, setAlerts] = useState({
    active: [],
    upcoming: [],
    past: []
  });
  const [alertsCount, setAlertsCount] = useState(() => {
    const savedCount = localStorage.getItem('alertsCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    localStorage.setItem('alertsCount', alertsCount.toString());
  }, [alertsCount]);

  // Define fetchAlerts first
  const fetchAlerts = useCallback(async (forceRefresh = false) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }
    
    setLoading(true);
    
    try {
      const response = await axios.get('/api/v1/alerts', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setAlerts(response.data);
      const activeAlertsData = response.data.active || [];
      setActiveAlerts(activeAlertsData);
      
      // Always update the count with the current active alerts count
      const currentActiveCount = activeAlertsData.length;
      
      // Only trigger animation if the count increased
      if (currentActiveCount > alertsCount && !forceRefresh) {
        setShouldAnimate(true);
        setTimeout(() => setShouldAnimate(false), 1000);
      }
      
      // Always update count regardless of forceRefresh
      setAlertsCount(currentActiveCount);
      
      if (initialLoading) {
        setInitialLoading(false);
      }
    } catch (error) {
      console.error('Error fetching alerts:', error);
    } finally {
      setLoading(false);
    }
  }, [alertsCount, initialLoading]);

  // Then use it in your useEffect
  useEffect(() => {
    // Fetch alerts as soon as the app loads on any page
    fetchAlerts();
  }, [fetchAlerts]);

  useEffect(() => {
    // Polling every 5 minutes.
    const interval = setInterval(() => {
      fetchAlerts();
    }, 5 * 60 * 1000);
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchAlerts();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    const handleStorageChange = (event) => {
      if (event.key === 'authToken' && event.newValue) {
        setTimeout(() => fetchAlerts(true), 500);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [fetchAlerts]);

  const acknowledgeAlert = async (alertId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found when acknowledging alert');
        return false;
      }
      await axios.post(`/api/v1/alerts/${alertId}/acknowledge`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAlerts(true);
      return true;
    } catch (error) {
      console.error('Error acknowledging alert:', error);
      return false;
    }
  };

  const preAcknowledgeAlert = async (alertId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found when pre-acknowledging alert');
        return false;
      }
      await axios.post(`/api/v1/alerts/${alertId}/pre_acknowledge`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAlerts(true);
      return true;
    } catch (error) {
      console.error('Error pre-acknowledging alert:', error);
      return false;
    }
  };

  const deleteAlert = async (alertId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found when deleting alert');
        return false;
      }
      await axios.delete(`/api/v1/alerts/${alertId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchAlerts(true);
      return true;
    } catch (error) {
      console.error('Error deleting alert:', error);
      return false;
    }
  };

    // Add a manual fetch method that can be called directly after login
    const manualFetchAlerts = () => {
      console.log('Manually Triggering Alerts Fetch');
      fetchAlerts(true);
    };
  
    useEffect(() => {
      fetchAlerts();
    }, [fetchAlerts]);

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        activeAlerts,
        alertsCount,
        loading,
        initialLoading,
        shouldAnimate,
        fetchAlerts,
        manualFetchAlerts,
        acknowledgeAlert,
        preAcknowledgeAlert,
        deleteAlert,
        resetAlerts: () => {
          setAlertsCount(0);
          setShouldAnimate(false);
        }
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => useContext(AlertsContext);