import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from '../../axios';

const WizardContext = createContext();

export const useWizard = () => useContext(WizardContext);

export const WizardProvider = ({ children, wizardName }) => {
  const [wizardData, setWizardData] = useState({
    published: {},
    draft: {},
  });
  const [currentSection, setCurrentSection] = useState('introduction');
  const [liveMode, setLiveMode] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      console.log(`Fetching fresh data for ${wizardName} wizard`);
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      if (response.data.entity[`${wizardName}_wizard_response`]) {
        // Add timestamp to track when the data was last fetched
        const wizardDataWithTimestamp = {
          ...response.data.entity[`${wizardName}_wizard_response`],
          lastFetched: new Date().getTime()
        };
        setWizardData(wizardDataWithTimestamp);
        return true; // Indicate successful fetch
      }
      return false; // Indicate no data was found
    } catch (error) {
      console.error(`Error fetching ${wizardName} wizard data:`, error);
      throw error; // Allow error to be handled by caller
    }
  }, [wizardName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateWizardData = useCallback(async (section, data, isDraft = false) => {
    const newData = {
      ...wizardData,
      [isDraft ? 'draft' : 'published']: {
        ...wizardData[isDraft ? 'draft' : 'published'],
        [section]: { ...wizardData[isDraft ? 'draft' : 'published'][section], ...data },
      },
    };

    setWizardData(newData);

    if (liveMode || !isDraft) {
      try {
        await axios.post(`/api/v1/wizards/${wizardName}${isDraft ? '/draft' : ''}`, {
          responses: { [section]: data }
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
      } catch (error) {
        console.error(`Error updating ${wizardName} wizard data:`, error);
      }
    }
  }, [wizardData, wizardName, liveMode]);

  const resetSection = useCallback(async (section) => {
    const newData = {
      ...wizardData,
      published: {
        ...wizardData.published,
        [section]: null,
      },
      draft: {
        ...wizardData.draft,
        [section]: null,
      },
    };

    setWizardData(newData);

    try {
      await axios.post(`/api/v1/wizards/${wizardName}/reset`, { pageId: section }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
    } catch (error) {
      console.error(`Error resetting ${wizardName} wizard section:`, error);
      // Optionally, revert the local state if the server update fails
      // setWizardData(wizardData);
    }
  }, [wizardData, wizardName]);

  const toggleLiveMode = useCallback((enabled) => {
    setLiveMode(enabled);
  }, []);  

  return (
    <WizardContext.Provider
      value={{
        wizardData,
        updateWizardData,
        resetSection,
        currentSection,
        setCurrentSection,
        wizardName,
        refetchData: fetchData,
        liveMode,
        toggleLiveMode,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

export default WizardContext;