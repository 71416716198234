// src/pages/wizards/DataPrivacyWizard/DataPrivacyWizardTransfers.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { useUserData } from '../../../hooks/useUserData';
import { getThirdCountriesContent } from '../../../components/wizard_components/DataPrivacyWizard/getThirdCountriesContent';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';

// Import subcomponents
import TransfersSummary from '../../../components/wizard_components/DataPrivacyWizard/TransfersSummary';
import RecipientsTable from '../../../components/wizard_components/DataPrivacyWizard/RecipientsTable';

// Helper functions
import {
  extractUniqueRecipients,
  isThirdCountry,
  getComplianceStatus,
} from '../../../components/wizard_components/DataPrivacyWizard/transfersHelpers';

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const DataPrivacyWizardTransfers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
  const { data: transfersData, dataStatus, loading: transfersLoading, error: transfersError } = useFetchData(wizardName, 'transfers');
  const { data: ropaData, loading: ropaLoading, error: ropaError } = useFetchData(wizardName, 'ropa');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
  const [countries, setCountries] = useState([]);
  const { userData } = useUserData();
  const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

  // Track expanded and editing states
  const [expandedRows, setExpandedRows] = useState({
    internal: {},
    external: {}
  });
  const [editingRows, setEditingRows] = useState({
    internal: {},
    external: {}
  });

  // Extract unique recipients from processing activities
  const [internalRecipients, setInternalRecipients] = useState([]);
  const [externalRecipients, setExternalRecipients] = useState([]);

  const processorRoles = [
    {
      value: 'subprocessor',
      label: t('DataPrivacyWizard.Subprocessor')
    },
    {
      value: 'authority',
      label: t('DataPrivacyWizard.Authority')
    },
    {
      value: 'other',
      label: t('DataPrivacyWizard.Other')
    }
  ];

  const dpaStatusOptions = [
    { value: 'signed', label: t('DataPrivacyWizard.Signed'), color: 'bg-green-100 text-green-800' },
    { value: 'pending', label: t('DataPrivacyWizard.Pending'), color: 'bg-yellow-100 text-yellow-800' },
    { value: 'not_required', label: t('DataPrivacyWizard.NotRequired'), color: 'bg-gray-100 text-gray-800' },
    { value: 'missing', label: t('DataPrivacyWizard.Missing'), color: 'bg-red-100 text-red-800' }
  ];

  // Fetch countries data
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('/api/v1/static_data/countries');
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Prepare initial values based on the ROPA data and any existing transfers data
  useEffect(() => {
    if (!ropaLoading && !transfersLoading && ropaData) {
      // Extract unique recipients from processing activities
      const internal = extractUniqueRecipients(ropaData.processingActivities, 'internal');
      const external = extractUniqueRecipients(ropaData.processingActivities, 'external');

      setInternalRecipients(internal);
      setExternalRecipients(external);

      // Initialize formik with existing data or create default structure
      if (!transfersData || !transfersData.internalTransfers) {
        const initialInternalTransfers = internal.map(recipient => ({
          id: recipient.id,
          name: recipient.name,
          country: userData?.entity?.country || '',
          multipleCountries: false,
          thirdCountries: false,
          complianceMechanism: 'none',
          complianceNotes: ''
        }));

        const initialExternalTransfers = external.map(recipient => ({
          id: recipient.id,
          name: recipient.name,
          processorRole: 'subprocessor',
          country: userData?.entity?.country || '',
          multipleCountries: false,
          thirdCountries: false,
          dpaStatus: 'missing',
          dpaLink: '',
          complianceMechanism: 'none',
          complianceNotes: ''
        }));

        formik.setValues({
          internalTransfers: initialInternalTransfers,
          externalTransfers: initialExternalTransfers
        });
      }
    }
  }, [ropaLoading, transfersLoading, ropaData, transfersData, userData]);

  useEffect(() => {
    setCurrentSection('transfers');
  }, [setCurrentSection]);

  // Initialize with empty or existing transfers data
  const initialValues = transfersData || {
    internalTransfers: [],
    externalTransfers: []
  };

  // Validation schema
  const validationSchema = Yup.object({
    internalTransfers: Yup.array().of(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
        country: Yup.string().nullable(),
        multipleCountries: Yup.boolean(),
        thirdCountries: Yup.boolean(),
        complianceMechanism: Yup.string(),
        complianceNotes: Yup.string()
      })
    ),
    externalTransfers: Yup.array().of(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
        processorRole: Yup.string().required(),
        country: Yup.string().nullable(),
        multipleCountries: Yup.boolean(),
        thirdCountries: Yup.boolean(),
        dpaStatus: Yup.string().required(),
        dpaLink: Yup.string().url().nullable(),
        complianceMechanism: Yup.string(),
        complianceNotes: Yup.string()
      })
    )
  });

  // Initialize Formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await saveData(wizardName, 'transfers', values);
        if (result.success) {
          setNotification({
            show: true,
            type: 'success',
            message: t('DataPrivacyWizard.dataSaved'),
          });
          updateWizardData('transfers', values, false);
          updateWizardData('transfers', values, true);
          setTimeout(() => {
            navigate('/data-privacy-wizard/technical-measures');
          }, 1000);
        } else {
          throw new Error(result.message || 'Error saving data');
        }
      } catch (error) {
        console.error('Error saving data:', error);
        setNotification({
          show: true,
          type: 'error',
          message: t('DataPrivacyWizard.errorSaving'),
          errors: error.message ? [error.message] : []
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode) {
      const result = await saveAsDraft(wizardName, 'transfers', formik.values);
      if (result.success) {
        updateWizardData('transfers', formik.values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, formik.values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = React.useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and formik.values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, formik.values]);

  const handleDraftSubmit = async () => {
    try {
      const result = await saveAsDraft(wizardName, 'transfers', formik.values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('DataPrivacyWizard.draftSaved'),
        });
        updateWizardData('transfers', formik.values, true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('DataPrivacyWizard.errorSavingDraft'),
        errors: [error.message],
      });
    }
  };

  const toggleDrawer = (title, content) => {
    if (drawerOpen && drawerContent.title === title) {
      setDrawerOpen(false);
    } else {
      // Special handling for third countries drawer
      if (title === t('DataPrivacyWizard.thirdCountriesInfo.title')) {
        const htmlContent = getThirdCountriesContent(countries, t);
        setDrawerContent({
          title,
          content: htmlContent
        });
      } else {
        setDrawerContent({
          title,
          content: `<div class="prose">${content}</div>`
        });
      }
      setDrawerOpen(true);
    }
  };

  const toggleRow = (type, id) => {
    setExpandedRows(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: !prev[type][id]
      }
    }));
  };

  const toggleEditMode = (type, id) => {
    setEditingRows(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: !prev[type][id]
      }
    }));
  };

  if (ropaLoading || transfersLoading) return <div>{t('Loading...')}</div>;
  if (ropaError) return <div>Error loading ROPA data: {ropaError.message}</div>;
  if (transfersError) return <div>Error loading transfers data: {transfersError.message}</div>;

  // Prepare data to pass to child components
  const commonProps = {
    formik,
    countries,
    toggleDrawer,
    toggleRow,
    toggleEditMode,
    expandedRows,
    editingRows,
    isThirdCountry: (countryCode) => isThirdCountry(countryCode, countries),
    userLang
  };

  const internalProps = {
    ...commonProps,
    recipientsData: internalRecipients,
    transfersData: formik.values.internalTransfers,
    type: 'internal',
    title: t('DataPrivacyWizard.internalRecipients')
  };

  const externalProps = {
    ...commonProps,
    recipientsData: externalRecipients,
    transfersData: formik.values.externalTransfers,
    type: 'external',
    title: t('DataPrivacyWizard.externalRecipients'),
    processorRoles,
    dpaStatusOptions
  };

  const summaryProps = {
    internalTransfers: formik.values.internalTransfers,
    externalTransfers: formik.values.externalTransfers,
    countries,
    userLang,
    internalRecipients,
    externalRecipients
  };

  return (
    <div className="container mx-auto p-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <LiveModeToggle onToggle={toggleLiveMode} />
        <div className="mt-10">
          <DataPrivacyWizardProgress />
        </div>
        <Notification
          show={notification.show}
          setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
          type={notification.type}
          message={notification.message}
        />
        <DataStatusBanner status={dataStatus} />

        <h1 className="text-3xl font-bold mb-6">{t('DataPrivacyWizard.dataTransfers')}</h1>

        <div className="mb-6">
          <p className="text-gray-700">
            {t('DataPrivacyWizard.dataTransfersDescription')}
          </p>
        </div>

        <FormikProvider value={formik}>
          <Form>
            {/* Internal Recipients Table */}
            <div className="mb-8">
              <RecipientsTable {...internalProps} />
            </div>

            {/* External Recipients Table */}
            <div className="mb-8">
              <RecipientsTable {...externalProps} />
            </div>

            {/* Summary Section */}
            <TransfersSummary {...summaryProps} />

            <div className="mt-8">
              <ActionButtons
                onDraft={handleDraftSubmit}
                onSubmit={formik.handleSubmit}
                isSubmitting={formik.isSubmitting}
                currentPage="transfers"
              />
            </div>
          </Form>
        </FormikProvider>
      </div>

      <WizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

export default DataPrivacyWizardTransfers;