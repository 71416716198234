import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import { saveData } from '../../../components/wizard_components/SaveData';
import useFetchData from '../../../components/wizard_components/FetchData';
import Confetti from '../../../components/wizard_components/Confetti';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import CreateSystemTaskButton from '../../../components/CreateSystemTaskButton';
import DisclaimerCheck from '../../../components/wizard_components/DisclaimerCheck';
import StartupWizardFinalCheck from '../../../components/wizard_components/StartupWizardFinalCheck';

const StartupWizardFinal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, wizardData, updateWizardData } = useWizard();
  const [expandedSections, setExpandedSections] = useState({});
  const [drawerContent, setDrawerContent] = useState({ isOpen: false, title: '', content: '' });
  const { data, dataStatus } = useFetchData(wizardName, 'final');

  const pages = useMemo(() => [
    { id: "legalForm", name: t('StartupWizard.legalForm') },
    { id: "company", name: t('StartupWizard.company') },
    { id: "founders", name: t('StartupWizard.founders') },
    { id: "articlesOfAssociation", name: t('StartupWizard.articlesOfAssociation') },
    { id: "shareholdersAgreement", name: t('StartupWizard.shareholdersAgreement') },
    { id: "notaryAndBank", name: t('StartupWizard.notaryAndBank') }
  ], [t]);

  // Function to check page status (similar to overview)
  const getPageStatus = useCallback((pageId) => {
    const publishedData = wizardData.published[pageId];
    const draftData = wizardData.draft[pageId];

    const isPublishedEmpty = !publishedData || Object.keys(publishedData).length === 0;
    const isDraftEmpty = !draftData || Object.keys(draftData).length === 0;
    const isDraftDifferent = JSON.stringify(publishedData) !== JSON.stringify(draftData);

    if (isPublishedEmpty && isDraftEmpty) {
      return 'empty';
    } else if (isDraftDifferent) {
      return 'draft';
    }
    return 'complete';
  }, [wizardData]);

  const companyName = wizardData.published?.company?.companyName || '';

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleFinish = async () => {
    try {
      const result = await saveData(wizardName, 'final', { final: 'Finished' });
      if (result.success) {
        updateWizardData('final', { final: 'Finished' }, false);
        navigate('/startup-wizard');
      }
    } catch (error) {
      console.error('Error saving completion status:', error);
    }
  };

  const Collapsible = ({ title, children, id }) => {
    const isExpanded = expandedSections[id];

    // Convert text with bullet points into proper list items
    const formatContent = (content) => {
      if (typeof content !== 'string') return content;

      const lines = content.split('\n');
      return lines.map((line, index) => {
        if (line.trim().startsWith('-')) {
          return (
            <li key={index} className="ml-6 list-disc">
              {line.replace('-', '').trim()}
            </li>
          );
        }
        return <p key={index}>{line}</p>;
      });
    };

    return (
      <div className="mb-1">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 text-large font-normal text-gray-900 bg-white hover:bg-gray-50"
          onClick={() => toggleSection(id)}
        >
          <span>{title}</span>
          <svg
            className={`w-3 h-3 shrink-0 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
          </svg>
        </button>
        {isExpanded && (
          <div className="px-5 py-4 bg-white">
            {formatContent(children)}
          </div>
        )}
      </div>
    );
  };


  const toggleDrawer = (title, content) => {
    // If drawer is already open with this title, close it
    if (drawerContent.isOpen && drawerContent.title === title) {
      setDrawerContent({
        ...drawerContent,
        isOpen: false
      });
    } else {
      // Open drawer with new content
      setDrawerContent({
        isOpen: true,
        title,
        content: `<div class="prose">${content}</div>`
      });
    }
  };

  if (!data) return null;

  const incompletePages = pages.filter(page => {
    const status = getPageStatus(page.id);
    return status === 'empty' || status === 'draft';
  }).map(page => ({
    ...page,
    status: getPageStatus(page.id)
  }));

  if (incompletePages.length > 0) {
    return <StartupWizardFinalCheck incompletePages={incompletePages} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Confetti />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4 mt-20 text-secondary-color">{t('Congratulations!')}</h1>
        <p className="text-2xl text-secondary-color">
          {t('You have successfully established your company')} {companyName}!
        </p>
      </div>

      <div className="mb-8 bg-yellow-100 p-6 rounded-lg">
        <div className="font-semibold mb-2 text-black">{t('First, a few safety instructions:')}</div>
        <p className="text-black">{t('If your company is registered in the commercial registers, you\'ll receive many FAKE INVOICES!')}</p>
        <p className="text-black">{t('The only invoices you need to consider are invoices from:')}</p>
        <ul className="list-disc ml-6 mt-2 text-black">
          <li>{t('Your notary')}</li>
          <li>{t('Landesjustizkasse, for roughly 40 EUR')}</li>
          <li>{t('IHK membership fee')}</li>
        </ul>
      </div>

      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-bold mb-4">{t('Final Activities after establishment of company')}</h2>

          <div className="mb-8">
            <h3 className="text-xl font-semibold mb-4">{t('Registration in the transparency register')}</h3>
            <p className="mb-4">
              {t('In addition to opening a business account, your startup in Germany must also be entered in the transparency register.')}
              <button
                onClick={() => toggleDrawer(
                  t('Why is the transparency register important?'),
                  t('Every company is legally obliged to disclose its beneficial owners in the transparency register. The term "beneficial owner" generally includes persons who hold more than 25% of a company\'s shares or voting rights or exercise comparable control over the company. Failure to comply with the reporting obligations can lead to substantial fines, which is why this step should not be neglected.')
                )}
                className="ml-2 text-gray-400 hover:text-gray-500"
              >
                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </p>
            <p className="mb-4">{t('Here is your step-by-step guide to registering in the Transparency Register:')}</p>

            <div className="space-y-1">
              <Collapsible id="identification" title={t('1. Identification of beneficial owners')}>
                {t('Before you can register, you must identify the beneficial owners of your company. These are usually the partners or shareholders who hold more than 25% of the company shares or have comparable control rights.')}
              </Collapsible>

              <Collapsible id="userAccount" title={t('2. Creation of a user account')}>
                {t('To register the company in the Transparency Register, you must first create a user account on the official website of the Transparency Register (www.transparenzregister.de). To do this, you will need the general company data and the personal data of the person authorized to represent the company (usually the managing director).')}
              </Collapsible>

              <Collapsible id="registration" title={t('registration.title')}>
                {t('registration.intro')}
                <ul className="list-disc pl-6 mt-2">
                  <li>{t('registration.list.item1')}</li>
                  <li>{t('registration.list.item2')}</li>
                  <li>{t('registration.list.item3')}</li>
                </ul>
                {t('registration.closing')}
              </Collapsible>

              <Collapsible id="verification" title={t('4. Verification and confirmation')}>
                {t('Once all the information has been entered, the system will perform an automatic check. In some cases, additional documentation may be requested. Once the registration is completed, you will receive a confirmation that your company has been duly registered in the Transparency Register.')}
              </Collapsible>

              <Collapsible id="maintenance" title={t('5. Maintenance and updating of data')}>
                {t('Entry in the transparency register is not a one-off process. You are obliged to check the data regularly and to update the register promptly in the event of changes, such as a change in the shareholder structure. This ensures that your company remains in compliance with the legal requirements.')}
              </Collapsible>
            </div>

            <div className="mt-4">
              <CreateSystemTaskButton
                taskName="Registration in the transparency register"
                section="transparency_register"
              />
            </div>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">{t('Business registration')}</h3>
            <p className="mb-4">
              {t('One of the first official obligations for your startup is business registration. In Germany, almost all companies, regardless of their size, must register a business before they can officially start their business activities. This registration serves to inform the relevant authorities about the existence of your company and ensures that you can operate your business legally.')}
              <button
                onClick={() => toggleDrawer(
                  t('Why is business registration important?'),
                  t('Business registration is required by law and forms the basis for obtaining a business license. You are not allowed to carry out any commercial activities without a valid business registration. The registration also automatically informs the tax office and other relevant authorities, such as the Chamber of Commerce (IHK) or Chamber of Crafts, the health insurance companies and the employers\' liability insurance association.')
                )}
                className="ml-2 text-gray-400 hover:text-gray-500"
              >
                <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </p>

            <div className="space-y-1">
              <Collapsible id="documents" title={t('1. You will need the following documents to register a business')}>
                {t('- ID card or passport of the managing director or owner\n- Extract from the commercial register (if your company is already registered)\n- Rental agreement for the business premises (if available)\n- Further permits or proof, if required for your industry (e.g. for craft businesses, restaurants or insurance companies)')}
              </Collapsible>

              <Collapsible id="tradeOffice" title={t('2. Registration with the trade office')}>
                {t('Business registration takes place at the trade office of the city or municipality in which your company is based. This step can often be carried out online, but you can also appear in person or submit the registration by post. You fill out the business registration form, providing the following information:\n- Name and legal form of the company\n- Address of the company\n- Type of business (brief description of the business activity)\n- Details of the shareholders and managing directors')}
              </Collapsible>

              <Collapsible id="fees" title={t('3. Payment of fees')}>
                {t('A fee of between 20 and 60 euros is payable for business registration, depending on the city or municipality. This usually has to be paid directly at the time of registration, either online or on site at the trade office and it is one of your first wire transfers you have to conduct.')}
              </Collapsible>

              <Collapsible id="license" title={t('4. Receipt of the trade license')}>
                {t('Once you have successfully registered, you will receive your trade license, which serves as official permission to operate your business. You should keep this trade license in a safe place, as it will be needed for various official matters and applications.')}
              </Collapsible>

              <Collapsible id="taxOffice" title={t('5. Notification to the tax office')}>
                {t('After registering your business, the relevant tax office will automatically be informed of your business opening. Within a few days or weeks, you will receive the tax registration form, in which you must provide further information about your business activities and your expected turnover. You will then receive a tax number for your company based on this information.')}
              </Collapsible>

              <Collapsible id="otherAuthorities" title={t('6. Notification to other authorities')}>
                {t('Your business registration will also be forwarded to the responsible Chamber of Industry and Commerce or Chamber of Crafts as well as to the responsible employers\' liability insurance association and health insurance companies. You will usually be contacted automatically by these institutions to initiate further necessary steps, such as compulsory membership of the Chamber of Industry and Commerce and registration with the employers\' liability insurance association, which is responsible for statutory accident insurance.')}
              </Collapsible>
            </div>
            <div className="mt-4">
              <CreateSystemTaskButton
                taskName="Business registration"
                section="business_registration"
              />
            </div>
          </div>
        </section>

        <div className="flex justify-center mt-8">
          <button
            onClick={handleFinish}
            className="px-6 py-3 bg-[#2A9D8F] text-white font-medium rounded-md hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
          >
            {t('Finish')}
          </button>
        </div>
      </div>

      <WizardDrawer
        isOpen={drawerContent.isOpen}
        onClose={() => setDrawerContent(prev => ({ ...prev, isOpen: false }))}
        title={drawerContent.title}
        content={drawerContent.content}
      />
    </div>
  );
};

export default StartupWizardFinal;