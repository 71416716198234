import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import Checkthumbs from '../../../components/wizard_components/Checkthumbs';
import TextField from '../../../components/wizard_components/TextField';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardExitStrategy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');
    const [currency, setCurrency] = useState('EUR');
    const [editingField, setEditingField] = useState(null);
    const [initialValues, setInitialValues] = useState({
        exitStrategies: [],
        targetExitYear: new Date().getFullYear() + 5,
        targetExitValue: '',
        exitReadinessChecklist: {
            revenueGrowth: false,
            profitability: false,
            marketPosition: false,
            customerBase: false,
            intellectualProperty: false,
            management: false,
            financialReporting: false,
            compliance: false,
            scalableProcesses: false,
            cleanCapTable: false,
            valueAlignment: false,
            employeeRetention: false,
            teamCulture: false,
            socialImpact: false,
            stakeholderInterests: false,
            founderRole: false,
            decisionMaking: false,
            productVision: false,
            employeeBenefits: false,
            communityImpact: false,
        },
        exitNotes: ''
    });

    const exitStrategySvgs = {
        "Strategic Merger/Acquisition": {
            default: '/thumbs/MandA_exit.svg',
            hover: '/thumbs/MandA_exit_hover.svg',
            active: '/thumbs/MandA_exit_active.svg'
        },
        "Private Equity Acquisition/Buyout": {
            default: '/thumbs/PEAquisition_exit.svg',
            hover: '/thumbs/PEAquisition_exit_hover.svg',
            active: '/thumbs/PEAquisition_exit_active.svg'
        },
        "Public Listing (IPO)": {
            default: '/thumbs/IPO_exit.svg',
            hover: '/thumbs/IPO_exit_hover.svg',
            active: '/thumbs/IPO_exit_active.svg'
        },
        "Succession": {
            default: '/thumbs/Succession_exit.svg',
            hover: '/thumbs/Succession_exit_hover.svg',
            active: '/thumbs/Succession_exit_active.svg'
        },
        "No Exit Planned": {
            default: '/thumbs/NoExit_exit.svg',
            hover: '/thumbs/NoExit_exit_hover.svg',
            active: '/thumbs/NoExit_exit_active.svg'
        }
    };

    const exitReadinessCriteria = {
        // Value for Buyer
        buyer: {
            revenueGrowth: t('Consistent revenue growth with clear trajectory'),
            profitability: t('Path to profitability or achieved profitability'),
            marketPosition: t('Strong market position and competitive advantage'),
            customerBase: t('Diversified and growing customer base'),
            intellectualProperty: t('Protected intellectual property and trade secrets'),
            management: t('Strong management team and succession planning'),
            financialReporting: t('Robust financial reporting and controls'),
            compliance: t('Regulatory compliance and risk management'),
            scalableProcesses: t('Scalable operational processes'),
            cleanCapTable: t('Clean cap table and shareholder documentation')
        },
        // Value for Seller
        seller: {
            valueAlignment: t('Buyer/investor shares company values and culture'),
            employeeRetention: t('Key employees have retention agreements'),
            teamCulture: t('Preservation of team culture post-exit'),
            socialImpact: t('Commitment to maintaining social/environmental impact'),
            stakeholderInterests: t('Protection of stakeholder interests'),
            founderRole: t('Clear founder role and involvement post-exit'),
            decisionMaking: t('Autonomy in key decision-making post-exit'),
            productVision: t('Alignment on product vision and roadmap'),
            employeeBenefits: t('Protection of employee benefits and equity'),
            communityImpact: t('Continued commitment to local community')
        }
    };

    useEffect(() => {
        if (userData?.entity) {
            const { published, draft } = userData.entity.know_yourself_response || {};
            const relevantDraftData = draft?.exitStrategy || {};
            const relevantPublishedData = published?.exitStrategy || {};

            const newCurrency = published?.fundingRounds?.currency ||
                draft?.fundingRounds?.currency ||
                'EUR';
            setCurrency(newCurrency);

            const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

            const isDataEmpty = (data) => {
                if (!data) return true;

                return Object.entries(data).every(([key, value]) => {
                    // Ignore targetExitYear in emptiness check
                    if (key === 'targetExitYear') return true;

                    return value === '' ||
                        value === null ||
                        value === undefined ||
                        (Array.isArray(value) && value.length === 0) ||
                        (typeof value === 'object' && value !== null &&
                            Object.values(value).every(v => v === false || v === '' || v === null || v === undefined));
                });
            };

            if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
                setDataStatus('draft');
                setInitialValues(relevantDraftData);
            } else if (!isDataEmpty(relevantPublishedData)) {
                setDataStatus('published');
                setInitialValues(relevantPublishedData);
            } else {
                setDataStatus('new');
                setInitialValues({
                    exitStrategies: [],
                    targetExitYear: new Date().getFullYear() + 5,
                    targetExitValue: '',
                    exitReadinessChecklist: Object.keys(exitReadinessCriteria.buyer)
                        .concat(Object.keys(exitReadinessCriteria.seller))
                        .reduce((acc, key) => ({ ...acc, [key]: false }), {}),
                    exitNotes: ''
                });
            }
        }
    }, [userData]);

    const validationSchema = Yup.object({
        exitStrategies: Yup.array()
            .min(1, t('KnowYourselfWizard.selectExitStrategy')),
        targetExitYear: Yup.number()
            .min(new Date().getFullYear(), t('KnowYourselfWizard.yearFuture'))
            .max(new Date().getFullYear() + 30, t('KnowYourselfWizard.yearTooFar'))
            .required(t('KnowYourselfWizard.yearRequired')),
        targetExitValue: Yup.number()
            .positive(t('KnowYourselfWizard.valuePositive'))
            .required(t('KnowYourselfWizard.valueRequired')),
        exitReadinessChecklist: Yup.object().shape(
            Object.keys(exitReadinessCriteria.buyer)
                .concat(Object.keys(exitReadinessCriteria.seller))
                .reduce((acc, key) => {
                    acc[key] = Yup.boolean();
                    return acc;
                }, {})
        ),
        exitNotes: Yup.string()
            .max(1000, t('KnowYourselfWizard.notesTooLong'))
    });

    const formatCurrency = (value) => {
        if (!value) return '';
        return new Intl.NumberFormat('en-US').format(value);
    };

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await handleSubmit({ responses: { exitStrategy: values } });
            setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
            setDataStatus('published');
            await handleDraftSubmit({ responses: { exitStrategy: values } });
            setTimeout(() => {
                navigate('/funding');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: 'Failed to save data',
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: { exitStrategy: values } });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully!') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>

            <h2 className="text-primary-color">{t('Exit Strategy')}</h2>
            <p className="mb-8">{t('KnowYourselfWizard.exitStrategyIntro')}</p>

            {/* Exit Strategy Recommendations */}
            <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                    <div className="flex items-center">
                        <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                        <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                        className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">
                        <div className="space-y-6 text-gray-700">
                            {/* Exit Strategies Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('exitStrategyRecommendations.exitStrategies.title')}
                                </h4>
                                {t('exitStrategyRecommendations.exitStrategies.paragraphs', { returnObjects: true }).map((p, i) => (
                                    <p key={i} className="text-sm">{p}</p>
                                ))}
                            </div>
                            {/* Targeted Exit Year and Value Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('exitStrategyRecommendations.targetExit.title')}
                                </h4>
                                <p className="text-sm">{t('exitStrategyRecommendations.targetExit.content')}</p>
                            </div>
                            {/* Exit Criteria Section */}
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('exitStrategyRecommendations.exitCriteria.title')}
                                </h4>
                                {t('exitStrategyRecommendations.exitCriteria.paragraphs', { returnObjects: true }).map((p, i) => (
                                    <p key={i} className="text-sm">{p}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, validateForm, setSubmitting, isSubmitting, setFieldValue }) => {

                    // Update the customSubmit function
                    const customSubmit = async (e) => {
                        e.preventDefault();
                        const errors = await validateForm(values);

                        if (Object.keys(errors).length === 0) {
                            onSubmit(values, { setSubmitting });
                        } else {
                            const errorMessages = [];

                            // Handle exitStrategies errors
                            if (errors.exitStrategies) {
                                errorMessages.push(`${t('KnowYourselfWizard.exitStrategies')}: ${errors.exitStrategies}`);
                            }

                            // Handle target year and value errors
                            if (errors.targetExitYear) {
                                errorMessages.push(`${t('KnowYourselfWizard.targetExitYear')}: ${errors.targetExitYear}`);
                            }
                            if (errors.targetExitValue) {
                                errorMessages.push(`${t('KnowYourselfWizard.targetExitValue')}: ${errors.targetExitValue}`);
                            }

                            // Handle notes error if any
                            if (errors.exitNotes) {
                                errorMessages.push(`${t('KnowYourselfWizard.exitNotes')}: ${errors.exitNotes}`);
                            }

                            setNotification({
                                show: true,
                                type: 'error',
                                message: t('Failed to save data due to validation errors:'),
                                errors: errorMessages
                            });
                        }
                    };

                    return (
                        <Form onSubmit={customSubmit}>
                            <div className="space-y-8">
                                <div className="bg-white p-6 rounded-lg shadow">
                                    <h3 className="font-semibold mb-4">{t('Preferred Exit Strategies')}</h3>
                                    <p className="mb-4 text-gray-600">{t('Select one or more exit strategies that align with your business goals')}</p>

                                    <Checkthumbs
                                        name="exitStrategies"
                                        options={Object.keys(exitStrategySvgs).map(strategy => ({
                                            value: strategy,
                                            svg: exitStrategySvgs[strategy],
                                            label: t(`ExitStrategies.${strategy}`)
                                        }))}
                                        value={values.exitStrategies}
                                        onChange={(value) => setFieldValue('exitStrategies', value)}
                                    />

                                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                {t('Target Exit Year')}
                                            </label>
                                            <select
                                                value={values.targetExitYear}
                                                onChange={(e) => setFieldValue('targetExitYear', Number(e.target.value))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                            >
                                                {Array.from({ length: 16 }, (_, i) => new Date().getFullYear() + i).map(year => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                                {t('Target Exit Value')}
                                            </label>
                                            <div className="flex">
                                                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 py-2 text-sm text-gray-500">
                                                    {(() => {
                                                        switch (currency) {
                                                            case 'USD': return '$';
                                                            case 'EUR': return '€';
                                                            case 'JPY': return '¥';
                                                            case 'GBP': return '£';
                                                            case 'AUD': return 'A$';
                                                            case 'BRL': return 'R$';
                                                            case 'CAD': return 'C$';
                                                            case 'CHF': return 'Fr';
                                                            case 'CNY': return '¥';
                                                            case 'HKD': return 'HK$';
                                                            case 'INR': return '₹';
                                                            case 'KRW': return '₩';
                                                            case 'MXN': return 'Mex$';
                                                            case 'NOK': return 'kr';
                                                            case 'NZD': return 'NZ$';
                                                            case 'RUB': return '₽';
                                                            case 'SEK': return 'kr';
                                                            case 'SGD': return 'S$';
                                                            case 'TRY': return '₺';
                                                            case 'ZAR': return 'R';
                                                            default: return '€';
                                                        }
                                                    })()}
                                                </span>
                                                {editingField === 'targetExitValue' ? (
                                                    <input
                                                        type="number"
                                                        value={values.targetExitValue || ''}
                                                        onChange={(e) => {
                                                            setFieldValue('targetExitValue', e.target.value);
                                                        }}
                                                        onBlur={() => setEditingField(null)}
                                                        className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                        step="1000000"
                                                        min="0"
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={new Intl.NumberFormat('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        }).format(values.targetExitValue || '')}
                                                        onFocus={() => setEditingField('targetExitValue')}
                                                        className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                        readOnly
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white mt-6 p-6 rounded-lg shadow">
                                    <div>
                                        <h3 className="font-semibold mb-4">{t('Exit Readiness Criteria')}</h3>
                                        <p className="mb-8 text-gray-600">
                                            {t('Select the criteria that are already in place or that you plan to achieve before exit')}
                                        </p>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                            {/* Value for Buyer Column */}
                                            <div>
                                                <h4 className="font-semibold text-gray-900 mb-4">{t('Value for Buyer')}</h4>
                                                <div className="space-y-4">
                                                    {Object.entries(exitReadinessCriteria.buyer).map(([key, label]) => (
                                                        <div key={key} className="flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={values.exitReadinessChecklist[key]}
                                                                    onChange={(e) =>
                                                                        setFieldValue(`exitReadinessChecklist.${key}`, e.target.checked)
                                                                    }
                                                                    className="h-4 w-4 text-primary-color border-gray-300 rounded focus:ring-primary-color"
                                                                />
                                                            </div>
                                                            <div className="ml-3 text-sm">
                                                                <label className="font-medium text-gray-700">{label}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Value for Seller Column */}
                                            <div>
                                                <h4 className="font-semibold text-gray-900 mb-4">{t('Value for Seller')}</h4>
                                                <div className="space-y-4">
                                                    {Object.entries(exitReadinessCriteria.seller).map(([key, label]) => (
                                                        <div key={key} className="flex items-start">
                                                            <div className="flex items-center h-5">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={values.exitReadinessChecklist[key]}
                                                                    onChange={(e) =>
                                                                        setFieldValue(`exitReadinessChecklist.${key}`, e.target.checked)
                                                                    }
                                                                    className="h-4 w-4 text-primary-color border-gray-300 rounded focus:ring-primary-color"
                                                                />
                                                            </div>
                                                            <div className="ml-3 text-sm">
                                                                <label className="font-medium text-gray-700">{label}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-8">
                                        <h3 className="text-lg font-medium mb-4">{t('Additional Notes')}</h3>
                                        <TextField
                                            name="exitNotes"
                                            type="textarea"
                                            placeholder={t('Add any additional details about your exit strategy')}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-end gap-x-6">
                                    <button
                                        type="button"
                                        className="text-sm font-normal leading-6 text-gray-900"
                                        onClick={() => navigate('/know-yourself')}
                                    >
                                        {t('cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                        disabled={isSubmitting}
                                        className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600"
                                    >
                                        {t('Save as Draft')}
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
                                    >
                                        {isSubmitting ? t('saving') : t('save')}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
        </>
    );
};

export default KnowYourselfWizardExitStrategy;