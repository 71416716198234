import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

const CircleChart = ({ data, labels, capitalRequired }) => {
  const roundedSeries = data.map(value => Math.round(value));
  
  const options = {
    chart: {
      type: 'donut',
    },
    labels: labels,  // Just use the labels as passed
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'],
    legend: {
      position: 'bottom',
      formatter: function(val, opts) {
        const index = opts.seriesIndex;
        const percentage = roundedSeries[index];
        const amount = (percentage / 100) * capitalRequired;
        return `${val}: ${percentage}%`;
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
        startAngle: -90,
        endAngle: 270
      },
    },
    tooltip: {
      y: {
        formatter: function(value, { seriesIndex }) {
          const amount = (value / 100) * capitalRequired;
          return `${amount.toLocaleString()} EUR`;
        }
      }
    },
    dataLabels: {
      formatter: function(val, opts) {
        return Math.round(val) + '%';
      }
    }
  };

  return (
    <div className="circle-chart">
      <Chart options={options} series={roundedSeries} type="donut" height={400} />
    </div>
  );
};

CircleChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  capitalRequired: PropTypes.number.isRequired,
};

export default CircleChart;