// src/pages/wizards/DataPrivacyWizard/DataPrivacyWizardDPIA.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, useFormik } from 'formik';
import axios from 'axios';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    QuestionMarkCircleIcon,
    CheckIcon,
    PencilIcon
} from '@heroicons/react/24/solid';
import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { useUserData } from '../../../hooks/useUserData';

// DPIA specific components
import DPIAIntroduction from '../../../components/wizard_components/DataPrivacyWizard/DPIAIntroduction';
import DPIADataFlow from '../../../components/wizard_components/DataPrivacyWizard/DPIADataFlow';
import DPIARiskAssessment from '../../../components/wizard_components/DataPrivacyWizard/DPIARiskAssessment';
import DPIARiskTreatment from '../../../components/wizard_components/DataPrivacyWizard/DPIARiskTreatment';
import DPIAConsultationGrid from '../../../components/wizard_components/DataPrivacyWizard/DPIAConsultationGrid';
import DPIAStatus from '../../../components/wizard_components/DataPrivacyWizard/DPIAStatus';
import RiskMatrix from '../../../components/wizard_components/DataPrivacyWizard/RiskMatrix';
import RiskSummaryTable from '../../../components/wizard_components/DataPrivacyWizard/RiskSummaryTable';
import DPIARiskReAssessment from '../../../components/wizard_components/DataPrivacyWizard/DPIARiskReAssessment';

function debounce(func, wait) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }
    debounced.cancel = () => {
        clearTimeout(timeout);
    };
    return debounced;
}

const DataPrivacyWizardDPIA = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [notification, setNotification] = useState({ show: false, type: '', message: '' });
    const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
    const { data: dpiaData, dataStatus, loading: dpiaLoading, error: dpiaError } = useFetchData(wizardName, 'dpia');
    const { data: ropaData, loading: ropaLoading, error: ropaError } = useFetchData(wizardName, 'ropa');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
    const [riskControls, setRiskControls] = useState([]);
    const { userData } = useUserData();

    // Expanded state and modals keyed by a unique activity id.
    const [expandedActivities, setExpandedActivities] = useState({});
    const [activeModals, setActiveModals] = useState({});

    // Log fetched ROPA data
    useEffect(() => {
        console.log('Fetched ROPA data:', ropaData);
    }, [ropaData]);

    // Extract processing activities that require a DPIA.
    const activitiesRequiringDPIA =
        ropaData?.processingActivities?.filter(activity => activity.dpiaRequired === true) || [];
    useEffect(() => {
        console.log('Activities requiring DPIA:', activitiesRequiringDPIA);
    }, [activitiesRequiringDPIA]);

    // Fetch risk controls data
    useEffect(() => {
        const fetchRiskControls = async () => {
            try {
                const response = await axios.get('/api/v1/static_data/risk_controls');
                setRiskControls(response.data);
            } catch (error) {
                console.error('Error fetching risk controls:', error);
            }
        };
        fetchRiskControls();
    }, []);

    useEffect(() => {
        setCurrentSection('dpia');
    }, [setCurrentSection]);

    // Initialize form values by merging DPIA data with ROPA activities.
    const getInitialValues = useCallback(() => {
        if (dpiaData && dpiaData.activities && dpiaData.activities.length > 0) {
            const existingDpiaMap = {};
            dpiaData.activities.forEach(dpiaActivity => {
                if (dpiaActivity.processingActivityId) {
                    existingDpiaMap[dpiaActivity.processingActivityId] = dpiaActivity;
                }
            });
            const mergedActivities = activitiesRequiringDPIA.map((activity) => {
                const uniqueId = activity.id;
                const existingDpia = existingDpiaMap[uniqueId];
                return existingDpia || {
                    processingActivityId: uniqueId,
                    processingActivityName: activity.name,
                    status: 'not_started',
                    necessityProportionality: {
                        dataMinimization: false,
                        purposeLimitation: false,
                        storageLimitation: false,
                        dataSubjectRights: false
                    },
                    dataFlow: {
                        collection: false,
                        storage: false,
                        processing: false,
                        sharing: false,
                        deletion: false
                    },
                    riskScenarios: [],
                    selectedRisks: [],
                    treatmentStarted: false,
                    recommendedControls: [],
                    selectedControls: [],
                    reassessedRisks: [],
                    consultation: {
                        dpoConsulted: false,
                        dpoName: '',
                        dpoConsultationDate: '',
                        dpoConsultationNotes: '',
                        stakeholdersConsulted: false,
                        stakeholderGroups: '',
                        stakeholderConsultationDate: '',
                        stakeholderConsultationNotes: '',
                        authorityConsultationRequired: false,
                        authorityConsulted: false,
                        authorityName: '',
                        authorityConsultationDate: '',
                        authorityConsultationNotes: ''
                    },
                    review: {
                        scheduled: false,
                        nextReviewDate: '',
                        notes: ''
                    }
                };
            });
            dpiaData.activities.forEach(dpiaActivity => {
                if (
                    dpiaActivity.processingActivityId &&
                    !activitiesRequiringDPIA.some(a => a.id === dpiaActivity.processingActivityId)
                ) {
                    mergedActivities.push(dpiaActivity);
                }
            });
            console.log('Merged DPIA activities:', mergedActivities);
            return { activities: mergedActivities };
        }
        return {
            activities: activitiesRequiringDPIA.map((activity) => ({
                processingActivityId: activity.id,
                processingActivityName: activity.name,
                status: 'not_started',
                necessityProportionality: {
                    dataMinimization: false,
                    purposeLimitation: false,
                    storageLimitation: false,
                    dataSubjectRights: false
                },
                dataFlow: {
                    collection: false,
                    storage: false,
                    processing: false,
                    sharing: false,
                    deletion: false
                },
                riskScenarios: [],
                selectedRisks: [],
                treatmentStarted: false,
                recommendedControls: [],
                selectedControls: [],
                reassessedRisks: [],
                consultation: {
                    dpoConsulted: false,
                    dpoName: '',
                    dpoConsultationDate: '',
                    dpoConsultationNotes: '',
                    stakeholdersConsulted: false,
                    stakeholderGroups: '',
                    stakeholderConsultationDate: '',
                    stakeholderConsultationNotes: '',
                    authorityConsultationRequired: false,
                    authorityConsulted: false,
                    authorityName: '',
                    authorityConsultationDate: '',
                    authorityConsultationNotes: ''
                },
                review: {
                    scheduled: false,
                    nextReviewDate: '',
                    notes: ''
                }
            }))
        };
    }, [dpiaData, activitiesRequiringDPIA]);

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: null,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const result = await saveData(wizardName, 'dpia', values);
                if (result.success) {
                    setNotification({
                        show: true,
                        type: 'success',
                        message: t('DataPrivacyWizard.dataSaved'),
                    });
                    updateWizardData('dpia', values, false);
                    updateWizardData('dpia', values, true);
                    setTimeout(() => {
                        navigate('/data-privacy-wizard/technical-measures');
                    }, 1000);
                } else {
                    throw new Error(result.message || 'Error saving data');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                setNotification({
                    show: true,
                    type: 'error',
                    message: t('DataPrivacyWizard.errorSaving'),
                    errors: error.message ? [error.message] : []
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    // Ensure any missing DPIA entries are added.
    useEffect(() => {
        if (formik.values.activities && activitiesRequiringDPIA.length > 0) {
            let updated = false;
            const newActivities = [...formik.values.activities];
            activitiesRequiringDPIA.forEach((activity) => {
                const uniqueId = activity.id;
                const exists = formik.values.activities.some(
                    dpia => dpia.processingActivityId === uniqueId
                );
                if (!exists) {
                    console.log(`Adding missing DPIA for activity id: ${uniqueId}`);
                    newActivities.push({
                        processingActivityId: uniqueId,
                        processingActivityName: activity.name,
                        status: 'not_started',
                        necessityProportionality: {
                            dataMinimization: false,
                            purposeLimitation: false,
                            storageLimitation: false,
                            dataSubjectRights: false
                        },
                        dataFlow: {
                            collection: false,
                            storage: false,
                            processing: false,
                            sharing: false,
                            deletion: false
                        },
                        riskScenarios: [],
                        selectedRisks: [],
                        treatmentStarted: false,
                        recommendedControls: [],
                        selectedControls: [],
                        reassessedRisks: [],
                        consultation: {
                            dpoConsulted: false,
                            dpoName: '',
                            dpoConsultationDate: '',
                            dpoConsultationNotes: '',
                            stakeholdersConsulted: false,
                            stakeholderGroups: '',
                            stakeholderConsultationDate: '',
                            stakeholderConsultationNotes: '',
                            authorityConsultationRequired: false,
                            authorityConsulted: false,
                            authorityName: '',
                            authorityConsultationDate: '',
                            authorityConsultationNotes: ''
                        },
                        review: {
                            scheduled: false,
                            nextReviewDate: '',
                            notes: ''
                        }
                    });
                    updated = true;
                }
            });
            if (updated) {
                console.log('Updated DPIA activities:', newActivities);
                formik.setFieldValue('activities', newActivities);
            }
        }
    }, [activitiesRequiringDPIA, formik.values.activities]);

    const autoSaveDraft = useCallback(async () => {
        if (liveMode) {
            const result = await saveAsDraft(wizardName, 'dpia', formik.values);
            if (result.success) {
                updateWizardData('dpia', formik.values, true);
            } else {
                console.error('Failed to save draft:', result.error);
            }
        }
    }, [liveMode, formik.values, wizardName, updateWizardData]);

    const debouncedAutoSaveRef = React.useRef();
    useEffect(() => {
        debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
    }, [autoSaveDraft]);

    useEffect(() => {
        if (liveMode) {
            debouncedAutoSaveRef.current();
            return () => debouncedAutoSaveRef.current.cancel();
        }
    }, [liveMode, formik.values]);

    const handleDraftSubmit = async () => {
        try {
            const result = await saveAsDraft(wizardName, 'dpia', formik.values);
            if (result.success) {
                setNotification({
                    show: true,
                    type: 'success',
                    message: t('DataPrivacyWizard.draftSaved'),
                });
                updateWizardData('dpia', formik.values, true);
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Error saving draft:', error);
            setNotification({
                show: true,
                type: 'error',
                message: t('DataPrivacyWizard.errorSavingDraft'),
                errors: [error.message],
            });
        }
    };

    const handleActivityComplete = async (dpiaIndex) => {
        try {
            // You may update the specific activity's data if needed here.
            const result = await saveAsDraft(wizardName, 'dpia', formik.values);
            if (result.success) {
                setNotification({
                    show: true,
                    type: 'success',
                    message: t('DataPrivacyWizard.dpia.activityCompleted'),
                });
                updateWizardData('dpia', formik.values, true);
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Error completing activity:', error);
            setNotification({
                show: true,
                type: 'error',
                message: t('DataPrivacyWizard.errorCompletingActivity'),
                errors: error.message ? [error.message] : [],
            });
        }
    };

    const toggleDrawer = (title, content) => {
        if (drawerOpen && drawerContent.title === title) {
            setDrawerOpen(false);
        } else {
            setDrawerContent({
                title,
                content: `<div class="prose">${content}</div>`
            });
            setDrawerOpen(true);
        }
    };

    // When toggling a collapsible, log the unique activity id.
    const toggleExpandActivity = (activityId) => {
        console.log('Toggling collapsible for activity id:', activityId);
        setExpandedActivities(prev => {
            const newState = { ...prev, [activityId]: !prev[activityId] };
            console.log('New expanded state:', newState);
            return newState;
        });
    };

    const toggleModalSection = (activityId, section) => {
        setActiveModals(prev => {
            const updatedModals = { ...prev };
            if (updatedModals[activityId] === section) {
                delete updatedModals[activityId];
            } else {
                updatedModals[activityId] = section;
            }
            console.log(`Modal for activity id ${activityId} set to:`, updatedModals[activityId]);
            return updatedModals;
        });
    };

    const calculateCompletionStatus = (activity) => {
        if (!activity) return 'not_started';
        const hasNecessityProportionality =
            activity.necessityProportionality?.dataMinimization &&
            activity.necessityProportionality?.purposeLimitation &&
            activity.necessityProportionality?.storageLimitation &&
            activity.necessityProportionality?.dataSubjectRights;
        const hasRisks = activity.selectedRisks && activity.selectedRisks.length > 0;
        const hasControls = activity.selectedControls && activity.selectedControls.length > 0;
        const hasConsultation = activity.consultation?.dpoConsulted ||
            activity.consultation?.stakeholdersConsulted ||
            activity.review?.scheduled;
        const requiresAuthorityConsultation = activity.consultation?.authorityConsultationRequired &&
            !activity.consultation?.authorityConsulted;
        if (hasNecessityProportionality && hasRisks && hasControls && hasConsultation && !requiresAuthorityConsultation) {
            return 'complete';
        } else if (hasNecessityProportionality || hasRisks || hasControls || hasConsultation) {
            return 'incomplete';
        }
        return 'not_started';
    };

    useEffect(() => {
        if (formik.values.activities && formik.values.activities.length > 0) {
            const updatedActivities = formik.values.activities.map(activity => ({
                ...activity,
                status: calculateCompletionStatus(activity)
            }));
            if (JSON.stringify(updatedActivities) !== JSON.stringify(formik.values.activities)) {
                console.log('Updating activity statuses:', updatedActivities);
                formik.setFieldValue('activities', updatedActivities);
            }
        }
    }, [formik.values]);

    if (ropaLoading || dpiaLoading) return <div>{t('Loading...')}</div>;
    if (ropaError) return <div>Error loading ROPA data: {ropaError.message}</div>;
    if (dpiaError) return <div>Error loading DPIA data: {dpiaError.message}</div>;

    return (
        <div className="container mx-auto p-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <LiveModeToggle onToggle={toggleLiveMode} />
                <div className="mt-10">
                    <DataPrivacyWizardProgress />
                </div>
                <Notification
                    show={notification.show}
                    setShow={(show) => setNotification(prev => ({ ...prev, show }))}
                    type={notification.type}
                    message={notification.message}
                />
                <DataStatusBanner status={dataStatus} />
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">{t('DataPrivacyWizard.dpia.title')}</h1>
                </div>
                <div className="mb-6">
                    {t('DataPrivacyWizard.dpia.introduction.whatIsDPIAContent')}
                </div>
                {activitiesRequiringDPIA.length === 0 && (
                    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
                        <div className="flex">
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    {t('DataPrivacyWizard.dpia.noProcessingActivityRequiresDPIA')}
                                </p>
                                <p className="mt-3 text-sm">
                                    <button
                                        type="button"
                                        onClick={() => navigate('/data-privacy-wizard/ropa')}
                                        className="font-medium text-yellow-700 hover:text-yellow-600"
                                    >
                                        {t('DataPrivacyWizard.dpia.goToROPA')}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <FormikProvider value={formik}>
                    <Form>
                        <div className="space-y-4 mb-6">
                            {activitiesRequiringDPIA.map((activity) => {
                                const uniqueId = activity.id;
                                const dpiaActivity = formik.values.activities?.find(
                                    dpia => dpia.processingActivityId === uniqueId
                                );
                                if (!dpiaActivity) return null;
                                
                                // Use the UUID as the unique key for expanded status
                                const isExpanded = expandedActivities[uniqueId] || false;
                                const activeModal = activeModals[uniqueId];
                                const dpiaIndex = formik.values.activities.findIndex(
                                    dpia => dpia.processingActivityId === uniqueId
                                );
                                
                                return (
                                    <div key={`dpia-activity-${uniqueId}`} className="border border-gray-200 rounded-lg overflow-hidden bg-white shadow-sm">
                                        <div
                                            className="bg-gray-50 px-4 py-3 flex justify-between items-center cursor-pointer"
                                            onClick={() => toggleExpandActivity(uniqueId)}
                                        >
                                            <div className="flex items-center space-x-3">
                                                <h3 className="text-lg font-medium text-gray-900">{activity.name}</h3>
                                                <DPIAStatus status={dpiaActivity.status} />
                                            </div>
                                            <div className="flex items-center">
                                                {isExpanded ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                                ) : (
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                                )}
                                            </div>
                                        </div>
                                        {isExpanded && (
                                            <div className="p-10">
                                                <DPIAIntroduction processingActivity={activity} />
                                                {/* Necessity & Proportionality Section */}
                                                <div className="mb-6">
                                                    <div className="flex justify-between items-center mb-2 mt-6">
                                                        <h4 className="font-medium text-lg text-gray-900">
                                                            {t('DataPrivacyWizard.dpia.necessityProportionality.title')}
                                                        </h4>
                                                    </div>
                                                    <div className="mb-6 font-medium text-sm text-gray-700">
                                                        {t('DataPrivacyWizard.dpia.descriptionNecessity')}
                                                    </div>
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        <div className="flex items-start">
                                                            <input
                                                                id={`dataMinimization-${uniqueId}`}
                                                                name={`activities[${dpiaIndex}].necessityProportionality.dataMinimization`}
                                                                type="checkbox"
                                                                checked={dpiaActivity.necessityProportionality?.dataMinimization || false}
                                                                onChange={formik.handleChange}
                                                                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                                                            />
                                                            <div className="ml-3">
                                                                <label htmlFor={`dataMinimization-${uniqueId}`} className="font-medium text-gray-900 text-sm">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.dataMinimizationStatement')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-start">
                                                            <input
                                                                id={`purposeLimitation-${uniqueId}`}
                                                                name={`activities[${dpiaIndex}].necessityProportionality.purposeLimitation`}
                                                                type="checkbox"
                                                                checked={dpiaActivity.necessityProportionality?.purposeLimitation || false}
                                                                onChange={formik.handleChange}
                                                                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                                                            />
                                                            <div className="ml-3">
                                                                <label htmlFor={`purposeLimitation-${uniqueId}`} className="font-medium text-gray-900 text-sm">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.purposeLimitationStatement')}
                                                                </label>
                                                                <p className="text-xs text-secondary-color">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.purposeLimitationNote')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-start">
                                                            <input
                                                                id={`storageLimitation-${uniqueId}`}
                                                                name={`activities[${dpiaIndex}].necessityProportionality.storageLimitation`}
                                                                type="checkbox"
                                                                checked={dpiaActivity.necessityProportionality?.storageLimitation || false}
                                                                onChange={formik.handleChange}
                                                                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                                                            />
                                                            <div className="ml-3">
                                                                <label htmlFor={`storageLimitation-${uniqueId}`} className="font-medium text-gray-900 text-sm">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.storageLimitationStatement')}
                                                                </label>
                                                                <p className="text-xs text-secondary-color">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.storageLimitationNote')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-start">
                                                            <input
                                                                id={`dataSubjectRights-${uniqueId}`}
                                                                name={`activities[${dpiaIndex}].necessityProportionality.dataSubjectRights`}
                                                                type="checkbox"
                                                                checked={dpiaActivity.necessityProportionality?.dataSubjectRights || false}
                                                                onChange={formik.handleChange}
                                                                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                                                            />
                                                            <div className="ml-3">
                                                                <label htmlFor={`dataSubjectRights-${uniqueId}`} className="font-medium text-gray-900 text-sm">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.dataSubjectRightsStatement')}
                                                                </label>
                                                                <p className="text-xs text-secondary-color">
                                                                    {t('DataPrivacyWizard.dpia.necessityProportionality.dataSubjectRightsNote')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-t border-gray-200 my-10"></div>
                                                {/* Data Flow Section */}
                                                <div className="mb-6">
                                                    <div className="flex justify-between items-center mb-3">
                                                        <h4 className="font-medium text-lg text-gray-900">
                                                            {t('DataPrivacyWizard.dpia.dataFlow.title')}
                                                        </h4>
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleModalSection(uniqueId, 'dataFlow')}
                                                            className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-white bg-secondary-color hover:opacity-90"
                                                        >
                                                            {dpiaActivity.dataFlow?.nodes?.length > 0
                                                                ? t('DataPrivacyWizard.edit')
                                                                : t('DataPrivacyWizard.dpia.dataFlow.createDataFlow')}
                                                        </button>
                                                    </div>
                                                    {dpiaActivity.dataFlow?.completed ? (
                                                        <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                                                            <div className="flex items-start">
                                                                <CheckIcon className="h-5 w-5 text-green-500 mt-0.5 mr-2" />
                                                                <div>
                                                                    <p className="text-sm font-medium text-green-800">
                                                                        {t('DataPrivacyWizard.dpia.dataFlow.completed')}
                                                                    </p>
                                                                    <p className="text-sm text-green-700 mt-1">
                                                                        {t('DataPrivacyWizard.dpia.dataFlow.nodesEdgesCount', {
                                                                            nodes: dpiaActivity.dataFlow.nodes?.length || 0,
                                                                            edges: dpiaActivity.dataFlow.edges?.length || 0
                                                                        })}
                                                                    </p>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                                                            <p className="text-sm text-yellow-700">
                                                                {t('DataPrivacyWizard.dpia.dataFlow.notCompleted')}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {activeModal === 'dataFlow' && (
                                                        <div className="fixed inset-0 z-50 overflow-y-auto">
                                                            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                                                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                                                </div>
                                                                <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl relative">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => toggleModalSection(uniqueId, null)}
                                                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
                                                                    >
                                                                        <span className="sr-only">{t('DataPrivacyWizard.close')}</span>
                                                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                                                                            {t('DataPrivacyWizard.dpia.dataFlow.title')}
                                                                        </h3>
                                                                        <DPIADataFlow
                                                                            formik={{
                                                                                values: { ...dpiaActivity },
                                                                                handleChange: (e) => {
                                                                                    const { name, checked } = e.target;
                                                                                    formik.setFieldValue(`activities[${dpiaIndex}].${name}`, checked);
                                                                                },
                                                                                setFieldValue: (field, value) => {
                                                                                    formik.setFieldValue(`activities[${dpiaIndex}].${field}`, value);
                                                                                }
                                                                            }}
                                                                            toggleDrawer={toggleDrawer}
                                                                        />
                                                                    </div>
                                                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => toggleModalSection(uniqueId, null)}
                                                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-color text-base font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
                                                                        >
                                                                            {t('DataPrivacyWizard.done')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="border-t border-gray-200 my-10"></div>
                                                {/* Risk Assessment Section */}
                                                <div className="mb-6">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <h4 className="font-medium text-lg text-gray-900">
                                                            {t('DataPrivacyWizard.dpia.riskAssessment.title')}
                                                        </h4>
                                                        {dpiaActivity.selectedRisks?.length > 0 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => toggleModalSection(uniqueId, 'riskAssessment')}
                                                                className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-md text-white bg-secondary-color hover:opacity-50"
                                                            >
                                                                <PencilIcon className="h-4 w-4 mr-2" />
                                                                {t('DataPrivacyWizard.edit')}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="mb-6 font-medium text-sm text-gray-700">
                                                        {t('DataPrivacyWizard.dpia.descriptionRiskAssessment')}
                                                    </div>
                                                    {dpiaActivity.selectedRisks?.length > 0 ? (
                                                        <div>
                                                            <RiskMatrix selectedRisks={dpiaActivity.selectedRisks} />
                                                            <RiskSummaryTable
                                                                selectedRisks={dpiaActivity.selectedRisks}
                                                                customRisks={dpiaActivity.riskScenarios}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleModalSection(uniqueId, 'riskAssessment')}
                                                            className="w-full py-3 px-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-color hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-color"
                                                        >
                                                            {t('DataPrivacyWizard.dpia.riskAssessment.startRiskAssessment')}
                                                        </button>
                                                    )}
                                                    {activeModal === 'riskAssessment' && (
                                                        <div className="fixed inset-0 z-50 overflow-y-auto">
                                                            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                                                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                                                </div>
                                                                <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl relative">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => toggleModalSection(uniqueId, null)}
                                                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
                                                                    >
                                                                        <span className="sr-only">{t('DataPrivacyWizard.close')}</span>
                                                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                        </svg>
                                                                    </button>
                                                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-3">
                                                                            {t('DataPrivacyWizard.dpia.riskAssessment.title')}
                                                                        </h3>
                                                                        <div>
                                                                            <DPIARiskAssessment
                                                                                formik={{
                                                                                    values: { ...dpiaActivity },
                                                                                    handleChange: (e) => {
                                                                                        const { name, checked } = e.target;
                                                                                        formik.setFieldValue(`activities[${dpiaIndex}].${name}`, checked);
                                                                                    },
                                                                                    setFieldValue: (field, value) => {
                                                                                        formik.setFieldValue(`activities[${dpiaIndex}].${field}`, value);
                                                                                    }
                                                                                }}
                                                                                toggleDrawer={toggleDrawer}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => toggleModalSection(uniqueId, null)}
                                                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-color text-base font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
                                                                        >
                                                                            {t('DataPrivacyWizard.done')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="border-t border-gray-200 my-10"></div>
                                                {/* Risk Treatment Section */}
                                                <div className="mb-6">
                                                    <div className="flex justify-between items-center mb-2">
                                                        <h4 className="font-medium text-lg text-gray-900">
                                                            {t('DataPrivacyWizard.dpia.riskTreatment.title')}
                                                        </h4>
                                                    </div>
                                                    <div className="mb-6 font-medium text-sm text-gray-700">
                                                        {t('DataPrivacyWizard.dpia.descriptionRiskTreatment')}
                                                    </div>
                                                    <DPIARiskTreatment
                                                        formik={{
                                                            values: { ...dpiaActivity },
                                                            handleChange: (e) => {
                                                                const { name, checked } = e.target;
                                                                formik.setFieldValue(`activities[${dpiaIndex}].${name}`, checked);
                                                            },
                                                            setFieldValue: (field, value) => {
                                                                formik.setFieldValue(`activities[${dpiaIndex}].${field}`, value);
                                                            }
                                                        }}
                                                        toggleDrawer={toggleDrawer}
                                                        riskControls={riskControls}
                                                        processingActivity={activity}
                                                        initialTreatmentStarted={dpiaActivity.treatmentStarted || false}
                                                        onTreatmentStarted={() => formik.setFieldValue(`activities[${dpiaIndex}].treatmentStarted`, true)}
                                                    />
                                                </div>
                                                {/* Risk Reassessment Section */}
                                                <DPIARiskReAssessment
                                                    formik={formik}
                                                    toggleDrawer={toggleDrawer}
                                                    selectedRisks={dpiaActivity.selectedRisks}
                                                    riskScenarios={dpiaActivity.riskScenarios}
                                                    activityId={uniqueId}
                                                    dpiaIndex={dpiaIndex}
                                                    treatmentStarted={dpiaActivity.treatmentStarted || false}
                                                />
                                                <div className="border-t border-gray-200 my-10"></div>
                                                {/* Consultation & Review Grid */}
                                                <DPIAConsultationGrid
                                                    formik={formik}
                                                    toggleDrawer={toggleDrawer}
                                                    dpiaIndex={dpiaIndex}
                                                    selectedRisks={dpiaActivity.selectedRisks || []}
                                                    reassessedRisks={dpiaActivity.reassessedRisks || []}
                                                />

                                                {/* Complete Activity Button */}
                                                <div className="mt-8 flex justify-end">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleActivityComplete(dpiaIndex)}
                                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-color hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                    >
                                                        {t('DataPrivacyWizard.dpia.completeActivity')}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-8">
                            <ActionButtons
                                onDraft={handleDraftSubmit}
                                onSubmit={formik.handleSubmit}
                                isSubmitting={formik.isSubmitting}
                                currentPage="dpia"
                            />
                        </div>
                    </Form>
                </FormikProvider>
            </div>
            <WizardDrawer
                isOpen={drawerOpen}
                title={drawerContent.title}
                content={drawerContent.content}
                onClose={() => setDrawerOpen(false)}
            />
        </div>
    );
};

export default DataPrivacyWizardDPIA;