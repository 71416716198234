import { useEffect } from 'react';

export const usePreventEnterSubmission = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        const form = event.target.closest('form');
        if (form) {
          event.preventDefault();
        }
      }
    };

    // Add event listener to document
    document.addEventListener('keydown', handleKeyDown, true);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);
};