import React, { useState, useEffect } from 'react';
import axios from '../../../axios';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, LockClosedIcon, UserIcon, BuildingOfficeIcon, HeartIcon, CurrencyDollarIcon, EllipsisHorizontalIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import { getTranslatedValue, getTranslatedArray } from '../../../utils/templateTranslations';

const TemplateStep = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(formData.templateId || null);
  const [showTemplateDetails, setShowTemplateDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedArea, setSelectedArea] = useState('all');

  // Define available areas
  const areas = [
    { id: 'all', name: t('All Areas') },
    { id: 'cybersecurity', name: t('areas.cybersecurity') },
    { id: 'data_privacy', name: t('areas.data_privacy') },
    { id: 'organization', name: t('areas.organization') },
    { id: 'culture', name: t('areas.culture') },
    { id: 'finance', name: t('areas.finance') }
  ];

  const getAreaIcon = (area, isSelected = false) => {
    const colorClass = isSelected ? 'text-white' : 'text-gray-400';

    switch (area) {
      case 'cybersecurity':
        return <LockClosedIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
      case 'data_privacy':
        return <UserIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
      case 'organization':
        return <BuildingOfficeIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
      case 'culture':
        return <HeartIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
      case 'finance':
        return <CurrencyDollarIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />;
    };
  };

  useEffect(() => {
    console.log('Current Step Data:', formData);
  }, [formData]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Fetch templates
  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('/api/v1/data_collections/templates', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });

        // Filter active templates only
        const filteredTemplates = response.data.filter(template => template.active);
        setAllTemplates(filteredTemplates);
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError(t('Failed to fetch templates. Please try again.'));
      } finally {
        setLoading(false);
      }
    };

    if (userData) {
      fetchTemplates();
    }
  }, [userData, t]);

  // Process templates whenever allTemplates or filters change
  useEffect(() => {
    if (!allTemplates.length) return;

    const userLang = userData?.language?.slice(0, 2) || 'en';

    // Filter by area if needed
    let filtered = [...allTemplates];
    if (selectedArea !== 'all') {
      filtered = filtered.filter(template => template.area === selectedArea);
    }

    // Sort alphabetically by translated name
    filtered.sort((a, b) => {
      const nameA = getTranslatedValue(a.name, userLang).toLowerCase();
      const nameB = getTranslatedValue(b.name, userLang).toLowerCase();
      return nameA.localeCompare(nameB);
    });

    setTemplates(filtered);
  }, [allTemplates, selectedArea, userData]);

  if (!userData) {
    return <div className="text-center">{t('Loading...')}</div>;
  }

  const userLang = userData.language
    ? userData.language.slice(0, 2)
    : 'en';

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">{t('Select a template')}</h2>
      </div>

      {/* Area filter */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="flex items-center">
          <FunnelIcon className="h-5 w-5 text-gray-400 mr-2" />
          <span className="text-sm font-medium text-gray-700">{t('Filter by area:')}</span>
        </div>
        <div className="flex flex-wrap gap-2">
          {areas.map(area => (
            <button
              key={area.id}
              className={`px-3 py-1.5 text-sm rounded-full inline-flex items-center ${selectedArea === area.id
                  ? 'bg-primary-color text-white'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
              onClick={() => setSelectedArea(area.id)}
            >
              {area.id !== 'all' && (
                <span className="flex items-center justify-center w-5 h-5 mr-1.5">
                  {React.cloneElement(getAreaIcon(area.id), { className: 'w-full h-full' })}
                </span>
              )}
              <span className="inline-block align-middle">{area.name}</span>
            </button>
          ))}
        </div>
      </div>

      {loading ? (
        <div className="text-center">{t('Loading templates...')}</div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : templates.length === 0 ? (
        <div className="text-center text-gray-500">
          {selectedArea !== 'all'
            ? t('No templates found for the selected area.')
            : t('No templates available.')}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {templates.map((template) => (
            <div
              key={template.id}
              className={`relative rounded-lg border p-4 cursor-pointer transition-all ${selectedTemplate === template.id
                ? 'bg-secondary-color text-white border-secondary-color ring-2 ring-secondary-color'
                : 'bg-white text-gray-900 border-gray-300 hover:border-gray-400'
                }`}
              onClick={() => {
                setSelectedTemplate(template.id);
                setFormData((prevData) => ({
                  ...prevData,
                  templateId: template.id,
                  templateName: getTranslatedValue(template.name, userLang),
                }));
              }}
            >
              <div className="flex justify-between items-start">
                <div className="flex items-center space-x-3">
                  {getAreaIcon(template.area, selectedTemplate === template.id)}
                  <div>
                    <h3 className={`mt-1 text-sm ${selectedTemplate === template.id ? 'text-white font-semibold' : 'text-primary-color font-medium'}`}>
                      {getTranslatedValue(template.name, userLang)}
                    </h3>
                    <p className={`mt-1 text-sm ${selectedTemplate === template.id ? 'text-gray-100' : 'text-gray-500'}`}>
                      {getTranslatedValue(template.description, userLang)}
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  className={`ml-4 ${selectedTemplate === template.id
                    ? 'text-gray-200 hover:text-white'
                    : 'text-gray-400 hover:text-gray-500'
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTemplateDetails(template.id);
                  }}
                >
                  <InformationCircleIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Template Details Modal */}
      {showTemplateDetails && (
        <Transition.Root show={!!showTemplateDetails} as={React.Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => setShowTemplateDetails(false)}>
            {/* Modal code (unchanged) */}
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    {(() => {
                      const template = templates.find((t) => t.id === showTemplateDetails);
                      if (!template) {
                        return (
                          <p className="text-gray-500">
                            {t('No template details available.')}
                          </p>
                        );
                      }

                      return (
                        <>
                          <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
                            {getTranslatedValue(template.name, userLang) || t('Template Details')}
                          </Dialog.Title>

                          <div className="mt-4 space-y-4">
                            {template.template_data?.parts?.length ? (
                              template.template_data.parts.map((part, index) => (
                                <div key={part.id} className="text-sm text-gray-700">
                                  <h4 className="font-semibold text-gray-800">
                                    {t('Part')} {index + 1}: {getTranslatedValue(part.name, userLang)}
                                  </h4>
                                  <ul className="list-disc list-inside mt-2 space-y-1">
                                    {part.questions.map((question) => (
                                      <li key={question.id}>
                                        {getTranslatedValue(question.text, userLang)}
                                        {question.type === 'boolean' && (
                                          <span> ({t('Answers')}: {
                                            getTranslatedArray(question.options, userLang).join(' / ')
                                          })</span>
                                        )}
                                        {question.type === 'rating' && (
                                          <span> ({t('Answers')}: {question.range[0]} - {question.range[1]})</span>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))
                            ) : (
                              <p className="text-gray-500">{t('No parts or questions available.')}</p>
                            )}
                          </div>
                        </>
                      );
                    })()}

                    <div className="mt-6 flex justify-end">
                      <button
                        className="px-4 py-2 bg-primary-color text-white rounded-md hover:bg-secondary-color"
                        onClick={() => setShowTemplateDetails(false)}
                      >
                        {t('Close')}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
};

export default TemplateStep;