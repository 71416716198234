import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

const DPIAConsultationGrid = ({ formik, toggleDrawer, dpiaIndex, selectedRisks, reassessedRisks }) => {
  const { t } = useTranslation();

  // Helper function to determine if authority consultation is required based on reassessed risks
  // Use memoization to prevent recalculating this value unnecessarily
  const isAuthorityConsultationRequired = React.useCallback(() => {
    if (!reassessedRisks || reassessedRisks.length === 0) {
      return false;
    }
    
    // Check if there are any high risks that remain after reassessment
    const hasHighRisks = reassessedRisks.some(risk => {
      const riskScore = risk.likelihood * risk.severity;
      return riskScore >= 15; // High risk threshold
    });
    
    return hasHighRisks;
  }, [reassessedRisks]);

  // Set authority consultation required automatically, with debouncing
  useEffect(() => {
    // Skip if no reassessed risks yet
    if (!reassessedRisks || reassessedRisks.length === 0) {
      return;
    }
    
    // Get current value
    const currentRequired = formik.values.activities[dpiaIndex]?.consultation?.authorityConsultationRequired;
    const shouldBeRequired = isAuthorityConsultationRequired();
    
    // Only update if the value needs to change
    if (currentRequired !== shouldBeRequired) {
      formik.setFieldValue(`activities[${dpiaIndex}].consultation.authorityConsultationRequired`, shouldBeRequired);
    }
  }, [reassessedRisks, dpiaIndex]);

  // Calculate default next review date (1 year from today)
  const getDefaultNextReviewDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // Set default next review date if not already set
  useEffect(() => {
    const currentValues = formik.values.activities[dpiaIndex];
    if (!currentValues.review?.nextReviewDate) {
      formik.setFieldValue(`activities[${dpiaIndex}].review.nextReviewDate`, getDefaultNextReviewDate());
    }
  }, [dpiaIndex, formik]);

  // Get consultation values for current activity
  const consultationValues = formik.values.activities[dpiaIndex]?.consultation || {};
  const reviewValues = formik.values.activities[dpiaIndex]?.review || {};

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-2">
        <h4 className="font-medium text-lg text-gray-900">
          {t('DataPrivacyWizard.dpia.consultation.title')} & {t('DataPrivacyWizard.dpia.consultation.reviewSchedule', 'Review Schedule')}
        </h4>
      </div>

      <div className="mb-6 font-medium text-sm text-gray-700">
          {t('DataPrivacyWizard.dpia.descriptionConsultation')}
        </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* DPO Consultation Section */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={`dpoConsulted-${dpiaIndex}`}
                name={`activities[${dpiaIndex}].consultation.dpoConsulted`}
                type="checkbox"
                checked={consultationValues.dpoConsulted || false}
                onChange={formik.handleChange}
                className="h-4 w-4 text-primary-color border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 w-full">
              <label htmlFor={`dpoConsulted-${dpiaIndex}`} className="font-medium text-gray-700 text-sm">
                {t('DataPrivacyWizard.dpia.consultation.dpoConsultation')}
              </label>
              
              {consultationValues.dpoConsulted && (
                <div className="mt-3 space-y-3">                  
                  <div>
                    <label htmlFor={`dpoConsultationDate-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationDate')}
                    </label>
                    <input
                      type="date"
                      id={`dpoConsultationDate-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.dpoConsultationDate`}
                      value={consultationValues.dpoConsultationDate || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`dpoConsultationNotes-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationNotes')}
                    </label>
                    <textarea
                      id={`dpoConsultationNotes-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.dpoConsultationNotes`}
                      rows="2"
                      value={consultationValues.dpoConsultationNotes || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                      placeholder={t('DataPrivacyWizard.dpia.consultation.consultationNotesPlaceholder')}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Stakeholder Consultation Section */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={`stakeholdersConsulted-${dpiaIndex}`}
                name={`activities[${dpiaIndex}].consultation.stakeholdersConsulted`}
                type="checkbox"
                checked={consultationValues.stakeholdersConsulted || false}
                onChange={formik.handleChange}
                className="h-4 w-4 text-primary-color border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 w-full">
              <label htmlFor={`stakeholdersConsulted-${dpiaIndex}`} className="font-medium text-gray-700 text-sm">
                {t('DataPrivacyWizard.dpia.consultation.stakeholderConsultation')}
              </label>
              
              {consultationValues.stakeholdersConsulted && (
                <div className="mt-3 space-y-3">
                  <div>
                    <label htmlFor={`stakeholderGroups-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.stakeholderGroups')}
                    </label>
                    <input
                      type="text"
                      id={`stakeholderGroups-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.stakeholderGroups`}
                      value={consultationValues.stakeholderGroups || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                      placeholder={t('DataPrivacyWizard.dpia.consultation.stakeholderGroupsPlaceholder')}
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`stakeholderConsultationDate-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationDate')}
                    </label>
                    <input
                      type="date"
                      id={`stakeholderConsultationDate-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.stakeholderConsultationDate`}
                      value={consultationValues.stakeholderConsultationDate || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`stakeholderConsultationNotes-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationNotes')}
                    </label>
                    <textarea
                      id={`stakeholderConsultationNotes-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.stakeholderConsultationNotes`}
                      rows="2"
                      value={consultationValues.stakeholderConsultationNotes || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                      placeholder={t('DataPrivacyWizard.dpia.consultation.consultationNotesPlaceholder')}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Authority Consultation Section */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={`authorityConsulted-${dpiaIndex}`}
                name={`activities[${dpiaIndex}].consultation.authorityConsulted`}
                type="checkbox"
                checked={consultationValues.authorityConsulted || false}
                onChange={formik.handleChange}
                className="h-4 w-4 text-primary-color border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 w-full">
              <div>
                <label htmlFor={`authorityConsulted-${dpiaIndex}`} className="font-medium text-gray-700 text-sm">
                  {t('DataPrivacyWizard.dpia.consultation.authorityConsultation')}
                </label>
                {isAuthorityConsultationRequired() && (
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                    {t('DataPrivacyWizard.dpia.consultation.required', 'Required')}
                  </span>
                )}
              </div>
              
              {isAuthorityConsultationRequired() && !consultationValues.authorityConsulted && (
                <p className="text-xs text-yellow-600 mt-1">
                  {t('DataPrivacyWizard.dpia.consultation.highRiskRemaining', 'High risk remains after treatment')}
                </p>
              )}
              
              {consultationValues.authorityConsulted && (
                <div className="mt-3 space-y-3">
                  <div>
                    <label htmlFor={`authorityName-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.authorityName', 'Authority Name')}
                    </label>
                    <input
                      type="text"
                      id={`authorityName-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.authorityName`}
                      value={consultationValues.authorityName || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`authorityConsultationDate-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationDate')}
                    </label>
                    <input
                      type="date"
                      id={`authorityConsultationDate-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.authorityConsultationDate`}
                      value={consultationValues.authorityConsultationDate || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`authorityConsultationNotes-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.consultationNotes')}
                    </label>
                    <textarea
                      id={`authorityConsultationNotes-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].consultation.authorityConsultationNotes`}
                      rows="2"
                      value={consultationValues.authorityConsultationNotes || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                      placeholder={t('DataPrivacyWizard.dpia.consultation.authorityConsultationNotesPlaceholder')}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Review Schedule Section */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id={`reviewScheduled-${dpiaIndex}`}
                name={`activities[${dpiaIndex}].review.scheduled`}
                type="checkbox"
                checked={reviewValues.scheduled || false}
                onChange={formik.handleChange}
                className="h-4 w-4 text-primary-color border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 w-full">
              <label htmlFor={`reviewScheduled-${dpiaIndex}`} className="font-medium text-gray-700 text-sm">
                {t('DataPrivacyWizard.dpia.consultation.nextReview', 'Next Review')}
              </label>
              
              {reviewValues.scheduled && (
                <div className="mt-3 space-y-3">
                  <div>
                    <label htmlFor={`nextReviewDate-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.reviewDate', 'Review Date')}
                    </label>
                    <input
                      type="date"
                      id={`nextReviewDate-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].nextReviewDate`}
                      value={reviewValues.nextReviewDate || getDefaultNextReviewDate()}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor={`reviewNotes-${dpiaIndex}`} className="block text-xs font-medium text-gray-700">
                      {t('DataPrivacyWizard.dpia.consultation.reviewNotes', 'Review Notes')}
                    </label>
                    <textarea
                      id={`reviewNotes-${dpiaIndex}`}
                      name={`activities[${dpiaIndex}].reviewNotes`}
                      rows="2"
                      value={reviewValues.notes || ''}
                      onChange={formik.handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1 px-2 text-xs focus:outline-none focus:ring-primary-color focus:border-primary-color"
                      placeholder={t('DataPrivacyWizard.dpia.consultation.reviewNotesPlaceholder', 'Enter any notes about the upcoming review')}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DPIAConsultationGrid;