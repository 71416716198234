/**
 * Utility functions for asset filtering
 * These functions help filter assets based on categories, classes, and other criteria
 */

/**
 * Enriches assets with their type, class, and category information
 * 
 * @param {Array} assets - Array of asset objects
 * @param {Array} assetTypes - Array of asset type objects
 * @param {Array} assetClasses - Array of asset class objects
 * @param {Array} assetCategories - Array of asset category objects
 * @returns {Array} Enriched assets with full hierarchy information
 */
// In AssetFilterUtils.js
export const enrichAssetsWithTypeInfo = (assets, assetTypes, assetClasses, assetCategories) => {
    if (!assets || !assets.length) return [];

    return assets.map(asset => {
        // Create a new object to avoid mutating the input
        const enrichedAsset = { ...asset };

        // Skip if asset has no type ID
        if (!asset.asset_type_id) return enrichedAsset;

        // Find matching type
        const matchingType = assetTypes.find(type =>
            type.id === asset.asset_type_id
        );

        if (matchingType) {
            // Add type info
            enrichedAsset.asset_type = matchingType.asset_type;
            enrichedAsset.werttyp = matchingType.werttyp;
            enrichedAsset.asset_class = matchingType.asset_class;

            // Find class info - use matchingType.asset_class to find the class
            const matchingClass = assetClasses.find(cls =>
                cls.asset_class === matchingType.asset_class
            );

            if (matchingClass) {
                // Important: Set asset_class_id from the matched class
                enrichedAsset.asset_class_id = matchingClass.id;
                enrichedAsset.wertklasse = matchingClass.wertklasse;
                enrichedAsset.asset_category = matchingClass.asset_category;

                // Find category info
                const matchingCategory = assetCategories.find(cat =>
                    cat.asset_categories === matchingClass.asset_category
                );

                if (matchingCategory) {
                    // Important: Set asset_category_id from the matched category
                    enrichedAsset.asset_category_id = matchingCategory.id;
                    enrichedAsset.wertkategorie = matchingCategory.wertkategorie;
                    enrichedAsset.category_icon = matchingCategory.icons;
                }
            }
        }

        return enrichedAsset;
    });
};

/**
 * Filters assets based on filter criteria
 * 
 * @param {Array} assets - Array of asset objects (should be enriched with type info first)
 * @param {Object} filter - Filter criteria object
 * @param {string} filter.categoryId - Category ID to filter by
 * @param {string} filter.classId - Class ID to filter by
 * @param {Array} filter.classIds - Array of class IDs to filter by
 * @param {Array} assetCategories - Array of asset category objects
 * @param {Array} assetTypes - Array of asset type objects
 * @returns {Array} Filtered assets
 */
export const filterAssetsByHierarchy = (assets, filter, assetCategories = [], assetTypes = []) => {
    if (!filter || !assets || !assets.length) return assets;
    
    console.log("filterAssetsByHierarchy starting with:", assets.length, "assets");
    console.log("Filter criteria:", filter);
    
    let filteredAssets = [...assets];
    
    if (filter.categoryId) {
      console.log("Filtering by categoryId:", filter.categoryId);
      
      // Try to match by asset_category_id first
      let categoryMatches = filteredAssets.filter(asset => 
        asset.asset_category_id && String(asset.asset_category_id) === String(filter.categoryId)
      );
      
      // If no matches, try alternative methods
      if (categoryMatches.length === 0) {
        console.log("No matches by asset_category_id, trying other methods");
        
        // Get the category name from categories using filter.categoryId
        const categoryObj = assetCategories.find(cat => String(cat.id) === String(filter.categoryId));
        if (categoryObj && categoryObj.asset_categories) {
          categoryMatches = filteredAssets.filter(asset => 
            asset.asset_category === categoryObj.asset_categories
          );
        }
      }
      
      filteredAssets = categoryMatches;
      console.log("After category filtering:", filteredAssets.length, "assets");
    }
    
    if (filter.classId) {
      console.log("Filtering by classId:", filter.classId);
      
      // Try to match by asset_class_id first
      let classMatches = filteredAssets.filter(asset => 
        asset.asset_class_id && String(asset.asset_class_id) === String(filter.classId)
      );
      
      // If no matches, try alternative methods
      if (classMatches.length === 0) {
        console.log("No matches by asset_class_id, checking asset_type_id");
        
        // Get all asset types that have asset_class_id matching filter.classId
        const matchingTypes = assetTypes.filter(type => 
          type.asset_class_id && String(type.asset_class_id) === String(filter.classId)
        );
        
        if (matchingTypes.length > 0) {
          console.log("Found", matchingTypes.length, "types in this class");
          const typeIds = matchingTypes.map(type => type.id);
          
          classMatches = filteredAssets.filter(asset => 
            asset.asset_type_id && typeIds.includes(asset.asset_type_id)
          );
        }
      }
      
      filteredAssets = classMatches;
      console.log("After class filtering:", filteredAssets.length, "assets");
    }
    
    // Handle filtering by multiple class IDs
    if (filter.classIds && Array.isArray(filter.classIds) && filter.classIds.length > 0) {
      console.log("Filtering by classIds:", filter.classIds);
      
      // Convert all classIds to strings for consistent comparison
      const targetClassIds = filter.classIds.map(id => String(id));
      
      // Try to match by asset_class_id first
      let classMatches = filteredAssets.filter(asset => 
        asset.asset_class_id && targetClassIds.includes(String(asset.asset_class_id))
      );
      
      // If no matches, try alternative methods
      if (classMatches.length === 0) {
        console.log("No matches by asset_class_id for classIds, checking asset_type_id");
        
        // Get all asset types that have asset_class_id matching any of the filter.classIds
        const matchingTypes = assetTypes.filter(type => 
          type.asset_class_id && targetClassIds.includes(String(type.asset_class_id))
        );
        
        if (matchingTypes.length > 0) {
          console.log("Found", matchingTypes.length, "types across these classes");
          const typeIds = matchingTypes.map(type => type.id);
          
          classMatches = filteredAssets.filter(asset => 
            asset.asset_type_id && typeIds.includes(asset.asset_type_id)
          );
        }
      }
      
      filteredAssets = classMatches;
      console.log("After multiple class filtering:", filteredAssets.length, "assets");
    }
    
    return filteredAssets;
  };

/**
 * Retrieves localized field values based on user language
 * 
 * @param {Object} item - Asset, class, or category object
 * @param {string} fieldEn - English field name
 * @param {string} fieldDe - German field name
 * @param {string} userLang - User language (e.g., 'en', 'de')
 * @returns {string} Localized field value
 */
export const getLocalizedValue = (item, fieldEn, fieldDe, userLang) => {
    if (!item) return '';
    return userLang === 'de' && item[fieldDe] ? item[fieldDe] : item[fieldEn];
};

/**
 * Applies search query filter to assets
 * 
 * @param {Array} assets - Array of asset objects
 * @param {string} query - Search query string
 * @param {string} userLang - User language code (e.g., 'en', 'de')
 * @returns {Array} Filtered assets matching the search query
 */
export const filterAssetsBySearchQuery = (assets, query, userLang) => {
    if (!query || !query.trim() || !assets || !assets.length) return assets;

    const searchLower = query.toLowerCase();

    return assets.filter(asset => {
        // Check names in both languages
        const nameMatch =
            (asset.name && asset.name.toLowerCase().includes(searchLower));

        // Check description in both languages
        const descriptionMatch =
            (asset.description && asset.description.toLowerCase().includes(searchLower));

        // Check asset type in both languages
        const typeMatch =
            (asset.asset_type && asset.asset_type.toLowerCase().includes(searchLower)) ||
            (asset.werttyp && asset.werttyp.toLowerCase().includes(searchLower));

        // Check asset class in both languages
        const classMatch =
            (asset.asset_class && asset.asset_class.toLowerCase().includes(searchLower)) ||
            (asset.wertklasse && asset.wertklasse.toLowerCase().includes(searchLower));

        // Check asset category in both languages
        const categoryMatch =
            (asset.asset_category && asset.asset_category.toLowerCase().includes(searchLower)) ||
            (asset.wertkategorie && asset.wertkategorie.toLowerCase().includes(searchLower));

        return nameMatch || descriptionMatch || typeMatch || classMatch || categoryMatch;
    });
};