import React, { useMemo, useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getTranslatedValue } from '../../../utils/templateTranslations';

const BarChart = ({ question, collection }) => {
  const { t } = useTranslation();
  const [userLang, setUserLang] = useState('en');
  const [users, setUsers] = useState({});

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });

        if (response.data.language) {
          setUserLang(response.data.language.slice(0, 2));
        }

        if (response.data.entity?.users) {
          const userMap = {};
          response.data.entity.users.forEach(user => {
            userMap[user.id] = `${user.first_name} ${user.last_name}`;
          });
          setUsers(userMap);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const getColor = (value, range, reverse = false) => {
    const [min, max] = range;
    const range_size = max - min;
    
    // Define thresholds for color transitions
    const redThreshold = min + (range_size * 0.5);    // Bottom 50%
    const greenThreshold = max - (range_size * 0.5);  // Top 50%
    
    // For reversed rating, swap the color logic
    let colorValue = reverse
      ? max - (value - min) // Invert the value within the range
      : value;
    
    if (colorValue <= redThreshold) {
      // Red zone - interpolate from pure red to yellow
      const t = (colorValue - min) / (redThreshold - min);
      const r = 255;
      const g = Math.round(69 + (107 * t));  // From FF4560 to FEB019
      const b = Math.round(96 + (-71 * t));
      return `rgb(${r},${g},${b})`;
    } else if (colorValue >= greenThreshold) {
      // Green zone - interpolate from yellow to pure green
      const t = (colorValue - greenThreshold) / (max - greenThreshold);
      const r = Math.round(254 - (212 * t));  // From FEB019 to 2A9D8F
      const g = Math.round(176 - (19 * t));
      const b = Math.round(25 + (118 * t));
      return `rgb(${r},${g},${b})`;
    } else {
      // Yellow zone - interpolate through yellow shades
      const t = (colorValue - redThreshold) / (greenThreshold - redThreshold);
      const r = 254;
      const g = Math.round(176);
      const b = Math.round(25 + (t * 25));  // Slight variation in yellow
      return `rgb(${r},${g},${b})`;
    }
  };

  const getPartId = (questionId) => questionId.split('_')[0];

  // Process and sort the data for the bar chart
  const chartData = useMemo(() => {
    if (!collection || !question) return null;

    const isReversed = question.rating_reverse || false;
    const partId = getPartId(question.id);
    const relevantResponses = collection.responses?.filter(r => !r.is_draft) || [];
    const responseCount = relevantResponses.length;
    
    if (responseCount === 0) return null;

    if (collection.collection_type === 'collaborative') {
      // Look for the response that contains data for this question
      const response = relevantResponses.find(r => r.response_data[question.id]);
      if (!response?.response_data?.[question.id]) return null;

      const values = response.response_data[question.id];
      
      const sortedData = question.items
        .map(item => ({
          label: getTranslatedValue(item.text, userLang),
          value: values[item.id] || 0,
          color: getColor(values[item.id] || 0, question.range || [-3, 3], isReversed)
        }))
        .sort((a, b) => isReversed ? a.value - b.value : b.value - a.value);

      return {
        labels: sortedData.map(d => d.label),
        colors: sortedData.map(d => d.color),
        series: [{
          name: t('Rating'),
          data: sortedData.map(d => d.value)
        }],
        responseCount: 1,
      };
    } else {
      const averages = question.items.map(item => {
        const values = relevantResponses
          .map(r => r.response_data?.[question.id]?.[item.id])
          .filter(v => v !== undefined);
        
        const value = values.length > 0 
          ? values.reduce((a, b) => a + b, 0) / values.length 
          : 0;

        return {
          label: getTranslatedValue(item.text, userLang),
          value,
          color: getColor(value, question.range || [-3, 3], isReversed)
        };
      }).sort((a, b) => b.value - a.value);

      return {
        labels: averages.map(a => a.label),
        colors: averages.map(a => a.color),
        series: [{
          name: t('Average Rating'),
          data: averages.map(a => a.value)
        }],
        responseCount
      };
    }
  }, [collection, question, userLang, users, t]);

  if (!chartData) return null;

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    legend: {
      show: false
    },
    title: {
      text: getTranslatedValue(question.text, userLang),
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: '500'
      }
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        borderRadius: 10,
        borderRadiusApplication: 'end',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'center'
        },
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        colors: ['#fff'],
        fontSize: '12px',
        fontWeight: '500'
      },
      background: {
        enabled: true,
        foreColor: '#4b5563',
        padding: 4,
        opacity: 0.5,
      },
      formatter: function(val, opt) {
        return `${opt.w.globals.labels[opt.dataPointIndex]} (${val.toFixed(1)})`;
      },
      offsetX: 0,
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: chartData.labels,
      min: question.range[0],
      max: question.range[1],
      tickAmount: Math.abs(question.range[1] - question.range[0]),
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontFamily: 'inherit'
        }
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    colors: chartData.colors,
    tooltip: {
      theme: 'light',
      x: {
        show: false
      },
      y: {
        formatter: value => value.toFixed(1)
      }
    }
  };

  // Add reference line for 0 if range includes negative values
  if (question.range[0] < 0) {
    options.annotations = {
      xaxis: [{
        x: 0,
        strokeDashArray: 0,
        borderColor: '#718096',
        label: {
          borderColor: '#718096',
          style: {
            color: '#fff',
            background: '#718096'
          }
        }
      }]
    };
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <Chart
        options={options}
        series={chartData.series}
        type="bar"
        height={Math.max(250, chartData.labels.length * 50)}
      />
      <div className="mt-2 text-xs text-gray-500 text-center">
        {collection.collection_type === 'individual' 
          ? t('Average ratings from {{count}} responses', { count: chartData.responseCount })
          : t('Rating from editor response')}
      </div>
    </div>
  );
};

export default BarChart;