import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const StartupWizardIntroduction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const totalPages = 4;
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'introduction');
  const initialValues = data || { relevantTopics: [], disclaimerChecked: false };
  const [answers, setAnswers] = useState(initialValues);

  useEffect(() => {
    setCurrentSection('introduction');
  }, [setCurrentSection]);

  const validationSchema = Yup.object({
    relevantTopics: Yup.array().min(1, t('StartupWizard.atLeastOneTopic')),
    disclaimerChecked: Yup.boolean().oneOf([true], t('StartupWizard.mustAgreeToDisclaimer')),
  });

  // Initialize Formik using useFormik hook
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const result = await saveData(wizardName, 'introduction', values);
        if (result.success) {
          setNotification({
            show: true,
            type: 'success',
            message: t('StartupWizard.dataSaved'),
          });
          updateWizardData('introduction', values, false);
          updateWizardData('introduction', values, true);
          setTimeout(() => {
            navigate('/startup-wizard/legalForm');
          }, 1000);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error('Error saving data:', error);
        setNotification({
          show: true,
          type: 'error',
          message: t('StartupWizard.errorSaving'),
          errors: [error.message],
        });
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode) {
      const result = await saveAsDraft(wizardName, 'introduction', formik.values);
      if (result.success) {
        updateWizardData('introduction', formik.values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, formik.values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and formik.values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, formik.values]);

  useEffect(() => {
    setAnswers(formik.values);
  }, [formik.values]);

  const handleDraftSubmit = async () => {
    try {
      const result = await saveAsDraft(wizardName, 'introduction', formik.values);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('StartupWizard.draftSaved'),
        });
        updateWizardData('introduction', formik.values, true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('StartupWizard.errorSavingDraft'),
        errors: [error.message],
      });
    }
  };

  const renderPage1 = () => (
    <div>
      <p className="mb-4">{t('StartupWizard.introductionText')}</p>
      <div className="mb-4">
        <p className="font-bold mb-2">{t('StartupWizard.relevantTopics')}</p>
        {[
          'startBusiness',
          'chooseLegalForm',
          'getExternalFunding',
          'avoidMistakes',
          'templatesForDocuments',
          'contactsToAdvisors',
          'contactsToInvestors',
        ].map((topic) => (
          <div key={topic} className="flex items-center mb-2">
            <Field
              type="checkbox"
              id={topic}
              name="relevantTopics"
              value={topic}
              className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded mr-3"
            />
            <label htmlFor={topic}>{t(`StartupWizard.topic.${topic}`)}</label>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPage2 = () => (
    <div>
      <h2 className="text-xl font-normal pb-0">{t('StartupWizard.whyUseWizardTitle1')}</h2>
      <h2 className="text-xl font-bold">{t('StartupWizard.whyUseWizardTitle2')}</h2>
      <p className="mb-4">{t('StartupWizard.whyUseWizardText1')}</p>
      <p className="mb-4">{t('StartupWizard.whyUseWizardText2')}</p>
    </div>
  );

  const renderPage3 = () => (
    <div>
      <h2 className="text-xl font-normal pb-0">{t('StartupWizard.whyItMattersTitle1')}</h2>
      <h2 className="text-xl font-bold">{t('StartupWizard.whyItMattersTitle2')}</h2>
      <p className="mb-4">{t('StartupWizard.whyItMattersText1')}</p>
      <p className="mb-4">{t('StartupWizard.whyItMattersText2')}</p>
    </div>
  );

  const renderPage4 = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">{t('StartupWizard.disclaimerTitle')}</h2>
      <p className="mb-4">{t('StartupWizard.disclaimerText')}</p>
      <div className="flex items-center mb-4 font-bold">
        <Field
          type="checkbox"
          id="disclaimerChecked"
          name="disclaimerChecked"
          className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded mr-3"
        />
        <label htmlFor="disclaimerChecked">{t('StartupWizard.disclaimerCheckbox')}</label>
      </div>
    </div>
  );

  const renderPagination = (isSubmitting) => (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          type="button"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1 || isSubmitting}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          {t('previous')}
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            type="button"
            onClick={() => setPage(index + 1)}
            disabled={isSubmitting}
            className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
              page === index + 1
                ? 'border-[#2A9D8F] text-[#2A9D8F]'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {page !== totalPages && (
          <button
            type="button"
            onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={isSubmitting}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
          >
            {t('next')}
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        )}
      </div>
    </nav>
  );

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <div className="mt-10 md:0">
      <StartupWizardProgress />
      </div>
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <DataStatusBanner status={dataStatus} />

      <h1 className="text-3xl font-bold mb-6">{t('StartupWizard.introduction')}</h1>

      <FormikProvider value={formik}>
        <Form>
          {page === 1 && renderPage1()}
          {page === 2 && renderPage2()}
          {page === 3 && renderPage3()}
          {page === 4 && renderPage4()}

          {renderPagination(formik.isSubmitting)}

          {page === totalPages && (
            <ActionButtons
              onDraft={handleDraftSubmit}
              onSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              currentPage="introduction"
              disabled={!formik.values.disclaimerChecked}
            />
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default StartupWizardIntroduction;
