import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';
import { PencilSquareIcon, PresentationChartLineIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import axios from '../../../axios';

const PeopleStep = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [template, setTemplate] = useState(null);

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/v1/live_data/user_data', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        setUsers(response.data.entity.users);
        console.log('Fetched Users:', response.data.entity.users); // Debug log
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (!formData.templateId) return;

      try {
        const response = await axios.get(`/api/v1/data_collections/templates/${formData.templateId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        console.log('Template data:', response.data); // Debug log
        setTemplate(response.data);
        
        // If recommended period type exists and no period type is selected yet
        if (response.data.recommended_period_type && !formData.periodType) {
          setFormData(prev => ({
            ...prev,
            periodType: response.data.recommended_period_type
          }));
        }
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [formData.templateId]);

  // Filter users based on search input
  const filteredUsers = users.filter(
    (user) =>
      `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Toggle viewer assignment
  const toggleViewer = (userId) => {
    const newViewers = formData.viewers.includes(userId)
      ? formData.viewers.filter((id) => id !== userId)
      : [...formData.viewers, userId];
    setFormData({ ...formData, viewers: newViewers });
  };

  // Toggle respondent assignment
  const toggleRespondent = (userId) => {
    const newRespondents = formData.respondents.includes(userId)
      ? formData.respondents.filter((id) => id !== userId)
      : [...formData.respondents, userId];
    setFormData({ ...formData, respondents: newRespondents });
  };

  // UI
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900 mb-4">{t('Select participants')}</h2>

        {/* Search input */}
        <div className="mb-6">
          <input
            type="text"
            placeholder={t('Search users...')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color"
          />
        </div>

        {/* Anonymity setting */}
        <div className="mb-6 bg-gray-50 p-4 rounded-md">
        <Switch.Group>
          <div className="flex items-center justify-between">
            <Switch.Label className="mr-4">
              <div className="flex items-center">
                <span className="text-base font-medium text-gray-900">
                  {t('Anonymous data collection')}
                </span>
                {template?.recommended_anonymous == true && (
                  <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                    <LightBulbIcon className="h-3 w-3 mr-1" />
                    {t('Recommended')}
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-500">{t('Responses will be anonymous')}</p>
            </Switch.Label>
              <Switch
                checked={formData.isAnonymous}
                onChange={(isAnonymous) => {
                  setFormData({
                    ...formData,
                    isAnonymous,
                    // Reset mandatory if non-anonymous
                    isMandatory: isAnonymous ? true : formData.isMandatory,
                  });
                }}
                className={`${
                  formData.isAnonymous ? 'bg-secondary-color' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    formData.isAnonymous ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </Switch.Group>

          {formData.isAnonymous && (
            <div className="mt-4">
              <Switch.Group>
                <div className="flex items-center justify-between">
                  <Switch.Label className="mr-4">
                    <span className="text-sm font-medium text-gray-900">
                      {t('Mandatory response')}
                    </span>
                    <p className="text-sm text-gray-500">
                      {t('All respondents must submit data before results can be viewed')}
                    </p>
                  </Switch.Label>
                  <Switch
                    checked={formData.isMandatory}
                    onChange={(isMandatory) =>
                      setFormData({ ...formData, isMandatory })
                    }
                    className={`${
                      formData.isMandatory ? 'bg-secondary-color' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        formData.isMandatory ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>
          )}
        </div>

        {/* Users list */}
        <div className="bg-white shadow rounded-md">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0"
            >
              <div className="flex items-center space-x-3">
                {user.avatar_url ? (
                  <img
                    src={user.avatar_url}
                    alt={`${user.first_name} ${user.last_name}`}
                    className="h-10 w-10 rounded-full"
                  />
                ) : (
                  <div className="h-10 w-10 rounded-full bg-secondary-color flex items-center justify-center text-white">
                    {user.first_name[0]}{user.last_name[0]}
                  </div>
                )}
                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {user.first_name} {user.last_name}
                  </div>
                  <div className="text-sm text-gray-500">{user.email}</div>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                {/* Viewer toggle */}
                <button
                  type="button"
                  onClick={() => toggleViewer(user.id)}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                    formData.viewers.includes(user.id)
                    ? 'bg-secondary-color text-white'
                    : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                  }`}
              >
                  <PresentationChartLineIcon className="h-4 w-4 mr-1" />
                  {t('Can View Report')}
                </button>

                {/* Respondent toggle */}
                <button
                  type="button"
                  onClick={() => toggleRespondent(user.id)}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                    formData.respondents.includes(user.id)
                    ? 'bg-secondary-color text-white'
                    : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                  }`}
              >
                  <PencilSquareIcon className="h-4 w-4 mr-1" />
                  {t('Provides Data')}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PeopleStep;
