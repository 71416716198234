import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import {
    LockClosedIcon,
    UserIcon,
    BuildingOfficeIcon,
    EllipsisHorizontalIcon,
    ArrowUpRightIcon,
    CircleStackIcon,
    PlayIcon,
    ClipboardDocumentCheckIcon,
    CheckCircleIcon,
    ArchiveBoxIcon,
    ArrowUturnLeftIcon,
    TrashIcon,
    CurrencyDollarIcon,
    HeartIcon,
} from '@heroicons/react/24/outline';
import { TaskDetailModal } from './TaskCard';
import axios from '../axios';
import NewTaskList from './NewTaskList';

const UserInitials = ({ firstName, lastName }) => {
    const initials = `${firstName?.charAt(0) || '?'}${lastName?.charAt(0) || '?'}`.toUpperCase();
    return (
        <div className="h-8 w-8 rounded-full bg-secondary-color flex-shrink-0 flex items-center justify-center">
            <span className="text-white text-xs font-bold">{initials}</span>
        </div>
    );
};

const TaskListView = ({ tasks, users, onUpdate }) => {
    const { t } = useTranslation();
    const [editingId, setEditingId] = useState(null);
    const [editingName, setEditingName] = useState('');
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    
    useEffect(() => {
        if (selectedTask) {
            const updatedTask = tasks.find(t => t.id === selectedTask.id);
            if (updatedTask) {
                setSelectedTask(updatedTask);
            }
        }
    }, [tasks]);

    const handleNameEdit = (task) => {
        setEditingId(task.id);
        setEditingName(task.name);
    };

    const handleDetailModalOpen = (task) => {
        setSelectedTask(task);
        setShowDetailModal(true);
    };

    const handleDetailModalClose = () => {
        setShowDetailModal(false);
        setSelectedTask(null);
    };

    // All update handlers should now trigger the parent's refresh
    const handleNameUpdate = async (taskId) => {
        try {
            await axios.put(`/api/v1/tasks/${taskId}`,
                { task: { name: editingName } },
                { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
            );
            setEditingId(null);
            onUpdate(); // Trigger parent refresh
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const handleUpdate = async (taskId, field, value) => {
        try {
            await axios.put(`/api/v1/tasks/${taskId}`,
                { task: { [field]: value } },
                { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
            );
            onUpdate(); // Trigger parent refresh
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const handleArchive = async (taskId) => {
        try {
            await axios.post(`/api/v1/tasks/${taskId}/archive`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            });
            onUpdate(); // Trigger parent refresh
        } catch (error) {
            console.error('Error archiving task:', error);
        }
    };

    const handleUnarchive = async (taskId) => {
        try {
            await axios.post(`/api/v1/tasks/${taskId}/unarchive`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            });
            onUpdate(); // Trigger parent refresh
        } catch (error) {
            console.error('Error unarchiving task:', error);
        }
    };

    const handlePermanentDelete = async (taskId) => {
        try {
            await axios.delete(`/api/v1/tasks/${taskId}/permanent_delete`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            });
            onUpdate(); // Trigger parent refresh
        } catch (error) {
            console.error('Error deleting task permanently:', error);
        }
    };

    // Sort tasks by status priority: approval > current > backlog > done
    const getStatusPriority = (status) => {
        const priorities = { approval: 0, current: 1, backlog: 2, done: 3 };
        return priorities[status] || 4;
    };

    const sortedTasks = [...tasks].sort((a, b) => {
        return getStatusPriority(a.status) - getStatusPriority(b.status);
    });

    const areas = [
        { id: 'cybersecurity', icon: LockClosedIcon },
        { id: 'data_privacy', icon: UserIcon },
        { id: 'organization', icon: BuildingOfficeIcon },
        { id: 'culture', icon: HeartIcon },
        { id: 'finance', icon: CurrencyDollarIcon },
        { id: 'other', icon: EllipsisHorizontalIcon },
    ];

    const priorities = [
        { id: 'normal', color: 'bg-gray-100 text-gray-800' },
        { id: 'high', color: 'bg-orange-100 text-orange-800' },
        { id: 'very_high', color: 'bg-red-100 text-red-800' },
    ];

    const statuses = [
        { id: 'backlog' },
        { id: 'current' },
        { id: 'approval' },
        { id: 'done' },
    ];

    // Status icon mapping
    const statusIcons = {
        backlog: CircleStackIcon,
        current: PlayIcon,
        approval: ClipboardDocumentCheckIcon,
        done: CheckCircleIcon
    };

    return (
        <div className="mt-8">
            <div className="overflow-x-auto">
                {/* Desktop List Layout */}
                <div className="hidden lg:block">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="-my-2 overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Area')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Status')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Priority')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Task')}
                                            </th>
                                            <th scope="col" className="pl-2 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Assigned to')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {t('Approval by')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 w-24"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {tasks.map((task) => {
                                            const assignedToUser = users.find(user => user.id === task.assigned_to_id);
                                            const approvedByUser = users.find(user => user.id === task.approved_by_id);

                                            return (
                                                <tr key={task.id} className={`${task.archived ? 'bg-red-50 hover:bg-red-100' : 'bg-white hover:bg-gray-50'}`}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Menu>
                                                            <Menu.Button className="flex items-center">
                                                                {React.createElement(areas.find(a => a.id === task.area)?.icon || EllipsisHorizontalIcon, { className: 'h-5 w-5 text-gray-500 mr-2' })}
                                                                <span className="text-sm text-gray-900">{t(`areas.${task.area}`)}</span>
                                                            </Menu.Button>
                                                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {areas.map((area) => (
                                                                        <Menu.Item key={area.id}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                                    onClick={() => handleUpdate(task.id, 'area', area.id)}
                                                                                >
                                                                                    {React.createElement(area.icon, { className: 'w-5 h-5 mr-3' })}
                                                                                    {t(`area_${area.id}`)}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Menu>
                                                            <Menu.Button className="flex items-center">
                                                                {React.createElement(statusIcons[task.status], { className: 'h-5 w-5 text-gray-500 mr-2' })}
                                                                <span className="text-sm text-gray-900">{t(`status_${task.status}`)}</span>
                                                            </Menu.Button>
                                                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {statuses.map((status) => (
                                                                        <Menu.Item key={status.id}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                                    onClick={() => handleUpdate(task.id, 'status', status.id)}
                                                                                >
                                                                                    {React.createElement(statusIcons[status.id], { className: 'h-5 w-5 mr-2' })}
                                                                                    {t(`status_${status.id}`)}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Menu>
                                                            <Menu.Button className="flex items-center">
                                                                <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priorities.find(p => p.id === task.priority)?.color}`}>
                                                                    {t(`priority_${task.priority}`)}
                                                                </span>
                                                            </Menu.Button>
                                                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {priorities.map((priority) => (
                                                                        <Menu.Item key={priority.id}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                                    onClick={() => handleUpdate(task.id, 'priority', priority.id)}
                                                                                >
                                                                                    <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priority.color}`}>
                                                                                        {t(`priority_${priority.id}`)}
                                                                                    </span>
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-wrap">
                                                        <div className="flex items-center">
                                                            {editingId === task.id ? (
                                                                <input
                                                                    type="text"
                                                                    value={editingName}
                                                                    onChange={(e) => setEditingName(e.target.value)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleNameUpdate(task.id);
                                                                        }
                                                                    }}
                                                                    onBlur={() => handleNameUpdate(task.id)}
                                                                    className="text-sm rounded-md focus:border-secondary-color focus:ring-secondary-color w-full"
                                                                    autoFocus
                                                                />
                                                            ) : (
                                                                <>
                                                                    <span
                                                                        className="text-sm font-medium text-gray-900 cursor-pointer"
                                                                        onClick={() => handleNameEdit(task)}
                                                                    >
                                                                        {task.name}
                                                                    </span>
                                                                    <button
                                                                        onClick={() => handleDetailModalOpen(task)}
                                                                        className="ml-2 flex-shrink-0 text-secondary-color hover:text-secondary-color/80"
                                                                    >
                                                                        <ArrowUpRightIcon className="h-4 w-4" />
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="pr-6 pl-2 py-4 whitespace-wrap">
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <Menu.Button className="flex items-center">
                                                                <div className="flex items-center">
                                                                    {assignedToUser?.avatar_url ? (
                                                                        <img
                                                                            src={assignedToUser.avatar_url}
                                                                            alt={`${assignedToUser.first_name} ${assignedToUser.last_name}`}
                                                                            className="h-8 w-8 flex-shrink-0 rounded-full bg-secondary-color object-cover object-center"
                                                                        />
                                                                    ) : (
                                                                        <UserInitials
                                                                            firstName={assignedToUser?.first_name}
                                                                            lastName={assignedToUser?.last_name}
                                                                        />
                                                                    )}
                                                                    <span className="ml-2 text-sm text-gray-900 max-w-[180px] text-left">
                                                                        {task.assigned_to_name || t('unknown')}
                                                                    </span>
                                                                </div>
                                                            </Menu.Button>
                                                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {users.map((user) => (
                                                                        <Menu.Item key={user.id}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                                    onClick={() => handleUpdate(task.id, 'assigned_to_id', user.id)}
                                                                                >
                                                                                    {user.avatar_url ? (
                                                                                        <img
                                                                                            src={user.avatar_url}
                                                                                            alt={`${user.first_name} ${user.last_name}`}
                                                                                            className="h-8 w-8 rounded-full mr-2 bg-secondary-color object-cover object-center"
                                                                                        />
                                                                                    ) : (
                                                                                        <UserInitials
                                                                                            firstName={user.first_name}
                                                                                            lastName={user.last_name}
                                                                                        />
                                                                                    )}
                                                                                    {user.first_name} {user.last_name}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-wrap">
                                                        <Menu as="div" className="relative inline-block text-left">
                                                            <Menu.Button className="flex items-center">
                                                                <div className="flex items-center">
                                                                    {approvedByUser?.avatar_url ? (
                                                                        <img
                                                                            src={approvedByUser.avatar_url}
                                                                            alt={`${approvedByUser.first_name} ${approvedByUser.last_name}`}
                                                                            className="h-8 w-8 flex-shrink-0 rounded-full bg-secondary-color object-cover object-center"
                                                                        />
                                                                    ) : (
                                                                        <UserInitials
                                                                            firstName={approvedByUser?.first_name}
                                                                            lastName={approvedByUser?.last_name}
                                                                        />
                                                                    )}
                                                                    <span className="ml-2 text-sm text-gray-900 max-w-[180px] text-left">
                                                                        {task.approved_by_name || t('unknown')}
                                                                    </span>
                                                                </div>
                                                            </Menu.Button>
                                                            <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {users.map((user) => (
                                                                        <Menu.Item key={user.id}>
                                                                            {({ active }) => (
                                                                                <button
                                                                                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                                    onClick={() => handleUpdate(task.id, 'approved_by_id', user.id)}
                                                                                >
                                                                                    {user.avatar_url ? (
                                                                                        <img
                                                                                            src={user.avatar_url}
                                                                                            alt={`${user.first_name} ${user.last_name}`}
                                                                                            className="h-8 w-8 rounded-full mr-2 bg-secondary-color object-cover object-center"
                                                                                        />
                                                                                    ) : (
                                                                                        <UserInitials
                                                                                            firstName={user.first_name}
                                                                                            lastName={user.last_name}
                                                                                        />
                                                                                    )}
                                                                                    {user.first_name} {user.last_name}
                                                                                </button>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right space-x-2">
                                                        {task.archived ? (
                                                            <>
                                                                <button
                                                                    onClick={() => handleUnarchive(task.id)}
                                                                    className="text-red-400 hover:text-gray-600"
                                                                >
                                                                    <ArrowUturnLeftIcon className="w-4 h-4" />
                                                                </button>
                                                                <button
                                                                    onClick={() => handlePermanentDelete(task.id)}
                                                                    className="text-red-600 hover:text-red-700"
                                                                >
                                                                    <TrashIcon className="w-4 h-4" />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button
                                                                onClick={() => handleArchive(task.id)}
                                                                className="text-red-400 hover:text-gray-600"
                                                            >
                                                                <ArchiveBoxIcon className="w-4 h-4" />
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <NewTaskList onAdd={onUpdate} />
                </div>
                {/* Mobile Card Layout */}
                <div className="lg:hidden space-y-4">
                    {tasks.map((task) => (
                        <div key={task.id}
                            className={`shadow rounded-lg p-4 ${task.archived ? 'bg-red-50' : 'bg-white'}`}>
                            <div className="space-y-3">
                                {/* Status and Area */}
                                <div className="flex justify-between items-start">
                                    <Menu>
                                        <Menu.Button className="flex items-center">
                                            {React.createElement(areas.find(a => a.id === task.area)?.icon || EllipsisHorizontalIcon, { className: 'h-5 w-5 text-gray-500 mr-2' })}
                                            <span className="text-sm text-gray-900">{t(`areas.${task.area}`)}</span>
                                        </Menu.Button>
                                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {areas.map((area) => (
                                                    <Menu.Item key={area.id}>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                onClick={() => handleUpdate(task.id, 'area', area.id)}
                                                            >
                                                                {React.createElement(area.icon, { className: 'w-5 h-5 mr-3' })}
                                                                {t(`area_${area.id}`)}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                    <Menu>
                                        <Menu.Button className="flex items-center">
                                            {React.createElement(statusIcons[task.status], { className: 'h-5 w-5 text-gray-500 mr-2' })}
                                            <span className="text-sm text-gray-900">{t(`status_${task.status}`)}</span>
                                        </Menu.Button>
                                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {statuses.map((status) => (
                                                    <Menu.Item key={status.id}>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                onClick={() => handleUpdate(task.id, 'status', status.id)}
                                                            >
                                                                {React.createElement(statusIcons[status.id], { className: 'h-5 w-5 mr-2' })}
                                                                {t(`status_${status.id}`)}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>

                                {/* Task Name and Detail Button */}
                                <div className="flex items-center justify-between">
                                    {editingId === task.id ? (
                                        <input
                                            type="text"
                                            value={editingName}
                                            onChange={(e) => setEditingName(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleNameUpdate(task.id);
                                                }
                                            }}
                                            onBlur={() => handleNameUpdate(task.id)}
                                            className="text-sm rounded-md focus:border-secondary-color focus:ring-secondary-color w-full"
                                            autoFocus
                                        />
                                    ) : (
                                        <>
                                            <span
                                                className="text-sm font-medium text-gray-900 cursor-pointer"
                                                onClick={() => handleNameEdit(task)}
                                            >
                                                {task.name}
                                            </span>
                                            <button
                                                onClick={() => handleDetailModalOpen(task)}
                                                className="text-secondary-color hover:text-secondary-color/80"
                                            >
                                                <ArrowUpRightIcon className="h-4 w-4" />
                                            </button>
                                        </>
                                    )}
                                </div>

                                {/* Priority Badge */}
                                <Menu>
                                    <Menu.Button className="inline-flex items-center">
                                        <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priorities.find(p => p.id === task.priority)?.color}`}>
                                            {t(`priority_${task.priority}`)}
                                        </span>
                                    </Menu.Button>
                                    <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {priorities.map((priority) => (
                                                <Menu.Item key={priority.id}>
                                                    {({ active }) => (
                                                        <button
                                                            className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                            onClick={() => handleUpdate(task.id, 'priority', priority.id)}
                                                        >
                                                            <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${priority.color}`}>
                                                                {t(`priority_${priority.id}`)}
                                                            </span>
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Menu>

                                {/* Assigned Info */}
                                <div className="flex flex-col space-y-2">
                                    <Menu>
                                        <div className="flex items-center">
                                            <div className="ml-2">
                                                <div className="text-xs text-gray-500">{t('Assigned to')}</div>
                                                <Menu.Button className="flex items-center">
                                                    <span className="text-sm text-gray-900">{task.assigned_to_name || t('unknown')}</span>
                                                </Menu.Button>
                                            </div>
                                        </div>
                                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {users.map((user) => (
                                                    <Menu.Item key={user.id}>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                onClick={() => handleUpdate(task.id, 'assigned_to_id', user.id)}
                                                            >
                                                                {user.avatar_url ? (
                                                                    <img
                                                                        src={user.avatar_url}
                                                                        alt={`${user.first_name} ${user.last_name}`}
                                                                        className="h-8 w-8 rounded-full mr-2"
                                                                    />
                                                                ) : (
                                                                    <UserInitials
                                                                        firstName={user.first_name}
                                                                        lastName={user.last_name}
                                                                    />
                                                                )}
                                                                <span className="ml-2 text-sm text-gray-900 text-left">
                                                                    {user.first_name} {user.last_name}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Menu>

                                    <Menu>
                                        <div className="flex items-center">
                                            <div className="ml-2">
                                                <div className="text-xs text-gray-500">{t('Approval by')}</div>
                                                <Menu.Button className="flex items-center">
                                                    <span className="text-sm text-gray-900">{task.approved_by_name || t('unknown')}</span>
                                                </Menu.Button>
                                            </div>
                                        </div>
                                        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {users.map((user) => (
                                                    <Menu.Item key={user.id}>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}
                                                                onClick={() => handleUpdate(task.id, 'approved_by_id', user.id)}
                                                            >
                                                                {user.avatar_url ? (
                                                                    <img
                                                                        src={user.avatar_url}
                                                                        alt={`${user.first_name} ${user.last_name}`}
                                                                        className="h-8 w-8 rounded-full mr-2"
                                                                    />
                                                                ) : (
                                                                    <UserInitials
                                                                        firstName={user.first_name}
                                                                        lastName={user.last_name}
                                                                    />
                                                                )}
                                                                <span className="ml-2 text-sm text-gray-900 text-left">
                                                                    {user.first_name} {user.last_name}
                                                                </span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>

                                {/* Archive/Delete Controls */}
                                <div className="mt-4 flex justify-end space-x-2">
                                    {task.archived ? (
                                        <>
                                            <button
                                                onClick={() => handleUnarchive(task.id)}
                                                className="text-red-400 hover:text-gray-600"
                                            >
                                                <ArrowUturnLeftIcon className="w-5 h-5" />
                                            </button>
                                            <button
                                                onClick={() => handlePermanentDelete(task.id)}
                                                className="text-red-600 hover:text-red-700"
                                            >
                                                <TrashIcon className="w-5 h-5" />
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            onClick={() => handleArchive(task.id)}
                                            className="text-red-400 hover:text-gray-600"
                                        >
                                            <ArchiveBoxIcon className="w-5 h-5" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <NewTaskList onAdd={onUpdate} />
                </div>
            </div>
            {/* TaskDetailModal */}
            {showDetailModal && selectedTask && (
                <TaskDetailModal
                    isOpen={showDetailModal}
                    task={selectedTask}
                    onClose={handleDetailModalClose}
                    onUpdate={() => {
                        onUpdate(); // This is fetchTasks from KanbanBoard
                    }}
                    users={users}
                />
            )}
        </div>
    );
};

export default TaskListView;