// src/components/wizard_components/DataPrivacyWizard/RiskSummaryTableAfterTreatment.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

// Likelihood options
const likelihoodOptions = [
  { id: 'rare', value: 1, key: 'rare' },
  { id: 'unlikely', value: 2, key: 'unlikely' },
  { id: 'possible', value: 3, key: 'possible' },
  { id: 'likely', value: 4, key: 'likely' },
  { id: 'almost_certain', value: 5, key: 'almostCertain' }
];

// Severity options
const severityOptions = [
  { id: 'negligible', value: 1, key: 'negligible' },
  { id: 'minor', value: 2, key: 'minor' },
  { id: 'moderate', value: 3, key: 'moderate' },
  { id: 'major', value: 4, key: 'major' },
  { id: 'severe', value: 5, key: 'severe' }
];

// Function to determine risk level
const calculateRiskLevel = (likelihood, severity) => {
  const riskScore = likelihood * severity;
  
  if (riskScore >= 15) return { level: 'high', color: 'bg-red-100 text-red-800' };
  if (riskScore >= 8) return { level: 'medium', color: 'bg-yellow-100 text-yellow-800' };
  return { level: 'low', color: 'bg-green-100 text-green-800' };
};

// Risk scenarios data for names
const riskScenarios = [
  { id: 'unauthorized_access', key: 'unauthorizedAccess' },
  { id: 'data_breach', key: 'dataBreach' },
  { id: 'excessive_collection', key: 'excessiveCollection' },
  { id: 'unauthorized_sharing', key: 'unauthorizedSharing' },
  { id: 'data_loss', key: 'dataLoss' },
  { id: 'inaccurate_processing', key: 'inaccurateProcessing' },
  { id: 'rights_hindrance', key: 'rightsHindrance' },
  { id: 'beyond_purpose', key: 'beyondPurpose' },
  { id: 'improper_legal_basis', key: 'improperLegalBasis' },
  { id: 'excessive_retention', key: 'excessiveRetention' }
];

const RiskSummaryTableAfterTreatment = ({ 
  selectedRisks, 
  customRisks, 
  onUpdateRiskAssessment,
  readOnly = false
}) => {
  const { t } = useTranslation();
  
  // State for tracking dropdown visibility and reassessed values
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [reassessedRisks, setReassessedRisks] = useState({});
  
  // Initialize reassessed risks based on original risk assessment
  useEffect(() => {
    const initialReassessment = {};
    selectedRisks.forEach(risk => {
      initialReassessment[risk.id] = {
        likelihood: risk.likelihood,
        severity: risk.severity,
        originalLikelihood: risk.likelihood,
        originalSeverity: risk.severity
      };
    });
    setReassessedRisks(initialReassessment);
  }, [selectedRisks]);

  // Effect to handle clicks outside dropdowns to close them
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside a dropdown toggle or menu
      if (!event.target.closest('.dropdown-toggle') && 
          !event.target.closest('.dropdown-menu')) {
        setDropdownOpen({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get the name of a risk
  const getRiskName = (riskId) => {
    // First check custom risks
    const customRisk = customRisks?.find(risk => risk.id === riskId && risk.isCustom);
    if (customRisk) return customRisk.name;
    
    // Then check built-in risks
    const builtInRisk = riskScenarios.find(risk => risk.id === riskId);
    if (builtInRisk) return t(`DataPrivacyWizard.dpia.riskAssessment.risks.${builtInRisk.key}`);
    
    // Return the risk ID if not found
    return riskId;
  };

  // Get likelihood label
  const getLikelihoodLabel = (value) => {
    const option = likelihoodOptions.find(opt => opt.value === value);
    return option ? t(`DataPrivacyWizard.dpia.riskAssessment.likelihoodOptions.${option.key}`) : '';
  };

  // Get severity label
  const getSeverityLabel = (value) => {
    const option = severityOptions.find(opt => opt.value === value);
    return option ? t(`DataPrivacyWizard.dpia.riskAssessment.severityOptions.${option.key}`) : '';
  };

  // Toggle dropdown visibility
  const toggleDropdown = (riskId, field) => {
    if (readOnly) return;
    
    setDropdownOpen(prev => {
      // Close all other dropdowns first
      const newState = {};
      
      // Toggle the current dropdown
      newState[`${riskId}_${field}`] = !prev[`${riskId}_${field}`];
      return newState;
    });
  };

  // Handle selection of dropdown options
  const handleSelect = (riskId, field, value) => {
    setReassessedRisks(prev => {
      // Update the specific risk object by its ID
      const updatedRisk = {
        ...prev[riskId],
        [field]: value,
      };
      const updatedRisks = {
        ...prev,
        [riskId]: updatedRisk,
      };
  
      // If your parent expects an array, convert the object to an array.
      if (onUpdateRiskAssessment) {
        const updatedArray = Object.keys(updatedRisks).map(id => ({
          id,
          ...updatedRisks[id],
        }));
        onUpdateRiskAssessment(updatedArray);
      }
      return updatedRisks;
    });
    setDropdownOpen({});
  };  

  // Calculate if a risk has been reassessed
  const isReassessed = (riskId) => {
    const reassessedRisk = reassessedRisks[riskId];
    if (!reassessedRisk) return false;
    
    return reassessedRisk.likelihood !== reassessedRisk.originalLikelihood || 
           reassessedRisk.severity !== reassessedRisk.originalSeverity;
  };

  // Calculate reduction percentage
  const calculateReduction = (originalScore, newScore) => {
    if (originalScore === 0) return 0;
    const reduction = ((originalScore - newScore) / originalScore) * 100;
    return Math.round(reduction);
  };

  // Render the table with proper structure and clean design
  return (
    <div className="mb-6 bg-white border border-gray-200 rounded-lg overflow-visible">
      <div className="overflow-visible">
        {selectedRisks.length > 0 ? (
          <div className="overflow-visible">
            {selectedRisks.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200 overflow-visible">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('DataPrivacyWizard.dpia.riskTreatment.riskScenario', 'Risikoszenario')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('DataPrivacyWizard.dpia.riskAssessment.likelihood', 'Wahrscheinlichkeit')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('DataPrivacyWizard.dpia.riskAssessment.severity', 'Schweregrad')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t('DataPrivacyWizard.dpia.riskTreatment.riskLevel', 'Risikoniveau')}
                    </th>
                  </tr>
                </thead>
                <tbody className="overflow-visible">
                  {selectedRisks.map((risk, index) => {
                    const originalRiskLevel = calculateRiskLevel(risk.likelihood, risk.severity);
                    const reassessedRisk = reassessedRisks[risk.id] || { likelihood: risk.likelihood, severity: risk.severity };
                    const newRiskLevel = calculateRiskLevel(reassessedRisk.likelihood, reassessedRisk.severity);
                    
                    const originalScore = risk.likelihood * risk.severity;
                    const newScore = reassessedRisk.likelihood * reassessedRisk.severity;
                    const reductionPercent = calculateReduction(originalScore, newScore);
                    
                    // Determine if this is the last risk group
                    const isLastRiskGroup = index === selectedRisks.length - 1;
                    
                    return (
                      <React.Fragment key={risk.id}>
                        {/* Original values row */}
                        <tr className="group">
                          <td rowSpan="3" className="px-6 py-4 text-sm font-medium text-gray-900 align-middle">
                            {getRiskName(risk.id)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {getLikelihoodLabel(risk.likelihood)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500">
                            {getSeverityLabel(risk.severity)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500 text-center">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${originalRiskLevel.color}`}>
                              {t(`DataPrivacyWizard.dpia.riskAssessment.riskLevel.${originalRiskLevel.level}`, originalRiskLevel.level === 'medium' ? 'Mittel' : originalRiskLevel.level === 'high' ? 'Hoch' : 'Niedrig')}
                            </span>
                          </td>
                        </tr>
                        
                        {/* Arrow row - no border between rows of the same risk */}
                        <tr className="h-8 group-hover:bg-gray-50">
                          <td className="px-6 text-center">
                            <div className="text-gray-400">↓</div>
                          </td>
                          <td className="px-6 text-center">
                            <div className="text-gray-400">↓</div>
                          </td>
                          <td className="px-6 text-center">
                            <div className="text-gray-400">↓</div>
                          </td>
                        </tr>
                        
                        {/* Reassessed values row - with dropdowns */}
                        <tr className={`group-hover:bg-gray-50 overflow-visible ${!isLastRiskGroup ? 'border-b border-gray-200' : ''}`}>
                          <td className="px-6 py-4 text-sm text-gray-500 relative overflow-visible">
                            <div className="relative dropdown-toggle">
                              {/* Dropdown trigger */}
                              <div 
                                className={`cursor-pointer flex items-center justify-between w-full border border-gray-300 rounded-md pl-3 pr-2 py-2 text-sm ${
                                  reassessedRisk.likelihood > risk.likelihood 
                                    ? 'bg-red-50 border-red-200' 
                                    : reassessedRisk.likelihood < risk.likelihood 
                                      ? 'bg-green-50 border-green-200' 
                                      : ''
                                } focus:outline-none focus:ring-primary-color focus:border-primary-color`}
                                onClick={() => toggleDropdown(risk.id, 'likelihood')}
                              >
                                <span>
                                  {getLikelihoodLabel(reassessedRisk.likelihood)}
                                </span>
                                {!readOnly && (
                                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                )}
                              </div>
                              
                              {/* Dropdown for likelihood options */}
                              {dropdownOpen[`${risk.id}_likelihood`] && (
                                <div 
                                  className="absolute left-0 z-50 mt-1 w-64 bg-white rounded-md shadow-lg overflow-hidden border border-gray-200 dropdown-menu"
                                >
                                  {likelihoodOptions.map(option => (
                                    <div
                                      key={option.id}
                                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleSelect(risk.id, 'likelihood', option.value)}
                                    >
                                      <div className="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center mr-3">
                                        <span>{option.value}</span>
                                      </div>
                                      <span>
                                        {t(`DataPrivacyWizard.dpia.riskAssessment.likelihoodOptions.${option.key}`)}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </td>
                          
                          <td className="px-6 py-4 text-sm text-gray-500 relative overflow-visible">
                            <div className="relative dropdown-toggle">
                              {/* Dropdown trigger */}
                              <div 
                                className={`cursor-pointer flex items-center justify-between w-full border border-gray-300 rounded-md pl-3 pr-2 py-2 text-sm ${
                                  reassessedRisk.severity > risk.severity 
                                    ? 'bg-red-50 border-red-200' 
                                    : reassessedRisk.severity < risk.severity 
                                      ? 'bg-green-50 border-green-200' 
                                      : ''
                                } focus:outline-none focus:ring-primary-color focus:border-primary-color`}
                                onClick={() => toggleDropdown(risk.id, 'severity')}
                              >
                                <span>
                                  {getSeverityLabel(reassessedRisk.severity)}
                                </span>
                                {!readOnly && (
                                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                )}
                              </div>
                              
                              {/* Dropdown for severity options */}
                              {dropdownOpen[`${risk.id}_severity`] && (
                                <div 
                                  className="absolute left-0 z-50 mt-1 w-64 bg-white rounded-md shadow-lg overflow-hidden border border-gray-200 dropdown-menu"
                                >
                                  {severityOptions.map(option => (
                                    <div
                                      key={option.id}
                                      className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => handleSelect(risk.id, 'severity', option.value)}
                                    >
                                      <div className="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center mr-3">
                                        <span>{option.value}</span>
                                      </div>
                                      <span>
                                        {t(`DataPrivacyWizard.dpia.riskAssessment.severityOptions.${option.key}`)}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </td>
                          
                          <td className="px-6 py-4 text-sm text-gray-500 text-center">
                            <div className="flex flex-col items-center">
                              {/* Always show the risk level */}
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${newRiskLevel.color}`}>
                                {t(`DataPrivacyWizard.dpia.riskAssessment.riskLevel.${newRiskLevel.level}`, newRiskLevel.level === 'medium' ? 'Mittel' : newRiskLevel.level === 'high' ? 'Hoch' : 'Niedrig')}
                              </span>
                              
                              {/* Show reduction percentage even if it's 0% */}
                              {isReassessed(risk.id) && (
                                <span className="text-xs text-gray-500 mt-1">
                                  {reductionPercent}% {t('DataPrivacyWizard.dpia.riskTreatment.reduction', 'Minderung')}
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="text-center py-4 text-sm text-gray-500">
                {t('DataPrivacyWizard.dpia.riskTreatment.noHighMediumRisks', 'Keine Risiken mit hoher oder mittlerer Einstufung vorhanden.')}
              </p>
            )}
          </div>
        ) : (
          <p className="text-center py-4 text-sm text-gray-500">
            {t('DataPrivacyWizard.dpia.riskTreatment.noRisksIdentified', 'Es wurden noch keine Risiken identifiziert.')}
          </p>
        )}
      </div>
    </div>
  );
};

export default RiskSummaryTableAfterTreatment;