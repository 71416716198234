// src/components/FlowChart/nodes/ActivityNode.js
import React, { useState, useEffect, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import axios from 'axios';

const ActivityNode = ({ data, selected, isConnectable }) => {
  const [subjectAsset, setSubjectAsset] = useState(data.subjectAsset || null);
  const [objectAsset, setObjectAsset] = useState(data.objectAsset || null);
  const [verb, setVerb] = useState(data.verb || '');
  const [assetTypes, setAssetTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjectQuery, setSubjectQuery] = useState('');
  const [objectQuery, setObjectQuery] = useState('');
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [objectOpen, setObjectOpen] = useState(false);

  // Fetch asset types on component mount
  useEffect(() => {
    fetchAssetTypes();
  }, []);

  // Update node data when selections change
  useEffect(() => {
    if (data.updateNodeData) {
      data.updateNodeData({
        subjectAsset,
        objectAsset,
        verb
      });
    }
  }, [subjectAsset, objectAsset, verb, data]);

  // Fetch all asset types
  const fetchAssetTypes = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/v1/static_data/asset_types');
      
      if (response.data && response.data.length > 0) {
        console.log("Asset types loaded:", response.data.length);
        setAssetTypes(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching asset types:', err);
      setLoading(false);
    }
  };

  // Debounced search for potentially large lists
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      // If the query is empty, don't perform search
      if (!searchQuery.trim()) return;
    }, 300),
    [assetTypes]
  );

  // Handle query changes and trigger search
  const handleSubjectQueryChange = (newQuery) => {
    setSubjectQuery(newQuery);
    debouncedSearch(newQuery);
  };
  
  const handleObjectQueryChange = (newQuery) => {
    setObjectQuery(newQuery);
    debouncedSearch(newQuery);
  };

  // Filter the asset types list based on the query
  const filteredSubjectTypes = subjectQuery === ''
    ? assetTypes
    : assetTypes.filter((assetType) => {
        return assetType.asset_type.toLowerCase().includes(subjectQuery.toLowerCase());
      });
      
  const filteredObjectTypes = objectQuery === ''
    ? assetTypes
    : assetTypes.filter((assetType) => {
        return assetType.asset_type.toLowerCase().includes(objectQuery.toLowerCase());
      });

  return (
    <div 
      className={`bg-blue-50 border rounded-md p-3 w-96 shadow-sm transition-all 
        ${selected ? 'border-blue-500 ring-2 ring-blue-200' : 'border-blue-300'}`}
    >
      <Handle 
        type="target" 
        position={Position.Top} 
        className="!bg-blue-500 !border-blue-700"
        isConnectable={isConnectable}
      />
      
      <div className="flex items-center mb-2">
        <ArrowPathIcon className="h-4 w-4 text-blue-700 mr-1 flex-shrink-0" />
        <div className="font-medium text-sm text-blue-900 truncate">
          {data.label || 'Activity'}
        </div>
      </div>
      
      <div className="flex flex-col space-y-2">
        {/* Subject Asset Selector */}
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Subject
          </label>
          <Combobox
            as="div"
            value={subjectAsset}
            onChange={(asset) => {
              setSubjectAsset(asset);
              setSubjectQuery('');
              setSubjectOpen(false); // Close dropdown after selection
            }}
            open={subjectOpen}
            onOpen={() => setSubjectOpen(true)}
            onClose={() => setSubjectOpen(false)}
          >
            <div className="relative">
              <ComboboxInput
                className="block w-full rounded-md border-gray-300 shadow-sm 
                           focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
                onChange={(event) => handleSubjectQueryChange(event.target.value)}
                displayValue={(asset) => asset?.asset_type || ''}
                placeholder="Select a subject asset type"
                onFocus={() => setSubjectOpen(true)}
              />
              
              <ComboboxButton className="absolute inset-y-0 right-0 flex items-center px-2">
                <ChevronUpDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              
              {filteredSubjectTypes.length > 0 && (
                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 
                                           text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {filteredSubjectTypes.map((asset) => (
                    <ComboboxOption
                      key={asset.id}
                      value={asset}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation
                        setSubjectAsset(asset);
                        setSubjectQuery('');
                        setSubjectOpen(false); // Ensure dropdown closes
                      }}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span className={`truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {asset.asset_type}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                active ? 'text-white' : 'text-blue-600'
                              }`}
                            >
                              <CheckIcon className="h-4 w-4" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
        </div>
        
        {/* Verb (Action) Input */}
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Action
          </label>
          <input
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
            value={verb}
            onChange={(e) => setVerb(e.target.value)}
            placeholder="enters data into..."
          />
        </div>
        
        {/* Object Asset Selector */}
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Object
          </label>
          <Combobox
            as="div"
            value={objectAsset}
            onChange={(asset) => {
              setObjectAsset(asset);
              setObjectQuery('');
              setObjectOpen(false); // Close dropdown after selection
            }}
            open={objectOpen}
            onOpen={() => setObjectOpen(true)}
            onClose={() => setObjectOpen(false)}
          >
            <div className="relative">
              <ComboboxInput
                className="block w-full rounded-md border-gray-300 shadow-sm 
                           focus:border-blue-500 focus:ring-blue-500 text-xs py-1 px-2"
                onChange={(event) => handleObjectQueryChange(event.target.value)}
                displayValue={(asset) => asset?.asset_type || ''}
                placeholder="Select an object asset type"
                onFocus={() => setObjectOpen(true)}
              />
              
              <ComboboxButton className="absolute inset-y-0 right-0 flex items-center px-2">
                <ChevronUpDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              
              {filteredObjectTypes.length > 0 && (
                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 
                                           text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {filteredObjectTypes.map((asset) => (
                    <ComboboxOption
                      key={asset.id}
                      value={asset}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop event propagation
                        setObjectAsset(asset);
                        setObjectQuery('');
                        setObjectOpen(false); // Ensure dropdown closes
                      }}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        }`
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span className={`truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {asset.asset_type}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                active ? 'text-white' : 'text-blue-600'
                              }`}
                            >
                              <CheckIcon className="h-4 w-4" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
        </div>
      </div>

      {/* Display formatted sentence */}
      {(subjectAsset || objectAsset || verb) && (
        <div className="mt-3 text-xs text-blue-800 bg-blue-100 p-2 rounded">
          {subjectAsset?.asset_type || '...'} {verb || '...'} {objectAsset?.asset_type || '...'}
        </div>
      )}
      
      <Handle 
        type="source" 
        position={Position.Bottom} 
        className="!bg-blue-500 !border-blue-700"
        isConnectable={isConnectable}
      />
      
      <Handle 
        type="source" 
        position={Position.Right} 
        className="!bg-blue-500 !border-blue-700"
        isConnectable={isConnectable}
      />
      
      <Handle 
        type="target" 
        position={Position.Left} 
        className="!bg-blue-500 !border-blue-700"
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default React.memo(ActivityNode);