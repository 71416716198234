import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import CollapsibleSection from './CollapsibleSection';
import PersonSelector from './PersonSelector'; 

const AssetDetailsSection = ({
  selectedType,
  expandedSections,
  toggleSection,
  assetName,
  setAssetName,
  assetDescription,
  setAssetDescription,
  assetLocation,
  setAssetLocation,
  assetCriticality,
  setAssetCriticality,
  assetOwner,
  setAssetOwner,
  assetCustodian,
  setAssetCustodian,
  userLang = 'en'
}) => {
  const { t } = useTranslation();
  
  if (!selectedType) return null;

  // Handler for person selection (owner)
  const handleOwnerChange = (person) => {
    setAssetOwner(person);
  };
  
  // Handler for person selection (custodian)
  const handleCustodianChange = (person) => {
    setAssetCustodian(person);
  };

  // Define criticality ratings with their corresponding colors
  const criticalityRatings = [
    { value: "1", label: t('Very Low (1)'), color: 'bg-green-100 text-green-800' },
    { value: "2", label: t('Low (2)'), color: 'bg-lime-100 text-lime-800' },
    { value: "3", label: t('Medium (3)'), color: 'bg-yellow-100 text-yellow-800' },
    { value: "4", label: t('High (4)'), color: 'bg-orange-100 text-orange-800' },
    { value: "5", label: t('Very High (5)'), color: 'bg-red-100 text-red-800' }
  ];

  // Custom RatingMenu component for criticality
  const CriticalityRatingMenu = ({ rating, onChange }) => {
    const currentRating = criticalityRatings.find(r => r.value === rating) || criticalityRatings[0];
    
    return (
      <Menu as="div" className="relative inline-block text-left w-full">
        <Menu.Button className="w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm">
          <span className={`inline-block w-full px-2 py-1 rounded text-sm font-medium ${currentRating.color}`}>
            {currentRating.label}
          </span>
        </Menu.Button>
        <Menu.Items className="absolute z-50 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {criticalityRatings.map((r) => (
              <Menu.Item key={r.value}>
                {({ active }) => (
                  <button
                    className={`${active ? 'bg-gray-50' : ''} w-full text-left px-4 py-2 text-sm`}
                    onClick={() => onChange(r.value)}
                  >
                    <span className={`px-2 py-0.5 rounded ${r.color}`}>
                      {r.label}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    );
  };

  return (
    <CollapsibleSection
      title={t('Asset Details')}
      isExpanded={expandedSections.details}
      onToggle={() => toggleSection('details')}
      noMaxHeight={true} // Add this prop to remove height restriction
    >
      <div className="p-4">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('Asset Name')} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('Description')}
            </label>
            <textarea
              value={assetDescription}
              onChange={(e) => setAssetDescription(e.target.value)}
              rows={2}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('Location')}
            </label>
            <input
              type="text"
              value={assetLocation}
              onChange={(e) => setAssetLocation(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary-color focus:ring-secondary-color sm:text-sm"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('Criticality')}
            </label>
            <CriticalityRatingMenu 
              rating={assetCriticality}
              onChange={(value) => setAssetCriticality(value)}
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              {/* Person selector for Business Owner */}
              <PersonSelector
                label={t('Business Owner')}
                value={assetOwner}
                onChange={handleOwnerChange}
                placeholder={t('Select or enter name')}
                userLang={userLang}
              />
            </div>
            
            <div>
              {/* Person selector for Technical Custodian */}
              <PersonSelector
                label={t('Technical Custodian')}
                value={assetCustodian}
                onChange={handleCustodianChange}
                placeholder={t('Select or enter name')}
                userLang={userLang}
              />
            </div>
          </div>
        </div>
      </div>
    </CollapsibleSection>
  );
};

export default AssetDetailsSection;