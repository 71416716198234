import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import KnowYourselfOverviewDisplay from '../../../components/KnowYourselfOverviewDisplay';
import Notification from '../../../components/Notification';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardSummary = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const [knowYourselfResponse, setKnowYourselfResponse] = useState({});
    const [notification, setNotification] = useState({ show: false, type: '', message: '' });

    const pages = [
        { id: "industry", name: t('KnowYourselfProgress.industry'), fields: ['industry', 'otherIndustry', 'sector', 'otherSector', 'industryNotes'] },
        { id: "market", name: t('KnowYourselfProgress.market'), fields: ['regions', 'subregions', 'marketNotes'] },
        { id: "business-model", name: t('KnowYourselfProgress.businessModel'), fields: ['clientele', 'businessSectors', 'clienteleAgeGroup', 'clienteleGenders', 'clienteleIncome', 'revenueCategories', 'modelNotes'] },
        { id: "product", name: t('KnowYourselfProgress.productsServices'), fields: ['products'] },
        { id: "team", name: t('KnowYourselfProgress.team'), fields: ['founders', 'keyTeamMembers', 'responsibilities', 'offices'] },
        { id: "investment", name: t('KnowYourselfProgress.funding'), fields: ['targetFundingRound', 'fundingTime', 'fundingCurrency', 'planningHorizon', 'avgCashBurn', 'capitalRequired', 'investmentNotes'] },
        { id: "fundingNeeds", name: t('KnowYourselfProgress.fundingNeeds'), fields: ['fundingNeeds.useOfFunds', 'fundingNeeds.descriptions'] },
        { id: "fundingRounds", name: t('KnowYourselfProgress.fundingRounds'), fields: ['fundingRounds.cashflow', 'fundingRounds.fundingRounds'] },
        { id: "captable", name: t('KnowYourselfProgress.captable'), fields: ['captable.shareCapital', 'captable.nominalShareValue', 'captable.currentShareholders', 'captable.targetedNewShareholderCount', 'captable.targetedShareholders'] }
    ];

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get('/api/v1/live_data/user_data', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                setCompany(response.data.entity);
                setKnowYourselfResponse(response.data.entity.know_yourself_response?.published || {});
            } catch (error) {
                console.error('Error fetching company data:', error);
                setNotification({
                    show: true,
                    type: 'error',
                    message: t('Error loading company data')
                });
            }
        };

        fetchCompanyData();
    }, [t]);

    if (!company) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="text-center">
                    {t('Loading...')}
                </div>
            </div>
        );
    }

    const generalInfo = {
        'Company Name': company.name,
        'Website': company.website,
        'Country': company.country,
        'Year Founded': company.year_founded
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
                <Progress />
                <Notification
                    show={notification.show}
                    setShow={(show) => setNotification(prev => ({ ...prev, show }))}
                    type={notification.type}
                    message={notification.message}
                />

                <h2 className="text-primary-color">{t('KnowYourselfWizard.summary')}</h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Latest published data')}</p>

                <div className="mb-8">
                    <KnowYourselfOverviewDisplay
                        data={knowYourselfResponse}
                        generalInfo={generalInfo}
                        pages={pages}
                        logoUrl={company.logo_url}
                    />
                </div>

                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        onClick={() => navigate('/dashboard')}
                        className="px-4 py-2 text-sm font-medium text-white bg-primary-color hover:bg-secondary-color rounded-md shadow-sm"
                    >
                        {t('Go to Dashboard')}
                    </button>
                </div>
            </div>
        </>
    );
};

export default KnowYourselfWizardSummary;