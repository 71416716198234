// src/components/wizard_components/DataPrivacyWizard/AppliedControls.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserData } from '../../../hooks/useUserData';
import { Popover } from 'flowbite-react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

const AppliedControls = ({
    riskControls,
    processingActivity,
    onViewDetails,
    onAppliedControlsChange,
    selectedControls = [],
    onToggleSelection
}) => {
    const { t } = useTranslation();
    const { userData } = useUserData();
    const [appliedControls, setAppliedControls] = useState([]);
    const [appliedControlsModalOpen, setAppliedControlsModalOpen] = useState(false);

    // Get user language
    const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

    // Find all controls that are already applied through the wizard
    useEffect(() => {
        if (!riskControls || !processingActivity) return;

        // Get the processing activity details
        const processorType = processingActivity.processingRole; // 'Controller', 'Co-Controller', 'Processor', 'Co-Processor'
        const lawfulBasis = processingActivity.lawfulBasis || {}; // Object with boolean properties
        const externalRecipients = processingActivity.externalRecipients || []; // Array of external recipient objects

        // Function to check if a control should be applied based on its ID and activity details
        const shouldApplyControl = (controlId) => {
            // PC7xxx controls apply to controller or co-controller
            if (controlId.startsWith('PC7')) {
                // Only apply if processor type is controller or co-controller
                if (processorType !== 'Controller' && processorType !== 'Co-Controller') {
                    return false;
                }

                // Special conditions for specific controls
                switch (controlId) {
                    case 'PC7203':
                    case 'PC7204':
                        // Only applied if consent is a legal basis
                        return lawfulBasis?.consent === true;
                    case 'PC7206':
                    case 'PC7307':
                        // Only applied if there are external recipients
                        return Array.isArray(externalRecipients) && externalRecipients.length > 0;
                    case 'PC7207':
                        // Only applied if processor type is co-controller
                        return processorType === 'Co-Controller';
                    case 'PC7409':
                        // Never pre-applied
                        return false;
                    default:
                        // All other PC7xxx controls are always applied for controllers
                        return true;
                }
            }

            // PC8xxx controls apply to processor or co-processor
            if (controlId.startsWith('PC8')) {
                // Only apply if processor type is processor or co-processor
                if (processorType !== 'Processor' && processorType !== 'Co-Processor') {
                    return false;
                }

                // Special conditions for specific controls
                switch (controlId) {
                    case 'PC8507':
                    case 'PC8508':
                        // Only applied if there are external recipients
                        return Array.isArray(externalRecipients) && externalRecipients.length > 0;
                    case 'PC8403':
                        // Never pre-applied
                        return false;
                    default:
                        // All other PC8xxx controls are always applied for processors
                        return true;
                }
            }

            return false;
        };

        // Filter controls based on the conditions
        const wizardAppliedControls = riskControls.filter(control =>
            control.id && control.id.startsWith('PC') && shouldApplyControl(control.id)
        );
        
        console.log('Applied controls filtered:', wizardAppliedControls.map(c => c.id));
        setAppliedControls(wizardAppliedControls);

        // Notify the parent about available applied controls
        // Parent will decide whether to select them or not
        if (onAppliedControlsChange) {
            onAppliedControlsChange(wizardAppliedControls);
        }
    }, [riskControls, processingActivity]);

    // Function to toggle risk control selection
    const toggleRiskControl = (controlId) => {
        if (onToggleSelection) {
            console.log('AppliedControls - toggle selection for:', controlId);
            onToggleSelection(controlId);
        }
    };

    // Get the filtered set of applied controls that are actually selected
    const selectedAppliedControls = appliedControls.filter(control =>
        selectedControls.includes(control.id)
    );
    
    // Debug log to see what's selected
    useEffect(() => {
        if (appliedControls.length > 0) {
            console.log('Applied controls available:', appliedControls.map(c => c.id));
            console.log('Currently selected controls:', selectedControls);
            console.log('Selected applied controls:', selectedAppliedControls.map(c => c.id));
        }
    }, [appliedControls, selectedControls]);

    // Render method for applied controls modal
    const renderAppliedControlsModal = () => {
        return (
            appliedControlsModalOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl relative">
                            {/* Close button */}
                            <button
                                type="button"
                                onClick={() => setAppliedControlsModalOpen(false)}
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
                            >
                                <span className="sr-only">{t('DataPrivacyWizard.close')}</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.appliedControls')}
                                </h3>
                                <p className="text-sm text-gray-600 mb-4">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.appliedControlsDescription',
                                        'These controls are automatically applied based on your processing activity settings. You can deselect controls if they are not applicable.')}
                                </p>

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    {appliedControls.map(control => {
                                        const isSelected = selectedControls.includes(control.id);
                                        return (
                                            <div
                                                key={control.id}
                                                className={`border rounded-lg p-4 relative cursor-pointer ${isSelected
                                                    ? 'border-primary-color bg-blue-50'
                                                    : 'border-gray-300'
                                                    }`}
                                                onClick={() => toggleRiskControl(control.id)}
                                            >
                                                <div className="flex justify-between items-start">
                                                    <div className="flex items-center space-x-2">
                                                        {control.icons && (
                                                            <img
                                                                src={`/is_icons/${control.icons}`}
                                                                alt={control.id}
                                                                className="h-8 w-8"
                                                            />
                                                        )}
                                                        <span className="text-sm font-semibold text-[#207425]">{control.id}</span>
                                                    </div>
                                                    <div
                                                        className="flex items-center h-5"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent parent div's onClick
                                                            toggleRiskControl(control.id);
                                                        }}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={() => { }} // No-op since we're handling change via onClick
                                                            className="h-4 w-4 text-primary-color border-gray-300 rounded cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="text-sm font-semibold text-gray-900">
                                                        {userLang === 'de' ? control.control_name_de : control.control_name}
                                                    </p>
                                                    <p className="mt-1 text-xs text-gray-600">
                                                        {userLang === 'de' ? control.control_definition_d : control.control_definition}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={() => setAppliedControlsModalOpen(false)}
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-color text-base font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    {t('DataPrivacyWizard.done')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    return (
        <div>
            {selectedAppliedControls.length === 0 ? (
                <button
                    type="button"
                    onClick={() => setAppliedControlsModalOpen(true)}
                    className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center hover:border-primary-color"
                >
                    {t('DataPrivacyWizard.editAppliedControls')}
                </button>
            ) : (
                <div className="flex flex-wrap gap-4 items-center">
                    {selectedAppliedControls.map(control => (
                        <Popover
                            key={control.id}
                            trigger="hover"
                            placement="top"
                            content={
                                <div className="w-64 text-sm">
                                    <div className="px-3 py-2 bg-[#AADEEA] border-b border-gray-200 rounded-t-lg">
                                        <p className="font-semibold text-sm text-[#1B7387]">
                                            {userLang === 'de' ? control.control_name_de : control.control_name}
                                        </p>
                                    </div>
                                    <div className="px-3 py-2">
                                        <p className="text-gray-700 text-xs">
                                            {userLang === 'de' ? control.control_definition_d : control.control_definition}
                                        </p>
                                    </div>
                                </div>
                            }
                        >
                            <div className="relative group cursor-help">
                                {control.icons && (
                                    <img
                                        src={`/is_icons/${control.icons}`}
                                        alt={control.id}
                                        className="h-10 w-10 hover:scale-110 transition-transform"
                                    />
                                )}
                            </div>
                        </Popover>
                    ))}
                    <button
                        type="button"
                        onClick={() => setAppliedControlsModalOpen(true)}
                        className="h-10 px-3 flex items-center justify-center rounded-md bg-gray-100 text-gray-700 hover:bg-secondary-color hover:text-white transition-colors ml-1 text-sm font-medium"
                    >
                        {t('DataPrivacyWizard.edit')}
                    </button>
                </div>
            )}

            {/* Render the modal */}
            {renderAppliedControlsModal()}
        </div>
    );
};

export default AppliedControls;