import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from '../../../axios';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import FounderCard from '../../../components/wizard_components/FounderCard';
import FounderCardAdd from '../../../components/wizard_components/FounderCardAdd';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';

const StartupWizardFounders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, updateWizardData, liveMode, toggleLiveMode, wizardData } = useWizard();
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [isOverviewFixed, setIsOverviewFixed] = useState(false);
  const [overviewHeight, setOverviewHeight] = useState(0);
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'founders');
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const totalShareCapital = wizardData.published?.company?.shareCapital || 0;

  // Initialize initialValues when data is loaded
  const initialValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        founders: data.founders || [
          {
            type: 'natural', // Default: 'natural' (human) or 'juridical' (company)

            // Fields for natural persons
            fullName: '',
            dateOfBirth: '',
            nationality: '',

            // Fields for juridical persons (only filled if type = 'juridical')
            legalName: '',
            seat: '',
            registry: '',
            registryNumber: '',

            // Beneficial owners (only for juridical persons)
            beneficialOwners: [], // Array of objects { fullName, nationality, dateOfBirth, residence: { place, country } }

            // Contact person (mandatory for juridical persons)
            contactPerson: {
              name: '',
              role: '',
              email: '',
              phone: '',
            },

            // Shared fields
            address: {
              street: '',
              streetAdditional: '',
              zip: '',
              place: '',
              country: '',
            },
            email: '',
            phone: '',
            isManagingDirector: false,
            sharePercentage: 0,
            capitalInvested: 0,
          },
        ],
      };
    } else {
      return {
        founders: [
          {
            type: 'natural', // Default is natural person

            // Fields for natural persons
            fullName: '',
            dateOfBirth: '',
            nationality: '',

            // Fields for juridical persons
            legalName: '',
            seat: '',
            registry: '',
            registryNumber: '',

            // Beneficial owners (only for juridical persons)
            beneficialOwners: [],

            // Contact person (only for juridical persons)
            contactPerson: {
              name: '',
              role: '',
              email: '',
              phone: '',
            },

            // Shared fields
            address: {
              street: '',
              streetAdditional: '',
              zip: '',
              place: '',
              country: '',
            },
            email: '',
            phone: '',
            isManagingDirector: false,
            sharePercentage: 0,
            capitalInvested: 0,
          },
        ],
      };
    }
  }, [data]);

  // Other useEffects and functions remain the same...
  useEffect(() => {
    const overviewElement = document.getElementById('share-capital-overview');
    if (overviewElement) {
      setOverviewHeight(overviewElement.offsetHeight);
    }

    const handleScroll = () => {
      const overviewElement = document.getElementById('share-capital-overview');
      if (overviewElement) {
        const overviewRect = overviewElement.getBoundingClientRect();
        const parentRect = overviewElement.parentElement.getBoundingClientRect();

        if (parentRect.top <= 0 && parentRect.bottom >= overviewHeight) {
          setIsOverviewFixed(true);
        } else {
          setIsOverviewFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [overviewHeight]);

  const validationSchema = Yup.object({
    founders: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().oneOf(['natural', 'juridical']).required(t('Type is required')),
  
        // Validation for Natural Person
        fullName: Yup.string().when('type', {
          is: 'natural',
          then: () => Yup.string().required(t('Full Name is required')),
        }),
        dateOfBirth: Yup.date().when('type', {
          is: 'natural',
          then: () => Yup.date().required(t('Date of Birth is required')),
        }),
        nationality: Yup.string().when('type', {
          is: 'natural',
          then: () => Yup.string().required(t('Nationality is required')),
        }),
  
        // Validation for Juridical Person (Company)
        legalName: Yup.string().when('type', {
          is: 'juridical',
          then: () => Yup.string().required(t('Legal Name of Corporation is required')),
        }),
        seat: Yup.string().when('type', {
          is: 'juridical',
          then: () => Yup.string().required(t('Seat of the Corporation is required')),
        }),
        registry: Yup.string().when('type', {
          is: 'juridical',
          then: () => Yup.string().required(t('Registry is required')),
        }),
        registryNumber: Yup.string().when('type', {
          is: 'juridical',
          then: () => Yup.string().required(t('Registry Number is required')),
        }),
  
        // Beneficial Owners (Only required for juridical persons when selected)
        beneficialOwners: Yup.mixed().when(['type', 'hasBeneficialOwners'], {
          is: (type, hasBeneficialOwners) => type === 'juridical' && hasBeneficialOwners === 'yes',
          then: () => 
            Yup.array()
              .of(
                Yup.object().shape({
                  fullName: Yup.string().required(t('Full Name is required')),
                  nationality: Yup.string().required(t('Nationality is required')),
                  dateOfBirth: Yup.date().required(t('Date of Birth is required')),
                  residencePlace: Yup.string().required(t('Place of Residence is required')),
                  residenceCountry: Yup.string().required(t('Country of Residence is required'))
                })
              )
              .min(1, t('At least one beneficial owner is required if selected')),
          otherwise: () => Yup.array().notRequired(),
        }),
  
        // Contact Person (Mandatory for juridical persons)
        contactPerson: Yup.mixed().when('type', {
          is: 'juridical',
          then: () =>
            Yup.object().shape({
              name: Yup.string().required(t('Contact Person Name is required')),
              role: Yup.string()
                .notOneOf(['Managing Director'], t('Managing Director cannot be the contact person'))
                .required(t('Role is required')),
            }),
          otherwise: () => Yup.object().notRequired(),
        }),
  
        // Shared Fields
        address: Yup.object({
          street: Yup.string().required(t('Street is required')),
          zip: Yup.string().required(t('ZIP is required')),
          place: Yup.string().required(t('Place is required')),
          country: Yup.string().required(t('Country is required')),
        }),
        email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
        phone: Yup.string().required(t('Phone is required')),
        isManagingDirector: Yup.boolean(),
        capitalInvested: Yup.number()
          .min(0, t('Capital invested must be non-negative'))
          .required(t('Capital invested is required')),
      })
    ),
  });  

  const formatFounderErrors = (errors) => {
    const flattenedErrors = [];
  
    if (Array.isArray(errors?.founders)) {
      errors.founders.forEach((founderErrors, index) => {
        if (!founderErrors || typeof founderErrors !== 'object') return;
  
        const processErrors = (obj, prefix = `${t('Founder')} ${index + 1}`) => {
          if (!obj || typeof obj !== 'object') return;
  
          Object.entries(obj).forEach(([key, value]) => {
            // Translate the key if it's a field name
            const translatedKey = t(key.charAt(0).toUpperCase() + key.slice(1));
            
            if (typeof value === 'object' && value !== null) {
              processErrors(value, `${prefix} > ${translatedKey}`);
            } else if (value) {
              // Ensure the error message is translated
              const translatedError = value.includes('required') ? t(value) : value;
              flattenedErrors.push(`${prefix}: ${translatedError}`);
            }
          });
        };
  
        processErrors(founderErrors);
      });
    }
  
    return flattenedErrors;
  };

  const validateShareCapital = (values) => {
    const shareStats = calculateShareStats(values.founders);
    if (shareStats.remainingCapital > 0) {
      return {
        isValid: false,
        error: `${formatNumber(shareStats.remainingCapital)} € (${formatNumber(100 - shareStats.totalPercentage)}%) of capital still needs to be invested.`
      };
    }
    return { isValid: true };
  };

  const handleValidationErrors = (errors) => {
    const formattedErrors = formatFounderErrors(errors);
    setNotification({
      show: true,
      type: 'error',
      message: t('Please correct the following errors:'),
      errors: formattedErrors
    });
  };

  const handleShareCapitalError = (error) => {
    setNotification({
      show: true,
      type: 'error',
      message: t('Cannot save: Not all capital has been invested.'),
      errors: [error]
    });
  };


  const handleSubmit = async (values, { setSubmitting }) => {
    // Ensure correct structure before saving
    const formattedFounders = values.founders.map(founder => {
      if (founder.type === 'natural') {
        return {
          ...founder,
          legalName: undefined,
          seat: undefined,
          registry: undefined,
          registryNumber: undefined,
          beneficialOwners: undefined,
          contactPerson: undefined,
        };
      } else {
        return {
          ...founder,
          fullName: undefined,
          dateOfBirth: undefined,
          nationality: undefined,
        };
      }
    });

    try {
      const result = await saveData(wizardName, 'founders', { ...values, founders: formattedFounders });
      if (result.success) {
        setNotification({ show: true, type: 'success', message: t('Data saved successfully') });
        updateWizardData('founders', { ...values, founders: formattedFounders }, false);
        setTimeout(() => navigate('/startup-wizard/articlesOfAssociation'), 1000);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setNotification({ show: true, type: 'error', message: t('Error saving data'), errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDraftSubmit = async (values, { setSubmitting }) => {
    try {
      // Ensure only relevant fields are saved
      const formattedFounders = values.founders.map(founder => {
        if (founder.type === 'natural') {
          return {
            ...founder,
            legalName: undefined,
            seat: undefined,
            registry: undefined,
            registryNumber: undefined,
            beneficialOwners: undefined,
            contactPerson: undefined,
          };
        } else {
          return {
            ...founder,
            fullName: undefined,
            dateOfBirth: undefined,
            nationality: undefined,
          };
        }
      });

      // Save draft with cleaned data
      const result = await saveAsDraft(wizardName, 'founders', { ...values, founders: formattedFounders }, false);

      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('Draft saved successfully'),
        });

        // Update wizard data with cleaned structure
        updateWizardData('founders', { ...values, founders: formattedFounders }, true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('Error saving draft'),
        errors: [error.message],
      });
    } finally {
      setSubmitting(false);
    }
  };

  const calculateShareStats = (founders = []) => {
    const totalInvested = founders.reduce((sum, founder) => sum + (founder.capitalInvested || 0), 0);
    const remainingCapital = totalShareCapital - totalInvested;
    const totalPercentage = (totalInvested / totalShareCapital) * 100;

    return {
      totalInvested,
      remainingCapital,
      totalPercentage,
    };
  };

  const formatNumber = (number, decimalPlaces = 2) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(number);
  };

  if (!initialValues) {
    return <div>{t('Loading...')}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <Notification
        show={notification.show}
        setShow={(show) => setNotification((prev) => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />

      <DataStatusBanner status={dataStatus} />

      <div className="mt-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          {t('Founding Team')}
        </p>
      </div>

      <div className="mt-6 text-gray-600">
        <p>{t('The founding team of a startup typically consists of the initial group of individuals who come together to create and launch the business. These are the co-founders who contribute to the startups vision, strategy, and early development. The founding team is crucial in shaping the companys culture, building the initial product or service, and setting the direction for future growth. Members of the founding team often bring complementary skills, such as technical expertise, business acumen, marketing, and sales, which are essential for the startups success.')}</p>
        <p className="mt-4">{t('The distribution of shares should reflect the contributions, risks, and commitments of each founder, while also considering the long-term sustainability of the team and the company. It is a foundational decision that can significantly impact the startups future, so it is crucial to approach it thoughtfully and with full transparency among the founding team.')}</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnChange={hasAttemptedSubmit}
      >
        {({ values, errors, isSubmitting, setFieldValue, submitForm }) => {
          const shareStats = values.founders
            ? calculateShareStats(values.founders)
            : { totalShares: 0, attributedShares: 0, remainingShares: totalShareCapital };

          return (
            <Form className="mt-8 space-y-8">

              <AutoSave
                values={values}
                liveMode={liveMode}
                wizardName={wizardName}
                updateWizardData={updateWizardData}
              />

              <div
                id="share-capital-overview"
                className={`bg-white overflow-hidden shadow rounded-lg transition-all duration-300 w-full ${isOverviewFixed
                  ? 'relative md:fixed md:top-0 md:left-[256px] md:right-0 md:z-10 md:w-[calc(100%-256px)]'
                  : ''
                  }`}
              >
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className={`text-lg font-medium leading-6 ${isOverviewFixed ? 'text-white' : 'text-gray-900'} transition-colors duration-300`}>
                      {t('Share Capital Overview')}
                    </h3>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Total Share Capital')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{formatNumber(totalShareCapital)} €</dd>
                      </div>
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Capital Invested')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-green-600">
                          {formatNumber(shareStats.totalInvested)} €
                          <span className="text-sm text-gray-500 ml-2">({formatNumber(shareStats.totalPercentage)}%)</span>
                        </dd>
                      </div>
                      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <dt className="text-sm font-medium text-gray-500 truncate">{t('Capital to be Invested')}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-red-600">
                          {formatNumber(shareStats.remainingCapital)} €
                          <span className="text-sm text-gray-500 ml-2">({formatNumber(100 - shareStats.totalPercentage)}%)</span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                {values.founders.map((founder, index) => (
                  <FounderCard
                    key={index}
                    founder={founder}
                    index={index}
                    setFieldValue={setFieldValue}
                    values={values}
                    onRemove={() => {
                      if (values.founders.length > 1) {
                        const newFounders = values.founders.filter((_, i) => i !== index);
                        setFieldValue('founders', newFounders);
                      }
                    }}
                    totalShareCapital={totalShareCapital}
                    errors={errors}
                    hasAttemptedSubmit={hasAttemptedSubmit}
                  />
                ))}
                <FounderCardAdd
                  onAdd={() => {
                    const newFounder = {
                      fullName: '',
                      dateOfBirth: '',
                      nationality: '',
                      address: {
                        street: '',
                        streetAdditional: '',
                        zip: '',
                        place: '',
                        country: '',
                      },
                      email: '',
                      phone: '',
                      isManagingDirector: false,
                      sharePercentage: 0,
                      capitalInvested: 0,
                    };
                    const newFounders = [...values.founders, newFounder];
                    setFieldValue('founders', newFounders);
                  }}
                />
              </div>

              <ActionButtons
                onCancel={() => navigate('/startup-wizard')}
                onDraft={() => handleDraftSubmit(values, { setSubmitting: () => { } })}
                onSubmit={() => {
                  setHasAttemptedSubmit(true);

                  // Check for validation errors
                  if (Object.keys(errors).length > 0) {
                    handleValidationErrors(errors);
                    return;
                  }

                  // Check share capital
                  const shareCapitalValidation = validateShareCapital(values);
                  if (!shareCapitalValidation.isValid) {
                    handleShareCapitalError(shareCapitalValidation.error);
                    return;
                  }

                  submitForm();
                }}
                isSubmitting={isSubmitting}
                currentPage="founders"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

// AutoSave Component
const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode && values) {
      const result = await saveAsDraft(wizardName, 'founders', values, true);
      if (result.success) {
        updateWizardData('founders', values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};


export default StartupWizardFounders;