// src/components/FlowChart/FlowChartProvider.js
import React, { createContext, useState, useCallback } from 'react';
import * as flowChartApi from './api/flowChartApi';

export const FlowChartContext = createContext();

export const FlowChartProvider = ({ children }) => {
  const [flowCharts, setFlowCharts] = useState([]);
  const [currentFlowChart, setCurrentFlowChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadFlowCharts = useCallback(async (params) => {
    setLoading(true);
    setError(null);
    try {
      const data = await flowChartApi.getFlowCharts(params);
      setFlowCharts(data);
      return data;
    } catch (err) {
      setError(err.message || 'Failed to load flow charts');
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const loadFlowChart = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      const data = await flowChartApi.getFlowChartById(id);
      setCurrentFlowChart(data);
      return data;
    } catch (err) {
      setError(err.message || 'Failed to load flow chart');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const saveFlowChart = useCallback(async (flowChartData) => {
    setLoading(true);
    setError(null);
    try {
      let data;
      if (flowChartData.id) {
        data = await flowChartApi.updateFlowChart(flowChartData.id, flowChartData);
      } else {
        data = await flowChartApi.createFlowChart(flowChartData);
      }
      setCurrentFlowChart(data);
      return data;
    } catch (err) {
      setError(err.message || 'Failed to save flow chart');
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // Other methods for cloning, deleting, etc.

  const value = {
    flowCharts,
    currentFlowChart,
    loading,
    error,
    loadFlowCharts,
    loadFlowChart,
    saveFlowChart,
    // Include other methods here
  };

  return (
    <FlowChartContext.Provider value={value}>
      {children}
    </FlowChartContext.Provider>
  );
};