import React from 'react';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Footer } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../../wizard_components/WizardContext';
import {
    BOOLEAN_VALUES,
    MEETING_LOCATIONS,
    EXTRAORDINARY_RESOLUTIONS,
    convertStorageToDocument,
    convertUIToStorage
} from '../../../constants/translationMappings';

const ArticlesOfAssociationGeneratorGerman = () => {
    const { t } = useTranslation();
    const { wizardData } = useWizard();

    const generateArticlesOfAssociation = async () => {
        const { published } = wizardData;
        const company = published?.company || {};

        // Convert the stored values to document-ready format with German translations
        const rawAoa = published?.articlesOfAssociation || {};
        const aoa = {
            companyPurpose: rawAoa.companyPurpose || 'Gegenstand des Unternehmens ist die Ausübung rechtlich zulässiger Geschäftstätigkeiten.',
            operationRestriction: convertStorageToDocument(rawAoa.operationRestriction || BOOLEAN_VALUES.NO, 'de'),
            terminationDate: rawAoa.terminationDate || '',
            financialYearDeviation: convertStorageToDocument(rawAoa.financialYearDeviation || BOOLEAN_VALUES.NO, 'de'),
            financialYearStandard: convertStorageToDocument(rawAoa.financialYearStandard || BOOLEAN_VALUES.YES, 'de'),
            financialYearStart: rawAoa.financialYearStart || '',
            shareholderMeetingNotice: rawAoa.shareholderMeetingNotice || '4',
            shareholderMeetingQuorum: rawAoa.shareholderMeetingQuorum || '50',
            meetingLocations: (rawAoa.meetingLocations || [
                MEETING_LOCATIONS.STATUTORY_SEAT,
                MEETING_LOCATIONS.REMOTE,
                MEETING_LOCATIONS.ANYWHERE
            ]).map(location => convertStorageToDocument(location, 'de')),
            chairmanAppointmentQuorum: rawAoa.chairmanAppointmentQuorum || '50',
            ordinaryResolutionThreshold: rawAoa.ordinaryResolutionThreshold || '50',
            extraordinaryResolutionThreshold: rawAoa.extraordinaryResolutionThreshold || '75',
            extraordinaryResolutions: (rawAoa.extraordinaryResolutions || Object.values(EXTRAORDINARY_RESOLUTIONS))
                .map(resolution => convertStorageToDocument(resolution, 'de')),
            shareholderTermination: convertStorageToDocument(rawAoa.shareholderTermination || BOOLEAN_VALUES.YES, 'de')
        };

        const founders = published?.founders?.founders || [];

        const currentDateTime = new Date();
        const formattedDateTime = currentDateTime.toLocaleString('de-DE');

        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "Normal",
                        name: "Normal",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            font: "Arial", // Apply a global font style (e.g., Arial)
                            size: 22, // 11pt font size (multiply pt by 2 to get the value)
                        },
                        paragraph: {
                            spacing: { after: 200 }, // Space after paragraphs
                            indent: { left: 1200 }
                        },
                    },
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 32, // 16pt font size
                        },
                        paragraph: {
                            spacing: {
                                after: 300,
                            },
                        },
                    },
                    {
                        id: "Heading2",
                        name: "Heading 2",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 28, // 14pt font size
                        },
                        paragraph: {
                            spacing: {
                                before: 400,
                                after: 250,
                            },
                        },
                    },
                    {
                        id: "Heading3",
                        name: "Heading 3",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            bold: true,
                            size: 24, // 12pt font size
                        },
                        paragraph: {
                            spacing: {
                                after: 250,
                            },
                        },
                    },
                ],
            },
            numbering: {
                config: [
                    {
                        reference: "romanNumbering", // For main sections (I, II, III)
                        levels: [
                            {
                                level: 0,
                                format: "upperRoman",
                                text: "%1.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                        tabStops: [{ position: 720, type: "left" }],
                                    },
                                },
                            },
                        ],
                    },
                    {
                        reference: "decimalNumbering", // For subsections (1, 2, 3)
                        levels: [
                            {
                                level: 0,
                                format: "decimal",
                                text: "%1.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "decimal",
                                text: "%1.%2.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "decimal",
                                text: "%1.%2.%3.",
                                style: {
                                    paragraph: {
                                        indent: { left: 360, hanging: 360 },
                                    },
                                },
                            },
                        ],


                    },
                ],
            },

            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        text: "Satzung",
                        heading: HeadingLevel.HEADING_1,
                    }),
                    ...generateGeneralProvisions(company, aoa),
                    ...generateShareCapital(company, aoa, founders),
                    ...generateManagementAndRepresentation(aoa),
                    ...generateShareholdersMeetingsAndResolutions(aoa),
                    ...generateShares(aoa),
                    ...generateTermination(aoa),
                    ...generateSuccessionUponDeath(aoa),
                    ...generateRedemptionOfShares(aoa),
                    ...generateCompensation(aoa),
                    ...generateAnnualAccounts(aoa),
                    ...generateNonCompeteObligation(),
                    ...generateIncorporationExpenses(),
                    ...generateFinalProvisions(),
                ],
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Exportiert von Cenedril am ${formattedDateTime}`,
                                        font: "Arial",
                                        size: 22,
                                    }),
                                ],
                            }),
                        ],
                    }),
                },
            }],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, "Satzung.docx");
    };

    // Generates the general provisions section
    const generateGeneralProvisions = (company, aoa) => {
        return [
            new Paragraph({
                text: "Allgemeine Bestimmungen",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Firma",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Die Firma der Gesellschaft lautet: ${company.companyName}`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Sitz",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Der Sitz der Gesellschaft ist ${company.place}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Gegenstand",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Gegenstand des Unternehmens ist ${aoa.companyPurpose}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Gesellschaft ist zu allen Geschäften und Maßnahmen berechtigt, die zur Erreichung des vorgenannten Gesellschaftszwecks, zur Errichtung von Zweigniederlassungen sowie zur Beteiligung an anderen Unternehmen, notwendig oder nützlich erscheinen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Dauer, Geschäftsjahr",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            ...(convertUIToStorage(aoa.operationRestriction) === BOOLEAN_VALUES.NO ? [
                new Paragraph({
                    text: "Die Gesellschaft ist auf unbestimmte Zeit errichtet. Die ordentliche Kündigung ist ausgeschlossen.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : [
                new Paragraph({
                    text: `Die Gesellschaft wird für eine beschränkte Dauer errichtet und endet am ${aoa.terminationDate}.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ]),
            ...(convertUIToStorage(aoa.financialYearDeviation) === BOOLEAN_VALUES.NO ? [
                new Paragraph({
                    text: "Das Geschäftsjahr der Gesellschaft entspricht dem Kalenderjahr. Das erste Geschäftsjahr ist ein Rumpfgeschäftsjahr.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : convertUIToStorage(aoa.financialYearStandard) === BOOLEAN_VALUES.YES ? [
                new Paragraph({
                    text: "Das Geschäftsjahr der Gesellschaft startet am 1.7. eines jeden Jahres und endet am 30.6. Das erste Geschäftsjahr ist ein Rumpfgeschäftsjahr.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ] : [
                new Paragraph({
                    text: `Das Geschäftsjahr der Gesellschaft startet am ${aoa.financialYearStart}. Das erste Geschäftsjahr ist ein Rumpfgeschäftsjahr.`,
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ]),
            new Paragraph({
                text: "Bekanntmachungen",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Die Bekanntmachungen der Gesellschaft erfolgen ausschließlich im Bundesanzeiger.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateShareCapital = (company, aoa, founders) => {
        
        // Helper function to calculate cumulative shares up to a given index
        const getShareNumberUpToIndex = (index) => {
            return founders.slice(0, index + 1).reduce((sum, founder) =>
                sum + (founder.sharePercentage * company.shareCapital / 100), 0);
        };
    
        return [
            new Paragraph({
                text: "Stammkapital",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Stammkapital und Geschäftsanteile",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Das Stammkapital der Gesellschaft beträgt EUR ${company.shareCapital},00.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Einlagen auf die Geschäftsanteile sind jeweils hälftig geleistet.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Das Stammkapital der Gesellschaft ist unterteilt in ${company.shareCapital} Geschäftsanteile im Nennbetrag zu jeweils EUR 1,00 mit den laufenden Nummern 1 bis ${company.shareCapital}.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Von diesem Stammkapital:",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            ...founders.map((founder, index) =>
                new Paragraph({
                    text: `${founder.type === 'natural' ? founder.fullName : founder.legalName}, insgesamt ${founder.sharePercentage * company.shareCapital / 100} Geschäftsanteile in Höhe von je nominal EUR 1,00 (in Worten: ein Euro) (Geschäftsanteile lfd. Nr. ${index === 0 ? '1' : Math.floor(getShareNumberUpToIndex(index - 1)) + 1} bis ${Math.floor(getShareNumberUpToIndex(index))})`,
                })
            ),
        ];
    };

    const generateManagementAndRepresentation = (aoa) => {
        return [
            new Paragraph({
                text: "Vertretung und Geschäftsführung",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Vertretung und Geschäftsführer",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({ 
                text: "Die Gesellschaft hat einen oder mehrere Geschäftsführer.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Ist nur ein Geschäftsführer bestellt, vertritt dieser die Gesellschaft allein. Sind mehrere Geschäftsführer bestellt, wird die Gesellschaft entweder durch zwei (2) Geschäftsführer gemeinsam oder durch einen Geschäftsführer gemeinsam mit einem Prokuristen vertreten.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Die Geschäftsführer werden durch die Gesellschafterversammlung bestellt und abberufen. Durch Beschluss der Gesellschafterversammlung kann jedem Geschäftsführer auch Einzelvertretungsbefugnis sowie die Befugnis erteilt werden, die Gesellschaft bei Rechtsgeschäften mit sich selbst oder als Vertreter eines Dritten (Befreiung von den Beschränkungen des § 181 BGB) zu vertreten.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({
                text: "Geschäftsführung",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({ 
                text: "Die Geschäfte der Gesellschaft werden von den Geschäftsführern unter Beachtung der Sorgfalt eines ordentlichen und gewissenhaften Geschäftsführers nach Maßgabe der gesetzlichen Bestimmungen, dieses Gesellschaftsvertrags, ihres jeweiligen Anstellungsvertrags, einer etwaigen Geschäftsordnung für die Geschäftsführung in ihrer jeweils aktuellen Fassung und den Beschlüssen der Gesellschafterversammlung geführt.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Die Gesellschafterversammlung kann eine Geschäftsordnung für die Geschäftsführung erlassen oder eine bestehende Geschäftsordnung für die Geschäftsführung ändern oder aufheben. Die Geschäftsordnung kann vorsehen, dass für bestimmte Rechtsgeschäfte und Maßnahmen die Zustimmung der Gesellschafterversammlung erforderlich ist.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Die Geschäftsführer haben unverzüglich nach Wirksamwerden jeder Veränderung in den Personen der Gesellschafter oder des Umfangs ihrer Beteiligung eine von ihnen unterschriebene Gesellschafterliste zum Handelsregister einzureichen, soweit nicht gemäß § 40 Abs. 2 GmbHG eine Verpflichtung des beurkundenden Notars zur Einreichung besteht. Die Veränderungen sind den Geschäftsführern schriftlich mitzuteilen und nachzuweisen. Als Nachweis sind im Allgemeinen entsprechende Urkunden in Urschrift oder beglaubigter Abschrift vorzulegen. Für den Nachweis der Erbfolge gilt § 35 Grundbuchordnung entsprechend. Nach Aufnahme der geänderten Gesellschafterliste im Handelsregister haben die Geschäftsführer sämtlichen Gesellschaftern unverzüglich eine Abschrift der aktuellen Gesellschafterliste zu übersenden.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
            new Paragraph({ 
                text: "Ziffer 8 gilt für den oder die Liquidatoren entsprechend.", 
                numbering: { reference: "decimalNumbering", level: 1 }, 
            }),
        ];
    };
    
    const generateShareholdersMeetingsAndResolutions = (aoa) => {
        return [
            new Paragraph({
                text: "Gesellschafterversammlungen und Beschlüsse",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Gesellschafterversammlungen",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Jeder Gesellschafter ist mindestens in Textform gemäß § 126b BGB mit der Maßgabe, dass nur Briefe, E-Mails und Kopien, die einer E-Mail als PDF-Anhang beigefügt sind, gelten, nicht aber eine sonstige Übermittlung im Wege der Telekommunikation („Textform"), unter Angabe von Ort, Tag, Uhrzeit und Tagesordnung unter Einhaltung einer Frist von mindestens ${aoa.shareholderMeetingNotice} Wochen zu einer Gesellschafterversammlung einzuladen.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Eine Gesellschafterversammlung ist nur beschlussfähig, wenn Gesellschafter, die alleine oder gemeinsam mehr als ${aoa.shareholderMeetingQuorum}% der Geschäftsanteile anwesend oder vertreten sind.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Gesellschafterversammlungen finden ${aoa.meetingLocations.join(", ")} statt.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Die Gesellschafterversammlung wählt mit einfacher Mehrheit (d. h. mehr als ${aoa.chairmanAppointmentQuorum}%) der abgegebenen Stimmen einen Vorsitzenden.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Gesellschafterbeschlüsse",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: `Sofern dieser Gesellschaftsvertrag oder das Gesetz kein höheres Mehrheitserfordernis vorsehen, werden sämtliche Gesellschafterbeschlüsse mit einfacher Mehrheit (d. h. mehr als ${aoa.ordinaryResolutionThreshold}%) der abgegebenen Stimmen gefasst.`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: `Die nachfolgenden Gegenstände bedürfen der Beschlussfassung durch die Gesellschafterversammlung und dabei für ihre Wirksamkeit einer Mehrheit von ${aoa.extraordinaryResolutionThreshold}%:`,
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            ...aoa.extraordinaryResolutions.map(resolution =>
                new Paragraph({
                    text: resolution,
                    bullet: { level: 0 }
                })
            ),
        ];
    };
    
    const generateShares = (aoa) => {
        return [
            new Paragraph({
                text: "Geschäftsanteile",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Verfügungen über Geschäftsanteile",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Jede mittelbare oder unmittelbare Verfügung über einen Geschäftsanteil oder Teile eines Geschäftsanteils an der Gesellschaft bedarf zu ihrer Wirksamkeit der Zustimmung der Gesellschafterversammlung durch Gesellschafterbeschluss. Veräußerungs- und/oder erwerbswillige Gesellschafter sind nicht stimmberechtigt.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Eine Verfügung im Sinne von Ziffer 11.1 ist jede Abtretung, Verpfändung oder sonstige Belastung, Bestellung des Nießbrauchs, Begründung bzw. Beendigung eines Treuhandverhältnisses, einer Unterbeteiligung, einer stillen Gesellschaft, einer Beteiligung am Gewinn oder Stimmbindungsvereinbarung sowie sonstige Rechtsgeschäfte und Vorgänge, insbesondere nach dem Umwandlungsgesetz, die wirtschaftlich einer Abtretung gleichkommen. Hierzu zählt auch die mittelbare Verfügung über Anteile an der Gesellschaft, bspw. durch Verfügung von Anteilen am Gesellschafter selbst.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateTermination = (aoa) => {
        if (convertUIToStorage(aoa.shareholderTermination) === BOOLEAN_VALUES.YES) {
            return [
                new Paragraph({
                    text: "Kündigung",
                    heading: HeadingLevel.HEADING_3,
                    numbering: { reference: "decimalNumbering", level: 0 },
                }),
                new Paragraph({
                    text: "Die Gesellschaft kann von jedem Gesellschafter mit einer Frist von sechs Monaten zum Ende eines Geschäftsjahres gekündigt werden. Die Kündigung hat durch eingeschriebenen Brief an die Gesellschaft zu erfolgen. Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.",
                    numbering: { reference: "decimalNumbering", level: 1 },
                }),
            ];
        }
        return [
            new Paragraph({
                text: "Kündigt ein Gesellschafter die Gesellschaft, so wird sie durch die übrigen Gesellschafter fortgeführt, wenn diese nicht innerhalb von drei Monaten nach dem Zugang der Kündigung die Auflösung beschließen. In letzterem Fall nimmt der kündigende Gesellschafter an der Liquidation teil; ansonsten scheidet er aus der Gesellschaft gemäß nachstehenden Bestimmungen aus.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateSuccessionUponDeath = (aoa) => {
        return [
            new Paragraph({
                text: "Erbfolge",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Stirbt ein Gesellschafter, so wird die Gesellschaft mit seinen Erben oder Vermächtnisnehmern unabhängig von deren Person und unbeschadet der nachstehenden Rechte der Gesellschaft fortgesetzt. Die Vorschriften einer etwaigen zwischen der Gesellschaft und ihren Gesellschaftern bestehenden Vereinbarung bleiben hiervon unberührt.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Verstirbt ein Gesellschafter, sind dessen gesetzliche oder gewillkürte Erben verpflichtet, ihre Inhaberschaft bezüglich der vererbten bzw. vermachten Geschäftsanteile gegenüber der Gesellschaft durch Vorlage eines Erbscheins oder Nachlasszeugnisses gemäß § 35 GBO nachzuweisen und die Gesellschaft und die übrigen Gesellschafter von dem Übergang der Geschäftsanteile unverzüglich schriftlich zu unterrichten.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Der Treuhänder darf hinsichtlich der Ausübung der Stimmrechte keinen vertraglichen Beschränkungen unterliegen; er hat jedoch die wirtschaftlichen Belange der Erben zu berücksichtigen. Sofern die Übertragung an einen Treuhänder aus steuerrechtlichen Gründen nicht, oder nur mit nachteiligen finanziellen Folgen für den oder die Erben realisierbar ist, scheidet die Übertragung an einen Treuhänder als Möglichkeit aus und der oder die Erben haben darauf hinzuwirken, und entsprechend in Gesellschafterversammlungen abzustimmen, dass die betroffenen Geschäftsanteile in dieser Satzung bis zu deren Veräußerung stimmrechtslos gestellt werden.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Bis zum Wirksamwerden der treuhänderischen Übertragung ruhen die Gesellschafterrechte aus den Geschäftsanteilen des verstorbenen Gesellschafters mit Ausnahme des Rechts auf Gewinnbeteiligung.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Ist ein Geschäftsanteil Gegenstand eines Vermächtnisses oder einer Teilungsanordnung, bedarf die Abtretung des Geschäftsanteils an den Vermächtnisnehmer oder Miterben nicht der Zustimmung gemäß vorstehender Ziffer 11. Nach Abtretung gilt der Vermächtnisnehmer oder Miterbe als Erbe im Sinne dieser Ziffer 12. Das Verfahren nach vorstehender Ziffer 13.2 darf erst nach Abtretung des Geschäftsanteils an den Vermächtnisnehmer oder Miterben erfolgen, es sei denn, der Erbfall liegt länger als ein (1) Jahr zurück.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateRedemptionOfShares = (aoa) => {
        return [
            new Paragraph({
                text: "Einziehung von Geschäftsanteilen",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Die Einziehung eines Geschäftsanteiles eines Gesellschafters oder von Teilen hiervon ist mit dessen Zustimmung jederzeit zulässig (einvernehmliche Einziehung).",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Einziehung des Geschäftsanteiles eines Gesellschafters oder von Teilen hiervon ist ohne dessen Zustimmung ('Zwangseinziehung') nur zulässig, wenn",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "über das Vermögen des Gesellschafters ein Insolvenzverfahren oder ein vergleichbares Verfahren nach ausländischem Recht eröffnet worden ist oder die Eröffnung eines solchen Verfahrens mangels Masse abgelehnt worden ist oder der Gesellschafter eine Vermögensauskunft abzugeben hat; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "der Geschäftsanteil des Gesellschafters gepfändet oder anderweitig Gegenstand der Zwangsvollstreckung geworden ist und die Pfändung bzw. sonstige Zwangsvollstreckungsmaßnahme nicht innerhalb von zwei (2) Monaten seit ihrem Beginn, spätestens aber bis zur Verwertung des Geschäftsanteils aufgehoben worden ist; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "der Gesellschafter Anlass gegeben hat, ihn aus wichtigem Grunde aus der Gesellschaft auszuschließen, insbesondere wenn in der Person des Gesellschafters ein wichtiger Grund im Sinne von §§ 140 in Verbindung mit 133 HGB vorliegt, der seinen Ausschluss aus der Gesellschaft rechtfertigt oder auch der Gesellschafter gegen wesentliche Verpflichtungen aus einer zwischen den Gesellschaftern bestehenden Gesellschaftervereinbarung verstößt; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "die Erben eines Gesellschafters die kraft Erbfolge übergegangenen Geschäftsanteile des verstorbenen Gesellschafters nicht innerhalb der in vorstehender Ziffer 13.2 geregelten Frist auf einen Treuhänder übertragen – sofern dies aus steuerrechtlichen Gründen und nicht mit nachteiligen finanziellen Folgen für den oder die Erben möglich gewesen wäre – und die Gesellschafterversammlung die Erben nach Ablauf der in vorstehender Ziffer 13.2 geregelten Frist unter Androhung der Einziehung schriftlich zur Übertragung aufgefordert hat; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "der Gesellschafter über seinen Geschäftsanteil entgegen Ziffer 11.1 verfügt; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "nach schriftlicher Aufforderung der Gesellschaft oder eines Gesellschafters der betreffende Gesellschafter nicht unverzüglich, spätestens aber innerhalb von sechs (6) Monaten seit Empfang der Aufforderung, nachweist, dass er durch Ehevertrag vereinbart hat, dass falls er im Güterstand der Zugewinngemeinschaft lebt, der Gesellschafter den Beschränkungen des § 1365 BGB nicht unterliegt und, dass seine Geschäftsanteile an der Gesellschaft dann, wenn dieser Güterstand auf andere Weise als durch den Tod eines Ehegatten beendet wird, nicht dem Zugewinnausgleich unterliegen; oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "Ehepartner von Gesellschaftern, die verheiratet sind und im gesetzlichen Güterstand leben, nicht gemäß § 1365 BGB der Veräußerung von Geschäftsanteilen der Gesellschafter zustimmen, sofern eine solche Zustimmung erforderlich ist;",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "die Gesellschafter eine schuldrechtliche Vereinbarung über die Möglichkeit der Einziehung von Geschäftsanteilen getroffen haben und die Voraussetzungen der Einziehung danach erfüllt sind oder der Gesellschafter seiner Verpflichtung zur Übertragung seiner Geschäftsanteile nach Aufforderung durch den Gesellschafterbeschluss gemäß Ziffer 14.8 nicht nachkommt;",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "eine unmittelbare oder mittelbare Beteiligung am Gesellschaftskapital des Gesellschafters, ohne vorherige Zustimmung der Gesellschafterversammlung, vollständig oder zum Teil auf einen oder mehrere Dritte übertragen wird (Änderung der Beteiligungsstruktur auf Ebene des Gesellschafters durch Anteilsübertragung oder Ausgabe neuer Gesellschaftsanteile); oder",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "der Gesellschafter – ungeachtet entsprechender Gründe – seinen Austritt aus der Gesellschaft in Textform erklärt oder aus wichtigem Grund (außerordentlich) kündigt.",
                bullet: { level: 0 },
            }),
            new Paragraph({
                text: "Steht ein Geschäftsanteil mehreren Mitberechtigten gemeinschaftlich zu, so ist die Einziehung gemäß Ziffer 14.2 auch dann zulässig, wenn die Voraussetzungen gemäß Ziffer 14.2 nur in der Person eines Mitberechtigten vorliegen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Einziehung wird durch die Gesellschafterversammlung gemäß Ziffer 10.5.13 beschlossen, wobei in Abweichung von Ziffer 10.3. eine Mehrheit von 75% erforderlich ist. Im Fall einer Zwangseinziehung gemäß Ziffer 14.2 hat der betroffene Gesellschafter kein Stimmrecht. Die Geschäftsführung erklärt die Einziehung gegenüber dem betroffenen Gesellschafter. Einer gesonderten Erklärung bedarf es nicht, wenn der betroffene Gesellschafter bei der Beschlussfassung über die Einziehung anwesend oder vertreten ist.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Einziehung wird wirksam mit Zugang der Mitteilung über den Einziehungsbeschluss bei dem betroffenen Gesellschafter bzw. mit Fassung des Einziehungsbeschlusses im Falle der Entbehrlichkeit der Mitteilung gemäß vorstehender Ziffer 14.4. Die Zahlung der in Ziffer 15 bestimmten Abfindung ist nicht Wirksamkeitsvoraussetzung der Einziehung. In jedem Fall ruhen von dem Zeitpunkt der Fassung des Einziehungsbeschlusses bis zum Zeitpunkt der Wirksamkeit des Ausscheidens des betroffenen Gesellschafters die den einzuziehenden Geschäftsanteilen zuzuordnenden Stimmrechte.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Geschäftsanteile an der Gesellschaft, die von der Gesellschaft gehalten werden, können jederzeit (ohne Zahlung einer Abfindung) durch Gesellschafterbeschluss gemäß Ziffer 14.4 eingezogen werden.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Im Beschluss über die Einziehung müssen die stimmberechtigten Gesellschafter zugleich entscheiden, ob (i) das Stammkapital der Gesellschaft entsprechend herabgesetzt, (ii) die Nennbeträge der übrigen Gesellschafter pro rata aufgestockt oder (iii) ein oder mehrere neue Geschäftsanteile gebildet werden, so dass die Summe der Nennbeträge sämtlicher Geschäftsanteile mit dem Stammkapital übereinstimmt. Die Neubildung eines eingezogenen Geschäftsanteils ist zulässig. Sie erfolgt durch einstimmigen Gesellschafterbeschluss.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Statt der Einziehung der Geschäftsanteile kann die Gesellschafterversammlung mit einer Mehrheit von 75% der abgegebenen Stimmen – ohne Stimmrecht des ausscheidenden Gesellschafters – beschließen, dass die betreffenden Geschäftsanteile, soweit nach § 33 GmbHG zulässig, auf die Gesellschaft oder auf einen von der Gesellschafterversammlung bestimmten Dritten (einschließlich weiterer Gesellschafter) übertragen werden (Zwangsabtretung), und zwar auch dergestalt, dass die Geschäftsanteile teilweise eingezogen und im Übrigen an die Gesellschaft oder den Dritten abgetreten werden. Die Höhe der Gegenleistung für die Übertragung der betreffenden Geschäftsanteile ist gemäß nachstehender Ziffer 15 zu bestimmen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateCompensation = (aoa) => {
        return [
            new Paragraph({
                text: "Abfindung",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Ein Gesellschafter oder sein Rechtsnachfolger erhält nach Maßgabe der folgenden Bestimmungen eine Abfindung für die Einziehung seiner Geschäftsanteile.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Abfindung besteht in den Fällen der Zwangseinziehung gemäß Ziffer 14.2.1 bis (und einschließlich) Ziffer 14.2.10 in einem Gesamtbetrag in Höhe des Buchwertes der eingezogenen Geschäftsanteile zum Stichtag, es sei denn, die Gesellschaft und der betreffende Gesellschafter haben etwas anderes vereinbart. Stichtag ist der mit dem Ausscheiden zusammenfallende Bilanzstichtag, sonst der vorausgehende Bilanzstichtag. Stille Reserven oder ein Firmenwert werden für die Berechnung des Buchwertes nicht berücksichtigt.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Sollte im Einzelfall rechtskräftig festgestellt werden, dass die Abfindungsregelung gemäß Ziffer 15.2 unwirksam ist, so ist die niedrigste noch zulässige Abfindung zu gewähren.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Abfindung besteht in allen anderen Fällen der Einziehung in einem Gesamtbetrag in Höhe des Verkehrswertes der eingezogenen Geschäftsanteile zum Stichtag.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Für die Zwecke dieser Ziffer 15 ist der Verkehrswert nach den Grundsätzen des Instituts der Wirtschaftsprüfer in Deutschland e.V. (IDW) zur Durchführung von Unternehmensbewertungen (Stellungnahme des Hauptfachausschusses IDW S1) in der jeweils geltenden Fassung oder den an deren Stelle tretenden Grundsätzen zu ermitteln. Der Buchwert berechnet sich aus der Summe des Stammkapital zuzüglich offener Rücklagen, zuzüglich Jahresüberschuss und Gewinnvortrag, abzüglich Jahresfehlbetrag und Verlustvortrag.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Änderungen von Jahresabschlüssen der Gesellschaft nach dem für die Ermittlung der Abfindung maßgeblichen Zeitpunkt durch steuerliche Außenprüfungen oder aus anderen Gründen (mit Ausnahme der erfolgreichen Anfechtung eines die Feststellung eines Jahresabschlusses betreffenden Gesellschafterbeschlusses) haben keine Auswirkung auf die Höhe der Abfindung.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Abfindung ist in drei gleichen Teilbeträgen zu entrichten. Der erste Teilbetrag ist sechs (6) Monate nach Fassung des Einziehungsbeschlusses zu zahlen. Die folgenden Teilbeträge sind jeweils ein (1) Jahr nach Fälligkeit des vorausgehenden Teilbetrages zur Zahlung fällig. Der jeweils offenstehende Teil der Abfindung ist mit zwei Prozentpunkten über dem Basiszinssatz p.a. zu verzinsen. Die Zinsen sind jeweils mit den einzelnen Raten fällig. Die Gesellschaft hat das Recht, die Abfindung jederzeit vollständig oder teilweise vor dem jeweiligen Fälligkeitsdatum zu zahlen und mit zukünftig fällig werdenden Zahlungen zu verrechnen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Falls, soweit und solange Abfindungszahlungen gegen § 30 Abs. 1 oder § 64 Satz 3 GmbHG verstoßen würden, gelten Zahlungen auf den Hauptbetrag als zum vereinbarten Satz verzinslich gestundet, Zinszahlungen als unverzinslich gestundet.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Der ausscheidende Gesellschafter ist nicht berechtigt, von der Gesellschaft Sicherheitsleistungen für die jeweils ausstehenden Abfindungszahlungen einschließlich Zinsen zu verlangen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Streitigkeiten über die Höhe der nach Maßgabe dieser Bestimmungen zu ermittelnden Abfindung sind endgültig und verbindlich durch einen Wirtschaftsprüfer als Schiedsgutachter zu entscheiden. § 319 BGB bleibt unberührt. Einigen sich die Gesellschaft und der von der Einziehung betroffene Gesellschafter nicht innerhalb eines (1) Monats nach Aufforderung eines der beiden gegenüber dem jeweils anderen auf die Person eines Schiedsgutachters, so wird dieser auf Antrag eines der beiden vom Institut der Wirtschaftsprüfer in Deutschland e.V. (IDW) bestimmt. Die Kosten des Verfahrens tragen die Gesellschaft und der von der Einziehung betroffene Gesellschafter entsprechend den §§ 91 ff ZPO.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Den Gesellschaftern ist bekannt, dass die vorstehend bestimmte Abfindung gemäß Ziffer 15.2 zu einer wesentlich geringeren Abfindung führen, kann als bei Ansatz des Verkehrswerts des Vermögens der Gesellschaft. Sämtliche Gesellschafter halten gleichwohl an diesen Regelungen fest und verzichten vorsorglich gegenseitig bereits jetzt auf einen etwa darüberhinausgehenden Abfindungsanspruch. Für den Fall, dass der vorgenannte Verzicht unwirksam sein sollte, und die hiernach an einen ausscheidenden Gesellschafter zu leistende Abfindung zu niedrig bemessen ist, so ist die dem ausscheidenden Gesellschafter zu gewährende Abfindung so anzupassen, dass sie höchstens 70% des tatsächlichen Verkehrswerts der eingezogenen Geschäftsanteile beträgt.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Ziffern 15.1 bis 15.11 finden entsprechende Anwendung, wenn die Gesellschafterversammlung statt der Einziehung die Übertragung der Geschäftsanteile auf die Gesellschaft oder auf einen von der Gesellschafterversammlung bestimmten Dritten gemäß Ziffer 14.8 verlangt, wobei der betreffende Übernehmer als Entgelt für die zu übertragenden Geschäftsanteile die Abfindung schuldet.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateAnnualAccounts = (aoa) => {
        return [
            new Paragraph({
                text: "Jahresabschluss",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Aufstellung, Prüfung",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Die Geschäftsführung ist verpflichtet, innerhalb der gesetzlichen Frist nach Ablauf eines jeden Geschäftsjahres den Jahresabschluss (Bilanz nebst Gewinn- und Verlustrechnung und Anhang) sowie – sofern gesetzlich vorgeschrieben oder von der Gesellschafterversammlung beschlossen – einen Lagebericht nach HGB aufzustellen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Feststellung, Ergebnisverwendung, Vermögensverteilung",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Die Feststellung des Jahresabschlusses und die Beschlussfassung über die Ergebnisverwendung obliegen der Gesellschafterversammlung.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Die Verteilung des Jahresergebnisses bzw. Bilanzgewinns erfolgt nach dem Verhältnis der Nennbeträge der Geschäftsanteile (§ 29 Abs. 3 Satz 1 GmbHG). Die Gesellschafterversammlung kann durch einstimmigen Beschluss sämtlicher Gesellschafter die Gewinne der Gesellschaft abweichend von der gesetzlichen Gewinnverteilung, die sich nach dem Verhältnis der Geschäftsanteile richtet, verteilen.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateNonCompeteObligation = () => {
        return [
            new Paragraph({
                text: "Wettbewerbsverbot",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Wettbewerbsverbot",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Die Gesellschafter unterliegen keinem Wettbewerbsverbot im Geschäftsbereich der Gesellschaft für die Dauer ihrer Stellung als Gesellschafter. Es ist zulässig, in gesonderter schriftlicher Vereinbarung (insbesondere in Anstellungsverträgen der Geschäftsführer) im Einzelfall oder generell Wettbewerbsverbote zu vereinbaren.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    const generateIncorporationExpenses = () => {
        return [
            new Paragraph({
                text: "Gründungsaufwand",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Gründungsaufwand",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Den Gründungsaufwand, wie Kosten dieser Urkunde und der Eintragung in das Handelsregister, einschließlich der Kosten der Gründungsberatung trägt die Gesellschaft bis zu einem Gesamtbetrag von EUR 2.500,00.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };
    
    const generateFinalProvisions = () => {
        return [
            new Paragraph({
                text: "Schlussbestimmungen",
                heading: HeadingLevel.HEADING_2,
                numbering: { reference: "romanNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Salvatorische Klausel",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Sollten einzelne Bestimmungen dieses Gesellschaftsvertrags ganz oder teilweise nichtig, unwirksam oder undurchführbar sein oder werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen dieses Gesellschaftsvertrags nicht berührt. Die unwirksame, nichtige oder undurchführbare Bestimmung ist so umzudeuten oder durch Satzungsänderung zu ändern bzw. zu ergänzen, dass der damit verfolgte Zweck im Rahmen der gesetzlichen Bestimmungen soweit wie möglich erreicht wird. Dasselbe gilt bei Vorhandensein von Lücken. Die Gesellschafter sind zur Mitwirkung an der notwendigen Änderung verpflichtet.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Anwendbares Recht, Gerichtsstand, Verbindlichkeit",
                heading: HeadingLevel.HEADING_3,
                numbering: { reference: "decimalNumbering", level: 0 },
            }),
            new Paragraph({
                text: "Dieser Gesellschaftsvertrag unterliegt dem Recht der Bundesrepublik Deutschland.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesem Gesellschaftsvertrag oder über seine Gültigkeit ist, soweit gesetzlich zulässig, der Sitz der Gesellschaft.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
            new Paragraph({
                text: "Nur die deutsche Fassung dieses Gesellschaftsvertrags ist rechtsverbindlich. Übersetzungen dienen lediglich Informationszwecken.",
                numbering: { reference: "decimalNumbering", level: 1 },
            }),
        ];
    };

    return (
        <button
            onClick={generateArticlesOfAssociation}
            className="text-white px-4 py-2 rounded hover:text-white bg-[#2A9D8F] hover:bg-[#238276] flex items-center text-sm"
        >
            <img src={process.env.PUBLIC_URL + '/ux_icons/download.svg'} alt="icon" className="h-5 w-5 mr-2 fill-current text-white" />
            Satzung (deutsch)
        </button>
    );
};

export default ArticlesOfAssociationGeneratorGerman;
