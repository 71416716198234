// src/components/wizard_components/DataPrivacyWizard/RiskControlModal.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import RiskControlGlossary from '../../RiskControlGlossary';
import { XMarkIcon, CheckIcon, ShieldCheckIcon, DocumentCheckIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useUserData } from '../../../hooks/useUserData';

const RiskControlModal = ({ isOpen, onClose, controlId, riskControls, fineTuneMode, riskControlOverrides, onOverrideChange }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [riskControl, setRiskControl] = useState(null);
    const [riskControlId, setControlId] = useState(controlId);
    const [details, setDetails] = useState(null);
    const { userData } = useUserData();
    const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';
    const isGerman = userLang === 'de';

    useEffect(() => {
        if (isOpen && controlId && riskControls) {
            // Find the control in the already loaded risk controls
            const control = riskControls.find(c => c.id === controlId);
            if (control) {
                setRiskControl(control);
                fetchRiskControlDetails();
            } else {
                setError(t('RiskControlModal.riskControlNotFound'));
                setLoading(false);
            }
        }
    }, [isOpen, controlId, riskControls, t]);

    const fetchRiskControlDetails = async () => {
        try {
            setLoading(true);
            setError(null);

            // Only fetch the detailed description and execution criteria
            const [descriptionRes, executionCriteriaRes] = await Promise.all([
                axios.get(`/api/v1/static_data/risk_control_description_for_risk_control/${controlId}`),
                axios.get(`/api/v1/static_data/risk_control_execution_criteria_for_risk_control/${controlId}`)
            ]);

            setDetails({
                description: Array.isArray(descriptionRes.data) ? descriptionRes.data : [descriptionRes.data],
                executionCriteria: Array.isArray(executionCriteriaRes.data) ? executionCriteriaRes.data : [executionCriteriaRes.data]
            });
        } catch (error) {
            console.error('Error fetching risk control details:', error);
            setError(t('RiskControlModal.errorFetchingRiskControlDetails'));
        } finally {
            setLoading(false);
        }
    };

    // Escape key handler to close modal
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape' && isOpen) {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [isOpen, onClose]);

    // Prevent scroll when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    // Helper to check if a feature is active
    const hasFeature = (feature) => {
        return riskControl && riskControl[feature] === 'Yes';
    };

    // Handle clicks on risk control IDs within the modal
    // Recursive navigation is allowed but we'll stay on the same page
    const handleNestedControlClick = (nestedControlId) => {
        if (nestedControlId === controlId) return; // Prevent clicking the same control

        // Save current position/scroll if needed

        // Find the control in the already loaded risk controls
        const nestedControl = riskControls.find(c => c.id === nestedControlId);
        if (nestedControl) {
            setRiskControl(nestedControl);
            setControlId(nestedControlId);
            setLoading(true);
            setError(null);
            setDetails(null);

            // Update URL/history if needed

            // Fetch details for the new control
            const fetchNestedDetails = async () => {
                try {
                    const [descriptionRes, executionCriteriaRes] = await Promise.all([
                        axios.get(`/api/v1/static_data/risk_control_description_for_risk_control/${nestedControlId}`),
                        axios.get(`/api/v1/static_data/risk_control_execution_criteria_for_risk_control/${nestedControlId}`)
                    ]);

                    setDetails({
                        description: Array.isArray(descriptionRes.data) ? descriptionRes.data : [descriptionRes.data],
                        executionCriteria: Array.isArray(executionCriteriaRes.data) ? executionCriteriaRes.data : [executionCriteriaRes.data]
                    });
                } catch (error) {
                    console.error('Error fetching nested risk control details:', error);
                    setError(t('RiskControlModal.errorFetchingRiskControlDetails'));
                } finally {
                    setLoading(false);
                }
            };

            fetchNestedDetails();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Background overlay */}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

                {/* Modal panel */}
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                    {/* Close button */}
                    <button
                        type="button"
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                        onClick={onClose}
                    >
                        <span className="sr-only">{t('RiskControlModal.close')}</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-5 border-b border-gray-200 sm:px-6">
                        {loading ? (
                            <div className="animate-pulse h-8 bg-gray-200 rounded w-3/4"></div>
                        ) : error ? (
                            <h3 className="text-lg leading-6 font-medium text-red-500">{error}</h3>
                        ) : riskControl ? (
                            <div className="flex items-center">
                                {riskControl.icons && (
                                    <img
                                        src={`/is_icons/${riskControl.icons}`}
                                        alt=""
                                        className="h-8 w-8 mr-3"
                                    />
                                )}
                                <div>
                                    <div className="text-sm text-gray-500">{riskControl.id}</div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        {isGerman ? riskControl.control_name_de : riskControl.control_name}
                                    </h3>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {/* Content */}
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 max-h-[70vh] overflow-y-auto">
                        {loading ? (
                            <div className="space-y-4 animate-pulse">
                                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            </div>
                        ) : error && !riskControl ? (
                            <div className="text-red-500">{error}</div>
                        ) : (
                            <div className="space-y-6">
                                {/* Information about when to apply this control */}
                                {riskControl && riskControl.id.startsWith('PC') && (
                                    <div className="text-sm font-semibold p-3 bg-blue-50 border border-blue-100 rounded mb-6">
                                        {riskControl.id.startsWith('PC7') ? (
                                            <p className="text-blue-800">{t('RiskControlModal.appliesTo.controllersCoControllers')}</p>
                                        ) : riskControl.id.startsWith('PC8') ? (
                                            <p className="text-blue-800">{t('RiskControlModal.appliesTo.processorsCoProcessors')}</p>
                                        ) : null}
                                    </div>
                                )}

                                {/* At a glance section */}
                                {riskControl && (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.inANutshell')}
                                        </h4>
                                        <div className="bg-gray-50 rounded-md p-4 text-gray-700">
                                            <RiskControlGlossary
                                                riskControls={riskControls}
                                                onControlClick={handleNestedControlClick}
                                            >
                                                {isGerman && riskControl.zusammengefasst ? (
                                                    <p>{riskControl.zusammengefasst}</p>
                                                ) : riskControl.in_a_nutshell ? (
                                                    <p>{riskControl.in_a_nutshell}</p>
                                                ) : (
                                                    <p>{isGerman ? riskControl.control_name_de : riskControl.control_name}</p>
                                                )}
                                            </RiskControlGlossary>
                                        </div>
                                    </div>
                                )}
                                {riskControl && (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.controlType')}
                                        </h4>
                                        <div className="flex flex-wrap gap-2">
                                            <div className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-semibold 
                        ${riskControl.id.startsWith('PC') ? 'bg-[#AADEEA] text-[#1B7387]' : 'bg-[#B0E199] text-[#207425]'}`}>
                                                {riskControl.id.startsWith('PC')
                                                    ? t('RiskControlModal.privacyControl')
                                                    : t('RiskControlModal.securityControl')}
                                            </div>

                                            {hasFeature('preventive') && (
                                                <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                                    {t('RiskControlModal.preventive')}
                                                </div>
                                            )}

                                            {hasFeature('detective') && (
                                                <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                                    {t('RiskControlModal.detective')}
                                                </div>
                                            )}

                                            {hasFeature('corrective') && (
                                                <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-pink-100 text-pink-800">
                                                    {t('RiskControlModal.corrective')}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Security objectives */}
                                {riskControl && (hasFeature('c') || hasFeature('i') || hasFeature('a')) && (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.securityObjectives')}
                                        </h4>
                                        <div className="flex gap-3">
                                            {hasFeature('c') && (
                                                <div className="flex items-center gap-1 text-sm">
                                                    <span className="w-6 h-6 flex items-center justify-center rounded-full bg-blue-100 text-blue-800 font-medium">C</span>
                                                    <span>{t('RiskControlModal.confidentiality')}</span>
                                                </div>
                                            )}
                                            {hasFeature('i') && (
                                                <div className="flex items-center gap-1 text-sm">
                                                    <span className="w-6 h-6 flex items-center justify-center rounded-full bg-green-100 text-green-800 font-medium">I</span>
                                                    <span>{t('RiskControlModal.integrity')}</span>
                                                </div>
                                            )}
                                            {hasFeature('a') && (
                                                <div className="flex items-center gap-1 text-sm">
                                                    <span className="w-6 h-6 flex items-center justify-center rounded-full bg-red-100 text-red-800 font-medium">A</span>
                                                    <span>{t('RiskControlModal.availability')}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Purpose */}
                                {riskControl && ((isGerman && riskControl.purpose_d) || riskControl.purpose) && (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.purpose')}
                                        </h4>
                                        <RiskControlGlossary
                                            riskControls={riskControls}
                                            onControlClick={handleNestedControlClick}
                                        >
                                            <div className="text-sm text-gray-700">
                                                {isGerman && riskControl.purpose_d ? riskControl.purpose_d : riskControl.purpose}
                                            </div>
                                        </RiskControlGlossary>
                                    </div>
                                )}

                                {/* Description */}
                                {details ? (
                                    <div>
                                        <RiskControlGlossary
                                            riskControls={riskControls}
                                            onControlClick={handleNestedControlClick}
                                        >
                                            <h4 className="text-base font-medium text-gray-900 mb-2">
                                                {t('RiskControlModal.description')}
                                            </h4>
                                            <div className="space-y-4 text-sm text-gray-700">
                                                {details.description
                                                    .sort((a, b) => a.text_block_no - b.text_block_no)
                                                    .map((desc, index) => (
                                                        <div key={index} className="text-sm space-y-2">
                                                            <p className={desc.bold === 'TRUE' ? 'text-sm font-bold' : 'text-sm'}>
                                                                {isGerman && desc.text_d ? desc.text_d : desc.text}
                                                            </p>
                                                            {desc.execution_criteria && details.executionCriteria && (
                                                                <ul className="ml-6 list-disc space-y-1">
                                                                    {fineTuneMode && details.executionCriteria
                                                                        .filter(criteria =>
                                                                            criteria.execution_criteria.startsWith(desc.execution_criteria)
                                                                        )
                                                                        .map((criteria, idx) => {
                                                                            // Get overrides for the current control – if none, assume all are selected.
                                                                            const overrides = (riskControlOverrides && riskControlOverrides[riskControl.id]) || [];
                                                                            const isUnchecked = overrides.includes(criteria.execution_criteria);
                                                                            return (
                                                                                <li key={idx} className="flex items-center space-x-2 text-sm">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            let newOverrides = [...overrides];
                                                                                            if (isUnchecked) {
                                                                                                newOverrides = newOverrides.filter(id => id !== criteria.execution_criteria);
                                                                                            } else {
                                                                                                newOverrides.push(criteria.execution_criteria);
                                                                                            }
                                                                                            onOverrideChange(newOverrides);
                                                                                        }}
                                                                                        className={`relative inline-flex items-center justify-center w-6 h-6 rounded flex-shrink-0 focus:outline-none ${!isUnchecked
                                                                                                ? 'bg-primary-color border border-primary-color text-white'
                                                                                                : 'bg-white border border-gray-300'
                                                                                            }`}
                                                                                    >
                                                                                        {!isUnchecked && <CheckIcon className="h-4 w-4" />}
                                                                                    </button>
                                                                                    <span>{isGerman && criteria.text_form_d ? criteria.text_form_d : criteria.text_form}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }

                                                                    {!fineTuneMode && details.executionCriteria
                                                                        .filter(criteria =>
                                                                            criteria.execution_criteria.startsWith(desc.execution_criteria)
                                                                        )
                                                                        .map((criteria, idx) => (
                                                                            <li key={idx} className="text-sm">
                                                                                {isGerman && criteria.text_form_d ? criteria.text_form_d : criteria.text_form}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        </RiskControlGlossary>
                                    </div>
                                ) : error ? (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.description')}
                                        </h4>
                                        <div className="p-3 bg-gray-50 rounded border border-gray-200">
                                            <p className="text-sm text-gray-700">
                                                {t('RiskControlModal.detailedDescriptionUnavailable')}
                                            </p>
                                            {riskControl && (
                                                <RiskControlGlossary
                                                    riskControls={riskControls}
                                                    onControlClick={handleNestedControlClick}
                                                >
                                                    <p className="mt-2 text-sm text-gray-700">
                                                        {isGerman && riskControl.control_definition_d ? riskControl.control_definition_d : riskControl.control_definition}
                                                    </p>
                                                </RiskControlGlossary>
                                            )}
                                        </div>
                                    </div>
                                ) : null}

                                {/* Standards */}
                                {riskControl && (riskControl.iso_270022022_chapter || riskControl.grundschutzbausteine || riskControl.additional_standards) && (
                                    <div>
                                        <h4 className="text-base font-medium text-gray-900 mb-2">
                                            {t('RiskControlModal.relatedStandards')}
                                        </h4>
                                        <RiskControlGlossary
                                            riskControls={riskControls}
                                            onControlClick={handleNestedControlClick}
                                        >
                                            <div className="space-y-2 text-sm">
                                                {riskControl.iso_270022022_chapter && (
                                                    <div>
                                                        {riskControl.iso_270022022_chapter}
                                                    </div>
                                                )}
                                                {riskControl.grundschutzbausteine && (
                                                    <div>
                                                        <span className="font-medium">{riskControl.id.startsWith('PC')
                                                            ? t('RiskControlModal.GDPR')
                                                            : t('RiskControlModal.BSIGrundschutz')}
                                                        </span> {riskControl.grundschutzbausteine}
                                                    </div>
                                                )}
                                                {riskControl.additional_standards && (
                                                    <div>
                                                        <span className="font-medium">{t('RiskControlModal.additionalStandards')}:</span> {riskControl.additional_standards}
                                                    </div>
                                                )}
                                            </div>
                                        </RiskControlGlossary>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Footer */}
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onClose}
                        >
                            {t('RiskControlModal.close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskControlModal;