// src/pages/wizards/DataPrivacyWizard/DataPrivacyWizardPoliciesProcedures.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { EditorState, ContentState, convertToRaw, convertFromRaw, AtomicBlockUtils, SelectionState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import EnhancedTableControls from '../../../components/EnhancedTableControls';
import TableComponent from '../../../components/TableComponent';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ArrowPathIcon,
    QuestionMarkCircleIcon,
    CheckIcon,
    ExclamationTriangleIcon
} from '@heroicons/react/24/solid';

import Notification from '../../../components/Notification';
import DataPrivacyWizardProgress from '../../../components/wizard_components/DataPrivacyWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { useUserData } from '../../../hooks/useUserData';

// Import policy generators
import SOPConsentManagement from '../../../components/wizard_components/DataPrivacyWizard/SOPConsentManagement';
import SOPConsentManagementDE from '../../../components/wizard_components/DataPrivacyWizard/SOPConsentManagementDE';
import SOPGovernanceDataInventory from '../../../components/wizard_components/DataPrivacyWizard/SOPGovernanceDataInventory';
import SOPDataTransfer from '../../../components/wizard_components/DataPrivacyWizard/SOPDataTransfer';
import SOPDataSubjectRights from '../../../components/wizard_components/DataPrivacyWizard/SOPDataSubjectRights';
import SOPVendorProcessorManagement from '../../../components/wizard_components/DataPrivacyWizard/SOPVendorProcessorManagement';
import SOPPrivacyByDesign from '../../../components/wizard_components/DataPrivacyWizard/SOPPrivacyByDesign';
import SOPPrivacyImpactAssessment from '../../../components/wizard_components/DataPrivacyWizard/SOPPrivacyImpactAssessment';
import SOPPrivacyImpactAssessmentDE from '../../../components/wizard_components/DataPrivacyWizard/SOPPrivacyImpactAssessmentDE';
import PrivacyPolicy from '../../../components/wizard_components/DataPrivacyWizard/PrivacyPolicy';
import PrivacyNotice from '../../../components/wizard_components/DataPrivacyWizard/PrivacyNotice';

const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'header-one') {
        return 'custom-h1';
    }
    if (type === 'header-two') {
        return 'custom-h2';
    }
    if (type === 'header-three') {
        return 'custom-h3';
    }
    return '';
};

function debounce(func, wait) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }
    debounced.cancel = () => {
        clearTimeout(timeout);
    };
    return debounced;
}

const DataPrivacyWizardPoliciesProcedures = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [notification, setNotification] = useState({ show: false, type: '', message: '' });
    const { wizardName, updateWizardData, liveMode, setCurrentSection, toggleLiveMode } = useWizard();
    const { data: policiesData, dataStatus, loading: policiesLoading, error: policiesError } = useFetchData(wizardName, 'policies');
    const { data: tomsData, loading: tomsLoading, error: tomsError } = useFetchData(wizardName, 'toms');
    const { data: ropaData, loading: ropaLoading, error: ropaError } = useFetchData(wizardName, 'ropa');
    const { userData } = useUserData();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });
    const [showRegenerateModal, setShowRegenerateModal] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [regenerateTarget, setRegenerateTarget] = useState(null);
    const [editorReadOnly, setEditorReadOnly] = useState(false);
    const [currentEditorRole, setCurrentEditorRole] = useState(null);

    const getUserLanguage = () => {
        const userData = JSON.parse(localStorage.getItem('user'));
        return userData?.language ? userData.language.slice(0, 2) : 'en';
    };

    const userLang = getUserLanguage();
    const [currentLanguage, setCurrentLanguage] = useState(userLang);

    // Define the subpages of the wizard
    const subpages = [
        { id: 'privacyPolicy', name: t('DataPrivacyWizard.privacyPolicy') },
        { id: 'privacyNotice', name: t('DataPrivacyWizard.privacyNotice') },
        { id: 'sopGovernanceDataInventory', name: t('DataPrivacyWizard.sopGovernanceDataInventory') },
        { id: 'sopConsentManagement', name: t('DataPrivacyWizard.sopConsentManagement') },
        { id: 'sopPrivacyImpactAssessment', name: t('DataPrivacyWizard.sopPrivacyImpactAssessment') },
        { id: 'sopVendorProcessorManagement', name: t('DataPrivacyWizard.sopVendorProcessorManagement') },
        { id: 'sopDataSubjectRights', name: t('DataPrivacyWizard.sopDataSubjectRights') },
        { id: 'sopPrivacyByDesign', name: t('DataPrivacyWizard.sopPrivacyByDesign') },
        { id: 'sopDataTransfer', name: t('DataPrivacyWizard.sopDataTransfer') }
    ];

    useEffect(() => {
        setCurrentSection('policies');
    }, [setCurrentSection]);

    // In getInitialValues function
    const getInitialValues = () => {
        if (policiesData) {
            // Create a deep copy to work with
            const initialValues = JSON.parse(JSON.stringify(policiesData));
            // Process each policy explicitly to ensure correct structure
            Object.keys(initialValues).forEach(policy => {
                // Force the completed property to be a boolean
                if (initialValues[policy]) {
                    initialValues[policy].completed = Boolean(initialValues[policy].completed);
                    initialValues[policy].completedDE = Boolean(initialValues[policy].completedDE);

                    // For dual-role policies
                    if (initialValues[policy].contentController !== undefined) {
                        initialValues[policy].editorStateController = null;
                        initialValues[policy].editorStateControllerDE = null;
                        initialValues[policy].editorStateProcessor = null;
                        initialValues[policy].editorStateProcessorDE = null;
                    }
                    // For single content policies
                    else if (initialValues[policy].content !== undefined) {
                        initialValues[policy].editorState = null;
                        initialValues[policy].editorStateDE = null;
                    }
                }
            });

            return initialValues;
        }

        // Default structure for new data
        return {
            privacyPolicy: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            privacyNotice: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            sopGovernanceDataInventory: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            sopConsentManagement: {
                content: '',
                contentDE: '',
                editorState: null,
                editorStateDE: null,
                completed: false,
                completedDE: false
            },
            sopPrivacyImpactAssessment: {
                content: '',
                contentDE: '',
                editorState: null,
                editorStateDE: null,
                completed: false,
                completedDE: false
            },
            sopVendorProcessorManagement: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            sopDataSubjectRights: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            sopPrivacyByDesign: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            },
            sopDataTransfer: {
                contentController: '',
                contentControllerDE: '',
                contentProcessor: '',
                contentProcessorDE: '',
                editorStateController: null,
                editorStateControllerDE: null,
                editorStateProcessor: null,
                editorStateProcessorDE: null,
                completed: false,
                completedDE: false
            }
        };
    };

    const sanitizeHtmlContent = (html) => {
        return preserveTablesInHtml(html);
    };

    useEffect(() => {
        return () => {
            // Reset editor readonly state when component unmounts or changes
            setEditorReadOnly(false);
        };
    }, []);

    const getEditorState = () => {
        const policy = currentPolicy?.id;
        if (!policy) {
            console.log('getEditorState: No current policy');
            return null;
        }

        const isGerman = currentLanguage === 'de';

        // For dual-role policies
        if (formik.values[policy].contentController !== undefined) {
            // Use the currentEditorRole to determine which editor state to return
            if (currentEditorRole) {
                const editorStateField = `editorState${currentEditorRole}${isGerman ? 'DE' : ''}`;
                if (formik.values[policy][editorStateField]) {
                    console.log(`getEditorState: found ${currentEditorRole} in formik`);
                    return formik.values[policy][editorStateField];
                }
            } else {
                // If no specific role is set, try both
                const controllerField = `editorStateController${isGerman ? 'DE' : ''}`;
                const processorField = `editorStateProcessor${isGerman ? 'DE' : ''}`;

                if (formik.values[policy][controllerField]) {
                    return formik.values[policy][controllerField];
                } else if (formik.values[policy][processorField]) {
                    return formik.values[policy][processorField];
                }
            }
        } else {
            // For single content policies
            const editorStateField = `editorState${isGerman ? 'DE' : ''}`;
            if (formik.values[policy][editorStateField]) {
                console.log('getEditorState: found in formik');
                return formik.values[policy][editorStateField];
            }
        }

        console.warn('getEditorState: not found in formik');
        return null;
    };

    const updateEntityData = (entityKey, newData) => {
        // Try getting current state from formik using the role information
        let currentEditorState = getEditorState();
        if (!currentEditorState) {
            console.warn('updateEntityData: falling back to captured state');
            currentEditorState = window.capturedEditorState;
            if (!currentEditorState) {
                console.error('updateEntityData: no editor state available at all!');
                // Always ensure we reset the readonly state
                setEditorReadOnly(false);
                return;
            }
        }

        console.log('updateEntityData: currentEditorState:', currentEditorState);

        try {
            const contentState = currentEditorState.getCurrentContent();
            const newContentState = contentState.mergeEntityData(entityKey, newData);
            const newEditorState = EditorState.push(currentEditorState, newContentState, 'apply-entity');

            console.log('updateEntityData: newEditorState:', newEditorState);

            const policy = currentPolicy?.id;
            const isGerman = currentLanguage === 'de';

            // Determine which field to update based on the current role
            let editorStateField;
            if (formik.values[policy].contentController !== undefined && currentEditorRole) {
                editorStateField = `editorState${currentEditorRole}${isGerman ? 'DE' : ''}`;
            } else {
                editorStateField = `editorState${isGerman ? 'DE' : ''}`;
            }

            formik.setFieldValue(`${policy}.${editorStateField}`, newEditorState);
        } catch (error) {
            console.error('Error updating entity data:', error);
        } finally {
            // Always reset the editor readonly state regardless of success/failure
            console.log('updateEntityData: setting editorReadOnly to false');
            setEditorReadOnly(false);
            
            // For extra safety, add a delayed reset
            setTimeout(() => {
                setEditorReadOnly(false);
            }, 200);
        }
    };

    // Pass getEditorState via blockProps in blockRendererFn:
    const blockRendererFn = (contentBlock) => {
        if (!contentBlock) return null;
        if (contentBlock.getType() === 'atomic') {
            const entityKey = contentBlock.getEntityAt(0);
            console.log('blockRendererFn called:', {
                blockType: contentBlock.getType(),
                hasEntityKey: !!entityKey,
                entityKey,
                currentRole: currentEditorRole
            });
            return {
                component: TableComponent,
                editable: false,
                props: {
                    block: contentBlock,
                    onUpdateEntityData: updateEntityData,
                    setEditorReadOnly: (flag) => {
                        console.log('blockRendererFn: setEditorReadOnly called with:', flag);
                        setEditorReadOnly(flag);
                    },
                    getEditorState,
                    currentRole: currentEditorRole // Pass the current role
                },
            };
        }
        return null;
    };

    // Formik form setup
    const formik = useFormik({
        initialValues: getInitialValues(),
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                // Create a sanitized copy of the values to send to the server
                const sanitizedValues = {};

                // Process each policy
                Object.keys(values).forEach(policy => {
                    // Create a clean policy object without EditorState properties
                    sanitizedValues[policy] = {
                        completed: values[policy].completed,
                        completedDE: values[policy].completedDE
                    };

                    // Handle single content fields
                    if (values[policy].content !== undefined) {
                        // Check for tables before sanitizing
                        const hasTablesBeforeSanitize = values[policy].content.includes('<table');
                        console.log(`Policy ${policy} content before sanitize - Has tables: ${hasTablesBeforeSanitize}`);
                        if (hasTablesBeforeSanitize) {
                            console.log('Table HTML before sanitize:', values[policy].content.match(/<table[\s\S]*?<\/table>/g));
                        }

                        sanitizedValues[policy].content = sanitizeHtmlContent(values[policy].content);

                        // Check for tables after sanitizing
                        const hasTablesAfterSanitize = sanitizedValues[policy].content.includes('<table');
                        console.log(`Policy ${policy} content after sanitize - Has tables: ${hasTablesAfterSanitize}`);
                        if (hasTablesAfterSanitize) {
                            console.log('Table HTML after sanitize:', sanitizedValues[policy].content.match(/<table[\s\S]*?<\/table>/g));
                        }

                        // Do the same for German content
                        if (values[policy].contentDE) {
                            const hasTablesBeforeSanitizeDE = values[policy].contentDE.includes('<table');
                            console.log(`Policy ${policy} contentDE before sanitize - Has tables: ${hasTablesBeforeSanitizeDE}`);
                            if (hasTablesBeforeSanitizeDE) {
                                console.log('Table HTML before sanitize (DE):', values[policy].contentDE.match(/<table[\s\S]*?<\/table>/g));
                            }

                            sanitizedValues[policy].contentDE = sanitizeHtmlContent(values[policy].contentDE);

                            const hasTablesAfterSanitizeDE = sanitizedValues[policy].contentDE.includes('<table');
                            console.log(`Policy ${policy} contentDE after sanitize - Has tables: ${hasTablesAfterSanitizeDE}`);
                            if (hasTablesAfterSanitizeDE) {
                                console.log('Table HTML after sanitize (DE):', sanitizedValues[policy].contentDE.match(/<table[\s\S]*?<\/table>/g));
                            }
                        }
                    }

                    // Handle dual role content fields (controller/processor)
                    if (values[policy].contentController !== undefined) {
                        const hasTablesRaw = values[policy].contentController.includes('<table');
                        console.log(`Policy ${policy} contentController RAW HTML - Has tables: ${hasTablesRaw}`);
                        if (hasTablesRaw) {
                            console.log('RAW Table HTML:', values[policy].contentController.match(/<table[\s\S]*?<\/table>/g));
                        }
                        // Check for tables in Controller content before sanitizing
                        const hasTablesBeforeSanitizeController = values[policy].contentController.includes('<table');
                        console.log(`Policy ${policy} contentController before sanitize - Has tables: ${hasTablesBeforeSanitizeController}`);
                        if (hasTablesBeforeSanitizeController) {
                            console.log('Controller Table HTML before sanitize:', values[policy].contentController.match(/<table[\s\S]*?<\/table>/g));
                        }

                        sanitizedValues[policy].contentController = sanitizeHtmlContent(values[policy].contentController);

                        // Check for tables after sanitizing
                        const hasTablesAfterSanitizeController = sanitizedValues[policy].contentController.includes('<table');
                        console.log(`Policy ${policy} contentController after sanitize - Has tables: ${hasTablesAfterSanitizeController}`);
                        if (hasTablesAfterSanitizeController) {
                            console.log('Controller Table HTML after sanitize:', sanitizedValues[policy].contentController.match(/<table[\s\S]*?<\/table>/g));
                        }

                        // Check for tables in Processor content before sanitizing
                        const hasTablesBeforeSanitizeProcessor = values[policy].contentProcessor.includes('<table');
                        console.log(`Policy ${policy} contentProcessor before sanitize - Has tables: ${hasTablesBeforeSanitizeProcessor}`);
                        if (hasTablesBeforeSanitizeProcessor) {
                            console.log('Processor Table HTML before sanitize:', values[policy].contentProcessor.match(/<table[\s\S]*?<\/table>/g));
                        }

                        sanitizedValues[policy].contentProcessor = sanitizeHtmlContent(values[policy].contentProcessor);

                        // Check for tables after sanitizing
                        const hasTablesAfterSanitizeProcessor = sanitizedValues[policy].contentProcessor.includes('<table');
                        console.log(`Policy ${policy} contentProcessor after sanitize - Has tables: ${hasTablesAfterSanitizeProcessor}`);
                        if (hasTablesAfterSanitizeProcessor) {
                            console.log('Processor Table HTML after sanitize:', sanitizedValues[policy].contentProcessor.match(/<table[\s\S]*?<\/table>/g));
                        }

                        // Do the same for German content (Controller)
                        if (values[policy].contentControllerDE) {
                            const hasTablesBeforeSanitizeControllerDE = values[policy].contentControllerDE.includes('<table');
                            console.log(`Policy ${policy} contentControllerDE before sanitize - Has tables: ${hasTablesBeforeSanitizeControllerDE}`);
                            if (hasTablesBeforeSanitizeControllerDE) {
                                console.log('Controller Table HTML before sanitize (DE):', values[policy].contentControllerDE.match(/<table[\s\S]*?<\/table>/g));
                            }

                            sanitizedValues[policy].contentControllerDE = sanitizeHtmlContent(values[policy].contentControllerDE);

                            const hasTablesAfterSanitizeControllerDE = sanitizedValues[policy].contentControllerDE.includes('<table');
                            console.log(`Policy ${policy} contentControllerDE after sanitize - Has tables: ${hasTablesAfterSanitizeControllerDE}`);
                            if (hasTablesAfterSanitizeControllerDE) {
                                console.log('Controller Table HTML after sanitize (DE):', sanitizedValues[policy].contentControllerDE.match(/<table[\s\S]*?<\/table>/g));
                            }
                        }

                        // Do the same for German content (Processor)
                        if (values[policy].contentProcessorDE) {
                            const hasTablesBeforeSanitizeProcessorDE = values[policy].contentProcessorDE.includes('<table');
                            console.log(`Policy ${policy} contentProcessorDE before sanitize - Has tables: ${hasTablesBeforeSanitizeProcessorDE}`);
                            if (hasTablesBeforeSanitizeProcessorDE) {
                                console.log('Processor Table HTML before sanitize (DE):', values[policy].contentProcessorDE.match(/<table[\s\S]*?<\/table>/g));
                            }

                            sanitizedValues[policy].contentProcessorDE = sanitizeHtmlContent(values[policy].contentProcessorDE);

                            const hasTablesAfterSanitizeProcessorDE = sanitizedValues[policy].contentProcessorDE.includes('<table');
                            console.log(`Policy ${policy} contentProcessorDE after sanitize - Has tables: ${hasTablesAfterSanitizeProcessorDE}`);
                            if (hasTablesAfterSanitizeProcessorDE) {
                                console.log('Processor Table HTML after sanitize (DE):', sanitizedValues[policy].contentProcessorDE.match(/<table[\s\S]*?<\/table>/g));
                            }
                        }
                    }
                });

                // Send the sanitized values to your API
                console.log('FINAL DATA TO SAVE:', JSON.stringify(sanitizedValues[currentPolicy.id]));
                const result = await saveData(wizardName, 'policies', sanitizedValues);

                if (result.success) {
                    setNotification({
                        show: true,
                        type: 'success',
                        message: t('DataPrivacyWizard.dataSaved'),
                    });
                    updateWizardData('policies', sanitizedValues, false);
                    updateWizardData('policies', sanitizedValues, true);
                    setTimeout(() => {
                        navigate('/data-privacy-wizard/implementation');
                    }, 1000);
                } else {
                    throw new Error(result.message || 'Error saving data');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                setNotification({
                    show: true,
                    type: 'error',
                    message: t('DataPrivacyWizard.errorSaving'),
                    errors: error.message ? [error.message] : []
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    // Convert HTML to EditorState for initializing the editor
    // Custom function to convert HTML with tables to Draft.js content
    const createEditorStateWithTables = (html) => {
        if (!html || html.length < 10) return EditorState.createEmpty();

        try {
            // First, let's check for tables
            let hasTables = html.includes('<table');
            console.log('HTML contains tables:', hasTables);

            // At the start of createEditorStateWithTables
            console.log('Starting HTML conversion - length:', html.length);

            // Check if HTML contains tables
            if (!hasTables) {
                // Additional checks for table indicators
                hasTables = html.includes('class="simple-table"') ||
                    html.includes('simple-table-header') ||
                    html.includes('{{TABLE_PLACEHOLDER');

                if (hasTables) {
                    console.log('Found tables through additional checks');
                }
            }

            if (!hasTables) {
                // No tables, use regular conversion
                return createEditorStateStandard(html);
            }

            // For HTML with tables, we need a custom approach
            console.log('Custom table conversion starting...');

            // 1. Extract all tables from the HTML and replace them with special markers
            const tables = [];
            let processedHtml = html;
            const tableRegex = /<table[\s\S]*?<\/table>/g;
            let match;
            let tableIndex = 0;

            // Extract tables while keeping track of their original positions
            processedHtml = html.replace(tableRegex, (match) => {
                const placeholder = `{{TABLE_PLACEHOLDER_${tableIndex}}}`;
                tables.push({
                    html: match,
                    index: tableIndex
                });
                tableIndex++;
                return placeholder;
            });

            console.log(`Found ${tables.length} tables in HTML`);

            // 2. Convert the HTML with placeholders to content blocks
            const blocksFromHTML = htmlToDraft(processedHtml);
            if (!blocksFromHTML) {
                console.error('htmlToDraft conversion failed');
                return EditorState.createEmpty();
            }

            // 3. Create initial content state
            let contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            // 4. Create the editor state
            let editorState = EditorState.createWithContent(contentState);

            // 5. Now identify blocks containing placeholders
            const blocks = contentState.getBlocksAsArray();
            const placeholderBlocks = [];

            // Find all blocks containing placeholders and their indices
            blocks.forEach((block, index) => {
                const text = block.getText();
                const match = text.match(/{{TABLE_PLACEHOLDER_(\d+)}}/);
                if (match) {
                    const tableIdx = parseInt(match[1]);
                    placeholderBlocks.push({
                        index,
                        blockKey: block.getKey(),
                        tableIdx
                    });
                }
            });

            console.log(`Found ${placeholderBlocks.length} blocks with table placeholders`);

            // If no placeholder blocks were found, return the standard editor state
            if (placeholderBlocks.length === 0) {
                return editorState;
            }

            // 6. Process each placeholder block and replace it with a table
            // We'll need to do this in reverse order to avoid index shifting issues
            placeholderBlocks.reverse();

            for (const placeholderBlock of placeholderBlocks) {
                try {
                    // Get the table data for this placeholder
                    const table = tables[placeholderBlock.tableIdx];
                    const tableData = parseTableHtml(table.html);

                    // Create a table entity in the content state
                    contentState = editorState.getCurrentContent();
                    contentState = contentState.createEntity(
                        'TABLE',
                        'IMMUTABLE',
                        { tableHtml: table.html, tableData }
                    );

                    const entityKey = contentState.getLastCreatedEntityKey();

                    // We need to:
                    // 1. Create a new block to replace the placeholder
                    // 2. Remove the old block with the placeholder text

                    // Create a selection targeting the placeholder block
                    const targetRange = new SelectionState({
                        anchorKey: placeholderBlock.blockKey,
                        anchorOffset: 0,
                        focusKey: placeholderBlock.blockKey,
                        focusOffset: blocks[placeholderBlock.index].getLength(),
                        hasFocus: false,
                        isBackward: false
                    });

                    // Update the editor state with the modified content state
                    editorState = EditorState.push(
                        editorState,
                        contentState,
                        'apply-entity'
                    );

                    // Set the selection to the placeholder block
                    editorState = EditorState.forceSelection(editorState, targetRange);

                    // Insert an atomic block with the table entity
                    editorState = AtomicBlockUtils.insertAtomicBlock(
                        editorState,
                        entityKey,
                        ' '
                    );

                    // Get the updated content state
                    contentState = editorState.getCurrentContent();

                    // Now remove the placeholder block
                    // We need to find it again as the keys might have changed
                    const updatedBlocks = contentState.getBlocksAsArray();
                    let placeholderBlockKey = null;

                    for (let i = 0; i < updatedBlocks.length; i++) {
                        const blockText = updatedBlocks[i].getText();
                        if (blockText.includes(`{{TABLE_PLACEHOLDER_${placeholderBlock.tableIdx}}}`)) {
                            placeholderBlockKey = updatedBlocks[i].getKey();
                            break;
                        }
                    }

                    if (placeholderBlockKey) {
                        // Create a selection for the placeholder block
                        const selectionState = new SelectionState({
                            anchorKey: placeholderBlockKey,
                            anchorOffset: 0,
                            focusKey: placeholderBlockKey,
                            focusOffset: updatedBlocks.find(b => b.getKey() === placeholderBlockKey).getLength(),
                            hasFocus: false,
                            isBackward: false
                        });

                        // Remove the placeholder block
                        contentState = Modifier.removeRange(
                            contentState,
                            selectionState,
                            'backward'
                        );

                        // Update the editor state
                        editorState = EditorState.push(
                            editorState,
                            contentState,
                            'remove-range'
                        );
                    }
                } catch (error) {
                    console.error(`Error processing placeholder at index ${placeholderBlock.index}:`, error);
                }
            }

            // Final content state cleanup (if needed)
            // Sometimes there can be empty blocks or artifacts left behind
            contentState = editorState.getCurrentContent();
            const finalBlocks = contentState.getBlocksAsArray();

            console.log(`Final block count: ${finalBlocks.length}`);

            return EditorState.createWithContent(contentState);
        } catch (error) {
            console.error('Error creating editor state with tables:', error);
            return EditorState.createEmpty();
        }
    };

    // Helper function to parse table HTML into a data structure
    const parseTableHtml = (tableHtml) => {
        try {
            console.log('Parsing table HTML:', tableHtml);

            // Create a temporary DOM element to parse the table
            const div = document.createElement('div');
            div.innerHTML = tableHtml;
            const table = div.querySelector('table');

            if (!table) {
                console.warn('No table found in HTML:', tableHtml);
                return [['Invalid Table']];
            }

            // Check if this is a simple-table format
            const isSimpleTable = table.classList.contains('simple-table');
            console.log('Detected simple-table:', isSimpleTable);

            const rows = table.querySelectorAll('tr');
            const tableData = [];

            rows.forEach(row => {
                const rowData = [];
                // Get both td and th elements
                const cells = row.querySelectorAll('td, th');

                cells.forEach(cell => {
                    rowData.push(cell.innerHTML);
                });

                if (rowData.length > 0) {
                    tableData.push(rowData);
                }
            });

            console.log('Successfully parsed table data:', tableData);
            return tableData;
        } catch (e) {
            console.error('Error parsing table HTML:', e);
            return [['Error parsing table']];
        }
    };

    // Standard editor state creation function (for HTML without tables)
    const createEditorStateStandard = (html) => {

        console.log('HTML being converted to EditorState:', html.substring(0, 200) + '...');
        if (html.includes('table')) {
            const tableMatches = html.match(/<table[^>]*>([\s\S]*?)<\/table>/g);
            console.log('Table HTML detected during loading:', tableMatches);
        }

        try {
            const cleanHtml = html
                .replace(/\s+data-offset-key="[^"]*"/g, '')
                .replace(/\s+data-editor="[^"]*"/g, '')
                .replace(/\s+data-block="[^"]*"/g, '');

            const blocksFromHTML = htmlToDraft(cleanHtml);

            if (blocksFromHTML) {
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                return EditorState.createWithContent(contentState);
            }
        } catch (error) {
            console.error('Error in standard editor state creation:', error);
        }

        return EditorState.createEmpty();
    };

    // Then, replace your current createEditorState function with this:
    const createEditorState = createEditorStateWithTables;

    // Initialize editor states once data is loaded
    useEffect(() => {
        if (!policiesLoading && policiesData) {
            let updatedValues = { ...formik.values };
            let changed = false;

            if (policiesData && policiesData[currentPolicy?.id]) {
                console.log('LOADED DATA FROM API:', JSON.stringify(policiesData[currentPolicy.id]));
            }

            // Process each policy field and create editor states if they don't exist
            Object.keys(updatedValues).forEach(policy => {
                const policyData = updatedValues[policy];

                // IMPORTANT: First, explicitly set the completion status from policiesData
                // Check if the policy exists in policiesData before accessing its properties
                if (policiesData[policy]) {
                    updatedValues[policy].completed = Boolean(policiesData[policy].completed || false);
                    updatedValues[policy].completedDE = Boolean(policiesData[policy].completedDE || false);

                    // For policies with separate controller/processor content
                    if (policyData.contentController !== undefined) {
                        // English
                        updatedValues[policy].editorStateController = createEditorState(policiesData[policy].contentController);
                        updatedValues[policy].contentController = policiesData[policy].contentController || '';
                        updatedValues[policy].editorStateProcessor = createEditorState(policiesData[policy].contentProcessor);
                        updatedValues[policy].contentProcessor = policiesData[policy].contentProcessor || '';

                        // German
                        updatedValues[policy].editorStateControllerDE = createEditorState(policiesData[policy].contentControllerDE);
                        updatedValues[policy].contentControllerDE = policiesData[policy].contentControllerDE || '';
                        updatedValues[policy].editorStateProcessorDE = createEditorState(policiesData[policy].contentProcessorDE);
                        updatedValues[policy].contentProcessorDE = policiesData[policy].contentProcessorDE || '';

                        changed = true;
                    }
                    // For policies with a single content field
                    else if (policyData.content !== undefined) {
                        // English
                        updatedValues[policy].editorState = createEditorState(policiesData[policy].content);
                        updatedValues[policy].content = policiesData[policy].content || '';

                        // German
                        updatedValues[policy].editorStateDE = createEditorState(policiesData[policy].contentDE);
                        updatedValues[policy].contentDE = policiesData[policy].contentDE || '';

                        changed = true;
                    }
                } else {
                    // If the policy doesn't exist in policiesData, set default values
                    updatedValues[policy].completed = false;
                    updatedValues[policy].completedDE = false;

                    // Make sure content fields are initialized to empty strings if they don't exist
                    if (policyData.contentController !== undefined) {
                        updatedValues[policy].contentController = '';
                        updatedValues[policy].contentProcessor = '';
                        updatedValues[policy].contentControllerDE = '';
                        updatedValues[policy].contentProcessorDE = '';

                        updatedValues[policy].editorStateController = EditorState.createEmpty();
                        updatedValues[policy].editorStateProcessor = EditorState.createEmpty();
                        updatedValues[policy].editorStateControllerDE = EditorState.createEmpty();
                        updatedValues[policy].editorStateProcessorDE = EditorState.createEmpty();
                    } else if (policyData.content !== undefined) {
                        updatedValues[policy].content = '';
                        updatedValues[policy].contentDE = '';

                        updatedValues[policy].editorState = EditorState.createEmpty();
                        updatedValues[policy].editorStateDE = EditorState.createEmpty();
                    }

                    changed = true;
                }
            });

            if (changed) {
                formik.setValues(updatedValues);
            }
        }
    }, [policiesLoading, policiesData]);

    // Define a custom entityToHTML function outside the handleEditorStateChange 
    // to ensure consistency
    const entityToHTML = (entity, originalText) => {
        if (entity.type === 'TABLE' && entity.data.tableData) {
            // Generate the table HTML directly here
            const tableData = entity.data.tableData;
            const tableHtml = `
    <table class="simple-table"><tbody>
        ${tableData.map((row, rowIndex) =>
                `<tr>
                ${row.map((cell, cellIndex) =>
                    `<td${rowIndex === 0 ? ' class="simple-table-header"' : ''}>${String(cell || '')}</td>`
                ).join('')}
            </tr>`
            ).join('')}
    </tbody></table>
    `;
            return tableHtml;
        }
        return originalText;
    };

    // Handle editor state change
    // Fix for handleEditorStateChange function
    const handleEditorStateChange = (editorState, policyId, role = null) => {
        const isGerman = currentLanguage === 'de';
        const editorStateField = role
            ? `editorState${role}${isGerman ? 'DE' : ''}`
            : `editorState${isGerman ? 'DE' : ''}`;
        const contentField = role
            ? `content${role}${isGerman ? 'DE' : ''}`
            : `content${isGerman ? 'DE' : ''}`;

        // Update editor state
        formik.setFieldValue(`${policyId}.${editorStateField}`, editorState);

        // Convert to HTML
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);

        console.log('Converting to HTML - Raw content state blocks:', rawContentState.blocks.length);

        // Don't save empty content states to reduce DB bloat
        if (rawContentState.blocks.length === 1 && !rawContentState.blocks[0].text) {
            formik.setFieldValue(`${policyId}.${contentField}`, '');
            return;
        }

        // Step 1: Identify all atomic table blocks and their positions in the content
        const contentBlocks = rawContentState.blocks;
        const tableBlocks = [];

        // Find all atomic blocks containing tables
        contentBlocks.forEach((block, blockIndex) => {
            if (block.type === 'atomic') {
                // Get the entity range
                const entityRanges = block.entityRanges;
                if (entityRanges && entityRanges.length > 0) {
                    const entityKey = entityRanges[0].key;
                    const entity = rawContentState.entityMap[entityKey];

                    if (entity && entity.type === 'TABLE' && entity.data && entity.data.tableData) {
                        // Store the table block with its position info
                        tableBlocks.push({
                            blockIndex,
                            blockKey: block.key,
                            entityKey,
                            entity
                        });
                        console.log(`Found TABLE entity at block ${blockIndex}, rows:`, entity.data.tableData.length);
                    }
                }
            }
        });

        // Step 2: Create a mapping of unique placeholders to table HTML
        const placeholders = {};
        const tableHtmlMap = {};

        tableBlocks.forEach((tableBlock, index) => {
            const tableData = tableBlock.entity.data.tableData;
            const placeholder = `{{TABLE_POSITION_${tableBlock.blockIndex}_${index}}}`;

            // Generate the table HTML
            const tableHtml = `
<table class="simple-table"><tbody>
    ${tableData.map((row, rowIndex) =>
                `<tr>
            ${row.map((cell, cellIndex) =>
                    `<td${rowIndex === 0 ? ' class="simple-table-header"' : ''}>${String(cell || '')}</td>`
                ).join('')}
        </tr>`
            ).join('')}
</tbody></table>
`;
            placeholders[tableBlock.blockKey] = placeholder;
            tableHtmlMap[placeholder] = tableHtml;
        });

        // Step 3: Generate HTML with special placeholders for table positions
        let html = '';
        let currentBlockIndex = 0;

        // First, create a modified version of the raw content with placeholders
        // where the table blocks should be
        const modifiedBlocks = contentBlocks.map(block => {
            // Create a deep copy of the block to avoid mutating the original
            const blockCopy = { ...block };

            // If this is a table block, replace it with a placeholder block
            if (block.type === 'atomic' && placeholders[block.key]) {
                blockCopy.text = placeholders[block.key];
                blockCopy.type = 'unstyled'; // Change type to ensure it's rendered as text
                blockCopy.entityRanges = []; // Remove entity ranges
            }

            return blockCopy;
        });

        // Create a modified raw content state with our placeholder blocks
        const modifiedRawContent = {
            blocks: modifiedBlocks,
            entityMap: {} // We don't need the entities for this step
        };

        // Convert the modified content to HTML
        html = draftToHtml(modifiedRawContent);

        // Step 4: Replace the placeholders with actual table HTML
        Object.keys(tableHtmlMap).forEach(placeholder => {
            html = html.replace(placeholder, tableHtmlMap[placeholder]);
        });

        // Final step: Sanitize the HTML while preserving tables
        const sanitizedHtml = preserveTablesInHtml(html);

        // Set the content field value
        formik.setFieldValue(`${policyId}.${contentField}`, sanitizedHtml);
    };

    // The error is in preserveTablesInHtml - here's a focused fix just for that error
    const preserveTablesInHtml = (html) => {
        if (!html) return '';

        console.log('Preserving tables in HTML - has tables before:', html.includes('<table'));
        if (html.includes('<table')) {
            console.log('Tables found before processing:', html.match(/<table[\s\S]*?<\/table>/g)?.length || 0);
        }

        // Extract all tables to protect them
        const tables = [];
        let processedHtml = html;
        const tableRegex = /<table[\s\S]*?<\/table>/g;

        // Extract tables while keeping track of their original positions
        processedHtml = html.replace(tableRegex, (match, offset) => {
            const placeholder = `{{TABLE_PLACEHOLDER_${tables.length}}}`;
            tables.push({
                html: match,
                index: tables.length,
                position: offset // Store the character position
            });
            return placeholder;
        });

        // Sanitize everything except tables
        // The error is in this line! We need to use processedHtml instead of htmlWithoutTables
        let cleanedHtml = processedHtml
            // Remove entity-map data and other draft.js specific attributes
            .replace(/\s+data-offset-key="[^"]*"/g, '')
            .replace(/\s+data-editor="[^"]*"/g, '')
            .replace(/\s+data-block="[^"]*"/g, '')
            .replace(/\s+(entity|entityKey|entityMap|entityRange)="[^"]*"/g, '')
            // Clean up excessive whitespace
            .replace(/\s{2,}/g, ' ')
            // Clean up empty tags (except placeholders)
            .replace(/<(?!TABLE_PLACEHOLDER)([a-z][a-z0-9]*)[^>]*>\s*<\/\1>/gi, '')
            // Remove any draft.js specific wrappers that might be empty after cleaning
            .replace(/<div class="DraftEditor[^>]*>\s*<\/div>/g, '');

        // Put the tables back
        tables.forEach((table) => {
            cleanedHtml = cleanedHtml.replace(`{{TABLE_PLACEHOLDER_${table.index}}}`, table.html);
        });

        // Add before return
        if (cleanedHtml.includes('<table')) {
            console.log('Tables found after processing:', cleanedHtml.match(/<table[\s\S]*?<\/table>/g).length);
            console.log('Table sample:', cleanedHtml.match(/<table[\s\S]*?<\/table>/g)[0].substring(0, 100) + '...');
        }

        return cleanedHtml;
    };

    // Generate initial content for a policy based on TOMS and ROPA data
    const generateInitialContent = (policyId, role = null) => {
        if (tomsLoading || ropaLoading) return '';

        const controlsMatrix = tomsData?.controlsMatrix || {};
        const riskControlOverrides = tomsData?.riskControlOverrides || {};
        const processingActivities = ropaData?.processingActivities || [];
        const entityInfo = userData?.entity || {};

        // Choose the appropriate generator based on language
        const isGerman = currentLanguage === 'de';

        // Call the appropriate generator component based on the policy ID
        switch (policyId) {
            case 'privacyPolicy':
                return role === 'Controller'
                    ? PrivacyPolicy.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : PrivacyPolicy.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'privacyNotice':
                return role === 'Controller'
                    ? PrivacyNotice.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : PrivacyNotice.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'sopGovernanceDataInventory':
                return role === 'Controller'
                    ? SOPGovernanceDataInventory.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPGovernanceDataInventory.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'sopConsentManagement':
                return isGerman
                    ? SOPConsentManagementDE.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo)
                    : SOPConsentManagement.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo);
            case 'sopPrivacyImpactAssessment':
                return isGerman
                    ? SOPPrivacyImpactAssessmentDE.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPPrivacyImpactAssessment.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller');
            case 'sopVendorProcessorManagement':
                return role === 'Controller'
                    ? SOPVendorProcessorManagement.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPVendorProcessorManagement.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'sopDataSubjectRights':
                return role === 'Controller'
                    ? SOPDataSubjectRights.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPDataSubjectRights.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'sopPrivacyByDesign':
                return role === 'Controller'
                    ? SOPPrivacyByDesign.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPPrivacyByDesign.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            case 'sopDataTransfer':
                return role === 'Controller'
                    ? SOPDataTransfer.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Controller')
                    : SOPDataTransfer.generateContent(controlsMatrix, riskControlOverrides, processingActivities, entityInfo, 'Processor');
            default:
                return '';
        }
    };

    // Initialize content when a section is first expanded
    const initializeContent = (policyId) => {
        let updatedValues = { ...formik.values };
        const policyData = updatedValues[policyId];
        let changed = false;
        const isGerman = currentLanguage === 'de';

        // For policies with separate controller/processor content
        if (policyData.contentController !== undefined) {
            if (isGerman) {
                if (!policyData.contentControllerDE) {
                    const contentController = generateInitialContent(policyId, 'Controller');
                    updatedValues[policyId].contentControllerDE = contentController;
                    updatedValues[policyId].editorStateControllerDE = createEditorState(contentController);
                    changed = true;
                }
                if (!policyData.contentProcessorDE) {
                    const contentProcessor = generateInitialContent(policyId, 'Processor');
                    updatedValues[policyId].contentProcessorDE = contentProcessor;
                    updatedValues[policyId].editorStateProcessorDE = createEditorState(contentProcessor);
                    changed = true;
                }
            } else {
                if (!policyData.contentController) {
                    const contentController = generateInitialContent(policyId, 'Controller');
                    updatedValues[policyId].contentController = contentController;
                    updatedValues[policyId].editorStateController = createEditorState(contentController);
                    changed = true;
                }
                if (!policyData.contentProcessor) {
                    const contentProcessor = generateInitialContent(policyId, 'Processor');
                    updatedValues[policyId].contentProcessor = contentProcessor;
                    updatedValues[policyId].editorStateProcessor = createEditorState(contentProcessor);
                    changed = true;
                }
            }
        }
        // For policies with a single content field
        else if (policyData.content !== undefined) {
            if (isGerman && !policyData.contentDE) {
                const content = generateInitialContent(policyId);
                updatedValues[policyId].contentDE = content;
                updatedValues[policyId].editorStateDE = createEditorState(content);
                changed = true;
            } else if (!isGerman && !policyData.content) {
                const content = generateInitialContent(policyId);
                updatedValues[policyId].content = content;
                updatedValues[policyId].editorState = createEditorState(content);
                changed = true;
            }
        }

        if (changed) {
            formik.setValues(updatedValues);
        }
    };

    // Handle regenerating content
    const handleRegenerateClick = (policyId, role = null) => {
        const isGerman = currentLanguage === 'de';
        setRegenerateTarget({ policyId, role, isGerman });
        setShowRegenerateModal(true);
    };

    // Confirm regeneration and actually regenerate the content
    const confirmRegeneration = () => {
        if (!regenerateTarget) return;

        const { policyId, role, isGerman } = regenerateTarget;
        const newContent = generateInitialContent(policyId, role);

        if (role) {
            const contentField = `content${role}${isGerman ? 'DE' : ''}`;
            const editorStateField = `editorState${role}${isGerman ? 'DE' : ''}`;
            formik.setFieldValue(`${policyId}.${contentField}`, newContent);
            formik.setFieldValue(`${policyId}.${editorStateField}`, createEditorState(newContent));
        } else {
            formik.setFieldValue(`${policyId}.content${isGerman ? 'DE' : ''}`, newContent);
            formik.setFieldValue(`${policyId}.editorState${isGerman ? 'DE' : ''}`, createEditorState(newContent));
        }

        setShowRegenerateModal(false);
        setRegenerateTarget(null);
    };

    // Mark a policy as complete
    const markAsComplete = (policyId) => {
        const isGerman = currentLanguage === 'de';
        const completedField = `completed${isGerman ? 'DE' : ''}`;
        formik.setFieldValue(`${policyId}.${completedField}`, true);
        handleDraftSubmit();
    };

    // Toggle a section's expanded state
    const toggleSection = (sectionId) => {
        if (expandedSection === sectionId) {
            setExpandedSection(null);
        } else {
            setExpandedSection(sectionId);
            initializeContent(sectionId);
        }
    };

    // Navigate between subpages
    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    // Auto-save as draft
    const autoSaveDraft = useCallback(async () => {
        if (liveMode) {
            // Create a sanitized copy of the values for auto-save
            const sanitizedValues = {};

            // Process each policy
            Object.keys(formik.values).forEach(policy => {
                sanitizedValues[policy] = {
                    completed: formik.values[policy].completed
                };

                if (formik.values[policy].content !== undefined) {
                    sanitizedValues[policy].content = sanitizeHtmlContent(formik.values[policy].content);
                }

                if (formik.values[policy].contentController !== undefined) {
                    sanitizedValues[policy].contentController = sanitizeHtmlContent(formik.values[policy].contentController);
                }

                if (formik.values[policy].contentProcessor !== undefined) {
                    sanitizedValues[policy].contentProcessor = sanitizeHtmlContent(formik.values[policy].contentProcessor);
                }
            });

            const result = await saveAsDraft(wizardName, 'policies', sanitizedValues);
            if (result.success) {
                updateWizardData('policies', sanitizedValues, true);
            } else {
                console.error('Failed to save draft:', result.error);
            }
        }
    }, [liveMode, formik.values, wizardName, updateWizardData]);

    // Setup debounced auto-save
    const debouncedAutoSaveRef = useRef();

    useEffect(() => {
        debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
    }, [autoSaveDraft]);

    useEffect(() => {
        if (liveMode) {
            debouncedAutoSaveRef.current();
            return () => {
                if (debouncedAutoSaveRef.current && debouncedAutoSaveRef.current.cancel) {
                    debouncedAutoSaveRef.current.cancel();
                }
            };
        }
    }, [liveMode, formik.values]);

    // Handle manual draft save
    const handleDraftSubmit = async () => {
        try {
            // Create a sanitized copy of all values
            const sanitizedValues = {};

            // Process each policy
            Object.keys(formik.values).forEach(policy => {
                // CRITICAL: Explicitly set the completed flag as a boolean
                sanitizedValues[policy] = {
                    completed: Boolean(formik.values[policy].completed)
                };

                // Handle content fields as before
                if (formik.values[policy].content !== undefined) {
                    sanitizedValues[policy].content = sanitizeHtmlContent(formik.values[policy].content);
                }

                if (formik.values[policy].contentController !== undefined) {
                    sanitizedValues[policy].contentController = sanitizeHtmlContent(formik.values[policy].contentController);
                }

                if (formik.values[policy].contentProcessor !== undefined) {
                    sanitizedValues[policy].contentProcessor = sanitizeHtmlContent(formik.values[policy].contentProcessor);
                }
            });

            const result = await saveAsDraft(wizardName, 'policies', sanitizedValues);
            if (result.success) {
                setNotification({
                    show: true,
                    type: 'success',
                    message: t('DataPrivacyWizard.draftSaved'),
                });

                // Important: Update the wizard data with our sanitized values
                // This ensures the context is updated with the completion status
                updateWizardData('policies', sanitizedValues, true);

            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Error saving draft:', error);
            setNotification({
                show: true,
                type: 'error',
                message: t('DataPrivacyWizard.errorSavingDraft'),
                errors: [error.message],
            });
        }
    };

    // Toggle information drawer
    const toggleDrawer = (title, content) => {
        if (drawerOpen && drawerContent.title === title) {
            setDrawerOpen(false);
        } else {
            setDrawerContent({
                title,
                content: `<div class="prose">${content}</div>`
            });
            setDrawerOpen(true);
        }
    };

    // Current policy being displayed
    const currentPolicy = subpages[currentPage];

    useEffect(() => {
        // Store the current editor state in a global variable for access by TableComponent
        const policy = currentPolicy?.id;
        if (policy && formik.values[policy]) {
            const isGerman = currentLanguage === 'de';
            const editorStateField = `editorState${isGerman ? 'DE' : ''}`;
            window.currentEditorState = formik.values[policy][editorStateField];
        }
    }, [currentPolicy, formik.values, currentLanguage]);

    // Loading and error handling
    if (policiesLoading || tomsLoading || ropaLoading) {
        return <div className="text-center p-6">{t('Loading...')}</div>;
    }

    if (policiesError) {
        return <div className="text-red-500 p-6">Error loading policies data: {policiesError.message}</div>;
    }

    if (tomsError) {
        return <div className="text-red-500 p-6">Error loading technical measures data: {tomsError.message}</div>;
    }

    if (ropaError) {
        return <div className="text-red-500 p-6">Error loading ROPA data: {ropaError.message}</div>;
    }

    // Get policy content descriptions
    const getPolicyDescription = (policyId) => {
        switch (policyId) {
            case 'privacyPolicyController':
                return t('DataPrivacyWizard.privacyPolicyControllerDescription');
            case 'privacyPolicyProcessor':
                return t('DataPrivacyWizard.privacyPolicyProcessorDescription');
            case 'sopGovernanceDataInventory':
                return t('DataPrivacyWizard.sopGovernanceDataInventoryDescription');
            case 'sopConsentManagement':
                return t('DataPrivacyWizard.sopConsentManagementDescription');
            case 'sopPrivacyImpactAssessment':
                return t('DataPrivacyWizard.sopPrivacyImpactAssessmentDescription');
            case 'sopVendorProcessorManagement':
                return t('DataPrivacyWizard.sopVendorProcessorManagementDescription');
            case 'sopDataSubjectRights':
                return t('DataPrivacyWizard.sopDataSubjectRightsDescription');
            case 'sopPrivacyByDesign':
                return t('DataPrivacyWizard.sopPrivacyByDesignDescription');
            case 'sopDataTransfer':
                return t('DataPrivacyWizard.sopDataTransferDescription');
            default:
                return '';
        }
    };

    // Determine if a policy has both controller and processor views
    const hasDualRoles = (policyId) => {
        return [
            'privacyNotice',
            'privacyPolicy',
            'sopGovernanceDataInventory',
            'sopVendorProcessorManagement',
            'sopDataSubjectRights',
            'sopPrivacyByDesign',
            'sopDataTransfer'
        ].includes(policyId);
    };


    const LanguageToggle = () => (
        <div className="flex items-center space-x-2">
            <button
                type="button"
                onClick={() => setCurrentLanguage('en')}
                className={`p-1 rounded ${currentLanguage === 'en' ? 'ring-2 ring-primary-color' : 'hover:bg-gray-100'}`}
                title="English"
            >
                <img src="/ux_icons/united-kingdom.svg" alt="English" className="w-6 h-6" />
            </button>
            <button
                type="button"
                onClick={() => setCurrentLanguage('de')}
                className={`p-1 rounded ${currentLanguage === 'de' ? 'ring-2 ring-primary-color' : 'hover:bg-gray-100'}`}
                title="Deutsch"
            >
                <img src="/ux_icons/germany.svg" alt="Deutsch" className="w-6 h-6" />
            </button>
        </div>
    );

    return (
        <div className="container mx-auto p-4">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <LiveModeToggle onToggle={toggleLiveMode} />
                <div className="mt-10">
                    <DataPrivacyWizardProgress />
                </div>
                <Notification
                    show={notification.show}
                    setShow={(show) => setNotification(prev => ({ ...prev, show }))}
                    type={notification.type}
                    message={notification.message}
                />
                <DataStatusBanner status={dataStatus} />

                <h1 className="text-3xl font-bold mb-6">{t('DataPrivacyWizard.policiesProcedures')}</h1>

                <div className="mb-6">
                    <p className="text-gray-700">
                        {t('DataPrivacyWizard.policiesProceduresDescription')}
                    </p>
                </div>

                {/* Subnavigation */}
                <div className="mb-8 border-b border-gray-200">
                    <nav className="-mb-px flex space-x-4 overflow-x-auto">
                        {subpages.map((page, index) => (
                            <button
                                key={page.id}
                                onClick={() => handlePageChange(index)}
                                className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${currentPage === index
                                    ? 'border-primary-color text-primary-color'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                            >
                                <span className="flex items-center">
                                    {page.name}
                                    {/* Force strict boolean check */}
                                    {formik.values[page.id]?.completed === true && (
                                        <CheckIcon className="ml-1.5 h-4 w-4 text-green-500" />
                                    )}
                                </span>
                            </button>
                        ))}
                    </nav>
                </div>

                <FormikProvider value={formik}>
                    <Form>
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-3">{currentPolicy.name}</h2>
                                <p className="text-gray-600 mb-6">{getPolicyDescription(currentPolicy.id)}</p>

                                {/* Collapsible section */}
                                <div className="border border-gray-200 rounded-lg mb-6">
                                    <div
                                        className={`px-4 py-3 flex justify-between items-center cursor-pointer bg-gray-50 ${expandedSection === currentPolicy.id ? 'rounded-t-lg' : 'rounded-lg'
                                            }`}
                                        onClick={() => toggleSection(currentPolicy.id)}
                                    >
                                        <h3 className="text-lg font-medium">{currentPolicy.name}</h3>
                                        <div className="flex items-center">
                                            {/* Force strict boolean check */}
                                            {formik.values[currentPolicy.id]?.[currentLanguage === 'de' ? 'completedDE' : 'completed'] === true && (
                                                <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    {t('DataPrivacyWizard.completed')}
                                                </span>
                                            )}
                                            {expandedSection === currentPolicy.id ? (
                                                <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                            ) : (
                                                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                            )}
                                        </div>
                                    </div>

                                    {expandedSection === currentPolicy.id && (
                                        <div className="p-4">
                                            <div className="flex justify-between mb-4">
                                                <LanguageToggle />
                                                {!hasDualRoles(currentPolicy.id) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRegenerateClick(currentPolicy.id)}
                                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
                                                    >
                                                        <ArrowPathIcon className="mr-2 h-4 w-4" />
                                                        {t('DataPrivacyWizard.regenerate')}
                                                    </button>
                                                )}
                                            </div>

                                            {/* Editor for policies with a single view */}
                                            {!hasDualRoles(currentPolicy.id) && formik.values[currentPolicy.id]?.[currentLanguage === 'de' ? 'editorStateDE' : 'editorState'] && (
                                                <div className="rounded-editor">
                                                    <div className="border-editor min-h-[400px] mb-6">
                                                        <Editor
                                                            editorState={formik.values[currentPolicy.id][currentLanguage === 'de' ? 'editorStateDE' : 'editorState']}
                                                            onEditorStateChange={(state) => {
                                                                // Set current role to null for single-role policies
                                                                setCurrentEditorRole(null);
                                                                handleEditorStateChange(state, currentPolicy.id);
                                                            }}
                                                            onFocus={() => setCurrentEditorRole(null)}
                                                            readOnly={editorReadOnly}
                                                            wrapperClassName="wrapper-class"
                                                            editorClassName="editor-class p-4"
                                                            toolbarClassName="toolbar-class"
                                                            blockStyleFn={myBlockStyleFn}
                                                            blockRendererFn={blockRendererFn}
                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'history'],
                                                                inline: {
                                                                    options: ['bold', 'italic', 'underline'],
                                                                },
                                                                blockType: {
                                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                },
                                                                list: {
                                                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                                },
                                                                emoji: {
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    popupClassName: undefined,
                                                                    emojis: [
                                                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                        '✅', '❎', '💯',
                                                                    ],
                                                                },
                                                            }}
                                                            toolbarCustomButtons={[
                                                                <EnhancedTableControls key="table-controls" />
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {/* Editors for policies with dual roles (controller/processor) */}
                                            {hasDualRoles(currentPolicy.id) && (
                                                <>
                                                    <div className="mb-8">
                                                        <div className="flex items-center justify-between mb-4">
                                                            <h4 className="text-2xl font-semibold">{t('DataPrivacyWizard.asController')}</h4>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRegenerateClick(currentPolicy.id, 'Controller')}
                                                                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                <ArrowPathIcon className="mr-1 h-4 w-4" />
                                                                {t('DataPrivacyWizard.regenerate')}
                                                            </button>
                                                        </div>
                                                        {formik.values[currentPolicy.id]?.editorStateController && (
                                                            <div className="rounded-editor">
                                                                <div className="border-editor min-h-[400px] mb-6">
                                                                    <Editor
                                                                        editorState={
                                                                            formik.values[currentPolicy.id][
                                                                            currentLanguage === 'de'
                                                                                ? 'editorStateControllerDE'
                                                                                : 'editorStateController'
                                                                            ]
                                                                        }
                                                                        onEditorStateChange={(state) => {
                                                                            setCurrentEditorRole('Controller');
                                                                            handleEditorStateChange(state, currentPolicy.id, 'Controller');
                                                                        }}
                                                                        onFocus={() => setCurrentEditorRole('Controller')}
                                                                        readOnly={editorReadOnly}
                                                                        wrapperClassName="wrapper-class"
                                                                        editorClassName="editor-class p-4"
                                                                        toolbarClassName="toolbar-class"
                                                                        blockStyleFn={myBlockStyleFn}
                                                                        blockRendererFn={blockRendererFn}
                                                                        toolbar={{
                                                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'emoji', 'history'],
                                                                            inline: {
                                                                                options: ['bold', 'italic', 'underline'],
                                                                            },
                                                                            blockType: {
                                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                            },
                                                                            list: {
                                                                                options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                                            },
                                                                            emoji: {
                                                                                className: undefined,
                                                                                component: undefined,
                                                                                popupClassName: undefined,
                                                                                emojis: [
                                                                                    '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                    '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                    '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                    '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                    '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                    '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                    '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                    '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                    '✅', '❎', '💯',
                                                                                ],
                                                                            },
                                                                        }}
                                                                        toolbarCustomButtons={[
                                                                            <EnhancedTableControls key="table-controls" />
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div>
                                                        <div className="flex items-center justify-between mb-4">
                                                            <h4 className="text-2xl font-semibold">{t('DataPrivacyWizard.asProcessor')}</h4>
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRegenerateClick(currentPolicy.id, 'Processor')}
                                                                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                <ArrowPathIcon className="mr-1 h-4 w-4" />
                                                                {t('DataPrivacyWizard.regenerate')}
                                                            </button>
                                                        </div>
                                                        {formik.values[currentPolicy.id]?.editorStateProcessor && (
                                                            <div className="rounded-editor">
                                                                <div className="border-editor min-h-[400px] mb-6">
                                                                    <Editor
                                                                        editorState={
                                                                            formik.values[currentPolicy.id][
                                                                            currentLanguage === 'de'
                                                                                ? 'editorStateProcessorDE'
                                                                                : 'editorStateProcessor'
                                                                            ]
                                                                        }
                                                                        onEditorStateChange={(state) => {
                                                                            setCurrentEditorRole('Processor');
                                                                            handleEditorStateChange(state, currentPolicy.id, 'Processor');
                                                                        }}
                                                                        onFocus={() => setCurrentEditorRole('Processor')}
                                                                        readOnly={editorReadOnly}
                                                                        wrapperClassName="wrapper-class"
                                                                        editorClassName="editor-class p-4"
                                                                        toolbarClassName="toolbar-class"
                                                                        blockStyleFn={myBlockStyleFn}
                                                                        blockRendererFn={blockRendererFn}
                                                                        toolbar={{
                                                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'emoji', 'history'],
                                                                            inline: {
                                                                                options: ['bold', 'italic', 'underline'],
                                                                            },
                                                                            blockType: {
                                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                                            },
                                                                            list: {
                                                                                options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                                            },
                                                                            emoji: {
                                                                                className: undefined,
                                                                                component: undefined,
                                                                                popupClassName: undefined,
                                                                                emojis: [
                                                                                    '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                                                    '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                                                    '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                                                    '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                                                    '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                                                    '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                                                    '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                                                    '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                                                    '✅', '❎', '💯',
                                                                                ],
                                                                            },
                                                                        }}
                                                                        toolbarCustomButtons={[
                                                                            <EnhancedTableControls key="table-controls" />
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}

                                            {/* Complete button */}
                                            <div className="mt-6 flex justify-end">
                                                <button
                                                    type="button"
                                                    onClick={() => markAsComplete(currentPolicy.id)}
                                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-color hover:bg-primary-color/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color"
                                                >
                                                    {formik.values[currentPolicy.id]?.completed
                                                        ? t('DataPrivacyWizard.completed')
                                                        : t('DataPrivacyWizard.markAsComplete')}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                <div className="mt-8 flex justify-between">
                                    <button
                                        type="button"
                                        disabled={currentPage === 0}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${currentPage === 0
                                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                            : 'bg-white text-gray-700 hover:bg-gray-50'
                                            }`}
                                    >
                                        {t('DataPrivacyWizard.previous')}
                                    </button>
                                    <button
                                        type="button"
                                        disabled={currentPage === subpages.length - 1}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${currentPage === subpages.length - 1
                                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                            : 'bg-white text-gray-700 hover:bg-gray-50'
                                            }`}
                                    >
                                        {t('DataPrivacyWizard.next')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8">
                            <ActionButtons
                                onDraft={handleDraftSubmit}
                                onSubmit={formik.handleSubmit}
                                isSubmitting={formik.isSubmitting}
                                currentPage="policies"
                            />
                        </div>
                    </Form>
                </FormikProvider>
            </div>

            {/* Regenerate confirmation modal */}
            {showRegenerateModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{t('DataPrivacyWizard.regenerateWarningTitle')}</h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {t('DataPrivacyWizard.regenerateWarningMessage')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-color text-base font-medium text-white hover:bg-primary-color/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color sm:col-start-2 sm:text-sm"
                                    onClick={() => confirmRegeneration()}
                                >
                                    {t('DataPrivacyWizard.regenerate')}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => setShowRegenerateModal(false)}
                                >
                                    {t('DataPrivacyWizard.cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <WizardDrawer
                isOpen={drawerOpen}
                title={drawerContent.title}
                content={drawerContent.content}
                onClose={() => setDrawerOpen(false)}
            />
        </div>
    );
};

export default DataPrivacyWizardPoliciesProcedures;