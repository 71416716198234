// src/components/wizard_components/DataPrivacyWizard/SOPGovernanceDataInventory.js
import React from 'react';

// Helper function to check if a control is applied
const hasControlApplied = (controlsMatrix, controlId) => {
    if (!controlsMatrix || typeof controlsMatrix !== 'object') {
        return false;
    }
    return Object.values(controlsMatrix).some(activityControls => {
        if (!activityControls || typeof activityControls !== 'object') {
            return false;
        }
        return activityControls[controlId] === true;
    });
};

// Helper function to check if an execution criteria is overridden
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);
    if (!isControlApplied) {
        return true;
    }
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        return riskControlOverrides[controlId].includes(criteriaId);
    }
    return false;
};

const SOPGovernanceDataInventory = {

    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo, role) => {
        const orgName = entityInfo.name || '[Your Organization Name]';

        // --- Determine relevant controls based on role ---
        const isController = role === 'Controller';
        const isProcessor = role === 'Processor';

        // --- Controller Specific Controls & Criteria ---
        const hasPC7201 = isController && hasControlApplied(controlsMatrix, 'PC7201');
        const hasPC7202 = isController && hasControlApplied(controlsMatrix, 'PC7202');
        const hasPC7207 = isController && hasControlApplied(controlsMatrix, 'PC7207');
        const hasPC7208 = isController && hasControlApplied(controlsMatrix, 'PC7208');
        const isPC72081aOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1a');
        const isPC72081bOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1b');
        const isPC72081cOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1c');
        const isPC72081dOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1d');
        const isPC72081eOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1e');
        const isPC72081fOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7208', '1f');

        // --- Processor Specific Controls & Criteria ---
        const hasPC8202 = isProcessor && hasControlApplied(controlsMatrix, 'PC8202');
        const hasPC8206 = isProcessor && hasControlApplied(controlsMatrix, 'PC8206');
        const isPC82061aOverridden = isProcessor && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC8206', '1a');
        const isPC82061bOverridden = isProcessor && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC8206', '1b');
        const isPC82061cOverridden = isProcessor && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC8206', '1c');

        // --- Dynamic Numbering ---
        let sectionCounter = 0;
        let subSectionCounters = {}; // Use an object to track counters for each main section

        const renderSectionHeading = (title) => {
            sectionCounter++;
            subSectionCounters[sectionCounter] = 0; // Initialize/reset subsection counter for this section
            return `<h2>${sectionCounter}. ${title}</h2>`;
        };

        const renderSubSectionHeading = (title) => {
            // Use the counter for the *current* main section
            subSectionCounters[sectionCounter]++;
            return `<h3>${sectionCounter}.${subSectionCounters[sectionCounter]} ${title}</h3>`;
        };

        // Step rendering - keeps static numbering within a block
        const renderStep = (title, content) => {
            // Basic check to prevent empty paragraphs if title is somehow empty
            if (!title) return content || '';
            return `<p><strong>${title}</strong></p>${content}`;
        };


        let htmlContent = `<h1>SOP: Governance & Data Inventory – <strong>Data ${role}</strong> (GDPR & ISO/IEC 27701:2021)</h1>`;

        // --- Section 1: Legal Basis ---
        let legalBasisContent = '';
        if (isController) {
            legalBasisContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 5(2) – Accountability:</strong> Requires the controller to <strong>demonstrate compliance</strong> with all data protection principles. This SOP implements processes to document and evidence compliance (accountability).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 24(1) – Responsibility of the Controller:</strong> Mandates implementation of appropriate measures to ensure and <strong>be able to demonstrate</strong> that processing is in accordance with the GDPR. A robust data inventory and governance framework fulfill this obligation.</li></ul>`;
            if (hasPC7208) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 30(1) – Records of Processing Activities:</strong> Requires each controller to <strong>maintain a record</strong> of processing activities, including details such as purposes, data categories, data subjects, recipients, transfers, retention, and security measures. This SOP establishes how to maintain these records comprehensively.</li></ul>`;
            }
            legalBasisContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 31 – Cooperation with Authorities:</strong> Requires cooperation with the supervisory authority <strong>on request</strong>. Having an updated data inventory and clear governance facilitates prompt and full cooperation (e.g. providing records or information to regulators).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 32(1) – Security of Processing:</strong> Requires controllers to implement appropriate <strong>technical and organizational measures</strong> to secure personal data. This procedure integrates documentation of such measures for each processing activity in the inventory (as part of accountability).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 37(1) – Data Protection Officer (DPO):</strong> In certain cases, controllers <strong>must designate a DPO</strong>. This SOP assigns responsibility to the DPO for overseeing data governance and inventory, ensuring compliance oversight.</li></ul>`;
            if (hasPC7201) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC7201 (Identify and document purpose):</strong> The organization must <strong>identify and document the specific purposes</strong> for which personal data (PII) is processed, aligning with GDPR’s purpose limitation principle and ensuring every processing activity’s purpose is recorded.</li></ul>`;
            }
            if (hasPC7202) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC7202 (Identify lawful basis):</strong> The organization must determine and document the <strong>relevant lawful basis</strong> for each processing purpose (e.g. consent, contract, legal obligation), ensuring processing is lawful under applicable regulations.</li></ul>`;
            }
            if (hasPC7207) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC7207 (Joint PII controller):</strong> If the organization acts as a <strong>joint controller</strong>, it should determine and document respective roles and responsibilities with the other controller, typically via a joint controller agreement (per GDPR Article 26). This SOP covers how such arrangements are recorded and governed.</li></ul>`;
            }
            if (hasPC7208) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC7208 (Records related to processing PII):</strong> The organization must <strong>securely maintain necessary records</strong> of PII processing to support its obligations. This extends ISO 27001 controls to include privacy-specific records (essentially the data inventory), supporting GDPR Article 30 compliance.</li></ul>`;
            }
            legalBasisContent += `<p><strong>Rationale:</strong> These legal and standard requirements establish the foundation for this procedure. By adhering to them, the organization ensures a strong governance framework over personal data processing and maintains a comprehensive data inventory, thereby demonstrating accountability and facilitating compliance audits or inquiries.</p>`;
        } else { // Processor
            if (hasPC8206) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 30(2) – Records of Processing Activities (Processor):</strong> Requires each processor to <strong>maintain a record of all categories of processing activities</strong> carried out on behalf of each controller. The record must include details such as the controllers’ name and contact info, categories of processing performed for each, transfers to third countries, and a general description of security measures. This SOP establishes how we document these processing activities (our “data inventory” as a processor) to meet this obligation.</li></ul>`;
            }
            legalBasisContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 31 – Cooperation with Supervisory Authority:</strong> Requires processors (as well as controllers) to <strong>cooperate, on request, with the supervisory authority</strong> in the performance of its tasks. This procedure ensures we maintain records and processes so that we can readily assist and provide required information to regulators when asked (via the controller or directly).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 32(1) – Security of Processing:</strong> Obligates processors, just like controllers, to implement appropriate security measures to protect personal data. Under this SOP, we document and uphold those measures for each processing engagement, and include a general description of these measures in our records (which is also part of Art. 30(2) requirements).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 33(2) – Breach Notification to Controller:</strong> Requires that <strong>the processor notify the controller without undue delay</strong> after becoming aware of a personal data breach. Our procedure incorporates a defined sub-process for incident reporting to controllers, ensuring compliance with this critical requirement.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 37(1) – Data Protection Officer:</strong> In cases where it applies, the processor must designate a DPO just as a controller would. This SOP assigns the role of overseeing compliance (including maintaining the processing records and handling communications) to the DPO or privacy manager, ensuring we meet this requirement when applicable.</li></ul>`;
            if (hasPC8202) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC8202 (Organization’s purposes):</strong> Instructs that the processor must ensure <strong>PII processed on behalf of a customer is only processed for the purposes specified by that customer’s instructions</strong>. In other words, we do not repurpose client data for our own needs. This procedure includes controls to enforce that all processing is within the scope defined by the controller (and if we ever need to diverge, it’s properly addressed).</li></ul>`;
            }
            if (hasPC8206) {
                legalBasisContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2021 – PC8206 (Records related to processing PII for processors):</strong> Requires the processor to <strong>determine and maintain necessary records</strong> to demonstrate compliance with its obligations to the controller. Specifically, it mirrors GDPR Art. 30(2) by listing that records should cover categories of processing for each customer, international transfers, and a description of security measures. This SOP operationalizes PC8206 by defining how we keep and manage those records.</li></ul>`;
            }
            legalBasisContent += `<p><strong>Rationale:</strong> These laws and standards form the backbone of our obligations as a data processor. Unlike a controller, we act on instructions from controllers, so our governance focuses on honoring those instructions, safeguarding the data, and keeping clear records of what we do with personal data on behalf of each client. By following this SOP, we ensure we meet the GDPR requirements directly applicable to processors and align with ISO 27701 best practices, thereby enabling transparency and trust with our client controllers and demonstrating accountability in our processor role.</p>`;
        }
        if (legalBasisContent) {
            htmlContent += renderSectionHeading('Legal/Standard Basis of the Procedure') + legalBasisContent;
        }

        // --- Section 2: Purpose ---
        let purposeContent = '';
        if (isController) {
            purposeContent += `<p>The purpose of this SOP is to <strong>define a structured process for data governance and inventory management</strong> for personal data processing activities. It ensures that the organization (as a data controller) maintains an up-to-date record of all processing activities and associated details, and that governance controls (roles, reviews, and documentation practices) are in place to uphold compliance with GDPR and ISO/IEC 27701:2021. In summary, this procedure aims to:</p>`;
            if (hasPC7208) {
                purposeContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Document All Processing Activities:</strong> Provide a systematic approach to identify, catalog, and update information on every personal data processing activity (the “Record of Processing Activities” or ROPA) in compliance with GDPR Article 30(1). Each entry will include purpose, lawful basis, data categories, data subjects, recipients, transfers, retention, and security measures, among other required details.</li></ul>`;
            }
            purposeContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Establish Accountability:</strong> Clearly define how the organization demonstrates accountability (GDPR Art. 5(2) and 24(1)) through governance measures – assigning responsibilities, maintaining evidence of compliance (policies, decisions, records), and ensuring oversight by appropriate roles (e.g. DPO, process owners).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Ensure Governance & Oversight:</strong> Outline roles and processes for ongoing oversight of data processing – including periodic reviews of processing activities, updates to records, and management of any changes – to ensure continuous compliance. This includes integration of privacy considerations into business processes (privacy by design principles) and readiness to cooperate with supervisory authorities (GDPR Art. 31) by having necessary documentation readily available.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Support Risk Management & Security:</strong> Tie the data inventory process to security and risk management requirements. By documenting technical and organizational security measures for each processing (as required by GDPR Art. 30(1)(g)), the procedure ensures that appropriate protections are identified, implemented, and reviewable. This supports compliance with GDPR Art. 32(1) on security and helps identify where risk assessments (e.g. DPIAs, though outside this SOP’s direct scope) may be needed.</li></ul>`;
            if (hasPC7207) {
                purposeContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Maintain Compliance with Joint Controller Obligations:</strong> If any processing is conducted jointly with other controllers, ensure that such arrangements are properly documented and responsibilities allocated (per PC7207 and GDPR Art. 26). Though joint-controllership is uncommon, this SOP covers steps to recognize and record such scenarios in the inventory and agreements, maintaining transparency and compliance in those cases.</li></ul>`;
            }
            purposeContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Facilitate Communication & Reporting:</strong> By having a governance framework and data inventory, the organization can efficiently respond to internal and external inquiries. Internally, it provides clarity to staff about what data is processed and why. Externally, it enables swift compilation of information required for regulatory reporting (e.g. providing the ROPA to a Data Protection Authority upon request, or informing stakeholders about processing in privacy notices). It also supports the DPO’s work in monitoring compliance and reporting to senior management and regulators.</li></ul>
                <p>Overall, this procedure serves as an internal playbook to ensure all personal data processing is known, tracked, and under control, thereby underpinning the organization’s privacy compliance program and culture of accountability.</p>`;
        } else { // Processor
            purposeContent += `<p>The purpose of this SOP is to define how we, as a data processor, manage data governance and maintain a data processing inventory in compliance with GDPR and ISO/IEC 27701:2021. It serves as an internal “wiki-style” guide for our teams to ensure all processing of personal data we perform for controllers is properly authorized, documented, secure, and monitored. Key objectives include:</p>`;
            if (hasPC8206) {
                purposeContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Documenting Processing Activities for Each Controller:</strong> Establish and maintain a record (inventory) of all personal data processing activities we carry out on behalf of client controllers, as mandated by GDPR Article 30(2). This record allows us and our clients to have full visibility on what data we process, for whom, and under what conditions, supporting transparency and accountability.</li></ul>`;
            }
            if (hasPC8202) {
                purposeContent += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Ensuring Adherence to Controller Instructions:</strong> Implement governance controls so that personal data is only processed in line with the documented instructions and purposes given by the controller (no unauthorized secondary use). This upholds the contractual and legal duty of processors (GDPR Article 28(3)(a) implicitly, and PC8202 explicitly requires purpose adherence).</li></ul>`;
            }
            purposeContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Facilitating Security and Risk Management:</strong> Tie the inventory to our security protocols by documenting the technical and organizational measures applied to each processing (e.g., encryption, access control, etc.), thereby ensuring compliance with GDPR Article 32(1) and giving controllers confidence (and documentation) that their data is protected.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Enabling Prompt Breach Notification and Cooperation:</strong> Provide a clear procedure for internal escalation of any personal data breaches and timely notification to the relevant controller(s) as required by GDPR Article 33(2). By including incident reporting in this governance SOP, we make sure that our staff knows how to react and that controllers receive all necessary information without delay.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Supporting Cooperation and Demonstrating Compliance:</strong> Have readily available records and processes so we can cooperate with supervisory authorities or controller audits. If a controller is audited or if a regulator asks the controller about processing, our records can be made available to help fulfill those obligations (GDPR Art. 31 cooperation, and also GDPR Art. 28(3)(h) which expects processors to contribute to audits). Essentially, this SOP ensures we can <strong>demonstrate our compliance</strong> to our clients (controllers) and regulators, building trust and reducing legal risk.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Assigning Clear Responsibilities in Processor Context:</strong> Define roles such as the DPO/Privacy Officer, account/project managers for client accounts, IT/security personnel, etc., in the context of processor duties. This way, everyone knows their part in maintaining compliance – from contract setup to execution and monitoring – reinforcing a culture of privacy and accountability within our processor activities.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Maintaining Up-to-Date Knowledge Base:</strong> Because laws and contracts can change, the SOP includes mechanisms for continuous improvement. It ensures we keep the data processing inventory current with any changes in client contracts (e.g., new types of processing) and adapt to any new regulatory requirements (for example, if standard contractual clauses or cross-border requirements change, the inventory and processes are updated accordingly).</li></ul>
                <p>In summary, this procedure aims to ensure that all personal data processing we perform for others is <strong>governed properly</strong>: fully documented, limited to agreed purposes, securely handled, and ready to be evidenced. It protects both our organization and our clients by systematically addressing the compliance requirements for data processors under GDPR and ISO 27701.</p>`;
        }
        if (purposeContent) {
            htmlContent += renderSectionHeading('Purpose of the Procedure') + purposeContent;
        }

        // --- Section 3: KPIs ---
        let kpisContent = '';
        let kpiList = '';
        if (isController) {
            if (hasPC7208) {
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Completeness of Data Inventory:</strong> 100% of identified personal data processing activities are documented in the Record of Processing Activities. This can be measured by periodic audits or questionnaires to business units to ensure no processes are missing. <em>Target:</em> All new processing activities are added to the inventory <strong>before</strong> processing begins, and no significant processing operation is undocumented.</li></ul>`;
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Timeliness of Inventory Updates:</strong> The average time between a change in a processing activity (e.g. new purpose, new data category, change in retention, etc.) and its reflection in the data inventory. <em>Target:</em> Updates to the ROPA within <strong>5 working days</strong> of the change notification. Similarly, new processing initiatives should be recorded promptly (e.g. prior to launch).</li></ul>`;
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Annual Review Completion Rate:</strong> Whether a formal review of all processing records is conducted at least annually (or another defined frequency). <em>Target:</em> <strong>100%</strong> of ROPA entries reviewed and confirmed accurate by process owners and the DPO during the scheduled review cycle. This ensures ongoing accuracy and relevance of records.</li></ul>`;
            }
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Accountability Metrics:</strong> Number of compliance issues or audit findings related to data governance or record-keeping. <em>Target:</em> <strong>0</strong> major findings. For example, an external or internal audit should find that records are accurate and that responsible personnel are aware of their duties. Additionally, track any <strong>regulatory requests</strong> (e.g. supervisory authority requests for records) and measure <strong>response time</strong> and completeness. <em>Target:</em> All regulatory requests for information (e.g. Art. 31 cooperation) fulfilled within the stipulated deadline, with no omissions.</li></ul>`;
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Training and Awareness:</strong> Percentage of relevant staff (e.g. department heads, data stewards, IT/security, etc.) who have undergone training on this SOP and their responsibilities in data governance. <em>Target:</em> <strong>100%</strong> of designated roles trained yearly. This KPI ensures that those involved understand the procedure, leading to better compliance (though indirect, it supports the other KPIs by ensuring knowledge).</li></ul>`;
            if (hasPC7208) {
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Security Incidents related to Unknown Processing:</strong> Number of security incidents or data breaches arising from “shadow” processing operations (i.e. activities that were not known or recorded). <em>Target:</em> <strong>0</strong>. This is an outcome indicator illustrating that all processing is governed; if an incident occurs in an undocumented process, that indicates a gap in inventory.</li></ul>`;
            }
        } else { // Processor
            if (hasPC8206) {
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Processing Records Coverage:</strong> The percentage of active client engagements (or contracts involving personal data processing) for which a <strong>record of processing</strong> exists and is fully populated. <em>Target:</em> <strong>100%</strong> – every service or project that involves personal data has an entry in our processor data inventory. This ensures no processing is unaccounted for.</li></ul>`;
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Timeliness of Record Updates:</strong> Average time taken to update the processing record after any change in processing instructions or scope from a controller (e.g., if a client adds a new data category for us to process, or ends a processing activity). <em>Target:</em> <strong>Within 5 business days</strong> of the change. This KPI ensures that our inventory remains current and reflective of actual activities, and that we promptly act on updates from clients.</li></ul>`;
            }
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Incident Notification Speed:</strong> In the event of a detected personal data breach in our systems, measure the time from detection to notifying the affected controller(s). <em>Target:</em> <strong>Immediately and no later than 24 hours</strong> after confirmation of the breach (well within the “undue delay” standard of GDPR Art. 33(2)). We can track this as the percentage of incidents where notification occurred within the target window. Maintaining a high percentage (ideally 100%) demonstrates our readiness and compliance in breach situations.</li></ul>`;
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Audit and Assessment Results:</strong> Count of <strong>audit findings or non-conformities</strong> related to our role as a processor in external audits (e.g., ISO 27701 certification audits or client compliance audits). <em>Target:</em> <strong>0 major non-conformities</strong> and minimal minor findings. Specifically, if a client audits us and checks our records (as they are entitled to under many DPAs), we aim for them to find our documentation complete and our practices compliant. Any findings should be addressed promptly, and the KPI can track closure of findings within a set timeframe (e.g., 30 days).</li></ul>`;
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Client Satisfaction / Compliance Queries:</strong> Number of compliance-related inquiries or complaints from client controllers regarding our data handling. <em>Target:</em> <strong>Maintain at low levels</strong> (qualitatively, positive feedback). For example, if clients frequently ask us for missing information about our processing or raise concerns that we’re doing something not agreed, that’s a red flag. Ideally, our proactive documentation and transparency reduce such issues. If we distribute regular reports to clients about how we handle their data (like sub-processor lists, security measure updates, etc.), the uptake and feedback on those can also be an indicator.</li></ul>`;
            kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Training and Awareness (Processor context):</strong> Percentage of relevant employees (especially those in operations, IT, and project management for client work) trained on their obligations as a processor and on this SOP. <em>Target:</em> <strong>100% initially trained</strong>, with annual refreshers. This KPI ensures our team understands that even though we don’t “own” the data, we have strict duties. A well-trained team should correlate with fewer errors in following client instructions or reporting issues.</li></ul>`;
            if (hasPC8202) {
                kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Compliance with Contractual Obligations:</strong> Many obligations in GDPR are mirrored in Data Processing Agreements (DPAs) with clients (e.g., assistance with DSARs, not engaging sub-processors without consent, etc.). We can define an internal KPI to track <strong>DPA compliance</strong> such as: number of times we missed a contractual deadline (like assisting with a data subject request beyond the agreed time) or instances of unauthorized processing. <em>Target:</em> <strong>Zero breaches of DPA terms</strong>. This KPI is more preventive – by monitoring, we ensure any slip is caught and rectified, feeding back into training or process improvement.</li></ul>`;
            }
        }

        if (kpiList) {
            kpisContent += `<p>To measure the effectiveness of this Governance & Data Inventory procedure, the following KPIs are established:</p>${kpiList}`;
            kpisContent += `<p>Regular reports on these KPIs will be presented to the Privacy Governance Committee (or senior management) by the Data Protection Officer. Meeting the targets indicates the SOP is effective; falling short will trigger remedial actions (e.g. additional training, process adjustments) to improve governance.</p>`;
            htmlContent += renderSectionHeading('Key Performance Indicators (KPIs)') + kpisContent;
        }

        // --- Section 4: Responsibilities ---
        let responsibilitiesContent = '';
        if (isController) {
            responsibilitiesContent += `<p>Clear responsibilities are assigned to ensure this procedure is implemented and maintained effectively:</p>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Protection Officer (DPO) / Privacy Officer:</strong> The DPO is the <strong>owner of this procedure</strong>. They oversee the data inventory and governance process end-to-end. Key duties include: maintaining the master Record of Processing Activities, advising on documentation of purposes and legal bases${(hasPC7201 || hasPC7202) ? ' (PC7201 & PC7202 compliance)' : ''}, monitoring compliance with this SOP (GDPR Art. 39 tasks include monitoring and advising), and reporting to top management on the state of data processing records. The DPO also serves as the point of contact for regulatory authorities (GDPR Art. 31 cooperation) and is responsible for providing the ROPA or related information to the authority upon request. Additionally, the DPO ensures that if a <strong>DPO is mandated</strong> under GDPR Art. 37(1), the designation is properly communicated and the DPO’s contact info is published. (If no DPO is legally required, a Privacy Officer or equivalent is assigned these tasks.)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Department Heads / Process Owners:</strong> For each business department or function that processes personal data, a designated Process Owner (e.g. department head or an appointed data steward in that team) is <strong>responsible for identifying and reporting</strong> all personal data processing activities in their area. They must provide the necessary details for the data inventory (purposes, data types, systems used, third-party recipients, etc.) and ensure updates are communicated when processes change. They also must implement the governance controls in their operations (ensuring staff follow documented procedures) and attest to the accuracy of records during periodic reviews. Essentially, they are the first line of accountability for compliance within their processes (GDPR Art. 24 expects management to take ownership of compliance measures).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Privacy Governance Committee or Compliance Team:</strong> If the organization has a privacy or compliance committee, it is responsible for <strong>oversight and escalation</strong>. This body (comprised of senior management, IT, Legal, DPO, etc.) reviews KPI reports, endorses the annual ROPA review results, and addresses any compliance gaps. They also approve any significant changes in processing from a governance perspective (for example, if a new high-risk processing activity is proposed, they ensure a proper assessment (e.g. DPIA) is done and that it’s added to the inventory with all required details).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Legal Department:</strong> The legal team supports determining the <strong>lawful basis</strong> for each processing (GDPR Art. 6) and ensures that documentation (privacy notices, consent forms, contracts) align with the recorded purposes and legal bases. ${hasPC7207 ? 'They also prepare or review <strong>joint controller agreements</strong> when needed (GDPR Art. 26, PC7207) and ensure that any obligations taken in contracts (e.g. with processors or partners) are reflected in the governance documentation. In case of joint controllership, Legal ensures roles are allocated and documented per this SOP and that any sharing of the ROPA or coordination between organizations is managed.' : 'They ensure that any obligations taken in contracts (e.g. with processors or partners) are reflected in the governance documentation.'}</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Information Security Officer / IT Department:</strong> The IT and security teams are responsible for implementing and documenting <strong>security measures for each processing activity</strong> (GDPR Art. 32). They provide input to the data inventory regarding technical and organizational measures in place (e.g. access controls, encryption, backup policies for each system listed). They also assist in maintaining inventories of systems or applications that store personal data (often feeding into the ROPA). If new technology is introduced for processing, IT must inform the DPO/Process Owner so it can be documented. They also cooperate in periodic audits of processing activities, especially related to verifying security controls.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Records Management / Data Governance Team (if applicable):</strong> In organizations with a records management function, that team will collaborate with the DPO to ensure retention schedules for each processing are defined and reflected in the inventory (GDPR Art. 30(1)(f) requires noting retention where possible). They help validate that data disposal or archiving processes align with what is documented.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>All Employees (General Awareness):</strong> All staff involved in processing personal data are responsible for following the approved procedures and <strong>using personal data only as documented</strong>. They are expected to <strong>report any new use of personal data</strong> or changes to existing uses to their Process Owner or the DPO, to allow update of the records. Employees must also promptly report incidents (security breaches) or any potential compliance concerns to enable proper action (though incident handling is outside this SOP, it ties in by possibly requiring updates to the inventory or informing authorities). Regular training ensures employees understand that undocumented processing is prohibited and that they should consult the DPO if unsure about compliance.</li></ul>
                <p>Each role’s responsibilities interlock to create a robust governance system: Process Owners manage day-to-day data use under the guidance of the DPO, who provides oversight and expertise, while upper management ensures resources and priority for compliance. This division of responsibilities ensures that maintaining the data inventory and related controls is a shared effort and integrated into business as usual.</p>`;
        } else { // Processor
            responsibilitiesContent += `<p>To effectively execute this SOP, the following roles and departments have been identified along with their specific responsibilities as a data processor:</p>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Protection Officer (DPO) / Privacy Officer:</strong> The DPO is the <strong>primary owner</strong> of this procedure. They oversee the creation and maintenance of the processing activities record for all client data processing. They ensure that each new client contract or project involving personal data triggers the process of documentation and compliance checks. The DPO advises on and monitors adherence to <strong>controller instructions</strong> (GDPR Art. 28(3)(a)) ${hasPC8202 ? 'and ensures we do not deviate in purpose (PC8202 compliance)' : ''}. They are also responsible for handling communications with controllers on privacy matters: for example, if a client asks for information needed for their Article 30(1) records or a DPIA, the DPO coordinates our response. In case a <strong>breach occurs</strong>, the DPO (or their team) is responsible for notifying the controller without undue delay, providing all necessary information about the incident (nature of breach, data affected, mitigation steps) in line with GDPR Art. 33(2). Additionally, the DPO cooperates with any supervisory authority inquiries (often via the controller, but potentially directly if asked), supplying records and information as needed (GDPR Art. 31). The DPO also keeps abreast of changes in law or standards to update the SOP and train staff accordingly. If a formal DPO is not mandated, an equivalent privacy manager is designated to perform these duties.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Client Account Managers / Project Managers:</strong> For each client relationship involving personal data, an Account Manager or Project Manager acts as the <strong>Process Owner</strong> for that processing from our side. They are responsible for understanding the data processing instructions in the contract or Statement of Work and conveying those to the operational teams. Crucially, they must ensure that <strong>no processing happens outside the agreed scope</strong>. If a client requests a change (e.g., “please also process X data for us”), the account manager must obtain necessary approvals (both internally and possibly via an amended DPA) and then inform the DPO to update records. They provide the DPO with initial details needed for the inventory: e.g., “Client ABC – we process customer emails and purchase history to provide support services, data is stored in System Y for 3 months…”. They also inform the DPO when a contract is terminated so the records can be updated (and data deletion can be arranged as per contract). Essentially, they act as the liaison between the client’s requirements and our internal compliance process, and they ensure operational teams stick to what’s documented. They also coordinate any client audits of our facilities or processes, working closely with the DPO and IT to satisfy the client’s oversight needs (as is their right under GDPR Art. 28).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>IT and Security Team:</strong> This team is responsible for implementing <strong>appropriate technical and organizational measures</strong> for the processing we perform (GDPR Art. 32). For every client service or processing activity, IT/Security ensures that data is stored and processed securely (access controls, encryption, network security, etc., as applicable). They document these measures and provide the descriptions to the DPO for inclusion in the record of processing (GDPR Art. 30(2)(d) requires a general description of security measures). They also manage any <strong>sub-processors or infrastructure</strong> (e.g., if we use a cloud service to host client data, the security team ensures there is a proper contract and that it’s disclosed to the client and in our records). In case of a security incident, the IT/security team works urgently to contain and investigate it, and provides the DPO with the facts needed for breach notification (what happened, how many records, root cause, etc.). They also facilitate client or regulator audits of security by showcasing relevant certifications or controls. Overall, they uphold the “security” aspect of governance and ensure that what we promise in contracts (often we commit to certain security standards) is actually executed.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Legal Department:</strong> The legal team plays a key role at the beginning of the processor relationship with each controller. They negotiate and review Data Processing Agreements (DPAs) which outline our obligations. They ensure that our contracts contain all required clauses (as per GDPR Art. 28(3)) and reflect reality (e.g., listing authorized sub-processors, setting breach notification timelines, etc.). Once a DPA is in place, Legal provides the DPO and Account Manager with the <strong>relevant extracts</strong> of obligations (for instance, “for Client ABC, we agreed to delete data within 30 days of contract termination” or “Client XYZ insists on review of any sub-processor changes”). This informs how we implement the processes. Legal also approves any new sub-processors (often needing client consent) and ensures such changes are documented. If there is any legal inquiry or if a client claims we deviated from instructions, Legal will get involved to assess our compliance and liability. They also help interpret any gray areas in instructions – if it’s unclear whether an action is allowed, the legal team clarifies it with the client or adjusts contract wording. In summary, Legal ensures we have a solid contractual basis for what we do and that our SOP aligns with those commitments. They also keep records of all DPAs and amendments, which the DPO can cross-reference with the processing inventory.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Operations Team Members:</strong> These are the employees actually handling or processing the data (e.g., support engineers, analysts, etc., depending on our service). They are responsible for <strong>following documented procedures</strong> exactly. They should not improvise uses of data beyond what is instructed. They receive training (as per this SOP) to recognize instructions boundaries. For example, if an operations member sees an opportunity to analyze client data for a helpful insight, they must first check if that’s within scope or get clearance (they can’t just do it, even if well-intentioned, because that could breach terms). They also must maintain confidentiality and security in their daily work (only use approved tools, etc.). If they accidentally receive a request directly from a data subject (e.g., a person contacts our support asking to delete their data), they must know to forward this to the controller or to our DPO rather than handling it, since as a processor we assist but do not respond directly unless authorized. Additionally, if they notice anything odd – like a potential data breach or an instruction that seems to violate privacy laws – they have the duty to escalate it (for instance, PC8204 “Infringing instruction” expects processors to inform controllers if an instruction seems illegal; while not explicitly listed, our staff should be primed to alert the DPO in such cases so we can handle it properly).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Compliance/ Audit Team (if distinct from DPO):</strong> If we have an internal audit or compliance function, they periodically review how well this SOP is followed. They might check a sample of processing activities to ensure records are complete and match the contract terms, or simulate an authority inquiry to see if we can produce records quickly. They would report any findings to the DPO and management, prompting improvements. They also ensure our ISO 27701 controls ${hasPC8202 ? '(PC8202, ' : ''}${hasPC8206 ? 'PC8206, ' : ''}etc.) are in place for certification if we pursue that.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Management (CTO/CIO/COO):</strong> Senior management ensures that sufficient resources (personnel, tools) are allocated to meet these obligations. They support the culture of compliance by reinforcing to all teams the importance of following client instructions and keeping records. If a conflict arises between meeting a client request fast and doing it compliantly, management must back the compliant approach (for example, not pressuring teams to skip the DPO check because of a tight deadline). Management also reviews KPI reports, as mentioned, and takes action on systemic issues (like if training is insufficient or if too many incidents are happening). They also empower the DPO to halt processing if something is not in order (this is critical – the DPO needs authority to say “we can’t proceed until this is compliant”). By fulfilling these responsibilities, management demonstrates top-down commitment to GDPR compliance, which is often looked at in assessments and audits.</li></ul>
                <p>By delineating these responsibilities, we ensure everyone in the organization knows their specific duties in protecting personal data we process on behalf of controllers. This clarity prevents gaps or overlaps that could lead to compliance failures. It also provides a quick reference (in wiki fashion) so new team members in any of these roles can understand how they fit into the bigger compliance picture.</p>`;
        }
        if (responsibilitiesContent) {
            htmlContent += renderSectionHeading('Responsibilities (Roles & Departments)') + responsibilitiesContent;
        }


        // --- Section 5: Detailed Processes ---
        let processesHtml = ''; // Build the inner HTML for processes
        let processSectionRendered = false;

        // --- Process 5.1 (Controller: ROPA / Processor: Cataloguing) ---
        let process51Content = '';
        if ((isController && hasPC7208) || (isProcessor && hasPC8206)) {
            process51Content += `<p>This process covers creating the initial data inventory and updating it for new or changed processing activities. It ensures compliance with GDPR Art. ${isController ? '30(1) and ISO 27701 controls on records (PC7208)' : '30(2) and ISO 27701 controls on records (PC8206)'}.</p>`;
            process51Content += renderStep('Step 1: Inventory Initiation & Scope Definition', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Management’s commitment to accountability (GDPR Art. 5(2)) and an overview of all departments/functions that process personal data. Any existing lists of processes, data maps, or past records serve as starting materials.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO (or Privacy Team) launches the inventory project by defining the <strong>scope</strong> of the ROPA – identifying all business areas that handle personal data. A template is prepared listing the fields required by GDPR Art. ${isController ? '30(1)' : '30(2)'} (e.g., ${isController ? 'purpose, categories of data subjects, categories of personal data, recipients, transfers, retention, security measures, etc.' : 'controller details, categories of processing, transfers, security measures, etc.'}). The DPO communicates with each Department Head/Process Owner, providing guidance on how to identify and describe their processes. Workshops or questionnaires may be used to capture all processing activities. Each Process Owner, with support from the DPO, <strong>documents each processing activity’s details</strong>.${isController ? ` Crucially, for each processing activity, ${hasPC7201 ? 'the <strong>purpose</strong> is clearly recorded (fulfilling PC7201: “identify and document the specific purposes”) and ' : ''} ${hasPC7202 ? 'the <strong>lawful basis</strong> is identified and recorded (fulfilling PC7202: “determine and document the relevant lawful basis”)' : 'the purpose and lawful basis are identified and recorded'}. The DPO ensures the purposes are specific and legitimate and that a valid legal basis (e.g. consent, contract, legitimate interest, etc.) is assigned for each, per GDPR Art. 6. If any processing is found to lack a clear purpose or lawful basis, it is flagged for revision or discontinuation (this enforces compliance from the start).` : ` They gather details like controller identity, ${!isPC82061aOverridden ? 'categories of processing, ' : ''}data types, ${!isPC82061bOverridden ? 'transfers, ' : ''} ${!isPC82061cOverridden ? 'security measures, ' : ''}sub-processors, and retention info based on the client contract.`}</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>${isController ? 'Initial ROPA Established' : 'Initialized Processor Record for the Client'}</strong> – a documented inventory entry for each identified processing activity. This master record includes all required information per GDPR Article ${isController ? '30(1)' : '30(2)'}. Each entry typically contains:
                    ${isController ? `
                        ${!isPC72081bOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Purpose of processing (why data is processed)</li></ul>' : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle">Lawful basis (e.g. consent, contract, etc.)</li></ul>
                        ${!isPC72081cOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Categories of data subjects and personal data involved</li></ul>' : ''}
                        ${!isPC72081dOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Categories of recipients (internal and external)</li></ul>' : ''}
                        ${!isPC72081dOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">International transfers (if any) and relevant safeguards</li></ul>' : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle">Retention period or criteria for data deletion</li></ul>
                        ${!isPC72081eOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">General description of security measures (per Art. 30(1)(g))</li></ul>' : ''}
                        ${!isPC72081fOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Whether a Data Protection Impact Assessment was done (if high risk – linking to the report)</li></ul>' : '<ul class="bulleted-list"><li style="list-style-type:circle">Whether a Data Protection Impact Assessment was done (if high risk – a note for governance)</li></ul>'}
                        <ul class="bulleted-list"><li style="list-style-type:circle">Date of last update and owner of the process.</li></ul>`
                    : `
                        <ul class="bulleted-list"><li style="list-style-type:circle">Name of controller (and representative/DPO if applicable).</li></ul>
                        ${!isPC82061aOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Categories of processing we do for them.</li></ul>' : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle">Details on data, purpose, etc., for internal clarity.</li></ul>
                        ${!isPC82061cOverridden ? `<ul class="bulleted-list"><li style="list-style-type:circle">Notation of applicable security measures ${!isPC82061bOverridden ? 'and any transfer info.' : '.'}</li></ul>` : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle">Reference to the contract or DPA.</li></ul>`}
                     <p>This output is typically in a centralized register (could be a spreadsheet, database, or GRC tool). It serves as the baseline for ongoing maintenance.</p></li></ul>`);

            // Controller Step 2
            if (isController) {
                process51Content += renderStep('Step 2: Ongoing Update for New Processing Activities', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Proposal or request for a <strong>new personal data processing activity</strong> or a significant change to an existing one. This could come from a project plan, a new system implementation, a marketing initiative requiring personal data, etc. The input includes details from the initiating team: what data they want to process, for what purpose, expected data subjects, etc.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> <strong>Before</strong> a new processing begins (or immediately upon a change), the Process Owner must engage the DPO for a compliance check. Together, they conduct a review: ${hasPC7201 ? 'the DPO helps define the purpose and ensure it’s documented clearly (again aligning with PC7201) and ' : 'the DPO helps define the purpose and '}${hasPC7202 ? 'identifies the appropriate lawful basis (PC7202)' : 'identifies the appropriate lawful basis'}. If the processing is high-risk, the DPO might require a DPIA per GDPR Art. 35 (outside scope here, but noted for context). The Process Owner provides all details needed for the ROPA entry. The DPO (or designated Privacy Analyst) then <strong>creates a new record</strong> in the ROPA or updates the existing record. They fill in all relevant fields, ensuring nothing is omitted. Particular attention is given to <strong>security measures</strong> – consulting IT to document how this new process will secure the data (GDPR Art. 32 compliance, e.g. encryption, access controls). The DPO validates that adding this processing does not conflict with any GDPR principles (e.g. if purpose is incompatible with original collection purpose, that’s flagged). Once approved, the new entry is officially added to the inventory.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Updated Data Inventory</strong> – the new processing activity is now part of the official ROPA, with a complete entry. Any necessary notifications or registrations (for example, if law requires notifying a regulator about certain processing) are prepared using this information. The output also includes communication: the DPO may issue a confirmation to the Process Owner that the activity is now recorded and can proceed from a compliance standpoint (assuming all other checks passed). This step ensures no new personal data use goes unaccounted. It directly supports GDPR Art. 5(2) (accountability) by requiring documentation and DPO sign-off for new uses of personal data.</li></ul>`);
            }
            // Processor Step 2 (Adhering to Instructions - conditional on PC8202)
            else if (isProcessor && hasPC8202) {
                process51Content += renderStep('Step 2: Adhering to Controller Instructions and Scope Management', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Ongoing operational instructions from the controller. These could be in the form of support tickets, batch processing requests, or day-to-day data handling tasks defined by the service. Also input: The agreed-upon instructions set in the contract (as a baseline) and any updates or clarifications the controller provides during the relationship.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Our operational teams carry out processing <strong>strictly as documented</strong>. This means that if the controller’s instruction says “process dataset A for purpose B,” we do that and nothing else. The Account/Project Manager and team leads ensure that all team members are familiar with the scope (maybe they have a summary of “dos and don’ts” for each client). If any employee is uncertain if an action is covered by the instructions, they must pause and escalate to the Account Manager or DPO. For example, if a client asks, “Can you also analyze this data for trend X?” and that wasn’t originally agreed, the Account Manager will consult the DPO and likely the legal DPA to see if this is allowed. If not clearly allowed, we either get written permission (which may mean updating the DPA) or politely decline. This operational discipline is a control implementing PC8202: ensuring we only process data for the <strong>purposes expressed by the customer</strong> and nothing more. Additionally, if we foresee a need to slightly diverge (maybe we want to use a snippet of data to improve our service algorithm), we treat it as a change request to the controller – i.e., we obtain consent or instruction before doing it, or have it in the contract that we can do so in certain ways. Meanwhile, the DPO monitors compliance by periodically checking with teams or via system logs that no unauthorized processing is happening (for instance, ensuring no one from marketing is accessing client data to send newsletters – which would be outside scope).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Demonstrated Compliance with Instructions.</strong> On a day-to-day basis, this output is somewhat intangible (it’s the absence of unauthorized processing). However, it can be evidenced by records like meeting minutes or emails where we sought clarification or permission from controllers for anything not explicitly covered, and by the lack of any client complaints that we’re using data in unexpected ways. In an audit, we could show our training materials or SOP excerpts that enforce this rule, and sample workflows where a client’s instruction was followed exactly. Essentially, the output is maintaining a trustworthy service where controllers can be confident we won’t surprise them with other uses of their data. If any new instruction is given by the controller, we treat that as input to possibly update the record (see Step 3). This step aligns with our legal obligation under GDPR Art. 29 (processors only act on instructions) and cements trust required by Art. 28 contracts.</li></ul>`);
            }

            // Step 3 (Common structure, some content differs)
            process51Content += renderStep('Step 3: Regular Review & Audit of Records', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The existing Record of Processing Activities (from prior steps), and any changes that occurred since the last review (changes could be gleaned from change logs, or by asking Process Owners to report any updates). Also input: regulatory updates or new guidance, which might require adding new elements to records (e.g. if the law changes). A schedule (at least annual) triggers this review.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO initiates a <strong>periodic review</strong> (e.g. annually) of all ROPA entries. Each Process Owner is asked to verify the accuracy of their entries. They check: Is the purpose still accurate and up-to-date? Are there new data elements or new recipients introduced that weren’t recorded? Have any processes been retired (and thus should be marked as such)? The DPO and possibly Internal Audit or the Compliance team perform spot-checks and audits to ensure the records match reality (e.g. compare an IT system’s data with what the inventory says). They also verify that for each processing, the documented <strong>legal basis remains valid</strong> (if laws changed or if consent mechanisms changed, etc., updates are made accordingly) and that <strong>retention schedules</strong> are being followed (update retention info if policy changed). Any missing information is completed; any outdated information is corrected. Additionally, they ensure <strong>security descriptions</strong> are updated if new controls were implemented or if threats evolved (maintaining GDPR Art. 32 alignment). If any processing is no longer necessary or has no legal basis, the DPO will flag it for termination – demonstrating compliance with data minimization and lawfulness. ${isController && hasPC7207 ? 'For joint controller situations, the review also checks that the joint controller agreement is current and that the essence of the arrangement is reflected in the record (per PC7207 and GDPR Art. 26).' : ''}</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Validated and Current Data Inventory.</strong> After review, each record is marked as “reviewed on [date]” and signed off (digitally or physically) by the Process Owner and/or DPO. The output may include a <strong>summary report</strong> of the review to management, noting any major changes or compliance issues discovered. This creates an audit trail proving that the organization is periodically verifying its compliance (which is evidence of accountability for regulators). If needed, an updated public privacy notice or registry entry might be derived from the updated ROPA (ensuring transparency to data subjects if required by law to publish certain info). Essentially, the inventory is “reset” to an accurate state, ready to serve as proof of compliance. This step fulfills the obligation to <strong>demonstrate compliance</strong> on an ongoing basis (GDPR Art. 24(1)) and aligns with ISO guidance that records should be maintained to support obligations.</li></ul>`);

            // Step 4 (Common structure, minor phrasing differs)
            process51Content += renderStep('Step 4: Secure Maintenance and Access Control', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The compiled records (${isController ? 'ROPA' : 'Processor Records'}) and the IT system or repository where they are stored (e.g. a compliance tool or a SharePoint/Excel file). Also input: organizational policy on information classification (the records contain sensitive info about processing which might be confidential).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO, in collaboration with IT, ensures that the ${isController ? 'ROPA' : 'Processor Record'} document or database is <strong>securely maintained</strong>. This includes controlling who can view or edit it. For instance, only the DPO and authorized privacy team members may edit the master record; Process Owners might have rights to update their portions or to submit change requests. Access is restricted to prevent unauthorized alteration or leakage (since the records might contain internal process details). Regular backups are taken to preserve the record (in line with good record-keeping). Additionally, the DPO sets up a process to log changes – every update to the inventory should be tracked (who changed what and when) to maintain a history. This aligns with ISO 27701’s emphasis that records should be <strong>securely maintained</strong>. If the supervisory authority requests access to these records (per GDPR Art. 31 and Art. ${isController ? '30(4)' : '30(2)'}), the DPO can retrieve the latest version knowing it’s accurate and complete.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Securely Maintained Repository</strong> – the data inventory is stored in a secure manner with proper version control and access logs. The output is not a document per se, but a state: the ${isController ? 'ROPA' : 'Processor Records'} are protected against loss, tampering, or unauthorized access. This ensures integrity and confidentiality of the records themselves, which is important as they might include sensitive operational info. It also ensures that when management or regulators need to see the records, they can trust the information. This step, though technical/administrative, is crucial to meet the requirement of keeping records in support of processing obligations.</li></ul>`);

            // Step 5 (Controller Only)
            if (isController) {
                process51Content += renderStep('Step 5: Providing Records & Demonstrating Compliance', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> A request either from a supervisory authority (formal notice to provide records or information) or as part of an internal/external audit. The input could be a letter from the Data Protection Authority asking for an overview of processing activities, or it could be a part of ISO 27701 certification audit requiring evidence of PC7208 compliance.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Upon receiving a request, the DPO retrieves the relevant portions of the data inventory. If it’s a full Article 30 report request, the DPO compiles the ROPA details into the required format. They double-check if any entries contain jargon or internal codes and clarify them for the recipient. The DPO coordinates with Legal if the release of certain information needs approval. Then, <strong>cooperation with the authority</strong> is executed: the DPO sends the records or arranges a meeting to present them, <strong>facilitating the authority’s task</strong> in accordance with GDPR Art. 31. If the request is for a subset (e.g. “records relating to X processing operation”), the DPO ensures that subset is extracted accurately. During audits, the DPO and relevant Process Owners walk auditors through how the records are maintained and demonstrate this SOP in action. They show evidence for each step (e.g. change logs, review reports, etc.). Throughout, the organization remains transparent and helpful, as required.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Compliance Evidence Provided</strong> – e.g. an exported report or document containing the up-to-date records, delivered to the requesting authority or auditor. The quality and completeness of this output is critical: it should satisfy the request fully. A copy of what was provided and the date is saved in the compliance archive. Successfully providing this information demonstrates the organization’s accountability in practice. If the supervisory authority has follow-up questions or guidance, those will be documented for continuous improvement. This process essentially closes the loop: the records we maintain are used to <strong>prove our compliance</strong> externally.</li></ul>`);
            }
            // End paragraph for Process 5.1
            process51Content += `<p>${isController ? 'By executing Process 5.1, the organization creates a living data inventory and governance record that satisfies legal requirements and serves as a cornerstone of privacy compliance. This process is continuous, ensuring that at any point in time, the organization can answer the fundamental questions: <em>What personal data do we process? Why? Under what lawful basis? Who is responsible? How is it secured?</em> – and do so with confidence and evidence.' : 'By following Process 5.1’s steps, we maintain a robust inventory and lifecycle management of processing activities for all controllers we work with. This addresses the <em>data inventory</em> aspect of governance: we know <strong>what we process, for whom, and all relevant details at all times</strong>, fulfilling GDPR 30(2). Next, we handle the broader governance, including security and incident handling.'}</p>`;

            processSectionRendered = true;
            processesHtml += renderSubSectionHeading(isController ? 'Establish and Maintain the Record of Processing Activities (ROPA)' : 'Cataloguing and Updating Processing Activities for Each Controller') + process51Content;
        }

        // --- Process 5.2 (Controller: Governance / Processor: Security) ---
        let process52Content = '';
        let step3RenderedForController5_2 = isController && hasPC7207;
        let process52Rendered = (isController && (true || step3RenderedForController5_2)) || isProcessor; // Simplified: always render if role exists

        if (process52Rendered) {
            if (isController) {
                process52Content += `<p>This process involves oversight mechanisms to ensure that the data inventory is not just a document, but part of an effective governance system. It covers management oversight, reporting, and handling special scenarios such as joint controllership or major compliance changes. It ties into ISO 27701’s controls for governance ${hasPC7207 ? '(PC7207 joint controller arrangements)' : ''} and general GDPR accountability obligations.</p>`;
                process52Content += renderStep('Step 1: Assign and Communicate Roles and Responsibilities', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Organizational chart and identification of key roles (DPO, Process Owners, etc.) as described in section 4. Also, the finalized data inventory from Process 5.1, which indicates who the owner is for each processing activity.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The Privacy Governance Committee (or top management) formally <strong>assigns responsibility</strong> for each component of data governance. For instance, for each ROPA entry, the designated Process Owner is confirmed and that person is made aware of their duties regarding that processing. The DPO issues an internal memo or inclusion in job descriptions outlining each role’s tasks in maintaining the data inventory and ensuring compliance (this includes the DPO’s own role, Process Owners, IT, etc.). If a Data Protection Officer has been appointed per GDPR Art. 37(1), this is communicated company-wide along with instructions that the DPO must be involved in all personal data initiatives. The DPO also communicates the <strong>requirement that any planned changes or new projects involving personal data must follow the SOP (contact DPO, update ROPA, etc.)</strong> – effectively operationalizing Article 5(2) (everyone has a part in demonstrating compliance). ${hasPC7207 ? 'Additionally, if the organization might enter into joint controller relationships, management assigns a <strong>Joint-Controller Liaison</strong> (could be the DPO or Legal) to handle those arrangements.' : ''}</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Governance Structure Documented and Communicated.</strong> A clear mapping of roles to responsibilities (e.g. RACI matrix for personal data processes) is produced and stored (perhaps appended to this SOP or in a governance manual). Each responsible person has acknowledged their role. The organization now has named individuals accountable for each aspect of compliance (which auditors and regulators view favorably as part of accountability). ${hasPC7207 ? 'This output aligns with ISO’s emphasis that roles (especially in joint processing) be transparently determined and ' : ''}supports GDPR Art. 24 which implies that appropriate personnel and resources be assigned to ensure compliance.</li></ul>`);
                process52Content += renderStep('Step 2: Management Review and Approval', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Reports from Process 5.1 (e.g. KPI results, the summary of the ROPA review, any incidents or compliance issues found). Also input: any significant changes in risk or legal requirements since the last management review (for example, new guidance from Data Protection Authorities or changes in the business).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> On a regular basis (e.g. annually or semi-annually), <strong>senior management reviews the state of the PII processing governance</strong>. The DPO presents a report containing: the KPIs from section 3 (e.g. is the inventory complete and updated, were there any gaps?), any notable deviations or issues (e.g. an instance where a department started processing data without informing – which is a compliance incident), and confirms whether the current processes meet ISO 27701 and GDPR requirements. Management discusses whether additional resources are needed or if any improvements to this SOP are warranted. For example, if the KPI “timeliness of updates” is lagging, management might decide to enforce a stricter project gating process (no project can launch without DPO sign-off). If a new regulation is on the horizon, they task the DPO to update the inventory accordingly. Importantly, if the organization has <strong>joint controller scenarios</strong> or complex processing, management ensures that appropriate agreements are in place and reviewed. ${hasPC7207 ? 'They may review any joint controller contracts (as per PC7207, which calls for documenting terms between joint controllers).' : ''} Management also ensures that any <strong>data processors</strong> (vendors) the company uses are under contract and listed in the ROPA with their roles (though processor management might be another SOP, it’s checked here for consistency and completeness of records). The outcome of this review might include approving the updated ROPA formally, signing an annual compliance statement, or approving action items to address gaps.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Management Review Minutes and Action Plan.</strong> There will be a documented record of the review meeting, including decisions and endorsements (e.g. “Management confirms that the ROPA as of date X is complete and accurate to the best of our knowledge” or “Action: IT to integrate inventory update checks into change management process by Q4”). This document is stored with compliance records and can be shown to auditors/regulators as proof of top-level oversight (a key element of accountability governance). Additionally, if any changes to the SOP are needed (for instance, adding a new KPI or modifying a process), those are initiated from this output. This ensures continuous improvement of the governance process.</li></ul>`);
                if (hasPC7207) {
                    process52Content += renderStep('Step 3: Handling Joint Controller Relationships (if applicable)', `
                        <p><em>(Skip this step if the organization has no joint controllership; otherwise follow the below.)</em></p>
                        <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> A scenario where the organization determines it is a <strong>joint controller</strong> with another party for a particular processing activity. Input could be a new business initiative involving data sharing, or a review that identifies overlapping control with a partner. Also input: GDPR Article 26 requirements and ISO guidance on joint controllers.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> When a joint controller situation is identified, the DPO and Legal work with the other organization to <strong>establish a Joint Controller Agreement</strong> that allocates responsibilities in compliance with GDPR Art. 26. This agreement will detail how each party ensures data subject rights, who is responsible for security, breach notifications, responding to access requests, etc., and the respective purposes and legal bases each party has. Per PC7207, roles and responsibilities should be determined transparently and documented. The DPO ensures that the essence of this arrangement (as required by GDPR Art. 26(2)) is communicated to data subjects, typically in the privacy notice. In terms of the data inventory, the <strong>ROPA entry is updated</strong> to reflect the joint controller status – listing the other controller’s identity and summarizing the split of responsibilities (for instance, noting that data is shared with Organisation B under joint control for Purpose Y, and that Org B will handle data subject access requests for that dataset, etc.). The DPO also may set up periodic coordination meetings with the joint controller to discuss compliance (ensuring ongoing alignment).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Joint Controller Documentation and Updated Records.</strong> A signed Joint Controller Agreement is in place (Legal keeps it on file, and key points are logged in the data inventory or an annex to it). The ROPA now clearly indicates joint-controller processes, which demonstrates compliance with the requirement to document such complex arrangements. Internally, staff are informed (so they know, for instance, inquiries related to that data might be forwarded to the partner, depending on the agreement). Should a regulator inquire, the organization can produce both the agreement and the ROPA entry to show that it has properly addressed joint accountability. This output ensures that even non-standard processing scenarios are governed and recorded, rather than falling through the cracks.</li></ul>`);
                }
                process52Content += renderStep('Step 4: Training and Awareness Program', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Training materials on GDPR, this SOP, and role-specific guidelines; list of employees/roles who need training (particularly those in responsibilities section).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The compliance or HR team, together with the DPO, conducts a <strong>training program</strong> to ensure everyone understands the governance process. New joiners in relevant roles get this training on induction, and all relevant staff get annual refreshers. The training covers: overview of GDPR principles, what our data inventory is and why it’s important, how to follow this SOP (e.g. “If you plan to start using personal data in a new way, you must contact the DPO and document it first”). It also covers repercussions of non-compliance (both regulatory fines and internal disciplinary action) to emphasize seriousness. Quiz or attestation may be used to confirm understanding. Specialized sessions might be held for Process Owners focusing on how to fill in ROPA fields and for IT teams on how to integrate security measures documentation. The DPO uses real examples (anonymized) from past incidents or audit findings to illustrate how following the SOP prevented problems or how lapses can cause issues.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Trained Personnel and Awareness Records.</strong> Post-training, employees have a clear understanding of their part in data governance. The output includes training attendance logs and scores (maintained to track that 100% target in KPI). Additionally, improved compliance culture often results – for instance, an employee might proactively reach out about a new dataset they want to use, indicating the training was effective. While not a direct “product,” the outcome is a workforce that supports the maintenance of the data inventory by feeding the process with the necessary information and diligence. This human factor is critical: technology and process alone won’t keep the inventory accurate if people don’t comply. This supports GDPR’s implicit requirement for awareness (Art. 39 DPO tasks include training staff), contributing to accountability.</li></ul>`);
                process52Content += renderStep('Step 5: Monitor Regulatory Changes and Update Controls', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Updates from regulators, new guidance, or changes in relevant standards (e.g. a new EDPB guideline on records of processing, or an update to ISO standards or national laws). Also input: results from any compliance audits or data breaches that reveal a need for process change.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO or Legal constantly <strong>monitors the regulatory landscape</strong>. If a change occurs that affects what needs to be in the data inventory or how governance should be done, the DPO updates this SOP and related processes. For example, if regulators emphasize that controllers should also record legitimate interest assessments for each processing that relies on legitimate interest, the DPO will incorporate that into the ROPA template and process (perhaps adding a step for documenting the LI assessment outcome). If ISO/IEC 27701 gets an update (since this SOP references the 2021 version), the DPO checks if any new controls or changes in PC7201, etc., need to be reflected. The DPO may also benchmark against best practices (like code of conduct requirements or other frameworks such as ISO 27001 integration). All necessary changes are drafted and taken to the Privacy Governance Committee for approval. Once approved, the DPO implements the changes: this could mean modifying the inventory fields, retraining staff on new requirements, or adjusting KPIs.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Up-to-date Procedure and Compliance Alignment.</strong> The SOP document itself may get a new version (which is circulated to stakeholders). The data inventory and governance processes remain aligned with current law and standards. This ensures ongoing effectiveness – the procedure is never static if the world changes. Maintaining this adaptability is part of governance: it prevents compliance drift. An up-to-date SOP also is evidence to regulators that the organization is diligent in its responsibilities, not just treating compliance as a one-time project.</li></ul>
                    <p>Through Process 5.2, the organization embeds the data inventory into its governance framework and ensures that accountability is continuously reinforced. Together, <strong>Process 5.1 (operational record-keeping) and Process 5.2 (oversight and improvement)</strong> establish a complete cycle for Governance & Data Inventory management, thereby fulfilling the legal and standard requirements enumerated at the start of this SOP. The data controller can confidently demonstrate at any time how it governs personal data across its lifecycle, knowing that both the records and the processes around those records are solid and compliant.</p>`);
            } else { // Processor: Security Measures
                process52Content += `<p>This process integrates information security into data governance, ensuring that we not only list security measures (as required in records) but also implement and maintain them, and control access to client data. It aligns with GDPR Art. 32 and contributes to the trust controllers place in us.</p>`;
                process52Content += renderStep('Step 1: Implement Baseline Security Controls', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The organization’s information security policies and any specific security requirements agreed in the client’s contract or due diligence phase. For instance, a client may require ISO 27001 compliance or use of encryption at rest, etc. Also input: the categories of data being processed (which inform what level of security is needed based on sensitivity).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The IT/Security team ensures that all systems and processes used for processing personal data have appropriate <strong>security controls</strong>. This includes but is not limited to:. The DPO ensures this description is reflected in the record.
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Access Control:</strong> Limit access to client data strictly to those who need it (principle of least privilege). Create separate accounts/roles for each client’s data if feasible (multi-tenant systems should logically segregate data). Maintain up-to-date user access lists and remove access promptly when personnel change roles or leave (to prevent unauthorized access).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Encryption:</strong> Use encryption for data in transit (TLS on networks) and at rest, especially for sensitive data, as per industry best practices or contract requirements. Document where encryption is applied.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Monitoring and Logging:</strong> Enable logging of access and operations on personal data (who accessed data, when, what was done). These logs are important for detecting unauthorized activities and will be crucial if investigating a breach.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Network Security:</strong> Firewalls, intrusion detection/prevention systems, and other network controls are configured to protect where the data resides or flows.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Backup and Recovery:</strong> Ensure data is backed up if required (and that backups have same security controls). Also ensure we can distinguish personal data in backups for deletion when needed.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Physical Security:</strong> If any data is stored on-premises, ensure secure facility controls.</li></ul>
                        <p>These controls are largely part of our ISMS, but here we emphasize them in context of each processing activity. For each client’s processing environment, the IT team documents the implemented controls and <strong>tests them</strong> (e.g., does our access provisioning indeed restrict as expected? Are our encryption keys managed properly?). The output of this could be an internal checklist or attachment to the processing record that details “Security Measures for Client X’s data: A, B, C…”, fulfilling the requirement to have a description of technical and org measures.</p>
                    </li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Secure Processing Environment &amp; Documented Measures.</strong> We achieve a state where the personal data we handle is protected in line with GDPR Art. 32(1) – appropriate to the risk. The documented list of controls (which might double as part of our ISO 27701 compliance evidence) is stored with each processing record or in a central security document repository. If a client asks “What measures do you have in place?”, we can provide this information (some of it might already be in our contract or security annex). This output reduces likelihood of incidents and is a core part of governance: it shows we not only account for data but also guard it properly.</li></ul>`);
                process52Content += renderStep('Step 2: Restrict and Monitor Data Access', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> List of personnel authorized for each client’s data processing (could be a formal access control list or user management system info). Also, system audit logs and monitoring tools as input sources.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The Security team (or system owners) actively <strong>manage user access</strong> to personal data. This means:
                        <ul class="bulleted-list"><li style="list-style-type:circle">Granting access rights only after approval by the Account Manager/DPO and ensuring those rights match the role’s needs.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Keeping client data logically or physically separated as needed. For example, if we have a shared database for multiple clients, ensure row-level or tenant isolation so one client’s data can’t be seen by another’s team, etc.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Monitoring</strong> usage: automated systems might alert if an unusual access pattern occurs (e.g., a staff member accessing a large amount of data outside of normal scope, or accessing data of a client they don’t usually work on). The security team reviews such alerts. If a suspicious access is detected, they investigate: was it an error, or potentially malicious? If unauthorized, trigger incident response (which leads to notifying the controller if a breach).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Regular reviews of user access: say every quarter, the DPO and IT review who has access to what client data to confirm it’s still needed and appropriate. This could be part of ISO 27001 user access management controls as well.</li></ul>
                        <p>Additionally, any data exports or transfers we perform for the client are carefully logged and controlled (e.g., if we send a data file back to client, ensure it’s via secure channels and only the intended recipient). This step effectively operationalizes privacy principle of confidentiality and integrity.</p>
                    </li></ul>
                     <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Access Audit Trails and Compliance Reports.</strong> The tangible outputs are logs and records: we have detailed logs of data access which are stored securely (for a defined retention). We may also produce periodic access review reports showing “these 5 people currently have access to Client Y’s data, reviewed and approved on [date].” In the absence of incidents, these logs and reports serve as evidence of our diligence. In case of an incident, they become crucial for forensic analysis. This output directly supports our ability to demonstrate to controllers that we only allow authorized processing (often clients might ask for such evidence or even do an audit themselves where we show them anonymized log entries or the process of access provisioning). It also ensures that if the supervisory authority ever asked how we protect data, we have concrete practices to show.</li></ul>`);
                process52Content += renderStep('Step 3: Sub-processor Management', `
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Any sub-processor (third-party service provider) we use that will handle the controller’s personal data. This includes cloud service providers, SaaS tools, consultants, etc. Input also includes the contracts with those sub-processors and the consents/approvals from controllers for using them (usually listed in the DPA or obtained separately).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Managing sub-processors is a vital part of processor governance. The steps include:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Assessment and Contracting:</strong> Before engaging a sub-processor, our Legal/DPO conducts due diligence to ensure they can meet GDPR requirements (ideally they have their own certifications or adequate security). We ensure a Data Processing Agreement (or equivalent clauses) is in place with them, requiring at least the same level of data protection obligations we have from our clients (GDPR Art. 28(4) flow-down). We also inform/seek approval from controllers as required (some DPAs list approved sub-processors or require notification).</li></ul>
                        ${hasPC8206 ? '<ul class="bulleted-list"><li style="list-style-type:circle"><strong>Inventory Update:</strong> The DPO updates the record (from Process 5.1) to list the sub-processor and what they do. E.g., “Sub-processor: CloudHostingCo – provides Infrastructure-as-a-Service, stores encrypted data in EU.” If a controller objects to a sub-processor, we either don’t use them for that controller or negotiate.</li></ul>' : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Ongoing Monitoring:</strong> We monitor the sub-processor’s performance and compliance. For example, if they have a data breach, they must notify us (we flow that obligation down). We then would notify controllers. The DPO keeps track of sub-processor notifications or updates (like if the sub-processor changes their location of data centers, we might need to inform controllers if it involves new transfers).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Sub-processor Changes:</strong> If we add or replace a sub-processor, we follow the notice/consent procedure in our client contracts (often providing 30 days notice for objections). The record and documentation are updated accordingly.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Termination of sub-processor:</strong> If we stop using one, ensure they delete data per contract, and reflect that in records.</li></ul>
                        <p>Essentially, this activity ensures that any entity other than us handling the data is also under governance and that controllers are aware, maintaining transparency and compliance</p>
                    </li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Approved Sub-processor List & Compliance Docs.</strong> We maintain an up-to-date <strong>list of sub-processors</strong> for each client (often we have a global list and note which ones apply to which service). This list can be shared with clients (some publish it on websites or provide on request). We also have on file all sub-processor agreements. ${hasPC8206 ? 'In our internal inventory, the presence of a sub-processor is clearly noted.' : ''} The outcome is that we can prove to any controller or auditor that:
                        <ul class="bulleted-list"><li style="list-style-type:circle">We only use disclosed and approved sub-processors.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Those sub-processors are contractually bound to protect the data (we could show sample contract clauses if needed).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">We oversee them (through audits or certifications, etc., which might be part of contractual rights).</li></ul>
                        <p>Managing sub-processors well prevents hidden risks and is crucial for compliance (since if a sub-processor fails, the controller and we are still on the hook). This output is a component of demonstrating that our whole processing chain is secure and compliant, not just our own company.</p>
                    </li></ul>`);
            }
            processSectionRendered = true; // Mark that Process 5.2 was added
            processesHtml += renderSubSectionHeading(isController ? 'Governance Oversight & Accountability Assurance' : 'Security Measures and Access Control for Client Data') + process52Content;
        }

        // --- Process 5.3 (Processor: Incident Management) ---
        let process53Content = '';
        if (isProcessor) { // This section only applies to processors based on HTML
            process53Content += `<p>This process delineates how we handle any personal data breaches or incidents, from detection to notification to controllers, fulfilling GDPR Article 33(2) and ensuring timely response to mitigate harm.</p>`;
            process53Content += renderStep('Step 1: Detect and Contain Security Incidents', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Potential indicators of a security incident or personal data breach. Inputs can come from various monitoring systems (intrusion detection alerts, system error logs, anti-malware alerts), reports from employees (e.g., someone accidentally emailed personal data to the wrong recipient and reports it), or communication from a sub-processor about a breach on their side.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Our Incident Response Team (which includes IT Security, and invokes the DPO as needed) follows the established <strong>Incident Response Plan</strong>. Upon an alert or report, they first <strong>identify</strong> if it indeed involves personal data and if it qualifies as a “personal data breach” (GDPR defines this as a security incident leading to accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to personal data). Examples: ransomware attack encrypting client data (availability breach), hacker exfiltrating data (confidentiality breach), accidental deletion of some records (integrity breach). Once identified, they <strong>contain</strong> the incident – isolating affected systems, revoking compromised credentials, stopping the bleeding. They also start a log of the incident timeline. The team assesses initial scope: which client’s data is impacted? (Important because we might have multiple controllers’ data – we need to pinpoint affected accounts). They preserve evidence for forensic analysis as well. If the incident is due to a sub-processor, we engage with them immediately to get details and ensure they too are containing it. Throughout this step, the DPO is in the loop. If it’s a minor incident (no real risk, e.g., we caught an intrusion attempt early with no data access), we still document internally but might not need to notify if no breach occurred. If a breach is confirmed, move to next step quickly.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Incident Analysis and Containment Report (Preliminary).</strong> Within hours of detection, we generate an initial incident report that includes what happened, which personal data sets might be affected (e.g., “Database X containing customer data of Client A and Client B was accessed by an unauthorized entity”), and actions taken so far to stop it. This internal report is critical for informing the notification. At this stage, we might not know everything, but we gather as much as possible. The output is essentially a foundation for deciding notification urgency. Under GDPR, “without undue delay” is expected – this often means we don’t wait for full forensic completion if the breach is clear; we proceed to notify with what we know and update later. So this output is what the DPO will use to craft the notifications in Step 2.</li></ul>`);
            process53Content += renderStep('Step 2: Notify the Controller(s) Without Undue Delay', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The preliminary incident details from Step 1, and contact information for the affected controller(s) (usually contained in our records or DPA contacts). Also input: Any notification templates or requirements agreed with the controller (some DPAs have specific breach notification formats or points of contact).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> <strong>As soon as possible</strong>, the DPO (or a designated incident manager) drafts a breach notification to the controller. GDPR Article 33(2) requires the processor to notify the controller <strong>“without undue delay”</strong> after becoming aware of a personal data breach. In practice, we aim to notify within 24 hours or sooner. The notification typically includes:
                    <ul class="bulleted-list"><li style="list-style-type:circle">A summary of the incident (what happened and how we discovered it).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">The <strong>estimated scope of impact</strong> – e.g., “Personal data of approximately 1,000 of your customers may have been accessed. Types of data involved include names and emails, and order history (no credit card info, as that&#x27;s not stored by us).”</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">The <strong>likely consequences</strong> of the breach – e.g., risk of phishing since emails leaked, etc., if we can assess that.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">The measures we have taken or plan to take to address the breach and mitigate its effects – e.g., “We have contained the issue by shutting down the affected server and applying patches. We are investigating and will update you with more details, and we are enhancing XYZ control.”</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">Our contact point for the controller to ask further questions (likely the DPO).</li></ul>
                    <p>If some information is not yet available (like exact numbers), we state that the investigation is ongoing and we will follow up. We use a secure and agreed channel to inform the controller (often email to their DPO or security contact, followed by a phone call for confirmation). If multiple controllers are affected by the same breach, we draft individual notices (to avoid disclosing one client’s info to another). Our tone is transparent and cooperative. We also remind the controller that we are there to assist them in their obligations (they may need to notify regulators per Art. 33(1) and possibly data subjects per Art. 34, but that’s their call—though we should provide info to help them decide). If the breach is at a sub-processor, we as the direct processor still take on the responsibility to notify controllers (while the sub-processor notifies us, we forward to controllers in essence).</p>
                </li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Breach Notification Sent to Controller.</strong> This is a formal message (which we keep a copy of for our records, timestamped). By sending it, we have fulfilled our duty under GDPR 33(2). We document the time of detection and time of notification to demonstrate “undue delay” was minimized (preferably same day). The controller now has the information to proceed with their steps (like notifying the supervisory authority within 72 hours of <em>their</em> awareness – which now counts from when we told them, effectively). We stand by to provide more as needed. This output is critical; failure here could mean regulatory penalties for us and damage trust with the client. So we treat this as high priority communications.</li></ul>`);
            process53Content += renderStep('Step 3: Investigate Thoroughly and Follow-Up', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Ongoing investigation findings, forensic reports, and any updates on containment or recovery. Also, any feedback or questions from the controller or their own regulators after the initial notification.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> After the immediate notification, the incident response team continues the deep investigation to determine exactly what happened, how to fix it, and how to prevent it in future. They may find, for example, that 800, not 1000, records were affected, or that certain data was actually encrypted and not compromised. They also assess if the breach is fully closed and monitor for any further suspicious activity. All this information is communicated in updates to the controller. The DPO might send a more detailed report once more facts are known, say 2 days later: including specifics like “the vulnerability exploited was X, which we have now patched across all systems” and confirming whether personal data was actually accessed or just at risk. We also inform the controller of any <strong>additional measures</strong> taken (e.g., forcing password resets if credentials were stolen, etc.). If the controller’s supervisory authority or law enforcement gets involved and asks us questions (usually via the controller, but possibly directly), we cooperate by providing logs, evidence, etc., as needed (this aligns with GDPR Art. 31 as well). Internally, we also document lessons learned. Additionally, if multiple controllers were affected, we ensure each gets only information relevant to their data (no leaking one client’s info to another).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Final Incident Report and Resolution Communication.</strong> We produce a comprehensive incident report that includes root cause, data impacted, timeline of actions, and improvements made. This is shared with the controller to the extent it concerns their data. We may also incorporate their input (if they observed effects or took certain actions on their side). The report serves as closure of the incident from a compliance standpoint. We update our internal records: for example, if as a result of the breach we’ve decided to implement a new control, we may update the security measures section of our inventory for that processing. If the breach revealed an instruction issue, maybe update procedures. We keep the incident report in our records in case of future audits or legal inquiries. Successfully handling the incident and providing a final report helps re-establish confidence with the controller and shows our accountability. Controllers will appreciate our transparency and thoroughness, which can mitigate damage to our business relationship.</li></ul>`);
            process53Content += renderStep('Step 4: Post-Incident Review and Preventive Actions', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The concluded incident details and the lessons learned identified by the incident response team and feedback from the controller. Also input: any requirement changes or action items (e.g., “enable two-factor auth” if it wasn’t before).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Once the dust settles, the DPO, Security lead, and relevant team members hold a <strong>post-mortem meeting</strong>. They evaluate how effective the response was and what could be improved. They ensure all root causes are addressed. For example, if it was a human error that caused the breach (like wrong data sent), maybe additional training or process check is instituted. If it was a technical flaw, in addition to fixing that instance, consider if other systems have similar issues. They also review if notification went smoothly, and if communication with the controller could be improved (e.g., was our contact list up to date? Did we notify as fast as possible?). Based on this, we <strong>update policies/SOP</strong>: maybe integrate a quicker alerting mechanism or adjust this SOP’s steps. The DPO also considers if this incident triggers any obligation to inform other parties (if, say, multiple controllers using the same system might benefit from knowing even if their data wasn’t hit – typically not required, but as goodwill possibly). We also verify that the controller has everything they need for their compliance (they might ask us to assist in communication to data subjects if needed, which we would do under their guidance).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Improved Controls and SOP Adjustments.</strong> The final output is twofold:
                    <ol type="1" class="numbered-list" start="1"><li>Implementation of additional safeguards or process changes (e.g., adding an intrusion detection system, updating access control, retraining staff on a specific risk) – basically reducing future breach likelihood or impact.</li></ol>
                    <ol type="1" class="numbered-list" start="2"><li>An updated incident response plan or this Governance SOP if needed, reflecting what we learned (for example, clarifying roles or communication flows).</li></ol>
                    <p>Additionally, we have a documented post-incident review report signed off by management, showing we took the breach seriously and have taken steps to prevent recurrence. This output closes the loop and feeds back into our governance framework, strengthening it.</p>
                </li></ul>
                <p>By executing Process 5.3 properly, we ensure that if the worst happens and a data breach occurs, we handle it in a compliant and professional manner: controllers are informed swiftly (meeting our legal duty and helping them meet theirs), and we minimize damage to individuals and to our relationship with clients. This is a critical aspect of data governance for processors, as it proves our reliability and accountability even under adverse events.</p>`);
            processSectionRendered = true;
            processesHtml += renderSubSectionHeading('Incident Management and Breach Notification to Controllers') + process53Content;
        }

        // --- Process 5.4 (Processor: Cooperation) ---
        let process54Content = '';
        if (isProcessor) { // This section only applies to processors based on HTML
            process54Content += `<p>This final process covers other aspects of cooperation, such as assisting controllers with data subject requests or regulatory inquiries, to ensure we support our controllers in their compliance and fulfill any direct obligations to regulators.</p>`;
            process54Content += renderStep('Step 1: Assist with Data Subject Requests (DSARs)', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> A request from a controller for assistance with a data subject access request, deletion request, or other rights exercise. Typically, the controller will forward us relevant details: e.g., “User John Doe (ID 12345) has requested all his data, please provide all personal data we store in your systems about him” or “Please delete data of customer Jane Doe in your backups as she invoked her right to erasure.” These requests are mandated by GDPR Art. 28(3)(e), which requires processors to help controllers fulfill data subject rights.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> Upon receiving such a request, the DPO/Privacy contact logs it and verifies the identity (through the controller’s confirmation). We then coordinate internally to retrieve or delete the data as requested. If it’s an access request:
                    <ul class="bulleted-list"><li style="list-style-type:circle">The DPO instructs the IT team to extract all personal data for that individual in our possession for that client. This might involve querying databases, gathering logs, etc., scoped to the data we process for the controller. We ensure we don’t include data from other controllers or internal data – focusing only on that controller’s dataset.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We format the data in a structured way if needed (some DPAs might specify format).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We then deliver the data securely back to the controller (not directly to the data subject, since it’s the controller’s responsibility to respond to the individual).</li></ul>
                    If it’s an erasure or correction request:<br/>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We find all instances of that individual’s data in our systems and delete or correct them as instructed, while maintaining a record of what we did.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We particularly ensure backups or any archival copies are also covered if the controller requests that (unless exception applies and we communicate those limits).</li></ul>
                    <p>We do this promptly, typically as soon as possible, knowing the controller has a one-month deadline (extendable to 3 months in complex cases under GDPR Art. 12). Our aim is to not be the bottleneck. If the request is too broad or unclear, we ask the controller for clarification rather than act incorrectly. If we think complying is impossible or violates law (rare, but e.g., data subject asks for deletion but the controller has lawful basis to retain), we inform the controller; ultimately, we follow their decision on how to handle it. We then confirm back to the controller when the action is completed and provide evidence if needed (like a confirmation log of deletion).</p>
                </li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Completion of DSAR Assistance.</strong> This results in either data delivered to controller or data deleted/corrected in our systems, as appropriate. We maintain an internal record of the request and our actions (to show compliance with Art. 28(3)(e)). The controller is now able to fulfill the data subject’s request knowing our portion is handled. This proactive assistance strengthens our compliance partnership with the controller and is often reviewed in audits (some controllers ask to see how many requests we handled and in what timeframe). Achieving timely and accurate assistance avoids compliance issues for the controller and thus for us.</li></ul>`);
            process54Content += renderStep('Step 2: Respond to Controller Inquiries & Audits', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Any inquiry from a controller regarding our processing, often as part of their compliance efforts. This may include periodic security questionnaires, requests for evidence of our controls (like pen test results, ISO certificates), or an audit visit as permitted by the DPA. Also input: our records (inventory, security docs) prepared earlier to answer such queries.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> We treat controller inquiries with high priority as part of cooperation. If a controller sends a security questionnaire annually, the DPO coordinates with Security and IT to fill it out accurately, leveraging our documented measures from Process 5.2. If a controller exercises their audit rights:
                    <ul class="bulleted-list"><li style="list-style-type:circle">The DPO and Account Manager plan the audit with them, clarifying scope (to protect other clients’ data, etc.). We might host them on-site or virtually, showing them policies, anonymized logs, maybe allowing inspection of relevant systems or processes.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We ensure all requested documentation is provided (e.g., our ROPA entry for their processing, records of staff training, sub-processor agreements, etc.), except where NDA or security constraints apply (then we find a compromise, like showing a document on screen).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">We allow them to interview key personnel if needed. The DPO is present to answer compliance questions, IT can answer technical ones.</li></ul>
                    <p>If the controller or their auditor finds issues or has recommendations, we take those seriously and work on improvements, then report back when resolved. Many controllers use audit findings as part of risk assessments – cooperating and showing a willingness to improve cements trust.</p>
                </li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Successful Audit/Inquiry Response.</strong> The tangible outputs could be a filled questionnaire, an audit report from the controller (often they share a summary or at least we have our notes of what happened), and any follow-up action plan. Ideally, the outcome is that the controller is satisfied we meet our obligations. Internally, we record that an audit was completed on such date with no major issues, or note any minor issues and how we addressed them. This output also doubles as preparation for any official regulatory audits that might involve us (though rare for processors to be audited by DPAs directly without going through the controller). It demonstrates our open cooperation stance.</li></ul>`);
            process54Content += renderStep('Step 3: Cooperation with Supervisory Authorities', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> A request or inquiry from a Data Protection Authority (DPA) that involves our processing activities. This will often come indirectly via a controller (the DPA contacts the controller, who then asks us), but it could also come directly if, say, the DPA knows we are a significant processor and wants to check something (GDPR Art. 31 applies to us directly too). The input might be a formal letter asking for logs, or info about a breach, or to inspect our compliance.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> We handle regulatory inquiries with utmost care. If the request comes through a controller, we give the controller priority and provide them with whatever they need to respond to the authority (similar to audit above). If the authority contacts us directly, we immediately inform the relevant controller (unless legally prohibited) to ensure transparency. We then cooperate by providing the requested information within the deadline given. The DPO is the point of contact for any such communications. For example, if the DPA asks “Provide all records of processing you maintain under Article 30(2) for controllers X, Y, Z” (maybe during an investigation of those controllers), we gather those records (ensuring we only show data related to that controller, protecting others’ data) and submit them as instructed. Or if the DPA asks about a breach mitigation, we explain the measures taken. All communication is cleared through Legal to ensure accuracy and that we’re not overstepping (like revealing something confidential without need). Throughout, we maintain a respectful and thorough tone – regulators appreciate complete and organized responses.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Regulatory Response Documentation.</strong> We produce a package of information to the DPA and keep a duplicate in our records. This could include our processing records (which, if well-maintained, is straightforward from our inventory), security policies, incident reports, etc. The goal is that the authority’s questions are answered fully. By doing so, we fulfill our direct duty under GDPR Art. 31 and also support our controllers in any regulatory scrutiny. We log the date and content of what was provided. If the DPA had recommendations or orders concerning us, we act on them and report back if required. Successfully handling such interactions without issue is a strong indicator of our effective governance.</li></ul>`);
            process54Content += renderStep('Step 4: Documentation and Improvement of Cooperation Processes', `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Experiences from handling DSARs, audits, and authority requests over time. Feedback from controllers on our cooperation (perhaps via satisfaction surveys or contract renewals discussions they mention our responsiveness).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO periodically evaluates how well we are cooperating and if our internal processes need adjustment. For example, if we had a scramble to gather info for an audit, perhaps we set up an <strong>audit preparation kit</strong> in advance (like common documents ready to go, updated quarterly). If a regulator’s question was hard to answer because our records lacked detail, we improve the record-keeping going forward. We might standardize the format of breach reports to controllers to make it easier for them to forward to authorities, etc. We incorporate any repeated questions into training (so staff know the answers beforehand). Essentially, we treat cooperation tasks as another facet of service quality – aiming to be efficient and reliable.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Refined Procedures and Higher Readiness.</strong> Over time, we build a reputation with clients and authorities as a processor that is organized and proactive. The output might be updated SOP documents, additional tools (maybe a portal where controllers can view certain compliance info live, some companies do that), or simply improved metrics (like faster turnaround on DSARs, fewer follow-ups needed from controllers). All these strengthen compliance. Documenting this improvement (even if just in internal meeting notes or KPI reports) shows a cycle of continuous improvement which is a principle in ISO management systems.</li></ul>
                <p>By completing Process 5.4, we ensure that our role as a processor is executed in harmony with our controllers’ needs and regulatory expectations. We are not a black box but a cooperative partner in data protection. This maintains the trust necessary for clients to confidently outsource processing to us, knowing that we will help them maintain compliance, and it closes any gaps that could expose either party to regulatory sanctions.</p>`);
            processSectionRendered = true;
            processesHtml += renderSubSectionHeading('Cooperation with Controllers and Authorities') + process54Content;
        }

        // --- Add Section 5 Heading and Content if applicable ---
        if (processSectionRendered) {
            htmlContent += renderSectionHeading('Detailed Processes');
            htmlContent += `<p>The following processes detail how to carry out Governance & Data Inventory activities step by step. Each process includes multiple steps, and <strong>each step is described with its Input, Activity, and Output</strong> to clarify how the procedure is executed and to maintain traceability to compliance requirements:</p>`;
            htmlContent += processesHtml; // Add the pre-built subsection content
        }


        return htmlContent; // Return the final combined HTML
    },

    shouldShow: (controlsMatrix) => {
        const relevantControls = [
            'PC7201', 'PC7202', 'PC7207', 'PC7208', // Controller
            'PC8202', 'PC8206' // Processor
        ];
        // Check if controlsMatrix is valid before proceeding
        if (!controlsMatrix || typeof controlsMatrix !== 'object') {
            return false;
        }
        return Object.values(controlsMatrix).some(activityControls =>
            activityControls && typeof activityControls === 'object' && relevantControls.some(control => activityControls[control] === true)
        );
    },


    getIntroText: () => {
        return 'This Standard Operating Procedure (SOP) defines the structured process for data governance and maintaining a comprehensive inventory of personal data processing activities, for both Data Controller and Data Processor roles.';
    }
};

export default SOPGovernanceDataInventory;