import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  GlobeAsiaAustraliaIcon, 
  LightBulbIcon, 
  BanknotesIcon, 
  UserGroupIcon 
} from '@heroicons/react/24/outline';

const Progress = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [expandedStep, setExpandedStep] = useState(null);

  // Define all sections and their pages
  const sections = [
    {
      id: "environment",
      name: "KnowYourselfProgress.environment",
      icon: GlobeAsiaAustraliaIcon,
      pages: [
        { id: "industry", name: 'KnowYourselfProgress.industry', route: '/funding/industry' },
        { id: "market", name: 'KnowYourselfProgress.regions', route: '/funding/regions' },
        { id: "market", name: 'KnowYourselfProgress.market', route: '/funding/market' },
        { id: "competition", name: 'KnowYourselfProgress.competition', route: '/funding/competition' }
      ]
    },
    {
      id: "organization",
      name: "KnowYourselfProgress.organization",
      icon: LightBulbIcon,
      pages: [
        { id: "business-model", name: 'KnowYourselfProgress.businessModel', route: '/funding/business-model' },
        { id: "product", name: 'KnowYourselfProgress.productsServices', route: '/funding/product' },
        { id: "team", name: 'KnowYourselfProgress.team', route: '/funding/team' },
        { id: "story", name: 'KnowYourselfProgress.story', route: '/funding/story' }
      ]
    },
    {
      id: "financing",
      name: "KnowYourselfProgress.financing",
      icon: BanknotesIcon,
      pages: [
        { id: "investment", name: 'KnowYourselfProgress.funding', route: '/funding/investment' },
        { id: "fundingNeeds", name: 'KnowYourselfProgress.fundingNeeds', route: '/funding/fundingNeeds' },
        { id: "fundingRounds", name: 'KnowYourselfProgress.fundingRounds', route: '/funding/fundingRounds' },
        { id: "termSheet", name: 'KnowYourselfProgress.termSheet', route: '/funding/termSheet' }
      ]
    },
    {
      id: "shareholder",
      name: "KnowYourselfProgress.shareholder",
      icon: UserGroupIcon,
      pages: [
        { id: "captable", name: 'KnowYourselfProgress.captable', route: '/funding/captable' },
        { id: "vesop", name: 'KnowYourselfProgress.esop', route: '/funding/vesop' },
        { id: "exit strategy", name: 'KnowYourselfProgress.exitStrategy', route: '/funding/exitStrategy' },
      ]
    }
  ];

  // Find current section based on the current route
  const currentSection = useMemo(() => {
    const currentPath = location.pathname;
    return sections.find(section => 
      section.pages.some(page => page.route === currentPath)
    );
  }, [location.pathname]);

  // If we're on the summary page or no section is found, don't show the progress
  if (location.pathname === '/funding/summary' || !currentSection) {
    return null;
  }

  const handleStepClick = (step) => {
    if (step.route === location.pathname) return;

    if (expandedStep === step.name) {
      // Do nothing, as Link component will handle navigation
      setExpandedStep(null);
    } else {
      // If step is not expanded, expand it
      setExpandedStep(step.name);
    }
  };

  return (
    <nav aria-label="Progress" className="mb-8">
      {/* Desktop version */}
      <div className="hidden lg:block">
        <div className="mb-0 flex">
          <currentSection.icon className="h-7 w-7 text-gray-300 mr-2" aria-hidden="true" />
          <h2 className="text-lg font-medium text-gray-300">{t(currentSection.name)}</h2>
        </div>
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {currentSection.pages.map((step, index) => {
            // Only show pages that have fields defined (not marked as TODO)
            if (!step.route) return null;

            return (
              <li key={step.name} className="md:flex-1">
                {location.pathname === step.route ? (
                  <Link
                    to={step.route}
                    className="flex flex-col border-l-4 border-primary-color py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-6 md:pl-0 md:pt-4"
                    aria-current="step"
                  >
                    <span className="text-xs font-medium text-primary-color">
                      {t('Step')} {index + 1}
                    </span>
                    <span className="text-xs font-bold text-black">
                      {t(step.name)}
                    </span>
                  </Link>
                ) : (
                  <Link
                    to={step.route}
                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-6 md:pl-0 md:pt-4"
                  >
                    <span className="text-xs font-medium text-gray-500 group-hover:text-primary-color">
                      {t('Step')} {index + 1}
                    </span>
                    <span className="text-xs font-bold text-gray-500 group-hover:text-gray-700">
                      {t(step.name)}
                    </span>
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </div>

      {/* Mobile version */}
      <ol role="list" className="lg:hidden flex items-center px-2">
        {currentSection.pages.map((step, index) => {
          const isActive = location.pathname === step.route;
          const isExpanded = expandedStep === step.name;

          return (
            <li key={step.name} className="flex-shrink-0">
              <motion.div
                className="flex items-center"
                initial={false}
                animate={{ width: isActive || isExpanded ? 'auto' : '20px' }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className={`flex items-center ${isActive ? 'cursor-default' : 'cursor-pointer'}`}
                  onClick={() => handleStepClick(step)}
                >
                  {/* Dot or active/expanded section */}
                  <div
                    className={`flex items-center rounded-full transition-colors ${isActive
                        ? 'h-5 bg-primary-color mr-2' //active section
                        : isExpanded
                          ? 'h-5 bg-gray-400 hover:bg-gray-300 mr-2' //other section
                          : 'w-3 h-3 bg-gray-400 hover:bg-gray-300' //dot
                      }`}
                  >
                    <AnimatePresence>
                      {(isActive || isExpanded) && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="flex items-center space-x-2 px-3"
                        >
                          <Link
                            to={step.route}
                            className={`text-xs font-bold whitespace-nowrap ${isActive ? 'text-white' : 'text-gray-700'}`}
                          >
                            {t(step.name)}
                          </Link>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Progress;