// KnowYourselfWizardValuationDevelopment.js
import React, { useEffect, useState } from 'react';
import { useUserData } from '../hooks/useUserData';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const KnowYourselfWizardValuationDevelopment = () => {
    const { userData } = useUserData();
    const [chartSeries, setChartSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [monthsLabels, setMonthsLabels] = useState([]);
    const [annotationsPoints, setAnnotationsPoints] = useState([]);
    const [roundEvents, setRoundEvents] = useState([]);
    const [selectedShareholder, setSelectedShareholder] = useState('');
    const [totalShares, setTotalShares] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const { t } = useTranslation();

    useEffect(() => {
        if (!userData?.entity) return;
      
        // 1. Get currency.
        const draftFundingCcy = userData.entity.know_yourself_response?.draft?.fundingCurrency;
        const pubFundingCcy = userData.entity.know_yourself_response?.published?.fundingCurrency;
        const usedCurrency = draftFundingCcy || pubFundingCcy || 'EUR';
        setCurrency(usedCurrency);
      
        // 2. Get captable data.
        const draftCaptable = userData.entity.know_yourself_response?.draft?.captable || {};
        const pubCaptable = userData.entity.know_yourself_response?.published?.captable || {};
        const chosenCaptable =
          Object.keys(draftCaptable).length > 0 ? draftCaptable : pubCaptable;
      
        // IMPORTANT: Calculate the share counts directly from the captable arrays.
        const currentShares = (chosenCaptable.currentShareholders || [])
          .reduce((sum, s) => sum + (s.shares || 0), 0);
        const targetShares = (chosenCaptable.targetedShareholders || [])
          .reduce((sum, s) => sum + (s.shares || 0), 0);
        const initialShares = currentShares; // pre-target share count
        const totalPostMoneyShares = currentShares + targetShares;
        setTotalShares(totalPostMoneyShares);
      
        // 3. Get exit strategy data.
        const exitStrategy =
          userData.entity.know_yourself_response?.published?.exitStrategy ||
          userData.entity.know_yourself_response?.draft?.exitStrategy ||
          {};
      
        // 4. Get funding rounds – note: these rounds are saved as an array.
        const allRounds =
          userData.entity.know_yourself_response?.draft?.fundingRounds?.fundingRounds ||
          userData.entity.know_yourself_response?.published?.fundingRounds?.fundingRounds ||
          [];
      
        // 5. Append an exit round if exit strategy is defined.
        if (exitStrategy.targetExitYear && exitStrategy.targetExitValue) {
          allRounds.push({
            time: { month: 12, year: exitStrategy.targetExitYear },
            type: 'Exit',
            preMoneyValuation: 0, // not used here
            investmentAmount: 0,
            postMoneyValuation: exitStrategy.targetExitValue,
            isExitStrategy: true
          });
        }
      
        // 6. Sort rounds chronologically.
        const sortedRounds = [...allRounds].sort((a, b) => {
          if (a.time.year !== b.time.year) return a.time.year - b.time.year;
          return a.time.month - b.time.month;
        });
      
        // 7. Find the target round (which marks the start of "future" rounds).
        const targetRound = sortedRounds.find(r => r.isTargetRound);
        if (!targetRound) {
          console.warn('No target round found. Cannot build chart.');
          return;
        }
        // For context (not used in later calculations):
        const preMoneyValuation = targetRound.preMoneyValuation || 0;
        const investmentAmount = targetRound.investmentAmount || 0;
        const postMoneyValuation = preMoneyValuation + investmentAmount;
      
        // 8. Set up the running share count starting from the actual pre-target shares.
        let runningShareCount = initialShares;
      
        // 9. Build the timeline using future rounds (starting with the target round).
        const futureRounds = sortedRounds.slice(sortedRounds.indexOf(targetRound));
        if (futureRounds.length === 0) return;
        const minYear = futureRounds[0].time.year;
        const minMonth = futureRounds[0].time.month;
        const maxYear = futureRounds[futureRounds.length - 1].time.year;
        const maxMonth = futureRounds[futureRounds.length - 1].time.month;
        const monthArray = [];
        const dateCursor = new Date(minYear, minMonth - 1);
        const endDate = new Date(maxYear, maxMonth + 2); // extend 3 months after last round
        while (dateCursor <= endDate) {
          monthArray.push({
            year: dateCursor.getFullYear(),
            month: dateCursor.getMonth() + 1
          });
          dateCursor.setMonth(dateCursor.getMonth() + 1);
        }
        const labels = monthArray.map(m =>
          `${m.month < 10 ? `0${m.month}` : m.month}/${String(m.year).slice(-2)}`
        );
        setMonthsLabels(labels);
      
        // 10. Loop over futureRounds to update runningShareCount via dilution math.
        // Here we update runningShareCount once and record its value per round.
        const recordedShareCounts = [];
        for (let i = 0; i < futureRounds.length; i++) {
          const round = futureRounds[i];
          let postVal;
          let inv = 0;
          if (round.isExitStrategy) {
            postVal = round.postMoneyValuation;
          } else {
            const preVal = round.preMoneyValuation || 0;
            inv = round.investmentAmount || 0;
            postVal = preVal + inv;
            const newShares = Math.floor((inv * runningShareCount) / (round.preMoneyValuation || 1));
            runningShareCount += newShares;
          }
          recordedShareCounts.push(runningShareCount);
        }
        // Now runningShareCount is final, but recordedShareCounts[i] holds the share count at round i.
      
        // 11. Build shareholder list from current shareholders.
        const founders = chosenCaptable.currentShareholders || [];
        const shareholderList = [];
        const totalFounderShares = founders.reduce((sum, f) => sum + (f.shares || 0), 0) || 1;
        founders.forEach((f, idx) => {
          shareholderList.push({
            id: `founder-${idx}`,
            name: f.name || `Founder #${idx + 1}`,
            fraction: (f.shares || 0) / totalFounderShares,
            values: new Array(monthArray.length).fill(0),
            isFutureGroup: false
          });
        });
      
        // 12. Process rounds and build data series and annotations.
        // In this loop, we do NOT update runningShareCount again.
        let prevIndex = 0;
        let prevPostVal = 0;
        const roundEventsTemp = [];
        const annotationsTemp = [];
        let lastNonExitRoundFractions = null;
        for (let i = 0; i < futureRounds.length; i++) {
          const round = futureRounds[i];
          let postVal;
          let inv = 0;
          if (round.isExitStrategy) {
            postVal = round.postMoneyValuation;
          } else {
            const preVal = round.preMoneyValuation || 0;
            inv = round.investmentAmount || 0;
            postVal = preVal + inv;
          }
          const roundMIndex = monthArray.findIndex(
            m => m.year === round.time.year && m.month === round.time.month
          );
          if (roundMIndex === -1) continue;
          // Interpolate values between rounds.
          if (roundMIndex > prevIndex) {
            const targetVal = round.isExitStrategy
              ? round.postMoneyValuation
              : (round.preMoneyValuation || 0);
            for (let j = prevIndex; j < roundMIndex; j++) {
              const progress = (j - prevIndex) / (roundMIndex - prevIndex);
              const currentVal = Math.round(prevPostVal + progress * (targetVal - prevPostVal));
              shareholderList.forEach(sh => {
                sh.values[j] = Math.round(sh.fraction * currentVal);
              });
            }
          }
          // Adjust shareholder fractions based on round type.
          if (!round.isExitStrategy) {
            if (round.isTargetRound) {
              const newGroupFraction = postVal > 0 ? (inv / postVal) : 0;
              shareholderList.forEach(sh => {
                sh.fraction *= (1 - newGroupFraction);
              });
              const targetSH = chosenCaptable.targetedShareholders || [];
              const sumTgtShares = targetSH.reduce((s, x) => s + (x.shares || 0), 0) || 1;
              targetSH.forEach((ts, idx2) => {
                const portionOfNewGroup = (ts.shares || 0) / sumTgtShares;
                shareholderList.push({
                  id: `target-${idx2}`,
                  name: ts.name || `Target #${idx2 + 1}`,
                  fraction: newGroupFraction * portionOfNewGroup,
                  values: new Array(monthArray.length).fill(0),
                  isFutureGroup: false
                });
              });
            } else {
              const newGroupFraction = postVal > 0 ? (inv / postVal) : 0;
              shareholderList.forEach(sh => {
                sh.fraction *= (1 - newGroupFraction);
              });
              shareholderList.push({
                id: `grp-${round.time.year}-${round.time.month}`,
                name: `Investors: ${round.type}`,
                fraction: newGroupFraction,
                values: new Array(monthArray.length).fill(0),
                isFutureGroup: true
              });
            }
            lastNonExitRoundFractions = shareholderList.map(sh => ({
              name: sh.name,
              fraction: sh.fraction
            }));
          } else {
            if (lastNonExitRoundFractions) {
              shareholderList.forEach(sh => {
                const lastFraction = lastNonExitRoundFractions.find(f => f.name === sh.name);
                if (lastFraction) {
                  sh.fraction = lastFraction.fraction;
                }
              });
            }
          }
          // Set shareholder values at this round.
          shareholderList.forEach(sh => {
            sh.values[roundMIndex] = Math.round(sh.fraction * postVal);
          });
          roundEventsTemp.push({
            roundIndex: roundMIndex,
            roundType: round.type,
            postMoneyVal: round.isExitStrategy
              ? Number(exitStrategy.targetExitValue)
              : postVal,
            dateLabel: labels[roundMIndex],
            isExitStrategy: round.isExitStrategy,
            // Use the recorded share count for this round.
            sharesAtRound: recordedShareCounts[i]
          });
          annotationsTemp.push({
            x: labels[roundMIndex],
            y: postVal,
            marker: {
              size: round.isExitStrategy ? 8 : 6,
              fillColor: round.isExitStrategy ? '#ff4444' : '#fff',
              strokeColor: round.isExitStrategy ? '#cc0000' : '#333',
              strokeWidth: 2
            },
            label: {
              borderColor: round.isExitStrategy ? '#cc0000' : '#777',
              style: {
                color: '#fff',
                background: round.isExitStrategy ? '#cc0000' : '#777'
              },
              text: round.isExitStrategy
                ? `Exit:\n${formatCurrency(Number(exitStrategy.targetExitValue), usedCurrency)}`
                : `${round.type}:\n${formatCurrency(postVal, usedCurrency)}`
            }
          });
          prevIndex = roundMIndex;
          prevPostVal = postVal;
        }
        // Fill remaining months with last known values.
        for (let k = prevIndex; k < monthArray.length; k++) {
          shareholderList.forEach(sh => {
            sh.values[k] = Math.round(sh.fraction * prevPostVal);
          });
        }
      
        setRoundEvents(roundEventsTemp);
        setAnnotationsPoints(annotationsTemp);
      
        // Build final chart series.
        const finalSeries = shareholderList.map(sh => ({
          name: sh.name,
          data: sh.values
        }));
        setChartSeries(finalSeries);
      
        // Compute maximum value across series for y-axis scaling.
        const maxValue = Math.max(
          ...shareholderList.map(sh => Math.max(...sh.values)),
          ...roundEventsTemp.map(evt => evt.postMoneyVal)
        );
        const yAxisMax = Math.ceil(maxValue * 1.1);
        setChartOptions({
          chart: {
            type: 'area',
            stacked: true,
            animations: { enabled: true },
            toolbar: { show: true }
          },
          xaxis: {
            categories: labels,
            tickAmount: Math.max(Math.floor(labels.length / 5), 1),
            labels: { rotate: -45 }
          },
          dataLabels: { enabled: false },
          yaxis: {
            labels: {
              formatter: (val) => formatCurrency(val, usedCurrency)
            },
            title: { text: usedCurrency },
            max: yAxisMax,
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: (val) => formatCurrency(val, usedCurrency)
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.1,
              opacityFrom: 0.9,
              opacityTo: 0.5
            }
          },
          stroke: {
            curve: 'smooth',
            width: 2
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center'
          },
          annotations: {
            points: annotationsTemp
          }
        });
      
        if (finalSeries.length > 0 && !selectedShareholder) {
          setSelectedShareholder(finalSeries[0].name);
        }
      }, [userData]);      

    // Format currency helper
    function formatCurrency(value, ccy = 'EUR') {
        if (typeof value !== 'number') return '';
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: ccy,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    }

    // Table rows section
    const tableRows = [];
    if (selectedShareholder) {
        const series = chartSeries.find(s => s.name === selectedShareholder);
        if (series) {
            roundEvents.forEach(evt => {
                const valAtRound = series.data[evt.roundIndex] || 0;
                const fraction = evt.postMoneyVal > 0 ? (valAtRound / evt.postMoneyVal) : 0;
                
                // Simple share price calculation now that we track actual shares per round
                const sharePrice = evt.postMoneyVal / evt.sharesAtRound;

                const rowStyle = evt.isExitStrategy ? {
                    backgroundColor: 'rgba(255, 68, 68, 0.1)'
                } : {};

                tableRows.push({
                    dateLabel: evt.dateLabel,
                    roundType: evt.roundType,
                    postVal: evt.isExitStrategy ? evt.postMoneyVal : evt.postMoneyVal,
                    sharePrice,
                    fraction,
                    stakeValue: valAtRound,
                    style: rowStyle,
                    displayPostVal: evt.isExitStrategy ? true : true // Always show post-money value
                });
            });
        }
    }

    return (
        <div className="max-w-7xl mx-auto">
            {chartSeries.length === 0 ? (
                <div className="text-gray-500 py-8 text-center">
                    {t('No data available...')}
                </div>
            ) : (
                <>
                    {/* Stacked area chart */}
                    <div className="bg-white p-4 rounded shadow">
                        <div className="h-96">
                            <Chart
                                options={chartOptions}
                                series={chartSeries}
                                type="area"
                                height="100%"
                            />
                        </div>
                    </div>

                    {/* Dropdown to pick a shareholder */}
                    <div className="my-6 flex items-center gap-2">
                        <label className="text-sm font-medium text-gray-700">
                            {t('Select shareholder')}:
                        </label>
                        <select
                            value={selectedShareholder}
                            onChange={(e) => setSelectedShareholder(e.target.value)}
                            className="border rounded px-2 py-1 text-sm"
                        >
                            {chartSeries.map(s => (
                                <option key={s.name} value={s.name}>
                                    {s.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Table for the selected shareholder's stake at each round */}
                    {tableRows.length > 0 && (
                        <div className="overflow-x-auto bg-white p-4 rounded shadow">
                            <table className="min-w-full divide-y divide-gray-200 text-sm">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Date')}</th>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Round')}</th>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Post-Money')}</th>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Share Price')}</th>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Stake %')}</th>
                                        <th className="px-4 py-2 text-left font-medium text-gray-600">{t('Value')}</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {tableRows.map((row, i) => (
                                        <tr key={i} style={row.style}>
                                            <td className="px-4 py-2 whitespace-nowrap">{row.dateLabel}</td>
                                            <td className="px-4 py-2 whitespace-nowrap">{row.roundType}</td>
                                            <td className="px-4 py-2 whitespace-nowrap">
                                                {row.displayPostVal ? formatCurrency(row.postVal, currency) : ''}
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap">
                                                {formatCurrency(row.sharePrice, currency)}
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap">
                                                {(row.fraction * 100).toFixed(2)}%
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap">
                                                {formatCurrency(row.stakeValue, currency)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default KnowYourselfWizardValuationDevelopment;