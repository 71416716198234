import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import TextField from '../../../components/wizard_components/TextField';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { ArrowRightCircleIcon, AcademicCapIcon } from '@heroicons/react/24/outline';
import axios from '../../../axios';

const KnowYourselfWizardStory = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData, loading: userDataLoading } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/company');
    const fileInputRef = useRef(null);
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');
    const [logoPreview, setLogoPreview] = useState(null);
    // Animation is now continuous, no need for state management
    const pulseAnimation = `
        @keyframes customPulse {
            0%, 100% {
                transform: scale(1);
                color: rgb(75, 85, 99);
            }
            50% {
                transform: scale(1.03);
                color: var(--secondary-color);
            }
        }
    `;

    useEffect(() => {
        // Insert the keyframes animation into the document
        const style = document.createElement('style');
        style.textContent = pulseAnimation;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const [initialValues, setInitialValues] = useState({
        companyLogo: '',
        slogan: '',
        mission: '',
        vision: '',
        specialSauce: '',
        swot: {
            strengths: '',
            weaknesses: '',
            opportunities: '',
            threats: ''
        }
    });

    useEffect(() => {
        const relevantFields = ['slogan', 'mission', 'vision', 'specialSauce', 'swot'];

        const isDataEmpty = (data) => {
            if (!data) return true;
            return relevantFields.every(key => {
                const value = data[key];
                if (value === null || value === undefined || value === '') return true;
                if (key === 'swot') {
                    return Object.values(value).every(v => !v);
                }
                return false;
            });
        };

        const isEqual = (obj1, obj2) => {
            const filtered1 = relevantFields.reduce((acc, key) => ({ ...acc, [key]: obj1[key] }), {});
            const filtered2 = relevantFields.reduce((acc, key) => ({ ...acc, [key]: obj2[key] }), {});
            return JSON.stringify(filtered1) === JSON.stringify(filtered2);
        };

        if (userData?.entity) {
            const { published, draft } = userData.entity.know_yourself_response || {};
            const draftData = draft || {};
            const publishedData = published || {};

            if (userData.entity.logo_url) {
                setLogoPreview(userData.entity.logo_url);
            }

            if (isDataEmpty(draftData) || isEqual(draftData, initialValues)) {
                setDataStatus('new');
            } else if (!isEqual(draftData, publishedData)) {
                setDataStatus('draft');
            } else {
                setDataStatus('published');
            }

            setInitialValues({
                companyLogo: '',
                slogan: '',
                mission: '',
                vision: '',
                specialSauce: '',
                swot: {
                    strengths: '',
                    weaknesses: '',
                    opportunities: '',
                    threats: ''
                },
                ...(!isDataEmpty(draftData) ? draftData : publishedData)
            });
        }
    }, [userData]);

    const validationSchema = Yup.object({
        slogan: Yup.string()
            .max(240, t('KnowYourselfWizard.sloganTooLong')),
        mission: Yup.string()
            .required(t('KnowYourselfWizard.missionRequired'))
            .min(20, t('KnowYourselfWizard.missionTooShort')),
        vision: Yup.string()
            .required(t('KnowYourselfWizard.visionRequired'))
            .min(20, t('KnowYourselfWizard.visionTooShort')),
        specialSauce: Yup.string()
            .required(t('KnowYourselfWizard.specialSauceRequired'))
            .min(20, t('KnowYourselfWizard.specialSauceTooShort')),
        swot: Yup.object({
            strengths: Yup.string()
                .required(t('KnowYourselfWizard.strengthsRequired'))
                .min(20, t('KnowYourselfWizard.strengthsTooShort')),
            weaknesses: Yup.string()
                .required(t('KnowYourselfWizard.weaknessesRequired'))
                .min(20, t('KnowYourselfWizard.weaknessesTooShort')),
            opportunities: Yup.string()
                .required(t('KnowYourselfWizard.opportunitiesRequired'))
                .min(20, t('KnowYourselfWizard.opportunitiesTooShort')),
            threats: Yup.string()
                .required(t('KnowYourselfWizard.threatsRequired'))
                .min(20, t('KnowYourselfWizard.threatsTooShort'))
        })
    });

    const handleLogoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoPreview(URL.createObjectURL(file));

            const formData = new FormData();
            formData.append('user_data', JSON.stringify({ entity: {} }));
            formData.append('companyLogo', file);

            try {
                const response = await axios.put('/api/v1/live_data/user_data', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });

                if (response.data.logo_url) {
                    setLogoPreview(response.data.logo_url);
                    // Add this line to update the form values
                    setInitialValues(prev => ({
                        ...prev,
                        companyLogo: response.data.logo_url
                    }));
                }
            } catch (error) {
                console.error('Error uploading logo:', error);
                setNotification({
                    show: true,
                    type: 'error',
                    message: t('Failed to upload logo'),
                    errors: [error.message]
                });
            }
        }
    };

    if (userDataLoading) return <div>{t('Loading...')}</div>;

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await handleSubmit({ responses: values });
            setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
            setDataStatus('published');
            await handleDraftSubmit({ responses: values });
            setTimeout(() => navigate('/funding'), 1000);
        } catch (error) {
            setNotification({ show: true, type: 'error', message: t('Failed to save data'), errors: [error.message] });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: values });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully!') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({ show: true, type: 'error', message: t('Failed to save draft'), errors: [error.message] });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>

            <h2 className="text-primary-color">{t('Story')}</h2>
            <p className="mb-8">{t('KnowYourselfWizard.storyIntro')}</p>

            {/* Story Recommendations */}
            <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                    <div className="flex items-center">
                        <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                        <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                        className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">
                        <div className="space-y-4 text-gray-700">
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('storyRecommendations.slogan.title')}
                                </h4>
                                <p className="text-sm">
                                    {t('storyRecommendations.slogan.content')}
                                </p>
                            </div>
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('storyRecommendations.missionVision.title')}
                                </h4>
                                <p className="text-sm">
                                    {t('storyRecommendations.missionVision.content')}
                                </p>
                            </div>
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('storyRecommendations.specialSauce.title')}
                                </h4>
                                <p className="text-sm">
                                    {t('storyRecommendations.specialSauce.content')}
                                </p>
                            </div>
                            <div>
                                <h4 className="text-sm font-semibold text-gray-900">
                                    {t('storyRecommendations.swot.title')}
                                </h4>
                                <p className="text-sm">
                                    {t('storyRecommendations.swot.content')}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, isSubmitting, validateForm }) => (
                    <Form onSubmit={async (e) => {
                        e.preventDefault();
                        const errors = await validateForm(values);
                        if (Object.keys(errors).length === 0) {
                            onSubmit(values, { setSubmitting: () => { } });
                        } else {
                            const errorMessages = [];

                            // Handle regular field errors
                            ['slogan', 'mission', 'vision', 'specialSauce'].forEach(field => {
                                if (errors[field]) {
                                    const fieldTranslations = {
                                        slogan: t('KnowYourselfWizard.slogan'),
                                        mission: t('KnowYourselfWizard.mission'),
                                        vision: t('KnowYourselfWizard.vision'),
                                        specialSauce: t('KnowYourselfWizard.specialSauce')
                                    };
                                    errorMessages.push(`${fieldTranslations[field]}: ${errors[field]}`);
                                }
                            });

                            // Handle SWOT analysis errors
                            if (errors.swot) {
                                Object.entries(errors.swot).forEach(([field, message]) => {
                                    const fieldTranslations = {
                                        strengths: t('KnowYourselfWizard.strengths'),
                                        weaknesses: t('KnowYourselfWizard.weaknesses'),
                                        opportunities: t('KnowYourselfWizard.opportunities'),
                                        threats: t('KnowYourselfWizard.threats')
                                    };
                                    errorMessages.push(`${t('KnowYourselfWizard.swot')} - ${fieldTranslations[field]}: ${message}`);
                                });
                            }

                            setNotification({
                                show: true,
                                type: 'error',
                                message: t('Failed to save data due to validation errors:'),
                                errors: errorMessages
                            });
                        }
                    }}>
                        <div className="space-y-8">
                            {/* Company Logo Section */}
                            <div className="bg-white p-6 rounded-lg shadow">
                                <h3 className="text-xl font-semibold text-gray-900 mb-4">{t('Company Logo')}</h3>
                                <div className="mt-2 flex items-center space-x-4">
                                    {logoPreview ? (
                                        <img src={logoPreview} alt="Company Logo" className="h-32 w-auto max-w-full object-contain border" />
                                    ) : (
                                        <span className="h-32 w-32 flex items-center text-center justify-center text-gray-400 bg-gray-200 border">
                                            {t('No Logo Uploaded')}
                                        </span>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        ref={fileInputRef}
                                        onChange={handleLogoChange}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => fileInputRef.current.click()}
                                        className="bg-secondary-color text-white px-4 py-2 rounded hover:bg-secondary-color/80"
                                    >
                                        {t('Upload')}
                                    </button>
                                </div>
                            </div>

                            {/* Company Identity Section */}
                            <div className="bg-white p-6 rounded-lg shadow">
                                <h3 className="text-xl font-semibold text-gray-900 mb-6">{t('Company Identity')}</h3>

                                <div className="space-y-6">
                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900 mb-2">{t('Slogan')}</h4>
                                        <TextField name="slogan" label={t('Describe your company in less than 240 characters')} maxLength={240} />
                                        <p className="text-sm text-gray-500 mt-1">{(values.slogan || '').length} / 240</p>
                                    </div>

                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900 mb-2">{t('Mission')}</h4>
                                        <TextField name="mission" label={t('What is your company striving to achieve?')} type="textarea" />
                                    </div>

                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900 mb-2">{t('Vision')}</h4>
                                        <TextField name="vision" label={t('What do you imagine the future to be as it is positively impacted by your company?')} type="textarea" />
                                    </div>

                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900 mb-2">{t('Special Sauce')}</h4>
                                        <TextField name="specialSauce" label={t('What makes your company different from others?')} type="textarea" />
                                    </div>
                                </div>
                            </div>

                            {/* SWOT Analysis Section */}
                            <div className="bg-white p-6 rounded-lg shadow">
                                <div className="flex justify-between items-center mb-6">
                                    <h3 className="text-xl font-semibold text-gray-900">{t('SWOT Analysis')}</h3>
                                    <a
                                        href="/reports"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center gap-2 text-gray-600 hover:text-secondary-color transition-all duration-300 group animate-[customPulse_5s_ease-in-out_infinite]"
                                    >
                                        <span>{t('CanvasCTA')}</span>
                                        <ArrowRightCircleIcon className="w-5 h-5 transition-transform duration-300 group-hover:scale-110 animate-[customPulse_5s_ease-in-out_infinite]" />
                                    </a>
                                </div>

                                <p className="text-gray-600 mb-6">
                                    {t('SWOTDescription')}
                                </p>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="bg-green-50 p-4 rounded-lg">
                                        <h4 className="font-medium text-green-800 mb-2">{t('Strengths')}</h4>
                                        <TextField name="swot.strengths" type="textarea" rows={4} />
                                    </div>

                                    <div className="bg-red-50 p-4 rounded-lg">
                                        <h4 className="font-medium text-red-800 mb-2">{t('Weaknesses')}</h4>
                                        <TextField name="swot.weaknesses" type="textarea" rows={4} />
                                    </div>

                                    <div className="bg-blue-50 p-4 rounded-lg">
                                        <h4 className="font-medium text-blue-800 mb-2">{t('Opportunities')}</h4>
                                        <TextField name="swot.opportunities" type="textarea" rows={4} />
                                    </div>

                                    <div className="bg-yellow-50 p-4 rounded-lg">
                                        <h4 className="font-medium text-yellow-800 mb-2">{t('Threats')}</h4>
                                        <TextField name="swot.threats" type="textarea" rows={4} />
                                    </div>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex justify-end gap-x-4">
                                <button
                                    type="button"
                                    className="text-sm text-gray-900"
                                    onClick={() => navigate('/funding')}
                                >
                                    {t('Cancel')}
                                </button>
                                <button
                                    type="button"
                                    onClick={(e) => onDraftSubmit(values, { setSubmitting: () => { } })}
                                    className="rounded-md bg-gray-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                >
                                    {t('Save as Draft')}
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="rounded-md bg-primary-color px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2"
                                >
                                    {isSubmitting ? t('Saving...') : t('Save')}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default KnowYourselfWizardStory;