// src/components/wizard_components/DataPrivacyWizard/SOPConsentManagement.js
import React from 'react';

const hasControlApplied = (controlsMatrix, controlId) => {
    // Protokolliere die Struktur zum Debuggen
    console.log("Kontrollmatrix-Struktur:", JSON.stringify(controlsMatrix, null, 2));
    console.log("Suche nach Kontrolle:", controlId);

    // Überprüfe, ob die Kontrolle auf mindestens eine Aktivität angewendet wird
    return Object.values(controlsMatrix).some(activityControls => {
        console.log("Aktivitätskontrollen:", JSON.stringify(activityControls, null, 2));
        return activityControls[controlId] === true;
    });
};

// Hilfsfunktion, um zu prüfen, ob ein Ausführungskriterium überschrieben wurde
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    // Zunächst überprüfen, ob die Kontrolle überhaupt angewendet wird
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);

    if (!isControlApplied) {
        return true; // Als "überschrieben" betrachten, wenn die Kontrolle überhaupt nicht angewendet wird
    }

    // Überprüfe, ob für die Kontrolle Überschreibungen vorliegen
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        // Überprüfe, ob das spezifische Kriterium im Überschreibungsarray enthalten ist
        return riskControlOverrides[controlId].includes(criteriaId);
    }

    return false; // Nicht überschrieben, wenn keine Überschreibungen existieren
};

/**
 * Diese Komponente übernimmt die Erstellung der SOP für das Einwilligungsmanagement.
 * Sie enthält die Logik, um auf Grundlage der Kontrollen und Verarbeitungstätigkeiten der Organisation
 * einen entsprechenden Inhalt zu generieren.
 */
const SOPConsentManagement = {

    /**
     * Erstelle den Inhalt für die SOP
     * @param {Object} controlsMatrix - Die ausgewählten Risikokontrollen aus den TOMs
     * @param {Object} riskControlOverrides - Alle auf die Risikokontrollen angewendeten Überschreibungen
     * @param {Array} processingActivities - Die Verarbeitungstätigkeiten aus dem ROPA
     * @param {Object} entityInfo - Informationen zur Organisation
     * @returns {String} HTML-Inhalt für die Richtlinie
     */
    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo) => {
        const orgName = entityInfo.name || '[Ihr Organisationsname]';

        console.log('Kontrollmatrix:', controlsMatrix);
        console.log('Überschreibungen der Risikokontrollen:', riskControlOverrides);

        // Überprüfe, welche Kontrollen angewendet werden
        // PC7203 - Bestimmen, wann und wie die Einwilligung eingeholt wird
        const hasPC7203 = hasControlApplied(controlsMatrix, 'PC7203');
        const isPC72031aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7203', '1a');
        const isPC72031bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7203', '1b');

        // PC7204 - Einwilligung einholen und erfassen
        const hasPC7204 = hasControlApplied(controlsMatrix, 'PC7204');
        const isPC72041aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1a');
        const isPC72041bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1b');
        const isPC72041cOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1c');

        // PC7304 - Bereitstellung eines Mechanismus zur Änderung oder zum Widerruf der Einwilligung
        const hasPC7304 = hasControlApplied(controlsMatrix, 'PC7304');
        const isPC73041aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1a');
        const isPC73041bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1b');
        const isPC73041cOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1c');

        // PC7305 - Bereitstellung eines Mechanismus, um der Verarbeitung personenbezogener Daten zu widersprechen
        const hasPC7305 = hasControlApplied(controlsMatrix, 'PC7305');
        const isPC73051aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7305', '1a');
        const isPC73051bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7305', '1b');


        // 1. Rechtliche/Standard-Grundlage

        // Erstelle die ISO/IEC-Verweise basierend auf den angewendeten Kontrollen
        let isoControlsText = '';

        const appliedControls = [];
        if (hasPC7203) appliedControls.push("<strong>PC7203</strong> (Bestimmen, wann und wie die Einwilligung eingeholt wird)");
        if (hasPC7204) appliedControls.push("<strong>PC7204</strong> (Einwilligung einholen und erfassen)");
        if (hasPC7304) appliedControls.push("<strong>PC7304</strong> (Bereitstellung eines Mechanismus zur Änderung oder zum Widerruf der Einwilligung)");
        if (hasPC7305) appliedControls.push("<strong>PC7305</strong> (Bereitstellung eines Mechanismus, um der Verarbeitung personenbezogener Daten zu widersprechen)");

        if (appliedControls.length > 0) {
            isoControlsText = `<ul>
      <li><strong>ISO/IEC 27701:2021 (Kontrollen für PII-Verantwortliche)</strong> – Diese SOP erfüllt die Kontrollen ${appliedControls.join(', ')}. Diese Kontrollen verlangen von der Organisation, als Datenverantwortliche, die Einwilligung in Übereinstimmung mit den Datenschutzgrundsätzen zu verwalten.</li>`;
        }


        // 2. Zweck

        // Erstelle den Zweckabschnitt basierend auf den angewendeten Kontrollen
        let purposeContent = '<h2>2. Zweck</h2>\n<p>Sicherstellung eines konsistenten und regelkonformen Ansatzes für das <strong>Einwilligungsmanagement</strong> bei der Verarbeitung personenbezogener Daten. Diese SOP definiert, wie unsere Organisation die Einwilligungen der betroffenen Personen als rechtliche Grundlage für die Verarbeitung ';

        // Verben basierend auf den angewendeten Kontrollen hinzufügen
        let verbs = [];
        if (hasPC7203) verbs.push("einholt");
        if (hasPC7204) verbs.push("erfasst");
        if (hasPC7304 || hasPC7305) verbs.push("verwaltet");

        // Verben mit Kommas und "und" verbinden
        if (verbs.length > 0) {
            if (verbs.length === 1) {
                purposeContent += verbs[0];
            } else if (verbs.length === 2) {
                purposeContent += verbs.join(" und ");
            } else {
                const lastVerb = verbs.pop();
                purposeContent += verbs.join(", ") + ", und " + lastVerb;
            }

            purposeContent += "";
        } else {
            purposeContent += "handhabt";
        }

        purposeContent += `, in Übereinstimmung mit ISO 27701 und DSGVO. Ziel ist es, die Rechte der betroffenen Personen zu schützen und Nachweise für die Einhaltung zu führen (Nachweis, dass eine gültige Einwilligung eingeholt wurde oder dass eine alternative rechtliche Grundlage verwendet wird). Die Befolgung dieses Verfahrens trägt dazu bei, Transparenz und Vertrauen bei den Betroffenen zu wahren und das rechtliche Risiko zu reduzieren.</p>`;

        // 3. Kennzahlen (KPIs)

        let kpisContent = '<h2>3. Kennzahlen (KPIs)</h2>';

        // Einwilligungsdokumentations-KPI nur einbeziehen, wenn PC7204 angewendet wird
        if (hasPC7204) {
            kpisContent += `
<ul>
  <li><strong>Dokumentationsrate der Einwilligungen:</strong> 100% der Verarbeitungstätigkeiten, die auf Einwilligung beruhen, verfügen über entsprechende Einwilligungsaufzeichnungen (Audit-Trail mit Informationen darüber, wer wann und wozu eingewilligt hat).</li>
</ul>`;
        }

        // Widerrufsverarbeitungs-KPI nur einbeziehen, wenn PC7304 angewendet wird
        if (hasPC7304) {
            kpisContent += `
<ul>
  <li><strong>Verarbeitungszeit für Widerrufe:</strong> Durchschnittliche Zeit zur Bearbeitung eines Widerrufsantrags (Ziel: z.B. innerhalb von 5 Werktagen). Alle Widerrufs- oder Änderungsanfragen werden innerhalb des veröffentlichten SLA (z.B. maximal 30 Tage) bearbeitet.</li>
</ul>`;
        }

        // Widerspruchsbearbeitungs-KPI nur einbeziehen, wenn PC7305 angewendet wird
        if (hasPC7305) {
            kpisContent += `
<ul>
  <li><strong>Bearbeitungsrate für Widersprüche:</strong> 100% der Widersprüche von betroffenen Personen werden protokolliert und bewertet, wobei innerhalb des vorgeschriebenen Zeitrahmens (z.B. 30 Tage gemäß DSGVO) eine Antwort an die betroffene Person erfolgt. Bei Widersprüchen gegen Direktmarketing wird die Verarbeitung sofort eingestellt (Ziel: 0 Vorfälle von Nichteinhaltung).</li>
</ul>`;
        }

        // Schulungs- und Audit-KPIs einbeziehen, wenn irgendeine Einwilligungskontrolle angewendet wird
        if (hasPC7203 || hasPC7204 || hasPC7304 || hasPC7305) {
            kpisContent += `
  <ul>
    <li><strong>Schulung und Sensibilisierung:</strong> 100% der relevanten Mitarbeiter (z.B. Marketing, Produkt, IT) werden jährlich zu Einwilligungsverfahren und -tools geschult. (Gemessen anhand der Schulungsabschlussdaten.)</li>
  </ul>
  <ul>
    <li><strong>Audit-Ergebnisse:</strong> Keine wesentlichen Abweichungen in internen/externen Audits hinsichtlich des Einwilligungsmanagements (z.B. alle überprüften Einwilligungen sind gültig und ordnungsgemäß dokumentiert; keine Fälle von fehlender elterlicher Zustimmung bei Minderjährigen oder fehlender ausdrücklicher Zustimmung bei besonderen Daten).</li>
  </ul>`;
        }

        // 4. Verantwortlichkeiten

        // Erstelle den Verantwortlichkeitsabschnitt basierend auf den angewendeten Kontrollen
        let responsibilitiesContent = '<h2>4. Verantwortlichkeiten</h2>';

        // Immer DSB/Privacy Officer mit angepasster Beschreibung einbeziehen
        responsibilitiesContent += `
<ul>
  <li><strong>Datenschutzbeauftragter (DSB) / Verantwortlicher für Datenschutz:</strong> Überwacht den Einwilligungsmanagementprozess und stellt sicher, dass er den regulatorischen Anforderungen entspricht. `;

        if (hasPC7203) {
            responsibilitiesContent += `Berät darüber, wann eine Einwilligung erforderlich ist im Vergleich zu anderen rechtlichen Grundlagen. `;
        }

        responsibilitiesContent += `Überprüft und aktualisiert diese SOP, wenn sich Gesetze oder Geschäftsanforderungen ändern. Überwacht die Einhaltung `;

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `und bearbeitet komplexe Fälle (z.B. Widerrufsanfragen, die eine Datenlöschung zur Folge haben könnten) sowie jegliche Beschwerden im Zusammenhang mit der Einwilligung.`;
        } else {
            responsibilitiesContent += `im Zusammenhang mit dem Einwilligungsmanagement.`;
        }

        responsibilitiesContent += `</li>
</ul>`;

        // Rechts- & Compliance-Team
        responsibilitiesContent += `
<ul>
  <li><strong>Rechts- &amp; Compliance-Team:</strong> `;

        if (hasPC7203 || hasPC7204) {
            responsibilitiesContent += `Erarbeitet und genehmigt den Einwilligungstext sowie die Mechanismen zur Einholung der Einwilligung, um sicherzustellen, dass diese den DSGVO-Bedingungen entsprechen (klar, spezifisch, informiert). `;
        }

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `Stellt sicher, dass Datenschutzhinweise die betroffenen Personen über ihr Recht auf Widerruf und Widerspruch informieren, wie es die DSGVO verlangt. `;
        }

        responsibilitiesContent += `Beobachtet relevante gesetzliche Änderungen (z.B. zulässiges Einwilligungsalter pro Region, neue Leitlinien zur Einwilligung) und kommuniziert diese an die Prozessverantwortlichen.</li>
</ul>`;

        // Geschäftsprozessverantwortliche / Datenverantwortliche
        responsibilitiesContent += `
<ul>
  <li><strong>Geschäftsprozessverantwortliche / Datenverantwortliche:</strong> `;

        if (hasPC7203) {
            responsibilitiesContent += `Ermitteln, ob eine geplante Verarbeitungstätigkeit die Einwilligung als rechtliche Grundlage verwendet. Vor dem Start jeglicher neuer Datenerhebungen oder -nutzungen ist Rücksprache mit dem Rechts- & Compliance-Team zu halten, um zu klären, ob eine Einwilligung erforderlich (und angemessen) ist. `;
        }

        if (hasPC7204) {
            responsibilitiesContent += `Setzen die Einholung der Einwilligung gemäß dieser SOP um (z.B. Einbeziehung entsprechender Einwilligungsabfragen in Formulare) und stellen sicher, dass keine Datenverarbeitung ohne die notwendige Einwilligung beginnt. `;
        }

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `Sind verantwortlich dafür, Widerrufe oder Widersprüche in ihrem Geschäftsbereich zu berücksichtigen (z.B. das Stoppen einer Marketingkampagne für Personen, die widerrufen haben).`;
        }

        responsibilitiesContent += `</li>
</ul>`;

        // IT- & Systemverantwortliche
        if (hasPC7204 || hasPC7304) {
            responsibilitiesContent += `
<ul>
  <li><strong>IT- &amp; Systemverantwortliche:</strong> Implementieren und warten Systeme zur Erfassung, Speicherung und Verwaltung von Einwilligungsaufzeichnungen. Dies umfasst das Frontend (z.B. Webformulare, App-Oberflächen mit Einwilligungs-Checkboxen oder -Schaltern) und das Backend (Datenbanken oder Einwilligungsmanagement-Plattformen, die Einwilligungsdetails protokollieren). `;

            if (hasPC7204) {
                responsibilitiesContent += `Stellen sicher, dass <strong>Einwilligungsaufzeichnungen</strong> einen Zeitstempel, die Identität der betroffenen Person (oder eine pseudonymisierte ID), die Version des Einwilligungstextes und den Umfang der Einwilligung (welche Zwecke abgedeckt werden) beinhalten. `;
            }

            if (hasPC7304) {
                responsibilitiesContent += `Implementieren auch Mechanismen, die es den betroffenen Personen ermöglichen, ihre Einwilligung so einfach zu widerrufen, wie sie erteilt wurde (z.B. Self-Service-Portale, Abmeldelinks). `;
            }

            responsibilitiesContent += `Wenden Zugangskontrollen auf Einwilligungsaufzeichnungen an und fügen sie bei Bedarf den Datensicherungen als Audit-Trail hinzu.</li>
</ul>`;
        }

        // Kundenbetreuung / Team für Anfragen betroffener Personen
        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `
<ul>
  <li><strong>Kundenbetreuung:</strong> Agiert als Kontaktstelle für den Erhalt von `;

            let requests = [];
            if (hasPC7304) requests.push("Widerrufsanfragen");
            if (hasPC7305) requests.push("Widersprüche");

            responsibilitiesContent += requests.join(" oder ") + ` von betroffenen Personen über offizielle Kanäle (E-Mail, Webportal, Telefon). Folgt den Prozessen in Abschnitt 5, um die Anfragen zu überprüfen und umzusetzen. Kommuniziert die Ergebnisse an die betroffene Person (Bestätigung des Widerrufs oder Einstellung der Verarbeitung) und aktualisiert die internen Aufzeichnungen entsprechend. Leitet ungewöhnliche Anfragen (z.B. unklarer Umfang des Widerrufs) an den DSB weiter.</li>
</ul>`;
        }

        // Alle Mitarbeiter – einbeziehen, wenn irgendeine Einwilligungskontrolle angewendet wird
        if (hasPC7203 || hasPC7204 || hasPC7304 || hasPC7305) {
            responsibilitiesContent += `
<ul>
  <li><strong>Alle Mitarbeiter (Allgemeine Verpflichtung):</strong> Wenn ein Mitarbeiter direkt mit der Erhebung personenbezogener Daten von Einzelpersonen (z.B. telefonisch oder persönlich) befasst ist, muss er diese SOP befolgen – `;

            if (hasPC7204) {
                responsibilitiesContent += `verwendet genehmigte Einwilligungsskripte/-formulare `;
            }

            if ((hasPC7204 && (hasPC7304 || hasPC7305))) {
                responsibilitiesContent += `und `;
            }

            if (hasPC7304 || hasPC7305) {
                let forwards = [];
                if (hasPC7304) forwards.push("Widerrufs-");
                if (hasPC7305) forwards.push("Widerspruchs-");
                responsibilitiesContent += `leiten jegliche ${forwards.join(" oder ")}anfragen umgehend an das zuständige Team weiter. `;
            }

            if (hasPC7203 || hasPC7204) {
                responsibilitiesContent += `Darf keine Einwilligung erzwungen werden `;
            }

            if ((hasPC7203 || hasPC7204) && hasPC7304) {
                responsibilitiesContent += `oder einen Widerruf ignorieren. `;
            } else if (hasPC7304) {
                responsibilitiesContent += `Mitarbeiter dürfen einen Widerruf nicht ignorieren. `;
            } else if (hasPC7203 || hasPC7204) {
                responsibilitiesContent += `. `;
            }

            responsibilitiesContent += `Im Zweifelsfall sollten sie sich an die Datenschutzabteilung wenden, wenn sie unsicher bezüglich der Einwilligungsanforderungen in einer Situation sind.</li>
</ul>`;
        }

        // Erstelle den Inhalt für Prozess 5.1
        let process51Content = '';

        if (hasPC7203) {
            process51Content = `
<h3>5.1 Bestimmen, wann und wie die Einwilligung eingeholt werden muss</h3>
<p><strong>Input:</strong></p>
<p>Eine vorgeschlagene <strong>neue Verarbeitungstätigkeit</strong> oder Änderung einer bestehenden Tätigkeit, die personenbezogene Daten umfasst, typischerweise dokumentiert in einem Projektplan. Dies beinhaltet Details zum vorgesehenen Zweck, zu den Datentypen (einschließlich besonderer Kategorien) und zu den betroffenen Personen (z.B. Erwachsene, Kinder).</p>
<p><strong>Aktivitäten:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Bewertung der rechtlichen Grundlage:</strong> Der Datenschutzbeauftragte oder Prozessverantwortliche bewertet den <strong>Zweck der Verarbeitung und den Kontext</strong>, um zu entscheiden, ob die Einwilligung die geeignete rechtliche Grundlage darstellt oder ob eine andere Grundlage (z.B. Vertrag, gesetzliche Verpflichtung, berechtigte Interessen) passender ist. Nutzen Sie die Einwilligung nur, wenn der betroffenen Person eine echte Wahlmöglichkeit geboten werden kann (die Verarbeitung ist nicht zwingend für einen Service erforderlich) und keine der anderen Grundlagen aus Artikel 6 eindeutig zutrifft.</li>
</ol>`;

            if (!isPC72031aOverridden) {
                process51Content += `
<ol type="1" class="numbered-list" start="2">
  <li><strong>Besondere Fälle identifizieren:</strong> Bestimmen Sie, ob die Verarbeitung besondere Kategorien personenbezogener Daten oder Strafregisterdaten umfasst. Falls ja, beachten Sie, dass eine <strong>ausdrückliche Einwilligung</strong> erforderlich ist (gemäß DSGVO Art. 9(2)(a)) und prüfen Sie, ob nationale Gesetze die Verwendung von Einwilligungen zu diesem Zweck einschränken. Ermitteln Sie auch, ob betroffene Personen Kinder unter dem Einwilligungsalter für digitale Dienste (Altersgrenze variiert je nach Land, 13–16) umfassen. Falls Daten von Kindern verarbeitet werden, planen Sie, die nachprüfbare elterliche Einwilligung gemäß DSGVO Art. 8 einzuholen.</li>
</ol>`;
            } else {
                process51Content += `
<ol type="1" class="numbered-list" start="2">
  <li><strong>Besondere Fälle identifizieren:</strong> Bestimmen Sie, ob die Verarbeitung besondere Kategorien personenbezogener Daten oder Strafregisterdaten umfasst. Falls ja, beachten Sie, dass eine <strong>ausdrückliche Einwilligung</strong> erforderlich ist (gemäß DSGVO Art. 9(2)(a)) und prüfen Sie, ob nationale Gesetze die Verwendung von Einwilligungen zu diesem Zweck einschränken.</li>
</ol>`;
            }

            process51Content += `
<ol type="1" class="numbered-list" start="3">
  <li><strong>Bestimmen, wann die Einwilligung eingeholt wird:</strong> Legen Sie fest, zu welchem Zeitpunkt im Nutzerprozess oder in der Ablauforganisation die Einwilligung eingeholt wird. Sie muss <strong>vor jeglicher Verarbeitung</strong> der personenbezogenen Daten zu diesem Zweck erfolgen. Beispielsweise sollte in einem Online-Formular die Einwilligung vor der Datenübermittlung abgefragt werden; in einer App beim ersten Start oder bevor eine Funktion aktiviert wird, die personenbezogene Daten sammelt. Dokumentieren Sie dies im Projektplan.</li>
</ol>`;

            process51Content += `
<ol type="1" class="numbered-list" start="4">
  <li><strong>Bestimmen, wie (Methode):</strong> Wählen Sie einen geeigneten <strong>Einwilligungsmechanismus</strong> aus, der zum Kontext passt: z.B. ein Ankreuzfeld in einem Webformular (standardmäßig nicht ausgewählt), ein spezieller Einwilligungsbildschirm in einer App, ein physisches Einwilligungsformular oder eine E-Mail-Bestätigung (für ausdrückliche Einwilligungen wird eine schriftliche oder ähnlich robuste Methode empfohlen). `;

            if (!isPC72031bOverridden) {
                process51Content += `Stellen Sie sicher, dass die Einwilligungsanfrage <strong>getrennt von anderen Bedingungen</strong> und in klarer Sprache dargestellt wird. `;
            } else {
                process51Content += `Stellen Sie sicher, dass die Einwilligungsanfrage in klarer Sprache dargestellt wird. `;
            }

            if (!isPC72031aOverridden) {
                process51Content += `Für Kinder entwerfen Sie eine Methode, um die elterliche Zustimmung einzuholen (z.B. ein elterliches Einwilligungsformular oder eine E-Mail-Verifizierung des Elternteils/Erziehungsberechtigten). `;
            }

            process51Content += `Für sensible Daten erwägen Sie eine Methode, die eine explizite bestätigende Handlung erfasst (z.B. digitale Unterschrift oder das Ankreuzen eines Feldes mit "Ich stimme der Verarbeitung [sensibler Daten] zu").</li>
</ol>
<ol type="1" class="numbered-list" start="5">
  <li><strong>Einwilligungstext formulieren:</strong> Erarbeiten Sie mit Unterstützung der Rechts- und Compliance-Abteilung den Einwilligungstext, der den betroffenen Personen angezeigt wird. Er sollte den <strong>konkreten Zweck</strong> der Verarbeitung und die zu verarbeitenden Daten klar angeben und auf die relevante Datenschutzerklärung für vollständige Details verweisen. Stellen Sie sicher, dass alle erforderlichen Elemente enthalten sind (z.B. Identität des Verantwortlichen, Zweck, Datentypen, sowie die Tatsache, dass die Einwilligung jederzeit widerrufen werden kann).`;
            if (!isPC72031bOverridden) {
                process51Content += ` (Vermeiden Sie die Bündelung nicht zusammenhängender Einwilligungen)`;
            }
            process51Content += `.</li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Überprüfung und Genehmigung:</strong> Das DSB-/Privacy-Team überprüft den Plan (Auswahl der rechtlichen Grundlage, Methode und Formulierung), um sicherzustellen, dass er die ISO-/DSGVO-Kriterien erfüllt. Insbesondere wird geprüft, ob die Einwilligung <strong>frei, spezifisch, informiert und eindeutig</strong> erteilt wird. `;
            if (!isPC72031bOverridden) {
                process51Content += `Bestätigen Sie außerdem, dass das Nicht-Erteilen der Einwilligung nicht dazu führt, dass ein Service, der nicht damit zusammenhängt, verweigert wird (um eine erzwungene Einwilligung zu vermeiden, gemäß DSGVO 7(4), wonach ein Vertrag nicht von einer unnötigen Einwilligung abhängig gemacht werden darf). `;
            }
            process51Content += `Nach Genehmigung werden Methode und Formulierung für die Umsetzung finalisiert.</li>
</ol>
<p><strong>Output:</strong></p>
<p>Eine <strong>dokumentierte Entscheidung in Cenedril</strong> bezüglich der rechtlichen Grundlage der Verarbeitung. Falls Einwilligung gewählt wurde, umfasst der Output einen <strong>Plan zur Einholung der Einwilligung</strong>: mit Angaben dazu, wann und wie die Einwilligung eingeholt wird, dem genauen Einwilligungstext und eventuellen Sondermaßnahmen`;
            if (!isPC72031aOverridden) {
                process51Content += ` (z.B. Ablauf für elterliche Einwilligung, explizite Einwilligungsabfrage)`;
            } else {
                process51Content += ` (z.B. explizite Einwilligungsabfrage)`;
            }
            process51Content += `. Diese Informationen sollten im <a href="/data-privacy-wizard/ropa">Verarbeitungsverzeichnis</a> für diese Tätigkeit festgehalten werden (unter Angabe von "Einwilligung" als rechtliche Grundlage und mit Verweis auf die Einwilligungsmethode). Das Projekt kann dann mit der Umsetzung der Einwilligungseinholung in den Systemen oder Prozessen wie spezifiziert fortfahren.</p>`;
        }

        // Erstelle den Inhalt für Prozess 5.2
        let process52Content = '';

        if (hasPC7204) {
            const sectionNumber = hasPC7203 ? "5.2" : "5.1";

            process52Content = `
<h3>${sectionNumber} Einwilligung einholen und erfassen</h3>
<p><strong>Input:</strong></p>
<p>Ein <strong>aktiver Datenerfassungsprozess</strong>, der eine Einwilligung erfordert, der einsatzbereit ist (z.B. ein Website-Formular, eine mobile App, eine Umfrage oder eine Schnittstelle mit der betroffenen Person). Ebenfalls gehört zu den Inputs der <strong>genehmigte Einwilligungstext und die Methode</strong>${hasPC7203 ? " aus Prozess 5.1" : ""} sowie eine aktuelle <strong>Datenschutzerklärung</strong>, auf die sich die Einwilligung bezieht (mit Details dazu, wozu die betroffene Person ihre Einwilligung gibt).</p>
<p><strong>Aktivitäten:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Einwilligungsanfrage präsentieren:</strong> Beim Erheben personenbezogener Daten wird die <strong>Einwilligungsanfrage klar und deutlich</strong> der betroffenen Person präsentiert, <strong>bevor die Daten verarbeitet werden</strong>. Die Anfrage sollte in klarer Sprache erfolgen und <strong>spezifisch auf den Zweck</strong> zugeschnitten sein. Zum Beispiel: "Ich stimme zu, dass [Unternehmen] meine E-Mail und Präferenzen verarbeitet, um mir den monatlichen Newsletter zu senden." `;
            if (hasPC7203 && !isPC72031bOverridden) {
                process52Content += `Stellen Sie sicher, dass die Einwilligungsanfrage von allgemeinen Geschäftsbedingungen getrennt präsentiert wird (nicht im Fließtext versteckt) und `;
            }
            process52Content += `eine ausdrückliche Handlung (z.B. Ankreuzen eines nicht vorausgewählten Feldes oder Klick auf "Akzeptieren") der betroffenen Person erfordert.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Sicherstellung der informierten Einwilligung:</strong> Stellen Sie zum Zeitpunkt der Einwilligung den Zugang zu relevanten Informationen bereit. Dies bedeutet in der Regel, auf die Datenschutzerklärung oder eine Zusammenfassung der wichtigsten Informationen (Identität des Verantwortlichen, Zweck der Verarbeitung, Datentypen, Widerrufsrecht etc.) zu verlinken oder diese anzuzeigen. Die betroffene Person sollte die Möglichkeit haben, zu lesen, worin sie einwilligt. Falls die Anfrage Teil eines längeren Prozesses ist, erlauben Sie, dass sie <strong>nicht einwilligen</strong> kann und dennoch fortfahren (oder den Prozess abbrechen, wenn die Einwilligung zwingend erforderlich ist).`;
            if (hasPC7203 && !isPC72031aOverridden) {
                process52Content += ` Für besondere Szenarien:
    <ul>
      <li>Wenn die betroffene Person ein <strong>Kind</strong> unter dem definierten Alter ist, richten Sie die Einwilligungsanfrage an den Elternteil/Erziehungsberechtigten. Beispielsweise sammeln Sie die E-Mail des Elternteils zur Verifizierung der Einwilligung oder verwenden eine Altersüberprüfung, um eine Unteralterseinwilligung zu verhindern. <strong>Überprüfen Sie die elterliche Einwilligung</strong> mittels eines geeigneten Verfahrens (z.B. Bestätigungslink an die E-Mail des Elternteils oder Unterschrift des Erziehungsberechtigten), bevor die Verarbeitung der Kinderdaten erlaubt wird.</li>
    </ul>
    <ul>
      <li>Falls <strong>besondere Kategorien von Daten</strong> betroffen sind, erwähnen Sie explizit den sensiblen Charakter und holen Sie eine <strong>ausdrückliche Bestätigung</strong> ein. Zum Beispiel: "Ich willige ausdrücklich in die Verarbeitung meiner Gesundheitsdaten zu [Zweck] ein." Erwägen Sie bei hohem Risiko eine doppelte Bestätigung (das Ankreuzen eines Feldes und zusätzlich das Klicken auf einen Bestätigungsbutton).</li>
    </ul>`;
            } else {
                process52Content += `
    <ul>
      <li>Falls <strong>besondere Kategorien von Daten</strong> betroffen sind, erwähnen Sie explizit den sensiblen Charakter und holen Sie eine <strong>ausdrückliche Bestätigung</strong> ein. Zum Beispiel: "Ich willige ausdrücklich in die Verarbeitung meiner Gesundheitsdaten zu [Zweck] ein." Erwägen Sie bei hohem Risiko eine doppelte Bestätigung (das Ankreuzen eines Feldes und zusätzlich das Klicken auf einen Bestätigungsbutton).</li>
    </ul>`;
            }
            process52Content += `
  </li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Erfassung der Einwilligungsaktion:</strong> Gestalten Sie die Benutzeroberfläche so, dass die betroffene Person eine bejahende Handlung vornehmen muss. Es sind keine vorab angekreuzten Kästchen oder implizite Einwilligungen (Stille oder Inaktivität) zulässig – die Einwilligung muss eine eindeutige Bestätigung der Absicht darstellen. Akzeptable Methoden umfassen das Klicken auf "Ja, ich willige ein", das Unterschreiben eines Formulars oder eine mündliche Einwilligung, die in einem System aufgezeichnet wird (bei telefonischer Einwilligung muss das Skript vorgelesen und ein prüfbarer Log oder eine Aufnahme erstellt werden). Falls die betroffene Person die Einwilligung verweigert (z.B. das Kästchen nicht ankreuzt oder "nein" sagt), stellen Sie sicher, dass das System diese Wahl respektiert (z.B. keine Erhebung oder Verarbeitung der Daten für diesen Zweck, und ggf. protokolliert, dass keine Einwilligung erteilt wurde, falls dies für Nachweise erforderlich ist).</li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Best Practice: Bestätigung bereitstellen (optional):</strong> Es ist gute Praxis, der betroffenen Person zu bestätigen, dass ihre Einwilligung erhalten wurde. Bei Online-Interaktionen kann eine Bestätigungsnachricht wie "Vielen Dank, Sie haben in X eingewilligt. Sie können Ihre Einwilligung jederzeit über [Methode] widerrufen." angezeigt werden. Falls angebracht, senden Sie eine E-Mail-Bestätigung der Einwilligung an die betroffene Person (insbesondere bei sensiblen Daten oder elterlichen Einwilligungen). Dies fördert die Transparenz.</li>
</ol>`;
            process52Content += `
<ol type="1" class="numbered-list" start="5">
  <li><strong>Aufzeichnung:</strong> Sobald die Einwilligung eingeholt wurde, muss das System oder der Prozess ein <strong>Aufzeichnungsprotokoll</strong> der Einwilligung erstellen. Es sind mindestens folgende Elemente aufzuzeichnen: `;
            let recordElements = [];

            if (!isPC72041bOverridden) {
                recordElements.push("die Identität der betroffenen Person (oder ein Identifikator/Pseudonym, falls keine direkt identifizierenden Informationen gespeichert werden)");
            }

            if (!isPC72041cOverridden) {
                recordElements.push("den Einwilligungstext oder eine ID/einen Link zur Version des vereinbarten Einwilligungstextes");
            }

            if (!isPC72041aOverridden) {
                recordElements.push("das Datum/die Uhrzeit, zu der die Einwilligung erteilt wurde");
            }

            recordElements.push("die Methode (z.B. über Webformular, IP-Adresse oder Standort, sofern relevant, oder der Auditor, der eine telefonische Einwilligung aufgezeichnet hat)");
            recordElements.push("jeglicher zugehöriger Kontext (z.B. Version der Datenschutzerklärung)");

            if (recordElements.length === 1) {
                process52Content += recordElements[0];
            } else if (recordElements.length === 2) {
                process52Content += recordElements.join(" und ");
            } else {
                const lastElement = recordElements.pop();
                process52Content += recordElements.join(", ") + ", und " + lastElement;
            }

            if (hasPC7203 && !isPC72031aOverridden) {
                process52Content += `. Für elterliche Einwilligungen werden Details der elterlichen Genehmigung (welcher Elternteil, wie überprüft) erfasst.`;
            }

            process52Content += ` Diese Aufzeichnungen werden sicher im vorgesehenen Repository gespeichert (z.B. in der Einwilligungsmanagement-Datenbank oder im Benutzerprofil in unserem System). Diese Aufzeichnungen dienen als Nachweis der Einhaltung (gemäß DSGVO <strong>Art. 7(1)</strong>, wir müssen nachweisen können, dass die betroffene Person eingewilligt hat).</li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Verknüpfung der Einwilligung mit der Verarbeitung:</strong> Stellen Sie sicher, dass nachgelagerte Systeme wissen, dass die betroffene Person eingewilligt hat. Beispielsweise kennzeichnen Sie die Daten im CRM als "Einwilligung für X Zweck eingeholt", sodass die Verarbeitung fortgesetzt werden kann. Falls keine Einwilligung vorliegt, sollten die Daten markiert oder getrennt werden, um deren Nutzung zu verhindern. Implementieren Sie eine Geschäftslogik, die den Einwilligungsstatus berücksichtigt – nur Personen mit aufgezeichneter Einwilligung werden in die Verarbeitung einbezogen (z.B. werden Marketing-E-Mails nur an diejenigen gesendet, die eingewilligt haben).</li>
</ol>`;
            if (hasPC7203 && !isPC72031bOverridden) {
                process52Content += `
<ol type="1" class="numbered-list" start="7">
  <li><strong>Bündelung und Erzwungeneinwilligung vermeiden:</strong> Wenn mehrere Einwilligungsanfragen (für unterschiedliche Zwecke) präsentiert werden, ermöglichen Sie der betroffenen Person, jede einzeln zu erteilen oder abzulehnen (die Einwilligung darf nicht pauschal "alles oder nichts" sein, es sei denn, es ist absolut notwendig). Verweigern Sie niemals einen Kernservice allein, weil eine Person die Einwilligung für einen nicht damit zusammenhängenden zusätzlichen Zweck verweigert hat (in Übereinstimmung mit den DSGVO-Leitlinien zu frei erteilter Einwilligung). Falls die Einwilligung eine Bedingung für den Service darstellt, sollte dies nur der Fall sein, wenn der Service <strong>ohne diese Datenverarbeitung nicht erbracht werden kann</strong> (dokumentieren Sie in solchen Fällen die Begründung).</li>
</ol>`;
            }
            const monitoringStepNumber = (hasPC7203 && !isPC72031bOverridden) ? "8" : "7";
            process52Content += `
<ol type="1" class="numbered-list" start="${monitoringStepNumber}">
  <li><strong>Überwachen und Qualitätssicherung:</strong> Überprüfen Sie regelmäßig, ob die Einholung der Einwilligung wie vorgesehen funktioniert. Beispielsweise bei einem Webformular: Vergewissern Sie sich, dass das Kästchen nicht umgangen werden kann und tatsächlich ein Eintrag erstellt wird. Bei telefonischen Prozessen stellen Sie sicher, dass das Skript die Einwilligungstexte beinhaltet und dass Aufzeichnungen/Logs gespeichert werden. Dies erfolgt in der Regel durch den Prozessverantwortlichen oder im Rahmen interner Audits. Jegliche Probleme (z.B. wenn Einwilligungen aufgrund eines Systemfehlers nicht aufgezeichnet werden) sind umgehend zu beheben und hinsichtlich möglicher Compliance-Auswirkungen zu bewerten.</li>
</ol>
<p><strong>Output:</strong></p>
<p>Eine <strong>gültige Einwilligung</strong> für jede betroffene Person, die zugestimmt hat, wird im System erfasst. Die Outputs umfassen:</p>
<ul>
  <li><strong>Einwilligungsaufzeichnungen</strong>, die im Einwilligungsrepository oder in der Datenbank gespeichert sind (mit allen notwendigen Details zum Nachweis der Einwilligung).</li>
</ul>
<ul>
  <li><strong>Aktualisiertes <a href="/data-privacy-wizard/ropa">Verarbeitungsverzeichnis</a>:</strong> Die rechtliche Grundlage für die Verarbeitung wird als Einwilligung bestätigt und es werden Verweise darauf hinzugefügt, wo die Einwilligungsaufzeichnungen gespeichert sind.</li>
</ul>
<ul>
  <li><strong>Fortsetzung der Verarbeitung:</strong> Personen, die eingewilligt haben, sind nun für die vorgesehene Verarbeitung berechtigt. Personen, die nicht eingewilligt haben, werden ausgeschlossen (und, falls zutreffend, wird ihnen eine Alternative angeboten oder sie erhalten schlichtweg den optionalen Service/Benefit, der an die Einwilligung gekoppelt ist, nicht).</li>
</ul>
<ul>
  <li>Die Organisation verfügt nun über aktuelle Nachweise, die bei Bedarf den Aufsichtsbehörden oder Prüfern vorgelegt werden können, dass jegliche Verarbeitung personenbezogener Daten auf der Basis der "Einwilligung" mit einer ordnungsgemäß eingeholten Einwilligung erfolgte.</li>
</ul>`;
        }

        // Erstelle den Inhalt für Prozess 5.3
        let process53Content = '';

        if (hasPC7304) {
            let sectionNumber = "5.1"; // Standard, falls keine vorherigen Abschnitte vorhanden sind
            if (hasPC7203 && hasPC7204) {
                sectionNumber = "5.3";
            } else if (hasPC7203 || hasPC7204) {
                sectionNumber = "5.2";
            }


            process53Content = `
<h3>${sectionNumber} Bereitstellung eines Mechanismus zur Änderung oder zum Widerruf der Einwilligung</h3>
<p><strong>Input:</strong></p>
<p>Eine <strong>Anfrage einer betroffenen Person zum Widerruf der Einwilligung</strong> oder zur Änderung ihrer Einwilligungspräferenzen. Diese kann über verschiedene Kanäle eingehen: beispielsweise durch Klicken auf einen "Abmelden" oder "Opt-out"-Link, durch Aktualisierung der Einstellungen im Benutzerprofil, per E-Mail/Telefon an den Kundenservice oder mündlich an einen Mitarbeiter. Zu den Inputs gehören die Identifizierung der anfragenden Person und die Einwilligung(en), die widerrufen oder geändert werden sollen (implizit oder explizit in der Anfrage angegeben). Der Einwilligungsdatensatz existiert bereits in unserem System aus dem vorangegangenen Prozess.</p>
<p><strong>Aktivitäten:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Ermöglichen des einfachen Widerrufs:</strong> Die Organisation stellt sicher, dass der Widerruf der Einwilligung ebenso einfach ist wie deren Erteilung. Übliche Mechanismen umfassen Ein-Klick-Abmelde-Links in E-Mails, ein Self-Service-Datenschutz-Dashboard oder eine klare E-Mail/Kontaktmöglichkeit für den Widerruf. Sobald eine Widerrufsanforderung eingeht, wird die Zeit und der Inhalt der Anfrage im Einwilligungs-/Protokollsystem vermerkt. Falls die Anfrage unklar ist (z.B. "Hört auf, meine Daten zu verwenden"), folgen Sie der Anfrage zur Klärung, auf welche Einwilligung oder Verarbeitung sie sich bezieht, sofern notwendig.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Authentifizierung der Anfrage (falls erforderlich):</strong> Falls der Widerruf über einen Kanal eingeht, der nicht selbst-authentifiziert ist (z.B. von der registrierten E-Mail der betroffenen Person oder über deren eingeloggte Sitzung), verifizieren Sie die Identität, um sicherzustellen, dass die Person, die widerruft, die betroffene Person oder eine autorisierte Vertretung ist. Beispielsweise, falls die Anfrage telefonisch oder von einer neuen E-Mail-Adresse kommt, fordern Sie einen Verifizierungsschritt an. Dies dient dazu, zu verhindern, dass Unbefugte unrechtmäßig den Widerruf der Einwilligung veranlassen.</li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Einwilligungsdatensatz finden:</strong> Suchen Sie den Einwilligungsdatensatz der betroffenen Person, auf den sich die Anfrage bezieht. Bestimmen Sie, welche spezifische Einwilligung widerrufen wird – z.B. "Einwilligung zu Marketing-E-Mails vom 2025-01-10" oder "Einwilligung zur Forschungsnutzung von Gesundheitsdaten." Dies kann durch Suche in der Einwilligungsdatenbank mittels Benutzer-ID oder E-Mail erfolgen.</li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Verarbeitung für widerrufene Einwilligung einstellen:</strong> Leiten Sie so schnell wie möglich Maßnahmen ein, damit die Verarbeitung, die unter diese Einwilligung fällt, für die betroffene Person eingestellt wird. Beispielsweise, wenn sie der Verarbeitung von Marketing-E-Mails widersprechen, entfernen oder kennzeichnen Sie deren Adresse in der Mailingliste, um sie von zukünftigen Sendungen auszuschließen (oder aktualisieren Sie deren Marketingpräferenz im CRM auf "Opt-out"). Falls sie der Verarbeitung aller Daten für ein bestimmtes Projekt widersprechen, informieren Sie den zuständigen Geschäfts-/Prozessverantwortlichen, um die Nutzung der Daten der betroffenen Person einzustellen. <strong>Nach dem Widerruf darf auf Grundlage dieser Einwilligung keine weitere Verarbeitung erfolgen</strong>. (Hinweis: Falls eine andere rechtliche Grundlage für die Datenverarbeitung besteht, konsultieren Sie die Rechtsabteilung, bevor Sie die Verarbeitung vollständig einstellen – in den meisten Fällen bedeutet ein Widerruf, dass die Verarbeitung nicht fortgesetzt werden kann, es sei denn, dies ist gesetzlich vorgeschrieben oder ähnlich, aber z.B. wenn sie der Verarbeitung zu optionalen Forschungszwecken widersprechen, könnten wir die Daten unter einer anderen Grundlage für den Kerndienst weiterhin behalten.)</li>
</ol>`;
            if (!isPC73041aOverridden) {
                process53Content += `
<ol type="1" class="numbered-list" start="5">
  <li><strong>Widerruf dokumentieren:</strong> Aktualisieren Sie den Einwilligungsdatensatz, um festzuhalten, dass er widerrufen wurde: Erfassen Sie das Datum/die Uhrzeit des Widerrufs und die Methode (z.B. "Benutzer hat auf Abmelde-Link geklickt" oder "mündlicher Widerruf via Telefon protokolliert"). Falls unser System dies unterstützt, markieren Sie die Einwilligung als inaktiv oder verschoben in ein Archiv. Bewahren Sie den historischen Datensatz auf, dass die Einwilligung einst erteilt und dann widerrufen wurde, als Nachweis der Einhaltung (die DSGVO verlangt, dass wir die Einwilligung für vergangene Verarbeitungen bis zum Widerruf nachweisen können und dass der Widerruf berücksichtigt wurde).</li>
</ol>`;
            }

            let nextStep = !isPC73041aOverridden ? 6 : 5;
            process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Bestätigung an die betroffene Person:</strong> Senden Sie eine Bestätigung an die betroffene Person, dass deren Einwilligung widerrufen wurde und dass entsprechende Maßnahmen ergriffen wurden. Zum Beispiel eine Nachricht: "Sie haben Ihre Einwilligung für [Zweck] erfolgreich widerrufen. Wir haben die Verarbeitung Ihrer Daten für diesen Zweck eingestellt." Falls der Widerruf bedeutet, dass die Daten (weil die Einwilligung die einzige Grundlage war) gelöscht werden, informieren Sie die betroffene Person darüber, ob Daten gelöscht oder anonymisiert werden (und fahren Sie dann gemäß der Datenlöschungsrichtlinie fort). Falls einige Daten behalten werden (eventuell aufgrund gesetzlicher Verpflichtungen), stellen Sie klar, dass die spezifische einwilligungsbasierte Verarbeitung eingestellt wurde.</li>
</ol>`;
            nextStep++;
            if (!isPC73041bOverridden) {
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Sicherstellung der fortlaufenden Einhaltung:</strong> Falls die Daten der betroffenen Person an Dritte oder Auftragsverarbeiter auf Basis ihrer Einwilligung weitergegeben wurden, benachrichtigen Sie diese Parteien über den Widerruf, falls erforderlich, damit sie die weitere Verarbeitung einstellen. Beispielsweise, wenn ein Partnerunternehmen die Daten im Rahmen einer gemeinsamen Einwilligung erhalten hat, informieren Sie es umgehend darüber, dass die Einwilligung widerrufen wurde (gemäß unseren Verpflichtungen, Löschungen/Widerrufe weiterzuleiten). Intern stellen Sie sicher, dass alle beteiligten Teammitglieder oder Systeme wissen, dass die Daten für diesen Zweck nicht weiter verwendet werden dürfen.</li>
</ol>`;
                nextStep++;
            } else {
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Sicherstellung der fortlaufenden Einhaltung:</strong> Falls die Daten der betroffenen Person an Dritte oder Auftragsverarbeiter auf Basis ihrer Einwilligung weitergegeben wurden, benachrichtigen Sie diese Parteien, falls erforderlich, damit sie die weitere Verarbeitung einstellen. Intern stellen Sie sicher, dass alle beteiligten Teammitglieder oder Systeme wissen, dass die Daten für diesen Zweck nicht weiter verwendet werden dürfen.</li>
</ol>`;
                nextStep++;
            }
            process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Änderung vs. Widerruf:</strong> Falls die Anfrage nicht einen vollständigen Widerruf, sondern eine <strong>Änderung</strong> der Einwilligung betrifft (z.B. sagt der Benutzer "Ich stimme weiterhin E-Mails zu, widerrufe aber die telefonische Einwilligung" oder ändert die Präferenzen in einem Cookie-Einstellungsmenü), behandeln Sie dies entsprechend: Passen Sie den Einwilligungsdatensatz an, um die neuen Präferenzen widerzuspiegeln. Behandeln Sie es im Wesentlichen so, als würden Sie die Einwilligung für einen Aspekt widerrufen und möglicherweise für einen anderen einholen (oder beibehalten). Dokumentieren Sie diese Änderungen ebenfalls.</li>
</ol>`;
            nextStep++;
            let auditTrailText = '';
            if (!isPC73041cOverridden) {
                auditTrailText = `Stellen Sie zumindest sicher, dass wir den Prüfern das Protokoll der Widerrufe und deren Bearbeitung innerhalb des SLA vorlegen können.`;
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Dateninventar/Audit-Trails aktualisieren:</strong> Falls zutreffend, aktualisieren Sie alle Aufzeichnungen oder Inventareinträge, falls der Widerruf unsere Verarbeitung signifikant verändert (z.B. wenn viele Personen widerrufen und wir uns entscheiden, die rechtliche Grundlage zu ändern oder ein Projekt zu beenden, stellen Sie sicher, dass dies dokumentiert wird). ${auditTrailText}</li>
</ol>`;
                nextStep++;
            } else {
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Dateninventar/Audit-Trails aktualisieren:</strong> Falls zutreffend, aktualisieren Sie alle Aufzeichnungen oder Inventareinträge, falls der Widerruf unsere Verarbeitung signifikant verändert (z.B. wenn viele Personen widerrufen und wir uns entscheiden, die rechtliche Grundlage zu ändern oder ein Projekt zu beenden, stellen Sie sicher, dass dies dokumentiert wird).</li>
</ol>`;
                nextStep++;
            }
            process53Content += `
<p><strong>Output:</strong></p>
<p>Der <strong>Widerruf (bzw. die Änderung) ist abgeschlossen</strong>. Der Einwilligungsstatus der betroffenen Person wird aktualisiert, sodass ersichtlich ist, dass sie nicht mehr in die betreffende Verarbeitung einwilligt. Alle relevanten Systeme und Datensätze werden aktualisiert, sodass die Daten der betroffenen Person <strong>nicht mehr für diesen Zweck verarbeitet werden</strong>. Die betroffene Person erhält (falls zutreffend) eine Bestätigung der Änderung. `;
            if (!isPC73041aOverridden) {
                process53Content += `Wir führen einen <strong>Audit-Trail</strong> der Anfrage und unserer Reaktion, um die Einhaltung der Vorgabe nachzuweisen, dass der Widerruf ebenso einfach ist wie die Erteilung der Einwilligung. `;
            }
            process53Content += `Falls der Widerruf für den einzigen Zweck erfolgte, für den die Daten vorgehalten wurden, können die Daten gemäß der Aufbewahrungsrichtlinie gelöscht oder anonymisiert werden`;
            if (!isPC73041aOverridden) {
                process53Content += ` (wobei diese Maßnahme ebenfalls dokumentiert wird)`;
            }
            process53Content += `.`;
            if (!isPC73041cOverridden) {
                process53Content += ` Unsere KPI zur Widerrufsverarbeitungszeit wird aktualisiert (die Bearbeitungszeit dieses Widerrufs fließt in die Berichterstattung ein). Etwaige Schwierigkeiten oder Verzögerungen werden dokumentiert und, falls erforderlich, an den DSB zur Verbesserung des Prozesses gemeldet.`;
            }
            process53Content += `</p>`;
        }

        // Erstelle den Inhalt für Prozess 5.4
        let process54Content = '';

        if (hasPC7305) {
            let sectionNumber = "5.1"; // Standard, falls keine vorherigen Abschnitte vorhanden sind
            let referenceToWithdrawalSection = "den Prozess zum Widerruf";

            if (hasPC7203 && hasPC7204 && hasPC7304) {
                sectionNumber = "5.4";
                referenceToWithdrawalSection = "5.3";
            } else if ((hasPC7203 && hasPC7204) || (hasPC7203 && hasPC7304) || (hasPC7204 && hasPC7304)) {
                sectionNumber = "5.3";
                referenceToWithdrawalSection = "5.2";
            } else if (hasPC7203 || hasPC7204 || hasPC7304) {
                sectionNumber = "5.2";
                referenceToWithdrawalSection = "5.1";
            }

            process54Content = `
<h3>${sectionNumber} Bereitstellung eines Mechanismus, um der Verarbeitung personenbezogener Daten zu widersprechen</h3>
<p><strong>Input:</strong></p>
<p>Eine <strong>Widerspruchsanfrage</strong> einer betroffenen Person hinsichtlich der Verarbeitung ihrer personenbezogenen Daten. Gemäß DSGVO bezieht sich dies typischerweise auf das Widerspruchsrecht bei Verarbeitungen auf Grundlage berechtigter Interessen oder im öffentlichen Interesse (Art. 21(1)) oder auf den Widerspruch gegen die Verarbeitung zu Direktmarketingzwecken (Art. 21(2)). Die Anfrage kann per E-Mail, Webformular oder mündlich erfolgen und sollte (explizit oder durch den Kontext) darauf hinweisen, dass die betroffene Person der Verarbeitung einer bestimmten Tätigkeit widerspricht. Zu den Inputs gehört die Identifizierung der betroffenen Person und der betreffenden Verarbeitung.</p>`;
            if (hasPC7304) {
                process54Content += `
<p><em>(Hinweis: Falls der Widerspruch ausdrücklich lautet "Ich möchte, dass Sie meine Daten überhaupt nicht mehr verarbeiten", und unsere einzige Grundlage die Einwilligung war, entspricht dies effektiv einem Widerruf (siehe ${referenceToWithdrawalSection}). Wenn wir jedoch auf einer anderen Grundlage wie berechtigten Interessen verarbeiten, gilt das formelle Widerspruchsverfahren.)</em></p>`;
            }
            process54Content += `
<p><strong>Aktivitäten:</strong></p>`;
            const showDetailedLegalRequirements = !isPC73051aOverridden;
            process54Content += `
<ol type="1" class="numbered-list" start="1">
  <li><strong>Widerspruchsanfrage annehmen:</strong> Das Kundenservice- oder Privacy-Team protokolliert den Widerspruch bei Eingang. Falls der Widerspruch nicht eindeutig ist, holen Sie eine Klärung ein. Beispielsweise könnte eine Person sagen "Ich widerspreche der Verarbeitung meiner Daten" – es muss geklärt werden, ob sie sich auf Direktmarketing oder eine spezifische Nutzung bezieht. `;
            if (showDetailedLegalRequirements) {
                process54Content += `Gemäß DSGVO kann eine betroffene Person gegen <em>jede</em> Verarbeitung auf Grundlage berechtigter Interessen aufgrund ihrer spezifischen Situation Widerspruch einlegen oder sich ausdrücklich gegen Direktmarketing entscheiden. `;
            }
            process54Content += `Stellen Sie sicher, dass die Anfrage mit Datum und Uhrzeit des Eingangs dokumentiert wird.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Identität verifizieren:</strong> `;
            if (hasPC7304) {
                process54Content += `Wie bei Widerrufen, `;
            }
            process54Content += `bestätigen Sie die Identität des Anfragenden, falls Zweifel bestehen (insbesondere, wenn der Widerspruch über einen anderen Kanal als der übliche Kontakt der betroffenen Person eingeht). Wir müssen sicherstellen, dass die widersprechende Person die betroffene Person oder deren Bevollmächtigte ist.</li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Die betroffene Verarbeitung identifizieren:</strong> Bestimmen Sie, auf welche Verarbeitung sich der Widerspruch bezieht. Übliche Szenarien:
    <ul>
      <li><strong>Direktmarketing:</strong> Falls die betroffene Person der Zusendung von Marketing (E-Mails, Anrufen etc.) widerspricht, muss dieser Widerspruch bedingungslos berücksichtigt werden. `;
            if (hasPC7304) {
                process54Content += `Dies führt im Wesentlichen zum gleichen Ergebnis wie der Widerruf der Marketing-Einwilligung, auch wenn wir "berechtigte Interessen" für das Marketing geltend machen, verlangt die DSGVO, dass der Widerspruch berücksichtigt wird.`;
            } else {
                process54Content += `Die DSGVO schreibt vor, dass Widersprüche gegen Direktmarketing ohne Weiteres zu berücksichtigen sind.`;
            }
            process54Content += `</li>
    </ul>
    <ul>
      <li><strong>Andere Verarbeitungen auf Grundlage berechtigter Interessen/öffentlichen Interesses:</strong> Falls der Widerspruch gegen eine Verarbeitung eingelegt wird, die wir gemäß Artikel 6(1)(f) (berechtigte Interessen) oder (e) (öffentliche Aufgabe) durchführen, muss dieser bewertet werden. Die betroffene Person könnte einen spezifischen Grund (ihre individuelle Situation) anführen oder auch nicht. Standardmäßig muss bei einem Widerspruch die Verarbeitung der Daten für diesen Zweck <strong>pausiert</strong> werden, bis eine Lösung gefunden wurde. Informieren Sie die zuständigen Prozessverantwortlichen, die die Daten zurückhalten (entsprechend nicht weiterverarbeiten oder Entscheidungen auf Grundlage dieser Daten treffen, bis zur Klärung).</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Widerspruch bewerten (bei Fällen von berechtigten Interessen/öffentlichem Interesse):</strong> Der Datenschutzbeauftragte und die zuständigen Stakeholder prüfen, ob wir <strong>überwältigende berechtigte Gründe</strong> haben, die eine Weiterverarbeitung rechtfertigen, oder ob der Widerspruch akzeptiert werden sollte. Dies umfasst:
    <ul>
      <li>Überprüfung des Zwecks und der Notwendigkeit der Verarbeitung.</li>
    </ul>
    <ul>
      <li>Abwägung der Rechte und Gründe der betroffenen Person im Vergleich zu unseren Interessen.</li>
    </ul>
    <ul>
      <li>Beispielsweise, wenn die Person einem Profiling widerspricht, das sie betrifft, ob wir einen überwiegenden Bedarf haben; in den meisten Fällen <strong>überwiegen die Rechte der betroffenen Personen</strong>, es sei denn, die Daten sind kritisch (z.B. für rechtliche Ansprüche oder andere starke Gründe). Dokumentieren Sie diese Bewertung.</li>
    </ul>
    <ul>
      <li>Falls wir keine überwiegenden Gründe nachweisen können oder der Widerspruch der betroffenen Person begründet ist, wird der Widerspruch berücksichtigt (die Verarbeitung wird eingestellt und die Daten werden wahrscheinlich gelöscht oder isoliert).</li>
    </ul>
    <ul>
      <li>Falls wir der Meinung sind, dass wir starke Gründe haben, den Widerspruch abzulehnen, holen Sie rechtlichen Rat ein und dokumentieren Sie die Begründung sorgfältig (dies ist selten und riskant; beachten Sie, dass dieser Weg bei Direktmarketing nicht zulässig ist – in diesem Fall muss die Verarbeitung eingestellt werden).</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="5">
  <li><strong>Antwort an die betroffene Person:</strong> Basierend auf der Bewertung antworten Sie der betroffenen Person `;
            if (showDetailedLegalRequirements) {
                process54Content += `innerhalb des vorgeschriebenen Zeitrahmens (in der Regel einen Monat gemäß DSGVO Art. 12)`;
            } else {
                process54Content += `zeitnah`;
            }
            process54Content += `.
    <ul>
      <li>Falls der Widerspruch <strong>angenommen</strong> wird: Informieren Sie die betroffene Person, dass wir die Verarbeitung ihrer personenbezogenen Daten für den beanstandeten Zweck eingestellt haben. Erklären Sie relevante Details (z.B. "Wir werden Ihre Daten nicht mehr für [Zweck] verarbeiten."). Falls zutreffend, erwähnen Sie, dass die Daten gelöscht oder nur eingeschränkt aufbewahrt werden (z.B. in einer Sperrliste, um eine zukünftige Verarbeitung zu verhindern).</li>
    </ul>
    <ul>
      <li>Falls der Widerspruch <strong>abgelehnt</strong> wird (nicht akzeptiert aufgrund überwiegender Gründe): Geben Sie der betroffenen Person eine klare Erklärung, warum die Verarbeitung trotz ihres Widerspruchs fortgesetzt wird, und führen Sie die überwiegenden berechtigten Interessen oder gesetzlichen Verpflichtungen an, die dies rechtfertigen. Informieren Sie sie auch über ihr Recht, Rechtsmittel einzulegen (z.B. Kontaktaufnahme mit der Aufsichtsbehörde oder gerichtliche Überprüfung), falls sie nicht einverstanden ist. Diese Antwort sollte sorgfältig von der Rechtsabteilung überprüft werden.</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Ergebnis umsetzen:</strong>
    <ul>
      <li>Falls der Widerspruch akzeptiert wird, schließen Sie die Daten der betroffenen Person, auf die sich der Widerspruch bezieht, umgehend und dauerhaft aus der Verarbeitung aus. Beispielsweise, wenn sie der Aufnahme in einen Analysedatensatz widersprochen haben, entfernen oder anonymisieren Sie deren Daten in diesem Datensatz für zukünftige Verarbeitungen. Falls sie einem Datenaustausch widersprechen, stellen Sie die Weitergabe ihrer Daten ein. Stellen Sie sicher, dass alle beteiligten Teammitglieder/Systeme über diese Änderung informiert sind. Verhindern Sie auch eine erneute Aufnahme ihrer Daten (manchmal durch Markierung oder Aufnahme in eine Sperrliste).</li>
    </ul>
    <ul>
      <li>Falls der Widerspruch abgelehnt wird, stellen Sie sicher, dass die Verarbeitung vorsichtig fortgesetzt wird und prüfen Sie, ob Maßnahmen ergriffen werden können, um die Auswirkungen auf die betroffene Person zu minimieren (als Kulanz oder Risikominderung). Dieses Szenario sollte erneut überprüft werden, falls die betroffene Person den Widerspruch weiter eskaliert.</li>
    </ul>
  </li>
</ol>`;
            if (!isPC73051bOverridden) {
                process54Content += `
<ol type="1" class="numbered-list" start="8">
  <li><strong>Verfügbarkeit des Widerspruchsmechanismus sicherstellen:</strong> Stellen Sie sicher, dass in unseren Datenschutzhinweisen (Datenschutzerklärung, Website) deutlich darauf hingewiesen wird, dass betroffene Personen das Recht haben, Widerspruch einzulegen und wie sie dieses Recht ausüben können. Dies ist Teil der DSGVO-Konformität (Art. 21(4) verlangt, dass das Widerspruchsrecht ausdrücklich hervorgehoben wird). Beispielsweise wird in unserer Datenschutzerklärung und in den ersten Mitteilungen angegeben: "Wenn Sie der Verarbeitung Ihrer personenbezogenen Daten widersprechen möchten, kontaktieren Sie uns unter [Kontaktinformationen]." Intern stellen Sie sicher, dass bei Eingang solcher Kontakte das Personal den Widerspruch als formale Anfrage erkennt und entsprechend diesem Prozess zuweist.</li>
</ol>`;
            }
            process54Content += `
<p><strong>Output:</strong></p>
<p>Der <strong>Widerspruch der betroffenen Person ist regelkonform bearbeitet</strong>. Falls der Widerspruch berücksichtigt wurde, ergibt sich, dass die betreffende Verarbeitung <strong>die Daten der betroffenen Person nicht mehr umfasst</strong>, und eine Aufzeichnung dieser Änderung wird gespeichert. Falls der Widerspruch aufgrund einer Überschreibung nicht berücksichtigt wurde, liegt eine <strong>dokumentierte Begründung</strong> vor und die betroffene Person wird informiert. In allen Fällen kann die Organisation nachweisen, dass ein Mechanismus zur Einlegung eines Widerspruchs bereitgestellt wurde und die Anfrage sorgfältig bearbeitet wurde. Die Präferenz der betroffenen Person ist nun in unseren Systemen abgebildet (entweder als Opt-out oder als fortgesetzte Verarbeitung mit Begründung). Diese Vorgehensweise erfüllt sowohl die Anforderungen der ISO 27701-Kontrollen für Widersprüche als auch die DSGVO-Verpflichtungen zur Wahrung der Rechte der betroffenen Personen. `;
            if (showDetailedLegalRequirements) {
                process54Content += `Die Ergebnisse werden zur Aktualisierung der KPI-Metriken herangezogen (z.B. Anzahl der eingegangenen Widersprüche, Prozentsatz der innerhalb der Frist bearbeiteten Fälle). Zudem können Muster aus den Widersprüchen in Verbesserungen der Durchführung bestimmter Verarbeitungen oder Kommunikationsstrategien einfließen (als Feedbackschleife für Compliance und Vertrauen).`;
            }
            process54Content += `</p>`;
        }

        return `
<h1>SOP: Einwilligungsmanagement</h1>
<h2>1. Rechtliche/Norm-Grundlage</h2>
<ul>
${isoControlsText}
</ul>
<ul>
  <li><strong>EU-DSGVO-Anforderungen</strong> – Diese SOP stellt die Einhaltung der DSGVO-Bestimmungen in Bezug auf die Einwilligung sicher, insbesondere, aber nicht ausschließlich, <strong>Artikel 6(1)(a)</strong> (Einwilligung als rechtliche Grundlage für die Verarbeitung); <strong>Artikel 7(1)–(4)</strong> (Bedingungen für eine gültige Einwilligung, einschließlich der Nachweisbarkeit, der klaren Unterscheidbarkeit und der jederzeitigen Widerrufbarkeit der Einwilligung); <strong>Artikel 8(1)–(2)</strong> (elterliche Einwilligung für Kinder unter dem geltenden Alter und deren Überprüfung); und <strong>Artikel 9(2)(a)</strong> (ausdrückliche Einwilligung erforderlich für die Verarbeitung besonderer Kategorien von Daten). Die Einhaltung des <strong>Widerspruchsrechts</strong> (Artikel 21) wird ebenfalls durch den Widerspruchsmechanismus berücksichtigt.</li>
</ul>
<p><em>Rechtlicher Hinweis:</em> Diese SOP ist für die Rolle des <strong>Datenverantwortlichen</strong> verfasst. Sie behandelt die Pflichten des Verantwortlichen im Einwilligungsmanagement. (Datenverarbeiter holen die Einwilligung nicht direkt ein, können jedoch den Verantwortlichen bei der Erleichterung von Widerrufen oder Widersprüchen unterstützen; diese Pflichten der Verarbeiter fallen nicht in den Geltungsbereich dieser SOP.)</p>
${purposeContent}
${kpisContent}
${responsibilitiesContent}
<h2>5. Prozesse</h2>
<p>Jeder der folgenden Teilprozesse beschreibt, wie die Organisation als Datenverantwortliche die Einwilligung über ihren gesamten Lebenszyklus verwaltet. <strong>Inputs</strong> beschreiben Auslöser oder Voraussetzungen, <strong>Aktivitäten</strong> beschreiben die verfahrenstechnischen Schritte (was zu tun ist und wie), und <strong>Outputs</strong> beschreiben die erwarteten Ergebnisse oder erzeugten Aufzeichnungen.</p>
${process51Content}
${process52Content}
${process53Content}
${process54Content}
                    `;
    },

    /**
     * Überprüft, ob diese Richtlinie basierend auf den ausgewählten Kontrollen angezeigt werden soll
     * @param {Object} controlsMatrix - Die ausgewählte Risikokontrollmatrix
     * @returns {boolean} Ob diese Richtlinie angezeigt werden soll
     */
    shouldShow: (controlsMatrix) => {
        // Überprüfe, ob irgendeine einwilligungsbezogene Kontrolle ausgewählt wurde
        const consentControls = ['PC7203', 'PC7204', 'PC7302', 'PC7304'];

        // Gibt true zurück, wenn bei irgendeiner Verarbeitungstätigkeit mindestens eine Einwilligungskontrolle aktiviert ist
        return Object.values(controlsMatrix).some(activityControls =>
            consentControls.some(control => activityControls[control] === true)
        );
    },

    /**
     * Gibt den Einleitungstext für diese Richtlinie zurück
     * @returns {String} Einleitungstext zur Anzeige
     */
    getIntroText: () => {
        return 'Diese Standardarbeitsanweisung (SOP) legt den Prozess zur Einholung, Erfassung, Verwaltung und zum Widerruf der Einwilligung für die Verarbeitung personenbezogener Daten fest.';
    }
};

export default SOPConsentManagement;
