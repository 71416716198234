// src/components/wizard_components/DataPrivacyWizard/DPIADataFlow.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

// Import the FlowChart component
import FlowChart from '../../../components/FlowChart';
import { useFlowChart } from '../../../components/FlowChart/hooks/useFlowChart';

const DPIADataFlow = ({ formik, toggleDrawer }) => {
  const { t } = useTranslation();
  const { loadFlowChart, saveFlowChart } = useFlowChart();
  
  // Track the flowchart ID and loading state
  const [flowChartId, setFlowChartId] = useState(formik.values.dataFlow?.flowChartId);
  const [isLoading, setIsLoading] = useState(false);
  
  // Keep track of changes for better form integration
  const [hasChanges, setHasChanges] = useState(false);
  
  // Load existing flowchart data if we have an ID
  useEffect(() => {
    const fetchFlowChart = async () => {
      if (flowChartId) {
        setIsLoading(true);
        try {
          await loadFlowChart(flowChartId);
        } catch (error) {
          console.error('Error loading flow chart:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFlowChart();
  }, [flowChartId, loadFlowChart]);

  // Save the flowchart
  const handleSaveFlowChart = async (nodes, edges) => {
    try {
      // Prepare the chart data
      const chartData = {
        name: `Data Flow - ${formik.values.processingActivityName || 'Processing Activity'}`,
        description: 'Data flow diagram for DPIA processing activity',
        nodes,
        edges,
        reference_type: 'dpia',
        reference_id: formik.values.processingActivityId || formik.values.id,
        is_template: false
      };
      
      // If we already have an ID, update the existing chart
      if (flowChartId) {
        chartData.id = flowChartId;
      }
      
      // Save the flowchart
      const savedChart = await saveFlowChart(chartData);
      
      if (savedChart && savedChart.id) {
        // Update formik with the flowchart ID
        formik.setFieldValue('dataFlow.flowChartId', savedChart.id);
        setFlowChartId(savedChart.id);
        setHasChanges(false);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error saving flow chart:', error);
      return false;
    }
  };

  // Handle changes in the flowchart
  const handleFlowChartChange = () => {
    setHasChanges(true);
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4">
        {t('DataPrivacyWizard.dpia.dataFlow.title')}
        <button
          type="button"
          onClick={() => toggleDrawer(
            t('DataPrivacyWizard.dpia.dataFlow.infoTitle'),
            t('DataPrivacyWizard.dpia.dataFlow.infoContent')
          )}
          className="ml-1 text-gray-400 hover:text-gray-500"
        >
          <QuestionMarkCircleIcon className="h-4 w-4 inline" aria-hidden="true" />
        </button>
      </h2>
      
      <p className="text-gray-600 mb-4">
        {t('DataPrivacyWizard.dpia.dataFlow.description')}
      </p>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64 bg-gray-50 border border-gray-200 rounded-lg">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary-color"></div>
        </div>
      ) : (
        <FlowChart
          id={flowChartId}
          height="500px"
          onSave={handleSaveFlowChart}
          onChange={handleFlowChartChange}
        />
      )}

      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id="saveDataFlow"
              name="dataFlow.completed"
              type="checkbox"
              checked={formik.values.dataFlow?.completed || false}
              onChange={(e) => formik.setFieldValue('dataFlow.completed', e.target.checked)}
              className="h-4 w-4 text-primary-color border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="saveDataFlow" className="font-medium text-gray-700">
              {t('DataPrivacyWizard.dpia.dataFlow.confirmCompletion')}
            </label>
            <p className="text-gray-500">
              {t('DataPrivacyWizard.dpia.dataFlow.completionHelp')}
            </p>
          </div>
        </div>
        
        {hasChanges && (
          <div className="text-sm text-amber-600">
            {t('DataPrivacyWizard.dpia.dataFlow.unsavedChanges', 'You have unsaved changes')}
          </div>
        )}
      </div>
    </div>
  );
};

export default DPIADataFlow;