import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const CategoryItem = ({ 
  category, 
  selected, 
  onSelect, 
  getIcon = null,
  userLang = 'en'
}) => {

  // Determine the correct display name based on item type and language
  let displayName = '';
  let displayDescription = '';
  
  // Check if this is a type item by looking for asset_type property
  if (category.asset_type || category.werttyp) {
    displayName = userLang === 'de' ? category.werttyp : category.asset_type;
  } 
  // Check if this is a class item
  else if (category.asset_class || category.wertklasse) {
    displayName = userLang === 'de' ? category.wertklasse : category.asset_class;
  }
  // Check if this is a category item
  else if (category.asset_categories || category.wertkategorie) {
    displayName = userLang === 'de' ? category.wertkategorie : category.asset_categories;
  }

  // Get description in the correct language
  displayDescription = userLang === 'de' ? category.beschreibung : category.description;

  // Fallback for empty names (shouldn't normally happen)
  if (!displayName) {
    displayName = userLang === 'de' ? 'Andere' : 'Other';
  }

  return (
    <div 
      className={`flex items-center p-3 border-b last:border-b-0 cursor-pointer hover:bg-secondary-color/10 ${
        selected ? 'bg-secondary-color/20' : ''
      }`}
      onClick={() => onSelect(category)}
    >
      <div className="flex items-center flex-1 overflow-hidden">
        {getIcon && getIcon(category) ? (
          <img 
            src={getIcon(category)} 
            alt={displayName}
            className="w-6 h-6 mr-3 flex-shrink-0"
          />
        ) : (
          <span className="w-6 h-6 mr-3 inline-block bg-gray-200 rounded-md flex-shrink-0"></span>
        )}
        <div className="flex-1 min-w-0">
          <div className="font-medium text-gray-900">
            {displayName}
          </div>
          {displayDescription && (
            <div className="text-xs text-gray-500 truncate hover:overflow-visible">
              <span className="block truncate hover:overflow-x-auto hover:whitespace-normal" style={{ transition: 'all 0.3s' }}>
                {displayDescription}
              </span>
            </div>
          )}
        </div>
      </div>
      {selected && (
        <CheckIcon className="h-5 w-5 text-secondary-color flex-shrink-0 ml-2" />
      )}
    </div>
  );
};

export default CategoryItem;