// src/components/wizard_components/DataPrivacyWizard/SOPVendorProcessorManagement.js
import React from 'react';

// Helper function to check if a control is applied
const hasControlApplied = (controlsMatrix, controlId) => {
    if (!controlsMatrix || typeof controlsMatrix !== 'object') {
        return false;
    }
    return Object.values(controlsMatrix).some(activityControls => {
        if (!activityControls || typeof activityControls !== 'object') {
            return false;
        }
        return activityControls[controlId] === true;
    });
};

// Helper function to check if an execution criteria is overridden
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);
    if (!isControlApplied) {
        return true;
    }
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        return riskControlOverrides[controlId].includes(criteriaId);
    }
    return false;
};

const SOPVendorProcessorManagement = {

    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo, role) => {
        const orgName = entityInfo.name || '[Your Organization Name]';

        // --- Determine relevant controls based on role ---
        const isController = role === 'Controller';
        const isProcessor = role === 'Processor';

        // --- Controller Specific Controls & Criteria ---
        const hasPC7206 = isController && hasControlApplied(controlsMatrix, 'PC7206'); // Contracts with PII processors
        // PC7207 (Joint Controller) is primarily handled in Governance SOP for setup,
        // but the *operational coordination* part (Process 5.6 in HTML) is relevant here.
        // We assume if they are a controller managing vendors, they might also be a joint controller.
        // For simplicity, we won't gate Process 5.6 based on PC7207 being active, but include it if Controller role.

        // --- Processor Specific Controls & Criteria ---
        const hasPC8201 = isProcessor && hasControlApplied(controlsMatrix, 'PC8201'); // Customer agreement
        const hasPC8204 = isProcessor && hasControlApplied(controlsMatrix, 'PC8204'); // Infringing instruction
        const hasPC8205 = isProcessor && hasControlApplied(controlsMatrix, 'PC8205'); // Customer obligations support
        const isPC82051aOverridden = isProcessor && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC8205', '1a'); // Audit contribution allowed
        const hasPC8506 = isProcessor && hasControlApplied(controlsMatrix, 'PC8506'); // Disclosure of subcontractors
        const hasPC8507 = isProcessor && hasControlApplied(controlsMatrix, 'PC8507'); // Engagement of subcontractors
        const hasPC8508 = isProcessor && hasControlApplied(controlsMatrix, 'PC8508'); // Change of subcontractor

        // --- Dynamic Numbering ---
        let sectionCounter = 0;
        let subSectionCounters = {};

        const renderSectionHeading = (title) => {
            sectionCounter++;
            subSectionCounters[sectionCounter] = 0;
            return `<h2 style="font-size: 1.5rem; margin-top: 1.5rem;">${sectionCounter}. ${title}</h2>`;
        };

        const renderSubSectionHeading = (title) => {
            subSectionCounters[sectionCounter]++;
            return `<h3 style="font-size: 1.25rem; margin-top: 1.25rem;">${sectionCounter}.${subSectionCounters[sectionCounter]} ${title}</h3>`;
        };

        // Step rendering
        const renderStep = (title, content) => {
             if (!title) return content || '';
             // Using simple <p><strong> wrapper for step titles as in source HTML structure
             return `<p><strong>${title}</strong></p>${content}`;
        };


        let htmlContent = `<h1>Vendor/Processor Management SOP – Data ${role}</h1>`;

        // --- Section 1: Legal Basis ---
        let legalBasisContent = '';
        if (isController) {
            legalBasisContent += `<ul class="bulleted-list">`;
             if (hasPC7206) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC7206</strong> – <em>Contracts with PII Processors</em>: Requires entering into binding contracts with all data processors, ensuring they implement all necessary privacy controls (per ISO 27701 Annex B) in processing personal data.</li>`;
             // Added GDPR Art 26 reference here, as Process 5.6 covers joint controller ops
             legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 26(1)</strong> – <em>Joint Controllers</em>: When sharing control of personal data with another party, a transparent arrangement must define each controller’s responsibilities (especially regarding data subject rights and privacy notices) and make the essence of the arrangement available to data subjects.</li>`;
             legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(1)</strong> – <em>Processor Due Diligence</em>: Controllers may only use processors providing sufficient guarantees to meet GDPR requirements and protect data subjects’ rights (implying thorough vendor risk assessment and selection).</li>`;
             if (hasPC7206) legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(3)</strong> – <em>Data Processing Agreement (DPA)</em>: Processing by a processor must be governed by a written contract that includes all mandatory clauses (e.g. processing only on documented instructions, confidentiality, security measures, sub-processor conditions, assistance with obligations, deletion/return of data, and audit support).</li>`;
             if (hasPC7206) legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(4)</strong> – <em>Sub-processor Flow-down</em>: If a processor engages sub-processors, the processor must impose the same data protection obligations on them via contract. The initial processor remains fully liable to the controller for any sub-processor’s compliance failures, so controllers must ensure this chain of accountability in agreements.</li>`;
             legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 29</strong> – <em>Instruction of Processor</em>: The processor (and any person acting under the authority of the controller or processor) shall only process personal data on the controller’s documented instructions. Controllers must therefore provide clear instructions and ensure processors do not deviate from them.</li>`;
             legalBasisContent += `</ul>`;

        } else { // Processor
            legalBasisContent += `<ul class="bulleted-list">`;
            if (hasPC8201) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8201</strong> – <em>Customer Agreement</em>: As a PII processor, the organization must ensure contracts with its clients (controllers) address how it will assist the client in meeting their GDPR obligations, considering the nature of processing and information available to us. This aligns with GDPR requirements to support the controller (e.g. helping with data subject requests and security measures).</li>`;
            if (hasPC8204) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8204</strong> – <em>Infringing Instruction</em>: If the organization (as processor) believes an instruction from a controller violates applicable data protection laws, it is required to inform the controller. This control reflects GDPR Art. 28(3)(h), which mandates processors to alert controllers if their instructions would lead to non-compliance.</li>`;
            if (hasPC8205) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8205</strong> – <em>Customer’s Obligations Support</em>: The processor must provide the controller with appropriate information to demonstrate compliance and to fulfill the controller’s own obligations. For example, providing audit reports or answers to due diligence questionnaires so the controller can show regulators that GDPR requirements are met (mirrors GDPR Art. 28(3)(h) on providing all info needed for compliance verification).</li>`;
            if (hasPC8506) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8506</strong> – <em>Disclosure of Subcontractors</em>: The processor must disclose any sub-processors (subcontractors) it uses to the controller <strong>before</strong> using them. This supports transparency and allows controllers to be aware of all parties processing their data (related to GDPR Art. 28(2) requiring authorization for sub-processors).</li>`;
            if (hasPC8507) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8507</strong> – <em>Engagement of Subcontractors</em>: The organization shall only engage sub-processors according to what’s agreed in the customer contract. In practice, this means not using any sub-processor unless the controller’s DPA allows it (specific or general consent given) and abiding by any conditions set (GDPR Art. 28(2) & 28(3)(d) – respecting conditions for sub-processor engagement).</li>`;
            if (hasPC8508) legalBasisContent += `<li style="list-style-type:disc"><strong>ISO 27701 Control PC8508</strong> – <em>Changes of Subcontractors</em>: If using general authorization, the processor must inform the controller of any intended changes to sub-processors (additions or replacements), giving the controller the chance to object. This directly implements GDPR Art. 28(2) second sentence.</li>`;
            legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(2)</strong> – <em>Sub-processor Authorization</em>: A processor shall not engage another processor without the controller’s prior specific or general written authorization. Our organization must obtain necessary approvals from the controller before outsourcing any processing to a sub-processor, and document such authorizations.</li>`;
            legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(3)(a)</strong> – <em>Process Only on Instructions</em>: We (as processor) may only process personal data on documented instructions from the controller (including with regard to data transfers to third countries), unless required by law. This underpins all our operations – we do not determine purposes or use data for our own benefit outside the controller’s mandate.</li>`;
            if (hasPC8204 || hasPC8205) legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(3)(h)</strong> – <em>Demonstrating Compliance & Informing of Violations</em>: We must make available to the controller all information necessary to demonstrate compliance with our obligations and allow for audits. Additionally, we must immediately inform the controller if, in our opinion, an instruction infringes GDPR or other data protection provisions.</li>`;
            if (hasPC8507) legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 28(4)</strong> – <em>Flow-down to Sub-processors & Liability</em>: If we engage sub-processors, we must impose the same data protection obligations on them by contract (e.g. the sub-processor must also only act on our instructions which originate from the controller, maintain security, etc.). We remain fully liable to the controller for any sub-processor’s failure to meet its data protection obligations.</li>`;
            legalBasisContent += `<li style="list-style-type:disc"><strong>GDPR Article 29</strong> – <em>Authorized Persons</em>: Our personnel or any sub-processor’s personnel who access the controller’s data can only do so on the controller’s instructions (as delegated to us). This emphasizes internal controls that no processing occurs beyond what the controller has authorized.</li>`;
            legalBasisContent += `</ul>`;
        }
        if (legalBasisContent) {
            htmlContent += renderSectionHeading('Legal/Standard Basis') + legalBasisContent;
        }

        // --- Section 2: Purpose ---
        let purposeContent = '';
        if (isController) {
            purposeContent += `<p>This SOP establishes a consistent approach for managing third-party <strong>vendors</strong> acting as <strong>data processors</strong> for the organization, as well as handling any <strong>joint controller</strong> arrangements. It aims to ensure:</p>
                <ul class="bulleted-list"><li style="list-style-type:disc">All vendors who process personal data on the organization’s behalf are vetted, contracted, and managed in compliance with GDPR and ISO 27701 requirements, thereby safeguarding data subject rights and organizational compliance.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc">Roles and responsibilities are clearly defined for engaging and overseeing processors, including how to conduct due diligence, implement Data Processing Agreements, monitor ongoing compliance, and address sub-processor usage.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc">Joint controllership scenarios are handled with proper agreements outlining each party’s duties, maintaining transparency and accountability.<p>Ultimately, this procedure protects the organization by minimizing privacy risks in vendor relationships and ensuring all controller obligations (legal, security, and privacy-related) are fulfilled throughout the vendor lifecycle.</p></li></ul>`;
        } else { // Processor
            purposeContent += `<p>This SOP defines how our organization, when acting as a <strong>data processor</strong> for client controllers, manages those relationships and any <strong>sub-processors</strong> we use. The goals are:</p>
                <ul class="bulleted-list"><li style="list-style-type:disc">To ensure full compliance with our legal and contractual obligations as a processor under GDPR and ISO 27701. This includes processing personal data strictly per the controller’s instructions, maintaining confidentiality and security, assisting the controller with compliance, and being transparent about any sub-processors.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc">To provide a clear, step-by-step process for our teams to follow from the moment we engage with a client (negotiating a Data Processing Agreement) through the service delivery, to termination of the contract and beyond (e.g. post-contract data handling).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc">To protect the privacy rights of data subjects by rigorously managing and monitoring processing activities, thereby upholding the trust that client controllers place in us as their chosen processor.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc">To mitigate risks associated with handling client data, such as unauthorized processing or data breaches, by implementing strong oversight and communication procedures (especially regarding sub-processor management and handling potentially non-compliant instructions).<p>By following this SOP, the organization will maintain consistent standards in vendor/processor management, avoid breaches of contract or law, and be able to demonstrate accountability to both clients and regulators.</p></li></ul>`;
        }
         if (purposeContent) {
            htmlContent += renderSectionHeading('Purpose') + purposeContent;
         }

        // --- Section 3: KPIs ---
         let kpisContent = '';
         let kpiList = '';
         if (isController) {
             if (hasPC7206) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>100% Vendor Contracts with DPA</strong> – All vendors processing personal data have a signed Data Processing Agreement incorporating GDPR-required clauses before any data is shared.</li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Vendor Due Diligence Completion</strong> – Required privacy and security risk assessments are completed for <strong>100% of new processor engagements</strong> prior to approval.</li></ul>`;
             // Joint Controller KPI - Assuming relevant if Controller
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Joint Controller Arrangements</strong> – <strong>100% of applicable data sharing relationships</strong> where the organization is a joint controller have a documented joint controller agreement, and the <em>essence</em> of each arrangement is available to data subjects (e.g. via privacy notice).</li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Annual Vendor Reviews</strong> – Critical or high-risk processors are reviewed <strong>at least annually</strong> for GDPR/ISO 27701 compliance (e.g. checking for up-to-date security certifications or audit reports).</li></ul>`;
             if (hasPC7206) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Sub-processor Approval Rate</strong> – All processor-initiated sub-processor changes are reviewed, with <strong>100% of sub-processors used by vendors explicitly authorized or not objected to</strong> within the agreed notice period.</li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Incident Involvement</strong> – Number of vendor-related personal data incidents or breaches reported. <em>(Target: minimized; all such incidents handled per incident response procedures and contractual obligations.)</em></li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Timely Offboarding</strong> – On contract termination, <strong>100% of vendors</strong> confirm data deletion or return within the required timeframe.</li></ul>`;
         } else { // Processor
             if (hasPC8201) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Contract Coverage</strong> – 100% of client engagements involving personal data have a signed Data Processing Agreement (DPA) or equivalent contract <em>in place before</em> any data processing begins. <em>(Measured by periodic contract audits.)</em></li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Instruction Compliance</strong> – 0 incidents of processing personal data beyond the scope of the controller’s documented instructions. <em>(Measured by internal audits of projects and any client complaints; target is zero unauthorized processing events.)</em></li></ul>`;
             if (hasPC8506 || hasPC8507 || hasPC8508) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Sub-processor Approval</strong> – 100% of sub-processors used have been authorized by the respective controllers. <em>(Measured by cross-checking sub-processor lists against obtained consents/notifications for each client; target is full compliance with no unapproved sub-processors.)</em></li></ul>`;
             if (hasPC8507) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Sub-processor Contracts</strong> – 100% of active sub-processors have a contract with us that mirrors required GDPR obligations (flow-down of clauses). <em>(Measured by an annual review of sub-processor agreements; all must include standard data protection terms.)</em></li></ul>`;
             // Removed hasPC8501 check here, kept the KPI as it relates broadly to controller comms
             if (hasPC8204 || hasPC8506 || hasPC8508) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Timely Controller Notification</strong> – All required notifications to controllers (e.g. breach notifications, sub-processor changes, or informing about illegal instructions) are made within contractual or regulatory timeframes. <em>(Measured by tracking each event’s notification time vs. deadline; target 100% on-time.)</em></li></ul>`;
             if (hasPC8205) kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Audit and Info Requests</strong> – Percentage of controller requests for information or audits fulfilled successfully. <em>(Target 100%: every formal request by a client for compliance information, e.g. security questionnaires or audit rights exercise, is responded to satisfactorily within agreed timelines.)</em></li></ul>`;
             kpiList += `<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Deletion/Return Compliance</strong> – 100% of client data is returned or deleted as required at contract termination, with confirmation provided. <em>(Measured by termination checklist completion for each ended contract; target is no lingering client data beyond agreed retention.)</em></li></ul>`;
         }
         if (kpiList) {
             kpisContent += kpiList;
             htmlContent += renderSectionHeading('Key Performance Indicators (KPIs)') + kpisContent;
         }

        // --- Section 4: Responsibilities ---
        let responsibilitiesContent = '';
        if (isController) {
            responsibilitiesContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Business Unit Owner</strong> – Initiates the vendor engagement process by identifying the need to use a third-party service (processor). Ensures the vendor’s scope is clearly defined and only provides the vendor access to personal data after all onboarding steps (contract, due diligence) are completed. Monitors the vendor’s performance and compliance in day-to-day operations.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Procurement/Vendor Management</strong> – Facilitates initial screening and due diligence for prospective processors (including obtaining security questionnaires, compliance attestations, and references). Ensures no vendor is engaged without approval from Privacy and Security stakeholders. Maintains a centralized <strong>Vendor Register</strong> of all approved data processors, their contract dates, and any sub-processors they use.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Protection Officer (DPO)/Privacy Office</strong> – Oversees privacy compliance in vendor relationships. Reviews and approves Data Processing Agreements and joint controller arrangements for legal sufficiency. Ensures GDPR Article 28 requirements and ISO 27701 controls are addressed in contracts ${hasPC7206 ? '(or justifies any deviation)' : ''}. Provides guidance on privacy risk assessments and whether a Data Protection Impact Assessment (DPIA) is needed before onboarding a vendor. Monitors ongoing compliance, including handling any incidents, data subject requests involving processors, or vendor’s requests regarding potentially unlawful instructions.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Legal Counsel</strong> – Drafts or reviews the contractual terms with vendors. Ensures the DPA includes all required clauses (e.g. those from GDPR Art. 28(3)) and that liability, indemnity, and confidentiality provisions protect the organization. If the vendor provides their own DPA or negotiates terms, Legal ensures equivalence with our standards. Also prepares or reviews joint controller agreements when applicable under Art. 26.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>IT Security/Information Security Team</strong> – Assesses the technical and organizational security measures of the vendor to verify they provide “sufficient guarantees” (GDPR 28(1)). Reviews security questionnaires or audit reports (e.g. ISO 27001/27701 certifications, SOC 2 reports) from the vendor. Recommends approval or required improvements. Continues to monitor vendor security posture (e.g. annual security reviews, penetration test results) and works with the vendor to remediate any identified risks.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Compliance/Audit Team</strong> (if separate from DPO) – May conduct or coordinate periodic audits of high-risk vendors as allowed by the DPA (on-site audits or review of policies/procedures). Verifies that the vendor and any sub-processors adhere to contract terms and privacy controls. Shares findings with the DPO, business owner, and management.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Senior Management</strong> – Ultimately accountable for third-party risk management. Approves high-risk vendor engagements and resources for oversight. In joint controller situations, management ensures that strategic partnerships have proper agreements and that the organization is willing to accept the allocated responsibilities. Addresses escalations (e.g. if a critical vendor is non-compliant or an instruction conflict arises). Also responsible for signing joint controller arrangements or high-value DPAs when required.</li></ul>`;
        } else { // Processor
            responsibilitiesContent += `
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Account/Project Manager</strong> – For each client (controller), an assigned manager (or team lead) is responsible for the daily oversight of that account’s data processing activities. They ensure operations stick to the documented instructions and scope. They serve as the primary liaison with the client for operational matters. If the client provides new instructions or requests, the Account Manager makes sure these are documented and communicated internally (and that we have the capacity and legal basis to comply). They also monitor deliverables and ensure no work is done without authorization. In case of any uncertainty about an instruction or a request to go beyond the agreed scope, the Account Manager pauses execution and consults the Privacy Officer/Legal (per the “infringing instruction” protocol).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Protection Officer (DPO)/Privacy Officer</strong> – Oversees compliance for all processing we do on behalf of clients. Reviews and approves Data Processing Agreements from our side. Provides guidance to Account Managers and other teams on what the controller’s instructions allow or prohibit. ${hasPC8204 ? 'If an instruction is potentially non-compliant, the DPO analyzes the situation and advises if we must refuse or seek clarification, and drafts the notice to the controller explaining the concern (fulfilling Art. 28(3)(h)).' : ''} The DPO also coordinates responses to any data protection queries or audits from the client. Internally, the DPO ensures that all staff are trained on their obligations (confidentiality, following instructions) and that internal procedures align with each client contract. They maintain records of processing activities we perform as a processor, as required by GDPR Art. 30(2).</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Legal Counsel</strong> – Negotiates contract terms with clients, including the DPA. Ensures contracts do not contradict GDPR or impose impossible requirements. For example, Legal will confirm that liability clauses are acceptable and that any request by a client to use certain sub-processors or transfer mechanisms are lawful. They maintain template clauses for consistency. Legal also reviews sub-processor contracts we sign to ensure back-to-back obligations. In case of disputes or changes (like a client objecting to a sub-processor), Legal works with the DPO and Account Manager on a resolution in line with contractual rights.</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Information Security Team</strong> – Implements and maintains the technical and organizational measures that we are contractually and legally required to have. They ensure our infrastructure meets the security standards promised (encryption, access control, etc.). ${hasPC8205 && !isPC82051aOverridden ? 'They also prepare documentation about our security (to share with controllers on request) and support any security audits clients perform.' : (hasPC8205 ? 'They also prepare documentation about our security (to share with controllers on request).' : '')} If a data breach occurs on our side, Security investigates and works with the DPO to inform the controller and remediate. The Security Team also evaluates any new sub-processor’s security (when we consider onboarding a sub-processor, Security reviews their practices).</li></ul>
                ${(hasPC8506 || hasPC8507 || hasPC8508) ? '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Sub-Processor Management (Vendor Management)</strong> – If our organization uses third-party services (sub-processors) to fulfill client work, the team responsible for vendor management will vet and onboard those sub-processors similarly to how we are vetted by our clients. They must obtain necessary approvals from our clients per contract. They maintain an updated <strong>Sub-processor List</strong> for each client or generally and ensure it’s available to clients (often via website or upon request). They handle notifications to all relevant controllers when a new sub-processor is proposed or when changes occur, coordinating with the Account Managers (who may communicate directly with client representatives as needed). They also ensure each sub-processor has a DPA with us that meets GDPR requirements, and they monitor sub-processor compliance (requesting their audits/certifications, etc., and passing relevant assurances on to our clients).</li></ul>' : ''}
                <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Operations Team Members</strong> – All employees or contractors processing personal data under a client’s instructions (e.g. analysts, engineers, customer support handling client data) are responsible for following the procedures set out for that client. They must <strong>not deviate</strong> from approved processes or use the data for any purpose except what the client has specified. They also must maintain confidentiality – only authorized staff should access client data, and all such staff should have confidentiality agreements or clauses in employment contracts (which HR ensures). If operations staff receive direct requests from a client that involve personal data (e.g. “could you pull this data set for me”), they should ensure it’s within scope and ideally route it through the Account Manager or written instruction channel. They are the front line in noticing if something seems off (like a client asking for something that wasn’t agreed); in such cases, they should escalate to the Account Manager or Privacy team. Operations also execute the data deletion or return at end of contract as instructed by the Privacy/Account Manager.</li></ul>`;
        }
        if (responsibilitiesContent) {
             htmlContent += renderSectionHeading('Responsibilities') + responsibilitiesContent;
         }


        // --- Section 5: Detailed Processes ---
        let processesHtml = '';
        let processSectionRendered = false;

        if (isController) {
            // Process 5.1 (Controller)
             processSectionRendered = true; // Assume always relevant for Controller
             processesHtml += renderSubSectionHeading('Vendor Selection and Privacy Risk Assessment');
             processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Business need for outsourcing or service requiring a vendor who will handle personal data (e.g. cloud service provider, data analytics firm). Initial vendor information (service description, proposed data to be shared) and any vendor-provided security/compliance documentation.</li></ul>');
             processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">The Business Unit Owner and Procurement initiate a <strong>vendor assessment</strong>. The Privacy Officer and IT Security evaluate the vendor’s suitability:
                    <ul class="bulleted-list"><li style="list-style-type:circle">Issue a <strong>Privacy and Security Questionnaire</strong> to the vendor covering GDPR and security compliance (e.g. asking about their data protection measures, certifications, past breaches, sub-processors, etc.).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Assess Sufficient Guarantees</strong> (GDPR 28(1)): Review the vendor’s responses and certifications (ISO 27001/27701, PCI-DSS, etc.), data protection policies, and any available audit reports. Identify any gaps or high risks (e.g. vendor lacks encryption at rest, or is missing a breach notification process).</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">Perform a <strong>Data Protection Impact Assessment (DPIA)</strong> if the processing is high-risk (e.g. large scale or sensitive data involved), or document why a DPIA is not required.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">Determine if the vendor will engage sub-processors; if yes, request a list of sub-processors and their locations to evaluate cross-border transfer implications and ensure they are acceptable.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">Evaluate <strong>Joint Controller Status</strong>: If the vendor would independently determine purposes or use the data beyond our instructions, this may indicate a joint controller or independent controller situation rather than a processor – flag to Legal/DPO for proper classification and arrangement if needed.</li></ul>
                    <ul class="bulleted-list"><li style="list-style-type:circle">Rate the vendor’s risk (e.g. low/medium/high). For any deficiencies, require mitigation (e.g. the vendor must implement specific controls or agree to contract clauses to improve security).</li></ul>
                </li></ul>`);
             processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc">A <strong>Vendor Risk Assessment Report</strong> is completed, documenting the vendor’s compliance posture and any conditions for engagement. The report includes a recommendation to approve or reject the vendor (or approve with remediation steps). High-risk findings are communicated to Senior Management for risk acceptance or rejection. Only if the vendor is deemed to provide sufficient guarantees and acceptable risk will the process continue to contracting.</li></ul>');

            // Process 5.2 (Controller) - Conditional on PC7206
            if (hasPC7206) {
                 processesHtml += renderSubSectionHeading('Contracting and Data Processing Agreement (DPA)');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Completed risk assessment with approval to proceed, and a clear definition of the processing scope (types of personal data, processing activities, purposes, duration, etc.). A <strong>DPA template</strong> (our standard) or the vendor’s proposed DPA. For joint controller scenarios, a draft Joint Controller Agreement or data sharing agreement template.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">The Legal Counsel (with DPO input) formalizes the arrangement in a contract before any personal data is shared:
                        <ul class="bulleted-list"><li style="list-style-type:circle">Prepare a <strong>Data Processing Agreement</strong> that meets GDPR Art. 28(3) requirements. At minimum, ensure it includes: the subject matter and duration of processing; nature and purpose of processing; types of personal data and categories of data subjects involved; and the obligations and rights of the controller.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Include all required clauses: the vendor (processor) will <em>only process personal data on documented instructions</em> from our organization (controller) ; ensure persons processing the data are bound by confidentiality ; implement appropriate security measures per Article 32; <strong>not engage sub-processors without authorization</strong> and under the same terms ; assist the controller with data subject requests and compliance obligations (e.g. Articles 32-36 on security, breach notification, DPIA) ; at end of contract, return or delete data as directed ; and make available all information necessary to demonstrate compliance and allow for audits .</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Ensure <strong>sub-processor terms</strong>: The contract must require the vendor to obtain our prior specific or general written authorization before appointing any sub-processor (GDPR 28(2)), and if general authorization is given, they must inform us of any changes (additions or replacements) so we can object if needed. Include a clause that the vendor must flow down all the above data protection obligations to any approved sub-processor (per Art. 28(4)), and remain liable for them.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Incorporate <strong>breach notification</strong> requirements (e.g. processor must inform controller without undue delay if they become aware of a personal data breach) and any service levels for such notification.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">If relevant, include <strong>international transfer safeguards</strong> (e.g. Standard Contractual Clauses) if the processor or its sub-processors will transfer personal data outside the EEA.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">For joint controller situations: Draft a <strong>Joint Controller Agreement</strong> (JCA) outlining each party’s responsibilities as per GDPR Art. 26. Specify how obligations are split – e.g. which party handles fulfilling data subject rights requests, who informs individuals, how security measures and breaches are managed, etc. Also specify a contact point for data subjects if appropriate. Ensure the essence of this arrangement can be provided to individuals (usually via privacy notice summary).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Negotiate with the vendor as needed to finalize terms. The DPO/Privacy should approve any deviations from standard clauses, documenting why a control from ISO 27701 Annex B might be omitted or altered (per ISO 27701 7.2.6 guidance, any such omission must be justified).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle">Once terms are agreed, have the contract signed by authorized signatories of both parties. For joint controller arrangements, ensure both controllers sign the arrangement document.</li></ul>
                    </li></ul>`);
                processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Executed Agreements</strong> – A signed DPA (for processor relationships) and/or a Joint Controller Agreement (for applicable cases) is in place <strong>before</strong> personal data is shared. These agreements are stored in the central repository. The Vendor Register is updated with the contract date, expiration/renewal date, and summary of agreed provisions (especially noting if general or specific sub-processor authorization was given, and any special instructions).</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.3 (Controller)
            if (isController) { // Onboarding relevant if engaging vendors
                 processesHtml += renderSubSectionHeading('Vendor Onboarding and Instruction');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Signed contract(s) and defined processing details; any controller instructions or operational documents to be given to the vendor (e.g. data import schedules, security policies to follow).</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">After contracting, formally onboard the vendor so they can start processing under controlled conditions:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Documented Instructions:</strong> Provide the processor with documented instructions for the data processing. This may include an onboarding guide or written instructions detailing how they should process the data, frequency of data transfers, permitted tools or systems, retention requirements, etc. (This fulfills the requirement of ensuring the processor has only our authorized instructions to follow, aligning with Art. 29). Both parties should agree on a written Statement of Work or similar that encapsulates these instructions to avoid ambiguity.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Access Control:</strong> Work with IT to provision secure access to the minimum necessary data. Ensure a non-disclosure agreement is in place if not covered by the contract, and that the vendor’s personnel who will access our data have been vetted and informed of their confidentiality obligations.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Kick-off Meeting:</strong> Hold a meeting with the vendor’s team to clarify roles and communication channels. Reiterate key contract terms: e.g. “you must not process data outside the purposes we’ve defined,” “notify us immediately of any suspected data breach,” etc. Confirm they understand the procedure to request clarification if any instruction might conflict with law (as per Art. 28(3)(h), though from the controller side we ensure they know we expect to be informed rather than them blindly following an unlawful instruction).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Record in RoPA:</strong> Update the organization’s Record of Processing Activities (RoPA) to reflect that personal data will be processed by this vendor as a processor, including details of processing purpose, categories of data, transfers, and safeguards. Also log the joint controller arrangement in records, if applicable, noting the distribution of duties.</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Vendor Onboarded</strong> – The processor is now formally authorized to process data under our instructions. There is a clear record of what has been instructed. The vendor’s access is set up with least privilege. Internal records and documentation are updated to show this new processing arrangement.</li></ul>');
                 processSectionRendered = true;
             }

            // Process 5.4 (Controller) - Conditional on PC7206
            if (hasPC7206) { // Monitoring depends on having a contract
                 processesHtml += renderSubSectionHeading('Ongoing Monitoring and Compliance Management');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Ongoing service period where the vendor is processing data. Sources of input include the vendor’s periodic reports, incident reports, any changes in processing, and updates from the vendor (e.g. new security certifications, personnel changes, or sub-processor announcements).</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">Throughout the life of the contract, the organization actively manages and monitors the vendor relationship to ensure continued compliance:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Performance & SLA Monitoring:</strong> The Business Unit Owner monitors that the vendor delivers services according to contract and does not exceed or deviate from the agreed processing. Any issues in service or deviations in use of data are flagged.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Regular Compliance Check-Ins:</strong> The Privacy or Vendor Management team may schedule check-ins (e.g. quarterly or annual) with the vendor to discuss any privacy-related updates. During these, review if there have been any changes in the vendor’s processes, new sub-processors, or significant security updates. Require the vendor to confirm ongoing compliance with the DPA (for example, via an annual attestation or updated questionnaire).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Sub-processor Notifications (GDPR 28(2)):</strong> If the DPA includes a general authorization for sub-processors, the vendor must notify the controller of any intended addition or replacement of a sub-processor before it happens. When such a notification is received, the <strong>Sub-processor Approval Process</strong> is triggered (see <strong>5.5</strong> below for details). In summary, the Privacy team will evaluate the proposed sub-processor’s adequacy and either approve or formally object within the allowed timeframe. Documentation of this decision is kept.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Incident Management:</strong> If the vendor experiences any security incident or data breach involving our data, they are obligated to inform us without undue delay. The DPO (and Security team) will work with the vendor to obtain details and assess impact. This kicks off our Incident Response procedure (outside the scope of this SOP) to notify regulators or data subjects as needed. Post-incident, require a report from the vendor and track remediation actions. Any such incidents are logged and reviewed in vendor performance evaluations.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Data Subject Requests Handling:</strong> Forward any relevant data subject requests to the vendor if their assistance is needed (e.g. if a user requests erasure and the data resides with the processor, instruct the vendor to delete specific records). Ensure the vendor responds within required timelines. This process should be pre-defined in the DPA; our team coordinates and tracks the request fulfillment, verifying completion and proper logging.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Audits and Assessments:</strong> As permitted by the DPA (typically with notice), conduct audits or request evidence of compliance. This could range from reviewing the vendor’s annual penetration test summary, SOC 2 report, or ISO 27701 certification, to an on-site audit for critical vendors. The Compliance/Audit team leads this, with support from IT Security. Document audit findings and follow up on any non-conformities (e.g. require the vendor to implement additional controls or corrective actions by a deadline).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Contract Reviews and Renewals:</strong> Prior to contract renewal or on a scheduled basis (e.g. annually), re-evaluate the vendor. Re-run key parts of the due diligence if needed (especially if the regulatory landscape changed or if the vendor’s role expanded). Check if any contract updates are required (for example, incorporating new Standard Contractual Clauses if laws changed, or updating contact points). Only renew if the vendor is still compliant and meeting performance expectations. If issues are found, consider a remediation plan or ultimately a replacement vendor if needed.</li></ul>
                    </li></ul>`);
                processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Monitoring Records</strong> – Documentation of all oversight activities is maintained. This includes records of sub-processor change decisions, meeting minutes from vendor check-ins, annual compliance statements from the vendor, incident reports and resolutions, audit reports, and any remediation plans. The vendor’s entry in the Vendor Register is updated with any changes (new sub-processors, certification updates, etc.). These records demonstrate ongoing controller due diligence and can be used to satisfy auditors or regulators that we manage our processors responsibly.</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.5 (Controller) - Conditional on PC7206
            if (hasPC7206) { // Sub-processor management is part of the DPA (PC7206)
                 processesHtml += renderSubSectionHeading('Managing Sub-Processor Changes');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Notification from a processor of an intended new sub-processor or a change to existing sub-processors; or a request from internal stakeholders to allow the vendor to use a specific sub-processor for a new function. Additionally, information about the sub-processor (location, services, security certifications) provided by the vendor.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">When a processor wants to engage a new sub-processor, the following steps occur (assuming our contract permits or requires us to handle this):
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Review Authorization</strong>: Determine if the vendor has general authorization (with notice) or needs specific approval per the DPA. If specific approval is required by contract, the vendor must wait for our explicit consent before using the sub-processor. If general, we have the right to object but the default is allowed after notice period.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Risk Assessment of Sub-processor</strong>: The Privacy and Security teams assess the proposed sub-processor similar to how a new vendor is assessed. Verify the sub-processor’s data protection standards (they should meet or exceed those of the primary vendor). Check for factors like: will data be transferred to a new country (requiring Standard Contractual Clauses or other safeguards), does the sub-processor have good security practices, and have they had any known breaches. Use the vendor’s provided info and possibly open-source research or questionnaires.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Decision</strong>: If the sub-processor is acceptable, document the approval. If there are concerns (e.g. sub-processor is in a high-risk jurisdiction or has poor compliance history), discuss internally (DPO, Legal, Business Owner) whether to object. An objection might trigger renegotiation with the vendor or plans to mitigate (perhaps the vendor offers an alternative sub-processor).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Communicate</strong>: Within the allowed timeline (as set in the contract, e.g. 30 days from notice), inform the vendor of our decision in writing. Approvals can be simple written consent. Objections should include the reason if appropriate, and reference contract rights. Work towards a resolution (the vendor might propose measures to alleviate the concern, or in rare cases, we may terminate the contract if no agreeable solution and the sub-processor is critical to service).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Contract Update</strong>: If we approve a new sub-processor, ensure the vendor adds them to the sub-processor list attached to the DPA. No formal contract amendment is usually needed if general authorization was in place, but our Vendor Register should be updated. If specific approval was required, document that as an addendum or written record.</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Sub-Processor Approval Record</strong> – A documented trail of our evaluation and approval/objection is kept (e.g. an email or memo approving the new sub-processor, or a letter of objection). The vendor’s sub-processor list is up-to-date and on file. If the sub-processor was approved, the relationship continues with the new sub-processor in scope; if objected, either the sub-processor is not used or alternative actions are taken (noted in the record).</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.6 (Controller - Reinstated)
            if (isController) { // Joint Controller Coordination
                processesHtml += renderSubSectionHeading('Joint Controller Coordination (When Applicable)');
                processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">An ongoing arrangement where the organization and one or more other parties are joint controllers for certain processing. Input includes the signed joint controller agreement and any agreed governance structures between the parties.</li></ul>');
                processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">In joint controllership scenarios, the organization must cooperate with the other controller(s) to ensure compliance and communication are effective:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Maintain the Arrangement</strong>: Keep the joint controller agreement terms in practice. If the agreement assigns our organization to handle data subject requests or inquiries, ensure processes are in place to do so and that the other controller forwards any received requests promptly (or vice versa). For example, if we agreed to be the contact point for data subjects, our customer service or DPO is prepared to handle queries about jointly controlled data.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Information to Data Subjects</strong>: Ensure that privacy notices to individuals clearly explain the essence of the joint controller arrangement (GDPR 26(2)). This typically means our Privacy Notice (or a joint notice) tells data subjects that we and Partner X jointly use their data for Y purpose, and outlines the key responsibility splits (e.g. “Company X handles deletion requests, Company Y provides the initial privacy information,” etc.). Verify that this information remains accessible and updated if the arrangement changes.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Meetings Between Controllers</strong>: Hold periodic coordination meetings with the joint controller partner’s privacy team. Discuss any issues in fulfilling obligations – e.g. have all access requests been handled correctly? Any confusion or complaints from data subjects? Any changes in processing that require updating the arrangement? Document decisions or changes; update the joint controller agreement if roles or processes change materially.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Breach and Incident Coordination</strong>: If a data breach occurs in jointly controlled data, coordinate per the agreement. (Typically the party where the breach occurred might notify the lead Supervisory Authority, but both should confer.) Ensure both controllers know their reporting obligations and help each other provide complete notifications to affected individuals or regulators.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Ongoing Compliance</strong>: Each controller should inform the other of any significant compliance developments (e.g. if one party wants to change a sub-processor or processing operation that impacts the joint data, or if one is subject to a regulator inquiry related to the joint processing). Maintain open communication to prevent compliance gaps.</li></ul>
                    </li></ul>`);
                processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Joint Operation Log</strong> – Meeting notes and communications with the joint controller partner are kept to track how responsibilities are executed. Privacy notices remain updated reflecting the partnership. Any adjustments to the division of responsibilities are captured in a revised agreement if needed. Data subjects continue to have a clear path to exercise their rights, and both controllers can demonstrate to regulators that they have a working arrangement as required by Art. 26.</li></ul>');
                processSectionRendered = true;
            }

            // Process 5.7 (Controller - Renumbered from 5.6)
            if (isController) {
                 processesHtml += renderSubSectionHeading('Vendor Off-boarding and Contract Termination');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Decision or notice to terminate the vendor contract (e.g. end of service, switching providers, or termination due to compliance issues). Also, inputs include the contractual obligations at termination (from the DPA) and any instructions from the business regarding data retrieval.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">When a processor’s services are no longer needed or the contract is expiring/terminated, steps are taken to securely disengage:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Notice and Planning:</strong> The Business Owner or Procurement gives notice per contract terms if not automatic. Develop a termination checklist with Privacy and IT: include dates for ending access, data return procedures, etc.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Revoke Access:</strong> Coordinate with IT to remove the vendor’s access to systems, accounts, or data streams on the effective termination date. This prevents any further processing beyond the contract period.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Data Return or Deletion:</strong> As required by GDPR and the DPA (Art. 28(3)(g)), instruct the vendor on what to do with the personal data at termination. Options usually include return all data to us, then delete their copies, or if return is not needed, just certify deletion. The instruction should be given in writing to have an audit trail. For example: “Please extract all our data in format X and deliver via secure channel by DATE, then permanently delete all our data from your systems and confirm deletion in writing.” If the contract already specifies the choice (return or destroy), follow that.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Obtain Certification:</strong> The vendor must provide confirmation that data has been deleted or returned and no personal data was retained (unless retention is required by law, in which case they must notify us of that legal obligation). The DPO/Privacy should review this confirmation. If data was returned, ensure we securely receive and store it or ingest it into our systems as needed, then verify the vendor’s deletion of their copy.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Update Records:</strong> Update the Vendor Register and RoPA to mark this processing activity as terminated. Note the date of termination and actions taken (data returned/deleted). If a replacement vendor is taking over, ensure smooth transition of data under proper agreements (new DPA, etc., outside this scope).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Post-termination Review:</strong> Internally, review the vendor’s performance and any lessons learned (e.g. were there any incidents or difficulties?). This can inform future vendor selection. Also, ensure any outstanding obligations (like post-contract confidentiality or restrictions on further use of data) are being honored – e.g. the vendor should not have retained any data or be using it in any way.</li></ul>
                    </li></ul>`);
                processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Verified Vendor Off-boarding</strong> – A completed off-boarding checklist. Key outputs are a <strong>Data Deletion Certificate or Acknowledgment</strong> from the vendor and updated internal records. The termination is formally closed only after confirming all personal data has been accounted for and secured. The organization retains the DPA and off-boarding records in case of future audits or if questions arise about the vendor’s handling of data.</li></ul>');
                 processSectionRendered = true;
             }

        } else { // Processor Processes
            // Process 5.1 (Processor) - Conditional on PC8201
            if (hasPC8201) {
                 processesHtml += renderSubSectionHeading('Client Onboarding and DPA Negotiation');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">A new client engagement that involves processing personal data on their behalf. Inputs include the client’s business requirements (what data processing they want us to do), any client-provided Data Processing Agreement or demands, and our standard contract terms. Also, information on whether the client permits sub-processors, specific security requirements, etc., typically obtained during sales discussions.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">Before we can start processing any personal data, a formal <strong>Data Processing Agreement (DPA)</strong> must be put in place and all details of the engagement clarified:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Identify Processing Details:</strong> Work with the client to document the scope: the purpose of processing, types of personal data, categories of data subjects, and duration. This usually goes into an annex of the DPA or Master Services Agreement. Ensure both sides have a common understanding of what will be done with the data.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Review Client’s DPA vs. Our Template:</strong> If the client provides their own DPA, have Legal and the DPO review it. Compare against GDPR Art. 28(3) checklist and ISO 27701 requirements to ensure nothing is missing and nothing is inconsistent with our practices. Key points: it should clearly state we will only process on their instructions, include confidentiality, security, sub-processor rules, assistance obligations (like helping with DSARs, breaches, etc.), deletion/return of data, and allow them audit rights. If anything is missing (e.g. no clause about informing if their instruction is unlawful), propose adding it for completeness or note internally if the law will imply it regardless.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Negotiate Terms:</strong> Resolve any discrepancies. For example, if the client DPA forbids any sub-processor use but we need one (like using a cloud hosting provider), negotiate an allowance for that sub-processor. Ensure the agreement either specifically names approved sub-processors or allows general authorization with notice. Also agree on breach notification timelines (often 24-72 hours after discovery) and audit mechanisms (perhaps limited to once annually with X days’ notice, etc.). Clarify liability and indemnification related to data protection (this might be in the main contract).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Establish Contact Points:</strong> Within the DPA or operational documents, identify who at the client is the contact for privacy/security issues (for sending breach notices or sub-processor notices) and who on our side fulfills those roles (likely our DPO or Privacy Officer). Also understand the client’s escalation path if we ever need to discuss an instruction (ideally the DPA specifies a process if we believe an instruction is non-compliant—e.g. we can suspend processing that instruction until clarified).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Finalize and Sign:</strong> Once both sides agree, execute the DPA along with the main service agreement. Ensure that any attachments (like the list of our current sub-processors, security measures description, etc.) are complete and attached. Our DPO/Privacy retains a copy and updates our records (especially noting any client-specific requirements, like “client X requires 1-week advance notice for any sub-processor change” or “client X did not allow data to be stored in country Y”).</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Executed Client Agreement</strong> – A signed contract (Master Service Agreement + Data Processing Agreement) that legally binds us to the controller’s terms and GDPR obligations. This includes a clear description of the processing authorized and our duties. Internally, a <strong>Client Processing Summary</strong> document may be created by the Account Manager or DPO, highlighting key obligations (e.g. “no sub-processors allowed without explicit written consent each time”, “must delete data after 30 days of processing” etc.). This summary, along with the DPA itself, is distributed to relevant teams (Account Manager, Operations lead, Security) to inform them of compliance requirements.</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.2 (Processor)
            if (isProcessor) { // Always relevant if processor role
                 processesHtml += renderSubSectionHeading('Internal Preparation and Documentation of Instructions');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">The signed DPA and related documents from the client, and any initial instructions provided by the controller (client) about how they want the processing done. Also, knowledge of any constraints from the contract (like geographic processing limits or special security rules).</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">Before actual processing starts, the organization sets up internal controls and understanding to ensure we only act as permitted:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Internal Kickoff Meeting:</strong> The Account Manager convenes a meeting with all internal stakeholders (Privacy, Security, Operations, IT) to go over the client’s requirements and our obligations. In this meeting, review the allowed processing scope and any specific instructions. For example, if the client said “process this data for analytics and then delete after 14 days,” make sure everyone knows that timeline. Discuss how we will log and handle any client requests (like deletion requests) quickly.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Documented Work Instructions:</strong> Translate the client’s requirements into internal <strong>Standard Operating Procedures or Work Instructions</strong> for the team. This might be a project-specific SOP that says: what data to expect from client, how to process it step by step, where to store results, who can access it, and when to purge it. These work instructions ensure all processing is <em>traceable to a controller instruction</em> and help avoid scope creep.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Access and Environment Setup:</strong> IT and Security set up the environment according to the contract. For instance, create segregated storage or databases for the client’s data (to avoid any mix-up with other clients’ data if multi-tenant). Implement access controls so that only the assigned Operations Team for this client can access their data. If the client requires data to stay in a certain jurisdiction, configure systems accordingly (e.g. use EU data centers only).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Confidentiality and Training:</strong> Verify that every team member assigned to this client has signed the necessary confidentiality agreements (often covered by employment contract and company policy, but ensure any contractors have NDAs). Provide a briefing or training focusing on the client’s project – emphasizing that data can only be used as instructed, and the legal duty to follow the contract (GDPR Art. 29). This training should also cover what to do if a data subject request comes in or if they suspect a security issue.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Record Keeping:</strong> The Privacy Officer updates our internal <strong>Record of Processing</strong> to include this client’s processing under the section for “processing carried out on behalf of other controllers” (GDPR Art. 30(2)). We note the controller’s name, the processing purposes, categories of data, etc., and contact details of the controller and any representative/DPO they have, as required.</li></ul>
                     </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Operational Readiness</strong> – The team is fully prepared to start processing. There is an internal procedure aligning exactly with the client’s expectations (documented instructions on file), systems are configured securely, and staff are briefed on compliance points. We have also logged the processing in compliance records. Essentially, all safeguards are in place so that once data comes in, it will be handled in a controlled and lawful manner.</li></ul>');
                 processSectionRendered = true;
             }

            // Process 5.3 (Processor)
            if (isProcessor) { // Always relevant if processor role
                 processesHtml += renderSubSectionHeading('Data Processing and Execution');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Personal data received from the client or collected on behalf of the client, along with the documented instructions for processing (from step 5.2). Could include continuous inputs if this is an ongoing service (e.g. daily data files or real-time data via API).</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">Perform the contracted processing activities, ensuring at every step we adhere to scope and security:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Process Data per Instructions:</strong> The Operations Team executes the tasks as defined. For example, if we are a payroll processor, we take the employee data from the client and run payroll calculations, produce outputs, etc., <strong>only</strong> for that purpose. We do not examine or use the data for any other reason (e.g. we don’t mine the client’s data to build our own analytics unless expressly allowed). We also stick to any retention limits – e.g. if instructed to delete intermediate files after processing, we do so.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Maintain Security Controls:</strong> Throughout processing, follow security best practices: use encryption, strong authentication for access, monitor for unusual access, etc. This fulfills our obligation under GDPR Art. 32 and also the typical contract clause. Any staff working with the data should do so in the secure environment set up (no exporting data to unauthorized personal devices or unauthorized cloud storage). The Security Team might enable extra logging for this client’s data to have an audit trail of all access and processing events.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Monitor for Instruction Changes:</strong> The Account Manager stays in communication with the client. If the client updates their requirements or gives additional instructions (for instance, “we actually need to also sort the data by region” or “please also retain a backup for 1 year”), these should be obtained in writing (email or ticket system) to count as documented instructions. Then assess if the DPA covers that or if an amendment is needed (if it’s a big change in scope). Implement changes only after proper documentation and any necessary contract update.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Prohibit Unauthorized Use:</strong> Ensure no one internally uses the client’s data for anything outside the contract. For example, it should not be used for testing, for training machine learning models, or for marketing, unless the contract explicitly allows it and such use is on the controller’s documented request (which is unlikely unless specified). This rule is essentially enforcing GDPR Art. 28(3)(a) & Art. 29 internally. Violations (even well-meaning, like a developer making a copy to troubleshoot an issue without permission) are treated seriously and reported to Privacy Officer for remediation.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Quality and Accuracy Checks:</strong> Although primarily an operational concern, maintaining data accuracy and processing correctness is important as part of our service commitment (and indirectly a GDPR principle if we have any control). Double-check outputs before returning them to the client to avoid any mishandling of personal data (like misformatting that could mix up individuals’ data).</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Processed Data/Service Deliverables</strong> – The result of processing as per contract. This could be an output dataset, a report to the client, or an action performed (like data loaded into client’s system). The key from a compliance view is that the output and any data retained on our side is exactly as expected under the instructions. All processing events are logged. If needed, we have logs or reports to show the client (or an auditor) what was done with the data (fulfilling accountability). The data remains protected during processing, and no extras copies or leaks occurred.</li></ul>');
                 processSectionRendered = true;
             }

            // Process 5.4 (Processor) - Conditional on PC8204/PC8205
            if (hasPC8204 || hasPC8205) {
                 processesHtml += renderSubSectionHeading('Handling Controller Requests and Communication');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">Requests or communications from the controller (client) regarding the processing. These may include routine queries, Data Subject Access Requests (DSARs) or other rights exercises forwarded to us, audit requests, instructions changes, or even a concern that we may be breaching terms. Additionally, internal input like our logs or documentation that would be used to respond.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">As a processor, we must assist the controller with their compliance obligations and maintain open communication:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Data Subject Rights Assistance:</strong> If the client needs our help to respond to a DSAR or other rights (like rectification or erasure), we act promptly. For example, if a user asks the controller to delete their data, the controller might ask us “please delete any data you hold about X and confirm.” Upon such a request (usually through the Account Manager or directly to our Privacy contact as specified in the DPA), we verify the identity of the request via the controller and then search our systems for the data subject’s information. We delete or anonymize as requested (or extract data to return to the controller for a SAR) within the timeframe given by the controller (which in turn is bound by GDPR’s one-month deadline, so we often have a shorter internal target). We then confirm completion back to the controller in writing. All such requests and our actions are logged in a <strong>DSAR log</strong> for compliance.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Breach Notification to Controller:</strong> If we detect a personal data breach in our systems affecting the client’s data (e.g. unauthorized access, ransomware, data sent to wrong party), we follow the breach management process. This involves immediate escalation to our Security and DPO. We contain the issue, assess impact, then <strong>notify the controller without undue delay</strong> (typically within 24 hours of confirmation, as per many DPAs). The notification includes all required information (nature of breach, data affected, measures taken, contact point, etc.). We continue to update the controller as we learn more. We do <strong>not</strong> notify any authorities or data subjects directly unless the controller instructs us to or we are legally required (since typically that’s the controller’s responsibility, but we must assist). We document the breach internally and how we assisted the controller.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Routine Communication:</strong> The Account Manager handles day-to-day questions from the client. If a client asks for a report of all processing activity for accountability, we provide it as appropriate (maybe via our web portal or manual report). If a client has a concern like “We think data is being kept too long, can you confirm deletion policy?”, we address it promptly, showing transparency.</li></ul>
                        ${hasPC8205 && !isPC82051aOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle"><strong>Audit and Compliance Requests:</strong> Under Art. 28(3)(h), the client may audit us. This could be a questionnaire (“please fill this security survey annually”) or an on-site/virtual audit. Our Compliance/Audit team coordinates these. We gather all necessary evidence: policies, penetration test results, certifications, etc. During an audit visit, we show the client auditors our relevant procedures (perhaps how we restrict access to their data, etc.). We ensure our staff cooperates and any findings are taken seriously. If a client auditor finds a minor non-conformity (e.g. they want us to enhance a policy), we work to resolve it and inform the client. Audit results and our responses are filed.</li></ul>' : ''}
                        ${hasPC8204 ? '<ul class="bulleted-list"><li style="list-style-type:circle"><strong>Instruction Clarification or Objection:</strong> If we ever receive an instruction from the client that we think is outside the original contract or illegal (e.g. client asks us to import a list of emails into a campaign that looks like it might violate spam laws, or asks us to combine datasets in a way that wasn’t originally agreed and could breach privacy principles), we do <em>not</em> simply execute it. The Account Manager or DPO will communicate with the client to clarify the request. If we confirm that complying would indeed violate GDPR or other laws, <strong>we inform the controller in writing of our inability to comply and the reason</strong> (citing that we believe the instruction infringes the law, as per Art. 28(3)(h)). We document this notification. We then seek either a lawful alternative instruction or the controller’s confirmation that they will assume responsibility (depending on the scenario). We also consider involving Legal if this might lead to a contract dispute. Throughout, we pause any questionable processing until resolved.</li></ul>' : ''}
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Communication Log and Compliance Records</strong> – For every client request or event, we have records: emails or letters responding to requests, copies of data delivered for SAR, breach notification reports, completed audit questionnaires, and any formal letters where we raised an objection to an instruction. This log demonstrates our responsiveness and compliance. The client should ideally be satisfied that we are supporting them; any open items (like pending deletion tasks or security improvements from an audit) are tracked to completion.</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.5 (Processor) - Conditional on Sub-processor controls
            if (hasPC8506 || hasPC8507 || hasPC8508) {
                 processesHtml += renderSubSectionHeading('Sub-Processor Management');
                 processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">A need or decision to employ a sub-processor to help deliver services to a client. This could be identified during initial onboarding (e.g. we plan to use a cloud hosting provider or an email service provider as part of our solution) or it might arise later (we want to bring in a new tool or service). Input includes details about the proposed sub-processor: who they are, what they will do, what data they will access, and any relevant compliance info (privacy policy, certifications, etc.). Also, the contractual status: whether the client gave general approval for sub-processors or if we need to seek specific consent.</li></ul>');
                 processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">Manage sub-processors to ensure compliance and transparency to the controller:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Initial Disclosure/Approval:</strong> During contract negotiation or as soon as a sub-processor is identified, disclose it to the client. If the DPA lists pre-approved sub-processors, ensure those listed match what we intend to use. If we want to add a sub-processor not in the list, formally <strong>request authorization</strong> from the controller. For specific consent: we send a request with the sub-processor’s details and wait for written approval. For general consent with notification: we send a notice (e.g. via email or a dedicated portal) detailing the new sub-processor and give the client the contractual period (say 30 days) to object. This process is coordinated by Sub-Processor Management or the Account Manager, and the timeline is tracked.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Due Diligence on Sub-processor:</strong> Even before using a sub-processor, we internally vet them. The Security and Privacy teams review the sub-processor’s security measures and privacy practices. Because we remain liable for them (Art. 28(4)), we treat them with similar scrutiny as a client would treat us. We ensure they meet our standards and can uphold GDPR obligations. If they’re not up to par, we either require improvements or choose a different partner. This due diligence report is kept on file.</li></ul>
                        ${hasPC8507 ? '<ul class="bulleted-list"><li style="list-style-type:circle"><strong>Contract with Sub-processor:</strong> We execute a <strong>Data Processing Agreement with the sub-processor</strong>. This contract mirrors the obligations we have to our client: the sub-processor can only act on our instructions (which are essentially the controller’s instructions passed down), must implement required security, assist with data subject rights via us, notify us of breaches, and so forth. It also has clauses that if the client ends the contract, the sub-processor will also cease processing and return/delete data. Essentially, the sub-processor DPA ensures if the client asked us for something (like deleting data or auditing), we can demand the same from the sub-processor. Legal ensures this contract is in place and signed before any data is shared.</li></ul>' : ''}
                        ${hasPC8506 ? '<ul class="bulleted-list"><li style="list-style-type:circle"><strong>Maintain Sub-processor List:</strong> We maintain an up-to-date list of all sub-processors for each client (or a global list if the sub-processor is used for multiple clients). This list typically includes the sub-processor’s name, location, and the service provided. If we have a web page for transparency, we update it whenever there’s a change. If clients must be individually notified, we prepare a notification.</li></ul>' : ''}
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Monitor Sub-processor Performance:</strong> Once the sub-processor is active, we monitor them. This could include requiring periodic compliance attestations or certifications, reviewing their SOC2/ISO reports annually, and ensuring they meet any SLA (service level agreements) we have that could affect our service to the client. If a sub-processor has a breach or issue, they must inform us per contract, and we in turn would inform controllers. We flow that info up promptly. If a sub-processor is found to be non-compliant or introduces high risk, we take action (could be suspending data sharing with them, or even replacing them). We inform affected controllers if something significant happens with a sub-processor (for instance, if we decided to terminate a sub-processor for cause, we’d likely alert clients and reassure them of continuity plans).</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Approved Sub-processors and Audit Trail</strong> – All sub-processors in use are documented and approved by controllers. We have an audit trail of communications for any new sub-processor introduction (e.g. an email to Client on date X, and response or no objection by date Y). We also have DPA agreements signed with each sub-processor on file. The current sub-processor list is readily available for any client or auditor, demonstrating compliance with transparency requirements. By managing sub-processors diligently, we uphold our contractual promise and legal responsibility that data is equally protected by any third-parties we utilize.</li></ul>');
                 processSectionRendered = true;
            }

            // Process 5.6 (Processor)
            if (isProcessor) { // Always relevant if processor role
                processesHtml += renderSubSectionHeading('Service Termination and Data Return/Deletion');
                processesHtml += renderStep('Input:', '<ul class="bulleted-list"><li style="list-style-type:disc">A client’s contract is ending or has been terminated (either by expiration, mutual agreement, or early termination). Input includes the termination date, any specific client instructions about data (some clients might request immediate deletion or to hand over data), and the obligations from the DPA (often there’s a clause detailing post-termination handling). Also, any ongoing requirements (e.g. if law enforcement holds require retention, but that would be rare and communicated).</li></ul>');
                processesHtml += renderStep('Activity:', `<ul class="bulleted-list"><li style="list-style-type:disc">When we cease providing services to a client, we must securely handle the client’s data and fulfill all end-of-contract obligations:
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Internal Notification:</strong> The Account Manager notifies all relevant teams of the impending termination date. Privacy, Security, IT, and Operations get a heads-up to prepare for data deletion/return. If the client hasn’t already given instructions (most DPAs give the controller the choice to have data returned or deleted), the Account Manager or DPO will reach out to the client asking for their preference if not clear. If the contract explicitly says “processor will delete all data upon termination,” we proceed with deletion, but often we confirm if the client needs any final data export.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Data Export to Controller (if requested):</strong> If the client wants their data back, coordinate a secure transfer. For example, provide all personal data we still hold in a commonly usable format (CSV, database dump, etc.), via a secure channel. Document what was given and get acknowledgment from the client of receipt. This might also include transferring operation logs or audit trails if that was agreed.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Data Deletion:</strong> Once data is returned or if no return was needed, we permanently <strong>delete personal data</strong> related to the client from all our systems. This includes production databases, backups (as far as technically feasible – if backups are encrypted and scheduled to cycle out, we ensure no restoration happens in the meantime or find a way to expedite secure deletion), and any files stored by sub-processors. We instruct sub-processors to do the same (per our contract with them). For example, if we used a cloud storage sub-processor, we ensure the specific buckets or directories for the client are purged. We also delete any service accounts or credentials associated solely with that client’s data to prevent access.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Confirmation/Ceremonial Sign-off:</strong> After deletion, the DPO or responsible manager prepares a <strong>Data Deletion Certification</strong> or email stating that all personal data for Client XYZ has been deleted as of [date], in accordance with GDPR and contract. If the client requires a formal certification letter, we provide it. We may have the sub-processors provide us deletion confirmations as well, which we can summarize or forward to the client as proof.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Revoke Access and Credentials:</strong> Ensure that any user accounts or access keys provided to us by the client are revoked/returned. For instance, if the client gave us login to their systems or an API token, we should not retain those. We either return them (if it makes sense) or simply securely destroy any copies and ask the client to deactivate our account on their side.</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Retention of Necessary Records:</strong> We will typically retain the DPA and some minimal data about the processing (e.g. invoices, records of what processing we did) as required for legal/tax purposes or evidence of compliance. This does not include personal data beyond the contract, except perhaps in anonymized or aggregated form. Make sure any such retention is permitted by the contract or law. Communicate to the client if we must keep data (for example, if a law requires us to keep some logs for cybersecurity or financial reasons, we inform them of that portion and ensure it’s covered by an exception in the DPA or in law).</li></ul>
                        <ul class="bulleted-list"><li style="list-style-type:circle"><strong>Service Closure Review:</strong> Internally, hold a brief post-mortem. Confirm all tasks done: “Did we receive confirmation from sub-processor A of deletion? Yes. Did we send the certificate to client? Yes.” Also note any lessons learned from the engagement that could improve future processes.</li></ul>
                    </li></ul>`);
                 processesHtml += renderStep('Output:', '<ul class="bulleted-list"><li style="list-style-type:disc"><strong>Contract Termination Dossier</strong> – A collection of records confirming proper closure. This includes any client instruction on data handling, the data export file (or a note that none requested), evidence of data deletion (deletion scripts logs or confirmation emails), the certificate of deletion sent to the client, and notes on sub-processor compliance. The Vendor (Processor) Register entry for this client is updated to show termination date and method of data disposition. With this, we ensure that no personal data from the client lingers improperly and that we have fulfilled our obligations, allowing both parties to part ways compliantly.</li></ul>');
                 processSectionRendered = true;
             }
        }

        // --- Add Section 5 Heading and Content if applicable ---
        if (processSectionRendered) {
            htmlContent += renderSectionHeading('Detailed Processes');
            htmlContent += `<p>The following processes detail how to carry out Vendor/Processor Management activities step by step. Each process includes multiple steps, and <strong>each step is described with its Input, Activity, and Output</strong> to clarify how the procedure is executed and to maintain traceability to compliance requirements:</p>`;
            htmlContent += processesHtml;
        }


        return htmlContent; // Return the final combined HTML
    },

    shouldShow: (controlsMatrix) => {
        const relevantControls = [
            'PC7206', // Controller
            'PC8201', 'PC8204', 'PC8205', 'PC8506', 'PC8507', 'PC8508' // Processor
        ];
        if (!controlsMatrix || typeof controlsMatrix !== 'object') {
            return false;
        }
        return Object.values(controlsMatrix).some(activityControls =>
             activityControls && typeof activityControls === 'object' && relevantControls.some(control => activityControls[control] === true)
        );
    },


    getIntroText: () => {
        return 'This Standard Operating Procedure (SOP) outlines the processes for managing relationships with vendors acting as data processors and handling joint controller arrangements to ensure compliance.';
    }
};

export default SOPVendorProcessorManagement;