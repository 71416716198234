import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const WizardDrawer = ({ isOpen, title, content, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={`fixed bottom-0 right-0 md:left-[256px] transition-transform transform ${isOpen ? 'translate-y-0' : 'translate-y-full'} bg-white shadow-lg z-42`} style={{ maxHeight: '80vh' }}>
      <div className="flex justify-between items-center h-16 px-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold truncate pb-0">{t(title)}</h2>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}
          className="text-gray-600 hover:text-gray-900 text-3xl flex items-center justify-center w-8 h-8"
          aria-label="Close"
        >
          &times;
        </button>
      </div>
      <div className="p-4 overflow-y-auto" style={{ maxHeight: 'calc(80vh - 64px)' }}>
        <style>
          {`
            .drawer-content h3 { font-size: 1.25rem; font-weight: 600; margin-bottom: 1rem; }
            .drawer-content h4 { font-size: 1.1rem; font-weight: 600; margin-top: 1.5rem; margin-bottom: 0.5rem; }
            .drawer-content ul { list-style-type: disc; padding-left: 1.5rem; margin-bottom: 1rem; }
            .drawer-content li { margin-bottom: 0.5rem; }
            .drawer-content strong { font-weight: 600; }
            .drawer-content p { margin-bottom: 1rem; }
          `}
        </style>
        <div className="drawer-content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

WizardDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WizardDrawer;