// src/components/wizard_components/DataPrivacyWizard/SOPPrivacyImpactAssessment.js
import React from 'react';

// Helper function to check if a control is applied
const hasControlApplied = (controlsMatrix, controlId) => {
    if (!controlsMatrix || typeof controlsMatrix !== 'object') {
        return false;
    }
    return Object.values(controlsMatrix).some(activityControls => {
        if (!activityControls || typeof activityControls !== 'object') {
            return false;
        }
        return activityControls[controlId] === true;
    });
};

// Helper function to check if an execution criteria is overridden
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);
    if (!isControlApplied) {
        return true;
    }
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        return riskControlOverrides[controlId].includes(criteriaId);
    }
    return false;
};

const SOPPrivacyImpactAssessment = {

    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo, role) => {
        const orgName = entityInfo.name || '[Your Organization Name]';

        // This SOP is primarily for the Controller role based on the source HTML title and content
        const isController = role === 'Controller';

        // --- Controller Specific Controls & Criteria ---
        const hasPC7205 = isController && hasControlApplied(controlsMatrix, 'PC7205'); // Privacy Impact Assessment
        const isPC72051aOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1a'); // Trigger: automated decision making
        const isPC72051bOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1b'); // Trigger: large scale special categories
        const isPC72051cOverridden = isController && isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7205', '1c'); // Trigger: systematic monitoring public area

        // --- Dynamic Numbering ---
        let sectionCounter = 0;
        let subSectionCounters = {};

        const renderSectionHeading = (title) => {
            sectionCounter++;
            subSectionCounters[sectionCounter] = 0;
            return `<h2 style="font-size: 1.5rem; margin-top: 1.5rem;">${sectionCounter}. ${title}</h2>`; // Use H2 for main sections
        };

        const renderSubSectionHeading = (title) => {
            subSectionCounters[sectionCounter]++;
            return `<h3 style="font-size: 1.25rem; margin-top: 1.25rem;">${sectionCounter}.${subSectionCounters[sectionCounter]} ${title}</h3>`; // Use H3 for subsections
        };

        // Step rendering
        const renderStep = (title, content) => {
             if (!title) return content || '';
             // Using simple <p><strong> wrapper for step titles as in source HTML structure
             return `<p><strong>${title}</strong></p>${content}`;
        };

        // Only generate content if the role is Controller and the relevant control is active
        if (!isController || !hasPC7205) {
            return ''; // Return empty string if not applicable
        }

        let htmlContent = `<h1>SOP: Privacy Impact Assessment (PIA)</h1>`; // Simplified title

        // --- Section 1: Legal Basis ---
        let legalBasisContent = `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 35 - Data Protection Impact Assessment (DPIA):</strong> Requires controllers to carry out an assessment of the impact of processing operations where processing is "likely to result in a high risk to the rights and freedoms of natural persons." It especially applies to systematic and extensive evaluation based on automated processing, large-scale processing of special categories of data, and systematic monitoring of publicly accessible areas.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 35(7):</strong> Specifies minimum requirements for a DPIA, including a systematic description of processing operations, assessment of necessity and proportionality, assessment of risks to rights and freedoms of data subjects, and measures to address those risks.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 36 - Prior Consultation:</strong> If a DPIA indicates high risk that cannot be mitigated, the controller must consult the supervisory authority before processing.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 27701:2019 - PC7205 (Privacy Impact Assessment):</strong> Requires organizations to "assess the need for, and implement where appropriate, a privacy impact assessment whenever new processing of PII or changes to existing processing of PII is planned."</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>ISO/IEC 29134:</strong> Provides further guidance on privacy impact assessments related to the processing of personal data.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 5(2) - Accountability:</strong> Requires controllers to demonstrate compliance with data protection principles. A PIA is an important accountability tool.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>GDPR Article 24(1) - Responsibility of the Controller:</strong> Requires implementation of appropriate technical and organizational measures to ensure and demonstrate GDPR compliance, of which PIAs are a key component.</li></ul>
            <p><strong>Rationale:</strong> This SOP establishes a systematic approach to identifying, assessing, and mitigating privacy risks before processing begins or when significant changes occur to existing processing. By conducting Privacy Impact Assessments, the organization can identify and address privacy risks early, demonstrate compliance with privacy regulations, and build privacy by design into all processing activities.</p>`;
        htmlContent += renderSectionHeading('Legal/Standard Basis of the Procedure') + legalBasisContent;

        // --- Section 2: Purpose ---
        let purposeContent = `
            <p>The purpose of this SOP is to define a structured process for conducting Privacy Impact Assessments (PIAs) that identifies and addresses privacy risks before personal data processing activities begin or when significant changes to existing processing are planned. This procedure aims to:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Identify and Mitigate Privacy Risks Early:</strong> Establish a systematic method to identify, assess, and mitigate privacy risks at the earliest stages of any project or change involving personal data, thereby applying the principle of privacy by design.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Ensure Regulatory Compliance:</strong> Meet the requirements of GDPR Article 35 and other applicable laws by determining when a PIA (or DPIA under GDPR) is necessary and ensuring it covers all required elements.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Enable Informed Decision-Making:</strong> Provide management and project teams with sufficient privacy risk information to make informed decisions about whether and how to proceed with personal data processing activities.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Document Accountability:</strong> Create and maintain comprehensive documentation of privacy risk assessments, demonstrating the organization's commitment to privacy and compliance with the accountability principle.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Support Vendor Assessments:</strong> Evaluate the privacy impacts of engaging third-party vendors or processors who will handle personal data on behalf of the organization.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Standardize Risk Evaluation:</strong> Establish consistent criteria for evaluating privacy risks across the organization, ensuring all assessments use the same thresholds and methodology.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Integrate with Project Management:</strong> Ensure privacy risk assessments are integrated into the organization's project management methodology and change management processes so that privacy considerations are addressed at every appropriate stage.</li></ul>
            <p>This procedure serves as a comprehensive guide for all staff involved in the implementation or modification of systems, applications, services, or processes that involve personal data, ensuring privacy risks are identified, assessed, and mitigated throughout the organization.</p>`;
        htmlContent += renderSectionHeading('Purpose of the Procedure') + purposeContent;

        // --- Section 3: KPIs ---
        let kpisContent = `
            <p>To measure the effectiveness of this Privacy Impact Assessment procedure, the following KPIs are established:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>PIA Completion Rate:</strong> Percentage of new projects or significant changes to existing projects involving personal data that have completed a PIA before implementation. <em>Target: 100%</em> - no project involving personal data should go live without an appropriate level of privacy risk assessment.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>PIA Timeliness:</strong> Percentage of PIAs initiated during the planning or design phase of projects (rather than near implementation). <em>Target: ≥90%</em> - PIAs should be integrated early in the development process to enable effective privacy by design.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Mitigation Implementation Rate:</strong> Percentage of identified high and medium privacy risks that have implemented mitigation measures before the processing begins. <em>Target: 100%</em> for high risks and ≥90% for medium risks - ensuring the most significant risks are addressed.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Supervisory Authority Consultations:</strong> Number of cases where supervisory authority consultation was required due to high residual risk (per GDPR Article 36). <em>Target: <5%</em> of completed PIAs - indicating most risks are mitigated internally.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>PIA Quality Score:</strong> Based on internal reviews or audit findings regarding the thoroughness and accuracy of completed PIAs. <em>Target: ≥85%</em> compliance with quality criteria - ensuring assessments are comprehensive and useful.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Risk Identification Effectiveness:</strong> Number of privacy incidents or breaches that occurred in processing activities that had undergone a PIA, but where the risk was not identified. <em>Target: Zero</em> - this is a lagging indicator that shows if PIAs are effectively capturing relevant risks.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Staff Training Coverage:</strong> Percentage of relevant staff (project managers, system owners, developers, etc.) trained on PIA requirements and methodology. <em>Target: 100%</em> - ensuring all relevant personnel understand when and how to conduct PIAs.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>PIA Process Efficiency:</strong> Average time to complete a PIA from initiation to final approval. <em>Target: <20 business days</em> for standard PIAs - balancing thoroughness with business needs for timely decisions.</li></ul>
            <p>These KPIs will be reviewed quarterly by the Privacy Committee and annually by senior management to ensure the PIA process is effective and continually improving.</p>`;
        htmlContent += renderSectionHeading('Key Performance Indicators (KPIs)') + kpisContent;

        // --- Section 4: Responsibilities ---
        let responsibilitiesContent = `
            <p>Clear responsibilities are assigned to ensure this procedure is implemented effectively:</p>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Data Protection Officer (DPO) / Privacy Officer:</strong> The DPO is the <strong>owner of this procedure</strong>. They provide guidance on when a PIA is required, review completed PIAs for quality and completeness, sign off on final assessments, and maintain the PIA registry. The DPO consults with supervisory authorities when necessary (per Article 36) and advises on risk mitigation strategies. They also periodically review and update the PIA methodology and templates to reflect evolving best practices and regulatory guidance.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Project Managers / Business Initiative Owners:</strong> Responsible for identifying when a project or change might require a PIA and initiating the process with the DPO. They ensure privacy considerations are integrated into project planning and that resources are allocated for conducting PIAs and implementing mitigation measures. They are also accountable for ensuring identified mitigations are implemented before processing begins.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>System Owners / Process Owners:</strong> Provide detailed information about the processing activities, data flows, and existing controls for their respective systems or processes. They work with the PIA team to accurately document current and planned processing operations and help implement recommended mitigation measures.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Privacy Team / Privacy Champions:</strong> Conduct or facilitate PIAs in collaboration with project teams. They help gather information, document processing activities, identify risks, and suggest appropriate mitigations. They may also provide training and support to business units on the PIA process.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Information Security Team:</strong> Evaluates technical security measures and controls during the PIA process. They assist in identifying technical risks and appropriate security mitigations, especially for confidentiality, integrity, and availability concerns. They also validate that technical measures are implemented as recommended.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Legal Counsel:</strong> Advises on legal aspects of the PIA, including appropriate legal bases for processing, contract requirements, and interpretation of applicable privacy laws. They review high-risk PIAs and assist in determining when supervisory authority consultation is required.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>IT Architecture / Technology Teams:</strong> Provide technical information about systems, data flows, and security measures. They help identify privacy-enhancing technologies and architectural approaches that can mitigate identified risks.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Senior Management / Privacy Governance Committee:</strong> Reviews high-risk PIAs and makes final decisions about accepting risks or allocating resources for mitigation measures. They ensure organizational commitment to addressing privacy risks and provide oversight of the PIA program. They are also responsible for balancing privacy risks against business objectives.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Procurement / Vendor Management:</strong> For PIAs involving third-party processors or vendors, ensures appropriate due diligence and contractual clauses are in place. They coordinate with vendors to obtain necessary information for the PIA and communicate requirements for privacy controls.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Records Management / Data Governance Team:</strong> Provides information about data retention policies, data inventories, and classification schemes to support accurate documentation of data flows and processing activities.</li></ul>`;
        htmlContent += renderSectionHeading('Responsibilities (Roles & Departments)') + responsibilitiesContent;


        // --- Section 5: Detailed Processes ---
        let processesHtml = '';
        let processSectionRendered = false;

        // Process 5.1: Determining When a PIA is Required
        processesHtml += renderSubSectionHeading('Determining When a PIA is Required');
        processesHtml += `<p>This process establishes criteria for identifying when a Privacy Impact Assessment should be conducted.</p>`;
        processesHtml += renderStep('Step 1: PIA Screening Assessment', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Initial project description or change request for any initiative that involves personal data. This could come from project charters, system architecture documents, process change requests, or procurement plans for new vendor services.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The Project Manager or Business Owner completes a <strong>PIA Screening Questionnaire</strong> to determine if a PIA is required. The questionnaire typically includes questions to identify:
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether the initiative involves the collection, use, or disclosure of personal data</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The categories and volume of personal data involved</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether sensitive or special categories of data are involved</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The scale and scope of processing (number of data subjects, geographic reach)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether there's any automated decision-making with significant effects</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether there's systematic monitoring or tracking of individuals</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether there are new technologies or innovative applications being used</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether data will be transferred internationally</li></ul>
                <p>The completed questionnaire is submitted to the DPO/Privacy Office for review. The DPO evaluates the responses to determine if a PIA is required and what level of assessment is appropriate (e.g., full PIA, lighter assessment, or no formal assessment needed). The DPO consults with Legal and Information Security as needed for this determination.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>PIA Requirement Determination</strong> - A documented decision on whether a PIA is required and at what level. This is communicated to the Project Manager along with next steps. The determination is recorded in the PIA Registry maintained by the Privacy Office. If a PIA is required, the process moves to Step 2. If not, the Project Manager documents the exemption rationale provided by the DPO and proceeds with the project, applying standard privacy practices.</li></ul>`);
        processesHtml += renderStep('Step 2: Mandatory PIA Triggers Evaluation', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> GDPR Article 35(3) criteria, organization-specific mandatory PIA triggers, and information from the screening questionnaire.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO/Privacy Team evaluates whether the project meets any <strong>mandatory triggers</strong> for a PIA. Under GDPR Article 35(3), a DPIA is required when processing involves:
                ${!isPC72051aOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Systematic and extensive evaluation of personal aspects through automated processing, including profiling, that produces legal or similarly significant effects</li></ul>' : ''}
                ${!isPC72051bOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Large-scale processing of special categories of data (sensitive data) or criminal conviction data</li></ul>' : ''}
                ${!isPC72051cOverridden ? '<ul class="bulleted-list"><li style="list-style-type:circle">Systematic monitoring of publicly accessible areas on a large scale</li></ul>' : ''}
                <p>Additionally, the organization may have defined its own mandatory triggers based on supervisory authority guidance or internal risk policies. For example:</p>
                <ul class="bulleted-list"><li style="list-style-type:circle">Processing involving children's data</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Processing involving employee monitoring or evaluation</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Use of biometric data for identification</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Combining datasets that could result in unexpected insights about individuals</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Processing that could prevent data subjects from exercising their rights</li></ul>
                <p>If any mandatory trigger is met, a full PIA is required regardless of other factors. If no mandatory trigger is met, the DPO will still evaluate whether a PIA is advisable based on the overall risk profile from Step 1.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>PIA Requirement Confirmation</strong> - A final determination of whether a full PIA is mandatory based on regulatory requirements or organization policy. The result is recorded in the PIA Registry and communicated to the Project Manager along with the specific trigger(s) that apply. For high-risk projects, the DPO also notifies senior management at this stage. When a PIA is required, the process moves to the next process (5.2).</li></ul>`);
        processSectionRendered = true; // Mark section 5 as having content

        // Process 5.2: Planning and Scoping the PIA
        processesHtml += renderSubSectionHeading('Planning and Scoping the PIA');
        processesHtml += `<p>This process outlines how to prepare for and define the scope of a Privacy Impact Assessment once it has been determined necessary.</p>`;
        processesHtml += renderStep('Step 1: Assemble the PIA Team', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Determination that a PIA is required (from Process 5.1), project details, and organizational resources.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO/Privacy Team, in collaboration with the Project Manager, identifies the appropriate <strong>stakeholders</strong> who should be involved in the PIA. This typically includes:
                <ul class="bulleted-list"><li style="list-style-type:circle">A lead assessor from the Privacy Team</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The Project Manager or Business Owner</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">System Owner or Process Owner</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Representative from Information Security</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Technical architect or developer (for technology projects)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Legal representative (especially for high-risk assessments)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Representatives from relevant business units that will use the system/process</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Subject matter experts as needed (e.g., for specific technologies)</li></ul>
                <p>The DPO determines the level of involvement required from each stakeholder and establishes a PIA schedule with key milestones. For complex projects, a formal PIA kick-off meeting is scheduled to ensure all team members understand the process, their roles, and the timeline. For simpler projects, coordination may happen through email or existing project meetings.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>PIA Team Roster and Schedule</strong> - A documented list of PIA team members, their roles, and responsibilities, along with a timeline for completing the assessment. This is shared with all stakeholders and stored with the project documentation. The output also includes scheduled meetings for information gathering and risk assessment workshops.</li></ul>`);
        processesHtml += renderStep('Step 2: Define PIA Scope and Methodology', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Project documentation, system architecture, data flow diagrams (if available), business requirements, and information from the screening assessment.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA team defines the <strong>scope and boundaries</strong> of the assessment to ensure all relevant processing activities are included while keeping the assessment focused and manageable. This includes:
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifying the specific processing activities to be assessed</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Determining the system boundaries and interfaces with other systems</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Clarifying which aspects of the processing lifecycle will be included (collection, use, storage, sharing, retention, destruction)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifying relevant stakeholders and data subjects</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Determining the appropriate PIA methodology and tools to be used (e.g., risk scoring approach, workshop format)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifying any dependencies on other assessments or parallel processes (e.g., security risk assessments, vendor assessments)</li></ul>
                <p>For large or complex projects, the PIA may be broken down into logical components or phases. The team also determines what documentation will be required to complete the assessment and who will provide it. The DPO ensures the scope aligns with regulatory requirements and organizational policies.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>PIA Scope Document</strong> - A clear definition of what is included in (and excluded from) the assessment, along with the agreed methodology. The document specifies:
                <ul class="bulleted-list"><li style="list-style-type:circle">The processing activities being assessed</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The systems, applications, and data flows in scope</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The categories of personal data and data subjects affected</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The assessment approach and tools to be used</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Documentation requirements</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Roles and responsibilities for completing each aspect of the PIA</li></ul>
                <p>This document is approved by the DPO and Project Manager and serves as the roadmap for conducting the PIA. It ensures all stakeholders have a common understanding of the assessment's boundaries and objectives.</p></li></ul>`);
        processesHtml += renderStep('Step 3: Gather Initial Documentation and Information', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Existing documentation about the project or change, such as business requirements, functional specifications, data flow diagrams, system architecture documents, and existing privacy notices.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA team collects and reviews all relevant documentation to understand the proposed processing. Typically, this includes:
                <ul class="bulleted-list"><li style="list-style-type:circle">Requesting specific documents from project teams and system owners</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Conducting interviews with key stakeholders to fill information gaps</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Creating or updating data flow diagrams that show how personal data will move through the system/process</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Cataloging the categories of personal data and data subjects involved</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Documenting the purpose(s) of processing and legal basis (under GDPR)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifying retention periods and deletion mechanisms</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Mapping any third parties who will receive the data</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Documenting existing or planned security measures</li></ul>
                <p>The information gathering is coordinated by the PIA lead, who ensures all necessary details are collected before proceeding to the assessment phase. For complex projects, this may involve multiple information-gathering sessions or workshops.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>PIA Information Package</strong> - A compilation of all information needed to conduct the assessment, including data flow diagrams, data inventories, and processing purpose documentation. This package serves as the foundation for the risk assessment and is stored with the PIA documentation. Any information gaps identified during this step are documented for follow-up. This comprehensive information package ensures the subsequent risk assessment is based on accurate and complete understanding of the processing activities.</li></ul>`);
        processSectionRendered = true;

        // Process 5.3: Conducting the PIA Assessment
        processesHtml += renderSubSectionHeading('Conducting the PIA Assessment');
        processesHtml += `<p>This process details how to systematically assess privacy risks once the necessary information has been gathered.</p>`;
        processesHtml += renderStep('Step 1: Document the Processing Activities in Detail', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The PIA Information Package from Process 5.2, including data flow diagrams, project documentation, and information from stakeholder interviews.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA team creates a <strong>comprehensive description of the processing activities</strong>, as required by GDPR Article 35(7)(a). This description typically includes:
                <ul class="bulleted-list"><li style="list-style-type:circle">Detailed data flow diagrams showing how personal data moves through the system/process</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Complete inventory of personal data elements and special categories of data involved</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Clear articulation of the purposes of processing and legal basis for each purpose</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identification of all data subjects and categories of data subjects affected</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Documentation of data collection methods and sources</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Description of processing operations (automated and manual)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Details on data storage locations and retention periods</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Information on recipients of the data (internal and external)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Description of any international transfers and safeguards</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Documentation of user interfaces or touchpoints where data subjects interact with the system</li></ul>
                <p>The team reviews this documentation collectively to ensure accuracy and completeness. This step should produce a clear picture of "what is happening with the data" that all stakeholders understand and agree upon. For complex processes, the documentation may be broken down by processing phase or data subject type.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Detailed Processing Description Document</strong> - A comprehensive document that describes all aspects of the personal data processing activities. This document serves multiple purposes: it forms a key part of the final PIA report, provides the foundation for risk assessment, and can be used to respond to regulatory inquiries if needed. The document is reviewed and approved by the System/Process Owner to confirm its accuracy before proceeding to risk assessment.</li></ul>`);
        processesHtml += renderStep('Step 2: Assess Necessity and Proportionality', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The Detailed Processing Description from Step 1, along with business requirements and legal/regulatory context.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA team evaluates whether the processing is <strong>necessary and proportionate</strong> to achieve its purposes, as required by GDPR Article 35(7)(b). This assessment considers:
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether each data element collected is necessary for the stated purpose(s)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether the processing operations are proportionate to the need</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether the same objectives could be achieved with less data or less intrusive processing</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The appropriate retention periods for the data</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Whether the legal basis for processing is appropriate and defensible</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">How data subject rights will be facilitated</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Transparency measures (how data subjects will be informed)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Measures to ensure data quality and accuracy</li></ul>
                <p>This step often involves collaborative discussion among the PIA team members, with Legal providing guidance on necessity from a compliance perspective and business representatives explaining the business requirements. Any discrepancies or concerns are noted for later risk assessment and mitigation.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Necessity and Proportionality Assessment</strong> - A documented analysis of whether the processing meets necessity and proportionality requirements, with specific findings about any aspects that may be excessive or insufficiently justified. This assessment may include recommendations for data minimization, shorter retention periods, or enhanced transparency measures. The output becomes part of the final PIA report and informs the risk assessment in the next step.</li></ul>`);
        processesHtml += renderStep('Step 3: Identify and Assess Privacy Risks', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The Detailed Processing Description, Necessity and Proportionality Assessment, and the organization's risk assessment methodology and criteria.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA team systematically <strong>identifies and assesses privacy risks</strong> to the rights and freedoms of data subjects, as required by GDPR Article 35(7)(c). This typically involves:
                <ul class="bulleted-list"><li style="list-style-type:circle">Conducting a structured risk identification workshop or series of meetings</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Using a consistent risk assessment framework that considers likelihood and impact</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Evaluating risks across various privacy principles (e.g., lawfulness, fairness, transparency, purpose limitation, data minimization, accuracy, storage limitation, integrity and confidentiality)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Assessing risks related to data subject rights (access, rectification, erasure, restriction, portability, objection)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Considering special risks for vulnerable data subjects (e.g., children, employees)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Evaluating technical vulnerabilities and security risks that could affect personal data</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Assessing risks related to third-party sharing or international transfers</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Considering risks of function creep (data being used for new, unintended purposes over time)</li></ul>
                <p>Each identified risk is documented along with its likelihood, potential impact, and inherent risk level (before controls). The assessment uses a consistent rating scale (e.g., Low/Medium/High or 1-5) for both likelihood and impact, leading to an overall risk rating. The DPO ensures the risk assessment methodology is applied consistently across all PIAs.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Privacy Risk Register</strong> - A comprehensive catalog of identified privacy risks, including:
                <ul class="bulleted-list"><li style="list-style-type:circle">Risk description and risk owner</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Related privacy principle or data subject right</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Inherent risk rating (likelihood and impact before controls)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Existing controls or measures already in place</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Residual risk rating (likelihood and impact after existing controls)</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Initial recommendations for additional controls or mitigations</li></ul>
                <p>This risk register becomes a key component of the PIA report and forms the basis for developing mitigation measures in the next step. Risks are prioritized based on their residual risk rating to focus mitigation efforts on the most significant concerns.</p></li></ul>`);
        processesHtml += renderStep('Step 4: Identify and Evaluate Controls and Mitigations', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The Privacy Risk Register from Step 3, along with information about available security and privacy controls, organizational capabilities, and resource constraints.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> For each identified risk, the PIA team <strong>develops and evaluates mitigation measures</strong>, as required by GDPR Article 35(7)(d). This involves:
                <ul class="bulleted-list"><li style="list-style-type:circle">Brainstorming possible controls and mitigation strategies for each risk</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Evaluating the effectiveness and feasibility of proposed mitigations</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Consulting with technical, legal, and business stakeholders to ensure measures are practical</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Identifying who would be responsible for implementing each measure</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Estimating implementation timelines and resource requirements</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Considering the impact of controls on usability and business operations</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Evaluating whether mitigations might introduce new risks</li></ul>
                <p>The team prioritizes mitigations for high and medium risks while ensuring a balanced approach to all identified concerns. They consider technical measures (e.g., encryption, access controls), organizational measures (e.g., policies, training), and procedural measures (e.g., approval processes, regular audits). For each mitigation, the team assesses how it would reduce the likelihood or impact of the risk, leading to a new "target risk rating" (expected residual risk after implementation).</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Mitigation Action Plan</strong> - A detailed plan documenting:
                <ul class="bulleted-list"><li style="list-style-type:circle">Specific mitigation measures for each identified risk</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The expected effect on risk rating after implementation</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Responsibility for implementing each measure</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Timeline and milestones for implementation</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Resources required and dependencies</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">How effectiveness will be measured</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Any risks that cannot be fully mitigated (accepted risks)</li></ul>
                <p>This plan is reviewed by the DPO and relevant stakeholders to ensure it adequately addresses the risks and is feasible to implement. The plan becomes part of the final PIA report and serves as the roadmap for implementation. Any high risks that cannot be sufficiently mitigated are flagged for senior management review and potential supervisory authority consultation.</p></li></ul>`);
        processSectionRendered = true;

        // Process 5.4: Documentation, Approval, and Implementation
        processesHtml += renderSubSectionHeading('PIA Documentation, Approval, and Implementation');
        processesHtml += `<p>This process covers finalizing the PIA report, obtaining necessary approvals, and implementing mitigation measures.</p>`;
        processesHtml += renderStep('Step 1: Compile the PIA Report', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> All documentation and outputs from previous processes, including the Detailed Processing Description, Necessity and Proportionality Assessment, Privacy Risk Register, and Mitigation Action Plan.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA lead compiles a <strong>comprehensive PIA report</strong> that documents the entire assessment process and conclusions. The report typically includes:
                <ul class="bulleted-list"><li style="list-style-type:circle">Executive summary with key findings and recommendations</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Project description and scope of the assessment</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Detailed description of processing activities</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Necessity and proportionality assessment</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Methodology used for risk assessment</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Detailed risk analysis and findings</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Proposed mitigation measures</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Implementation plan and responsibilities</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Conclusions and recommendations</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Appendices with supporting documentation (e.g., data flow diagrams)</li></ul>
                <p>The report follows a standardized format established by the organization, ensuring consistency across assessments. The DPO reviews the draft report for completeness, clarity, and alignment with regulatory requirements. Feedback is incorporated before finalizing the document.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Final PIA Report</strong> - A comprehensive document that serves as the official record of the assessment, findings, and recommendations. The report is stored in the organization's document management system and linked to the PIA Registry. This document provides evidence of compliance with GDPR Article 35 and serves as a reference for implementation, future reviews, and potential regulatory inquiries.</li></ul>`);
        processesHtml += renderStep('Step 2: Review and Approval Process', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The Final PIA Report and organizational approval procedures.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The PIA undergoes a <strong>formal review and approval process</strong> appropriate to the risk level:
                <ul class="bulleted-list"><li style="list-style-type:circle">The DPO reviews all PIAs for quality, completeness, and compliance with requirements</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">For low-risk PIAs, the DPO's approval may be sufficient</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">For medium-risk PIAs, approval typically includes the System/Process Owner and relevant Department Head</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">For high-risk PIAs, additional approval from senior management, Legal, and possibly the Privacy Governance Committee is required</li></ul>
                <p>Reviewers evaluate whether:</p>
                <ul class="bulleted-list"><li style="list-style-type:circle">The assessment is thorough and accurately captures the processing activities</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The risk assessment is reasonable and comprehensive</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Proposed mitigations are appropriate and sufficient</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Implementation plans are realistic and adequately resourced</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Any accepted risks are appropriate to accept and properly documented</li></ul>
                <p>If reviewers identify concerns or gaps, the PIA team makes necessary revisions and resubmits for approval. For high-risk processing where risks cannot be sufficiently mitigated, the DPO determines whether prior consultation with the supervisory authority is required under GDPR Article 36. If so, the consultation process is initiated before final approval.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Approved PIA with Sign-offs</strong> - The final PIA report with formal approvals from all required stakeholders, documenting their acceptance of the findings and mitigation plan. Approvals may be collected via electronic signatures, approval workflow systems, or documented email confirmations, depending on the organization's practices. The approved document is stored with appropriate version control. If supervisory authority consultation was required, the response and any additional requirements are incorporated into the final approved document.</li></ul>`);
        processesHtml += renderStep('Step 3: Implement Mitigation Measures', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> The Approved PIA with Mitigation Action Plan and assigned responsibilities.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The Project Manager, in collaboration with assigned owners, ensures the <strong>implementation of agreed mitigation measures</strong>:
                <ul class="bulleted-list"><li style="list-style-type:circle">The Mitigation Action Plan is translated into specific work items and tasks</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Tasks are assigned to responsible individuals with clear deadlines</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Implementation is tracked through the project management system</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Technical measures are implemented by IT and verified by security teams</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Organizational measures (policies, procedures) are developed by relevant departments</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Training and awareness materials are created and delivered as needed</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">The DPO or Privacy Team provides guidance and support during implementation</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Regular status updates are provided to key stakeholders</li></ul>
                <p>Implementation should occur before processing begins (for new initiatives) or according to an agreed timeline (for changes to existing processing). The Project Manager ensures all critical mitigations are in place before go-live and tracks any deferred items. The DPO monitors implementation progress and escalates any delays in critical mitigations to appropriate management levels.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Implementation Verification Report</strong> - Documentation confirming that mitigation measures have been implemented as planned, with evidence where appropriate (e.g., screenshots of system controls, policy documents, training records). Any deviations from the original plan are documented with justification and alternative approaches. This verification serves as evidence of the organization's commitment to privacy by design and accountability. The Project Manager and DPO sign off on this verification before processing begins or changes are deployed.</li></ul>`);
        processesHtml += renderStep('Step 4: Post-Implementation Review and Ongoing Monitoring', `
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Input:</strong> Implemented system or process with privacy controls, the original PIA report, and organization's monitoring procedures.</li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Activity:</strong> The DPO/Privacy Team, in collaboration with the System/Process Owner, conducts <strong>post-implementation reviews and establishes ongoing monitoring</strong>:
                <ul class="bulleted-list"><li style="list-style-type:circle">A post-implementation review is scheduled 3-6 months after deployment to verify controls are working as intended</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Effectiveness of mitigations is evaluated against the original risk assessment</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Any new or changed risks are identified and assessed</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Compliance with the original processing description is verified</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Feedback from operational teams and users is collected and analyzed</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Key performance metrics related to privacy controls are established and monitored</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Regular reviews are scheduled based on risk level (e.g., annually for high-risk processing)</li></ul>
                <p>The PIA is treated as a living document that should be updated when significant changes occur to the processing activities, the organizational context, or the regulatory environment. The System/Process Owner is responsible for notifying the DPO of any significant changes that might trigger a reassessment.</p></li></ul>
            <ul class="bulleted-list"><li style="list-style-type:disc"><strong>Output:</strong> <strong>Monitoring and Review Framework</strong> - A documented approach for ongoing oversight of the processing activity, including:
                <ul class="bulleted-list"><li style="list-style-type:circle">Schedule for periodic reviews</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Metrics and monitoring mechanisms</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Responsibilities for continuous compliance</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Trigger events that would require reassessment</li></ul>
                <ul class="bulleted-list"><li style="list-style-type:circle">Process for handling changes and updates</li></ul>
                <p>This framework is integrated into the organization's broader privacy management program and governance structure. Regular status reports are provided to senior management as part of privacy program reporting. The PIA Registry is updated with review dates and status information, ensuring continued visibility of all assessed processing activities.</p></li></ul>`);
        processSectionRendered = true;


        // --- Add Section 5 Heading and Content if applicable ---
        if (processSectionRendered) {
            htmlContent += renderSectionHeading('Detailed Processes');
            htmlContent += `<p>The following processes detail how to carry out Privacy Impact Assessment activities step by step. Each process includes multiple steps, and <strong>each step is described with its Input, Activity, and Output</strong> to clarify how the procedure is executed and to maintain traceability to compliance requirements:</p>`;
            htmlContent += processesHtml;
        }


        return htmlContent; // Return the final combined HTML
    },

    shouldShow: (controlsMatrix) => {
        // Show this SOP if the organization acts as a Controller and PC7205 is enabled.
        const relevantControl = 'PC7205';
        if (!controlsMatrix || typeof controlsMatrix !== 'object') {
            return false;
        }
        // Check if PC7205 is enabled for *any* activity
        return Object.values(controlsMatrix).some(activityControls =>
             activityControls && typeof activityControls === 'object' && activityControls[relevantControl] === true
        );
    },


    getIntroText: () => {
        return 'This Standard Operating Procedure (SOP) defines the process for conducting Privacy Impact Assessments (PIAs) or Data Protection Impact Assessments (DPIAs) for Data Controllers.';
    }
};

export default SOPPrivacyImpactAssessment; // Renamed component export