import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useUserData } from '../../hooks/useUserData';

// Import utility components and hooks
import AssetActionSelection from './AssetActionSelection';
import AssetSelectionView from './AssetSelectionView';
import AssetCreationView from './AssetCreationView';
import { useAssetHierarchyData } from './AssetDataLoader';

/**
 * Modal component for asset selection with filtering capabilities
 * 
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to close the modal
 * @param {Function} props.onSelect - Callback when assets are selected
 * @param {Object} props.currentAsset - Current asset for editing mode
 * @param {Array} props.existingAssets - Assets already associated with parent entity
 * @param {Object} props.filter - Optional filter configuration
 * @param {string} props.filter.categoryId - Filter by category ID
 * @param {string} props.filter.classId - Filter by class ID
 * @param {Array} props.filter.classIds - Filter by multiple class IDs
 * @param {string} props.title - Optional custom title for the modal
 */
const AssetSelectionModal = ({
    isOpen,
    onClose,
    onSelect,
    currentAsset = null,
    existingAssets = [],
    filter = null,
    title = null
}) => {
    const { userData } = useUserData();
    const { t } = useTranslation();
    const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

    // For debugging
    console.log("Current user language:", userLang);
    if (filter) {
        console.log("Asset selection filter:", filter);
    }

    // State for modal mode
    const [mode, setMode] = useState('initial'); // 'initial', 'create', 'select'

    // State for selected assets (when in 'select' mode)
    const [selectedAssets, setSelectedAssets] = useState([]);

    // State for error display
    const [error, setError] = useState(null);

    // Load asset hierarchy data (categories, classes, types)
    const hierarchyData = useAssetHierarchyData(true, filter);

    // Reset state when modal opens/closes
    useEffect(() => {
        if (isOpen) {
            console.log("AssetSelectionModal opened with filter:", filter);
            console.log("Current assets:", existingAssets?.length || 0);

            if (currentAsset) {
                // Edit asset flow - skip initial selection screen
                setMode('create');
            } else {
                // New flow - start with selection screen
                setMode('initial');
            }

            // Reset selected assets
            setSelectedAssets([]);
            setError(null);

            // Preload hierarchy data when modal opens
            hierarchyData.loadAllHierarchyData().then(() => {
                console.log("Hierarchy data loaded: ", {
                    categories: hierarchyData.categories.length,
                    classes: hierarchyData.allAssetClasses.length,
                    types: hierarchyData.allAssetTypes.length
                });
            });
        }
    }, [isOpen, currentAsset, filter]);

    // Handle create new asset option
    const handleCreateNewAsset = () => {
        setMode('create');
    };

    // Handle select existing asset option
    const handleSelectExistingAsset = () => {
        setMode('select');
    };

    // Get category icon for display
    const getCategoryIcon = (category) => {
        if (!category || !category.icons) return null;
        const iconPath = `/is_icons/${category.icons}`;
        return iconPath;
    };

    // Get the appropriate modal title based on the current mode
    const getModalTitle = () => {
        if (title) return title;

        if (currentAsset) {
            return t('Edit Asset');
        }

        switch (mode) {
            case 'select':
                return t('Select Asset');
            case 'create':
                return t('Add New Asset');
            default:
                return t('Asset Management');
        }
    };

    // Handle going back to the initial mode
    const handleBack = () => {
        if (mode === 'select' || mode === 'create') {
            setMode('initial');
            setError(null);
            setSelectedAssets([]);
        } else {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>

                <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle md:max-w-3xl">
                    <div className="bg-white flex flex-col h-full max-h-screen">
                        {/* Modal Header */}
                        <div className="flex justify-between items-center px-6 py-1 border-b">
                            <h2 className="text-lg font-medium pt-5">
                                {getModalTitle()}
                            </h2>
                            <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={onClose}
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>

                        {/* Error Messages */}
                        {error && (
                            <div className="m-4 p-3 bg-red-50 text-red-600 rounded-md flex items-start">
                                <XMarkIcon className="h-5 w-5 text-red-400 mr-2 flex-shrink-0" />
                                <span>{error}</span>
                            </div>
                        )}

                        {/* Modal Content based on current mode */}
                        <div className="flex-grow">
                            {/* Initial Selection Screen */}
                            {mode === 'initial' && (
                                <AssetActionSelection
                                    onCreateNew={handleCreateNewAsset}
                                    onSelectExisting={handleSelectExistingAsset}
                                    userLang={userLang}
                                />
                            )}

                            {/* Select from Existing Assets View */}
                            {mode === 'select' && (
                                <AssetSelectionView
                                    selectedAssets={selectedAssets}
                                    setSelectedAssets={setSelectedAssets}
                                    existingAssets={existingAssets}
                                    onConfirm={(assets) => {
                                        onSelect(assets);
                                        onClose();
                                    }}
                                    onCancel={handleBack} // Pass the handleBack function as onCancel
                                    setError={setError}
                                    filter={filter}
                                    userLang={userLang}
                                    getCategoryIcon={getCategoryIcon}
                                    multiSelect={true}
                                    allAssetTypes={hierarchyData.allAssetTypes}
                                    allAssetClasses={hierarchyData.allAssetClasses}
                                    allAssetCategories={hierarchyData.categories}
                                />
                            )}

                            {/* Create New Asset View */}
                            {mode === 'create' && (
                                <AssetCreationView
                                    currentAsset={currentAsset}
                                    onSave={(assetData) => {
                                        onSelect(assetData);
                                        onClose();
                                    }}
                                    onCancel={handleBack}
                                    filter={filter}
                                    initialFiltered={!!filter}
                                    hierarchyData={hierarchyData}
                                    userLang={userLang}
                                />
                            )}
                        </div>

                        {/* Modal Footer - only shown for initial mode */}
                        {mode === 'initial' && (
                            <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-3 border-t mt-auto">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                                >
                                    {t('Cancel')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetSelectionModal;