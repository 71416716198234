import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Slider.css'; // Ensure consistent styles

const OneSlider = ({ leftLabel, rightLabel, min = 0, max = 100, step = 1, value, onChange }) => {
  return (
    <div className="w-full mb-4">
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm font-semibold text-gray-900">{leftLabel}</span>
        <span className="text-sm font-semibold text-gray-900">{rightLabel}</span>
      </div>
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        trackStyle={[{ backgroundColor: '#4C9A92', height: 8 }]} /* Green Primary */
        handleStyle={[
          { borderColor: '#4C9A92', backgroundColor: '#4C9A92', height: 18, width: 18, opacity: 1 }, /* No transparency */
        ]}
        railStyle={{ backgroundColor: '#E5E7EB', height: 8 }} /* Gray Rail */
      />
    </div>
  );
};

OneSlider.propTypes = {
  name: PropTypes.string.isRequired,
  leftLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  rightLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OneSlider;
