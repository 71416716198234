// src/pages/LogoutSuccess.js
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LogoutSuccess() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('logoutSuccess')}</h2>
        <p className="text-center text-gray-700 mb-4">
          {t('logoutMessage')}
        </p>
      </div>
    </div>
  );
}
