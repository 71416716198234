import React, { useState, useEffect } from 'react';
import axios from '../../axios'; 
import { useNavigate, useParams } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [message, setMessage] = useState('');
  const [errorType, setErrorType] = useState('');
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const { t } = useTranslation();

  // Clear error messages when user types in the password fields
  useEffect(() => {
    if (message) {
      setMessage('');
      setErrorType('');
    }
  }, [password, passwordConfirmation]);

  const validatePasswords = () => {
    // Check password length
    if (password.length < 6) {
      setErrorType('password_too_short');
      setMessage(t('password_too_short'));
      return false;
    }
    
    // Check if passwords match
    if (password !== passwordConfirmation) {
      setErrorType('password_mismatch');
      setMessage(t('passwords_do_not_match'));
      return false;
    }
    
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Client-side validation first
    if (!validatePasswords()) {
      return;
    }
    
    try {
      const response = await axios.put('/api/v1/users/password', {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetToken,
        }
      });

      if (response.status === 200) {
        navigate('/password-reset-confirmation');
      }
    } catch (error) {
      console.error('Error resetting password:', error.response?.data || error.message);
      
      // Check for specific error types
      if (error.response?.data?.error_code === 'password_mismatch') {
        setErrorType('password_mismatch');
        setMessage(t('passwords_do_not_match'));
      } else if (error.response?.data?.error_code === 'password_too_short') {
        setErrorType('password_too_short');
        setMessage(t('password_too_short'));
      } else if (error.response?.data?.errors?.some(err => err.includes('Password is too short'))) {
        setErrorType('password_too_short');
        setMessage(t('password_too_short'));
      } else if (error.response?.data?.errors?.some(err => err.includes('Password confirmation') || err.includes('match'))) {
        setErrorType('password_mismatch');
        setMessage(t('passwords_do_not_match'));
      } else {
        setErrorType('general_error');
        setMessage(t('error_resetting_password'));
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('reset_password')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-tertiary-color">{t('new_password')}</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`mt-1 block w-full rounded-md ${errorType ? 'border-red-300' : 'border-gray-300'} shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2 pr-10`}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-1/2 right-0 transform -y-1/2 px-3 flex items-center text-sm leading-5">
                {showPassword ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
              </button>
            </div>
            <div className="relative">
              <label htmlFor="passwordConfirmation" className="block text-sm font-medium text-tertiary-color">{t('confirm_new_password')}</label>
              <input
                type={showPasswordConfirmation ? 'text' : 'password'}
                name="passwordConfirmation"
                id="passwordConfirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                className={`mt-1 block w-full rounded-md ${errorType ? 'border-red-300' : 'border-gray-300'} shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2 pr-10`}
              />
              <button
                type="button"
                onClick={togglePasswordConfirmationVisibility}
                className="absolute top-1/2 right-0 transform -y-1/2 px-3 flex items-center text-sm leading-5">
                {showPasswordConfirmation ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
              </button>
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-x-6">
            <button
              type="button"
              className="text-sm text-primary-color"
              onClick={() => navigate('/login')}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              disabled={!password || !passwordConfirmation}
              className={`rounded-md px-6 py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color ${
                !password || !passwordConfirmation 
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                  : 'bg-primary-color text-white hover:bg-secondary-color'
              }`}
            >
              {t('reset_password')}
            </button>
          </div>
          {message && (
            <p className={`mt-4 text-sm ${errorType === 'password_mismatch' || errorType === 'password_too_short' ? 'text-red-500' : 'text-red-500'}`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}