import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import Flag from 'react-world-flags';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLanguageModalOpen(false);
  };

  if (!i18n.isInitialized) {
    return <div>Loading...</div>;
  }

  const NavigationLinks = ({ className = "", onClick = () => {} }) => (
    <>
      <Link 
        to="/login" 
        className={className}
        onClick={onClick}
      >
        {t('login')}
      </Link>
      <Link 
        to="/privacy" 
        className={className}
        onClick={onClick}
      >
        {t('imprint')}
      </Link>
      <Link 
        to="/terms" 
        className={className}
        onClick={onClick}
      >
        {t('toc')}
      </Link>
      <Link
        to="https://status.cenedril.net/"
        className={className}
        onClick={onClick}
      >
        Status
      </Link>
    </>
  );

  const LanguageModal = () => (
    <Transition.Root show={isLanguageModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[60]" onClose={setIsLanguageModalOpen}>
        <div className="fixed inset-0 z-[60]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-color bg-opacity-50 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all w-full max-w-md mx-4">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setIsLanguageModalOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="mt-3 text-center">
                      <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900 mb-6">
                        {t('chooseLanguage')}
                      </Dialog.Title>
                      <div className="mt-2 flex flex-col items-center space-y-4">
                        <button
                          onClick={() => changeLanguage('en')}
                          className="flex items-center space-x-3 hover:bg-gray-100 px-6 py-3 rounded-md w-full justify-center transition-colors"
                        >
                          <Flag code="GB" style={{ width: '32px', height: '20px' }} />
                          <span className="text-gray-900 font-medium">English</span>
                        </button>
                        <button
                          onClick={() => changeLanguage('de')}
                          className="flex items-center space-x-3 hover:bg-gray-100 px-6 py-3 rounded-md w-full justify-center transition-colors"
                        >
                          <Flag code="DE" style={{ width: '32px', height: '20px' }} />
                          <span className="text-gray-900 font-medium">Deutsch</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  const MobileMenu = () => (
    <Transition.Root show={isMobileMenuOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsMobileMenuOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary-color bg-opacity-50 backdrop-blur-md transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all w-full max-w-sm">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex flex-col space-y-6 items-center">
                  <button
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      setIsLanguageModalOpen(true);
                    }}
                    className="text-gray-900 font-semibold"
                  >
                    {t('chooseLanguage')}
                  </button>
                  <div className="flex flex-col space-y-4 items-center">
                    <NavigationLinks 
                      className="text-gray-900 hover:text-gray-700"
                      onClick={() => setIsMobileMenuOpen(false)}
                    />
                  </div>
                  <Link 
                    to="/" 
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    &copy; Cenedril, 2025
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <>
      {/* Desktop Footer */}
      <footer className="bg-primary-color text-white py-4 fixed bottom-0 w-full z-30 hidden md:block">
        <div className="container mx-auto px-4">
          {/* Large Desktop Layout (>1024px) */}
          <div className="hidden lg:flex justify-between items-center">
            <div>
              <Link to="/" className="text-white">
                &copy; Cenedril, 2025
              </Link>
            </div>
            <div className="flex space-x-6">
              <NavigationLinks className="text-white hover:text-gray-200" />
            </div>
            <div>
              <button 
                className="text-white font-bold hover:text-gray-200"
                onClick={() => setIsLanguageModalOpen(true)}
              >
                {t('chooseLanguage')}
              </button>
            </div>
          </div>

          {/* Small Desktop Layout (768px-1024px) */}
          <div className="flex lg:hidden flex-col items-center space-y-4">
            <button 
              className="text-white font-bold hover:text-gray-200"
              onClick={() => setIsLanguageModalOpen(true)}
            >
              {t('chooseLanguage')}
            </button>
            <div className="flex space-x-6">
              <NavigationLinks className="text-white hover:text-gray-200" />
            </div>
            <div>
              <Link to="/" className="text-white">
                &copy; Cenedril, 2025
              </Link>
            </div>
          </div>
        </div>
      </footer>

      {/* Mobile Burger Menu Button */}
      <div className="fixed top-4 right-4 z-40 md:hidden">
        <button
          onClick={() => setIsMobileMenuOpen(true)}
          className="bg-primary-color text-white p-2 rounded-lg shadow-lg hover:bg-opacity-90 transition-colors"
          aria-label="Open menu"
        >
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>

      <LanguageModal />
      <MobileMenu />
    </>
  );
}