// src/components/wizard_components/DataPrivacyWizard/SOPConsentManagement.js
import React from 'react';

const hasControlApplied = (controlsMatrix, controlId) => {
    // Log the structure for debugging
    console.log("Control matrix structure:", JSON.stringify(controlsMatrix, null, 2));
    console.log("Looking for control:", controlId);

    // Check if the control is applied to at least one activity
    return Object.values(controlsMatrix).some(activityControls => {
        console.log("Activity controls:", JSON.stringify(activityControls, null, 2));
        return activityControls[controlId] === true;
    });
};

// Helper function to check if an execution criteria is overridden
const isExecutionCriteriaOverridden = (controlsMatrix, riskControlOverrides, controlId, criteriaId) => {
    // First check if the control is applied at all
    const isControlApplied = hasControlApplied(controlsMatrix, controlId);

    if (!isControlApplied) {
        return true; // Consider it "overridden" if the control isn't applied at all
    }

    // Check if the control has any overrides
    if (riskControlOverrides && riskControlOverrides[controlId]) {
        // Check if the specific criteria is in the overrides array
        return riskControlOverrides[controlId].includes(criteriaId);
    }

    return false; // Not overridden if no overrides exist
};

/**
 * This component handles the generation of SOP for Consent Management
 * It contains the logic to produce appropriate content based on the organization's 
 * controls and processing activities
 */
const SOPConsentManagement = {

    /**
     * Generate content for the SOP
     * @param {Object} controlsMatrix - The selected risk controls from TOMs
     * @param {Object} riskControlOverrides - Any overrides applied to risk controls
     * @param {Array} processingActivities - The processing activities from ROPA
     * @param {Object} entityInfo - Organization information
     * @returns {String} HTML content for the policy
     */
    generateContent: (controlsMatrix, riskControlOverrides, processingActivities, entityInfo) => {
        const orgName = entityInfo.name || '[Your Organization Name]';

        console.log('Control matrix:', controlsMatrix);
        console.log('Risk control overrides:', riskControlOverrides);

        // Check which controls are applied
        // PC7203 - Determine when and how consent is to be obtained
        const hasPC7203 = hasControlApplied(controlsMatrix, 'PC7203');
        const isPC72031aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7203', '1a');
        const isPC72031bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7203', '1b');

        // PC7204 - Obtain and record consent
        const hasPC7204 = hasControlApplied(controlsMatrix, 'PC7204');
        const isPC72041aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1a');
        const isPC72041bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1b');
        const isPC72041cOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7204', '1c');

        // PC7304 - Providing mechanism to modify or withdraw consent
        const hasPC7304 = hasControlApplied(controlsMatrix, 'PC7304');
        const isPC73041aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1a');
        const isPC73041bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1b');
        const isPC73041cOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7304', '1c');

        // PC7305 - Providing mechanism to object to PII processing
        const hasPC7305 = hasControlApplied(controlsMatrix, 'PC7305');
        const isPC73051aOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7305', '1a');
        const isPC73051bOverridden = isExecutionCriteriaOverridden(controlsMatrix, riskControlOverrides, 'PC7305', '1b');


        // 1. Legal/Standard Basis

        // Generate the ISO/IEC references based on applied controls
        let isoControlsText = '';

        const appliedControls = [];
        if (hasPC7203) appliedControls.push("<strong>PC7203</strong> (determine when and how consent is obtained)");
        if (hasPC7204) appliedControls.push("<strong>PC7204</strong> (obtain and record consent)");
        if (hasPC7304) appliedControls.push("<strong>PC7304</strong> (provide mechanism to modify or withdraw consent)");
        if (hasPC7305) appliedControls.push("<strong>PC7305</strong> (provide mechanism to object to processing of PII)");

        if (appliedControls.length > 0) {
            isoControlsText = `<ul>
      <li><strong>ISO/IEC 27701:2021 (PII Controller Controls)</strong> – This SOP fulfills controls ${appliedControls.join(', ')}. These controls require the organization, as a data controller, to manage consent in compliance with privacy principles.</li>`;
        }


        // 2. Purpose

        // Generate the purpose section based on applied controls
        let purposeContent = '<h2>2. Purpose</h2>\n<p>Ensure a consistent and compliant approach to <strong>consent management</strong> for personal data processing. This SOP defines how our organization ';

        // Add verbs based on applied controls
        let verbs = [];
        if (hasPC7203) verbs.push("obtains");
        if (hasPC7204) verbs.push("records");
        if (hasPC7304 || hasPC7305) verbs.push("manages");

        // Join verbs with commas and "and"
        if (verbs.length > 0) {
            if (verbs.length === 1) {
                purposeContent += verbs[0];
            } else if (verbs.length === 2) {
                purposeContent += verbs.join(" and ");
            } else {
                const lastVerb = verbs.pop();
                purposeContent += verbs.join(", ") + ", and " + lastVerb;
            }

            purposeContent += " data subject consent as a legal basis for processing";
        } else {
            purposeContent += "handles consent-related matters";
        }

        purposeContent += `, in line with ISO 27701 and GDPR. It aims to protect data subject rights and maintain evidence of compliance (demonstrating that valid consent was obtained or that an alternative lawful basis is used). Adhering to this procedure helps maintain transparency and trust with individuals while reducing legal risk.</p>`;

        // 3. KPIs

        let kpisContent = '<h2>3. Key Performance Indicators (KPIs)</h2>';

        // Only include consent documentation KPI if PC7204 is applied
        if (hasPC7204) {
            kpisContent += `
<ul>
  <li><strong>Consent Documentation Rate:</strong> 100% of processing activities that rely on consent have corresponding consent records on file (audit trail with who consented, when, and what was consented to).</li>
</ul>`;
        }

        // Only include withdrawal processing KPI if PC7304 is applied
        if (hasPC7304) {
            kpisContent += `
<ul>
  <li><strong>Withdrawal Processing Time:</strong> Average time to honor a consent withdrawal request (target: e.g. within 5 business days). All withdrawal or modification requests are resolved within the published SLA (e.g. 30 days maximum).</li>
</ul>`;
        }

        // Only include objection handling KPI if PC7305 is applied
        if (hasPC7305) {
            kpisContent += `
<ul>
  <li><strong>Objection Handling Rate:</strong> 100% of data subject objections are logged and evaluated, with responses provided to the individual within the required timeframe (e.g. 30 days as per GDPR). For direct marketing objections, processing is ceased immediately (target: 0 incidents of non-compliance).</li>
</ul>`;
        }

        // Include training and audit KPIs if any consent control is applied
        if (hasPC7203 || hasPC7204 || hasPC7304 || hasPC7305) {
            kpisContent += `
  <ul>
    <li><strong>Training and Awareness:</strong> 100% of relevant staff (e.g. marketing, product, IT) trained annually on consent procedures and tools. (Measured via training completion records.)</li>
  </ul>
  <ul>
    <li><strong>Audit Findings:</strong> No major non-conformities in internal/external audits regarding consent management (e.g. all sampled consents are valid and properly recorded; no instances of missing parental consent for minors or missing explicit consent for special data).</li>
  </ul>`;
        }

        // 4. Responsibilities

        // Generate the responsibilities section based on applied controls
        let responsibilitiesContent = '<h2>4. Responsibilities</h2>';

        // Always include DPO/Privacy Officer with tailored description
        responsibilitiesContent += `
<ul>
  <li><strong>Data Protection Officer (DPO) / Privacy Officer:</strong> Oversees the consent management process and ensures it meets regulatory standards. `;

        if (hasPC7203) {
            responsibilitiesContent += `Advises on when consent is needed versus other legal bases. `;
        }

        responsibilitiesContent += `Reviews and updates this SOP as laws or business needs change. Monitors compliance `;

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `and handles complex cases (e.g. withdrawal requests that may implicate data deletion) and any complaints related to consent.`;
        } else {
            responsibilitiesContent += `related to consent management.`;
        }

        responsibilitiesContent += `</li>
</ul>`;

        // Legal & Compliance Team
        responsibilitiesContent += `
<ul>
  <li><strong>Legal &amp; Compliance Team:</strong> `;

        if (hasPC7203 || hasPC7204) {
            responsibilitiesContent += `Drafts and approves consent language and consent collection mechanisms to ensure they meet GDPR conditions (clear, specific, informed). `;
        }

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `Ensures privacy notices inform data subjects of their right to withdraw consent and to object, as required by GDPR. `;
        }

        responsibilitiesContent += `Keeps track of relevant legal changes (e.g. allowable age of consent per region, new guidance on consent) and communicates them to process owners.</li>
</ul>`;

        // Business Process Owners / Data Owners
        responsibilitiesContent += `
<ul>
  <li><strong>Business Process Owners / Data Owners:</strong> `;

        if (hasPC7203) {
            responsibilitiesContent += `Identify whether a planned processing activity will use consent as the legal basis. Before launching any new data collection or use, consult with Privacy/Legal to determine if consent is required (and appropriate). `;
        }

        if (hasPC7204) {
            responsibilitiesContent += `Implement the consent collection as per this SOP (e.g. include proper consent prompts in forms) and ensure no data processing starts without the necessary consent. `;
        }

        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `Responsible for honoring withdrawals or objections in their business area (e.g. stopping a marketing campaign for those who withdraw).`;
        }

        responsibilitiesContent += `</li>
</ul>`;

        // IT & System Owners
        if (hasPC7204 || hasPC7304) {
            responsibilitiesContent += `
<ul>
  <li><strong>IT &amp; System Owners:</strong> Implement and maintain systems for capturing, storing, and managing consent records. This includes frontend (e.g. web forms, app interfaces with consent checkboxes or toggles) and backend (databases or consent management platforms logging consent details). `;

            if (hasPC7204) {
                responsibilitiesContent += `Ensure <strong>consent records</strong> include timestamp, data subject identity (or pseudonymized ID), version of consent text, and scope of consent (what purposes it covers). `;
            }

            if (hasPC7304) {
                responsibilitiesContent += `Also implement mechanisms that enable data subjects to withdraw consent as easily as it was given (e.g. self-service portals, unsubscribe links). `;
            }

            responsibilitiesContent += `Apply access controls to consent records and include them in data backups as needed for audit trail.</li>
</ul>`;
        }

        // Customer Support / Data Subject Request Team
        if (hasPC7304 || hasPC7305) {
            responsibilitiesContent += `
<ul>
  <li><strong>Customer Support:</strong> Acts as the contact point for receiving `;

            let requests = [];
            if (hasPC7304) requests.push("consent withdrawals");
            if (hasPC7305) requests.push("objections");

            responsibilitiesContent += requests.join(" or ") + ` from data subjects via official channels (email, web portal, phone). Follows the Processes in Section 5 to verify and execute the requests. Communicates outcomes to data subjects (confirmation of withdrawal or processing stopped) and updates internal records accordingly. Escalates any unusual requests (e.g. unclear scope of withdrawal) to the DPO.</li>
</ul>`;
        }

        // All Employees - include if any consent control is applied
        if (hasPC7203 || hasPC7204 || hasPC7304 || hasPC7305) {
            responsibilitiesContent += `
<ul>
  <li><strong>All Employees (General Obligation):</strong> If any employee is involved in collecting personal data directly from individuals (e.g. via phone or in person), they must follow this SOP – `;

            if (hasPC7204) {
                responsibilitiesContent += `use approved consent scripts/forms `;
            }

            if ((hasPC7204 && (hasPC7304 || hasPC7305))) {
                responsibilitiesContent += `and `;
            }

            if (hasPC7304 || hasPC7305) {
                let forwards = [];
                if (hasPC7304) forwards.push("withdrawal");
                if (hasPC7305) forwards.push("objection");
                responsibilitiesContent += `forward any ${forwards.join(" or ")} requests to the appropriate team immediately. `;
            }

            if (hasPC7203 || hasPC7204) {
                responsibilitiesContent += `Employees must not attempt to coerce consent `;
            }

            if ((hasPC7203 || hasPC7204) && hasPC7304) {
                responsibilitiesContent += `or ignore a withdrawal. `;
            } else if (hasPC7304) {
                responsibilitiesContent += `Employees must not ignore a withdrawal. `;
            } else if (hasPC7203 || hasPC7204) {
                responsibilitiesContent += `. `;
            }

            responsibilitiesContent += `They should seek guidance from Privacy if unsure about consent requirements in any situation.</li>
</ul>`;
        }

        // Generate Process 5.1 content
        let process51Content = '';

        if (hasPC7203) {
            process51Content = `
<h3>5.1 Determine When and How Consent Must Be Obtained</h3>
<p><strong>Input:</strong></p>
<p>A proposed <strong>new processing activity</strong> or change to an existing activity involving personal data, typically documented in a project plan. This includes details of the intended purpose, data types (including if any special category data), and target subjects (e.g. adults, children).</p>
<p><strong>Activities:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Assess Lawful Basis:</strong> The Privacy Officer or Process Owner evaluates the <strong>purpose of processing and context</strong> to decide if consent is the appropriate lawful basis, or if another basis (e.g. contract, legal obligation, legitimate interests) is more suitable. Use consent only if the data subject can be given a real choice (processing is not strictly necessary for a service) and none of the other Article 6 bases clearly apply.</li>
</ol>`;

            if (!isPC72031aOverridden) {
                process51Content += `
<ol type="1" class="numbered-list" start="2">
  <li><strong>Identify Special Cases:</strong> Determine if the processing involves special categories of personal data or criminal-record data. If yes, note that <strong>explicit consent</strong> will be required (per GDPR Art. 9(2)(a)) and check if any national laws restrict using consent for that purpose. Also determine if data subjects include <strong>children</strong> under the age of consent for digital services (age threshold varies by country, 13–16). If children's data is involved, plan to obtain verifiable parental consent as required by GDPR Art. 8.</li>
</ol>`;
            } else {
                process51Content += `
<ol type="1" class="numbered-list" start="2">
  <li><strong>Identify Special Cases:</strong> Determine if the processing involves special categories of personal data or criminal-record data. If yes, note that <strong>explicit consent</strong> will be required (per GDPR Art. 9(2)(a)) and check if any national laws restrict using consent for that purpose.</li>
</ol>`;
            }

            process51Content += `
<ol type="1" class="numbered-list" start="3">
  <li><strong>Determine "When" to Obtain:</strong> Decide at what point in the user journey or process the consent will be obtained. It must be <strong>prior to any processing</strong> of the personal data for that purpose. For example, in an online form, consent should be requested <strong>before data submission</strong>; for an app, at first launch or before enabling a feature that collects personal data. Document this in the project plan.</li>
</ol>`;

            process51Content += `
<ol type="1" class="numbered-list" start="4">
  <li><strong>Determine "How" (Method):</strong> Choose an appropriate <strong>consent mechanism</strong> suitable for the context: e.g. a checked box on a web form (unchecked by default), a dedicated consent screen in an app, a physical consent form, or an email confirmation (for explicit consent, a written or similarly robust method is recommended). `;

            // Only include this part if PC72031b is not overridden
            if (!isPC72031bOverridden) {
                process51Content += `Ensure the consent request will be presented <strong>separately from other terms</strong> and in plain language. `;
            } else {
                process51Content += `Ensure the consent request will be presented in plain language. `;
            }

            // Only include children-specific guidance if PC72031a is not overridden
            if (!isPC72031aOverridden) {
                process51Content += `For children, design a method to involve parental approval (such as a parental consent form or email verification of parent/guardian). `;
            }

            process51Content += `For sensitive data, consider a method that captures an explicit affirmative action (e.g. digital signature or ticking an "I consent to [sensitive data] processing" box).</li>
</ol>
<ol type="1" class="numbered-list" start="5">
  <li><strong>Draft Consent Wording:</strong> With Legal/Compliance assistance, draft the consent statement that will be shown to data subjects. It should clearly state the <strong>specific purpose</strong> of processing and the <strong>data to be processed</strong>, and refer to the relevant privacy notice for full details. Ensure it includes all required elements (e.g. identity of controller, purpose, data types, the fact they can withdraw anytime). If the consent covers multiple purposes or data uses, ensure each is listed or consider obtaining separate consent for distinct purposes`;

            // Only include this part if PC72031b is not overridden
            if (!isPC72031bOverridden) {
                process51Content += ` (avoid bundling unrelated consents)`;
            }

            process51Content += `.</li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Review and Approval:</strong> The DPO/Privacy team reviews the plan (lawful basis choice, method and wording) to ensure it meets ISO/GDPR criteria. Particularly, verify that consent will be <strong>freely given, specific, informed, and unambiguous</strong>. `;

            // Only include this part if PC72031b is not overridden
            if (!isPC72031bOverridden) {
                process51Content += `Also confirm that not giving consent will not lead to denying a service that is unrelated (to avoid <strong>coercive consent</strong>, per GDPR 7(4) about not making a contract conditional on unnecessary consent). `;
            }

            process51Content += `Once approved, the method and wording are finalized for implementation.</li>
</ol>
<p><strong>Output:</strong></p>
<p>A <strong>documented decision in Cenedril</strong> on lawful basis for the processing. If consent is chosen, the output includes a <strong>Consent Collection Plan</strong>: detailing when and how consent will be obtained, the exact consent language, and any special measures`;

            // Only include parental consent flow if PC72031a is not overridden
            if (!isPC72031aOverridden) {
                process51Content += ` (e.g. parental consent flow, explicit consent capture)`;
            } else {
                process51Content += ` (e.g. explicit consent capture)`;
            }

            process51Content += `. This information should be recorded in the <a href="/data-privacy-wizard/ropa">record of processing for that activity</a> (noting "Consent" as the legal basis and linking to the consent method). The project can then proceed to implement the consent collection in systems or processes as specified.</p>`;
        }

        // Generate Process 5.2 content
        let process52Content = '';

        if (hasPC7204) {
            // Determine the correct section number
            const sectionNumber = hasPC7203 ? "5.2" : "5.1";

            process52Content = `
<h3>${sectionNumber} Obtain and Record Consent</h3>
<p><strong>Input:</strong></p>
<p>An <strong>active data collection process</strong> requiring consent, ready to go live (e.g. a website form, mobile app, survey, or any interface with the data subject). Also input includes the <strong>approved consent wording and method</strong>${hasPC7203 ? " from process 5.1" : ""}, and an up-to-date <strong>Privacy Notice</strong> that the consent refers to (detailing what the individual is consenting to).</p>
<p><strong>Activities:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Present Consent Request:</strong> When collecting personal data, present the <strong>consent request clearly and prominently</strong> to the data subject <strong>before processing</strong> the data. The request should be in plain language and <strong>specific to the purpose</strong>. For example: "I agree to [Company] processing my email and preferences to send me the monthly newsletter." `;

            // Only check PC72031b override if PC7203 is applied
            if (hasPC7203 && !isPC72031bOverridden) {
                process52Content += `Ensure the consent request is separate from general terms and conditions (not buried in text) and `;
            }

            process52Content += `requires an explicit action (e.g. ticking an unchecked box or clicking "Accept") by the individual.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Ensure Informed Consent:</strong> Provide access to relevant information <em>at the time of consent</em>. This typically means linking to or displaying the privacy notice or a summary of key information (controller identity, purpose of processing, types of data, right to withdraw consent, etc.). The individual should have the opportunity to read what they are agreeing to. If the request is part of a longer process, allow them to <strong>not consent</strong> and still proceed if consent is optional (or exit if consent is mandatory for that service).`;

            // Only check PC72031a override if PC7203 is applied
            if (hasPC7203 && !isPC72031aOverridden) {
                process52Content += ` For special scenarios:
    <ul>
      <li>If the individual is a <strong>child</strong> under the defined age, direct the consent request to the parent/guardian. For instance, collect the parent's email for consent verification or use an age-gate to prevent underage consent. <strong>Verify parental consent</strong> via an appropriate method (e.g. confirm link sent to parent's email, or require a signature of guardian) before allowing processing of the child's data.</li>
    </ul>
    <ul>
      <li>If <strong>special category data</strong> is involved, explicitly mention the sensitive nature and obtain an <strong>explicit confirmation</strong>. For example: "I explicitly consent to the processing of my health information for [purpose]." Consider a double-confirmation if the risk is high (the user checks a box and additionally clicks a confirm button).</li>
    </ul>`;
            } else {
                process52Content += `
    <ul>
      <li>If <strong>special category data</strong> is involved, explicitly mention the sensitive nature and obtain an <strong>explicit confirmation</strong>. For example: "I explicitly consent to the processing of my health information for [purpose]." Consider a double-confirmation if the risk is high (the user checks a box and additionally clicks a confirm button).</li>
    </ul>`;
            }

            process52Content += `
  </li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Capture Consent Action:</strong> Design the interface such that the individual must take an affirmative action. No pre-ticked boxes or implicit consent (silence or inactivity) is allowed – consent <strong>must be an unambiguous indication</strong> of wishes. Acceptable methods include clicking "Yes, I consent," signing a form, or oral consent recorded in a system (if verbal consent is obtained by phone, the script must be read and an auditable log or recording kept). If the individual declines consent (e.g. does not check the box or says "no"), ensure the system respects this choice (e.g. do not collect or process that data for that purpose, and possibly log that consent was not given if needed for record).</li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Provide Confirmation (Optional Best Practice):</strong> It's good practice to confirm to the individual that their consent was received. For online interactions, a confirmation message like "Thank you, you have consented to X. You may withdraw at any time via [method]." If appropriate, send an email receipt of the consent for their records (especially for sensitive data consent or parental consent scenarios). This reinforces transparency.</li>
</ol>`;

            // Record Keeping section with conditional elements based on execution criteria
            process52Content += `
<ol type="1" class="numbered-list" start="5">
  <li><strong>Record Keeping:</strong> As consent is obtained, the system or process must <strong>create a record</strong> of the consent. Record at minimum: `;

            // Add required record elements based on execution criteria
            let recordElements = [];

            if (!isPC72041bOverridden) {
                recordElements.push("the identity of the data subject (or an identifier/pseudonym if directly identifying info is not stored at this stage)");
            }

            if (!isPC72041cOverridden) {
                recordElements.push("the consent text or an ID/link to the version of the consent wording agreed to");
            }

            if (!isPC72041aOverridden) {
                recordElements.push("the date/time when consent was given");
            }

            // Always include these elements
            recordElements.push("the method (e.g. via web form, IP address or location if relevant, or auditor who recorded a phone consent)");
            recordElements.push("any related context (e.g. version of privacy notice)");

            // Join the elements with commas and "and"
            if (recordElements.length === 1) {
                process52Content += recordElements[0];
            } else if (recordElements.length === 2) {
                process52Content += recordElements.join(" and ");
            } else {
                const lastElement = recordElements.pop();
                process52Content += recordElements.join(", ") + ", and " + lastElement;
            }

            // Continue with parental consent recording if applicable - only check if PC7203 is applied
            if (hasPC7203 && !isPC72031aOverridden) {
                process52Content += `. For parental consent, record details of the parental authorization (which parent, how verified).`;
            }

            process52Content += ` Store these records securely in the designated repository (e.g. consent management database or within the user's profile in our system). These records will be used to demonstrate compliance (per GDPR <strong>Art. 7(1)</strong>, we must be able to prove the data subject consented).</li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Associate Consent with Processing:</strong> Ensure that downstream systems know the individual has consented. For example, mark the data in the CRM as "consent obtained for X purpose" so that processing can proceed. If no consent, the data should be flagged or segregated to prevent use. Implement business logic that <strong>honors the consent status</strong> – only those individuals with recorded consent are included in the processing (e.g. only send marketing emails to those who consented).</li>
</ol>`;

            // Include bundling & coercion section only if PC72031b is not overridden AND PC7203 is applied
            if (hasPC7203 && !isPC72031bOverridden) {
                process52Content += `
<ol type="1" class="numbered-list" start="7">
  <li><strong>Avoid Bundling &amp; Coercion:</strong> If multiple consent requests are presented (for different purposes), allow the user to grant or refuse each separately (do not make consent "all or nothing" unless absolutely necessary). Never refuse a core service solely because a user refused consent for an unrelated additional use (in line with GDPR guidance on freely given consent). If consent is a condition of service, it should only be so when the service <strong>cannot be provided without that data processing</strong> (document the justification in such cases).</li>
</ol>`;
            }

            // Always include monitoring
            // Step number depends on whether the previous step was included
            const monitoringStepNumber = (hasPC7203 && !isPC72031bOverridden) ? "8" : "7";
            process52Content += `
<ol type="1" class="numbered-list" start="${monitoringStepNumber}">
  <li><strong>Monitor and Quality Check:</strong> Periodically verify that consent collection is functioning as designed. For instance, if it's a web form, confirm that the checkbox cannot be bypassed and that a record is indeed created. For phone processes, ensure call scripts include the consent text and that recordings/logs are stored. This is usually done by the process owner or via internal audit. Any issues (e.g. consents not being recorded due to a system bug) should be fixed immediately and assessed for potential compliance impact.</li>
</ol>
<p><strong>Output:</strong></p>
<p>A <strong>valid consent</strong> for each data subject who agreed, captured in the system. The outputs include:</p>
<ul>
  <li><strong>Consent Records</strong> stored in the consent repository or database (with all necessary details to prove consent).</li>
</ul>
<ul>
  <li><strong>Updated <a href="/data-privacy-wizard/ropa">Record of Processing Activity</a>:</strong> The legal basis for the processing is confirmed as consent, and references to where consent records are kept are added.</li>
</ul>
<ul>
  <li><strong>Proceed with Processing:</strong> Individuals who provided consent are now eligible for the intended processing. Those who did not consent are excluded (and, if applicable, may be offered an alternative or simply not receive the optional service/benefit tied to consent).</li>
</ul>
<ul>
  <li>The organization now has up-to-date evidence that can be provided to regulators or auditors showing that any personal data we process under the "consent" basis was gathered with a proper consent in place.</li>
</ul>`;
        }

        // Generate Process 5.3 content
        let process53Content = '';

        if (hasPC7304) {
            // Determine the correct section number based on which previous sections exist
            let sectionNumber = "5.1"; // Default if no previous sections
            if (hasPC7203 && hasPC7204) {
                sectionNumber = "5.3";
            } else if (hasPC7203 || hasPC7204) {
                sectionNumber = "5.2";
            }

            process53Content = `
<h3>${sectionNumber} Provide Mechanism to Modify or Withdraw Consent</h3>
<p><strong>Input:</strong></p>
<p>A <strong>request from a data subject to withdraw consent</strong> or modify their consent preferences. This could be received through various channels: the individual clicking an "unsubscribe" or "opt-out" link, updating settings in their account profile, emailing/calling customer support with a withdrawal request, or verbally telling a representative. The input includes the identity of the requester and the consent(s) they wish to withdraw or change (implicitly or explicitly provided by their request). The consent record exists in our system from the earlier process.</p>
<p><strong>Activities:</strong></p>
<ol type="1" class="numbered-list" start="1">
  <li><strong>Enable Easy Withdrawal:</strong> The organization ensures that withdrawing consent is as straightforward as giving it. Common mechanisms include one-click unsubscribe links in emails, a self-service privacy dashboard, or a clear email/contact for withdrawal. When a withdrawal request comes in, log the time and details of the request in the consent/log system. If the request is ambiguous (e.g. user says "stop using my data"), follow up to clarify which consent or processing it refers to, if needed.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Authenticate the Request (if needed):</strong> If the withdrawal is received through a channel that is not self-authenticated (e.g. coming from the data subject's registered email or via their logged-in session), verify the identity to ensure the person withdrawing is the data subject or authorized representative. For example, if via phone or a new email, you might ask for a verification step. This is to prevent unauthorized persons from withdrawing someone else's consent improperly.</li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Locate Consent Record:</strong> Find the individual's consent record(s) in question. Determine which specific consent they are withdrawing – e.g. "marketing emails consent given on 2025-01-10" or "consent to research use of health data." This can be done by searching the consent database by user ID or email.</li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Cease Processing for Withdrawn Consent:</strong> As soon as possible, take action so that the processing covered by that consent is stopped for this individual. For example, if they withdrew consent to marketing emails, remove or flag their address in the mailing list to exclude them from future sends (or update their marketing preference in CRM to "opt-out"). If they withdraw consent to all processing of their data for a certain project, inform the relevant business/process owner to stop using that individual's data. <strong>No further processing on the basis of that consent should occur after withdrawal</strong>. (Note: If there is another lawful basis that applies to the data, consult Legal before stopping completely – in most cases withdrawal means we cannot continue that processing unless required by law or similar, but e.g. if they withdraw consent for optional research use, we might still keep their data for core service under a different basis.)</li>
</ol>`;

            // Recording withdrawal details - related to PC73041a
            if (!isPC73041aOverridden) {
                process53Content += `
<ol type="1" class="numbered-list" start="5">
  <li><strong>Document the Withdrawal:</strong> Update the consent record to reflect that it was withdrawn: record the date/time of withdrawal and the method (e.g. "user clicked unsubscribe link" or "verbal withdrawal via call recorded"). If our system supports it, mark the consent as inactive or moved to an archive. Keep the historical record that consent was once given and then withdrawn, for compliance demonstration (GDPR requires that we prove consent for past processing until withdrawal, and also that we honored the withdrawal).</li>
</ol>`;
            }

            // Acknowledge to data subject - always included
            let nextStep = !isPC73041aOverridden ? 6 : 5;
            process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Acknowledge to Data Subject:</strong> Send a confirmation to the individual that their consent has been withdrawn and that we have acted upon it. For example, a message: "You have successfully withdrawn your consent for [purpose]. We have stopped processing your data for this purpose." If withdrawal means we will delete their data (because consent was the sole basis), inform them if any data will be erased or anonymized as a result (and then proceed to do so under the data deletion policy). If some data will be retained (perhaps for legal obligations), clarify that the specific consent-based processing is ceased.</li>
</ol>`;

            // Notifying third parties - related to PC73041b
            nextStep++;
            if (!isPC73041bOverridden) {
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Ensure Ongoing Compliance:</strong> If the individual's data was shared with any <strong>third parties</strong> or processors based on their consent, notify those parties of the withdrawal if required to stop further processing. For instance, if a partner company had the data under a joint consent, inform them promptly that the consent is withdrawn (per our obligations to propagate deletions/withdrawals). Internally, ensure that all team members or systems that had access know not to use the data for that purpose.</li>
</ol>`;
                nextStep++;
            }

            // Handling modifications - always included
            process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Modify vs. Withdraw:</strong> If the request is not a full withdrawal but a <strong>modification</strong> of consent (e.g. user says "I still allow emails but withdraw consent for phone calls" or changes preferences in a cookie settings panel), handle it similarly: adjust their consent record to reflect new preferences. Essentially treat it as withdrawing consent for one aspect and perhaps obtaining (or keeping) consent for another. Always honor the granular choices the data subject makes. Document these changes similarly.</li>
</ol>`;

            // Record keeping and SLA - related to PC73041c
            nextStep++;
            let auditTrailText = '';
            if (!isPC73041cOverridden) {
                auditTrailText = `At minimum, ensure we can show auditors the log of withdrawals and how they were handled within the SLA.`;
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Update Data Inventory/Audit Trails:</strong> If applicable, update any records or inventory entries if the withdrawal significantly changes our processing (for example, if many people withdraw and we decide to switch legal basis or end a project, ensure documentation reflects that). ${auditTrailText}</li>
</ol>`;
                nextStep++;
            } else {
                process53Content += `
<ol type="1" class="numbered-list" start="${nextStep}">
  <li><strong>Update Data Inventory/Audit Trails:</strong> If applicable, update any records or inventory entries if the withdrawal significantly changes our processing (for example, if many people withdraw and we decide to switch legal basis or end a project, ensure documentation reflects that).</li>
</ol>`;
                nextStep++;
            }

            // Output section with conditional elements based on execution criteria
            process53Content += `
<p><strong>Output:</strong></p>
<p>The <strong>withdrawal (or modification) is completed</strong>. The individual's consent status is updated to reflect they no longer consent to the given purpose. All relevant systems and data sets are updated so that the individual's data is <strong>no longer processed for that purpose</strong>. The data subject receives confirmation (where applicable) of the change. `;

            // Audit trail element based on PC73041a
            if (!isPC73041aOverridden) {
                process53Content += `We maintain an <strong>audit trail</strong> of the request and our response, demonstrating compliance with the requirement that withdrawal is honored and is as easy as giving consent. `;
            }

            process53Content += `If the withdrawal was for the only purpose we held the data, the data may be deleted or anonymized as per the retention policy`;

            if (!isPC73041aOverridden) {
                process53Content += ` (with that action recorded as well)`;
            }

            process53Content += `.`;

            // SLA reference based on PC73041c
            if (!isPC73041cOverridden) {
                process53Content += ` Our KPI on withdrawal processing time is updated (this withdrawal's turnaround time will factor into reporting). Any difficulties or delays are documented and, if necessary, reported to the DPO to address process improvements.`;
            }

            process53Content += `</p>`;
        }

        // Generate Process 5.4 content
        let process54Content = '';

        if (hasPC7305) {
            // Determine the correct section number based on which previous sections exist
            let sectionNumber = "5.1"; // Default if no previous sections
            let referenceToWithdrawalSection = "process on withdrawal";

            if (hasPC7203 && hasPC7204 && hasPC7304) {
                sectionNumber = "5.4";
                referenceToWithdrawalSection = "5.3";
            } else if ((hasPC7203 && hasPC7204) || (hasPC7203 && hasPC7304) || (hasPC7204 && hasPC7304)) {
                sectionNumber = "5.3";
                referenceToWithdrawalSection = "5.2";
            } else if (hasPC7203 || hasPC7204 || hasPC7304) {
                sectionNumber = "5.2";
                referenceToWithdrawalSection = "5.1";
            }

            process54Content = `
<h3>${sectionNumber} Provide Mechanism to Object to Processing of Personal Data</h3>
<p><strong>Input:</strong></p>
<p>An <strong>objection request</strong> from a data subject regarding processing of their personal data. Under GDPR, this typically refers to the right to object to processing based on legitimate interests or public interest (Art. 21(1)) or to object to processing for direct marketing (Art. 21(2)). The request might be received via email, web form, or verbally, and should specify (explicitly or by context) that the individual objects to a certain processing activity. The input includes identifying the data subject and the processing in question.</p>`;

            // Reference to withdrawal section only if PC7304 is applied
            if (hasPC7304) {
                process54Content += `
<p><em>(Note: If the objection is specifically "I don't want you to process my data at all anymore," and our only basis was consent, this is effectively a withdrawal (see ${referenceToWithdrawalSection}). But if we are processing on another basis like legitimate interest, the formal objection process applies.)</em></p>`;
            }

            process54Content += `
<p><strong>Activities:</strong></p>`;

            // PC73051a affects documenting legal requirements - but all process steps are included regardless
            const showDetailedLegalRequirements = !isPC73051aOverridden;

            process54Content += `
<ol type="1" class="numbered-list" start="1">
  <li><strong>Accept Objection Request:</strong> The Customer Support or Privacy team logs the objection upon receipt. If the objection is not clear, seek clarification. For example, a user might say "I object to you having my data" – we may need to clarify if they mean direct marketing or a specific use. `;

            if (showDetailedLegalRequirements) {
                process54Content += `Under GDPR, an individual can object to <em>any</em> processing on legitimate interest grounds due to their particular situation, or absolutely opt-out of direct marketing. `;
            }

            process54Content += `Ensure the request is documented with date/time of receipt.</li>
</ol>
<ol type="1" class="numbered-list" start="2">
  <li><strong>Verify Identity:</strong> `;

            if (hasPC7304) {
                process54Content += `As with withdrawals, `;
            }

            process54Content += `confirm the identity of the requester if there's any doubt (especially if the objection comes from a different channel than the data subject's usual contact). We must be sure the person objecting is the data subject or authorized on their behalf.</li>
</ol>
<ol type="1" class="numbered-list" start="3">
  <li><strong>Identify the Processing Activity:</strong> Determine which processing is being objected to. Common scenarios:
    <ul>
      <li><strong>Direct Marketing:</strong> If the person objects to receiving marketing (emails, calls, etc.), this objection must be honored unconditionally. `;

            if (hasPC7304) {
                process54Content += `It is essentially the same outcome as withdrawing marketing consent, though even if we argued "legitimate interest" for marketing, GDPR says we must stop.`;
            } else {
                process54Content += `GDPR requires that direct marketing objections must be honored without question.`;
            }

            process54Content += `</li>
    </ul>
    <ul>
      <li><strong>Other Legitimate Interest/Public Interest Processing:</strong> If the objection is to processing that we undertake under Article 6(1)(f) (legitimate interests) or (e) (public task), we need to evaluate it. The data subject might cite a specific reason (their particular situation) or may not. By default, when someone objects, we <strong>must pause processing</strong> their data for that purpose until a resolution is reached. Notify relevant process owners to put a hold on the data (do not continue or make decisions involving that data for now).</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="4">
  <li><strong>Evaluate Objection (LI/Public Interest cases):</strong> The Privacy Officer and relevant stakeholders assess whether we have <strong>"compelling legitimate grounds"</strong> to continue processing that override the individual's rights, or if the objection should be accepted. This involves:
    <ul>
      <li>Reviewing the purpose and necessity of the processing.</li>
    </ul>
    <ul>
      <li>Weighing the individual's rights and reasons versus our interests.</li>
    </ul>
    <ul>
      <li>For example, if the person objects to profiling that affects them, do we have an overriding need? In most cases, <strong>individual rights will prevail</strong> unless the data is critical (e.g. for legal claims or other strong grounds). Document this assessment.</li>
    </ul>
    <ul>
      <li>If we <strong>cannot demonstrate overriding grounds</strong>, or if the individual's objection is reasonable, we will honor the objection (stop processing and likely delete or isolate their data for that purpose).</li>
    </ul>
    <ul>
      <li>If we believe we do have strong grounds to refuse the objection, seek legal advice and document justification carefully (this is rare and risky; and note this path is not allowed for direct marketing – those must stop).</li>
    </ul>
  </li>
</ol>`;

            // PC73051c affects response timeframe
            process54Content += `
<ol type="1" class="numbered-list" start="5">
  <li><strong>Respond to Data Subject:</strong> Based on the evaluation, respond to the individual `;

            if (showDetailedLegalRequirements) {
                process54Content += `within the required timeframe (typically one month per GDPR Art.12)`;
            } else {
                process54Content += `in a timely manner`;
            }

            process54Content += `.
    <ul>
      <li>If <strong>objection upheld (accepted)</strong>: Inform the data subject that we have stopped processing their personal data for the contested purpose. Explain any relevant details (e.g. "We will no longer process your data for [purpose]."). If applicable, mention that data will be deleted or retained only in a restricted manner (e.g. suppression list to ensure no future processing).</li>
    </ul>
    <ul>
      <li>If <strong>objection denied</strong> (not accepted because of compelling grounds): Provide a clear explanation to the data subject of why we are continuing processing despite their objection, outlining the compelling legitimate interests or legal obligations that justify it. Also inform them of their right to seek remedies (e.g. contacting the DPA or judicial review) if they disagree. This response must be very carefully reviewed by Legal.</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="6">
  <li><strong>Implement Outcome:</strong>
    <ul>
      <li>If accepted, immediately and permanently <strong>exclude the individual's data from the processing</strong> they objected to. For instance, if they objected to being in an analytics dataset processed under legitimate interest, remove or anonymize their data in that dataset going forward. If they objected to a data sharing arrangement, cease sharing their data. Ensure all team members/systems involved in that processing are informed of this change. Also, prevent any re-introduction of their data (sometimes by flagging it or adding them to a do-not-process list for that purpose).</li>
    </ul>
    <ul>
      <li>If denied, ensure the processing continues with caution, and consider if there are any measures to reduce the impact on the individual (as a courtesy or risk mitigation). This scenario should be revisited if the individual escalates the matter.</li>
    </ul>
  </li>
</ol>
<ol type="1" class="numbered-list" start="7">
  <li><strong>Record and Monitor:</strong> Log the objection and our resolution in an internal register of data subject requests (this helps with KPI tracking and demonstrates compliance with handling rights). Include details like date received, nature of objection, decisions made, date responded. If the objection leads to significant changes (like stopping a certain processing for many individuals), note that for management review. Regularly review objections to identify if certain processing activities are generating repeated objections – this might indicate the need to reconsider the legal basis or transparency of those activities.</li>
</ol>`;

            // PC73051b affects providing information to principals about ability to object
            if (!isPC73051bOverridden) {
                process54Content += `
<ol type="1" class="numbered-list" start="8">
  <li><strong>Maintain Mechanism Availability:</strong> Make sure our privacy communications (privacy policy, website) clearly inform data subjects that they have the right to object and how to exercise it. This is part of GDPR compliance (Art. 21(4) requires that the right to object is explicitly brought to attention). For example, in our privacy notice and first communications we state: "If you object to any processing of your personal data, contact us at [contact info]." Internally, ensure that when such contacts come in, staff recognize it as a formal objection and route it to this process.</li>
</ol>`;
            }

            // Output section
            process54Content += `
<p><strong>Output:</strong></p>
<p>The <strong>data subject's objection is resolved</strong> in a compliant manner. If the objection is honored, the output is that the specific processing in question <strong>no longer involves that individual's data</strong>, and a record of this change is saved. If the objection is not honored due to an override, the output is a <strong>documented justification</strong> on file and a communication to the data subject. In all cases, the organization can demonstrate that a mechanism to object was provided and the request was handled diligently. The data subject's preference is now reflected in our systems (either opt-out in place or processing continued with justification). This fulfills both ISO 27701 control requirements for objections and GDPR obligations to respect data subject rights. `;

            if (showDetailedLegalRequirements) {
                process54Content += `The outcomes are used to update KPI metrics (e.g. number of objections received, percentage resolved within time). Additionally, patterns from objections might feed into improvements in how we conduct certain processing or communications (a form of feedback loop for compliance and trust).`;
            }

            process54Content += `</p>`;
        }

        return `
<h1>SOP: Consent Management</h1>
<h2>1. Legal/Standard Basis</h2>
<ul>
${isoControlsText}
</ul>
<ul>
  <li><strong>EU GDPR Requirements</strong> – This SOP ensures compliance with GDPR provisions related to consent, in particular but not exclusively, <strong>Article 6(1)(a)</strong> (consent as a lawful basis for processing); <strong>Article 7(1)–(4)</strong> (conditions for valid consent, including that consent must be demonstrable, clearly distinguishable, and revocable at any time); <strong>Article 8(1)–(2)</strong> (parental consent for children under the applicable age and verification of such consent); and <strong>Article 9(2)(a)</strong> (explicit consent required for processing special categories of data). Compliance with the GDPR <strong>right to object</strong> (Article 21) is also addressed via the mechanism for objections.</li>
</ul>
<p><em>Legal Note:</em> This SOP is written for the <strong>data controller</strong> role. It addresses controller obligations for consent management. (Data processors do not obtain consent directly but may assist controllers in facilitating withdrawals or objections; those processor obligations are outside the scope of this SOP.)</p>
${purposeContent}
${kpisContent}
${responsibilitiesContent}
<h2>5. Processes</h2>
<p>Each sub-process below outlines how the organization, as data controller, manages consent throughout its lifecycle. <strong>Inputs</strong> describe triggers or prerequisites, <strong>Activities</strong> describe procedural steps (what to do and how), and <strong>Outputs</strong> describe the expected results or records produced.</p>
${process51Content}
${process52Content}
${process53Content}
${process54Content}
                    `;
    },

    /**
     * Check if this policy should be shown based on selected controls
     * @param {Object} controlsMatrix - The selected risk controls matrix
     * @returns {boolean} Whether to show this policy
     */
    shouldShow: (controlsMatrix) => {
        // Check if any consent-related controls are selected
        const consentControls = ['PC7203', 'PC7204', 'PC7302', 'PC7304'];

        // Return true if any processing activity has at least one consent control enabled
        return Object.values(controlsMatrix).some(activityControls =>
            consentControls.some(control => activityControls[control] === true)
        );
    },

    /**
     * Get intro text for this policy
     * @returns {String} Introductory text to display
     */
    getIntroText: () => {
        return 'This Standard Operating Procedure (SOP) establishes the process for obtaining, recording, managing, and withdrawing consent for the processing of personal data.';
    }
};

export default SOPConsentManagement;