// src/utils/wizardPageContexts.js
// This informs the Mistral AI Assistant about the context of each wizard page

export const WIZARD_PAGE_CONTEXTS = {
    // Funding Wizard Pages
    '/funding/regions': {
      wizardName: 'Funding Wizard',
      pageName: 'Regions Selection',
      description: 'Select the regions and subregions where your company currently operates',
      helpTopics: [
        'Understanding geographical market presence',
        'Selecting relevant business regions',
        'Exploring potential market opportunities'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const existingRegions = knowYourselfResponse?.draft?.regions || 
                                 knowYourselfResponse?.published?.regions || [];
        
        return {
          pageDescription: 'Strategic geographical expansion planning',
          currentSelection: existingRegions.length > 0 
            ? `Currently selected regions: ${existingRegions.join(', ')}` 
            : 'No regions selected yet',
          strategicInsights: [
            'Consider market size and growth potential in each region',
            'Evaluate regulatory environments and business ease',
            'Assess competitive landscape in target regions',
            'Align regional selection with long-term business strategy'
          ]
        };
      }
    },

    '/funding/industry': {
      wizardName: 'Funding Wizard',
      pageName: 'Industry Selection',
      description: 'Define your industry and sector focus for better market positioning',
      helpTopics: [
        'Selecting the right industry classification',
        'Understanding industry-specific considerations',
        'Positioning your company within your sector'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const existingIndustry = knowYourselfResponse?.draft?.industry || 
                                 knowYourselfResponse?.published?.industry || '';
        const existingSector = knowYourselfResponse?.draft?.sector || 
                               knowYourselfResponse?.published?.sector || '';
        
        return {
          pageDescription: 'Industry classification and sector specialization',
          currentSelection: existingIndustry 
            ? `Currently selected industry: ${existingIndustry}${existingSector ? `, sector: ${existingSector}` : ''}` 
            : 'No industry selected yet',
          strategicInsights: [
            'Your industry classification affects investor perceptions',
            'Consider which industry best represents your primary value creation',
            'Industry selection impacts expected metrics and comparables',
            'Choose the sector that best describes your specific business focus'
          ]
        };
      }
    },

    '/funding/market': {
      wizardName: 'Funding Wizard',
      pageName: 'Market Analysis',
      description: 'Define your market size, growth potential, and addressable opportunities',
      helpTopics: [
        'Calculating total addressable market (TAM)',
        'Determining serviceable addressable market (SAM)',
        'Estimating serviceable obtainable market (SOM)',
        'Market growth projections'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const hasTAM = knowYourselfResponse?.draft?.totalAddressableMarket || 
                       knowYourselfResponse?.published?.totalAddressableMarket;
        const currency = knowYourselfResponse?.draft?.currency || 
                         knowYourselfResponse?.published?.currency || 'USD';
        
        return {
          pageDescription: 'Market sizing and growth opportunity analysis',
          currentSelection: hasTAM 
            ? 'Market data has been provided' 
            : 'No market data provided yet',
          strategicInsights: [
            'Precise market sizing is crucial for investor credibility',
            'Show the relationship between TAM, SAM, and SOM',
            'Include reliable market growth rate from industry sources',
            'Explain key market trends and how they benefit your business'
          ]
        };
      }
    },

    '/funding/competition': {
      wizardName: 'Funding Wizard',
      pageName: 'Competitive Analysis',
      description: 'Analyze your competitive landscape and position your differentiators',
      helpTopics: [
        'Mapping competitive landscape',
        'Comparative advantage analysis',
        'Identifying market positioning',
        'Highlighting unique value propositions'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const competitionData = knowYourselfResponse?.draft?.competition || 
                               knowYourselfResponse?.published?.competition || {};
        const competitors = competitionData.competitors || [];
        
        return {
          pageDescription: 'Competitive landscape and positioning analysis',
          currentSelection: competitors.length > 1 
            ? `Analysis includes ${competitors.length} competitors` 
            : 'No competitors added yet',
          strategicInsights: [
            'Be honest and thorough in your competitive analysis',
            'The first company in the competitive analysis is your own',
            'Focus on your unique strengths across multiple dimensions',
            'Identify areas where competitors outperform you and your plans to address them',
            'Analyze the competitive landscape holistically, not just direct competitors'
          ]
        };
      }
    },

    '/funding/business-model': {
      wizardName: 'Funding Wizard',
      pageName: 'Business Model',
      description: 'Define your customer segments and revenue streams',
      helpTopics: [
        'Customer segmentation strategy',
        'B2B vs B2C business models',
        'Revenue stream diversification',
        'Pricing strategy development'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const clientele = knowYourselfResponse?.draft?.clientele || 
                          knowYourselfResponse?.published?.clientele || [];
        const revenueCategories = knowYourselfResponse?.draft?.revenueCategories || 
                                  knowYourselfResponse?.published?.revenueCategories || {};
        
        return {
          pageDescription: 'Business model and revenue stream analysis',
          currentSelection: clientele.length > 0 
            ? `Business model focuses on ${clientele.join(', ')}` 
            : 'No business model defined yet',
          strategicInsights: [
            'Clearly define your target customer segments',
            'Establish how your revenue model aligns with customer needs',
            'Consider multiple revenue streams for business resilience',
            'Explain how your business model enables scalability'
          ]
        };
      }
    },

    '/funding/product': {
      wizardName: 'Funding Wizard',
      pageName: 'Products & Services',
      description: 'Detail your product/service offerings and unique selling propositions',
      helpTopics: [
        'Product portfolio development',
        'Service offering structure',
        'Unique selling proposition (USP) definition',
        'Product development roadmap'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const products = knowYourselfResponse?.draft?.products || 
                         knowYourselfResponse?.published?.products || [];
        
        return {
          pageDescription: 'Product and service offering analysis',
          currentSelection: products.length > 0 
            ? `${products.length} products/services defined` 
            : 'No products/services defined yet',
          strategicInsights: [
            'Clearly articulate what makes your products/services unique',
            'Explain how your offerings address specific customer pain points',
            'Outline your product development stage and roadmap',
            'Highlight intellectual property or technical advantages'
          ]
        };
      }
    },

    '/funding/team': {
      wizardName: 'Funding Wizard',
      pageName: 'Team Structure',
      description: 'Detail your leadership team, key roles, and organizational structure',
      helpTopics: [
        'Leadership team composition',
        'Organizational structure development',
        'Key roles and responsibilities',
        'Team growth planning'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const founders = knowYourselfResponse?.draft?.founders || 
                         knowYourselfResponse?.published?.founders || [];
        const keyTeamMembers = knowYourselfResponse?.draft?.keyTeamMembers || 
                              knowYourselfResponse?.published?.keyTeamMembers || [];
        
        return {
          pageDescription: 'Team composition and organizational structure',
          currentSelection: founders.length > 0 
            ? `Team includes ${founders.length} founders and ${keyTeamMembers.length} key team members` 
            : 'Team structure not defined yet',
          strategicInsights: [
            'Highlight relevant experience and expertise of founders and key team members',
            'Address how the team composition addresses business needs',
            'Outline plans for filling critical skill gaps',
            'Demonstrate team cohesion and complementary skills'
          ]
        };
      }
    },

    '/funding/story': {
      wizardName: 'Funding Wizard',
      pageName: 'Company Story',
      description: 'Craft your company narrative, mission, vision, and values',
      helpTopics: [
        'Developing a compelling brand narrative',
        'Defining mission and vision statements',
        'Creating a memorable company story',
        'SWOT analysis preparation'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const hasMission = knowYourselfResponse?.draft?.mission || 
                          knowYourselfResponse?.published?.mission;
        const hasVision = knowYourselfResponse?.draft?.vision || 
                         knowYourselfResponse?.published?.vision;
        
        return {
          pageDescription: 'Company narrative and strategic positioning',
          currentSelection: (hasMission && hasVision) 
            ? 'Company mission and vision defined' 
            : 'Company story incomplete',
          strategicInsights: [
            'Create an emotionally resonant company story',
            'Ensure mission and vision statements are clear and memorable',
            'Identify and articulate your company\'s special value ("special sauce")',
            'Conduct a balanced SWOT analysis that demonstrates self-awareness'
          ]
        };
      }
    },

    '/funding/investment': {
      wizardName: 'Funding Wizard',
      pageName: 'Investment Requirements',
      description: 'Define your funding needs, burn rate, and capital planning',
      helpTopics: [
        'Funding round planning',
        'Burn rate calculation',
        'Capital requirement projections',
        'Funding timeline development'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const targetRound = knowYourselfResponse?.draft?.targetFundingRound || 
                           knowYourselfResponse?.published?.targetFundingRound || '';
        const capitalRequired = knowYourselfResponse?.draft?.capitalRequired || 
                               knowYourselfResponse?.published?.capitalRequired || 0;
        const currency = knowYourselfResponse?.draft?.fundingCurrency || 
                         knowYourselfResponse?.published?.fundingCurrency || 'USD';
        
        return {
          pageDescription: 'Investment requirements and capital planning',
          currentSelection: targetRound 
            ? `Seeking ${targetRound} funding of ${capitalRequired.toLocaleString()} ${currency}` 
            : 'Investment requirements not defined yet',
          strategicInsights: [
            'Be precise about your capital requirements based on burn rate and runway',
            'Clearly explain the funding round you\'re targeting',
            'Outline the timing of your fundraising efforts',
            'Demonstrate how your capital needs align with business milestones'
          ]
        };
      }
    },

    '/funding/fundingNeeds': {
      wizardName: 'Funding Wizard',
      pageName: 'Use of Funds',
      description: 'Detail how you will allocate investment capital across business areas',
      helpTopics: [
        'Capital allocation strategy',
        'Investment prioritization',
        'Expenditure planning',
        'Resource allocation optimization'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const fundingNeeds = knowYourselfResponse?.draft?.fundingNeeds || 
                            knowYourselfResponse?.published?.fundingNeeds || {};
        const useOfFunds = fundingNeeds.useOfFunds || {};
        
        return {
          pageDescription: 'Capital allocation and use of funds planning',
          currentSelection: Object.keys(useOfFunds).length > 0 
            ? 'Allocation of funds defined' 
            : 'Use of funds not yet specified',
          strategicInsights: [
            'Be transparent about how investment capital will be used',
            'Prioritize investments that drive key metrics and milestones',
            'Balance between product development, marketing, operations, and other areas',
            'Demonstrate efficiency in how capital will be deployed'
          ]
        };
      }
    },

    '/funding/fundingRounds': {
      wizardName: 'Funding Wizard',
      pageName: 'Funding Rounds',
      description: 'Plan your funding timeline, rounds, and financial projections',
      helpTopics: [
        'Funding timeline planning',
        'Financial projection development',
        'Valuation expectations',
        'Cashflow forecasting'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const fundingRounds = knowYourselfResponse?.draft?.fundingRounds?.fundingRounds || 
                             knowYourselfResponse?.published?.fundingRounds?.fundingRounds || [];
        
        return {
          pageDescription: 'Funding timeline and financial projections',
          currentSelection: fundingRounds.length > 1 
            ? `${fundingRounds.length} funding rounds planned` 
            : 'Funding rounds not yet defined',
          strategicInsights: [
            'Create realistic cashflow projections based on your business model',
            'Plan your funding rounds to align with major business milestones',
            'Demonstrate understanding of appropriate valuations for your stage',
            'Show how each funding round supports your growth trajectory'
          ]
        };
      }
    },

    '/funding/termSheet': {
      wizardName: 'Funding Wizard',
      pageName: 'Term Sheet',
      description: 'Define your preferred investment terms and conditions',
      helpTopics: [
        'Investment term structure',
        'Investor rights and protections',
        'Liquidation preferences',
        'Governance considerations'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const termSheet = knowYourselfResponse?.draft?.termSheet || 
                         knowYourselfResponse?.published?.termSheet || {};
        
        return {
          pageDescription: 'Investment terms and conditions preferences',
          currentSelection: Object.keys(termSheet).length > 0 
            ? 'Term sheet preferences defined' 
            : 'Term sheet preferences not yet specified',
          strategicInsights: [
            'Understand common investment terms and their implications',
            'Balance investor protections with founder control considerations',
            'Consider how terms affect future funding rounds',
            'Prepare for negotiation on key terms'
          ]
        };
      }
    },

    '/funding/captable': {
      wizardName: 'Funding Wizard',
      pageName: 'Cap Table Management',
      description: 'Manage your company\'s equity structure and shareholder distribution',
      helpTopics: [
        'Equity structure planning',
        'Shareholder management',
        'Dilution considerations',
        'Equity distribution strategy'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const captable = knowYourselfResponse?.draft?.captable || 
                        knowYourselfResponse?.published?.captable || {};
        const currentShareholders = captable.currentShareholders || [];
        
        return {
          pageDescription: 'Capitalization table and equity structure',
          currentSelection: currentShareholders.length > 0 
            ? `Cap table includes ${currentShareholders.length} shareholders` 
            : 'Cap table not yet defined',
          strategicInsights: [
            'Maintain a clean and well-structured capitalization table',
            'Plan for dilution from current and future funding rounds',
            'Balance founder equity with investor allocations',
            'Consider employee equity pools in your ownership structure'
          ]
        };
      }
    },

    '/funding/captable-history': {
      wizardName: 'Funding Wizard',
      pageName: 'Cap Table History',
      description: 'Track historical changes to your capitalization table',
      helpTopics: [
        'Equity transaction records',
        'Share transfer documentation',
        'Capitalization history',
        'Ownership evolution tracking'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const captableHistory = knowYourselfResponse?.captablehistory || {};
        const transactions = captableHistory.transactions || [];
        
        return {
          pageDescription: 'Historical equity transactions and ownership records',
          currentSelection: transactions.length > 0 
            ? `${transactions.length} equity transactions recorded` 
            : 'No equity transaction history recorded',
          strategicInsights: [
            'Maintain accurate records of all equity transactions',
            'Document share transfers, issuances, and other capitalization changes',
            'Ensure transparency in ownership evolution for investors',
            'Keep meticulous transaction documentation for legal compliance'
          ]
        };
      }
    },

    '/funding/vesop': {
      wizardName: 'Funding Wizard',
      pageName: 'VESOP Configuration',
      description: 'Configure your Virtual Employee Stock Option Pool',
      helpTopics: [
        'Employee equity incentive design',
        'VESOP structure planning',
        'Vesting schedule configuration',
        'Equity incentive allocation'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const vesop = knowYourselfResponse?.draft?.vesop || 
                     knowYourselfResponse?.published?.vesop || {};
        
        return {
          pageDescription: 'Virtual employee stock option plan configuration',
          currentSelection: vesop.hasVESOPPool 
            ? `VESOP pool of ${vesop.poolPercentage}% configured` 
            : 'VESOP not configured or disabled',
          strategicInsights: [
            'Design a competitive employee equity incentive program',
            'Structure vesting schedules to balance retention and motivation',
            'Allocate equity incentives strategically across team roles',
            'Plan for VESOP dilution in your capitalization strategy'
          ]
        };
      }
    },

    '/funding/vesop-management': {
      wizardName: 'Funding Wizard',
      pageName: 'VESOP Management',
      description: 'Manage virtual stock option allocations to team members',
      helpTopics: [
        'Employee equity allocation',
        'Vesting schedule management',
        'Equity incentive tracking',
        'Team member equity distribution'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const vesopManagement = knowYourselfResponse?.vesopManagement || {};
        const allocatedPeople = vesopManagement.allocatedPeople || [];
        
        return {
          pageDescription: 'VESOP allocation and team member distribution',
          currentSelection: allocatedPeople.length > 0 
            ? `VESOP allocated to ${allocatedPeople.length} team members` 
            : 'No VESOP allocations made yet',
          strategicInsights: [
            'Distribute equity incentives based on role impact and value creation',
            'Track vesting progress and projected value for team transparency',
            'Align equity compensation with performance and contribution',
            'Use equity effectively as a recruitment and retention tool'
          ]
        };
      }
    },

    '/funding/exitStrategy': {
      wizardName: 'Funding Wizard',
      pageName: 'Exit Strategy',
      description: 'Plan your potential exit options and timeline',
      helpTopics: [
        'Exit option analysis',
        'Acquisition preparation',
        'IPO readiness planning',
        'Exit timeline development'
      ],
      contextExtractor: (wizardData) => {
        const knowYourselfResponse = wizardData?.know_yourself_response;
        const exitStrategy = knowYourselfResponse?.draft?.exitStrategy || 
                            knowYourselfResponse?.published?.exitStrategy || {};
        const exitStrategies = exitStrategy.exitStrategies || [];
        
        return {
          pageDescription: 'Exit planning and liquidity event preparation',
          currentSelection: exitStrategies.length > 0 
            ? `Exit options include: ${exitStrategies.join(', ')}` 
            : 'Exit strategy not yet defined',
          strategicInsights: [
            'Consider multiple potential exit pathways',
            'Develop a realistic timeline for potential liquidity events',
            'Prepare for due diligence requirements for different exit options',
            'Balance exit planning with sustainable business building'
          ]
        };
      }
    },

    '/funding/summary': {
      wizardName: 'Funding Wizard',
      pageName: 'Summary',
      description: 'Review your complete funding profile',
      helpTopics: [
        'Funding profile review',
        'Investment readiness assessment',
        'Pitch preparation',
        'Investor presentation development'
      ],
      contextExtractor: (wizardData) => {
        return {
          pageDescription: 'Complete funding profile overview',
          currentSelection: 'Full funding profile available for review',
          strategicInsights: [
            'Ensure all elements of your funding profile are complete and consistent',
            'Prepare to address key investor questions from your comprehensive profile',
            'Use your funding profile as the foundation for pitch materials',
            'Review how your story, team, market, and financials work together'
          ]
        };
      }
    },
    
    // Startup Wizard Pages
    '/startup-wizard/introduction': {
      wizardName: 'Startup Wizard',
      pageName: 'Introduction',
      description: 'Learn about the startup creation process and set your preferences',
      helpTopics: [
        'Understanding the startup creation process',
        'Selecting relevant topics for your business',
        'Legal disclaimers and agreements'
      ],
      contextExtractor: (wizardData) => {
        const introData = wizardData?.draft?.introduction || wizardData?.published?.introduction || {};
        const relevantTopics = introData.relevantTopics || [];
        
        return {
          pageDescription: 'Introduction to the startup creation process',
          currentSelection: relevantTopics.length > 0 
            ? `Selected topics: ${relevantTopics.length}` 
            : 'No topics selected yet',
          strategicInsights: [
            'The startup wizard guides you through the process of setting up your company',
            'Your selections will tailor the experience to your specific needs',
            'Each step builds on the previous one to create a complete company formation package',
            'You can save your progress at any time and continue later'
          ]
        };
      }
    },

    '/startup-wizard/legalForm': {
      wizardName: 'Startup Wizard',
      pageName: 'Legal Form',
      description: 'Select the appropriate legal structure for your business',
      helpTopics: [
        'Understanding different legal entity types',
        'Comparing UG and GmbH structures - not talking about other legal forms',
        'Capital requirements for different legal forms',
        'Selecting the most suitable form for your business goals'
      ],
      contextExtractor: (wizardData) => {
        const legalFormData = wizardData?.draft?.legalForm || wizardData?.published?.legalForm || {};
        const selectedLegalForm = legalFormData.selectedLegalForm;
        const answeredQuestions = legalFormData.answers ? Object.keys(legalFormData.answers).length : 0;
        
        return {
          pageDescription: 'Legal structure selection for your startup',
          currentSelection: selectedLegalForm 
            ? `Selected legal form: ${selectedLegalForm}` 
            : 'No legal form selected yet',
          strategicInsights: [
            'Your legal form impacts liability, taxation, and capital requirements',
            'UG requires minimal starting capital but has limitations on profit distribution',
            'GmbH requires €25,000 starting capital but offers more flexibility and credibility',
            'Consider your long-term goals and financing strategy when choosing'
          ]
        };
      }
    },

    '/startup-wizard/company': {
      wizardName: 'Startup Wizard',
      pageName: 'Company Details',
      description: 'Define your company name, address, and initial share capital',
      helpTopics: [
        'Choosing a legally compliant company name',
        'Understanding registered address requirements',
        'Determining appropriate share capital',
        'German business naming laws and regulations'
      ],
      contextExtractor: (wizardData) => {
        const companyData = wizardData?.draft?.company || wizardData?.published?.company || {};
        const companyName = companyData.companyName;
        const shareCapital = companyData.shareCapital;
        
        return {
          pageDescription: 'Company identity and capitalization setup',
          currentSelection: companyName 
            ? `Company name: ${companyName}, Share capital: ${shareCapital || 0} EUR` 
            : 'No company details entered yet',
          strategicInsights: [
            'Your company name must comply with German business naming laws',
            'A registered business address is required for official correspondence',
            'Share capital requirements depend on your chosen legal form',
            'UG requires at least €1, while GmbH requires at least €25,000'
          ]
        };
      }
    },

    '/startup-wizard/founders': {
      wizardName: 'Startup Wizard',
      pageName: 'Founding Team',
      description: 'Define your founding team, their roles, and share distribution',
      helpTopics: [
        'Structuring founder equity distribution',
        'Defining roles and responsibilities',
        'Managing director appointments',
        'Capital contributions from founders'
      ],
      contextExtractor: (wizardData) => {
        const foundersData = wizardData?.draft?.founders || wizardData?.published?.founders || {};
        const founders = foundersData.founders || [];
        const totalShareCapital = wizardData?.published?.company?.shareCapital || 0;
        const totalInvested = founders.reduce((sum, founder) => sum + (founder.capitalInvested || 0), 0);
        
        return {
          pageDescription: 'Founding team composition and equity distribution',
          currentSelection: founders.length > 0 
            ? `${founders.length} founders with ${totalInvested} EUR invested (${totalShareCapital > 0 ? ((totalInvested / totalShareCapital) * 100).toFixed(2) : 0}% of capital)` 
            : 'No founders added yet',
          strategicInsights: [
            'Carefully consider equity distribution to reflect each founders contribution',
            'Designate at least one founder as managing director (Geschäftsführer)',
            'Each founders capital investment should be clearly documented',
            'All share capital must be fully allocated among the founders'
          ]
        };
      }
    },

    '/startup-wizard/articlesOfAssociation': {
      wizardName: 'Startup Wizard',
      pageName: 'Articles of Association',
      description: 'Define the internal governance rules of your company',
      helpTopics: [
        'Setting company purpose and objectives',
        'Configuring financial year arrangements',
        'Establishing shareholder meeting protocols',
        'Resolution requirements and voting thresholds'
      ],
      contextExtractor: (wizardData) => {
        const articlesData = wizardData?.draft?.articlesOfAssociation || wizardData?.published?.articlesOfAssociation || {};
        const hasCompanyPurpose = articlesData.companyPurpose && articlesData.companyPurpose.length > 0;
        
        return {
          pageDescription: 'Internal governance and operating rules',
          currentSelection: hasCompanyPurpose 
            ? 'Articles of association details entered' 
            : 'No articles of association details entered yet',
          strategicInsights: [
            'The company purpose should be broad enough to cover future business activities',
            'Setting appropriate meeting locations and notice periods ensures operational flexibility',
            'Voting thresholds for ordinary and extraordinary resolutions affect decision-making dynamics',
            'These articles form the constitutional document of your company'
          ]
        };
      }
    },

    '/startup-wizard/shareholdersAgreement': {
      wizardName: 'Startup Wizard',
      pageName: 'Shareholders Agreement',
      description: 'Configure additional agreements between company shareholders',
      helpTopics: [
        'Stock option program configuration',
        'Shareholder binding periods',
        'Exit and call option arrangements',
        'Control and information rights'
      ],
      contextExtractor: (wizardData) => {
        const agreementData = wizardData?.draft?.shareholdersAgreement || wizardData?.published?.shareholdersAgreement || {};
        const hasStockOptions = agreementData.stockOptionProgram === 'YES' || agreementData.stockOptionProgram === 'Ja';
        const bindingYears = agreementData.shareholderBindingYears;
        
        return {
          pageDescription: 'Additional shareholder arrangements and protections',
          currentSelection: Object.keys(agreementData).length > 0 
            ? `Agreement with ${bindingYears || 0} year binding period${hasStockOptions ? ', includes stock options' : ''}` 
            : 'No shareholders agreement details entered yet',
          strategicInsights: [
            'A shareholders agreement complements the articles of association with private arrangements',
            'Stock option programs can help attract and retain key talent',
            'Founder binding periods provide stability during critical early stages',
            'Call options protect the company if a shareholder leaves prematurely'
          ]
        };
      }
    },

    '/startup-wizard/notaryAndBank': {
      wizardName: 'Startup Wizard',
      pageName: 'Notary & Bank',
      description: 'Prepare for notarization and business bank account creation',
      helpTopics: [
        'Notary appointment preparation',
        'Required documentation for notarization',
        'Selecting a business bank',
        'Account opening process and requirements'
      ],
      contextExtractor: (wizardData) => {
        const notaryData = wizardData?.draft?.notaryAndBank || wizardData?.published?.notaryAndBank || {};
        const chosenBank = notaryData.chosenBank;
        
        return {
          pageDescription: 'Notarization and banking setup',
          currentSelection: chosenBank 
            ? `Selected bank: ${chosenBank}` 
            : 'No bank selected yet',
          strategicInsights: [
            'Notarization is a mandatory legal requirement for company registration in Germany',
            'All founders must be present at the notary appointment with identification',
            'Choose a bank with good corporate client services and user-friendly online banking',
            'The account opening process typically takes 2-4 weeks to complete'
          ]
        };
      }
    },

    '/startup-wizard/final': {
      wizardName: 'Startup Wizard',
      pageName: 'Completion',
      description: 'Final steps to complete your company formation process',
      helpTopics: [
        'Transparency register registration',
        'Business registration process',
        'Deposit of initial share capital',
        'Post-registration requirements'
      ],
      contextExtractor: (wizardData) => {
        const finalData = wizardData?.draft?.final || wizardData?.published?.final || {};
        const companyName = wizardData?.published?.company?.companyName || '';
        
        return {
          pageDescription: 'Company formation completion',
          currentSelection: finalData.final === 'Finished' 
            ? `Company ${companyName} setup completed` 
            : 'Company setup not yet completed',
          strategicInsights: [
            'Register your company in the transparency register to disclose beneficial owners',
            'Complete business registration with the local trade office',
            'Deposit the initial share capital into your business account',
            'Be cautious of fake invoices after registration in the commercial registers'
          ]
        };
      }
    },
    
    // ISMS Wizard Pages
    '/isms/risk-assessment': {
      wizardName: 'Information Security Management System',
      pageName: 'Risk Assessment',
      description: 'Evaluate and document potential security risks',
      helpTopics: [
        'Identifying security vulnerabilities',
        'Assessing potential threats',
        'Developing risk mitigation strategies'
      ],
      contextExtractor: (wizardData) => {
        const ismsResponse = wizardData?.isms_response;
        const existingRisks = ismsResponse?.draft || ismsResponse?.published || {};
        
        return {
          pageDescription: 'Conduct a comprehensive security risk assessment',
          currentSelection: Object.keys(existingRisks).length > 0
            ? 'Some risk assessment data has been previously recorded'
            : 'No risk assessment data added yet'
        };
      }
    },
    
    // Data Privacy Wizard Pages
    '/data-privacy-wizard/data-processing': {
      wizardName: 'Data Privacy Wizard',
      pageName: 'Data Processing Activities',
      description: 'Document and analyze data processing activities',
      helpTopics: [
        'Understanding data processing requirements',
        'Documenting data handling practices',
        'Ensuring compliance with privacy regulations'
      ],
      contextExtractor: (wizardData) => {
        const privacyResponse = wizardData?.data_privacy_response;
        const existingProcesses = privacyResponse?.draft || privacyResponse?.published || {};
        
        return {
          pageDescription: 'Detail your organization\'s data processing activities',
          currentSelection: Object.keys(existingProcesses).length > 0
            ? 'Some data processing activities have been documented'
            : 'No data processing activities recorded yet'
        };
      }
    }
  };
  
  // Helper function to get context for a specific route
  export const getWizardPageContext = (route, wizardData) => {
    const pageContext = WIZARD_PAGE_CONTEXTS[route];
    
    if (!pageContext) {
      return {
        wizardName: 'Business Wizard',
        pageName: 'Unknown Page',
        description: 'Navigate through the wizard',
        currentSelection: 'No specific context available',
        pageDescription: 'Assist with wizard navigation'
      };
    }
    
    // If a context extractor exists, use it
    const extractedContext = pageContext.contextExtractor ? 
      pageContext.contextExtractor(wizardData) : 
      {};
    
    return {
      ...pageContext,
      ...extractedContext
    };
  };