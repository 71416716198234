import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon, ListBulletIcon } from '@heroicons/react/24/outline';

/**
 * Initial screen shown within the AssetSelectionModal that allows users to either
 * create a new asset or select an existing one.
 * 
 * @param {Object} props
 * @param {Function} props.onCreateNew - Callback when "Create New Asset" is selected
 * @param {Function} props.onSelectExisting - Callback when "Select Existing Asset" is selected
 */
const AssetActionSelection = ({ onCreateNew, onSelectExisting }) => {
  const { t } = useTranslation();

  return (
    <div className="py-6 px-4">      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Create New Asset Button */}
        <button
          onClick={onCreateNew}
          className="flex flex-col items-center justify-center bg-white border-2 border-secondary-color rounded-lg p-6 hover:bg-secondary-color/10 transition-colors"
        >
          <PlusCircleIcon className="h-16 w-16 text-secondary-color mb-4" />
          <span className="text-lg font-medium text-gray-800">
            {t('Create New Asset')}
          </span>
          <p className="text-sm text-gray-500 text-center mt-2">
            {t('Create and configure a new asset from scratch')}
          </p>
        </button>

        {/* Select Existing Asset Button */}
        <button
          onClick={onSelectExisting}
          className="flex flex-col items-center justify-center bg-white border-2 border-secondary-color rounded-lg p-6 hover:bg-secondary-color/10 transition-colors"
        >
          <ListBulletIcon className="h-16 w-16 text-secondary-color mb-4" />
          <span className="text-lg font-medium text-gray-800">
            {t('Select Existing Asset')}
          </span>
          <p className="text-sm text-gray-500 text-center mt-2">
            {t('Browse and select from previously created assets')}
          </p>
        </button>
      </div>
    </div>
  );
};

export default AssetActionSelection;