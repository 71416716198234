// src/pages/wizards/DataPrivacyWizard/components/ExternalRecipientDetails.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { LinkIcon } from '@heroicons/react/24/outline';
import CountrySelector from '../../CountrySelector';
import ComplianceMechanismField from './ComplianceMechanismField';
import { getCountryName, getCountryComplianceStatus } from './transfersHelpers';

const ExternalRecipientDetails = ({
  transfer,
  recipientData,
  index,
  formik,
  isEditing,
  countries,
  gdprCountries,
  isThirdCountry,
  processorRoles,
  dpaStatusOptions,
  toggleDrawer,
  userLang
}) => {
  const { t } = useTranslation();

  // Determine country compliance status (GDPR, GDPR-like, third country)
  const countryComplianceStatus = transfer.country
    ? getCountryComplianceStatus(transfer.country, countries)
    : null;

  // Translated processor roles
  const translatedRoles = [
    {
      value: 'subprocessor',
      label: t('DataPrivacyWizard.Subprocessor')
    },
    {
      value: 'authority',
      label: t('DataPrivacyWizard.Authority')
    },
    {
      value: 'other',
      label: t('DataPrivacyWizard.Other')
    }
  ];

  // Translated DPA status options
  const translatedDpaOptions = [
    {
      value: 'signed',
      label: t('DataPrivacyWizard.Signed'),
      color: 'bg-green-100 text-green-800'
    },
    {
      value: 'pending',
      label: t('DataPrivacyWizard.Pending'),
      color: 'bg-yellow-100 text-yellow-800'
    },
    {
      value: 'not_required',
      label: t('DataPrivacyWizard.NotRequired'),
      color: 'bg-gray-100 text-gray-800'
    },
    {
      value: 'missing',
      label: t('DataPrivacyWizard.Missing'),
      color: 'bg-red-100 text-red-800'
    }
  ];

  const countryName = getCountryName(transfer.country, countries) || '';
  const translationKey = `Countries.${countryName}`;
  const translatedCountryName = countryName ? t(translationKey) : '';

  return (
    <div className="space-y-4">
      {/* Processor Role */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.processorRole')}
          <button
            type="button"
            onClick={() => toggleDrawer(
              t('DataPrivacyWizard.processorRoleInfo.title'),
              t('DataPrivacyWizard.processorRoleInfo.content')
            )}
            className="ml-1 text-gray-400 hover:text-gray-500"
          >
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </button>
        </label>

        {isEditing ? (
          <div className="mt-1">
            <select
              value={transfer.processorRole}
              onChange={(e) => {
                formik.setFieldValue(`externalTransfers[${index}].processorRole`, e.target.value);
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-color focus:border-primary-color sm:text-sm rounded-md"
            >
              {translatedRoles.map(role => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="mt-1 text-sm text-gray-900">
            {translatedRoles.find(role => role.value === transfer.processorRole)?.label ||
              t('DataPrivacyWizard.notSpecified')}
          </div>
        )}
      </div>

      {/* Country Information */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.processingCountry')}
          <button
            type="button"
            onClick={() => toggleDrawer(
              t('DataPrivacyWizard.processingCountryInfo.title'),
              t('DataPrivacyWizard.processingCountryInfo.content')
            )}
            className="ml-1 text-gray-400 hover:text-gray-500"
          >
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </button>
        </label>

        {isEditing ? (
          <>
            <div className="flex items-start mb-2">
              <input
                type="checkbox"
                id={`external-multiple-${index}`}
                checked={transfer.multipleCountries}
                onChange={(e) => {
                  formik.setFieldValue(`externalTransfers[${index}].multipleCountries`, e.target.checked);
                  if (!e.target.checked) {
                    formik.setFieldValue(`externalTransfers[${index}].thirdCountries`, false);
                  }
                }}
                className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
              />
              <label
                htmlFor={`external-multiple-${index}`}
                className="ml-2 block text-sm text-gray-700"
              >
                {t('DataPrivacyWizard.multipleCountriesOption')}
              </label>
            </div>

            {!transfer.multipleCountries ? (
              <div className="mt-1">
                <CountrySelector
                  value={transfer.country}
                  onChange={(value) => {
                    formik.setFieldValue(`externalTransfers[${index}].country`, value);
                  }}
                  className="w-full"
                />
              </div>
            ) : (
              <div className="mt-3 pl-6">
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id={`external-third-countries-${index}`}
                    checked={transfer.thirdCountries}
                    onChange={(e) => {
                      formik.setFieldValue(`externalTransfers[${index}].thirdCountries`, e.target.checked);
                    }}
                    className="h-4 w-4 text-primary-color border-gray-300 rounded mt-1"
                  />
                  <div className="ml-2">
                    <label
                      htmlFor={`external-third-countries-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('DataPrivacyWizard.includesThirdCountries')}
                    </label>
                    <p className="mt-1 text-xs text-gray-500">
                      {t('DataPrivacyWizard.thirdCountriesExplanation')}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => toggleDrawer(
                      t('DataPrivacyWizard.thirdCountriesInfo.title'),
                      t('DataPrivacyWizard.thirdCountriesInfo.content', countries, userLang)
                    )}
                    className="ml-1 text-gray-400 hover:text-gray-500"
                  >
                    <QuestionMarkCircleIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="mt-1 text-sm text-gray-900">
            {transfer.multipleCountries ? (
              <div>
                <span>{t('DataPrivacyWizard.multipleCountries')}</span>
                {transfer.thirdCountries && (
                  <div className="mt-1">
                    <span className="font-medium text-amber-600">
                      {t('DataPrivacyWizard.includesThirdCountries')}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {translatedCountryName}
                </div>

                {transfer.country && countryComplianceStatus && (
                  <div className="mt-0">
                    {countryComplianceStatus === 'gdpr' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'gdpr-like' && (
                      <span className="font-medium text-green-600 text-xs">
                        {t('DataPrivacyWizard.gdprEquivalentCountry')}
                      </span>
                    )}
                    {countryComplianceStatus === 'third-country' && (
                      <span className="font-medium text-amber-600 text-xs">
                        {t('DataPrivacyWizard.nonGdprCountry')}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* DPA Status */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.dpaStatus')}
          <button
            type="button"
            onClick={() => toggleDrawer(
              t('DataPrivacyWizard.dpaInfo.title'),
              t('DataPrivacyWizard.dpaInfo.content')
            )}
            className="ml-1 text-gray-400 hover:text-gray-500"
          >
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </button>
        </label>

        {isEditing ? (
          <div className="mt-1">
            <select
              value={transfer.dpaStatus}
              onChange={(e) => {
                formik.setFieldValue(`externalTransfers[${index}].dpaStatus`, e.target.value);
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-color focus:border-primary-color sm:text-sm rounded-md"
            >
              {translatedDpaOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="mt-1 text-sm text-gray-900">
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${translatedDpaOptions.find(opt => opt.value === transfer.dpaStatus)?.color ||
              'bg-gray-100 text-gray-800'
              }`}>
              {translatedDpaOptions.find(opt => opt.value === transfer.dpaStatus)?.label ||
                t('DataPrivacyWizard.notSpecified')}
            </span>
          </div>
        )}
      </div>

      {/* DPA Link - Show only when status is not 'missing' or 'not_required' */}
      {transfer.dpaStatus !== 'missing' && transfer.dpaStatus !== 'not_required' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            {t('DataPrivacyWizard.dpaLink')}
            <button
              type="button"
              onClick={() => toggleDrawer(
                t('DataPrivacyWizard.dpaLinkInfo.title'),
                t('DataPrivacyWizard.dpaLinkInfo.content')
              )}
              className="ml-1 text-gray-400 hover:text-gray-500"
            >
              <QuestionMarkCircleIcon className="h-4 w-4" />
            </button>
          </label>

          {isEditing ? (
            <div className="mt-1">
              <input
                type="url"
                value={transfer.dpaLink || ''}
                onChange={(e) => {
                  formik.setFieldValue(`externalTransfers[${index}].dpaLink`, e.target.value);
                }}
                placeholder="https://..."
                className="shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          ) : (
            <div className="mt-1 text-sm text-gray-900">
              {transfer.dpaLink ? (
                <a
                  href={transfer.dpaLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-color hover:text-secondary-color inline-flex items-center"
                >
                  <LinkIcon className="h-4 w-4 mr-1" />
                  {t('DataPrivacyWizard.viewDpa')}
                </a>
              ) : (
                <span className="text-gray-500">
                  {t('DataPrivacyWizard.noDpaLink')}
                </span>
              )}
            </div>
          )}
        </div>
      )}

      {/* "Why is no DPA required?" field - Show only when status is 'not_required' */}
      {transfer.dpaStatus === 'not_required' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            {t('DataPrivacyWizard.whyNoDpaRequired')}
            <button
              type="button"
              onClick={() => toggleDrawer(
                t('DataPrivacyWizard.whyNoDpaRequiredInfo.title'),
                t('DataPrivacyWizard.whyNoDpaRequiredInfo.content')
              )}
              className="ml-1 text-gray-400 hover:text-gray-500"
            >
              <QuestionMarkCircleIcon className="h-4 w-4" />
            </button>
          </label>

          {isEditing ? (
            <div className="mt-1">
              <textarea
                value={transfer.noDpaReason || ''}
                onChange={(e) => {
                  formik.setFieldValue(`externalTransfers[${index}].noDpaReason`, e.target.value);
                }}
                rows={2}
                className="shadow-sm text-sm focus:ring-primary-color focus:border-primary-color mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder={t('DataPrivacyWizard.explainWhyNoDpaRequired')}
              />
            </div>
          ) : (
            <div className="mt-1 text-sm text-gray-900">
              {transfer.noDpaReason ? (
                <p className="text-sm italic">{transfer.noDpaReason}</p>
              ) : (
                <span className="text-gray-500">
                  {t('DataPrivacyWizard.noReasonProvided')}
                </span>
              )}
            </div>
          )}
        </div>
      )}

      {/* Compliance Mechanism - Show only for third countries */}
      {((transfer.multipleCountries && transfer.thirdCountries) ||
        isThirdCountry(transfer.country, countries)) && (
          <ComplianceMechanismField
            transfer={transfer}
            index={index}
            formik={formik}
            isEditing={isEditing}
            fieldPrefix="externalTransfers"
            toggleDrawer={toggleDrawer}
            userLang={userLang}
          />
        )}

      {/* Connected Activities */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-1 flex items-center">
          {t('DataPrivacyWizard.connectedProcessingActivities')}
          <button
            type="button"
            onClick={() => toggleDrawer(
              t('DataPrivacyWizard.connectedActivitiesInfo.title'),
              t('DataPrivacyWizard.connectedActivitiesInfo.content')
            )}
            className="ml-1 text-gray-400 hover:text-gray-500"
          >
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </button>
        </h4>
        <ul className="mt-1 divide-y divide-gray-200 border rounded-md overflow-hidden">
          {recipientData.connectedActivities && recipientData.connectedActivities.length > 0 ? (
            recipientData.connectedActivities.map((activity) => (
              <li key={activity.id} className="px-4 py-3 text-sm text-gray-900 bg-white hover:bg-gray-50">
                {activity.name}
              </li>
            ))
          ) : (
            <li className="px-4 py-3 text-sm text-gray-500 bg-white">
              {t('DataPrivacyWizard.noConnectedActivities')}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ExternalRecipientDetails;