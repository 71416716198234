import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';

const CashflowTable = ({ values, setFieldValue, currency, foundingYear, currentYear }) => {
    const { t } = useTranslation();
    const [editingField, setEditingField] = useState(null);

    const addYear = () => {
        const newYear = values.cashflow[values.cashflow.length - 1].year + 1;
        setFieldValue('cashflow', [...values.cashflow, {
            year: newYear,
            expenses: 0,
            revenue: 0,
            profitLoss: 0,
            investments: 0,
            cashflow: 0,
            capital: 0
        }]);
    };

    const deleteYear = (yearToDelete) => {
        if (yearToDelete <= currentYear) return; // Can't delete non-projection years
        const updatedCashflow = values.cashflow.filter(row => row.year !== yearToDelete);
        setFieldValue('cashflow', updatedCashflow);
        recalculateTotals(updatedCashflow);
    };

    const recalculateTotals = (data) => {
        const updatedData = data.map((row, index) => {
            const profitLoss = row.revenue - row.expenses;
            const cashflow = profitLoss + row.investments;
            const capital = index === 0 ? cashflow : data[index - 1].capital + cashflow;
            return { ...row, profitLoss, cashflow, capital };
        });
        setFieldValue('cashflow', updatedData);
    };

    const updateRowCalculations = (index, field, value) => {
        const updatedCashflow = [...values.cashflow];
        updatedCashflow[index][field] = Number(value);
        recalculateTotals(updatedCashflow);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    const handleFocus = (e) => {
        setEditingField(e.target.name);
    };

    const handleBlur = (e, index, field) => {
        setEditingField(null);
    };

    const getInputValue = (field, index, value) => {
        if (editingField === `${field}-${index}`) {
            return value === 0 ? '' : value.toString();
        }
        // When not editing, show formatted number without currency symbol
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };
    const totalInvestmentNeed = values.cashflow.reduce((sum, row) => sum + row.investments, 0);

    return (
        <div className="space-y-4">
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Year')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Expenses')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Revenue')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Profit/Loss')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Investments')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Cashflow')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Capital')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {values.cashflow.map((row, index) => (
                            <tr key={row.year}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <div className="flex items-center">
                                        <span>{row.year}</span>
                                        {row.year === foundingYear && (
                                            <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">
                                                {t('founding')}
                                            </span>
                                        )}
                                        {row.year === currentYear && (
                                            <span className="ml-2 text-xs bg-green-100 text-green-800 px-2 py-0.5 rounded">
                                                {t('current')}
                                            </span>
                                        )}
                                        {row.year > currentYear && (
                                            <span className="ml-2 text-xs bg-gray-100 text-gray-800 px-2 py-0.5 rounded">
                                                {t('projection')}
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    <div className="flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                                            {(() => {
                                                switch (currency) {
                                                    case 'USD': return '$';
                                                    case 'EUR': return '€';
                                                    case 'JPY': return '¥';
                                                    case 'GBP': return '£';
                                                    case 'AUD': return 'A$';
                                                    case 'BRL': return 'R$';
                                                    case 'CAD': return 'C$';
                                                    case 'CHF': return 'Fr.';
                                                    case 'CNY': return '¥';
                                                    case 'HKD': return 'HK$';
                                                    case 'INR': return '₹';
                                                    case 'KRW': return '₩';
                                                    case 'MXN': return 'Mex$';
                                                    case 'NOK': return 'kr';
                                                    case 'NZD': return 'NZ$';
                                                    case 'RUB': return '₽';
                                                    case 'SEK': return 'kr';
                                                    case 'SGD': return 'S$';
                                                    case 'TRY': return '₺';
                                                    case 'ZAR': return 'R';
                                                    default: return '€';
                                                }
                                            })()}
                                        </span>
                                        {editingField === `expenses-${index}` ? (
                                            // Number input when editing
                                            <input
                                                type="number"
                                                name={`expenses-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={row.expenses || ''}
                                                onChange={(e) => updateRowCalculations(index, 'expenses', e.target.value)}
                                                onFocus={handleFocus}
                                                onBlur={(e) => handleBlur(e, index, 'expenses')}
                                                step="1000"
                                            />
                                        ) : (
                                            // Formatted text display when not editing
                                            <input
                                                type="text"
                                                name={`expenses-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(row.expenses)}
                                                onFocus={handleFocus}
                                                readOnly
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    <div className="flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                                            {(() => {
                                                switch (currency) {
                                                    case 'USD': return '$';
                                                    case 'EUR': return '€';
                                                    case 'JPY': return '¥';
                                                    case 'GBP': return '£';
                                                    case 'AUD': return 'A$';
                                                    case 'BRL': return 'R$';
                                                    case 'CAD': return 'C$';
                                                    case 'CHF': return 'Fr.';
                                                    case 'CNY': return '¥';
                                                    case 'HKD': return 'HK$';
                                                    case 'INR': return '₹';
                                                    case 'KRW': return '₩';
                                                    case 'MXN': return 'Mex$';
                                                    case 'NOK': return 'kr';
                                                    case 'NZD': return 'NZ$';
                                                    case 'RUB': return '₽';
                                                    case 'SEK': return 'kr';
                                                    case 'SGD': return 'S$';
                                                    case 'TRY': return '₺';
                                                    case 'ZAR': return 'R';
                                                    default: return '€';
                                                }
                                            })()}
                                        </span>
                                        {editingField === `revenue-${index}` ? (
                                            <input
                                                type="number"
                                                name={`revenue-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={row.revenue || ''}
                                                onChange={(e) => updateRowCalculations(index, 'revenue', e.target.value)}
                                                onFocus={handleFocus}
                                                onBlur={(e) => handleBlur(e, index, 'revenue')}
                                                step="1000"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={`revenue-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(row.revenue)}
                                                onFocus={handleFocus}
                                                readOnly
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className={`px-6 py-4 whitespace-nowrap text-sm ${row.profitLoss >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {formatCurrency(row.profitLoss)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm">
                                    <div className="flex">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-2 text-sm text-gray-500">
                                            {(() => {
                                                switch (currency) {
                                                    case 'USD': return '$';
                                                    case 'EUR': return '€';
                                                    case 'JPY': return '¥';
                                                    case 'GBP': return '£';
                                                    case 'AUD': return 'A$';
                                                    case 'BRL': return 'R$';
                                                    case 'CAD': return 'C$';
                                                    case 'CHF': return 'Fr.';
                                                    case 'CNY': return '¥';
                                                    case 'HKD': return 'HK$';
                                                    case 'INR': return '₹';
                                                    case 'KRW': return '₩';
                                                    case 'MXN': return 'Mex$';
                                                    case 'NOK': return 'kr';
                                                    case 'NZD': return 'NZ$';
                                                    case 'RUB': return '₽';
                                                    case 'SEK': return 'kr';
                                                    case 'SGD': return 'S$';
                                                    case 'TRY': return '₺';
                                                    case 'ZAR': return 'R';
                                                    default: return '€';
                                                }
                                            })()}
                                        </span>
                                        {editingField === `investments-${index}` ? (
                                            <input
                                                type="number"
                                                name={`investments-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={row.investments || ''}
                                                onChange={(e) => updateRowCalculations(index, 'investments', e.target.value)}
                                                onFocus={handleFocus}
                                                onBlur={(e) => handleBlur(e, index, 'investments')}
                                                step="1000"
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name={`investments-${index}`}
                                                className="block w-full rounded-none rounded-r-md border-gray-300 px-2 py-1 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                value={new Intl.NumberFormat('en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }).format(row.investments)}
                                                onFocus={handleFocus}
                                                readOnly
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className={`px-6 py-4 whitespace-nowrap text-sm ${row.cashflow >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                                    {formatCurrency(row.cashflow)}
                                </td>
                                <td className={`px-6 py-4 whitespace-nowrap text-sm ${row.capital < 0 ? 'text-red-600 font-bold' : 'text-gray-500'}`}>
                                    {formatCurrency(row.capital)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {row.year > currentYear && (
                                        <button
                                            type="button"
                                            onClick={() => deleteYear(row.year)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center">
                <button
                    type="button"
                    onClick={addYear}
                    className="px-4 py-2 bg-secondary-color text-white rounded hover:bg-secondary-color/80 focus:outline-none focus:ring-2 focus:ring-secondary inline-flex items-center gap-2"
                >
                    <PlusIcon className="h-5 w-5" />
                    {t('Add Year (Projection)')}
                </button>

                <div className="text-normal">
                    <span className="font-bold">{t('Sum of Investments needed')}: {formatCurrency(totalInvestmentNeed)}</span>
                </div>
            </div>
        </div>
    );
};

CashflowTable.propTypes = {
    foundingYear: PropTypes.number.isRequired,
    currentYear: PropTypes.number.isRequired,
    values: PropTypes.shape({
        cashflow: PropTypes.arrayOf(
            PropTypes.shape({
                year: PropTypes.number.isRequired,
                expenses: PropTypes.number.isRequired,
                revenue: PropTypes.number.isRequired,
                profitLoss: PropTypes.number.isRequired,
                investments: PropTypes.number.isRequired,
                cashflow: PropTypes.number.isRequired,
                capital: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
};

export default CashflowTable;