import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Checkthumbs from '../../../components/wizard_components/Checkthumbs';
import TextField from '../../../components/wizard_components/TextField';
import KnowYourselfWizardMarketDrawer from '../../../components/wizard_components/KnowYourselfWizardMarketDrawer';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { countryData, getCountriesBySubregion } from '../../../constants/countryData';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardRegions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/funding/market');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);
  const [dataStatus, setDataStatus] = useState('published');
  const [initialValues, setInitialValues] = useState({
    regions: [],
    subregions: [],
    marketNotes: ''
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerSubregion, setDrawerSubregion] = useState('');
  const [drawerCountries, setDrawerCountries] = useState([]);

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        regions: draft?.regions || [],
        subregions: draft?.subregions || [],
        marketNotes: draft?.marketNotes || ''
      };
      const relevantPublishedData = {
        regions: published?.regions || [],
        subregions: published?.subregions || [],
        marketNotes: published?.marketNotes || ''
      };

      const isDataEmpty = (data) => {
        return Object.values(data).every(value =>
          value === '' ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        );
      };

      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues(relevantDraftData);
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues(relevantPublishedData);
      } else {
        setDataStatus('new');
        setInitialValues({
          regions: [],
          subregions: [],
          marketNotes: ''
        });
      }
    }
  }, [userData]);

  if (userDataLoading || schemaLoading) {
    return <div>{t('Loading...')}</div>;
  }

  const validationSchema = Yup.object({
    regions: Yup.array().min(1, t('KnowYourselfWizard.regionsRequired')),
    subregions: Yup.array().min(1, t('KnowYourselfWizard.subregionsRequired')),
    marketNotes: Yup.string().max(1000, t('KnowYourselfWizard.notesMaxLength'))
  });

  const regionSvgs = {
    "Worldwide": {
      default: '/thumbs/Worldwide_region.svg',
      hover: '/thumbs/Worldwide_region_hover.svg',
      active: '/thumbs/Worldwide_region_active.svg'
    },
    "Europe": {
      default: '/thumbs/Europe_region.svg',
      hover: '/thumbs/Europe_region_hover.svg',
      active: '/thumbs/Europe_region_active.svg'
    },
    "North America": {
      default: '/thumbs/NorthAmerica_region.svg',
      hover: '/thumbs/NorthAmerica_region_hover.svg',
      active: '/thumbs/NorthAmerica_region_active.svg'
    },
    "Latin America and the Caribbean": {
      default: '/thumbs/LatinAmerica_region.svg',
      hover: '/thumbs/LatinAmerica_region_hover.svg',
      active: '/thumbs/LatinAmerica_region_active.svg'
    },
    "Sub-Saharan Africa": {
      default: '/thumbs/SubSaharanAfrica_region.svg',
      hover: '/thumbs/SubSaharanAfrica_region_hover.svg',
      active: '/thumbs/SubSaharanAfrica_region_active.svg'
    },
    "Middle East and North Africa": {
      default: '/thumbs/MiddleEast_region.svg',
      hover: '/thumbs/MiddleEast_region_hover.svg',
      active: '/thumbs/MiddleEast_region_active.svg'
    },
    "South and Central Asia": {
      default: '/thumbs/SouthAsia_region.svg',
      hover: '/thumbs/SouthAsia_region_hover.svg',
      active: '/thumbs/SouthAsia_region_active.svg'
    },
    "East Asia and Pacific": {
      default: '/thumbs/EastAsia_region.svg',
      hover: '/thumbs/EastAsia_region_hover.svg',
      active: '/thumbs/EastAsia_region_active.svg'
    },
    "Clear": {
      default: '/thumbs/Delete_region.svg',
      hover: '/thumbs/Delete_region_hover.svg',
      active: '/thumbs/Delete_region_active.svg'
    }
  };
  const getTranslatedRegions = () => {
    const regions = schema.properties.region.enum.map((region) => ({
      value: region,
      svg: regionSvgs[region],
      label: t(`Regions.${region}`)
    }));
    const worldwide = {
      value: "Worldwide",
      svg: regionSvgs["Worldwide"],
      label: t("Regions.Worldwide")
    };
    return [worldwide, ...regions];
  };

  const getTranslatedSubregions = (selectedRegions) => {
    if (!selectedRegions || selectedRegions.length === 0) return [];
    if (selectedRegions.includes("Worldwide")) {
      return schema.properties.subregion.dependencies.region.oneOf.flatMap((regionData) =>
        regionData.properties.subregion.enum.map((subregion) => ({
          value: subregion,
          svg: regionSvgs[regionData.properties.region.enum[0]],
          label: t(`Subregions.${subregion}`)
        }))
      );
    }
    return selectedRegions.flatMap((region) => {
      const regionData = schema.properties.subregion.dependencies.region.oneOf.find(
        (reg) => reg.properties.region.enum.includes(region)
      );
      if (regionData) {
        return regionData.properties.subregion.enum.map((subregion) => ({
          value: subregion,
          svg: regionSvgs[region],
          label: t(`Subregions.${subregion}`)
        }));
      }
      return [];
    });
  };

  const getCountriesForSubregion = (subregion) => {
    return getCountriesBySubregion(subregion).map(country => ({
      id: country.code,
      name: country.name,
      flag: country.flag
    }));
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: values });

      // Set a timeout to navigate after 1 second
      setTimeout(() => {
        navigate('/funding/market');
      }, 1000);
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: 'Failed to save data',
        errors: [error.message]
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      // Wrap the values in a 'responses' object
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
        <Notification
          show={notification.show}
          setShow={(show) => setNotification({ ...notification, show })}
          type={notification.type}
          message={notification.message}
          errors={notification.errors}
        />
        <Progress />
        <div className="data-status-banner mt-4 mb-4">
          {dataStatus === 'published' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('This is the last saved and published version.')}
            </span>
          )}
          {dataStatus === 'draft' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('This is a draft version. Some of the information on this page might not have been published yet.')}
            </span>
          )}
          {dataStatus === 'new' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('There is no prior version of this page. Please manually save or save as draft below.')}
            </span>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting, validateForm }) => {
            const handleRegionsChange = (newRegions) => {
              setFieldValue('regions', newRegions);
              if (newRegions.includes("Worldwide")) {
                const allRegions = schema.properties.region.enum;
                setFieldValue('regions', allRegions);
                const allSubregions = getTranslatedSubregions(allRegions).map(subregion => subregion.value);
                setFieldValue('subregions', allSubregions);
              } else {
                const subregions = getTranslatedSubregions(newRegions).map(subregion => subregion.value);
                setFieldValue('subregions', (values.subregions || []).filter(sr => subregions.includes(sr)));
              }
            };

            const handleSubregionsChange = (newSubregions) => {
              setFieldValue('subregions', newSubregions);
            };

            const handleQuestionClick = (subregion) => {
              // If drawer is already open with this subregion, close it
              if (drawerOpen && drawerSubregion === subregion) {
                setDrawerOpen(false);
              } else {
                // Open drawer with new content
                setDrawerSubregion(subregion);
                setDrawerCountries(getCountriesForSubregion(subregion));
                setDrawerOpen(true);
              }
            };

            const handleSubmit = async (e) => {
              e.preventDefault();
              const errors = await validateForm(values);
              if (Object.keys(errors).length === 0) {
                onSubmit(values, { setSubmitting: () => { } });
              } else {
                const errorMessages = Object.entries(errors).map(([field, message]) => `${t(`KnowYourselfWizard.${field}`)}: ${message}`);
                setNotification({
                  show: true,
                  type: 'error',
                  message: t('Failed to save data due to validation errors:'),
                  errors: errorMessages
                });
              }
            };

            const handleClearSelection = () => {
              setFieldValue('regions', []);
              setFieldValue('subregions', []);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <h2 className="text-primary-color">{t('KnowYourselfWizard.regions')}</h2>
                <p className="mb-8">{t('KnowYourselfWizard.regionsIntro')}</p>
                {/* Recommendations Section */}
                <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center">
                      <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                      <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                      className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>

                  {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">

                      <div className="space-y-2 text-gray-700">
                        <h4 className="text-sm font-semibold text-gray-900 pt-2">
                          {t('regions.recommendations.definingMarketReach')}
                        </h4>
                        <p className="text-sm">
                          {t('regions.recommendations.marketReachDescription')}
                        </p>

                        <h4 className="text-sm font-semibold text-gray-900 pt-2 pb-2">
                          {t('regions.recommendations.strategicConsiderations')}
                        </h4>
                        <ul className="text-sm list-disc list-inside">
                          <li>{t('regions.recommendations.strategicConsiderationsList.existingCustomerBase')}</li>
                          <li>{t('regions.recommendations.strategicConsiderationsList.businessModelAlignment')}</li>
                          <li>{t('regions.recommendations.strategicConsiderationsList.growthPotential')}</li>
                          <li>{t('regions.recommendations.strategicConsiderationsList.competitiveLandscape')}</li>
                        </ul>

                        <h4 className="text-sm font-semibold text-gray-900 pt-2">
                          {t('regions.recommendations.regionalSelectionFactors')}
                        </h4>
                        <p className="text-sm pb-2">
                          {t('regions.recommendations.regionalSelectionDescription')}
                        </p>
                        <ul className="text-sm list-disc list-inside">
                          <li>{t('regions.recommendations.regionalSelectionFactorsList.marketSize')}</li>
                          <li>{t('regions.recommendations.regionalSelectionFactorsList.regulatoryEnvironment')}</li>
                          <li>{t('regions.recommendations.regionalSelectionFactorsList.economicStability')}</li>
                          <li>{t('regions.recommendations.regionalSelectionFactorsList.culturalFit')}</li>
                        </ul>

                        <h4 className="text-sm font-semibold text-gray-900 pt-2">
                          "{t('KnowYourselfWizard.marketNotesDescription')}"
                        </h4>
                        <p className="text-sm">
                          {t('regions.recommendations.notesContext')}
                        </p>
                        <ul className="text-sm list-disc list-inside">
                          <li>{t('regions.recommendations.notesContextList.marketResearch')}</li>
                          <li>{t('regions.recommendations.notesContextList.strategyNuances')}</li>
                          <li>{t('regions.recommendations.notesContextList.futureExpansion')}</li>
                          <li>{t('regions.recommendations.notesContextList.operationalChallenges')}</li>
                        </ul>

                        <p className="text-sm pt-2 italic text-gray-600">
                          {t('regions.recommendations.selectionTip')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bg-white p-6 rounded-lg shadow">
                  <p className="mt-2">{t('KnowYourselfWizard.regionsDescription')}</p>
                  <div className="relative">
                    <Checkthumbs
                      name="regions"
                      options={[
                        ...getTranslatedRegions(),
                        {
                          value: "Clear",
                          svg: regionSvgs["Clear"],
                          label: t('Clear Selection')
                        }
                      ]}
                      value={values.regions}
                      onChange={(newRegions) => {
                        if (newRegions.includes("Clear")) {
                          handleClearSelection();
                        } else {
                          handleRegionsChange(newRegions);
                        }
                      }}
                    />
                  </div>
                  <div className="mt-6"></div>
                  <p className="mt-2">{t('KnowYourselfWizard.subregionsDescription')}</p>
                  <Checkthumbs
                    name="subregions"
                    options={getTranslatedSubregions(values.regions)}
                    value={values.subregions}
                    onChange={handleSubregionsChange}
                    onQuestionClick={handleQuestionClick}
                  />
                  <div className="mt-6"></div>
                  <p>{t('KnowYourselfWizard.marketNotesDescription')}</p>
                  <TextField
                    name="marketNotes"
                    type="textarea"
                  />
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className="text-sm text-gray-900"
                    onClick={() => navigate('/funding')}
                  >
                    {t('cancel')}
                  </button>
                  <button
                    type="button"
                    onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                    disabled={isSubmitting}
                    className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    {t('Save as Draft')}
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                  >
                    {isSubmitting ? t('saving') : t('save')}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <KnowYourselfWizardMarketDrawer
        isOpen={drawerOpen}
        subregion={drawerSubregion}
        countries={drawerCountries}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
};

export default KnowYourselfWizardRegions;