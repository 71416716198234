import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SparklesIcon, BriefcaseIcon, LockClosedIcon, CheckIcon } from '@heroicons/react/24/solid';
import { AuthContext } from '../context/AuthContext';
import { Helmet } from 'react-helmet';

function Upgrade() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  // Move all hooks to the top level, before any conditional logic
  const ownerUser = useMemo(() => {
    if (!user?.entity?.users) return null;
    const isOwner = user?.role === 'owner';
    if (!isOwner) {
      return user.entity.users.find(u => u.role === 'owner');
    }
    return null;
  }, [user]);

  const mailtoLink = useMemo(() => {
    if (!ownerUser || !user) return '#';
    const subject = encodeURIComponent(`${user.first_name} ${user.last_name} wants to upgrade to 'Professional' on Cenedril`);
    return `mailto:${ownerUser.email}?subject=${subject}`;
  }, [ownerUser, user]);

  // Early return after hooks
  if (!user || !user.entity) {
    return <div>{t('Loading...')}</div>;
  }

  const tier = user.entity.subscription_tier;
  const isOwner = user.role === 'owner';

  const professionalTier = {
    name: t('pricing.professional.name'),
    priceMonthly: '€9',
    description: t('pricing.professional.description'),
    features: [
      { text: t('pricing.professional.feature1'), soon: false },
      { text: t('pricing.professional.feature2'), soon: true },
      { text: t('pricing.professional.feature3'), soon: true },
      { text: t('pricing.professional.feature4'), soon: true },
      { text: t('pricing.professional.feature5'), soon: false },
    ],
  };

  return (
    <div>
      <div className="relative isolate pb-16 pt-16">
        <div className="mx-auto max-w-4xl text-center px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t('pricing.professional.upgrade')}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('pricing.professional.description')}
          </p>
        </div>
      </div>

      <div className="mt-6 mx-auto max-w-4xl px-6 lg:px-8">
        <div className="rounded-3xl bg-gray-900 shadow-2xl p-8 sm:p-10 text-white">
          <h3 className="text-base font-semibold leading-7 text-secondary-color">
            {professionalTier.name}
          </h3>
          <p className="mt-4 flex items-baseline gap-x-2">
            <span className="text-5xl font-bold tracking-tight text-white">
              {professionalTier.priceMonthly}
            </span>
            <span className="text-gray-400">{t('pricing.perMonth')}</span>
          </p>
          <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300">
            {professionalTier.features.map((feature) => (
              <li key={feature.text} className={`flex gap-x-1 ${feature.soon ? 'opacity-50' : ''}`}>
                <CheckIcon className="h-6 w-5 flex-none text-secondary-color" />
                {feature.text} {feature.soon && <b>({t('soon')})</b>}
              </li>
            ))}
          </ul>

          <div className="mt-8">
            {isOwner ? (
              <Link
                to="/billing"
                className="block rounded-md bg-primary-color px-3.5 py-2.5 text-center text-sm font-semibold text-white hover:bg-secondary-color"
              >
                {t('Upgrade on Billing-page')}
              </Link>
            ) : (
              <>
                {ownerUser ? (
                  <a
                    href={mailtoLink}
                    className="block rounded-md bg-white text-primary-color px-3.5 py-2.5 text-center text-sm font-semibold hover:bg-gray-100"
                  >
                    {t('Ask')} {ownerUser.first_name} {ownerUser.last_name} {t('to upgrade')}
                  </a>
                ) : (
                  <div className="mt-4 text-sm text-gray-200">
                    {t('Owner not found, please contact support.')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upgrade;