import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const DataPrivacyWizardProgress = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [expandedStep, setExpandedStep] = useState(null);

  // Define the steps in the wizard
  const steps = useMemo(() => [
    { id: 'introduction', name: t('DataPrivacyWizard.introduction'), route: '/data-privacy-wizard/introduction' },
    { id: 'ropa', name: t('DataPrivacyWizard.ropaNav'), route: '/data-privacy-wizard/ropa' },
    { id: 'datatransfer', name: t('DataPrivacyWizard.datatransfer'), route: '/data-privacy-wizard/datatransfer' },
    { id: 'dpia', name: t('DataPrivacyWizard.dpiaTitle'), route: '/data-privacy-wizard/dpia' },
    { id: 'toms', name: t('DataPrivacyWizard.toms'), route: '/data-privacy-wizard/toms' },
    { id: 'policies', name: t('DataPrivacyWizard.policies'), route: '/data-privacy-wizard/policies' },
    { id: 'dpa', name: t('DataPrivacyWizard.dpa'), route: '/data-privacy-wizard/dpa' },
    { id: 'implementation', name: t('DataPrivacyWizard.implementation'), route: '/data-privacy-wizard/implementation' }
  ], [t]);

  const handleStepClick = (step) => {
    if (step.route === location.pathname) return;

    if (expandedStep === step.name) {
      // Do nothing, as Link component will handle navigation
      setExpandedStep(null);
    } else {
      // If step is not expanded, expand it
      setExpandedStep(step.name);
    }
  };

  // Define the current step based on the URL
  const currentStep = useMemo(() => {
    // Extract the current step path from the URL
    const pathSegments = location.pathname.split('/');
    const currentPathSegment = pathSegments[pathSegments.length - 1];
    
    return steps.find(step => step.id === currentPathSegment) || steps[0];
  }, [location.pathname, steps]);

  return (
    <nav aria-label="Progress" className="overflow-x-auto">
      {/* Desktop version */}
      <div className="hidden lg:block">
        <ol role="list" className="lg:flex lg:space-x-8">
          {steps.map((step) => (
            <li key={step.name} className="lg:flex-1">
              <Link
                to={step.route}
                className={`group flex flex-col border-t-4 pb-6 pt-4 ${location.pathname === step.route
                    ? 'border-primary-color'
                    : 'border-gray-200 hover:border-gray-300'
                  }`}
                aria-current={location.pathname === step.route ? 'step' : undefined}
              >
                <span className={`text-xs font-medium ${location.pathname === step.route
                    ? 'text-primary-color'
                    : 'text-gray-500 group-hover:text-primary-color'
                  }`}>
                  {t('Section')} {steps.indexOf(step) + 1}
                </span>
                <span className={`text-xs font-bold ${location.pathname === step.route
                    ? 'text-black'
                    : 'text-gray-500 group-hover:text-gray-700'
                  }`}>
                  {step.name}
                </span>
              </Link>
            </li>
          ))}
        </ol>
      </div>

      {/* Mobile version */}
      <ol role="list" className="lg:hidden flex items-center px-2 mt-8">
        {steps.map((step, index) => {
          const isActive = location.pathname === step.route;
          const isExpanded = expandedStep === step.name;

          return (
            <li key={step.name} className="flex-shrink-0">
              <motion.div
                className="flex items-center"
                initial={false}
                animate={{ width: isActive || isExpanded ? 'auto' : '20px' }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className={`flex items-center ${isActive ? 'cursor-default' : 'cursor-pointer'}`}
                  onClick={() => handleStepClick(step)}
                >
                  {/* Dot or active/expanded section */}
                  <div
                    className={`flex items-center rounded-full transition-colors ${isActive
                        ? 'h-5 bg-primary-color mr-2' //active section
                        : isExpanded
                          ? 'h-5 bg-gray-400 hover:bg-gray-300 mr-2' //other section
                          : 'w-3 h-3 bg-gray-400 hover:bg-gray-300' //dot
                      }`}
                  >
                    <AnimatePresence>
                      {(isActive || isExpanded) && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="flex items-center space-x-2 px-3"
                        >
                          <Link
                            to={step.route}
                            className={`text-xs font-bold whitespace-nowrap ${isActive ? 'text-white' : 'text-gray-700'}`}
                          >
                            {t('Step')} {index + 1}: {step.name}
                          </Link>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default DataPrivacyWizardProgress;