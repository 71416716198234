import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleSection from './CollapsibleSection';
import CategoryItem from './CategoryItem';

const AssetTypesSection = ({
  selectedClass,
  selectedCategory,
  types,
  selectedType,
  handleTypeSelect,
  expandedSections,
  toggleSection,
  loading,
  userLang = 'en'
}) => {
  const { t } = useTranslation();
  
  if (!selectedClass) return null;
  
  // For debugging
  console.log("AssetTypesSection - types:", types);
  console.log("AssetTypesSection - userLang:", userLang);
  
  if (loading && types.length === 0 && expandedSections.types) {
    return (
      <div className="p-3 text-center">
        <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 secondary-color-600 mr-2"></div>
        <span className="text-sm text-gray-600">{t("Loading...")}</span>
      </div>
    );
  }

  // Get the display value for the selected type
  const getSelectedTypeDisplay = () => {
    if (!selectedType) return null;
    return userLang === 'de' ? selectedType.werttyp : selectedType.asset_type;
  };

  return (
    <CollapsibleSection
      title={t('Asset Type')}
      isExpanded={expandedSections.types}
      onToggle={() => toggleSection('types')}
      selectedValue={getSelectedTypeDisplay()}
    >
      {types.length === 0 ? (
        <div className="p-3 text-center text-gray-500">
          {t('No asset types found for this class')}
        </div>
      ) : (
        types.map(type => (
          <CategoryItem
            key={type.id}
            category={type}
            selected={selectedType?.id === type.id}
            onSelect={handleTypeSelect}
            userLang={userLang}
          />
        ))
      )}
      
      {/* Other option - only shown if selected class is not "Other" */}
      {selectedClass && selectedClass.id !== 'other' && (
        <CategoryItem
          category={{ 
            id: 'other', 
            asset_type: userLang === 'de' ? 'Andere' : 'Other',
            werttyp: userLang === 'de' ? 'Andere' : 'Other',
            asset_class: selectedClass.asset_class,
            wertklasse: selectedClass.wertklasse,
            description: userLang === 'de' ? 'Benutzerdefinierter Asset-Typ' : 'Custom asset type',
            beschreibung: userLang === 'de' ? 'Benutzerdefinierter Asset-Typ' : 'Custom asset type',
          }}
          selected={selectedType?.id === 'other'}
          onSelect={handleTypeSelect}
          userLang={userLang}
        />
      )}
    </CollapsibleSection>
  );
};

export default AssetTypesSection;