import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { UsersIcon, EyeIcon, TrashIcon, ChartBarIcon, LockClosedIcon, UserIcon, BuildingOfficeIcon, HeartIcon, CurrencyDollarIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import axios from '../../axios';
import { getTranslatedValue } from '../../utils/templateTranslations';

const ReportsTable = ({ reports, refetchReports }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTemplateDetails, setShowTemplateDetails] = useState(null);
  const navigate = useNavigate();

  const getAreaIcon = (area) => {
    switch (area) {
      case 'cybersecurity':
        return <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'data_privacy':
        return <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'organization':
        return <BuildingOfficeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'culture':
        return <HeartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'finance':
        return <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    };
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDataResponse = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setUserData(userDataResponse.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchTemplateDetails = async (templateId) => {
    try {
      const response = await axios.get(`/api/v1/data_collections/templates/${templateId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      setSelectedTemplate(response.data);
      setShowTemplateDetails(true);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  if (!userData || !reports) {
    return <div className="text-center">{t('Loading...')}</div>;
  }

  const userLang = userData.language
    ? userData.language.slice(0, 2)
    : 'en';

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleDelete = async (collectionId) => {
    if (window.confirm(t('Are you sure you want to delete this data collection? All responses will be permanently deleted.'))) {
      try {
        await axios.delete(`/api/v1/data_collections/${collectionId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });

        refetchReports();
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  };

  const calculateTotalSources = (collection) => {
    if (collection.collection_type === 'individual') {
      // For individual collections, include both active and deleted respondents
      const deletedUsers = Object.values(collection.deleted_assignments || {})
        .flatMap(assignments => assignments.assignees || []);
      return (collection.respondents?.length || 0) + deletedUsers.length;
    } else if (collection.collection_type === 'collaborative') {
      // Get unique users from active assignments
      const activeUsers = new Set();
      collection.parts?.forEach(part => {
        // Add each editor and submitter to the set
        part.editors?.forEach(editor => activeUsers.add(editor));
        part.submitters?.forEach(submitter => activeUsers.add(submitter));
      });
  
      // Get unique users from deleted assignments
      const deletedUsers = new Set();
      Object.values(collection.deleted_assignments || {}).forEach(assignments => {
        (assignments.editors || []).forEach(editor => deletedUsers.add(editor.name));
        (assignments.submitters || []).forEach(submitter => deletedUsers.add(submitter.name));
      });
  
      // Return total unique users (both active and deleted)
      return activeUsers.size + deletedUsers.size;
    }
    return 0;
  };

  const handleViewReport = (collection) => {
    navigate(`/reports/report/${collection.access_token}`);
  };

  const getCollectionTypeLabel = (collection) => {
    if (collection.collection_type === 'individual') {
      if (collection.is_anonymous) {
        return collection.is_mandatory
          ? t('Individual, anonymous (mandatory)')
          : t('Individual, anonymous (optional)');
      } else {
        return t('Individual, named');
      }
    } else if (collection.collection_type === 'collaborative') {
      return t('Collaborative');
    }
    return '';
  };

  const getUserNameById = (users, userId) => {
    const user = users.find(u => u.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  // Filter reports where the user is a viewer
  const userReports = reports
    .filter(collection =>
      collection.viewers &&
      collection.viewers.some(viewer => viewer.id === userData.id)
    )
    .sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));

  return (
    <div className="grid grid-cols-1 gap-4">
      {userReports.map((collection) => (
        <div key={collection.id} className="bg-white rounded-lg shadow p-4">
          {/* Header */}
          <div className="flex flex-col space-y-2 mb-1">
            {/* Area indicator */}
            <div className="flex items-center text-sm text-gray-500">
              {getAreaIcon(collection.area)}
              <span className="ml-2">{t(`area_${collection.area}`)}</span>
            </div>
            {/* Title */}
            <button
              onClick={() => fetchTemplateDetails(collection.template_id)}
              className="text-left group"
            >
              <span className="text-lg font-semibold text-gray-900 group-hover:text-primary-color">
                {getTranslatedValue(collection.data, userLang)}
              </span>
              <span className="text-sm font-normal text-gray-500 ml-2">
                ({collection.period_value})
              </span>
            </button>
          </div>

          {/* Dates */}
          <div className="text-sm text-gray-600 mb-2">
            {t('Started on')} <span className="font-medium">{formatDate(collection.starts_at)}</span>
            {collection.ends_at && (
              <>, {t('completed on')} <span className="font-medium">{formatDate(collection.ends_at)}</span></>
            )}
          </div>

          {/* Collection Type and Sources */}
          <div className="flex items-center mb-1 text-sm">
            <Popover className="relative inline-block">
              <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                <UsersIcon className="h-4 w-4 mr-1" />
                <span>
                  {t('Sources')}: {calculateTotalSources(collection)}
                </span>
              </Popover.Button>

              <Popover.Panel className="absolute z-10 w-80 px-4 mt-3 transform -translate-x-1/2 left-1/2">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white p-3">
                    <div className="flow-root">
                      {collection.collection_type === 'individual' ? (
                        collection.respondents?.map((respondent, index) => (
                          <div key={index} className="flex items-center justify-between py-1">
                            <span className={`text-sm ${respondent.was_deleted ? 'text-gray-500 italic' : 'text-gray-900'}`}>
                              {respondent.was_deleted
                                ? `${respondent.user_name}`
                                : respondent.user_name}
                            </span>
                          </div>
                        ))
                      ) : (
                        collection.parts?.map((part, index) => {
                          // Get deleted users for this part
                          const deletedUsers = collection.deleted_assignments?.[part.id] || {};
                          const deletedEditors = deletedUsers.editors || [];
                          const deletedSubmitters = deletedUsers.submitters || [];
                      
                          // Format editors list (combining active and deleted)
                          const formattedEditors = [
                            ...part.editors.map(editor => ({ name: editor, isDeleted: false })),
                            ...deletedEditors.map(editor => ({ name: editor.name, isDeleted: true }))
                          ];
                      
                          // Format submitters list (combining active and deleted)
                          const formattedSubmitters = [
                            ...part.submitters.map(submitter => ({ name: submitter, isDeleted: false })),
                            ...deletedSubmitters.map(submitter => ({ name: submitter.name, isDeleted: true }))
                          ];
                      
                          return (
                            <div key={index} className="py-1">
                              <div className="flex items-center justify-between">
                                <span className="text-sm text-gray-900">
                                  {getTranslatedValue(part.name, userLang)}
                                </span>
                                <span className={`text-xs px-2 py-1 rounded-full ${
                                  part.status.status === 'responded'
                                    ? 'bg-green-100 text-green-800'
                                    : (deletedEditors.length > 0 || deletedSubmitters.length > 0)
                                      ? 'bg-red-100 text-red-800'
                                      : 'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {part.status.status === 'responded' 
                                    ? t('Finished') 
                                    : (deletedEditors.length > 0 || deletedSubmitters.length > 0)
                                      ? t('Blocked')
                                      : t('Pending')}
                                </span>
                              </div>
                              <div className="text-xs text-gray-600">
                                {t('Editors')}: {formattedEditors.map(editor => (
                                  <span key={editor.name} className={editor.isDeleted ? 'italic text-gray-500' : ''}>
                                    {editor.name}{editor.isDeleted ? ` (${t('deleted')})` : ''}
                                  </span>
                                )).reduce((prev, curr) => [prev, ', ', curr])}
                              </div>
                              <div className="text-xs text-gray-600">
                                {t('Submitters')}: {formattedSubmitters.map(submitter => (
                                  <span key={submitter.name} className={submitter.isDeleted ? 'italic text-gray-500' : ''}>
                                    {submitter.name}{submitter.isDeleted ? ` (${t('deleted')})` : ''}
                                  </span>
                                )).reduce((prev, curr) => [prev, ', ', curr])}
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>

            <span className="text-primary-color/60 ml-1">
              {getCollectionTypeLabel(collection)}
            </span>
          </div>

          {/* Viewers */}
          <div className="flex items-center mb-3 text-sm">
            <Popover className="relative inline-block">
              <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                <EyeIcon className="h-4 w-4 mr-1" />
                <span>
                  {t('Recipients')}: {collection.viewers?.length || 0}
                </span>
              </Popover.Button>

              <Popover.Panel className="absolute z-10 w-64 px-4 mt-3 transform -translate-x-1/2 left-1/2">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white p-3">
                    <div className="flow-root">
                      {collection.viewers?.map((viewer, index) => (
                        <div key={index} className="py-1">
                          <span className="text-sm text-gray-900">{viewer.user_name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </div>

          {/* Actions */}
          <div className="mt-4 space-y-2">
            <button
              onClick={() => handleViewReport(collection)}
              className="inline-flex items-center justify-center w-full py-2 px-4 text-white bg-primary-color hover:bg-primary-color/80 rounded"
            >
              <ChartBarIcon className="h-4 w-4 mr-2" />
              {t('See Report')}
            </button>

            {collection.created_by_id === userData?.id && (
              <button
                onClick={() => handleDelete(collection.id)}
                className="group inline-flex flex-col items-center justify-center w-full py-2 px-4 border-1 border-red-400 text-red-400 rounded hover:bg-red-700 hover:text-white transition-colors"
              >
                <span className="text-xs font-bold">{t('Delete data collection')}</span>
                <span className="text-xs">{t('Deletes all already provided responses!')}</span>
              </button>
            )}
          </div>
        </div>
      ))}

      {userReports.length === 0 && (
        <div className="text-center py-6 text-gray-500">
          {t('No reports available')}
        </div>
      )}
    </div>
  );
};

export default ReportsTable;