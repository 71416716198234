import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Checkthumbs from '../../../components/wizard_components/Checkthumbs';
import TextField from '../../../components/wizard_components/TextField';
import RadioButtons from '../../../components/wizard_components/RadioButtons';
import Checkmark from '../../../components/wizard_components/Checkmark';
import AgeRangeSlider from '../../../components/wizard_components/Slider';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useSchema } from '../../../hooks/useSchema';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardBusinessModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData, loading: userDataLoading } = useUserData();
  const { schema, loading: schemaLoading } = useSchema();
  const { handleSubmit, handleDraftSubmit, error: submitError } = useFormSubmit('/funding/product');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);
  const [dataStatus, setDataStatus] = useState('published');
  const [initialValues, setInitialValues] = useState({
    clientele: [],
    businessSectors: [],
    clienteleAgeGroup: [0, 100],
    clienteleGenders: 'All',
    clienteleIncome: [],
    revenueCategories: {
      SalesBasedModels: [],
      ServiceBasedModels: [],
      RecurringRevenueModels: [],
      AlternativeRevenueModels: []
    },
    modelNotes: ''
  });

  useEffect(() => {
    if (userData?.entity?.know_yourself_response) {
      const { published, draft } = userData.entity.know_yourself_response;
      const relevantDraftData = {
        clientele: draft?.clientele || [],
        businessSectors: draft?.businessSectors || [],
        clienteleAgeGroup: draft?.clienteleAgeGroup || [0, 100],
        clienteleGenders: draft?.clienteleGenders || 'All',
        clienteleIncome: draft?.clienteleIncome || [],
        revenueCategories: draft?.revenueCategories || {
          SalesBasedModels: [],
          ServiceBasedModels: [],
          RecurringRevenueModels: [],
          AlternativeRevenueModels: []
        },
        modelNotes: draft?.modelNotes || ''
      };
      const relevantPublishedData = {
        clientele: published?.clientele || [],
        businessSectors: published?.businessSectors || [],
        clienteleAgeGroup: published?.clienteleAgeGroup || [0, 100],
        clienteleGenders: published?.clienteleGenders || 'All',
        clienteleIncome: published?.clienteleIncome || [],
        revenueCategories: published?.revenueCategories || {
          SalesBasedModels: [],
          ServiceBasedModels: [],
          RecurringRevenueModels: [],
          AlternativeRevenueModels: []
        },
        modelNotes: published?.modelNotes || ''
      };

      const isDataEmpty = (data) => {
        return Object.values(data).every(value =>
          value === '' ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'object' && Object.keys(value).length === 0)
        );
      };

      const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

      if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
        setDataStatus('draft');
        setInitialValues(relevantDraftData);
      } else if (!isDataEmpty(relevantPublishedData)) {
        setDataStatus('published');
        setInitialValues(relevantPublishedData);
      } else {
        setDataStatus('new');
        setInitialValues({
          clientele: [],
          businessSectors: [],
          clienteleAgeGroup: [0, 100],
          clienteleGenders: 'All',
          clienteleIncome: [],
          revenueCategories: {
            SalesBasedModels: [],
            ServiceBasedModels: [],
            RecurringRevenueModels: [],
            AlternativeRevenueModels: []
          },
          modelNotes: ''
        });
      }
    }
  }, [userData]);

  if (userDataLoading || schemaLoading) {
    return <div>{t('Loading...')}</div>;
  }

  const validationSchema = Yup.object({
    clientele: Yup.array().min(1, t('KnowYourselfWizard.clienteleRequired')),
    businessSectors: Yup.array().when('clientele', {
      is: (val) => val && (val.includes('Business-to-Business (B2B)') || val.includes('Business-to-Business-to-Customer (B2B2C)')),
      then: (schema) => schema.min(1, t('KnowYourselfWizard.businessSectorsRequired')),
    }),
    clienteleAgeGroup: Yup.array().when('clientele', {
      is: (val) => val && (val.includes('Business-to-Customer (B2C)') || val.includes('Business-to-Business-to-Customer (B2B2C)') || val.includes('Customer-to-Customer (C2C)')),
      then: (schema) => schema.min(2).required(t('KnowYourselfWizard.ageGroupRequired')),
    }),
    clienteleGenders: Yup.string().when('clientele', {
      is: (val) => val && (val.includes('Business-to-Customer (B2C)') || val.includes('Business-to-Business-to-Customer (B2B2C)') || val.includes('Customer-to-Customer (C2C)')),
      then: (schema) => schema.required(t('KnowYourselfWizard.gendersRequired')),
    }),
    clienteleIncome: Yup.array().when('clientele', {
      is: (val) => val && (val.includes('Business-to-Customer (B2C)') || val.includes('Business-to-Business-to-Customer (B2B2C)') || val.includes('Customer-to-Customer (C2C)')),
      then: (schema) => schema.min(1, t('KnowYourselfWizard.incomeRequired')),
    }),
    revenueCategories: Yup.object().shape({
      SalesBasedModels: Yup.array(),
      ServiceBasedModels: Yup.array(),
      RecurringRevenueModels: Yup.array(),
      AlternativeRevenueModels: Yup.array()
    }).test('at-least-one-revenue-model', t('KnowYourselfWizard.revenueModelsRequired'), value => {
      return Object.values(value).some(arr => arr.length > 0);
    }),
    modelNotes: Yup.string().max(1000, t('KnowYourselfWizard.notesMaxLength'))
  });

  const industrySvgs = {
    "Agriculture": {
      default: '/thumbs/Agriculture_industry_check.svg',
      hover: '/thumbs/Agriculture_industry_check_hover.svg',
      active: '/thumbs/Agriculture_industry_check_active.svg'
    },
    "Climate and Environment": {
      default: '/thumbs/Climate_industry_check.svg',
      hover: '/thumbs/Climate_industry_check_hover.svg',
      active: '/thumbs/Climate_industry_check_active.svg'
    },
    "Consumer Goods / E-Commerce": {
      default: '/thumbs/Consumer_Goods_industry_check.svg',
      hover: '/thumbs/Consumer_Goods_industry_check_hover.svg',
      active: '/thumbs/Consumer_Goods_industry_check_active.svg'
    },
    "Consumer Services": {
      default: '/thumbs/Consumer_Services_industry_check.svg',
      hover: '/thumbs/Consumer_Services_industry_check_hover.svg',
      active: '/thumbs/Consumer_Services_industry_check_active.svg'
    },
    "Culture & Media": {
      default: '/thumbs/Media_industry_check.svg',
      hover: '/thumbs/Media_industry_check_hover.svg',
      active: '/thumbs/Media_industry_check_active.svg'
    },
    "Education": {
      default: '/thumbs/Education_industry_check.svg',
      hover: '/thumbs/Education_industry_check_hover.svg',
      active: '/thumbs/Education_industry_check_active.svg'
    },
    "Energy, Waste & Utilities": {
      default: '/thumbs/Energy_industry_check.svg',
      hover: '/thumbs/Energy_industry_check_hover.svg',
      active: '/thumbs/Energy_industry_check_active.svg'
    },
    "Finance": {
      default: '/thumbs/Finance_industry_check.svg',
      hover: '/thumbs/Finance_industry_check_hover.svg',
      active: '/thumbs/Finance_industry_check_active.svg'
    },
    "Government": {
      default: '/thumbs/Government_industry_check.svg',
      hover: '/thumbs/Government_industry_check_hover.svg',
      active: '/thumbs/Government_industry_check_active.svg'
    },
    "Healthcare": {
      default: '/thumbs/Healthcare_industry_check.svg',
      hover: '/thumbs/Healthcare_industry_check_hover.svg',
      active: '/thumbs/Healthcare_industry_check_active.svg'
    },
    "Manufacturing": {
      default: '/thumbs/Manufacturing_industry_check.svg',
      hover: '/thumbs/Manufacturing_industry_check_hover.svg',
      active: '/thumbs/Manufacturing_industry_check_active.svg'
    },
    "Mining, Quarrying, and Oil": {
      default: '/thumbs/Mining_industry_check.svg',
      hover: '/thumbs/Mining_industry_check_hover.svg',
      active: '/thumbs/Mining_industry_check_active.svg'
    },
    "Professional Services": {
      default: '/thumbs/Professional_industry_check.svg',
      hover: '/thumbs/Professional_industry_check_hover.svg',
      active: '/thumbs/Professional_industry_check_active.svg'
    },
    "Real Estate": {
      default: '/thumbs/Real_Estate_industry_check.svg',
      hover: '/thumbs/Real_Estate_industry_check_hover.svg',
      active: '/thumbs/Real_Estate_industry_check_active.svg'
    },
    "Technology": {
      default: '/thumbs/Technology_industry_check.svg',
      hover: '/thumbs/Technology_industry_check_hover.svg',
      active: '/thumbs/Technology_industry_check_active.svg'
    },
    "Telecommunication": {
      default: '/thumbs/Telecommunication_industry_check.svg',
      hover: '/thumbs/Telecommunication_industry_check_hover.svg',
      active: '/thumbs/Telecommunication_industry_check_active.svg'
    },
    "Transportation": {
      default: '/thumbs/Transportation_industry_check.svg',
      hover: '/thumbs/Transportation_industry_check_hover.svg',
      active: '/thumbs/Transportation_industry_check_active.svg'
    },
    "Other": {
      default: '/thumbs/Other_industry_check.svg',
      hover: '/thumbs/Other_industry_check_hover.svg',
      active: '/thumbs/Other_industry_check_active.svg'
    }
  };

  const getTranslatedGenders = () => {
    return schema.properties.clienteleGenders.enum.map((gender) => ({
      value: gender,
      label: t(`KnowYourselfWizard.${gender}`)
    }));
  };

  const getTranslatedIncome = () => {
    return schema.properties.clienteleIncome.items.enum.map((income) => ({
      value: income,
      label: t(`KnowYourselfWizard.${income}`)
    }));
  };

  const getTranslatedClientele = () => {
    return schema.properties.clientele.items.enum.map((clientele) => ({
      value: clientele,
      label: t(`KnowYourselfWizard.${clientele}`)
    }));
  };

  const getTranslatedBusinessSectors = () => {
    return schema.properties.businessSectors.items.enum.map((businessSector) => ({
      value: businessSector,
      svg: industrySvgs[businessSector],
      label: t(`Industries.${businessSector}`)
    }));
  };

  const getTranslatedRevenueModels = (category) => {
    return schema.properties.revenueCategories.properties[category].items.enum.map((revenueModel) => ({
      value: revenueModel,
      label: t(`KnowYourselfWizard.${revenueModel}`)
    }));
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      // Clear draft data by setting it to the same as the saved data
      await handleDraftSubmit({ responses: values });

      // Set a timeout to navigate after 1 second
      setTimeout(() => {
        navigate('/funding/product');
      }, 1000);
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: 'Failed to save data',
        errors: [error.message]
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      // Wrap the values in a 'responses' object
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
        <Notification
          show={notification.show}
          setShow={(show) => setNotification({ ...notification, show })}
          type={notification.type}
          message={notification.message}
          errors={notification.errors}
        />
        <Progress />
        <div className="data-status-banner mt-4 mb-4">
          {dataStatus === 'published' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('This is the last saved and published version.')}
            </span>
          )}
          {dataStatus === 'draft' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('This is a draft version. Some of the information on this page might not have been published yet.')}
            </span>
          )}
          {dataStatus === 'new' && (
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
              <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                <circle r={3} cx={3} cy={3} />
              </svg>
              {t('There is no prior version of this page. Please manually save or save as draft below.')}
            </span>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting, validateForm }) => {
            const handleSubmit = async (e) => {
              e.preventDefault();
              const errors = await validateForm(values);
              if (Object.keys(errors).length === 0) {
                onSubmit(values, { setSubmitting: () => { } });
              } else {
                const errorMessages = Object.entries(errors).map(([field, message]) => `${t(`KnowYourselfWizard.${field}`)}: ${message}`);
                setNotification({
                  show: true,
                  type: 'error',
                  message: t('Failed to save data due to validation errors:'),
                  errors: errorMessages
                });
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <h2 className="text-primary-color">{t('businessModel')}</h2>
                <p className="mb-8">{t('KnowYourselfWizard.businessModelIntro')}</p>

                {/* Business Model Recommendations */}
                <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center">
                      <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                      <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                      className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">
                      <div className="space-y-4 text-gray-700">
                        <div>
                          <h4 className="text-sm font-semibold text-gray-900">
                            {t('businessModelRecommendations.recommendations.customerSection.title')}
                          </h4>
                          <p className="text-sm">
                            {t('businessModelRecommendations.recommendations.customerSection.content')}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-sm font-semibold text-gray-900">
                            {t('businessModelRecommendations.recommendations.revenueSection.title')}
                          </h4>
                          <p className="text-sm">
                            {t('businessModelRecommendations.recommendations.revenueSection.content')}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="bg-white p-6 rounded-lg shadow mb-6">
                  <h3 className="mb-6 text-primary-color">{t('KnowYourselfWizard.businessModelBasis')}</h3>
                  <div className="business-model-section">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {getTranslatedClientele().map(option => (
                        <Checkmark
                          key={option.value}
                          id={option.value}
                          name="clientele"
                          label={option.label}
                          description={t(`KnowYourselfWizard.${option.value}Description`)}
                          checked={values.clientele?.includes(option.value) ?? false}
                          onChange={() => {
                            const newClientele = values.clientele?.includes(option.value)
                              ? values.clientele.filter(val => val !== option.value)
                              : [...(values.clientele ?? []), option.value];
                            setFieldValue('clientele', newClientele);
                          }}
                        />
                      ))}
                    </div>
                    <div>

                      {values.clientele?.includes('Business-to-Business (B2B)') ||
                        values.clientele?.includes('Business-to-Business-to-Customer (B2B2C)') ? (
                        <div>
                          <h3 className="mt-6">{t('KnowYourselfWizard.businessSectors')}</h3>
                          <Checkthumbs
                            name="businessSectors"
                            options={getTranslatedBusinessSectors()}
                            value={values.businessSectors}
                            onChange={(newBusinessSectors) =>
                              setFieldValue('businessSectors', newBusinessSectors)
                            }
                          />
                        </div>
                      ) : null}

                      {values.clientele?.includes('Business-to-Customer (B2C)') ||
                        values.clientele?.includes('Business-to-Business-to-Customer (B2B2C)') ||
                        values.clientele?.includes('Customer-to-Customer (C2C)') ? (
                        <div>
                          <h3 className="mt-8 mb-4">{t('KnowYourselfWizard.endConsumerDemographics')}</h3>
                          <p>{t('KnowYourselfWizard.ageGroupDescription')}</p>
                          <AgeRangeSlider
                            name="clienteleAgeGroup"
                            label={t('KnowYourselfWizard.ageGroup')}
                            value={values.clienteleAgeGroup}
                            onChange={(newAgeGroup) => setFieldValue('clienteleAgeGroup', newAgeGroup)}
                          />
                          <p className="mt-8 mb-4">{t('KnowYourselfWizard.genderDescription')}</p>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <RadioButtons
                              name="clienteleGenders"
                              options={getTranslatedGenders()}
                              value={values.clienteleGenders}
                              onChange={(newGender) => setFieldValue('clienteleGenders', newGender)}
                            />
                          </div>
                          <p className="mt-8 mb-4">{t('KnowYourselfWizard.incomeDescription')}</p>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {getTranslatedIncome().map(option => (
                              <Checkmark
                                key={option.value}
                                id={option.value}
                                name="clienteleIncome"
                                label={option.label}
                                checked={values.clienteleIncome?.includes(option.value) ?? false}
                                onChange={() => {
                                  const newIncome = values.clienteleIncome?.includes(option.value)
                                    ? values.clienteleIncome.filter(val => val !== option.value)
                                    : [...(values.clienteleIncome ?? []), option.value];
                                  setFieldValue('clienteleIncome', newIncome);
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                  <h3 className="text-primary-color">{t('KnowYourselfWizard.revenueModels')}</h3>

                  <h4 className="mt-8 mb-4 font-bold">{t('KnowYourselfWizard.Sales-Based Models')}</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {getTranslatedRevenueModels('SalesBasedModels').map(option => (
                      <Checkmark
                        key={option.value}
                        id={option.value}
                        name="revenueCategories.SalesBasedModels"
                        label={option.label}
                        description={t(`KnowYourselfWizard.${option.value}Description`)}
                        checked={values.revenueCategories.SalesBasedModels?.includes(option.value) ?? false}
                        onChange={() => {
                          const newRevenueModels = values.revenueCategories.SalesBasedModels?.includes(option.value)
                            ? values.revenueCategories.SalesBasedModels.filter(val => val !== option.value)
                            : [...(values.revenueCategories.SalesBasedModels ?? []), option.value];
                          setFieldValue('revenueCategories.SalesBasedModels', newRevenueModels);
                        }}
                      />
                    ))}
                  </div>

                  <h4 className="mt-8 mb-4 font-bold">{t('KnowYourselfWizard.Service-Based Models')}</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {getTranslatedRevenueModels('ServiceBasedModels').map(option => (
                      <Checkmark
                        key={option.value}
                        id={option.value}
                        name="revenueCategories.ServiceBasedModels"
                        label={option.label}
                        description={t(`KnowYourselfWizard.${option.value}Description`)}
                        checked={values.revenueCategories.ServiceBasedModels?.includes(option.value) ?? false}
                        onChange={() => {
                          const newRevenueModels = values.revenueCategories.ServiceBasedModels?.includes(option.value)
                            ? values.revenueCategories.ServiceBasedModels.filter(val => val !== option.value)
                            : [...(values.revenueCategories.ServiceBasedModels ?? []), option.value];
                          setFieldValue('revenueCategories.ServiceBasedModels', newRevenueModels);
                        }}
                      />
                    ))}
                  </div>

                  <h4 className="mt-8 mb-4 font-bold">{t('KnowYourselfWizard.Recurring Revenue Models')}</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {getTranslatedRevenueModels('RecurringRevenueModels').map(option => (
                      <Checkmark
                        key={option.value}
                        id={option.value}
                        name="revenueCategories.RecurringRevenueModels"
                        label={option.label}
                        description={t(`KnowYourselfWizard.${option.value}Description`)}
                        checked={values.revenueCategories.RecurringRevenueModels?.includes(option.value) ?? false}
                        onChange={() => {
                          const newRevenueModels = values.revenueCategories.RecurringRevenueModels?.includes(option.value)
                            ? values.revenueCategories.RecurringRevenueModels.filter(val => val !== option.value)
                            : [...(values.revenueCategories.RecurringRevenueModels ?? []), option.value];
                          setFieldValue('revenueCategories.RecurringRevenueModels', newRevenueModels);
                        }}
                      />
                    ))}
                  </div>

                  <h4 className="mt-8 mb-4 font-bold">{t('KnowYourselfWizard.Alternative Revenue Models')}</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {getTranslatedRevenueModels('AlternativeRevenueModels').map(option => (
                      <Checkmark
                        key={option.value}
                        id={option.value}
                        name="revenueCategories.AlternativeRevenueModels"
                        label={option.label}
                        description={t(`KnowYourselfWizard.${option.value}Description`)}
                        checked={values.revenueCategories.AlternativeRevenueModels?.includes(option.value) ?? false}
                        onChange={() => {
                          const newRevenueModels = values.revenueCategories.AlternativeRevenueModels?.includes(option.value)
                            ? values.revenueCategories.AlternativeRevenueModels.filter(val => val !== option.value)
                            : [...(values.revenueCategories.AlternativeRevenueModels ?? []), option.value];
                          setFieldValue('revenueCategories.AlternativeRevenueModels', newRevenueModels);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <div className="mt-8">
                    <p>{t('KnowYourselfWizard.businessNotesDescription')}</p>
                    <TextField name="modelNotes" type="textarea" />
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      className="text-sm text-gray-900"
                      onClick={() => navigate('/funding')}
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="button"
                      onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                      disabled={isSubmitting}
                      className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      {t('Save as Draft')}
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                    >
                      {isSubmitting ? t('saving') : t('save')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default KnowYourselfWizardBusinessModel;