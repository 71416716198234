import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

/**
 * Component to display an individual asset card with proper hierarchy and icon
 * Performs lookup of asset type, class, and category information based on asset_type_id
 * 
 * @param {Object} props
 * @param {Object} props.asset - The asset data to display
 * @param {Function} props.onRemove - Callback when the remove button is clicked
 * @param {string} props.userLang - User's language preference (default: 'en')
 */
const AssetCard = ({ asset, onRemove, userLang = 'en' }) => {
  const [enrichedAsset, setEnrichedAsset] = useState({ ...asset });
  const [loading, setLoading] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetClasses, setAssetClasses] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);
  const { t } = useTranslation();

  // Function to get the category icon
  const getCategoryIcon = (category) => {
    if (!category || !category.icons) return null;
    
    // Assuming icons field contains SVG filename
    const iconPath = `/is_icons/${category.icons}`;
    return iconPath;
  };

  // Fetch all the required data and enrich the asset
  useEffect(() => {
    const fetchData = async () => {
      // Skip enrichment if asset already has all the necessary data
      if ((asset.asset_type || asset.werttyp) &&
          (asset.asset_class || asset.wertklasse) &&
          (asset.asset_category || asset.wertkategorie)) {
        setEnrichedAsset(asset);
        return;
      }

      // Only proceed with enrichment if we have the asset_type_id
      if (!asset.asset_type_id) {
        setEnrichedAsset(asset);
        return;
      }

      setLoading(true);
      try {
        // Load asset types, classes, and categories
        const [typesRes, classesRes, categoriesRes] = await Promise.all([
          axios.get('/api/v1/static_data/asset_types'),
          axios.get('/api/v1/static_data/asset_classes'),
          axios.get('/api/v1/static_data/asset_categories')
        ]);

        setAssetTypes(typesRes.data || []);
        setAssetClasses(classesRes.data || []);
        setAssetCategories(categoriesRes.data || []);

        // Find matching type
        let enriched = { ...asset };
        
        const matchingType = typesRes.data.find(type => 
          type.id === asset.asset_type_id
        );
        
        if (matchingType) {
          // Add type info
          enriched.asset_type = matchingType.asset_type;
          enriched.werttyp = matchingType.werttyp;
          enriched.asset_class = matchingType.asset_class;
          
          // Find class info
          const matchingClass = classesRes.data.find(cls => 
            cls.asset_class === matchingType.asset_class
          );
          
          if (matchingClass) {
            enriched.wertklasse = matchingClass.wertklasse;
            enriched.asset_category = matchingClass.asset_category;
            
            // Find category info
            const matchingCategory = categoriesRes.data.find(cat => 
              cat.asset_categories === matchingClass.asset_category
            );
            
            if (matchingCategory) {
              enriched.wertkategorie = matchingCategory.wertkategorie;
              enriched.category_icon = matchingCategory.icons;
            }
          }
        }
        
        setEnrichedAsset(enriched);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching asset data for enrichment:', err);
        setLoading(false);
      }
    };

    fetchData();
  }, [asset]);

  // Render a loading state while fetching data
  if (loading) {
    return (
      <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-center">
        <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 secondary-color-600 mr-2"></div>
        <span className="text-sm text-gray-600">{t("Loading...")}</span>
      </div>
    );
  }

  return (
    <div className="bg-purple-100 p-4 rounded-lg">
      <div className="flex items-start">
        {/* Category icon */}
        <div className="mr-3 flex-shrink-0">
          {enrichedAsset.category_icon ? (
            <img
              src={getCategoryIcon({ icons: enrichedAsset.category_icon })}
              alt=""
              className="w-12 h-12"
            />
          ) : (
            <div className="w-12 h-12 bg-purple-200 rounded-md flex items-center justify-center">
              <span className="text-xs text-gray-600">Asset</span>
            </div>
          )}
        </div>

        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-start">
            <h4 className="text-sm font-semibold text-purple-900">{enrichedAsset.name}</h4>
            {onRemove && (
              <button
                type="button"
                onClick={() => onRemove(asset)}
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          
          {enrichedAsset.description && (
            <p className="text-xs text-purple-600 mt-2">{enrichedAsset.description}</p>
          )}

          {/* Breadcrumb for asset type hierarchy */}
          <div className="text-xs text-gray-500 mt-1 flex items-center flex-wrap">
            {/* Check if we have any meaningful categorization data */}
            {(enrichedAsset.asset_category || enrichedAsset.wertkategorie ||
              enrichedAsset.asset_class || enrichedAsset.wertklasse ||
              enrichedAsset.asset_type || enrichedAsset.werttyp) ? (
              <>
                {/* Render the full breadcrumb with available data */}
                {(enrichedAsset.asset_category || enrichedAsset.wertkategorie) && (
                  <>
                    <span>
                      {userLang === 'de' && enrichedAsset.wertkategorie 
                        ? enrichedAsset.wertkategorie 
                        : enrichedAsset.asset_category}
                    </span>
                    {(enrichedAsset.asset_class || enrichedAsset.wertklasse || 
                      enrichedAsset.asset_type || enrichedAsset.werttyp) && 
                      <span className="mx-1">›</span>
                    }
                  </>
                )}

                {(enrichedAsset.asset_class || enrichedAsset.wertklasse) && (
                  <>
                    <span>
                      {userLang === 'de' && enrichedAsset.wertklasse 
                        ? enrichedAsset.wertklasse 
                        : enrichedAsset.asset_class}
                    </span>
                    {(enrichedAsset.asset_type || enrichedAsset.werttyp) && 
                      <span className="mx-1">›</span>
                    }
                  </>
                )}

                {(enrichedAsset.asset_type || enrichedAsset.werttyp) && (
                  <span>
                    {userLang === 'de' && enrichedAsset.werttyp 
                      ? enrichedAsset.werttyp 
                      : enrichedAsset.asset_type}
                  </span>
                )}
              </>
            ) : (
              // If we don't have categorization data, try to determine if it's a custom asset
              <span>
                {enrichedAsset.custom_type 
                  ? `${userLang === 'de' ? 'Benutzerdefiniert' : 'Custom'}: ${enrichedAsset.custom_type}` 
                  : (userLang === 'de' ? 'Andere' : 'Other')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetCard;