import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AIChat from './AIChat';

const AIChatWrapper = () => {
  const [pageContent, setPageContent] = useState('');
  const location = useLocation();

  // Extract text content from the main content area of the page
  useEffect(() => {
    // Function to extract content
    const extractPageContent = () => {
      // Find the main content area (adjusting for your specific class names)
      const contentContainer = document.querySelector('.wizard-content') || 
                              document.querySelector('.content-area') || 
                              document.querySelector('main');
      
      if (!contentContainer) {
        console.warn('Could not find content container for AI Chat');
        setPageContent('');
        return;
      }
      
      // Extract header/title information
      const headings = Array.from(contentContainer.querySelectorAll('h1, h2, h3'))
        .map(el => el.textContent.trim())
        .filter(text => text.length > 0);
      
      // Extract paragraph content
      const paragraphs = Array.from(contentContainer.querySelectorAll('p, label, li, dt, dd'))
        .map(el => el.textContent.trim())
        .filter(text => text.length > 0);
      
      // Extract relevant UI elements that might provide context
      // Look for buttons, tabs, or other navigation elements that indicate what the page is about
      const uiElements = Array.from(contentContainer.querySelectorAll('button, .tab, .step-indicator'))
        .map(el => el.textContent.trim())
        .filter(text => text.length > 0);
      
      // Extract form field values
      const formValues = {};
      contentContainer.querySelectorAll('input, select, textarea').forEach(el => {
        if (el.id || el.name) {
          const key = el.id || el.name;
          if (el.type === 'checkbox' || el.type === 'radio') {
            formValues[key] = el.checked ? 'Yes' : 'No';
          } else if (el.value) {
            formValues[key] = el.value;
          }
        }
      });
      
      // Look for any progress information
      const progressInfo = Array.from(contentContainer.querySelectorAll('[aria-label="Progress"], .progress'))
        .map(el => el.textContent.trim())
        .filter(text => text.length > 0);
      
      // Format all the content
      let formattedContent = '';
      
      if (headings.length > 0) {
        formattedContent += `Page Title: ${headings[0]}\n\n`;
        
        if (headings.length > 1) {
          formattedContent += `Sections:\n${headings.slice(1).join('\n')}\n\n`;
        }
      }
      
      if (paragraphs.length > 0) {
        formattedContent += `Content:\n${paragraphs.join('\n')}\n\n`;
      }
      
      if (uiElements.length > 0) {
        formattedContent += `UI Elements:\n${uiElements.join(', ')}\n\n`;
      }
      
      if (progressInfo.length > 0) {
        formattedContent += `Progress:\n${progressInfo.join('\n')}\n\n`;
      }
      
      if (Object.keys(formValues).length > 0) {
        formattedContent += `Current Form Values:\n${Object.entries(formValues)
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n')}\n\n`;
      }
      
      // URL path information can also be useful
      formattedContent += `Current path: ${location.pathname}`;
      
      setPageContent(formattedContent);
    };
    
    // Initial extraction on component mount
    extractPageContent();
    
    // Set up MutationObserver to detect content changes
    // This helps capture dynamic content that loads after initial render
    const observer = new MutationObserver((mutations) => {
      // Debounce the extraction to avoid too many updates
      if (window.aiChatTimeout) {
        clearTimeout(window.aiChatTimeout);
      }
      
      window.aiChatTimeout = setTimeout(() => {
        extractPageContent();
      }, 500);
    });
    
    const contentContainer = document.querySelector('.wizard-content') || 
                            document.querySelector('.content-area') || 
                            document.querySelector('main');
    
    if (contentContainer) {
      observer.observe(contentContainer, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
        attributeFilter: ['value', 'checked']
      });
    }
    
    // Clean up
    return () => {
      if (window.aiChatTimeout) {
        clearTimeout(window.aiChatTimeout);
      }
      observer.disconnect();
    };
  }, [location.pathname]);

  return <AIChat currentPageContent={pageContent} />;
};

export default AIChatWrapper;