import React, { memo, useState, useContext, useEffect, useCallback, useMemo, Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  ShieldExclamationIcon,
  ArrowLeftOnRectangleIcon,
  BriefcaseIcon,
  Square3Stack3DIcon,
  CreditCardIcon,
  Bars3Icon,
  XMarkIcon,
  BellIcon
} from '@heroicons/react/24/outline';
import { TaskContext } from '../context/TaskContext';
import { AlertsContext } from '../context/AlertsContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MobileNavigation() {
  const { user, logout } = useContext(AuthContext);
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);
  const { newTasksCount, shouldAnimate } = useContext(TaskContext);
  const [open, setOpen] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isFounder = user?.entity?.subscription_tier === 'founder';
  const { 
    alertsCount, 
    shouldAnimate: alertsShouldAnimate, 
    fetchAlerts 
  } = useContext(AlertsContext);

    useEffect(() => {
      // Fetch alerts when the sidebar is initialized
      const fetchInitialAlerts = async () => {
        try {
          await fetchAlerts(true);
        } catch (error) {
          console.error('Error fetching initial alerts:', error);
        }
      };
    
      fetchInitialAlerts();
    }, [fetchAlerts]);

  const navigation = [
    { name: 'sidebar.dashboard', to: '/dashboard', icon: HomeIcon, disabled: false },
    {
      name: 'sidebar.tasks',
      to: '/tasks',
      icon: BriefcaseIcon,
      disabled: false,
      badge: newTasksCount > 0 ? `${newTasksCount}` : null,
    },
    { 
      name: 'sidebar.data-collection', 
      to: '/reports', 
      icon: ChartPieIcon, 
      disabled: false, 
      badge: isFounder ? 'Pro' : null 
    },
    {
      name: 'sidebar.alerts',
      to: '/alerts',
      icon: BellIcon,
      disabled: false,
      badge: alertsCount > 0 ? `${alertsCount}` : null,
      shouldAnimate: alertsShouldAnimate
    }  
  ];

  if (user?.entity_type === 'Company') {
    navigation.push({ name: 'sidebar.wizards', to: '/wizards', icon: Square3Stack3DIcon, disabled: false });
  }

  if (user?.entity_type === 'Portfolio') {
    navigation.push({ name: 'sidebar.portfolio', to: '/portfolio', icon: DocumentDuplicateIcon, disabled: false });
  }

  if (user?.role === 'admin' || user?.role === 'owner') {
    navigation.push({ name: 'sidebar.team', to: '/team', icon: UsersIcon, disabled: false });
  }

  navigation.push({ name: 'sidebar.settings', to: '/settings', icon: AdjustmentsHorizontalIcon, disabled: false });

  if (user?.role === 'owner') {
    navigation.push({
      name: 'sidebar.billing',
      to: '/billing',
      icon: CreditCardIcon,
      disabled: false,
    });
  }

  navigation.push({
    name: 'sidebar.logout',
    to: '#',
    icon: ArrowLeftOnRectangleIcon,
    action: () => setLogoutDialogOpen(true),
    noActive: true,
    disabled: false,
  });

  const handleLogout = useCallback(() => {
    logout();
    setLogoutDialogOpen(false);
    setSidebarOpen(false);
    navigate('/logout-success');
  }, [logout, navigate]);

  function UserInitials({ firstName, lastName }) {
    const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
    return (
      <div className="h-8 w-8 rounded-full bg-secondary-color flex items-center justify-center">
        <span className="text-white font-bold">{initials}</span>
      </div>
    );
  }

  function fixAvatarUrl(url) {
    if (!url) return url;

    // Define the malformed hosts and the corresponding correct hosts
    const replacements = [
      {
        malformed: 'dev.cenedril.net,%20dev.cenedril.net',
        correct: 'dev.cenedril.net',
      },
      {
        malformed: 'cenedril.net,%20cenedril.net',
        correct: 'cenedril.net',
      },
      {
        malformed: 'staging.cenedril.net,%20staging.cenedril.net',
        correct: 'staging.cenedril.net',
      },
    ];

    // Iterate over each replacement and fix the URL if needed
    for (const { malformed, correct } of replacements) {
      if (url.includes(malformed)) {
        return url.replace(malformed, correct);
      }
    }

    return url;
  }

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="fixed top-6 right-6 z-40 md:hidden">
        <button
          onClick={() => setSidebarOpen(true)}
          className="bg-primary-color text-white p-2 rounded-lg shadow-lg hover:bg-opacity-90 transition-colors"
          aria-label="Open menu"
        >
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>

      {/* Mobile Menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-color bg-opacity-50 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-primary-color p-6 text-left shadow-xl transition-all w-full max-w-sm">
  <div className="absolute top-0 right-0 pt-4 pr-4">
    <button
      type="button"
      className="text-white hover:text-gray-200"
      onClick={() => setSidebarOpen(false)}
    >
      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  </div>

  <div className="flex flex-col space-y-2">
    {/* Logo at the top */}
    <div className="flex justify-center mb-6 -mt-2">
      <img className="h-12 w-auto" src="/LogoOneColor_white.svg" alt="Cenedril Logo" />
    </div>

    {/* Navigation Items */}
    {navigation.map((item) => (
      <div key={item.name}>
        {item.disabled ? (
          <span className="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6 cursor-not-allowed">
            <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
            {t(item.name)}
          </span>
        ) : (
          <NavLink
            to={item.to}
            className={({ isActive }) =>
              classNames(
                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 relative',
                item.noActive
                  ? 'text-white hover:bg-secondary-color hover:bg-opacity-50'
                  : isActive
                  ? 'bg-secondary-color text-white'
                  : 'text-white hover:bg-secondary-color hover:bg-opacity-50'
              )
            }
            onClick={(e) => {
              if (item.action) {
                e.preventDefault();
                item.action();
              }
              setSidebarOpen(false);
            }}
          >
            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
            {t(item.name)}
            {item.badge && (
              <div className="absolute right-2 flex items-center">
                {shouldAnimate && (
                  <span className="absolute inline-flex h-full w-full rounded-full bg-secondary-color opacity-75 animate-ping" />
                )}
                <span className="relative bg-secondary-color text-white px-2 py-0.5 rounded-full text-xs">
                  {item.badge}
                </span>
              </div>
            )}
          </NavLink>
        )}
      </div>
    ))}
    
    {/* User Profile Section */}
    <div className="border-t border-white/10 pt-4 mt-4">
      <div className="flex items-center gap-x-3 p-2 pl-0">
        {user?.avatar_url && !imageError ? (
          <img
            className="h-8 w-8 rounded-full bg-secondary-color object-cover object-center"
            src={fixAvatarUrl(user.avatar_url)}
            alt={`${user.first_name} ${user.last_name}`}
            onError={() => setImageError(true)}
          />
        ) : (
          <UserInitials firstName={user?.first_name} lastName={user?.last_name} />
        )}
        <span className="text-sm font-semibold text-white">
          {user?.first_name} {user?.last_name}
        </span>
      </div>
    </div>
  </div>
</Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Logout Dialog */}
      <Transition.Root show={logoutDialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setLogoutDialogOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('logoutConfirmation')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('logoutConfirmationText')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleLogout}
                    >
                      {t('logoutConfirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setLogoutDialogOpen(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}