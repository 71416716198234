import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { getTranslatedValue } from '../../utils/templateTranslations';

// Register the different font weights
Font.register({
    family: 'Red Hat Display',
    fonts: [
        {
            src: '/fonts/RedHatDisplay-Regular.ttf',
            fontWeight: 400
        },
        {
            src: '/fonts/RedHatDisplay-Bold.ttf',
            fontWeight: 700
        },
        {
            src: '/fonts/RedHatDisplay-Italic.ttf',
            fontStyle: 'italic',
            fontWeight: 400
        }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
        paddingTop: 60,
        paddingBottom: 70, // Added extra padding at bottom for footer
        backgroundColor: '#ffffff',
        fontFamily: 'Red Hat Display',
        fontWeight: 400
    },
    title: {
        fontSize: 24,
        fontFamily: 'Red Hat Display',
        fontWeight: 700,
        marginBottom: 15,
        textAlign: 'center'
    },
    detailsContainer: {
        backgroundColor: '#f3f4f6',
        borderRadius: 8,
        padding: 15,
        marginBottom: 30
    },
    infoGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    infoColumn: {
        width: '50%',
        marginBottom: 10
    },
    infoLabel: {
        fontSize: 10,
        color: '#6b7280',
        marginBottom: 2
    },
    infoValue: {
        fontSize: 10,
        color: '#111827'
    },
    deletedInfoValue: {
        fontSize: 10,
        color: '#6b7280',
        fontStyle: 'italic'
    },
    userList: {
        marginTop: 2
    },
    mainContent: {
        flex: 1
    },
    section: {
        marginBottom: 20,
        padding: 10
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Red Hat Display',
        fontWeight: 700,
        marginBottom: 10
    },
    text: {
        fontSize: 12,
        marginBottom: 5
    },
    mainContent: {
        flex: 1,
        marginBottom: 60 // Add margin to ensure content doesn't overlap with footer
    },
    section: {
        marginBottom: 20,
        padding: 10
    },
    partSection: {
        marginTop: 15,
        borderBottom: 1,
        borderColor: '#e5e7eb',
        paddingBottom: 15
    },
    lastPartSection: {
        marginTop: 15,
        paddingBottom: 15,
        borderBottom: 0 // Remove border from last section
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 8,
        color: '#6b7280',
        gap: 10,
        backgroundColor: '#ffffff', // Add background to ensure footer is visible
        paddingTop: 10 // Add some padding at the top of footer
    },
    footerLogo: {
        width: 20,
        height: 20
    },
    footerContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerLeft: {
        flex: 1
    },
    footerRight: {
        textAlign: 'right'
    },
    radarSection: {
        marginBottom: 30,
        padding: 10,
        borderRadius: 8,
        backgroundColor: '#ffffff'
    },
    radarTitle: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 10
    },
    radarGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    radarChartContainer: {
        width: '100%',
        padding: 10
    },
    radarSummaryContainer: {
        width: '100%',
        padding: 10
    },
    summaryTitle: {
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 8,
        color: '#374151'
    },
    gridHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#e5e7eb',
        paddingVertical: 4
    },
    gridHeaderText: {
        fontSize: 10,
        color: '#6b7280',
        fontWeight: 500
    },
    gridRow: {
        flexDirection: 'row',
        paddingVertical: 4,
        borderBottomWidth: 0.5,
        borderColor: '#e5e7eb'
    },
    questionText: {
        flex: 1,
        fontSize: 10,
        color: '#111827'
    },
    valueText: {
        width: 50,
        fontSize: 10,
        textAlign: 'right'
    },
    valueTextGreen: {
        width: 50,
        fontSize: 10,
        textAlign: 'right',
        color: '#059669'
    },
    valueTextRed: {
        width: 50,
        fontSize: 10,
        textAlign: 'right',
        color: '#dc2626'
    },
    debaterCard: {
        marginTop: 10,
        padding: 8,
        borderRadius: 4
    },
    debaterCardGreen: {
        backgroundColor: '#ecfdf5'
    },
    debaterCardRed: {
        backgroundColor: '#fef2f2'
    }
});

const PartAssignmentsSection = ({ part, collection, translations }) => {
    if (!collection.part_assignments?.[part.id]) return null;

    if (collection.collection_type === 'collaborative') {
        const assignments = collection.part_assignments[part.id];
        const deletedUsers = collection.deleted_assignments?.[part.id] || {};
        const deletedEditors = deletedUsers.editors || [];
        const deletedSubmitters = deletedUsers.submitters || [];

        // Format editors list
        const formattedEditors = [
            ...(assignments.editors || []).map(userId => {
                const user = collection.respondents.find(r => r.id === userId);
                return user ? { name: user.user_name, isDeleted: false } : null;
            }).filter(Boolean),
            ...deletedEditors.map(editor => ({ name: editor.name, isDeleted: true }))
        ];

        // Format submitters list
        const formattedSubmitters = [
            ...(assignments.submitters || []).map(userId => {
                const user = collection.respondents.find(r => r.id === userId);
                return user ? { name: user.user_name, isDeleted: false } : null;
            }).filter(Boolean),
            ...deletedSubmitters.map(submitter => ({ name: submitter.name, isDeleted: true }))
        ];

        return (
            <View style={styles.assignmentSection}>
                <View>
                    <Text style={styles.infoLabel}>{translations.editors}</Text>
                    <Text style={styles.infoValue}>
                        {formattedEditors.map((editor, index) => (
                            `${editor.name}${editor.isDeleted ? ` (${translations.deleted})` : ''}${index < formattedEditors.length - 1 ? ', ' : ''
                            }`
                        ))}
                    </Text>
                </View>
                <View style={{ marginTop: 5 }}>
                    <Text style={styles.infoLabel}>{translations.submitters}</Text>
                    <Text style={styles.infoValue}>
                        {formattedSubmitters.map((submitter, index) => (
                            `${submitter.name}${submitter.isDeleted ? ` (${translations.deleted})` : ''}${index < formattedSubmitters.length - 1 ? ', ' : ''
                            }`
                        ))}
                    </Text>
                </View>
            </View>
        );
    }

    // For individual collections
    const assignments = collection.part_assignments[part.id];
    const deletedAssignments = collection.deleted_assignments?.[part.id]?.assignees || [];

    const formattedAssignees = [
        ...(assignments.assignees || []).map(userId => {
            const user = collection.respondents.find(r => r.id === userId);
            return user ? { name: user.user_name, isDeleted: false } : null;
        }).filter(Boolean),
        ...deletedAssignments.map(user => ({ name: user.name, isDeleted: true }))
    ];

    return (
        <View style={styles.assignmentSection}>
            <Text style={styles.infoLabel}>{translations.assignees}</Text>
            <Text style={styles.infoValue}>
                {formattedAssignees.map((assignee, index) => (
                    `${assignee.name}${assignee.isDeleted ? ` (${translations.deleted})` : ''}${index < formattedAssignees.length - 1 ? ', ' : ''
                    }`
                ))}
            </Text>
        </View>
    );
};

// Update getAllUsers to use translations
const getAllUsers = (collection, translations) => {
    if (collection.collection_type === 'collaborative') {
        const activeUsers = new Set();
        Object.values(collection.part_assignments || {}).forEach(assignment => {
            (assignment.editors || []).forEach(editor => activeUsers.add(editor));
            (assignment.submitters || []).forEach(submitter => activeUsers.add(submitter));
        });

        const activeUsersList = Array.from(activeUsers).map(userId => {
            const user = collection.respondents.find(r => r.id === userId);
            return user ? {
                id: userId,
                user_name: user.user_name,
                was_deleted: false
            } : null;
        }).filter(Boolean);

        const deletedUsers = [];
        Object.values(collection.deleted_assignments || {}).forEach(assignments => {
            ['editors', 'submitters'].forEach(role => {
                (assignments?.[role] || []).forEach(user => {
                    if (!deletedUsers.some(u => u.id === user.id)) {
                        deletedUsers.push({
                            id: user.id,
                            user_name: `${user.name} (${translations.deleted})`,
                            was_deleted: true
                        });
                    }
                });
            });
        });

        return [...activeUsersList, ...deletedUsers];
    }

    // For individual collections
    const activeUsers = collection.respondents.map(user => ({
        id: user.id,
        user_name: user.user_name,
        was_deleted: false
    }));

    const deletedUsers = Object.values(collection.deleted_assignments || {})
        .flatMap(assignments => assignments.assignees || [])
        .map(user => ({
            id: user.id,
            user_name: `${user.name} (${translations.deleted})`,
            was_deleted: true
        }));

    return [...activeUsers, ...deletedUsers];
};

const RadarChartSection = ({ question, chartImage, data, translations, userLang }) => {

    return (
        <View style={styles.radarSection}>
            <Text style={styles.radarTitle}>
                {getTranslatedValue(question.comparison_group_name, userLang)}
            </Text>

            <View style={styles.radarGrid}>
                {/* Chart Image */}
                {chartImage && (
                    <View style={styles.radarChartContainer}>
                        <Image 
                            src={chartImage} 
                            style={{ width: '100%', height: 'auto' }}
                            />
                    </View>
                )}

                {/* Summary Section */}
                <View style={styles.radarSummaryContainer}>
                    <Text style={styles.summaryTitle}>{translations.biggestTakeaways}</Text>

                    {/* Top 5 */}
                    <View>
                        <View style={styles.gridHeader}>
                            <Text style={[styles.gridHeaderText, { flex: 1 }]}>{translations.top5}</Text>
                            <Text style={[styles.gridHeaderText, { width: 50 }]}>{translations.current}</Text>
                        </View>
                        {data.questionAverages.slice(0, 5).map((item, index) => (
                            <View key={index} style={styles.gridRow}>
                                <Text style={styles.questionText}>{item.text}</Text>
                                <Text style={styles.valueTextGreen}>
                                    {item.average.toFixed(2)}
                                </Text>
                            </View>
                        ))}
                    </View>

                    {/* Bottom 5 */}
                    <View style={{ marginTop: 15 }}>
                        <View style={styles.gridHeader}>
                            <Text style={[styles.gridHeaderText, { flex: 1 }]}>{translations.bottom5}</Text>
                            <Text style={[styles.gridHeaderText, { width: 50 }]}>{translations.current}</Text>
                        </View>
                        {data.questionAverages.slice(-5).reverse().map((item, index) => (
                            <View key={index} style={styles.gridRow}>
                                <Text style={styles.questionText}>{item.text}</Text>
                                <Text style={styles.valueTextRed}>
                                    {item.average.toFixed(2)}
                                </Text>
                            </View>
                        ))}
                    </View>

                    {/* Top Debaters */}
                    {data.userStats?.length > 0 && !data.collection.is_anonymous && (
                        <View style={{ marginTop: 20 }}>
                            <Text style={styles.summaryTitle}>{translations.topDebaters}</Text>

                            {/* Highest Scorer */}
                            <View style={[styles.debaterCard, styles.debaterCardGreen]}>
                                <Text style={[styles.questionText, { color: '#059669' }]}>
                                    {data.userStats[0].name}
                                </Text>
                                <Text style={[styles.questionText, { color: '#059669' }]}>
                                    {translations.average}: {data.userStats[0].average.toFixed(2)}
                                    {' '}({data.userStats[0].maxScores} {translations.maxScores})
                                </Text>
                            </View>

                            {/* Lowest Scorer */}
                            <View style={[styles.debaterCard, styles.debaterCardRed]}>
                                <Text style={[styles.questionText, { color: '#dc2626' }]}>
                                    {data.userStats[data.userStats.length - 1].name}
                                </Text>
                                <Text style={[styles.questionText, { color: '#dc2626' }]}>
                                    {translations.average}: {data.userStats[data.userStats.length - 1].average.toFixed(2)}
                                    {' '}({data.userStats[data.userStats.length - 1].minScores} {translations.minScores})
                                </Text>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

// Helper function to format user lists
const formatUserList = (users = [], collection) => {
    if (!users.length) return '';
    return users
        .map(userId => {
            const user = collection.respondents.find(r => r.id === userId);
            return user ? user.user_name : '';
        })
        .filter(Boolean)
        .join(', ');
};

// Main PDF Document Component
const ReportPDF = ({ 
    collection, 
    template, 
    userData, 
    comparisonData, 
    translations, 
    capturedImages,
    comparisonGroupQuestions,
    userLang
}) => {

    const allUsers = getAllUsers(collection);

    const formatDateWithTime = (date = new Date()) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        return `${day}.${month}.${year}, ${hours}:${minutes}`;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString();
    };

    const companyName = userData?.entity?.name || '';

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Title */}
                <Text style={styles.title}>
                    {console.log('template name:', template.name)}
                    {console.log('translated value:', getTranslatedValue(template.name, userLang))}
                    {getTranslatedValue(template.name, userLang)}
                </Text>

                {/* Details Container */}
                <View style={styles.detailsContainer}>
                    <View style={styles.infoGrid}>
                        <View style={styles.infoColumn}>
                            <Text style={styles.infoLabel}>{translations.collectionPeriod}</Text>
                            <Text style={styles.infoValue}>{collection.period_value}</Text>
                        </View>
                        <View style={styles.infoColumn}>
                            <Text style={styles.infoLabel}>{translations.started}</Text>
                            <Text style={styles.infoValue}>
                                {formatDate(collection.starts_at)}
                            </Text>
                        </View>
                        <View style={styles.infoColumn}>
                            <Text style={styles.infoLabel}>{translations.reportViewers}</Text>
                            {collection.viewers.map((viewer) => (
                                <Text key={viewer.id} style={styles.infoValue}>
                                    {viewer.user_name}
                                </Text>
                            ))}
                        </View>
                        <View style={styles.infoColumn}>
                            <Text style={styles.infoLabel}>{translations.dataSources}</Text>
                            <View style={styles.userList}>
                                {allUsers.map((user) => (
                                    <Text
                                        key={user.id}
                                        style={user.was_deleted ? styles.deletedInfoValue : styles.infoValue}
                                    >
                                        {user.user_name}{user.was_deleted ? ` (${translations.deleted})` : ''}
                                    </Text>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>

                {/* Main Content */}
                <View style={styles.mainContent}>
                    {/* Integrated Analysis Section */}
                    <View style={styles.section}>
                        <Text style={styles.subtitle}>{translations.integratedAnalysis}</Text>

                        {/* Radar Charts */}
                        {comparisonGroupQuestions
                            .filter(question =>
                                question.analysis_types?.includes('comparison_external_as_radar_chart')
                            )
                            .map((question, index) => (
                                <RadarChartSection
                                    key={`radar-${question.id}`}
                                    question={question}
                                    chartImage={capturedImages[`radar-chart-${question.id}`]}
                                    data={{
                                        questionAverages: [],
                                        userStats: [],
                                        collection
                                    }}
                                    translations={translations}
                                    userLang={userLang}  // Add this
                                />
                            ))}
                    </View>

                    {/* Segmented Analysis Section */}
                    <View style={styles.section}>
                        <Text style={styles.subtitle}>{translations.segmentedAnalysis}</Text>
                        {template.parts.map((part, index) => (
                            <View key={part.id} style={styles.partSection}>
                                <Text style={styles.subtitle}>
                                    {getTranslatedValue(part.name, userLang)}
                                </Text>

                                <PartAssignmentsSection
                                    part={part}
                                    collection={collection}
                                    translations={translations}
                                />
                            </View>
                        ))}
                    </View>
                </View>

                {/* Footer */}
                <View fixed style={styles.footer}>
                    <Image src="/logo192.png" style={styles.footerLogo} />
                    <View style={styles.footerContent}>
                        <Text style={styles.footerLeft}>
                            {translations.exportedFrom} {formatDateWithTime()} {translations.by} {userData.first_name} {userData.last_name} ({companyName})
                        </Text>
                        <Text style={styles.footerRight} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default ReportPDF;