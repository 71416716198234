// src/components/wizard_components/DataPrivacyWizard/DPIARiskTreatment.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useUserData } from '../../../hooks/useUserData';

// Import risk treatment subcomponents
import RecommendedControls from './RecommendedControls';
import AdditionalControls from './AdditionalControls';
import AppliedControls from './AppliedControls';
import ControlDetailsModal from './ControlDetailsModal';

// Define risk control map directly here for simplicity
const riskControlMap = {
    // Unauthorized Access
    'unauthorized_access': [
        'RC515', 'RC516', 'RC517', 'RC518', 'RC805', 'RC802'
    ],
    // Data Breach
    'data_breach': [
        'RC524', 'RC525', 'RC526', 'RC527', 'RC528', 'RC824'
    ],
    // Excessive Collection
    'excessive_collection': [
        'RC534', 'RC509', 'RC512', 'RC513'
    ],
    // Unauthorized Sharing
    'unauthorized_sharing': [
        'RC514', 'RC513', 'RC606', 'RC812'
    ],
    // Data Loss
    'data_loss': [
        'RC813', 'RC814', 'RC710', 'RC711'
    ],
    // Inaccurate Processing
    'inaccurate_processing': [
        'RC509', 'RC537', 'RC809', 'RC832'
    ],
    // Rights Hindrance
    'rights_hindrance': [
        'RC534', 'RC531', 'RC536', 'RC533'
    ],
    // Beyond Purpose
    'beyond_purpose': [
        'RC534', 'RC510', 'RC512', 'RC536'
    ],
    // Improper Legal Basis
    'improper_legal_basis': [
        'RC531', 'RC534', 'RC536', 'RC606'
    ],
    // Excessive Retention
    'excessive_retention': [
        'RC810', 'RC811', 'RC533', 'RC710'
    ]
};

// Function to determine risk level
const calculateRiskLevel = (likelihood, severity) => {
    const riskScore = likelihood * severity;

    if (riskScore >= 15) return { level: 'high', color: 'bg-red-100 text-red-800' };
    if (riskScore >= 8) return { level: 'medium', color: 'bg-yellow-100 text-yellow-800' };
    return { level: 'low', color: 'bg-green-100 text-green-800' };
};

const DPIARiskTreatment = ({ formik, toggleDrawer, riskControls = [], processingActivity }) => {
    const { t } = useTranslation();
    const { userData } = useUserData();
    const [controlModalOpen, setControlModalOpen] = useState(false);
    const [selectedControlId, setSelectedControlId] = useState(null);
    const [appliedControls, setAppliedControls] = useState([]);
    const [recommendedControls, setRecommendedControls] = useState([]);
    const [additionalControls, setAdditionalControls] = useState([]);
    const [treatmentStarted, setTreatmentStarted] = useState(formik.values.treatmentStarted || false);
    const [appliedControlsLoaded, setAppliedControlsLoaded] = useState(false);
    
    // Track expanded sections independently with a set of flags
    const [expandedSections, setExpandedSections] = useState({
        applied: true,     // Default: first section open
        recommended: false,
        additional: false
    });

    // Get user language
    const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';

    // When applied controls are loaded from the AppliedControls component, 
    // store them and if this is the initial load during treatment start, select them all
    const handleAppliedControlsChange = (controls) => {
        console.log('Applied controls loaded:', controls.map(c => c.id));
        setAppliedControls(controls);
        setAppliedControlsLoaded(true);
        
        // If treatment just started and we haven't initialized selections yet,
        // select all applied controls
        if (treatmentStarted && 
            (!formik.values.selectedControls || formik.values.selectedControls.length === 0)) {
            console.log('Initializing selections with applied controls');
            const appliedControlIds = controls.map(control => control.id);
            formik.setFieldValue('selectedControls', appliedControlIds);
        }
        
        // Also update the additional controls list
        if (recommendedControls.length > 0) {
            updateAdditionalControls(controls, recommendedControls);
        }
    };

    // Function to update additional controls
    const updateAdditionalControls = (appliedControls, recommendedControlObjects) => {
        // Get IDs of controls shown in other sections
        const appliedControlIds = appliedControls.map(control => control.id);
        const recommendedControlIds = recommendedControlObjects.map(control => control.id);
        const shownControlIds = [...appliedControlIds, ...recommendedControlIds];

        // Filter remaining controls:
        // 1. Must not be already shown in applied/recommended
        // 2. Must start with "RC" (not "PC")
        const remaining = riskControls.filter(control =>
            !shownControlIds.includes(control.id) &&
            control.id.startsWith('RC')
        );

        setAdditionalControls(remaining);
    };

    // Generate recommendations directly based on selected risks
    const generateRecommendations = () => {
        if (!formik.values.selectedRisks || !riskControls || riskControls.length === 0) {
            return [];
        }

        // Get the processor type
        const processorType = processingActivity?.processingRole || '';

        // Create a set to keep unique control IDs from all selected risk scenarios
        const recommendedControlIds = new Set();

        // Add controls based on selected risk scenarios
        formik.values.selectedRisks.forEach(risk => {
            // Get mapped controls for this risk scenario
            const mappedControls = riskControlMap[risk.id] || [];

            // Add each control to the set
            mappedControls.forEach(controlId => {
                recommendedControlIds.add(controlId);
            });
        });

        // Add special PC controls based on processor type
        if (processorType !== 'Controller' && processorType !== 'Co-Controller') {
            recommendedControlIds.add('PC7409');
        }

        if (processorType !== 'Processor' && processorType !== 'Co-Processor') {
            recommendedControlIds.add('PC8403');
        }

        // Return the array of recommended control IDs
        return Array.from(recommendedControlIds);
    };

    // Handle recommendations update - this function handles the UI state updates
    const handleRecommendationsUpdate = (recommendedControlIds) => {
        // Find the actual control objects from the riskControls array
        const recommendedControlObjects = recommendedControlIds
            .map(id => riskControls.find(control => control.id === id))
            .filter(Boolean); // Remove any undefined values

        // Skip controls already applied through the wizard
        const appliedControlIds = appliedControls.map(control => control.id);
        const filteredRecommendations = recommendedControlObjects.filter(control =>
            !appliedControlIds.includes(control.id)
        );

        // Save recommendations to state and formik
        setRecommendedControls(filteredRecommendations);
        formik.setFieldValue('recommendedControls', recommendedControlIds);

        // Update additional controls based on applied and recommended controls
        updateAdditionalControls(appliedControls, filteredRecommendations);
    };

    // Toggle selection of a control
    const handleToggleControlSelection = (controlId) => {
        console.log('Toggling selection for control:', controlId);
        const updatedSelectedControls = [...(formik.values.selectedControls || [])];

        if (updatedSelectedControls.includes(controlId)) {
            // Remove control
            const index = updatedSelectedControls.indexOf(controlId);
            updatedSelectedControls.splice(index, 1);
        } else {
            // Add control
            updatedSelectedControls.push(controlId);
        }

        console.log('Updated selected controls:', updatedSelectedControls);
        formik.setFieldValue('selectedControls', updatedSelectedControls);
    };

    // Toggle a section independently without affecting other sections
    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };

    // Handle opening the control details modal
    const handleOpenControlDetails = (controlId) => {
        setSelectedControlId(controlId);
        setControlModalOpen(true);
    };

    // Check if there are any risks selected
    const hasSelectedRisks = formik.values.selectedRisks && formik.values.selectedRisks.length > 0;

    // Start risk treatment function - directly implements all the logic
    const startRiskTreatment = () => {
        if (!hasSelectedRisks) return;

        // Mark treatment as started
        setTreatmentStarted(true);
        formik.setFieldValue('treatmentStarted', true);
        
        // Initialize selectedControls with empty array if it doesn't exist yet
        if (!formik.values.selectedControls) {
            formik.setFieldValue('selectedControls', []);
        }

        // Generate recommendations directly
        const recommendedControlIds = generateRecommendations();

        // Update UI with recommendations
        handleRecommendationsUpdate(recommendedControlIds);
        
        // The applied controls will be selected when the AppliedControls component loads
        // through the handleAppliedControlsChange callback
    };

    // Apply new recommendations whenever applied controls change or on initial render
    useEffect(() => {
        if (treatmentStarted) {
            const recommendations = generateRecommendations();
            handleRecommendationsUpdate(recommendations);
        }
    }, [treatmentStarted]);

    return (
        <div>
            {/* Start Risk Treatment Button */}
            {!treatmentStarted ? (
                <div className="mb-6">
                    <button
                        type="button"
                        onClick={startRiskTreatment}
                        disabled={!hasSelectedRisks}
                        className={`w-full py-3 px-4 flex justify-center items-center gap-2 text-white text-sm font-medium rounded-md shadow-sm
                            ${hasSelectedRisks
                                ? 'bg-secondary-color hover:opacity-90 focus:ring-green-500'
                                : 'bg-gray-300 cursor-not-allowed'
                            }`}
                    >
                        {hasSelectedRisks
                            ? t('DataPrivacyWizard.dpia.riskTreatment.startRiskTreatment')
                            : t('DataPrivacyWizard.dpia.riskTreatment.noRisksSelectedYet')}
                    </button>
                </div>
            ) : (
                <>
                    {/* Controls Applied Through This Wizard - Collapsible */}
                    <div className="mb-6 border border-gray-200 rounded-lg overflow-hidden">
                        <div className="bg-gray-50 px-4 py-3 flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSection('applied')}>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.appliedControls')}
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.appliedControlsDescription',
                                        'These are risk controls that are applied by default, as they are implemented by working with this wizard')}
                                </p>
                            </div>
                            <div>
                                {expandedSections.applied ?
                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" /> :
                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                            </div>
                        </div>
                        {expandedSections.applied && (
                            <div className="p-4">
                                <AppliedControls
                                    riskControls={riskControls}
                                    processingActivity={processingActivity}
                                    onViewDetails={handleOpenControlDetails}
                                    onAppliedControlsChange={handleAppliedControlsChange}
                                    selectedControls={formik.values.selectedControls || []}
                                    onToggleSelection={handleToggleControlSelection}
                                />
                            </div>
                        )}
                    </div>

                    {/* Recommended Controls - Collapsible */}
                    <div className="mb-6 border border-gray-200 rounded-lg overflow-hidden">
                        <div className="bg-gray-50 px-4 py-3 flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSection('recommended')}>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.recommendedControls')}
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.recommendedControlsDescription',
                                        'These are risk controls that are recommended based on the types of risk scenarios you described')}
                                </p>
                            </div>
                            <div>
                                {expandedSections.recommended ?
                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" /> :
                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                            </div>
                        </div>
                        {expandedSections.recommended && (
                            <div className="p-4">
                                <RecommendedControls
                                    recommendedControls={recommendedControls}
                                    selectedControls={formik.values.selectedControls || []}
                                    onToggleSelection={handleToggleControlSelection}
                                    onViewDetails={handleOpenControlDetails}
                                    riskControls={riskControls}
                                    selectedRisks={formik.values.selectedRisks || []}
                                    processingActivity={processingActivity}
                                    onGenerateRecommendations={handleRecommendationsUpdate}
                                />
                            </div>
                        )}
                    </div>

                    {/* Additional Controls - Collapsible */}
                    <div className="mb-6 border border-gray-200 rounded-lg overflow-hidden">
                        <div className="bg-gray-50 px-4 py-3 flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSection('additional')}>
                            <div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.additionalControls')}
                                </h3>
                                <p className="text-sm text-gray-500">
                                    {t('DataPrivacyWizard.dpia.riskTreatment.additionalControlsDescription',
                                        'These are cybersecurity risk controls that can be added for additional security')}
                                </p>
                            </div>
                            <div>
                                {expandedSections.additional ?
                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" /> :
                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />}
                            </div>
                        </div>
                        {expandedSections.additional && (
                            <div className="p-4">
                                <AdditionalControls
                                    additionalControls={additionalControls}
                                    selectedControls={formik.values.selectedControls || []}
                                    onToggleSelection={handleToggleControlSelection}
                                    onViewDetails={handleOpenControlDetails}
                                    riskControls={riskControls}
                                    userLang={userLang}
                                />
                            </div>
                        )}
                    </div>

                    {/* Control Details Modal */}
                    <ControlDetailsModal
                        isOpen={controlModalOpen}
                        onClose={() => setControlModalOpen(false)}
                        controlId={selectedControlId}
                        control={riskControls.find(control => control.id === selectedControlId)}
                        isSelected={formik.values.selectedControls?.includes(selectedControlId)}
                        onToggleSelection={handleToggleControlSelection}
                    />
                </>
            )}
        </div>
    );
};

export default DPIARiskTreatment;