import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initI18n } from './i18n';
import Glossary from './Glossary';
import { TaskProvider } from './context/TaskContext';
import { AlertsProvider } from './context/AlertsContext';
import { usePreventEnterSubmission } from './hooks/usePreventEnterSubmission';

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
);

const AppWrapper = () => {
  usePreventEnterSubmission();

  return (
    <Glossary>
      <AlertsProvider>
        <TaskProvider>
          <Suspense fallback={<LoadingFallback />}>
            <App />
          </Suspense>
        </TaskProvider>
      </AlertsProvider>
    </Glossary>
  );
};

// Initialize i18n and render the app
initI18n().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<AppWrapper />); // Render the wrapper instead of the direct composition
});