import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';

const germanStates = [
  'Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen',
  'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz',
  'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen'
];

const StartupWizardCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizardName, updateWizardData, liveMode, toggleLiveMode } = useWizard();
  const [notification, setNotification] = useState({
    show: false,
    type: '',
    message: '',
    errors: [],
    errorNames: ''
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [answers, setAnswers] = useState({});
  const [rawShareCapital, setRawShareCapital] = useState('');
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const formatShareCapital = (value) => {
    if (!value || isNaN(value)) return ''; // Return empty if value is invalid
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
  };

  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'company');

  // Update the answers when data is loaded
  useEffect(() => {
    if (data) {
      setAnswers(data);
      if (data.shareCapital) {
        setRawShareCapital(String(data.shareCapital).replace(/[^0-9]/g, ''));
      }
    }
  }, [data]);

  // Outside the Formik context
  useEffect(() => {
  }, [answers]);  // This will watch the answers and trigger when answers change

  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {

    if (liveMode) {
      const result = await saveAsDraft(wizardName, 'company', answers, true);
      if (result.success) {
        updateWizardData('company', answers, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    } else {
    }
  }, [liveMode, answers, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = React.useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);


  // Trigger auto-save when liveMode and answers change
  useEffect(() => {

    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => {
        debouncedAutoSaveRef.current.cancel();
      };
    } else {
    }
  }, [liveMode, answers]);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required(t('Company name is required'))
      .min(2, t('Company name must be at least 2 characters')),
    street: Yup.string()
      .required(t('Street is required')),
    streetAdditional: Yup.string()
      .optional(),
    zip: Yup.string()
      .required(t('ZIP code is required'))
      .matches(/^\d+$/, t('ZIP code must contain only numbers'))
      .length(5, t('ZIP code must be exactly 5 digits')),
    place: Yup.string()
      .required(t('Place is required')),
    state: Yup.string()
      .required(t('State is required'))
      .oneOf(germanStates, t('Please select a valid state')),
    country: Yup.string()
      .required()
      .default('Deutschland'),
    shareCapital: Yup.number()
      .transform((value, originalValue) => {
        return Number(rawShareCapital);
      })
      .required(t('Share capital is required'))
      .min(
        answers.legalForm === 'UG' ? 1 :
          answers.legalForm === 'GmbH' ? 25000 :
            answers.legalForm === 'AG' ? 50000 : 1,
        t(`Minimum share capital for ${answers.legalForm} is ${answers.legalForm === 'UG' ? '1' :
          answers.legalForm === 'GmbH' ? '25,000' :
            answers.legalForm === 'AG' ? '50,000' : '1'
          } EUR`)
      )
  });

  const formatValidationErrors = (errors) => {
    return Object.values(errors);
  };

  const handleValidationErrors = (errors) => {
    const formattedErrors = formatValidationErrors(errors);
    setNotification({
      show: true,
      type: 'error',
      message: t('Please correct the following errors:'),
      errors: formattedErrors
    });
  };

  // Simplify handleSubmit to only handle saving
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSave = {
        ...values,
        shareCapital: Number(rawShareCapital),
        country: 'Deutschland',
      };

      const result = await saveData(wizardName, 'company', dataToSave);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('Data saved and published successfully')
        });
        updateWizardData('company', dataToSave, false);
        setTimeout(() => navigate('/startup-wizard/founders'), 1000);
      }
    } catch (error) {
      console.error('Save error:', error);
      setNotification({
        show: true,
        type: 'error',
        message: t('Error saving data')
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Draft submit - no validation needed
  const handleDraftSubmit = async (values, { setSubmitting }) => {
    try {
      const dataToSave = {
        ...values,
        shareCapital: Number(rawShareCapital),
        country: 'Germany',
      };
      const result = await saveAsDraft(wizardName, 'company', dataToSave, false);
      if (result.success) {
        setNotification({
          show: true,
          type: 'success',
          message: t('Draft saved successfully')
        });
        updateWizardData('company', dataToSave, true);
      }
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: t('Error saving draft')
      });
    } finally {
      setSubmitting(false);
    }
  };

  const namingLawsContent = `
  <p>${t('namingLaws.intro')}</p>
  <h4>${t('namingLaws.legalAvailability.title')}</h4>
  <ul>
    <li><strong>${t('namingLaws.legalAvailability.uniqueness.title')}</strong> ${t('namingLaws.legalAvailability.uniqueness.content')}</li>
    <li><strong>${t('namingLaws.legalAvailability.tradeRegister.title')}</strong> ${t('namingLaws.legalAvailability.tradeRegister.content')}</li>
    <li><strong>${t('namingLaws.legalAvailability.trademarkSearch.title')}</strong> ${t('namingLaws.legalAvailability.trademarkSearch.content')}</li>
  </ul>
  <h4>${t('namingLaws.compliance.title')}</h4>
  <ul>
    <li><strong>${t('namingLaws.compliance.companyType.title')}</strong> ${t('namingLaws.compliance.companyType.content')}</li>
    <li><strong>${t('namingLaws.compliance.truthfulness.title')}</strong> ${t('namingLaws.compliance.truthfulness.content')}</li>
    <li><strong>${t('namingLaws.compliance.protectedTerms.title')}</strong> ${t('namingLaws.compliance.protectedTerms.content')}</li>
  </ul>
  <h4>${t('namingLaws.distinctiveness.title')}</h4>
  <ul>
    <li><strong>${t('namingLaws.distinctiveness.descriptiveTerms.title')}</strong> ${t('namingLaws.distinctiveness.descriptiveTerms.content')}</li>
    <li><strong>${t('namingLaws.distinctiveness.branding.title')}</strong> ${t('namingLaws.distinctiveness.branding.content')}</li>
    <li><strong>${t('namingLaws.distinctiveness.international.title')}</strong> ${t('namingLaws.distinctiveness.international.content')}</li>
  </ul>
  <h4>${t('namingLaws.approvalProcess.title')}</h4>
  <ul>
    <li><strong>${t('namingLaws.approvalProcess.chamberApproval.title')}</strong> ${t('namingLaws.approvalProcess.chamberApproval.content')}</li>
    <li><strong>${t('namingLaws.approvalProcess.registration.title')}</strong> ${t('namingLaws.approvalProcess.registration.content')}</li>
  </ul>
  <h4>${t('namingLaws.ongoingCompliance.title')}</h4>
  <ul>
    <li><strong>${t('namingLaws.ongoingCompliance.updates.title')}</strong> ${t('namingLaws.ongoingCompliance.updates.content')}</li>
    <li><strong>${t('namingLaws.ongoingCompliance.nameProtection.title')}</strong> ${t('namingLaws.ongoingCompliance.nameProtection.content')}</li>
  </ul>
`;

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />

      <DataStatusBanner status={dataStatus} />

      <div className="mt-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
          {t('Company Details')}
        </p>
      </div>

      <Formik
        initialValues={{
          ...data || {
            companyName: '',
            street: '',
            streetAdditional: '',
            zip: '',
            place: '',
            state: '',
            country: 'Deutschland',
            shareCapital: '',
          },
          // Override shareCapital specifically to ensure proper number format
          shareCapital: data?.shareCapital ? String(data.shareCapital).replace(/[^0-9]/g, '') : ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, submitForm }) => {
          return (
            <Form className="mt-8 space-y-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">{t('Company Name')}</h3>
                <p className="mb-2 flex items-center">
                  {t('When naming your business, ensure that the name is legally available, complies with local laws, and is distinctive and memorable.')}
                  <button
                    type="button"
                    onClick={() => setDrawerOpen(true)}
                    className="ml-2 text-gray-400 hover:text-gray-500"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </p>
                <Field
                  name="companyName"
                  type="text"
                  placeholder={t('Enter company name')}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                  onChange={(e) => {
                    handleChange(e);
                    setAnswers(prev => ({ ...prev, companyName: e.target.value }));
                  }}
                />
                {hasAttemptedSubmit && errors.companyName && (
                  <div className="text-red-500 mt-1">{errors.companyName}</div>
                )}
              </div>

              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">{t('Registered Address')}</h3>
                <p className="mb-2">{t('This is where official correspondence from government agencies, tax authorities, and legal notices will be sent and where usually your shareholder meetings take place.')}</p>
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                      {t('Street')}
                    </label>
                    <Field
                      name="street"
                      type="text"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                      onChange={(e) => {
                        handleChange(e);
                        setAnswers(prev => ({ ...prev, street: e.target.value }));
                      }}
                    />
                    {hasAttemptedSubmit && errors.street && (
                      <div className="text-red-500 mt-1">{errors.street}</div>
                    )}
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="streetAdditional" className="block text-sm font-medium text-gray-700">
                      {t('Additional')}
                    </label>
                    <Field
                      name="streetAdditional"
                      type="text"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                      onChange={(e) => {
                        handleChange(e);
                        setAnswers(prev => ({ ...prev, streetAdditional: e.target.value }));
                      }}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                      {t('ZIP')}
                    </label>
                    <Field
                      name="zip"
                      type="text"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                      onChange={(e) => {
                        handleChange(e);
                        setAnswers(prev => ({ ...prev, zip: e.target.value }));
                      }}
                    />
                    {hasAttemptedSubmit && errors.zip && (
                      <div className="text-red-500 mt-1">{errors.zip}</div>
                    )}
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="place" className="block text-sm font-medium text-gray-700">
                      {t('Place')}
                    </label>
                    <Field
                      name="place"
                      type="text"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                      onChange={(e) => {
                        handleChange(e);
                        setAnswers(prev => ({ ...prev, place: e.target.value }));
                      }}
                    />
                    {hasAttemptedSubmit && errors.place && (
                      <div className="text-red-500 mt-1">{errors.place}</div>
                    )}
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                      {t('State')}
                    </label>
                    <Field
                      as="select"
                      name="state"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                      onChange={(e) => {
                        handleChange(e);
                        setAnswers(prev => ({ ...prev, state: e.target.value }));
                      }}
                    >
                      <option value="">{t('Select a state')}</option>
                      {germanStates.map(state => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </Field>
                    {hasAttemptedSubmit && errors.state && (
                      <div className="text-red-500 mt-1">{errors.state}</div>
                    )}
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                      {t('Country')}
                    </label>
                    <Field
                      name="country"
                      type="text"
                      disabled={true}
                      value="Deutschland"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-100 cursor-not-allowed"
                      title={t('Country is fixed to Germany')}
                    />
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">{t('Initial Share Capital')}</h3>
                <p className="mb-2">{t('Is the money paid in through the issuance of shares to founders, forming the company\'s initial equity at its inception.')}</p>
                <div className="flex">
                  <span className="mt-1 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-4 font-medium text-gray-500">
                    EUR
                  </span>
                  <Field
                    name="shareCapital"
                    type="text"
                    className="mt-1 block w-full rounded-none rounded-r-md border-gray-300 shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                    value={formatShareCapital(values.shareCapital)}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, '');
                      setFieldValue('shareCapital', numericValue);
                      setRawShareCapital(numericValue);
                      setAnswers(prev => ({ ...prev, shareCapital: numericValue }));
                    }}
                  />
                </div>
                {hasAttemptedSubmit && errors.shareCapital && (
                  <div className="text-red-500 mt-1">{errors.shareCapital}</div>
                )}
              </div>

              <ActionButtons
                onCancel={() => navigate('/startup-wizard')}
                onDraft={() => handleDraftSubmit(values, { setSubmitting: () => { } })}
                onSubmit={() => {
                  setHasAttemptedSubmit(true);

                  // Check for validation errors
                  if (Object.keys(errors).length > 0) {
                    handleValidationErrors(errors);
                    return;
                  }

                  submitForm();
                }}
                isSubmitting={isSubmitting}
                currentPage="company"
              />
            </Form>
          );
        }}
      </Formik>

      <WizardDrawer
        isOpen={drawerOpen}
        title="Business Naming Laws in Germany"
        content={namingLawsContent}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export default StartupWizardCompany;