import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MatomoTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Determine Matomo URL based on current domain
    const currentDomain = window.location.hostname;
    let matomoUrl;
    
    if (currentDomain.startsWith('dev.')) {
      matomoUrl = 'https://matomo.dev.cenedril.net/';
    } else if (currentDomain.startsWith('staging.')) {
      matomoUrl = 'https://matomo-staging.cenedril.net/';
    } else {
      matomoUrl = 'https://matomo.cenedril.net/';
    }
    
    // Initialize Matomo tracking
    window._paq = window._paq || [];
    const _paq = window._paq;

    // Remove any existing Matomo script
    const existingScript = document.getElementById('matomo-script');
    if (existingScript) {
      existingScript.remove();
    }

    // Set custom URL if available
    if (location.pathname) {
      _paq.push(['setCustomUrl', location.pathname + location.search]);
    }

    // Track page view
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    // Initialize Matomo tracker
    (function() {
      _paq.push(['setTrackerUrl', matomoUrl + 'matomo.php']);
      _paq.push(['setSiteId', '1']);

      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];

      g.async = true;
      g.src = matomoUrl + 'matomo.js';
      g.id = 'matomo-script';
      
      if (s && s.parentNode) {
        s.parentNode.insertBefore(g, s);
      }
    })();
  }, [location]); // Re-run when location changes

  return null; // This component doesn't render anything
};

export default MatomoTracker;