import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ValuationChart = ({ cashflowData = [], fundingRounds = [], currency = 'USD' }) => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const [chartSeries, setChartSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [annotations, setAnnotations] = useState([]);
    const [avgGrowthRate, setAvgGrowthRate] = useState(0);
    const [avgProfitMargin, setAvgProfitMargin] = useState(0);

    const formatCurrency = (value) => {
        if (!value && value !== 0) return '';
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    const formatPercentage = (value) => {
        return `${(value * 100).toFixed(1)}%`;
    };

    useEffect(() => {
        // Add validation for data
        if (!Array.isArray(cashflowData) || !Array.isArray(fundingRounds)) {
            console.warn('Invalid data format:', { cashflowData, fundingRounds });
            return;
        }

        // Validate funding rounds
        const validFundingRounds = fundingRounds.filter(round => 
            round && round.time && 
            typeof round.time.year === 'number' && 
            typeof round.time.month === 'number'
        );

        if (validFundingRounds.length === 0) {
            console.warn('No valid funding rounds found');
            return;
        }

        // Calculate Growth & Profit Metrics
        let totalGrowthRate = 0, growthRateCount = 0;
        let totalProfitMargin = 0, profitMarginCount = 0;

        cashflowData.forEach((yearData, index) => {
            if (index > 0 && yearData.revenue > 0) {
                const prevRevenue = cashflowData[index - 1].revenue;
                if (prevRevenue > 0) {
                    totalGrowthRate += (yearData.revenue - prevRevenue) / prevRevenue;
                    growthRateCount++;
                }
            }
            if (yearData.revenue > 0) {
                totalProfitMargin += (yearData.revenue - yearData.expenses) / yearData.revenue;
                profitMarginCount++;
            }
        });

        setAvgGrowthRate(growthRateCount > 0 ? totalGrowthRate / growthRateCount : 0);
        setAvgProfitMargin(profitMarginCount > 0 ? totalProfitMargin / profitMarginCount : 0);

        // Generate Labels and Time Points
        const years = [...new Set([
            ...cashflowData.map(d => d.year),
            ...fundingRounds.map(d => d.time.year)
        ])].sort();

        if (years.length === 0) return;

        const months = [];
        const labelList = [];
        
        for (let year = years[0]; year <= years[years.length - 1]; year++) {
            for (let month = 1; month <= 12; month++) {
                months.push({ year, month });
                labelList.push(`${month}/${year.toString().slice(-2)}`);
            }
        }
        setLabels(labelList);

        // Initialize Data Arrays
        const realizedValuationData = new Array(months.length).fill(null);
        const conservativeValuationData = new Array(months.length).fill(null);
        const growthValuationData = new Array(months.length).fill(null);
        const dcfValuationData = new Array(months.length).fill(null);
        const annotationPoints = [];

        const getMonthIndex = (year, month) => 
            months.findIndex(m => m.year === year && m.month === month);

        // Process Cashflow Data - Interpolate Values Monthly
        let lastConservative = 0, lastGrowth = 0, lastDCF = 0;

        cashflowData.forEach((yearData) => {
            if (!yearData.revenue || yearData.revenue <= 0) return;

            const yearStartIndex = getMonthIndex(yearData.year, 1);
            const yearEndIndex = getMonthIndex(yearData.year, 12);

            if (yearStartIndex === -1 || yearEndIndex === -1) return;

            const conservativeEnd = yearData.revenue * 3;
            const growthEnd = yearData.revenue * 6;
            const dcfEnd = yearData.year >= currentYear
                ? ((yearData.revenue - yearData.expenses) * 5) / 0.15
                : null;

            // Interpolate Monthly Values
            for (let i = yearStartIndex; i <= yearEndIndex; i++) {
                const progress = (i - yearStartIndex) / (yearEndIndex - yearStartIndex || 1);
                conservativeValuationData[i] = Math.round(lastConservative + progress * (conservativeEnd - lastConservative));
                growthValuationData[i] = Math.round(lastGrowth + progress * (growthEnd - lastGrowth));
                if (dcfEnd !== null) {
                    dcfValuationData[i] = Math.round(lastDCF + progress * (dcfEnd - lastDCF));
                }
            }

            lastConservative = conservativeEnd;
            lastGrowth = growthEnd;
            lastDCF = dcfEnd;
        });

        // Process Funding Rounds
        let currentValuation = 0;
        fundingRounds
            .sort((a, b) => (a.time.year !== b.time.year ? 
                a.time.year - b.time.year : 
                a.time.month - b.time.month))
            .forEach(round => {
                const preMoneyIndex = getMonthIndex(round.time.year, round.time.month);
                const postMoneyIndex = getMonthIndex(round.time.year, round.time.month + 1) || preMoneyIndex + 1;

                if (preMoneyIndex !== -1 && preMoneyIndex < realizedValuationData.length) {
                    realizedValuationData[preMoneyIndex] = round.preMoneyValuation;
                    currentValuation = round.preMoneyValuation + round.investmentAmount;
                    
                    if (postMoneyIndex < realizedValuationData.length) {
                        realizedValuationData[postMoneyIndex] = currentValuation;

                        // Fill forward the current valuation
                        for (let i = postMoneyIndex + 1; i < realizedValuationData.length; i++) {
                            realizedValuationData[i] = currentValuation;
                        }
                    }

                    annotationPoints.push({
                        x: labelList[preMoneyIndex],
                        y: round.preMoneyValuation,
                        marker: {
                            size: 8,
                            fillColor: '#fff',
                            strokeColor: '#2196F3',
                            radius: 2
                        },
                        label: {
                            borderColor: '#2196F3',
                            style: {
                                color: '#fff',
                                background: '#2196F3'
                            },
                            text: round.type
                        }
                    });
                }
            });

        setChartSeries([
            {
                name: t('Realized Valuation'),
                type: 'line',
                data: realizedValuationData.map(val => val || null),
            },
            {
                name: t('Conservative'),
                type: 'line',
                data: conservativeValuationData.map(val => val || null),
            },
            {
                name: t('Growth-Adjusted'),
                type: 'line',
                data: growthValuationData.map(val => val || null),
            },
            {
                name: t('DCF'),
                type: 'line',
                data: dcfValuationData.map(val => val || null),
            }
        ]);
        setAnnotations(annotationPoints);
    }, [cashflowData, fundingRounds, currentYear, t, currency]);

    if (!cashflowData?.length || !fundingRounds?.length) {
        return (
            <div className="w-full bg-white rounded-lg shadow p-4">
                <div className="h-[500px] flex items-center justify-center">
                    <p className="text-gray-500">Loading valuation data...</p>
                </div>
            </div>
        );
    }

    const chartOptions = {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                show: true
            },
            animations: {
                enabled: true
            }
        },
        stroke: {
            width: [3, 2, 2, 2],
            curve: 'smooth',
            dashArray: [0, 5, 5, 5]
        },
        colors: ['#2196F3', '#4CAF50', '#FFC107', '#9C27B0'],
        labels: labels,
        xaxis: {
            type: 'category',
            tickAmount: Math.max(Math.floor(labels.length / 5), 1),
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false
            }
        },
        yaxis: {
            min: 0,  // Add this line
            labels: {
                formatter: (value) => formatCurrency(value)
            },
            title: {
                text: currency
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value) => formatCurrency(value)
            }
        },
        annotations: {
            points: annotations
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        }
    };

    return (
        <div className="w-full bg-white rounded-lg shadow p-4">
            <div>
                <div className="h-[500px]">
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        height="100%"
                    />
                </div>
                <div className="mt-6 space-y-4 text-sm text-gray-400">
                    <h3 className="font-medium text-lg mb-2 text-gray-400">{t('Valuation Methods')}</h3>
                    <ul className="space-y-2">
                        <li>
                            <span className="font-medium">{t('Conservative Revenue Multiple')}:</span>
                            {t(' A simple 3x revenue multiple applied to the latest annual revenue.')}
                        </li>
                        <li>
                            <span className="font-medium">{t('Growth-Adjusted Multiple')}:</span>
                            {t(' This valuation uses a base 6x revenue multiple, adjusted as follows:')}
                            <ul className="ml-4 mt-1">
                                <li>• {t('Growth multiplier')}: {avgGrowthRate > 0.2 ? '1.5x ' : '1.0x '} 
                                    ({t('Your average revenue growth')}: {formatPercentage(avgGrowthRate)})</li>
                                <li>• {t('Profit margin multiplier')}: {avgProfitMargin > 0.3 ? '1.4x ' : '1.0x '} 
                                    ({t('Your average profit margin')}: {formatPercentage(avgProfitMargin)})</li>
                                <li>• {t('Final multiplier applied: ')} 
                                    {(6 * (avgGrowthRate > 0.2 ? 1.5 : 1) * (avgProfitMargin > 0.3 ? 1.4 : 1)).toFixed(1)}x</li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-medium">{t('DCF Valuation')}:</span>
                            {t(' A discounted cash flow valuation based on the latest annual revenue and projected growth rate.')}
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-400 text-sm italic">
                        {t('These valuation models provide estimates and should not be considered financial advice.')}
                    </p>
                </div>
            </div>
        </div>
    );
};

ValuationChart.propTypes = {
    cashflowData: PropTypes.arrayOf(
        PropTypes.shape({
            year: PropTypes.number.isRequired,
            revenue: PropTypes.number.isRequired,
            expenses: PropTypes.number.isRequired,
            investments: PropTypes.number.isRequired,
        })
    ),
    fundingRounds: PropTypes.arrayOf(
        PropTypes.shape({
            time: PropTypes.shape({
                month: PropTypes.number.isRequired,
                year: PropTypes.number.isRequired,
            }).isRequired,
            preMoneyValuation: PropTypes.number.isRequired,
            investmentAmount: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
        })
    ),
    currency: PropTypes.string
};

export default ValuationChart;