import axios from '../../axios';

// Add tracking mechanism for auto-saves
const autoSaveTracker = {};

export const saveAsDraft = async (wizardName, section, data, isAutoSave = false) => {
  try {
    // Regular API call to save draft
    await axios.post(`/api/v1/wizards/${wizardName}/draft`, {
      responses: { [section]: data },
      isAutoSave: isAutoSave // Pass this flag to the backend
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
    });
    
    // Only track activity if it's NOT an auto-save OR if it's the first auto-save in a while
    const shouldLogActivity = !isAutoSave || shouldLogAutoSave(wizardName, section);
    
    if (shouldLogActivity && isAutoSave) {
      // Update tracker for this section
      autoSaveTracker[`${wizardName}-${section}`] = Date.now();
      
      // Call separate endpoint to log the activity
      await axios.post(`/api/v1/wizards/${wizardName}/activity`, {
        section: section,
        activityType: 'draft',
        isAutoSave: isAutoSave
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
    }
    
    return { success: true, message: 'Draft saved successfully' };
  } catch (error) {
    console.error('Error saving draft:', error);
    return { success: false, message: 'Error saving draft', error };
  }
};

export const saveData = async (wizardName, section, data) => {
  try {
    console.log(`Saving data for section ${section} at ${new Date().toISOString()}`);
    console.trace('saveData call stack');
    
    // Only make one request to save the published data
    const result = await axios.post(`/api/v1/wizards/${wizardName}`, {
      responses: { [section]: data },
      // Add a flag to also update draft data in the same request
      updateDraft: true
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
    });
    
    return { success: true, message: 'Data saved and published successfully' };
  } catch (error) {
    console.error('Error saving data:', error);
    return { success: false, message: 'Error saving data', error };
  }
};

// Helper to determine if we should log this auto-save
function shouldLogAutoSave(wizardName, section) {
  const key = `${wizardName}-${section}`;
  const lastLoggedTime = autoSaveTracker[key] || 0;
  const now = Date.now();
  
  // Only log auto-saves once every 5 minutes per section
  const COOLDOWN = 5 * 60 * 1000; // 5 minutes in milliseconds
  
  return !lastLoggedTime || (now - lastLoggedTime) > COOLDOWN;
}

// Function to clear the auto-save tracker for a section
// Call this when the user navigates away from the page
export const clearAutoSaveTracker = (wizardName, section) => {
  const key = `${wizardName}-${section}`;
  delete autoSaveTracker[key];
};

// Function to get all tracked sections (for debugging)
export const getTrackedSections = () => {
  return Object.keys(autoSaveTracker);
};

export default {
  saveAsDraft,
  saveData,
  clearAutoSaveTracker,
  getTrackedSections
};