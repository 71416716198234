import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SessionExpired = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-0 text-center">{t('sessionExpired')}</h2>
        <p className="text-center text-gray-700 mb-6">
          {t('sessionExpiredMessage')}
        </p>
        <div className="text-center">
          <Link
            to="/login"
            className="inline-block bg-primary-color text-white px-4 py-2 rounded hover:bg-secondary-color transition-colors"
          >
            {t('loginAgain')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;