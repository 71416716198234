/**
 * Get content for the third countries drawer explaining GDPR status
 * 
 * @param {Array} countries - List of all countries
 * @param {function} t - Translation function
 * @returns {string} HTML content for the drawer
 */
export const getThirdCountriesContent = (countries, t) => {
    if (!countries || !countries.length) {
      return `<p>${t("DataPrivacyWizard.countryDataNotAvailable")}</p>`;
    }
  
    // Group countries by compliance status
    const gdprCountries = countries.filter(c => String(c.gdpr).toLowerCase() === 'true');
    const gdprLikeCountries = countries.filter(c =>
      String(c.gdpr).toLowerCase() !== 'true' &&
      String(c.gdprlike).toLowerCase() === 'true'
    );
    const thirdCountries = countries.filter(c =>
      String(c.gdpr).toLowerCase() !== 'true' &&
      String(c.gdprlike).toLowerCase() !== 'true'
    );
  
    // Create country lists using t() for translation
    const gdprList = gdprCountries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(c => t(`Countries.${c.name}`))
      .join(', ');
  
    const gdprLikeList = gdprLikeCountries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(c => t(`Countries.${c.name}`))
      .join(', ');
  
    const thirdCountriesList = thirdCountries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(c => t(`Countries.${c.name}`))
      .join(', ');
  
    // Return HTML string
    return `
      <div class="prose">
        <p>${t("DataPrivacyWizard.thirdCountriesInfo.description")}</p>
        
        <h4 class="font-bold mt-4 mb-1">${t("DataPrivacyWizard.thirdCountriesInfo.gdprCountriesTitle")}</h4>
        <p class="text-sm">${gdprList}</p>
        
        <h4 class="font-bold mt-4 mb-1">${t("DataPrivacyWizard.thirdCountriesInfo.gdprEquivalentCountriesTitle")}</h4>
        <p class="text-sm">${gdprLikeList}</p>
        
        <h4 class="font-bold mt-4 mb-1">${t("DataPrivacyWizard.thirdCountriesInfo.thirdCountriesTitle")}</h4>
        <p class="text-sm">${thirdCountriesList}</p>
      </div>
    `;
  };