import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';
import axios from '../axios'; // Adjust the import path as needed

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    const fetchTemplateName = async () => {
      // Check if we're on a data collection response page
      if (pathnames[0] === 'reports' && pathnames[1] === 'respond' && pathnames[2]) {
        try {
          const response = await axios.get(`/api/v1/data_collections/respond/${pathnames[2]}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          });
          // Get the template name in the current language
          const userData = JSON.parse(localStorage.getItem('userData'));
          const userLang = userData?.language ? userData.language.slice(0, 2) : 'en';
          setTemplateName(response.data.collection.data[userLang]);
        } catch (error) {
          console.error('Error fetching template name:', error);
          setTemplateName('Data Collection'); // Fallback name
        }
      }
    };

    fetchTemplateName();
  }, [pathnames]);

  const getPageName = (name, index) => {
    // Special handling for data collection pages
    if (pathnames[0] === 'reports') {
      if (pathnames[1] === 'respond' || pathnames[1] === 'report') {
        if (index === 1) {
          return pathnames[1] === 'respond' ? t('Breadcrumbs.provide_data') : t('Breadcrumbs.view_report');
        }
        if (index === 2) {
          return templateName;
        }
      }
    }
  
    // Default behavior
    const withoutHyphens = name.split('-').join(' ');
    const split = withoutHyphens.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const capitalized = split.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
    return t(`Breadcrumbs.${name}`, capitalized);
  };
  
  const getRouteLink = (index) => {
    // For data collection pages, link to the current page
    if (pathnames[0] === 'reports' && 
        (pathnames[1] === 'respond' || pathnames[1] === 'report') && 
        index >= 1) {
      return location.pathname;
    }
    // Default behavior
    return `/${pathnames.slice(0, index + 1).join('/')}`;
  };

  return (
    <nav aria-label="Breadcrumb" className="border-b fixed top-0 right-0 left-0 md:left-[256px] border-gray-200 bg-white z-40">
      <div className="flex w-full items-center px-0 md:px-4 sm:px-6 lg:px-8">
        <ol role="list" className="flex space-x-4">
          <li className="flex">
            <div className="flex items-center">
              <Link to="/" className="text-gray-400 hover:text-gray-500 pl-6 md:pl-0 lg:pl-0">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {pathnames.map((name, index) => {
            // Skip the access token in the breadcrumb for data collection responses
            if (pathnames[0] === 'reports' && pathnames[1] === 'respond' && index > 2) {
              return null;
            }

            const isLast = index === (pathnames[0] === 'reports' && pathnames[1] === 'respond' ? 2 : pathnames.length - 1);

            return (
              <li key={name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={getRouteLink(index)}
                    className={`ml-4 text-sm font-medium ${
                      isLast ? 'text-gray-700' : 'text-gray-500 hover:text-gray-700'
                    }`}
                    aria-current={isLast ? 'page' : undefined}
                  >
                    {getPageName(name, index)}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;