/**
 * Component for selecting a person via a dropdown
 * 
 * @param {Object} props
 * @param {string} props.label - Field label
 * @param {Object} props.value - Selected person object
 * @param {Function} props.onChange - Callback when selection changes
 * @param {string} props.placeholder - Placeholder text
 * @param {boolean} props.required - Whether the field is required
 * @param {string} props.className - Additional CSS classes
 * @param {string} props.userLang - User language (default: 'en')
 * @param {Object} props.filter - Optional filter configuration
 * @param {string} props.filter.categoryId - Filter by category ID (e.g., Human)
 * @param {string} props.filter.classId - Filter by class ID
 */

import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';

const PersonSelector = ({
    label,
    value,
    onChange,
    placeholder = '',
    required = false,
    className = '',
    userLang = 'en',
    filter = null
  }) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    // Fetch human assets on component mount
    useEffect(() => {
      fetchHumanAssets();
    }, []);
  
// In fetchHumanAssets method of PersonSelector.js
const fetchHumanAssets = async () => {
    try {
      setLoading(true);
      setError(null);
            
      // Step 1: Get all categories
      const categoriesResponse = await axios.get('/api/v1/static_data/asset_categories');

      // Find the target category (Human)
      let targetCategory;
      
      if (filter?.categoryId) {
        // Use category ID from filter if provided
        targetCategory = categoriesResponse.data.find(cat => cat.id === filter.categoryId);
      } else {
        // Fall back to finding by name
        const humanCategoryName = userLang === 'de' ? 'Mensch' : 'Human';
        targetCategory = categoriesResponse.data.find(cat => 
          (userLang === 'de' && cat.wertkategorie === humanCategoryName) ||
          (userLang !== 'de' && cat.asset_categories === humanCategoryName)
        );
      }
      
      if (!targetCategory) {
        console.error('PersonSelector: Target category not found');
        setLoading(false);
        return;
      }
      
      // Step 2: Get all asset classes and types in a single batch
      const [classesResponse, typesResponse] = await Promise.all([
        axios.get('/api/v1/static_data/asset_classes'),
        axios.get('/api/v1/static_data/asset_types')
      ]);
      
      // Find classes for the target category
      const targetClasses = classesResponse.data.filter(cls => 
        cls.asset_category_id === targetCategory.id || 
        cls.asset_category === targetCategory.asset_categories
      );
            
      if (targetClasses.length === 0) {
        console.error('PersonSelector: No classes found for category');
        setLoading(false);
        return;
      }
      
      // Find types for the target classes
      const targetTypeIds = [];
      
      for (const cls of targetClasses) {
        const matchingTypes = typesResponse.data.filter(type => 
          type.asset_class_id === cls.id || 
          type.asset_class === cls.asset_class
        );
        
        matchingTypes.forEach(type => targetTypeIds.push(type.id));
      }
            
      if (targetTypeIds.length === 0) {
        console.error('PersonSelector: No types found for classes');
        setLoading(false);
        return;
      }
      
      // Step 3: Get assets matching these types
      const assetsResponse = await axios.get('/api/v1/asset_manager');
      
      const targetAssets = assetsResponse.data.filter(asset => 
        targetTypeIds.includes(asset.asset_type_id)
      );
            
      // Format the people data
      const formattedPeople = targetAssets.map(asset => ({
        id: asset.id,
        name: asset.name || '',
        description: asset.description || '',
        department: asset.custom_department || '',
        role: asset.role || asset.custom_role || '',
      }));
      
      setPeople(formattedPeople);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching people assets:', err);
      setError('Failed to load people data');
      setLoading(false);
    }
  };

  // Debounced search for potentially large lists
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      // If the query is empty, don't perform search
      if (!searchQuery.trim()) return;
      
      // In a real implementation, you might want to query the server here
      // For now, we'll just use the already loaded people array
    }, 300),
    [people]
  );

  // Handle query changes and trigger search
  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    debouncedSearch(newQuery);
  };

  // Filter the people list based on the query
  const filteredPeople = query === ''
    ? people
    : people.filter((person) => {
        return person.name.toLowerCase().includes(query.toLowerCase());
      });

  // Special handling for when the user types a name that doesn't exist
  const handleCustomValue = () => {
    if (!query.trim()) return;
    
    // If the query doesn't match any existing person
    const matchingPerson = people.find(
      person => person.name.toLowerCase() === query.toLowerCase()
    );
    
    if (!matchingPerson && query.trim()) {
      // Create a custom person with the typed name
      const customPerson = {
        id: null, // No ID means it's a custom entry
        name: query.trim(),
        isCustom: true
      };
      
      // Set as selected and notify parent
      onChange(customPerson);
      setQuery('');
    }
  };

  return (
    <Combobox
      as="div"
      value={value}
      onChange={(person) => {
        setQuery('');
        onChange(person);
      }}
      className={className}
    >
      {label && (
        <Combobox.Label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </Combobox.Label>
      )}
      
      <div className="relative">
        <ComboboxInput
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
          onChange={(event) => handleQueryChange(event.target.value)}
          onBlur={handleCustomValue} // Handle custom entries on blur
          displayValue={(person) => person?.name || ''}
          placeholder={placeholder}
        />
        
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center px-2">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        
        {filteredPeople.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <ComboboxOption
                key={person.id || `custom-${person.name}`}
                value={person}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-9 ${
                    active ? 'bg-primary-color text-white' : 'text-gray-900'
                  }`
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex flex-col">
                      <span className={`truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {person.name}
                      </span>
                      {person.department && (
                        <span className={`text-xs truncate ${
                          active ? 'text-indigo-100' : 'text-gray-500'
                        }`}>
                          {person.department}
                        </span>
                      )}
                    </div>

                    {selected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          active ? 'text-white' : 'text-primary-color'
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
        
        {/* Show loading indicator or error message */}
        {loading && (
          <div className="absolute right-10 top-1/2 transform -translate-y-1/2">
            <div className="spinner h-4 w-4 border-t-2 border-primary-color rounded-full animate-spin"></div>
          </div>
        )}
        
        {error && (
          <p className="mt-1 text-xs text-red-600">{error}</p>
        )}
      </div>
    </Combobox>
  );
};

export default PersonSelector;