// share-utils.js

// We'll rely on your translation function at runtime if we want the error itself to be translatable.
// Another approach is to return just metadata (like `sellerName, totalShares, numberOfShares, buyerName`)
// and let the caller build the error string. But I'll show an example of passing in `t` here.

class ShareBlock {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  get count() {
    return this.end - this.start + 1;
  }

  contains(number) {
    return number >= this.start && number <= this.end;
  }

  overlaps(other) {
    return this.start <= other.end && other.start <= this.end;
  }

  toString() {
    return `${this.start}-${this.end}`;
  }
}

/**
 * Parse a single share block string like "1-100" into a ShareBlock object.
 * - If the parse yields NaN or invalid numbers, return null so we can filter it out.
 */
export const parseShareBlock = (blockStr) => {
  if (!blockStr || typeof blockStr !== 'string') return null;
  const [startRaw, endRaw] = blockStr.split('-').map((n) => parseInt(n.trim(), 10));

  if (Number.isNaN(startRaw) || Number.isNaN(endRaw)) {
    return null;  // invalid block
  }
  // You might also enforce that startRaw <= endRaw && startRaw >= 1, etc.
  if (startRaw <= 0 || endRaw < startRaw) {
    return null;  // skip nonsense ranges
  }
  return new ShareBlock(startRaw, endRaw);
};

/**
 * Parse multiple share block strings (comma-separated) into an array of valid ShareBlock objects.
 * e.g. "1-100, 101-200"
 */
export const parseShareBlocks = (blocksStr) => {
  if (!blocksStr || typeof blocksStr !== 'string') return [];
  // Split on commas
  return blocksStr
    .split(',')
    .map((b) => parseShareBlock(b.trim()))
    .filter((block) => block !== null);  // keep only valid blocks
};

/**
 * Convert ShareBlock objects to a comma-separated string representation: "1-100, 101-150"
 */
export const shareBlocksToString = (blocks) => {
  if (!blocks?.length) return '';
  return blocks.map((block) => block.toString()).join(', ');
};

/**
 * Calculate total shares from a block string (like "1-100, 105-200").
 */
export const calculateTotalShares = (blocksStr) => {
  const blocks = parseShareBlocks(blocksStr);
  return blocks.reduce((sum, block) => sum + block.count, 0);
};

/**
 * Get the next available share number, 1 + the maximum 'end' among all existing blocks.
 * If no valid blocks exist, returns 1.
 */
export const getNextShareNumber = (allBlocksStr) => {
  const blocks = parseShareBlocks(allBlocksStr);
  if (!blocks.length) return 1;  // no existing shares => start at 1
  const maxEnd = Math.max(...blocks.map((b) => b.end));
  return maxEnd + 1;
};

/**
 * Extract (aka remove) a specified number of shares from the front of the `sourceBlocks`.
 * Returns { extracted, remaining } as block-strings.
 *
 * Example: sourceBlocks = "1-100, 200-250", numberOfShares = 20
 * => extracted might be "1-20"
 * => remaining might be "21-100, 200-250"
 */
export const extractShareBlocks = (sourceBlocks, numberOfShares) => {
  const blocks = parseShareBlocks(sourceBlocks);
  let remainingShares = numberOfShares;
  const extractedBlocks = [];
  const remainingSourceBlocks = [];

  for (const block of blocks) {
    if (remainingShares <= 0) {
      // no more to extract, keep the block as-is
      remainingSourceBlocks.push(block);
      continue;
    }
    if (block.count <= remainingShares) {
      // we need all of this block
      extractedBlocks.push(block);
      remainingShares -= block.count;
    } else {
      // partial usage of this block
      const newEnd = block.start + remainingShares - 1; // e.g. if block is 1-100, needing 20 => 1-20
      extractedBlocks.push(new ShareBlock(block.start, newEnd));
      // leftover portion of that block
      const leftoverStart = newEnd + 1;
      if (leftoverStart <= block.end) {
        remainingSourceBlocks.push(new ShareBlock(leftoverStart, block.end));
      }
      remainingShares = 0;
    }
  }

  return {
    extracted: shareBlocksToString(extractedBlocks),
    remaining: shareBlocksToString(remainingSourceBlocks),
  };
};

/**
 * Validate a share transfer: does `seller` have enough total shares to transfer `numberOfShares`?
 *
 * We also accept `buyerName` and `t` so we can produce a translatable, descriptive error like:
 * "Seller (Alice) only has 10 shares, but attempting to transfer 20 to Bob."
 *
 * If you prefer, you can just return metadata and build the error in the form code.
 */
export const validateShareTransfer = ({
  sellerName,
  sellerBlocks,
  buyerName,
  numberOfShares,
  t,
}) => {
  const totalShares = calculateTotalShares(sellerBlocks);
  if (totalShares < numberOfShares) {
    // Example i18n approach:
    return {
      isValid: false,
      error: t('SellerOnlyHasXButAttemptingToTransferYToZ', {
        sellerName,
        totalShares,
        numberOfShares,
        buyerName,
      }),
    };
  }
  return { isValid: true };
};