const RainbowButton = ({ 
    children, 
    className = "", 
    onClick, 
    disabled = false,
    size = "md", // sm, md, lg
    ...props 
  }) => {
    const sizeClasses = {
      sm: "px-4 py-2 text-sm",
      md: "px-6 py-3 text-lg",
      lg: "px-8 py-4 text-xl"
    };
  
    const styles = `
      :root {
        --rainbow-1: hsl(0, 100%, 50%);
        --rainbow-2: hsl(60, 100%, 50%);
        --rainbow-3: hsl(120, 100%, 50%);
        --rainbow-4: hsl(180, 100%, 50%);
        --rainbow-5: hsl(240, 100%, 50%);
        --rainbow-6: hsl(300, 100%, 50%);
      }
  
      @keyframes rainbow-glow {
        0% { filter: hue-rotate(0deg); }
        100% { filter: hue-rotate(360deg); }
      }
  
      @keyframes wipe {
        0% { mask-position: 200% center; }
        100% { mask-position: 0% center; }
      }
  
      .rainbow-button {
        background: white;
        position: relative;
        isolation: isolate;
        overflow: visible;
        border: none;
        transition: all 0.3s ease;
        color: rgb(31, 41, 55);
      }
  
      .rainbow-button:hover {
        transform: scale(1.05);
        background: #2A9D8F;
        color: white;
      }
  
      .rainbow-button:disabled {
        background: #E5E7EB;
        color: #9CA3AF;
        transform: none;
        cursor: not-allowed;
      }
  
      .shimmer {
        position: absolute;
        inset: -20px;  /* Reduced from -40px */
        mix-blend-mode: plus-lighter;
        pointer-events: none;
        border-radius: inherit;
        mask-image: linear-gradient(
          90deg, 
          transparent 15%, 
          black 45%, 
          black 55%, 
          transparent 85%
        );
        mask-size: 200% 200%;
        mask-position: center;
        animation: 
          wipe 5s linear infinite both -0.5s,
          rainbow-glow 5s linear infinite;
      }
  
      .shimmer::before,
      .shimmer::after {
        content: "";
        position: absolute;
        inset: 20px;  /* Reduced from 40px */
        border-radius: inherit;
        animation: rainbow-glow 5s linear infinite;
      }
  
      .shimmer::before {
        box-shadow: 
          0 0 3px 2px var(--rainbow-1),
          0 0 5px 4px var(--rainbow-2),
          0 0 9px 5px var(--rainbow-3),
          0 0 13px 3px var(--rainbow-4);
      }
  
      .shimmer::after {
        box-shadow: 
          inset 0 0 0 1px var(--rainbow-5),
          inset 0 0 3px 1px var(--rainbow-6),
          inset 0 0 9px 1px var(--rainbow-1);
      }
  
      .rainbow-button:disabled .shimmer {
        display: none;
      }
    `;
  
    return (
      <>
        <style>{styles}</style>
        <button 
          className={`rainbow-button font-semibold rounded-lg ${sizeClasses[size]} ${className}`}
          onClick={onClick}
          disabled={disabled}
          {...props}
        >
          <span>{children}</span>
          <span className="shimmer"></span>
        </button>
      </>
    );
  };
  
  export default RainbowButton;