// src/pages/wizards/DataPrivacyWizard/components/TransfersSummary.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/solid';
import { isThirdCountry } from './transfersHelpers';

const TransfersSummary = ({
  internalTransfers = [],
  externalTransfers = [],
  countries = [],
  userLang,
  // Add parameters to receive the current recipients from the page
  internalRecipients = [],
  externalRecipients = []
}) => {
  const { t } = useTranslation();

  // Filter transfers to only include those that are active in the current processing activities
  const filterActiveTransfers = (transfers, recipients) => {
    if (!Array.isArray(transfers) || !Array.isArray(recipients)) return [];
    
    // Create a Set of recipient IDs for O(1) lookups
    const activeRecipientIds = new Set(recipients.map(recipient => recipient.id));
    
    // Only include transfers whose IDs are in the active recipients
    return transfers.filter(transfer => activeRecipientIds.has(transfer.id));
  };

  // Get only the transfers for currently active recipients
  const activeInternalTransfers = filterActiveTransfers(internalTransfers, internalRecipients);
  const activeExternalTransfers = filterActiveTransfers(externalTransfers, externalRecipients);

  const countCompliantStatus = (transfers, type) => {
    if (!Array.isArray(transfers)) return { compliant: 0, nonCompliant: 0 };

    let compliant = 0;
    let nonCompliantTotal = 0;
    let nonCompliantDpa = 0;
    let nonCompliantComplianceMechanism = 0;
    let nonCompliantBoth = 0;

    transfers.forEach(transfer => {
      // Check third country status
      const hasThirdCountry =
        (transfer.multipleCountries && transfer.thirdCountries) ||
        (transfer.country && isThirdCountry(transfer.country, countries));

      // Check DPA status (external only) - now also considers 'pending' as non-compliant
      const hasDpaIssue = type === 'external' &&
        (transfer.dpaStatus === 'missing' || transfer.dpaStatus === 'pending');

      // Check compliance mechanism (if third country)
      const hasComplianceIssue = hasThirdCountry &&
        (!transfer.complianceMechanism || transfer.complianceMechanism === 'none');

      if (hasDpaIssue && hasComplianceIssue) {
        nonCompliantBoth++;
        nonCompliantTotal++;
      } else if (hasDpaIssue) {
        nonCompliantDpa++;
        nonCompliantTotal++;
      } else if (hasComplianceIssue) {
        nonCompliantComplianceMechanism++;
        nonCompliantTotal++;
      } else {
        compliant++;
      }
    });

    return {
      compliant,
      nonCompliantTotal,
      nonCompliantDpa,
      nonCompliantComplianceMechanism,
      nonCompliantBoth
    };
  };

  // Get statistics for internal and external transfers - using the filtered transfers
  const internalStats = countCompliantStatus(activeInternalTransfers, 'internal');
  const externalStats = countCompliantStatus(activeExternalTransfers, 'external');

  // Count totals
  const totalTransfers = activeInternalTransfers.length + activeExternalTransfers.length;
  const totalCompliant = internalStats.compliant + externalStats.compliant;
  const totalNonCompliant = internalStats.nonCompliantTotal + externalStats.nonCompliantTotal;

  // Calculate compliance percentage for the progress bar
  const compliancePercentage = totalTransfers > 0
    ? Math.round((totalCompliant / totalTransfers) * 100)
    : 0;

  return (
    <div className="mb-8 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
      {/* Header with overall status */}
      <div className="pt-6 px-6">
        <h2 className="text-xl font-semibold text-gray-900">
          {t('DataPrivacyWizard.transfersSummary')}
        </h2>
      </div>

      {/* Detailed breakdown by transfer type */}
      <div className="px-6 ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-6">
          {/* Internal transfers panel */}
          <div className="bg-white rounded-lg border border-gray-200 overflow-hidden flex flex-col">
            <div className="flex items-center justify-between px-4 py-3 bg-gray-50 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-700">
                {t('DataPrivacyWizard.internalTransfersSummary')}
              </h3>
              <div className="flex items-center">
                {internalStats.nonCompliantTotal > 0 ? (
                  <span className="inline-flex items-center text-xs font-medium text-red-600">
                    <XCircleIcon className="h-4 w-4 mr-1" />
                    {internalStats.nonCompliantTotal} {t('DataPrivacyWizard.issues')}
                  </span>
                ) : (
                  <span className="inline-flex items-center text-xs font-medium text-green-600">
                    <CheckCircleIcon className="h-4 w-4 mr-1" />
                    {t('DataPrivacyWizard.allCompliant')}
                  </span>
                )}
              </div>
            </div>

            <div className="px-4 py-3 flex-grow">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-700 font-medium">{t('DataPrivacyWizard.totalInternalRecipients')}</span>
                <span className="text-sm font-medium text-gray-800">{activeInternalTransfers.length}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-sm text-green-600">{t('DataPrivacyWizard.compliantTransfers')}</span>
                <span className="text-sm font-medium text-green-600">{internalStats.compliant}</span>
              </div>

              {internalStats.nonCompliantTotal > 0 && (
                <div className="mt-3 mb-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-red-600">{t('DataPrivacyWizard.nonCompliantTransfers')}</span>
                    <span className="font-medium text-red-600">{internalStats.nonCompliantTotal}</span>
                  </div>

                  {internalStats.nonCompliantComplianceMechanism > 0 && (
                    <div className="flex justify-between text-xs mt-1 pl-4">
                      <span className="text-red-600">{t('DataPrivacyWizard.missingComplianceMechanism')}</span>
                      <span className="font-medium text-red-600">{internalStats.nonCompliantComplianceMechanism}</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Actionable tasks section */}
            {internalStats.nonCompliantTotal > 0 && (
              <div className="mt-auto bg-yellow-50 px-4 py-3 border-t border-yellow-200">
                <h4 className="text-xs font-semibold text-yellow-800 mb-2 flex items-center">
                  <ExclamationTriangleIcon className="h-4 w-4 mr-1 text-yellow-600" />
                  {t('DataPrivacyWizard.requiredActions')}
                </h4>
                <ul className="text-xs text-yellow-700 space-y-1">
                  {internalStats.nonCompliantComplianceMechanism > 0 && (
                    <li className="flex items-start">
                      <span>{t('DataPrivacyWizard.actionRequiredInternalComplianceMechanisms')}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>

          {/* External transfers panel */}
          <div className="bg-white rounded-lg border border-gray-200 overflow-hidden flex flex-col">
            <div className="flex items-center justify-between px-4 py-3 bg-gray-50 border-b border-gray-200">
              <h3 className="text-sm font-medium text-gray-700">
                {t('DataPrivacyWizard.externalTransfersSummary')}
              </h3>
              <div className="flex items-center">
                {externalStats.nonCompliantTotal > 0 ? (
                  <span className="inline-flex items-center text-xs font-medium text-red-600">
                    <XCircleIcon className="h-4 w-4 mr-1" />
                    {externalStats.nonCompliantTotal} {t('DataPrivacyWizard.issues')}
                  </span>
                ) : (
                  <span className="inline-flex items-center text-xs font-medium text-green-600">
                    <CheckCircleIcon className="h-4 w-4 mr-1" />
                    {t('DataPrivacyWizard.allCompliant')}
                  </span>
                )}
              </div>
            </div>

            <div className="px-4 py-3 flex-grow">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-700 font-medium">{t('DataPrivacyWizard.totalExternalRecipients')}</span>
                <span className="text-sm font-medium text-gray-800">{activeExternalTransfers.length}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-sm text-green-600">{t('DataPrivacyWizard.compliantTransfers')}</span>
                <span className="text-sm font-medium text-green-600">{externalStats.compliant}</span>
              </div>

              {externalStats.nonCompliantTotal > 0 && (
                <div className="mt-3 mb-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-red-600">{t('DataPrivacyWizard.nonCompliantTransfers')}</span>
                    <span className="font-medium text-red-600">{externalStats.nonCompliantTotal}</span>
                  </div>

                  {externalStats.nonCompliantDpa > 0 && (
                    <div className="flex justify-between text-xs mt-1 pl-4">
                      <span className="text-red-600">{t('DataPrivacyWizard.missingDpas')}</span>
                      <span className="font-medium text-red-600">{externalStats.nonCompliantDpa}</span>
                    </div>
                  )}

                  {externalStats.nonCompliantComplianceMechanism > 0 && (
                    <div className="flex justify-between text-xs mt-1 pl-4">
                      <span className="text-red-600">{t('DataPrivacyWizard.missingComplianceMechanism')}</span>
                      <span className="font-medium text-red-600">{externalStats.nonCompliantComplianceMechanism}</span>
                    </div>
                  )}

                  {externalStats.nonCompliantBoth > 0 && (
                    <div className="flex justify-between text-xs mt-1 pl-4">
                      <span className="text-red-600">{t('DataPrivacyWizard.missingBoth')}</span>
                      <span className="font-medium text-red-600">{externalStats.nonCompliantBoth}</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Actionable tasks section */}
            {externalStats.nonCompliantTotal > 0 && (
              <div className="mt-auto bg-yellow-50 px-4 py-3 border-t border-yellow-200">
                <h4 className="text-xs font-semibold text-yellow-800 mb-2 flex items-center">
                  <ExclamationTriangleIcon className="h-4 w-4 mr-1 text-yellow-600" />
                  {t('DataPrivacyWizard.requiredActions')}
                </h4>
                <ul className="text-xs text-yellow-700 space-y-1">
                  {externalStats.nonCompliantDpa + externalStats.nonCompliantBoth > 0 && (
                    <li className="flex items-start">
                      <span>{t('DataPrivacyWizard.actionRequiredDpas')}</span>
                    </li>
                  )}
                  {externalStats.nonCompliantComplianceMechanism + externalStats.nonCompliantBoth > 0 && (
                    <li className="flex items-start">
                      <span>{t('DataPrivacyWizard.actionRequiredExternalComplianceMechanisms')}</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransfersSummary;