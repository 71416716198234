import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Radiothumbs from '../../../components/wizard_components/Radiothumbs';
import OneSlider from '../../../components/wizard_components/OneSlider';
import TextField from '../../../components/wizard_components/TextField';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const KnowYourselfWizardInvestment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding');
  const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);
  const [dataStatus, setDataStatus] = useState('new');

  const isEqual = (value1, value2) => {
    return JSON.stringify(value1) === JSON.stringify(value2);
  };

  const [initialValues, setInitialValues] = useState({
    targetFundingRound: '',
    planningHorizon: 12,
    avgCashBurn: 50000,
    capitalRequired: 600000,
    fundingCurrency: 'USD',
    fundingTime: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    },
    investmentNotes: '',
  });

  function coalesceInvestmentData(raw) {
    // Fallback to the current date for "fundingTime"
    const currentDate = new Date();
    const defaultFundingTime = {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear()
    };

    // Use the same defaults as in your local initialValues
    let obj = {
      targetFundingRound: raw?.targetFundingRound ?? '',
      planningHorizon: raw?.planningHorizon ?? 12,
      avgCashBurn: raw?.avgCashBurn ?? 50000,
      fundingCurrency: raw?.fundingCurrency ?? 'USD',
      fundingTime: raw?.fundingTime || defaultFundingTime,
      investmentNotes: raw?.investmentNotes ?? '',
    };

    // Then recalc capitalRequired the same way you do locally
    obj.capitalRequired = obj.planningHorizon * obj.avgCashBurn;
    if (!obj.capitalRequired || obj.capitalRequired <= 0) {
      // If that fails for some reason, or you want a fallback
      obj.capitalRequired = 600000;
    }

    return obj;
  }

  useEffect(() => {
    if (!userData?.entity?.know_yourself_response) return;

    const { published, draft } = userData.entity.know_yourself_response;

    // 1. Coalesce each chunk to local defaults
    const coalescedDraft = coalesceInvestmentData(draft);
    const coalescedPublished = coalesceInvestmentData(published);

    // 2. Compare each with your local defaults
    //    This will let us see if they’re “empty”
    const localDefaults = coalesceInvestmentData({}); // an empty object => your local initial

    const isDraftEmpty = isEqual(coalescedDraft, localDefaults);
    const isPublishedEmpty = isEqual(coalescedPublished, localDefaults);

    // 3. Decide status
    if (!isPublishedEmpty) {
      // There is published data
      setDataStatus('published');
      setInitialValues(coalescedPublished);

      if (!isDraftEmpty && !isEqual(coalescedDraft, coalescedPublished)) {
        // There’s a different draft on top
        setDataStatus('draft');
        setInitialValues(coalescedDraft);
      }
    } else if (!isDraftEmpty) {
      // No published data but we do have draft
      setDataStatus('draft');
      setInitialValues(coalescedDraft);
    } else {
      // Everything is basically default
      setDataStatus('new');
      setInitialValues(localDefaults);
    }
  }, [userData]);

  const investmentStageSvgs = {
    "Pre-Seed": {
      default: '/thumbs/PreSeed_investmentstage.svg',
      hover: '/thumbs/PreSeed_investmentstage_hover.svg',
      active: '/thumbs/PreSeed_investmentstage_active.svg'
    },
    "Seed": {
      default: '/thumbs/Seed_investmentstage.svg',
      hover: '/thumbs/Seed_investmentstage_hover.svg',
      active: '/thumbs/Seed_investmentstage_active.svg'
    },
    "Series A": {
      default: '/thumbs/SeriesA_investmentstage.svg',
      hover: '/thumbs/SeriesA_investmentstage_hover.svg',
      active: '/thumbs/SeriesA_investmentstage_active.svg'
    },
    "Series B": {
      default: '/thumbs/SeriesB_investmentstage.svg',
      hover: '/thumbs/SeriesB_investmentstage_hover.svg',
      active: '/thumbs/SeriesB_investmentstage_active.svg'
    },
    "Series C": {
      default: '/thumbs/SeriesC_investmentstage.svg',
      hover: '/thumbs/SeriesC_investmentstage_hover.svg',
      active: '/thumbs/SeriesC_investmentstage_active.svg'
    },
    "Past Series C": {
      default: '/thumbs/SeriesCplus_investmentstage.svg',
      hover: '/thumbs/SeriesCplus_investmentstage_hover.svg',
      active: '/thumbs/SeriesCplus_investmentstage_active.svg'
    }
  };

  const validationSchema = Yup.object({
    targetFundingRound: Yup.string()
      .required(t('KnowYourselfWizard.targetFundingRoundRequired')),
    planningHorizon: Yup.number()
      .min(6, t('KnowYourselfWizard.planningHorizonMin'))
      .max(36, t('KnowYourselfWizard.planningHorizonMax'))
      .required(t('KnowYourselfWizard.planningHorizonRequired')),
    avgCashBurn: Yup.number()
      .min(0, t('KnowYourselfWizard.avgCashBurnMin'))
      .max(2500000, t('KnowYourselfWizard.avgCashBurnMax'))
      .required(t('KnowYourselfWizard.avgCashBurnRequired')),
    fundingCurrency: Yup.string()
      .required(t('KnowYourselfWizard.fundingCurrencyRequired')),
    fundingTime: Yup.object({
      month: Yup.number()
        .min(1, t('KnowYourselfWizard.monthInvalid'))
        .max(12, t('KnowYourselfWizard.monthInvalid'))
        .required(t('KnowYourselfWizard.monthRequired')),
      year: Yup.number()
        .min(2024, t('KnowYourselfWizard.yearMin'))
        .max(2050, t('KnowYourselfWizard.yearMax'))
        .required(t('KnowYourselfWizard.yearRequired'))
    }).required(),
    investmentNotes: Yup.string()
      .max(1000, t('KnowYourselfWizard.notesMaxLength'))
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await handleSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
      setDataStatus('published');
      await handleDraftSubmit({ responses: values });

      // Navigate to funding needs page after successful save
      setTimeout(() => {
        navigate('/funding/fundingNeeds');
      }, 1000);
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: 'Failed to save data',
        errors: [error.message]
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDraftSubmit = async (values, { setSubmitting }) => {
    try {
      await handleDraftSubmit({ responses: values });
      setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
      setDataStatus('draft');
    } catch (error) {
      setNotification({ show: true, type: 'error', message: 'Failed to save draft', errors: [error.message] });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
      <Notification
        show={notification.show}
        setShow={(show) => setNotification({ ...notification, show })}
        type={notification.type}
        message={notification.message}
        errors={notification.errors}
      />
      <Progress />
      <div className="data-status-banner mt-4 mb-4">
        {dataStatus === 'published' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is the last saved and published version.')}
          </span>
        )}
        {dataStatus === 'draft' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
          </span>
        )}
        {dataStatus === 'new' && (
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
              <circle r={3} cx={3} cy={3} />
            </svg>
            {t('There is no prior version of this page. Please manually save or save as draft below.')}
          </span>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting, validateForm }) => (
          <Form onSubmit={async (e) => {
            e.preventDefault();
            const errors = await validateForm(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values, { setSubmitting: () => { } });
            } else {
              const errorMessages = [];

              // Handle regular fields
              const fieldTranslations = {
                targetFundingRound: t('KnowYourselfWizard.targetFundingRound'),
                planningHorizon: t('KnowYourselfWizard.planningHorizon'),
                avgCashBurn: t('KnowYourselfWizard.avgCashBurn'),
                fundingCurrency: t('KnowYourselfWizard.currency'),
                investmentNotes: t('KnowYourselfWizard.notes')
              };

              // Handle regular field errors
              Object.entries(errors).forEach(([field, message]) => {
                if (field !== 'fundingTime') {
                  const fieldName = fieldTranslations[field] || field;
                  errorMessages.push(`${fieldName}: ${message}`);
                }
              });

              // Handle funding time errors separately
              if (errors.fundingTime) {
                if (errors.fundingTime.month) {
                  errorMessages.push(`${t('KnowYourselfWizard.month')}: ${errors.fundingTime.month}`);
                }
                if (errors.fundingTime.year) {
                  errorMessages.push(`${t('KnowYourselfWizard.year')}: ${errors.fundingTime.year}`);
                }
              }

              setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save data due to validation errors:'),
                errors: errorMessages
              });
            }
          }}>

            <div className="investment-section">
              <h2 className="text-primary-color">{t('KnowYourselfWizard.nextFinancingRound')}</h2>
              <p className="mb-8">{t('KnowYourselfWizard.investmentIntro')}</p>

              {/* Investment Recommendations */}
              <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setRecommendationsOpen(!recommendationsOpen);
                  }}
                  className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center">
                    <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                    <span className="text-primary-color">{t('Recommendations')}</span>
                  </div>
                  <svg
                    className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {recommendationsOpen && (
                  <div className="p-4 bg-white rounded-b-lg border-t">
                    <div className="space-y-4 text-gray-700">
                      {/* Funding Round Section */}
                      <div>
                        <h4 className="text-sm font-semibold pt-2 pb-2 text-gray-900">
                          {t('investmentRecommendations.fundingRound.title')}
                        </h4>
                        <ul className="list-disc list-inside text-sm">
                          <li>{t('investmentRecommendations.fundingRound.bullet1')}</li>
                          <li>{t('investmentRecommendations.fundingRound.bullet2')}</li>
                          <li>{t('investmentRecommendations.fundingRound.bullet3')}</li>
                          <li>{t('investmentRecommendations.fundingRound.bullet4')}</li>
                        </ul>
                        <p className="text-sm">
                          {t('investmentRecommendations.fundingRound.contentAdditional')}
                        </p>
                      </div>
                      {/* Planning Horizon and Cash Burn Section */}
                      <div>
                        <h4 className="text-sm font-semibold pt-2 pb-2 text-gray-900">
                          {t('investmentRecommendations.planningCash.title')}
                        </h4>
                        <ul className="list-disc list-inside text-sm">
                          <li>{t('investmentRecommendations.planningCash.bullet1')}</li>
                          <li>{t('investmentRecommendations.planningCash.bullet2')}</li>
                          <li>{t('investmentRecommendations.planningCash.bullet3')}</li>
                        </ul>
                      </div>
                      {/* Funding Timing Section */}
                      <div>
                        <h4 className="text-sm font-semibold pt-2 text-gray-900">
                          {t('investmentRecommendations.timing.title')}
                        </h4>
                        <p className="text-sm">
                          {t('investmentRecommendations.timing.content')}
                        </p>
                      </div>
                      {/* Investment Strategy Section */}
                      <div>
                        <h4 className="text-sm font-semibold pt-2 text-gray-900">
                          {t('investmentRecommendations.strategy.title')}
                        </h4>
                        <p className="text-sm">
                          {t('investmentRecommendations.strategy.content')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-primary-color mb-6">{t('KnowYourselfWizard.targetFundingRound')}</h3>
                <Radiothumbs
                  name="targetFundingRound"
                  options={Object.keys(investmentStageSvgs).map(stage => ({
                    value: stage,
                    svg: investmentStageSvgs[stage],
                    label: t(`InvestmentStages.${stage}`)
                  }))}
                  value={values.targetFundingRound}
                  onChange={(e) => setFieldValue('targetFundingRound', e.target.value)}
                />
              </div>

              <div className="bg-white p-6 rounded-lg shadow mt-8">
                <h3>{t('Required Capital')}</h3>
                <div className="mb-6 mt-4">
                  <label htmlFor="fundingCurrency" className="block text-sm font-medium text-gray-700 mb-2">
                    {t('Select Currency')}
                  </label>
                  <select
                    name="fundingCurrency"
                    value={values.fundingCurrency}
                    onChange={(e) => setFieldValue('fundingCurrency', e.target.value)}
                    className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                  >
                    <option value="USD">💵 USD - US Dollar</option>
                    <option value="EUR">💶 EUR - Euro</option>
                    <option value="JPY">💴 JPY - Japanese Yen</option>
                    <option value="GBP">💷 GBP - British Pound</option>

                    <option disabled>──────────</option> {/* Divider */}

                    <option value="AUD">AUD - Australian Dollar</option>
                    <option value="BRL">BRL - Brazilian Real</option>
                    <option value="CAD">CAD - Canadian Dollar</option>
                    <option value="CHF">CHF - Swiss Franc</option>
                    <option value="CNY">CNY - Chinese Yuan/Renminbi</option>
                    <option value="HKD">HKD - Hong Kong Dollar</option>
                    <option value="INR">INR - Indian Rupee</option>
                    <option value="KRW">KRW - South Korean Won</option>
                    <option value="MXN">MXN - Mexican Peso</option>
                    <option value="NOK">NOK - Norwegian Krone</option>
                    <option value="NZD">NZD - New Zealand Dollar</option>
                    <option value="RUB">RUB - Russian Ruble</option>
                    <option value="SEK">SEK - Swedish Krona</option>
                    <option value="SGD">SGD - Singapore Dollar</option>
                    <option value="TRY">TRY - Turkish Lira</option>
                    <option value="ZAR">ZAR - South African Rand</option>
                  </select>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('When is the funding round planned?')}
                  </label>
                  <div className="flex gap-2">
                    <select
                      className="border rounded px-2 py-1 text-sm"
                      value={values.fundingTime.month}
                      onChange={(e) => setFieldValue('fundingTime.month', Number(e.target.value))}
                    >
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                        <option key={month} value={month}>{month}</option>
                      ))}
                    </select>
                    <select
                      className="border rounded px-2 py-1 text-sm"
                      value={values.fundingTime.year}
                      onChange={(e) => setFieldValue('fundingTime.year', Number(e.target.value))}
                    >
                      {Array.from({ length: 27 }, (_, i) => 2024 + i).map(year => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mt-6 mb-3 text-sm text-gray-500">
                  {t('Estimate the amount of capital you need to raise to achieve your next business milestones.')}
                </div>

                <OneSlider
                  name="planningHorizon"
                  leftLabel={t('Planning Horizon (months)')}
                  rightLabel={values.planningHorizon}
                  min={6}
                  max={36}
                  value={values.planningHorizon}
                  onChange={(val) => {
                    setFieldValue('planningHorizon', val);
                    setFieldValue('capitalRequired', val * values.avgCashBurn);
                  }}
                />

                <OneSlider
                  name="avgCashBurn"
                  leftLabel={t('Avg. Cash Burn per month')}
                  rightLabel={`${values.avgCashBurn.toLocaleString()} ${values.fundingCurrency}`}
                  min={0}
                  max={250000}
                  step={1000}
                  value={values.avgCashBurn}
                  onChange={(val) => {
                    setFieldValue('avgCashBurn', val);
                    setFieldValue('capitalRequired', val * values.planningHorizon);
                  }}
                />

                <div className="mt-4 font-semibold text-xl">
                  {t('Capital Required')}: {(values.planningHorizon * values.avgCashBurn).toLocaleString()} {values.fundingCurrency}
                </div>
              </div>
            </div>

            <div className="mt-6">
              <p>{t('KnowYourselfWizard.investmentNotesDescription')}</p>
              <TextField
                name="investmentNotes"
                type="textarea"
              />
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm text-gray-900"
                onClick={() => navigate('/funding/team')}
              >
                {t('cancel')}
              </button>
              <button
                type="button"
                onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                disabled={isSubmitting}
                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600"
              >
                {t('Save as Draft')}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
              >
                {isSubmitting ? t('saving') : t('save')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </>
  );
};

export default KnowYourselfWizardInvestment;