// src/components/FlowChart/FlowChart.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useNodesState,
    useEdgesState,
    addEdge
} from 'reactflow';
import 'reactflow/dist/style.css';
import ELK from 'elkjs/lib/elk.bundled.js';
import { useTranslation } from 'react-i18next';

// Import custom components
import NodePalette from './components/NodePalette';
import ControlPanel from './components/ControlPanel';
import NodeProperties from './components/NodeProperties';
import useFlowChart from './hooks/useFlowChart';

// Import custom node types
import DataFlowNode from './nodes/DataFlowNode';
import SystemNode from './nodes/SystemNode';
import ActorNode from './nodes/ActorNode';
import ActivityNode from './nodes/ActivityNode';
import InputOutputNode from './nodes/InputOutputNode';

// Define node types
const nodeTypes = {
    dataFlowNode: DataFlowNode,
    systemNode: SystemNode,
    actorNode: ActorNode,
    activityNode: ActivityNode,
    inputNode: (props) => <InputOutputNode {...props} data={{...props.data, isInput: true}} />,
    outputNode: (props) => <InputOutputNode {...props} data={{...props.data, isInput: false}} />
};

// Initialize ELK instance
const elk = new ELK();

// ELK layout options
const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.direction': 'DOWN',
    'elk.spacing.nodeNode': '50',
    'elk.layered.spacing.nodeNodeBetweenLayers': '100'
};

const FlowChart = ({
    id = null,
    initialNodes = [],
    initialEdges = [],
    onSave,
    onChange,
    height = '400px',
    readOnly = false
}) => {
    const { t } = useTranslation();
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    // We can remove these since we're removing the node properties panel
    const [selectedNode, setSelectedNode] = useState(null);
    const [nodeFormOpen, setNodeFormOpen] = useState(false);
    const [nodeName, setNodeName] = useState('');
    const [nodeType, setNodeType] = useState('systemNode');
    const [nodeDescription, setNodeDescription] = useState('');
    const [autoLayoutRunning, setAutoLayoutRunning] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { loadFlowChart, saveFlowChart } = useFlowChart();
    
    // Get default label based on node type
    const getDefaultLabelForType = useCallback((type) => {
        switch (type) {
            case 'systemNode':
                return t('FlowChart.system', 'System');
            case 'actorNode':
                return t('FlowChart.actor', 'Actor');
            case 'dataFlowNode':
                return t('FlowChart.dataProcess', 'Process');
            case 'activityNode':
                return t('FlowChart.activity', 'Activity');
            case 'inputNode':
                return t('FlowChart.input', 'Input');
            case 'outputNode':
                return t('FlowChart.output', 'Output');
            default:
                return 'New Node';
        }
    }, [t]);

    // Initialize react flow instance
    const onInit = useCallback((instance) => {
        console.log('ReactFlow initialized');
        setReactFlowInstance(instance);
    }, []);

    // Load flow chart data if ID is provided - THIS RUNS ONCE
    useEffect(() => {
        // Skip if already initialized to prevent infinite loops
        if (initialized) return;
        
        const fetchFlowChart = async () => {
            setIsLoading(true);
            try {
                if (id) {
                    // Load existing flow chart
                    const flowChart = await loadFlowChart(id);
                    if (flowChart) {
                        console.log('Loading existing flow chart', flowChart);
                        setNodes(flowChart.nodes || []);
                        setEdges(flowChart.edges || []);
                        setHasUnsavedChanges(false);
                    }
                } else if (initialNodes.length > 0 || initialEdges.length > 0) {
                    // Use provided initial data
                    console.log('Using initial nodes/edges');
                    setNodes(initialNodes);
                    setEdges(initialEdges);
                    setHasUnsavedChanges(false);
                } else {
                    // Default starting nodes for an empty diagram
                    console.log('Creating default nodes and edges');
                    const defaultNodes = [
                        {
                            id: 'input-data',
                            type: 'inputNode',
                            data: {
                                label: t('FlowChart.input', 'Input Data'),
                                description: t('FlowChart.inputDesc', 'Data used in the process')
                            },
                            position: { x: 150, y: 50 }
                        },
                        {
                            id: 'activity-process',
                            type: 'activityNode',
                            data: {
                                label: t('FlowChart.activity', 'Activity'),
                                description: t('FlowChart.activityDesc', 'Process activity'),
                                updateNodeData: (data) => {
                                    // This function will be called when activity node data changes
                                    setNodes(nds => 
                                        nds.map(n => 
                                            n.id === 'activity-process' 
                                                ? { ...n, data: { ...n.data, ...data } } 
                                                : n
                                        )
                                    );
                                }
                            },
                            position: { x: 250, y: 150 }
                        },
                        {
                            id: 'output-data',
                            type: 'outputNode',
                            data: {
                                label: t('FlowChart.output', 'Output Data'),
                                description: t('FlowChart.outputDesc', 'Data produced by the process')
                            },
                            position: { x: 350, y: 250 }
                        }
                    ];

                    const defaultEdges = [
                        {
                            id: 'e-input-activity',
                            source: 'input-data',
                            target: 'activity-process',
                            animated: true
                        },
                        {
                            id: 'e-activity-output',
                            source: 'activity-process',
                            target: 'output-data',
                            animated: true
                        }
                    ];

                    setNodes(defaultNodes);
                    setEdges(defaultEdges);
                    setHasUnsavedChanges(true);
                }
            } catch (error) {
                console.error('Error in fetchFlowChart:', error);
            } finally {
                setIsLoading(false);
                setInitialized(true); // Mark as initialized to prevent re-fetching
            }
        };
        
        fetchFlowChart();
    }, [id, loadFlowChart, initialNodes, initialEdges, t, setNodes, setEdges, initialized]);

    // Fit view when instance is available and loading is complete
    useEffect(() => {
        if (reactFlowInstance && !isLoading && nodes.length > 0) {
            // Wait a bit to ensure nodes are properly rendered
            const timer = setTimeout(() => {
                console.log('Fitting view');
                reactFlowInstance.fitView({ padding: 0.2 });
            }, 200);
            
            return () => clearTimeout(timer);
        }
    }, [reactFlowInstance, isLoading, nodes]);

    // Track changes to mark unsaved changes - only after initialization
    useEffect(() => {
        if (!initialized || isLoading) return;
        
        if (nodes.length > 0 || edges.length > 0) {
            setHasUnsavedChanges(true);

            // Call onChange handler if provided
            if (onChange) {
                onChange({
                    nodes,
                    edges
                });
            }
        }
    }, [nodes, edges, onChange, initialized, isLoading]);

    // Handle connecting nodes
    const onConnect = useCallback((params) => {
        if (readOnly) return;

        setEdges((eds) => addEdge({
            ...params,
            animated: true,
            style: { stroke: '#555' }
        }, eds));
    }, [setEdges, readOnly]);

    // Handle drag and drop
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    // Handle dropping a new node
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            if (readOnly) return;

            if (!reactFlowInstance || !reactFlowWrapper.current) {
                console.warn('React Flow instance not available yet');
                return;
            }

            // Get the node type from dataTransfer
            const type = event.dataTransfer.getData('application/reactflow');
            
            if (!type) {
                console.warn('Invalid node type from drag event');
                return;
            }

            // Get the wrapper bounds
            const wrapperBounds = reactFlowWrapper.current.getBoundingClientRect();
            
            // Calculate the correct position
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX - wrapperBounds.left,
                y: event.clientY - wrapperBounds.top
            });

            console.log('Creating node at position:', position);
            console.log('Node type:', type);

            // Create the new node
            const newNode = {
                id: `${type}-${Date.now()}`,
                type,
                position,
                data: {
                    label: getDefaultLabelForType(type),
                    description: ''
                },
            };

            // For activity nodes, we need to add a callback for updating the node data
            if (type === 'activityNode') {
                newNode.data.updateNodeData = (data) => {
                    setNodes(nds => 
                        nds.map(n => 
                            n.id === newNode.id 
                                ? { ...n, data: { ...n.data, ...data } } 
                                : n
                        )
                    );
                };
            }
            
            // For input/output nodes, add a callback for updating
            if (type === 'inputNode' || type === 'outputNode') {
                newNode.data.updateNodeData = (data) => {
                    setNodes(nds => 
                        nds.map(n => 
                            n.id === newNode.id 
                                ? { ...n, data: { ...n.data, ...data } } 
                                : n
                        )
                    );
                };
                
                // Also set the isInput flag based on the node type
                newNode.data.isInput = type === 'inputNode';
            }

            // Add the new node to the list
            setNodes((nds) => nds.concat(newNode));
            
            // Select the new node for editing
            setSelectedNode(newNode);
            setNodeName(newNode.data.label);
            setNodeType(type);
            setNodeDescription(newNode.data.description || '');
            setNodeFormOpen(true);
        },
        [reactFlowInstance, setNodes, getDefaultLabelForType, readOnly]
    );

    // Handle node click - we're going to remove the node properties form completely
    const onNodeClick = useCallback((event, node) => {
        // In a direct editing approach, we don't need to do anything on node click
        // The editing happens directly on the node
        console.log('Node clicked:', node);
    }, []);

    // Update node properties
    const updateNodeProperties = useCallback(() => {
        if (!selectedNode) return;

        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === selectedNode.id) {
                    // Don't change the node type for activityNode, inputNode, or outputNode
                    // as they have special functionality
                    const shouldUpdateType = 
                        !['activityNode', 'inputNode', 'outputNode'].includes(selectedNode.type);
                    
                    return {
                        ...node,
                        type: shouldUpdateType ? nodeType : node.type,
                        data: {
                            ...node.data,
                            label: nodeName,
                            description: nodeDescription
                        }
                    };
                }
                return node;
            })
        );

        setNodeFormOpen(false);
    }, [selectedNode, nodeType, nodeName, nodeDescription, setNodes]);

    // Handle deleting a node
    const handleDeleteNode = useCallback(() => {
        if (!selectedNode || readOnly) return;

        const nodeId = selectedNode.id;

        // First close the form
        setNodeFormOpen(false);
        setSelectedNode(null);

        // Use a timeout to ensure UI updates before we modify the nodes/edges
        setTimeout(() => {
            // Remove all edges connected to this node
            setEdges(eds =>
                eds.filter(edge => edge.source !== nodeId && edge.target !== nodeId)
            );

            // Then remove the node itself
            setNodes(nds =>
                nds.filter(node => node.id !== nodeId)
            );
        }, 0);
    }, [selectedNode, setNodes, setEdges, readOnly]);

    // Run automatic layout
    const runLayout = useCallback(async () => {
        if (!nodes.length || nodes.length < 2) return;

        setAutoLayoutRunning(true);

        try {
            const elkGraph = {
                id: 'root',
                layoutOptions: elkOptions,
                children: nodes.map((node) => ({
                    id: node.id,
                    width: node.type === 'activityNode' ? 240 : 180,
                    height: node.type === 'activityNode' ? 140 : 70
                })),
                edges: edges.map((edge) => ({
                    id: edge.id,
                    sources: [edge.source],
                    targets: [edge.target]
                }))
            };

            const layoutResult = await elk.layout(elkGraph);

            // Update node positions with animation
            setNodes((nds) =>
                nds.map((node) => {
                    const layoutNode = layoutResult.children.find((n) => n.id === node.id);
                    if (layoutNode) {
                        return {
                            ...node,
                            position: {
                                x: layoutNode.x,
                                y: layoutNode.y
                            },
                            // Add transition for smooth animation
                            style: { transition: 'all 300ms ease-in-out' }
                        };
                    }
                    return node;
                })
            );

            // Fit view after layout completes
            setTimeout(() => {
                if (reactFlowInstance) {
                    reactFlowInstance.fitView({ padding: 0.2 });
                }
            }, 350);

        } catch (error) {
            console.error('ELK layout error:', error);
        } finally {
            setAutoLayoutRunning(false);
        }
    }, [nodes, edges, reactFlowInstance, setNodes]);

    // Handle save
    const handleSave = useCallback(async () => {
        if (!onSave || readOnly) return;

        setIsSaving(true);

        try {
            const success = await onSave(nodes, edges);
            if (success) {
                setHasUnsavedChanges(false);
            }
        } catch (error) {
            console.error('Error saving flow chart:', error);
        } finally {
            setIsSaving(false);
        }
    }, [nodes, edges, onSave, readOnly]);

    return (
        <div className="flex flex-col space-y-4">
            {/* Flow chart area */}
            <div
                className="border border-gray-200 rounded-lg bg-gray-50"
                style={{ height }}
                ref={reactFlowWrapper}
            >
                {isLoading && (
                    <div className="flex items-center justify-center h-full">
                        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary-color"></div>
                    </div>
                )}

                {!isLoading && (
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onInit={onInit}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onNodeClick={readOnly ? undefined : onNodeClick}
                        nodeTypes={nodeTypes}
                        fitView
                        minZoom={0.5}
                        maxZoom={1.5}
                        defaultEdgeOptions={{
                            animated: true,
                            style: { stroke: '#555', strokeWidth: 1.5 }
                        }}
                        nodesDraggable={!readOnly}
                        nodesConnectable={!readOnly}
                        elementsSelectable={!readOnly}
                        zoomOnScroll={true}
                        zoomOnPinch={true}
                        panOnScroll={false}
                        panOnDrag={true}
                    >
                        <Controls showInteractive={!readOnly} />
                        <MiniMap
                            nodeStrokeColor="#aaa"
                            nodeColor={(node) => {
                                switch (node.type) {
                                    case 'dataFlowNode': return '#ebf5ff';
                                    case 'systemNode': return '#ecfdf5';
                                    case 'actorNode': return '#f5f3ff';
                                    case 'activityNode': return '#ebf5ff';
                                    case 'inputNode': return '#ecfdf5';
                                    case 'outputNode': return '#f0f5ff';
                                    default: return '#fff';
                                }
                            }}
                        />
                        <Background gap={12} size={1} />

                        {!readOnly && <NodePalette disabled={readOnly} />}

                        <ControlPanel
                            onAutoLayout={runLayout}
                            autoLayoutRunning={autoLayoutRunning}
                            onSave={onSave ? handleSave : undefined}
                            isSaving={isSaving}
                            hasUnsavedChanges={hasUnsavedChanges}
                            nodeCount={nodes.length}
                        />
                    </ReactFlow>
                )}
            </div>
        </div>
    );
};

export default FlowChart;