import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from '../../axios';
import { getWizardPageContext } from '../../utils/wizardPageContexts';
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  XMarkIcon,
  ArrowsRightLeftIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline';

// Chat character configurations
const CHAT_CHARACTERS = {
  thomas: {
    name: 'Thomas',
    avatar: '/ai_avatars/oldman.png',
  },
  barbara: {
    name: 'Barbara',
    avatar: '/ai_avatars/oldwoman.png',
  },
  marie: {
    name: 'Marie',
    avatar: '/ai_avatars/youngwoman.png',
  },
  robert: {
    name: 'Robert',
    avatar: '/ai_avatars/youngman.png',
  }
};

const AIChat = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [userData, setUserData] = useState(null);
  const [wizardData, setWizardData] = useState(null);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const messagesEndRef = useRef(null);

  // Fetch user and wizard data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, wizardResponse] = await Promise.all([
          axios.get('/api/v1/live_data/user_data', {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          }),
          axios.get('/api/v1/live_data/user_data', {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          })
        ]);

        const userData = userResponse.data;
        setUserData(userData);
        setWizardData(userData.entity);

        // Simply set the character if it exists
        if (userData.ai_assistant && CHAT_CHARACTERS[userData.ai_assistant]) {
          setSelectedCharacter(userData.ai_assistant);
        } else {
          setSelectedCharacter(null);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // Auto-scroll to bottom of chat when new messages arrive
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // When the chat is first opened, show character selection if no character is selected
  useEffect(() => {
    if (isOpen && !selectedCharacter) {
      setMessages([]);
    }
  }, [isOpen, selectedCharacter]);

  // Get full route for context lookup and set previous route
  const fullRoute = location.pathname;
  const [previousRoute, setPreviousRoute] = useState(fullRoute);

  // Determine user name
  const getUserName = () =>
    userData ? (userData.first_name || userData.last_name || 'User') : 'User';

  const generateInitialResponse = async (character) => {
    setIsLoading(true);

    // Get page-specific context
    const pageContext = getWizardPageContext(fullRoute, wizardData);
    const userName = getUserName();

    try {
      const response = await axios.post(
        '/api/v1/mistral/chat',
        {
          prompt: 'Generate a very brief, direct greeting specific to this wizard page',
          user_name: userName,
          language: userData?.language || 'en',
          wizard: pageContext.wizardName,
          page_name: pageContext.pageName,
          page_description: pageContext.description,
          current_selection: pageContext.currentSelection,
          character: character,
          know_yourself_response: wizardData?.know_yourself_response,
          startup_wizard_response: wizardData?.startup_wizard_response,
          isms_response: wizardData?.isms_response,
          data_privacy_response: wizardData?.data_privacy_response
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
      );

      if (response.data && response.data.response) {
        setMessages([
          { role: 'assistant', content: response.data.response }
        ]);
      }
    } catch (error) {
      console.error('Error generating initial response:', error);
      setMessages([
        {
          role: 'assistant', content: userData?.language === 'de'
            ? 'Hallo. Benötigen Sie Hilfe?'
            : 'Hello. Do you need help?'
        }
      ]);
    }

    setIsLoading(false);
  };

  const generateWelcomeBackResponse = async (character) => {
    setIsLoading(true);

    // Get page-specific context
    const pageContext = getWizardPageContext(fullRoute, wizardData);
    const userName = getUserName();

    try {
      const response = await axios.post(
        '/api/v1/mistral/chat',
        {
          prompt: 'Generate a warm, personalized welcome back message that feels like continuing a previous conversation. Make it brief and contextual to the current wizard page.',
          user_name: userName,
          language: userData?.language || 'en',
          wizard: pageContext.wizardName,
          page_name: pageContext.pageName,
          page_description: pageContext.description,
          current_selection: pageContext.currentSelection,
          character: character,
          conversation_context: 'welcome_back',
          know_yourself_response: wizardData?.know_yourself_response,
          startup_wizard_response: wizardData?.startup_wizard_response,
          isms_response: wizardData?.isms_response,
          data_privacy_response: wizardData?.data_privacy_response
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
      );

      if (response.data && response.data.response) {
        setMessages([
          { role: 'assistant', content: response.data.response }
        ]);
      }
    } catch (error) {
      console.error('Error generating welcome back response:', error);
      setMessages([
        {
          role: 'assistant', 
          content: userData?.language === 'de'
            ? 'Willkommen zurück! Wie kann ich Ihnen heute helfen?'
            : 'Welcome back! How can I help you today?'
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const userMessage = { role: 'user', content: inputValue };
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setIsLoading(true);

    // Get page-specific context
    const pageContext = getWizardPageContext(fullRoute, wizardData);
    const userName = getUserName();

    try {
      const response = await axios.post(
        '/api/v1/mistral/chat',
        {
          prompt: inputValue,
          user_name: userName,
          language: userData?.language || 'en',
          wizard: pageContext.wizardName,
          page_name: pageContext.pageName,
          page_description: pageContext.description,
          current_selection: pageContext.currentSelection,
          character: selectedCharacter,
          conversation_history: messages.map(msg => `${msg.role}: ${msg.content}`).join('\n\n'),
          know_yourself_response: wizardData?.know_yourself_response,
          startup_wizard_response: wizardData?.startup_wizard_response,
          isms_response: wizardData?.isms_response,
          data_privacy_response: wizardData?.data_privacy_response
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
      );

      if (response.data && response.data.response) {
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: response.data.response
        }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: userData?.language === 'de'
          ? 'Entschuldigung, ich konnte die Anfrage nicht verarbeiten.'
          : 'Apologies, I couldn\'t process that request.'
      }]);
    }

    setIsLoading(false);
  };

// Generate page transition response when the wizard page changes
useEffect(() => {
  // Only trigger if chat is open, a character is selected, and we have wizard data
  // AND the route has actually changed
  if (isOpen && 
      selectedCharacter && 
      wizardData && 
      fullRoute !== previousRoute) {
    
    const pageContext = getWizardPageContext(fullRoute, wizardData);
  
    const generatePageTransitionResponse = async () => {
      setIsLoading(true);
  
      try {
        const response = await axios.post(
          '/api/v1/mistral/chat',
          {
            prompt: 'Provide a concise, contextual message about the current wizard page, focusing on the next steps or key considerations without a greeting.',
            user_name: getUserName(),
            language: userData?.language || 'en',
            wizard: pageContext.wizardName,
            page_name: pageContext.pageName,
            page_description: pageContext.description,
            current_selection: pageContext.currentSelection,
            character: selectedCharacter,
            conversation_context: 'page_transition',
            know_yourself_response: wizardData?.know_yourself_response,
            startup_wizard_response: wizardData?.startup_wizard_response,
            isms_response: wizardData?.isms_response,
            data_privacy_response: wizardData?.data_privacy_response
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          }
        );
  
        if (response.data && response.data.response) {
          setMessages(prev => [
            ...prev, 
            { role: 'assistant', content: response.data.response }
          ]);
        }
      } catch (error) {
        console.error('Error generating page transition response:', error);
      } finally {
        setIsLoading(false);
        
        // Update the previous route after the transition
        setPreviousRoute(fullRoute);
      }
    };
  
    // Only generate response if this is a different page from the last message
    if (messages.length === 0 || 
        messages[messages.length - 1].page !== pageContext.pageName) {
      generatePageTransitionResponse();
    }
  }
}, [fullRoute, isOpen, selectedCharacter, wizardData, previousRoute]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen && selectedCharacter) {
      generateWelcomeBackResponse(selectedCharacter);
    }
  };

  const selectCharacter = async (character) => {
    try {
      await axios.post('/api/v1/live_data/update_ai_assistant',
        { ai_assistant: character },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setSelectedCharacter(character);
      generateInitialResponse(character);
    } catch (error) {
      console.error('Full error:', error.response ? error.response.data : error);
      console.error('Error saving AI assistant selection:', error.message);
    }
  };

  const resetCharacterSelection = async () => {
    try {
      await axios.post('/api/v1/live_data/update_ai_assistant',
        { ai_assistant: null },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setSelectedCharacter(null);
      setMessages([]);
    } catch (error) {
      console.error('Error clearing AI assistant:', error);
    }
  };

  const toggleChatExpansion = () => {
    setIsChatExpanded(!isChatExpanded);
  };

  // Render character selection modal
  const renderCharacterSelection = () => (
    <div className="fixed top-14 right-4 z-50 w-80 bg-white rounded-lg shadow-xl border border-gray-300">
      <div className="p-4 bg-primary-color text-white rounded-t-lg flex justify-between items-center">
        <h3 className="font-semibold text-sm text-white">{t('Select AI Assistant')}</h3>
        <button onClick={toggleChat} className="text-white hover:text-gray-200">
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="p-4 grid grid-cols-2 gap-4">
        {Object.entries(CHAT_CHARACTERS).map(([key, character]) => (
          <button
            key={key}
            onClick={() => selectCharacter(key)}
            className="border-4 rounded-2xl border-white hover:border-primary-color transition-colors relative overflow-hidden"
          >
            <div className="relative w-full aspect-square">
              <img
                src={character.avatar}
                alt={character.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-1 left-0 right-0 flex justify-center">
                <span className="text-xs font-semibold bg-black/50 rounded-lg text-white px-3 py-1 shadow-sm">{character.name}</span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div className="fixed top-14 right-4 z-30">
      {/* Chat toggle button - will be hidden when chat is open */}
      {!isOpen && (
        <button
          onClick={toggleChat}
          className="ai-chat-button bg-primary-color hover:bg-primary-color/80 text-white rounded-full p-4 shadow-lg transition-all"
          aria-label={t('AI Assistant')}
        >
          <img src="/ux_icons/aichat.svg" alt="AI Chat" className="h-6 w-6" />
        </button>
      )}

      {/* Show character selection only if chat is open AND no character is selected */}
      {isOpen && !selectedCharacter && renderCharacterSelection()}

      {/* Always show chat window if a character is selected */}
      {isOpen && selectedCharacter && (
        <div
          className={`fixed top-14 right-4 z-40 bg-white rounded-lg shadow-xl border border-gray-300 flex flex-col transition-all duration-300 ease-in-out ${isChatExpanded ? 'w-[900px] h-[600px]' : 'w-96 h-96'
            }`}
        >
          {/* Chat header */}
          <div className="p-4 bg-primary-color text-white rounded-t-lg flex justify-between items-center">
            <div className="flex items-center relative group">
              <div className="relative">
                <img
                  src={CHAT_CHARACTERS[selectedCharacter].avatar}
                  alt={CHAT_CHARACTERS[selectedCharacter].name}
                  className="w-12 h-12 rounded-full mr-2 object-cover"
                />
                <button
                  onClick={resetCharacterSelection}
                  className="absolute top-0 right-0 -translate-y-1/4 translate-x-1/4 bg-gray-500/70 rounded-full p-1 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity"
                  title="Change AI Assistant"
                >
                  <ArrowsRightLeftIcon className="h-4 w-4 text-white" />
                </button>
              </div>
              <h3 className="font-semibold text-sm text-white">{CHAT_CHARACTERS[selectedCharacter].name}</h3>
            </div>
            <div className="flex items-center">
              <button
                onClick={toggleChatExpansion}
                className="mr-2 text-white hover:text-gray-200"
                title={isChatExpanded ? "Shrink Chat" : "Expand Chat"}
              >
                {isChatExpanded ? (
                  <ArrowsPointingInIcon className="h-5 w-5" />
                ) : (
                  <ArrowsPointingOutIcon className="h-5 w-5" />
                )}
              </button>
              <button onClick={toggleChat} className="text-white hover:text-gray-200">
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Chat messages */}
          <div className="flex-1 p-4 overflow-y-auto bg-gray-50 text-sm">
            {messages.length === 0 && isLoading ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-pulse flex space-x-2">
                  <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                  <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                  <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                </div>
              </div>
            ) : (
              messages.map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}
                >
                  <div
                    className={`inline-block p-3 rounded-lg text-sm ${message.role === 'user'
                      ? 'bg-secondary-color text-white rounded-br-none ai-message-bubble user'
                      : 'bg-white border border-gray-300 rounded-bl-none ai-message-bubble assistant'
                      }`}
                  >
                    {message.content}
                  </div>
                </div>
              ))
            )}
            {isLoading && messages.length > 0 && (
              <div className="text-left mb-4">
                <div className="inline-block p-3 bg-gray-100 rounded-lg rounded-bl-none text-sm">
                  <div className="animate-pulse flex space-x-2">
                    <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                    <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                    <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Chat input */}
          <div className="p-4 border-t border-gray-300">
            <div className="flex">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                placeholder={t('Type your message...')}
                className="flex-1 p-2 text-sm border border-gray-300 rounded-l-lg focus:outline-none focus:ring-0 focus:border-gray-300"
                disabled={isLoading}
              />
              <button
                onClick={handleSendMessage}
                disabled={isLoading || !inputValue.trim()}
                className={`p-2 rounded-r-lg text-sm ${isLoading || !inputValue.trim()
                  ? 'bg-gray-300 text-gray-500'
                  : 'bg-secondary-color text-white hover:bg-secondary-color/80'
                  }`}
              >
                <PaperAirplaneIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIChat;