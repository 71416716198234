// src/components/wizard_components/DataPrivacyWizard/DPIAStatus.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

const DPIAStatus = ({ status }) => {
  const { t } = useTranslation();

  // Status variations
  const statusVariants = {
    not_started: {
      icon: <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />,
      text: t('DataPrivacyWizard.dpia.status.notStarted'),
      color: 'bg-gray-100 text-gray-600'
    },
    incomplete: {
      icon: <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />,
      text: t('DataPrivacyWizard.dpia.status.incomplete'),
      color: 'bg-yellow-100 text-yellow-800'
    },
    complete: {
      icon: <CheckCircleIcon className="h-5 w-5 text-green-500" />,
      text: t('DataPrivacyWizard.dpia.status.complete'),
      color: 'bg-green-100 text-green-800'
    }
  };

  const statusInfo = statusVariants[status] || statusVariants.not_started;

  return (
    <div className={`px-3 py-1 flex items-center rounded-full ${statusInfo.color}`}>
      <span className="mr-1">
        {statusInfo.icon}
      </span>
      <span className="text-sm font-medium">
        {statusInfo.text}
      </span>
    </div>
  );
};

export default DPIAStatus;