// src/components/wizard_components/DataPrivacyWizard/DPIAIntroduction.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScaleIcon } from '@heroicons/react/24/solid';

const DPIAIntroduction = ({ processingActivity }) => {
    const { t } = useTranslation();

    // Determine the reason for DPIA - KEEP THIS UNCHANGED
    const getDPIAReasons = () => {
        if (!processingActivity) return [];
    
        const reasons = [];
    
        // High risk processing
        if (processingActivity.riskRating === 'High') {
            reasons.push(t('DataPrivacyWizard.dpia.reasons.highRisk'));
        }
    
        // Special category data
        const hasSpecialCategories = processingActivity.dataCategories?.some(cat => [
            'political_affiliations', 'religious_beliefs', 'sexual_orientation',
            'health_data', 'biometric_data', 'legal_criminal_records'
        ].includes(cat));
    
        if (hasSpecialCategories) {
            reasons.push(t('DataPrivacyWizard.dpia.reasons.specialCategories'));
        }
    
        // Systematic monitoring
        if (processingActivity.purpose?.toLowerCase().includes('monitor') ||
            processingActivity.description?.toLowerCase().includes('monitor')) {
            reasons.push(t('DataPrivacyWizard.dpia.reasons.systematicMonitoring'));
        }
    
        // Automated decision making
        if (processingActivity.purpose?.toLowerCase().includes('automat') ||
            processingActivity.description?.toLowerCase().includes('automat')) {
            reasons.push(t('DataPrivacyWizard.dpia.reasons.automatedDecisionMaking'));
        }
    
        // Manually set DPIA required
        if (reasons.length === 0 && processingActivity.dpiaRequired) {
            reasons.push(t('DataPrivacyWizard.dpia.reasons.manuallySet'));
        }
    
        return reasons;
    };

    // Convert snake_case to camelCase for translation keys
    const toCamelCase = (str) => {
        return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    };

    // Separate function to check if a category is special (for display/highlighting)
    const isSpecialDataCategory = (category) => {
        const specialCategories = [
            'politicalAffiliations', 'religiousBeliefs', 'sexualOrientation',
            'healthData', 'biometricData', 'legalCriminalRecords'
        ];
        
        return specialCategories.includes(toCamelCase(category));
    };

    return (
        <div>
            <div className="mb-6 bg-blue-50 rounded-lg p-4 shadow-sm">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ScaleIcon className="h-6 w-6 text-blue-800" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="text-base font-medium text-blue-800 mb-2 mt-0">
                            {t('DataPrivacyWizard.dpia.introduction.reason')}
                        </p>
                        <ul className="list-disc pl-5 text-sm text-blue-800">
                            {getDPIAReasons().map((reason, index) => (
                                <li key={index}>{reason}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mb-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.activityName')}
                            </p>
                            <p className="mt-1">{processingActivity.name}</p>
                        </div>

                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.purpose')}
                            </p>
                            <p className="mt-1">{processingActivity.purpose}</p>
                        </div>

                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.dataType')}
                            </p>
                            <p className="mt-1">{processingActivity.dataType}</p>
                        </div>

                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.processorRole')}
                            </p>
                            <p className="mt-1">{t(`DataPrivacyWizard.${processingActivity.processingRole}`)}</p>
                        </div>

                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.privacyClassification')}
                            </p>
                            <p className="mt-1">{t(`DataPrivacyWizard.${processingActivity.privacyClassification.toLowerCase()}`)}</p>
                        </div>

                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {t('DataPrivacyWizard.riskRating')}
                            </p>
                            <p className="mt-1">{t(`DataPrivacyWizard.${processingActivity.riskRating.toLowerCase()}`)}</p>
                        </div>
                    </div>

                    {/* ADD NEW DATA CATEGORIES SECTION */}
                    {processingActivity.dataCategories && processingActivity.dataCategories.length > 0 && (
                        <div className="mt-6 pt-4 border-t border-gray-200">
                            <p className="text-sm font-medium text-gray-500 mb-2">
                                {t('DataPrivacyWizard.dataCategoriesTitle')}
                            </p>
                            <div className="flex flex-wrap gap-2">
                                {processingActivity.dataCategories.map((category, index) => (
                                    <span 
                                        key={index}
                                        className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                                            isSpecialDataCategory(category) 
                                                ? 'bg-amber-100 text-amber-800' 
                                                : 'bg-gray-100 text-gray-800'
                                        }`}
                                    >
                                        {t(`DataPrivacyWizard.dataCategories.${toCamelCase(category)}`)}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DPIAIntroduction;