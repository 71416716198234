// src/pages/wizards/DataPrivacyWizard/components/RecipientsTable.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import RecipientRow from './RecipientRow';

const RecipientsTable = ({
  type,
  title,
  recipientsData,
  transfersData,
  formik,
  countries,
  gdprCountries,
  toggleDrawer,
  toggleRow,
  toggleEditMode,
  expandedRows,
  editingRows,
  isThirdCountry,
  processorRoles,
  dpaStatusOptions
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-900">
          {title}
        </h2>
      </div>

      {/* Help note for external recipients */}
      {type === 'external' && (
        <div className="mt-0 mb-6">
          <div className="bg-blue-50 rounded-lg border border-blue-200 p-4 flex">
            <InformationCircleIcon className="h-6 w-6 text-blue-500 flex-shrink-0" />
            <div className="ml-3">
              <h3 className="text-sm font-semibold text-blue-500">
                {t('DataPrivacyWizard.dpaAssistant')}
              </h3>
              <p className="mt-2 text-xs text-blue-800 mb-0">
                {t('DataPrivacyWizard.dpaAssistantText')}
              </p>
            </div>
          </div>
        </div>
      )}

      {!transfersData || transfersData.length === 0 ? (
        <div className="bg-white p-6 text-center rounded-lg border border-gray-300">
          <p className="text-gray-500">
            {t(`DataPrivacyWizard.no${type.charAt(0).toUpperCase() + type.slice(1)}RecipientsFound`)}
          </p>
        </div>
      ) : (
        <div className="bg-white shadow-sm border border-gray-100 rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('DataPrivacyWizard.recipient')}
                </th>

                {/* External-only column - Processor Role */}
                {type === 'external' && (
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('DataPrivacyWizard.processorRole')}
                  </th>
                )}

                {/* Common column - Country */}
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('DataPrivacyWizard.country')}
                </th>

                {/* Common column - Compliance Status */}
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('DataPrivacyWizard.complianceStatus')}
                </th>

                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transfersData.map((transfer, index) => {
                // Find matching recipient data
                const recipientData = recipientsData.find(r => r.id === transfer.id);
                if (!recipientData) return null;

                return (
                  <RecipientRow
                    key={transfer.id}
                    type={type}
                    transfer={transfer}
                    recipientData={recipientData}
                    index={index}
                    formik={formik}
                    isExpanded={expandedRows[type][transfer.id]}
                    isEditing={editingRows[type][transfer.id]}
                    toggleRow={() => toggleRow(type, transfer.id)}
                    toggleEditMode={() => toggleEditMode(type, transfer.id)}
                    countries={countries}
                    gdprCountries={gdprCountries}
                    isThirdCountry={isThirdCountry}
                    processorRoles={processorRoles}
                    dpaStatusOptions={dpaStatusOptions}
                    toggleDrawer={toggleDrawer}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RecipientsTable;