import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { RadioGroup } from '@headlessui/react';
import Notification from '../../../components/Notification';
import StartupWizardProgress from '../../../components/wizard_components/StartupWizardProgress';
import { useWizard } from '../../../components/wizard_components/WizardContext';
import ActionButtons from '../../../components/wizard_components/ActionButtons';
import DataStatusBanner from '../../../components/wizard_components/DataStatusBanner';
import useFetchData from '../../../components/wizard_components/FetchData';
import { saveData, saveAsDraft } from '../../../components/wizard_components/SaveData';
import LiveModeToggle from '../../../components/wizard_components/LiveModeToggle';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Formik, Form } from 'formik';
import {
  BOOLEAN_VALUES,
  convertUIToStorage,
  convertStorageToDocument,
  getOptionsForField
} from '../../../constants/translationMappings';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Debounce function to prevent excessive autosaves
function debounce(func, wait) {
  let timeout;

  function debounced(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const StartupWizardShareholdersAgreement = () => {
  const { wizardName, updateWizardData, liveMode, toggleLiveMode, wizardData } = useWizard();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({ show: false, type: '', message: '' });
  const { data, dataStatus, loading, error } = useFetchData(wizardName, 'shareholdersAgreement');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({ title: '', content: '' });

  const legalForm = wizardData?.companyDetails?.legalForm;

  const getInitialValues = (data, i18n) => {
    console.log('Incoming data:', data);

    const defaultValues = {
      stockOptionProgram: BOOLEAN_VALUES.NO,
      stockOptionPercentage: 5,
      shareholderBindingYears: 3,
      callOptionOnExit: BOOLEAN_VALUES.NO,
      controlInformationRights: BOOLEAN_VALUES.NO,
      shareholderInMarriage: BOOLEAN_VALUES.NO
    };

    if (!data) return defaultValues;

    // Helper function to convert "Ja"/"Nein" to YES/NO
    const convertBooleanValue = (value) => {
      if (value === "Ja" || value === "YES") return BOOLEAN_VALUES.YES;
      if (value === "Nein" || value === "NO") return BOOLEAN_VALUES.NO;
      return defaultValues.stockOptionProgram; // fallback to default
    };

    // Convert the stored values to display values
    const convertedValues = {
      ...defaultValues,
      stockOptionProgram: convertBooleanValue(data.stockOptionProgram),
      callOptionOnExit: convertBooleanValue(data.callOptionOnExit),
      controlInformationRights: convertBooleanValue(data.controlInformationRights),
      shareholderInMarriage: convertBooleanValue(data.shareholderInMarriage),
      // Keep numeric values as is
      stockOptionPercentage: Number(data.stockOptionPercentage || defaultValues.stockOptionPercentage),
      shareholderBindingYears: Number(data.shareholderBindingYears || defaultValues.shareholderBindingYears)
    };

    console.log('Converted values:', convertedValues);
    return convertedValues;
  };

  const processValuesForSave = (values) => {
    return {
      ...values,
      stockOptionProgram: convertUIToStorage(values.stockOptionProgram),
      callOptionOnExit: convertUIToStorage(values.callOptionOnExit),
      controlInformationRights: convertUIToStorage(values.controlInformationRights),
      shareholderInMarriage: convertUIToStorage(values.shareholderInMarriage),
      // Ensure numeric values are properly formatted
      stockOptionPercentage: Number(values.stockOptionPercentage),
      shareholderBindingYears: Number(values.shareholderBindingYears)
    };
  };

  const getBooleanOptions = () => getOptionsForField('boolean', i18n.language);

  const handleSave = async (values, { setSubmitting }) => {
    const processedValues = processValuesForSave(values);
    const result = await saveData(wizardName, 'shareholdersAgreement', processedValues);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Data saved successfully') });
      updateWizardData('shareholdersAgreement', processedValues, false);
      updateWizardData('shareholdersAgreement', processedValues, true);
      setTimeout(() => navigate('/startup-wizard/notaryAndBank'), 1000);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving data') });
    }
    setSubmitting(false);
  };

  const handleSaveAsDraft = async (values, { setSubmitting }) => {
    const processedValues = processValuesForSave(values);
    const result = await saveAsDraft(wizardName, 'shareholdersAgreement', processedValues, false);
    if (result.success) {
      setNotification({ show: true, type: 'success', message: t('Saved as draft successfully') });
      updateWizardData('shareholdersAgreement', processedValues, true);
    } else {
      setNotification({ show: true, type: 'error', message: t('Error saving as draft') });
    }
    setSubmitting(false);
  };

  const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
    // Convert values before saving
    const processValuesForSave = (values) => {
      return {
        ...values,
        stockOptionProgram: convertUIToStorage(values.stockOptionProgram),
        callOptionOnExit: convertUIToStorage(values.callOptionOnExit),
        controlInformationRights: convertUIToStorage(values.controlInformationRights),
        shareholderInMarriage: convertUIToStorage(values.shareholderInMarriage)
      };
    };

    // Auto-save draft function
    const autoSaveDraft = useCallback(async () => {
      if (liveMode && values) {
        const processedValues = processValuesForSave(values);
        const result = await saveAsDraft(wizardName, 'shareholdersAgreement', processedValues, true);
        if (result.success) {
          updateWizardData('shareholdersAgreement', processedValues, true);
        } else {
          console.error('Failed to save draft:', result.error);
        }
      }
    }, [liveMode, values, wizardName, updateWizardData]);

    // Debounce function to prevent excessive saving
    const debouncedAutoSaveRef = useRef();

    useEffect(() => {
      debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
    }, [autoSaveDraft]);

    // Trigger auto-save when liveMode and values change
    useEffect(() => {
      if (liveMode) {
        debouncedAutoSaveRef.current();
        return () => debouncedAutoSaveRef.current.cancel();
      }
    }, [liveMode, values]);

    return null;
  };

  const validationSchema = Yup.object({
    // Add validation rules here if needed
  });

  const toggleDrawer = (title, content) => {
    // If drawer is already open with this title, close it
    if (drawerOpen && drawerContent.title === title) {
      setDrawerOpen(false);
    } else {
      // Set drawer content
      setDrawerContent({
        title,
        content: `<div class="prose">${content}</div>`
      });
      // Open drawer
      setDrawerOpen(true);
    }
  };

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return <div>No data available</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <LiveModeToggle onToggle={toggleLiveMode} />
      <StartupWizardProgress />
      <DataStatusBanner status={dataStatus} />

      <div className="text-left mt-8">
        <h1 className="md:text-3xl text-2xl font-bold tracking-tight text-gray-900">
          {t('StartupWizard.shareholdersAgreement')}
        </h1>
        <p className="mt-4 text-base text-gray-600">
          {t('A shareholders’ agreement (Gesellschaftervereinbarung) is a private contract between the shareholders of a german company that supplements the company’s articles of association (Satzung). While the articles of association are a public document registered with the commercial register (Handelsregister), the shareholders’ agreement is a private document that allows shareholders to regulate internal matters more flexibly. It is not legally required, but it is a widely used instrument to define shareholders’ rights, duties, and relationships beyond the mandatory rules provided by the German GmbH Act (GmbHG) and AG Act (AktG).')}
        </p>
      </div>

      <Formik
        initialValues={getInitialValues(data, i18n)}
        validationSchema={validationSchema}
        onSubmit={handleSave}
        enableReinitialize={true}
      >
        {({ values, isSubmitting, setFieldValue, submitForm, setSubmitting }) => (
          <Form className="mt-12 space-y-8">
            {/* Include the AutoSave component here */}
            <AutoSave
              values={values}
              liveMode={liveMode}
              wizardName={wizardName}
              updateWizardData={updateWizardData}
            />

            {/* Question 1 */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Do you want to incentivize key personnel or advisors in the future by introducing a stock option program?')}
                </label>
                <button
                  type="button"
                  onClick={() => toggleDrawer(
                    t('Stock Option Program'),
                    t('A Virtual Stock Option Plan (VSOP), also known as Virtual Share Option Plan, is a form of employee incentive. It allows companies, particularly startups, to offer key employees or executives a form of "virtual equity" without issuing actual shares. Under a VSOP, participants are granted the right to receive a cash payout based on the hypothetical value of shares or the sale price of the company, aligning their interests with those of shareholders by linking rewards to company success.\n\nUnlike actual stock option plans where shares are issued, a VSOP is a virtual participation in the company\'s value, meaning no real shares or equity are transferred. This is beneficial for companies that want to incentivize employees without diluting ownership or complicating their shareholder structure.')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.stockOptionProgram}
                onChange={(value) => setFieldValue('stockOptionProgram', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-[#2A9D8F]' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Conditional Question - Stock Option Percentage */}
            {values.stockOptionProgram === BOOLEAN_VALUES.YES && (
              <div>
                <div className="flex items-center">
                  <label
                    htmlFor="stockOptionPercentage"
                    className="block text-lg font-medium text-gray-700"
                  >
                    {t('How many of the company shares shall be subject to the stock option program?')}
                  </label>
                  <button
                    type="button"
                    onClick={() => toggleDrawer(
                      t('Stock Option Percentage'),
                      t('More than 15% is not recommended as it makes investing in the organization unattractive for incoming shareholders.')
                    )}
                    className="ml-2 text-gray-400 hover:text-gray-500"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <input
                  type="range"
                  name="stockOptionPercentage"
                  id="stockOptionPercentage"
                  min="1"
                  max="30"
                  step="1"
                  value={values.stockOptionPercentage}
                  onChange={(e) => setFieldValue('stockOptionPercentage', e.target.value)}
                  className="mt-1 block w-full custom-range"
                />
                <div className="mt-2 text-sm text-gray-600">
                  {values.stockOptionPercentage}%
                  {values.stockOptionPercentage >= 16 && (
                    <span className="text-red-300"> ({t('Not Recommended')})</span>
                  )}
                </div>
              </div>
            )}

            {/* Question 2 */}
            <div>
              <div className="flex items-center">
                <label htmlFor="shareholderBindingYears" className="block text-lg font-medium text-gray-700">
                  {t('Would you like to bind the founding shareholders for a certain period of time?')}
                </label>
                <button
                  type="button"
                  onClick={() => toggleDrawer(
                    t('Shareholder Binding Period'),
                    t('Binding of the founders is desirable for investors to assure that the founding team remains stable for a certain period. If the founding shareholders are to be bound for a longer period, it is advisable to include such a provision. The term is usually 2-3 years (the founding phase).')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <input
                type="range"
                name="shareholderBindingYears"
                id="shareholderBindingYears"
                min="1"
                max="5"
                step="1"
                value={values.shareholderBindingYears}
                onChange={(e) => setFieldValue('shareholderBindingYears', e.target.value)}
                className="mt-1 block w-full custom-range"
              />
              <div className="mt-2 text-sm text-gray-600">{values.shareholderBindingYears} {t('year(s)')}</div>
            </div>

            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Do you want to protect yourselves with a call-option in case one of the founding members leaves within the first few years?')}
                </label>
              </div>
              <RadioGroup
                value={values.callOptionOnExit}
                onChange={(value) => setFieldValue('callOptionOnExit', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-[#2A9D8F]' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Question 4 */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Do you wish to include control and information rights?')}
                  {legalForm && (
                    <span className="text-sm text-gray-500 ml-2">
                      ({(legalForm === 'AG' && 'Recommended') || ((legalForm === 'GmbH' || legalForm === 'UG') && 'Not Recommended')})
                    </span>
                  )}
                </label>
                <button
                  type="button"
                  onClick={() => toggleDrawer(
                    t('Control and information rights'),
                    t('Control and information rights are important for shareholders to ensure transparency and oversight of the company. These rights allow shareholders to access information about the company’s financial situation, business activities, and decision-making processes. They also provide shareholders with the ability to influence key decisions and protect their interests. Control and information rights are particularly important for minority shareholders who may not have a significant say in the management of the company.')
                  )}
                  className="ml-2 text-gray-400 hover:text-gray-500"
                >
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <RadioGroup
                value={values.controlInformationRights}
                onChange={(value) => setFieldValue('controlInformationRights', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-[#2A9D8F]' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {option.label}
                              {legalForm && (
                                ((legalForm === 'AG' && option.value === BOOLEAN_VALUES.YES) ||
                                  ((legalForm === 'GmbH' || legalForm === 'UG') && option.value === BOOLEAN_VALUES.NO)) ? (
                                  <span className="text-sm text-gray-500 ml-2">({t('Recommended')})</span>
                                ) : null
                              )}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Question 5 - Shareholder in Marriage */}
            <div>
              <div className="flex items-center">
                <label className="block text-lg font-medium text-gray-700 mb-2">
                  {t('Does one or more of the shareholders live in a marriage?')}
                </label>
              </div>
              <RadioGroup
                value={values.shareholderInMarriage}
                onChange={(value) => setFieldValue('shareholderInMarriage', value)}
              >
                <div className="space-y-2">
                  {getBooleanOptions().map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option.value}
                      className={({ checked }) =>
                        classNames(
                          'relative border rounded-lg p-4 flex cursor-pointer focus:outline-none',
                          checked ? 'bg-[#2A9D8F]/10 border-[#2A9D8F]' : 'bg-white border-gray-200'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className={classNames(
                            checked ? 'bg-[#2A9D8F] border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-[#2A9D8F]' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}>
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-[#2A9D8F]' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {option.label}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <ActionButtons
              onCancel={() => navigate('/startup-wizard')}
              onDraft={() => handleSaveAsDraft(values, { setSubmitting })}
              onSubmit={submitForm}
              isSubmitting={isSubmitting}
              currentPage="shareholdersAgreement"
            />
          </Form>
        )}
      </Formik>

      <Notification
        show={notification.show}
        setShow={(show) => setNotification(prev => ({ ...prev, show }))}
        type={notification.type}
        message={notification.message}
      />

      <WizardDrawer
        isOpen={drawerOpen}
        title={drawerContent.title}
        content={drawerContent.content}
        onClose={() => setDrawerOpen(false)}
      />
    </div>
  );
};

export default StartupWizardShareholdersAgreement;

// AutoSave Component
const AutoSave = ({ values, liveMode, wizardName, updateWizardData }) => {
  // Auto-save draft function
  const autoSaveDraft = useCallback(async () => {
    if (liveMode && values) {
      const result = await saveAsDraft(wizardName, 'shareholdersAgreement', values, true);
      if (result.success) {
        updateWizardData('shareholdersAgreement', values, true);
      } else {
        console.error('Failed to save draft:', result.error);
      }
    }
  }, [liveMode, values, wizardName, updateWizardData]);

  // Debounce function to prevent excessive saving
  const debouncedAutoSaveRef = useRef();

  useEffect(() => {
    debouncedAutoSaveRef.current = debounce(autoSaveDraft, 1000);
  }, [autoSaveDraft]);

  // Trigger auto-save when liveMode and values change
  useEffect(() => {
    if (liveMode) {
      debouncedAutoSaveRef.current();
      return () => debouncedAutoSaveRef.current.cancel();
    }
  }, [liveMode, values]);

  return null;
};