import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import Notification from '../../../components/Notification';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import { TrashIcon, BuildingOfficeIcon, UserIcon, ExclamationTriangleIcon, CheckIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import axios from '../../../axios';
import OneSlider from '../../../components/wizard_components/OneSlider';
import DistributionCharts from '../../../components/wizard_components/DistributionCharts';
import WizardDrawer from '../../../components/wizard_components/WizardDrawer';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

const InvestmentInput = ({ field, form, shareholder, nominalShareValue }) => {
    const [isFocused, setIsFocused] = useState(false);

    // This effect sets an initial value if it's not already set
    useEffect(() => {
        if (!field.value && shareholder.shares) {
            form.setFieldValue(field.name, shareholder.shares * nominalShareValue);
        }
    }, [field.value, shareholder.shares, nominalShareValue, field.name, form]);

    return (
        <input
            {...field}
            type="number"
            step="1000"
            className="mt-1 block w-full rounded-none rounded-r-md border-gray-300 px-3 py-2 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
            value={isFocused ? field.value : (field.value ? new Intl.NumberFormat('de-DE').format(field.value) : '')}
            onFocus={(e) => {
                setIsFocused(true);
                // Optionally, you can set the raw value here if needed
            }}
            onBlur={(e) => {
                setIsFocused(false);
                field.onBlur(e);
                const numValue = Number(e.target.value);
                if (!isNaN(numValue)) {
                    form.setFieldValue(field.name, numValue);
                }
            }}
            onChange={(e) => {
                const value = e.target.value;
                form.setFieldValue(field.name, value === '' ? '' : Number(value));
            }}
        />
    );
};

const KnowYourselfWizardCaptable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/vesop');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');
    const [drawerContent, setDrawerContent] = useState({ isOpen: false, title: '', content: '' });
    const [initialValues, setInitialValues] = useState({
        shareCapital: 25000,
        nominalShareValue: 1,
        currentShareholders: [],
        targetedNewShareholderCount: 1,
        targetedShareholders: [],
        shareCurrency: 'EUR'
    });

    // Get funding rounds for the dropdown
    const fundingRounds = userData?.entity?.know_yourself_response?.draft?.fundingRounds?.fundingRounds ||
        userData?.entity?.know_yourself_response?.published?.fundingRounds?.fundingRounds || [];
    const [showFundingRound, setShowFundingRound] = useState(false);

    const [historyDataExists, setHistoryDataExists] = useState(false);
    // Add hasMinimumCapTable function
    const hasMinimumCapTable = (captable) => {
        if (!captable) return false;
        const { shareholders = [], transactions = [] } = captable;
        // We consider it valid if there are any shareholders and transactions
        return shareholders.length > 0 && transactions.length > 0;
    };

    useEffect(() => {
        if (!userData?.entity) return;

        // Check if captable history data exists
        const historyData = userData.entity.know_yourself_response?.published?.captablehistory ||
            userData.entity.know_yourself_response?.draft?.captablehistory;

        setHistoryDataExists(!!historyData && hasMinimumCapTable(historyData));
        console.log('History data exists:', historyDataExists);

        // First check KnowYourself data
        const { published, draft } = userData.entity.know_yourself_response || {};
        const relevantDraftData = draft?.captable || {};
        const relevantPublishedData = published?.captable || {};

        // Get startup wizard data
        const startupWizardData = userData.entity.startup_wizard_response?.published?.founders ||
            userData.entity.startup_wizard_response?.draft?.founders;

        // Define the base initial values for comparison
        const baseInitialValues = {
            shareCurrency: 'EUR',
            shareCapital: 25000,
            nominalShareValue: 1,
            currentShareholders: [],
            targetedNewShareholderCount: 1,
            targetedShareholders: [],
        };

        const isDefaultValue = (value, defaultValue) => {
            if (value === defaultValue) return true;
            if (Array.isArray(value) && Array.isArray(defaultValue)) {
                return value.length === defaultValue.length;
            }
            if (typeof value === 'object' && value !== null && typeof defaultValue === 'object') {
                return Object.keys(value).every(key =>
                    isDefaultValue(value[key], defaultValue[key])
                );
            }
            return false;
        };

        const isDataEmpty = (data) => {
            if (!data || Object.keys(data).length === 0) return true;

            return Object.keys(baseInitialValues).every(key =>
                isDefaultValue(data[key], baseInitialValues[key])
            );
        };

        // Compare drafts excluding empty arrays/objects
        const isDraftDifferent = JSON.stringify(relevantDraftData) !== JSON.stringify(relevantPublishedData);

        if (!isDataEmpty(relevantDraftData) && isDraftDifferent) {
            console.log('Setting captable status to draft');
            setDataStatus('draft');
            setInitialValues(relevantDraftData);
        } else if (!isDataEmpty(relevantPublishedData)) {
            console.log('Setting captable status to published');
            setDataStatus('published');
            setInitialValues(relevantPublishedData);
        } else if (startupWizardData?.founders) {
            console.log('Setting captable status to new with startup wizard data');
            setDataStatus('new');
            setInitialValues({
                shareCapital: startupWizardData.shareCapital || 25000,
                nominalShareValue: 1,
                currentShareholders: startupWizardData.founders.map(founder => ({
                    id: founder.id || Date.now(),
                    name: founder.type === 'natural' ? founder.fullName : founder.legalName,
                    type: founder.type,
                    fundingRound: 'Founding',
                    shares: Math.floor((founder.capitalInvested || 0) / 1),
                    investment: founder.capitalInvested || null
                })),
                targetedNewShareholderCount: 1,
                targetedShareholders: [],
                shareCurrency: userData?.entity?.know_yourself_response?.draft?.fundingRounds?.currency ||
                    userData?.entity?.know_yourself_response?.published?.fundingRounds?.currency ||
                    'EUR'
            });
        } else {
            console.log('Setting captable status to new with base values');
            setDataStatus('new');
            setInitialValues(baseInitialValues);
        }
    }, [userData]);

    const handleSyncWithHistory = async (e) => {
        e.preventDefault();

        try {
            const historyData = userData.entity.know_yourself_response?.published?.captablehistory ||
                userData.entity.know_yourself_response?.draft?.captablehistory;

            if (!historyData || !hasMinimumCapTable(historyData)) {
                throw new Error('No valid cap table history data found');
            }

            // Calculate total shares from transactions
            const getSharesForShareholder = (shareholderId) => {
                return historyData.transactions
                    .reduce((total, tx) => {
                        if (tx.buyerId === shareholderId) {
                            return total + tx.shares;
                        }
                        if (tx.sellerId === shareholderId) {
                            return total - tx.shares;
                        }
                        return total;
                    }, 0);
            };

            // First, get the total number of shares from the initial creation transaction
            const totalCreatedShares = historyData.transactions.find(tx => tx.type === 'created')?.shares || 25000;

            // Convert history data format to captable format
            const convertedData = {
                // Calculate share capital based on total shares and nominal value
                nominalShareValue: 1,
                shareCapital: totalCreatedShares, // This will effectively set Total Number of Shares correctly

                currentShareholders: historyData.shareholders
                    .map(sh => {
                        const currentShares = getSharesForShareholder(sh.id);
                        return {
                            id: sh.id,
                            name: sh.name,
                            type: sh.type,
                            fundingRound: sh.entryRound,
                            shares: currentShares > 0 ? currentShares : 0 // Ensure no negative shares
                        };
                    })
                    .filter(sh => sh.shares > 0), // Only include shareholders who still have shares
                targetedNewShareholderCount: 1,
                targetedShareholders: []
            };

            // Update local state without saving
            setInitialValues(convertedData);
            setDataStatus('draft');

            setNotification({
                show: true,
                type: 'success',
                message: t('Successfully synchronized with Cap Table History. Remember to save your changes.')
            });

        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Error synchronizing with Cap Table History'),
                errors: [error.message]
            });
        }
    };

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // Wrap the values in a 'captable' object to match the expected structure
            await handleSubmit({ responses: { captable: values } });
            setNotification({
                show: true,
                type: 'success',
                message: t('Data saved and published successfully!')
            });
            setDataStatus('published');

            // Also save as draft to maintain consistency
            await handleDraftSubmit({ responses: { captable: values } });

            // Only navigate after both operations are complete
            setTimeout(() => {
                navigate('/funding/vesop');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Error saving data'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            // Maintain the same nested structure for drafts
            await handleDraftSubmit({ responses: { captable: values } });
            setNotification({
                show: true,
                type: 'success',
                message: t('Draft saved successfully')
            });
            setDataStatus('draft');
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Error saving draft'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object({
        shareCapital: Yup.number().min(1).required(t('Share capital is required')),
        nominalShareValue: Yup.number().min(0.01).required(t('Nominal share value is required')),
        currentShareholders: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('Name is required')),
                type: Yup.string().oneOf(['natural', 'juridical']).required(),
                fundingRound: Yup.string().required(t('Funding round is required')),
                shares: Yup.number().min(1).required(t('Number of shares is required')),
                investment: Yup.number().min(0).required(t('Investment amount is required'))
            })
        ).test(
            'total-shares-match',
            t('Total shares in current cap table must match the share capital configuration'),
            function (currentShareholders) {
                const totalShares = Math.floor(this.parent.shareCapital / this.parent.nominalShareValue);
                const currentTotalShares = currentShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
                return currentTotalShares === totalShares;
            }
        ),
        targetedNewShareholderCount: Yup.number().min(1).max(20).required(),
        targetedShareholders: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('Name is required')),
                type: Yup.string().oneOf(['natural', 'juridical']).required(),
                fundingRound: Yup.string().required(t('Funding round is required')),
                shares: Yup.number().min(1).required(t('Number of shares is required'))
            })
        ).test(
            'target-shares-match',
            t('Target shares must match the required new shares for the target round'),
            function (targetedShareholders) {
                const totalShares = Math.floor(this.parent.shareCapital / this.parent.nominalShareValue);
                const totalTargetShares = targetedShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);

                // Get the required equity percentage from the funding round
                const fundingRounds = userData?.entity?.know_yourself_response?.draft?.fundingRounds?.fundingRounds ||
                    userData?.entity?.know_yourself_response?.published?.fundingRounds?.fundingRounds || [];
                const targetRoundObj = fundingRounds.find((round) => round.isTargetRound === true);
                const investmentAmount = targetRoundObj?.investmentAmount || 0;
                const preMoneyValuation = targetRoundObj?.preMoneyValuation || 0;
                const equityPercentage = (investmentAmount / (preMoneyValuation + investmentAmount)) * 100;
                const postMoneyShares = Math.floor(totalShares / (1 - equityPercentage / 100));
                const requiredNewShares = postMoneyShares - totalShares;

                return totalTargetShares === requiredNewShares;
            }
        )
    });

    const toggleDrawer = (title, content) => {
        // If drawer is already open with this title, close it
        if (drawerContent.isOpen && drawerContent.title === title) {
            setDrawerContent({
                ...drawerContent,
                isOpen: false
            });
        } else {
            // Open drawer with new content
            setDrawerContent({
                isOpen: true,
                title,
                content: `<div class="prose">${content}</div>`
            });
        }
    };

    const ShareholderTable = ({ shareholders, onDelete, setFieldValue, tableIndex, readOnly = false, totalShares, values, formatCurrency, isTargetTable = false, investmentAmount = 0, sharePrice }) => {
        const totalTargetShares = values.targetedShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);

        return (
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Type')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Name')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Shares')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Percentage')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {t('Investment')}
                            </th>
                            <th className="px-6 py-3 bg-gray-50"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {shareholders.map((shareholder, index) => (
                            <tr key={shareholder.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="inline-flex rounded-md shadow-sm">
                                        <button
                                            type="button"
                                            onClick={() => setFieldValue(`${tableIndex}.${index}.type`, 'natural')}
                                            className={`relative inline-flex items-center rounded-l-md focus:z-10 p-2
                                                ${shareholder.type === 'natural' ? 'bg-secondary-color text-white' : 'bg-white text-gray-900'} 
                                                ring-1 ring-inset ring-gray-300`}
                                            disabled={readOnly}
                                        >
                                            <UserIcon className="w-6 h-6" />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setFieldValue(`${tableIndex}.${index}.type`, 'juridical')}
                                            className={`relative -ml-px inline-flex items-center rounded-r-md focus:z-10 p-2
                                                ${shareholder.type === 'juridical' ? 'bg-secondary-color text-white' : 'bg-white text-gray-900'} 
                                                ring-1 ring-inset ring-gray-300`}
                                            disabled={readOnly}
                                        >
                                            <BuildingOfficeIcon className="w-6 h-6" />
                                        </button>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <Field
                                        name={`${tableIndex}.${index}.name`}
                                        className="mt-1 block w-full rounded-md border-gray-300"
                                        disabled={readOnly}
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <Field
                                        type="number"
                                        name={`${tableIndex}.${index}.shares`}
                                        className="mt-1 block w-full rounded-md border-gray-300"
                                        disabled={readOnly}
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {isTargetTable
                                        ? ((shareholder.shares / (totalShares + totalTargetShares)) * 100).toFixed(2)
                                        : ((shareholder.shares / totalShares) * 100).toFixed(2)}%
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {isTargetTable ? (
                                        formatCurrency(shareholder.shares * sharePrice)
                                    ) : (
                                        <div className="flex">
                                            <span className="mt-1 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-sm text-gray-500">
                                                {(() => {
                                                    const currency = userData?.entity?.know_yourself_response?.draft?.fundingRounds?.currency ||
                                                        userData?.entity?.know_yourself_response?.published?.fundingRounds?.currency || 'EUR';
                                                    switch (currency) {
                                                        case 'USD': return '$';
                                                        case 'EUR': return '€';
                                                        case 'JPY': return '¥';
                                                        case 'GBP': return '£';
                                                        case 'AUD': return 'A$';
                                                        case 'BRL': return 'R$';
                                                        case 'CAD': return 'C$';
                                                        case 'CHF': return 'Fr';
                                                        case 'CNY': return '¥';
                                                        case 'HKD': return 'HK$';
                                                        case 'INR': return '₹';
                                                        case 'KRW': return '₩';
                                                        case 'MXN': return 'Mex$';
                                                        case 'NOK': return 'kr';
                                                        case 'NZD': return 'NZ$';
                                                        case 'RUB': return '₽';
                                                        case 'SEK': return 'kr';
                                                        case 'SGD': return 'S$';
                                                        case 'TRY': return '₺';
                                                        case 'ZAR': return 'R';
                                                        default: return '€';
                                                    }
                                                })()}
                                            </span>
                                            <Field name={`${tableIndex}.${index}.investment`}>
                                                {({ field, form }) => (
                                                    <InvestmentInput
                                                        field={field}
                                                        form={form}
                                                        shareholder={shareholder}
                                                        nominalShareValue={values.nominalShareValue}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {!readOnly && (
                                        <button
                                            type="button"
                                            onClick={() => onDelete(index)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            <TrashIcon className="w-5 h-5" />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
                <Notification
                    show={notification.show}
                    setShow={(show) => setNotification({ ...notification, show })}
                    type={notification.type}
                    message={notification.message}
                    errors={notification.errors}
                />

                <Progress />

                <div className="data-status-banner mt-4 mb-4">
                    {dataStatus === 'published' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t('This is the last saved and published version.')}
                        </span>
                    )}
                    {dataStatus === 'draft' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                        </span>
                    )}
                    {dataStatus === 'new' && (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                                <circle r={3} cx={3} cy={3} />
                            </svg>
                            {t('There is no prior version of this page. Please manually save or save as draft below.')}
                        </span>
                    )}
                </div>

                <h2 className="text-primary-color">{t('KnowYourselfWizard.shareDistribution')}</h2>
                <p className="mb-8">{t('KnowYourselfWizard.captableIntro')}</p>

                {/* Cap Table Recommendations */}
                <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setRecommendationsOpen(!recommendationsOpen);
                        }}
                        className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                    >
                        <div className="flex items-center">
                            <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                            <span className="text-primary-color">{t('Recommendations')}</span>
                        </div>
                        <svg
                            className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                    {recommendationsOpen && (
                        <div className="p-4 bg-white rounded-b-lg border-t">
                            <div className="space-y-6 text-gray-700">
                                {/* Current Cap Table Section */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('captableRecommendations.currentCapTable.title')}
                                    </h4>
                                    {t('captableRecommendations.currentCapTable.paragraphs', { returnObjects: true }).map((p, i) => (
                                        <p key={i} className="text-sm">{p}</p>
                                    ))}
                                </div>
                                {/* Planning for New Investors Section */}
                                <div>
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {t('captableRecommendations.targetedShareholders.title')}
                                    </h4>
                                    {t('captableRecommendations.targetedShareholders.paragraphs', { returnObjects: true }).map((p, i) => (
                                        <p key={i} className="text-sm">{p}</p>
                                    ))}
                                    <div className="pt-2">
                                        <h5 className="text-sm font-semibold text-gray-600">
                                            {t('captableRecommendations.targetedShareholders.prosAndCons.title')}
                                        </h5>
                                        <ul className="list-disc list-inside text-sm pt-2">
                                            {t('captableRecommendations.targetedShareholders.prosAndCons.items', { returnObjects: true }).map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="pt-2">
                                        <p className="text-sm">
                                            {t('captableRecommendations.targetedShareholders.conclusion')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="space-y-8">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {({ values, setFieldValue, isSubmitting, validateForm }) => {
                            const handleSubmit = async (e) => {
                                e.preventDefault();
                                const errors = await validateForm(values);

                                if (Object.keys(errors).length === 0) {
                                    onSubmit(values, { setSubmitting: () => { } });
                                } else {
                                    const errorMessages = [];

                                    // Handle share capital and nominal value errors
                                    if (errors.shareCapital) {
                                        errorMessages.push(`${t('KnowYourselfWizard.shareCapital')}: ${errors.shareCapital}`);
                                    }
                                    if (errors.nominalShareValue) {
                                        errorMessages.push(`${t('KnowYourselfWizard.nominalShareValue')}: ${errors.nominalShareValue}`);
                                    }

                                    // Handle current shareholders errors
                                    if (errors.currentShareholders) {
                                        if (typeof errors.currentShareholders === 'string') {
                                            // This is our custom total shares validation error
                                            errorMessages.push(t('KnowYourselfWizard.currentShareholdersTotal'));
                                        } else {
                                            errors.currentShareholders.forEach((shareholderError, index) => {
                                                if (typeof shareholderError === 'object') {
                                                    Object.entries(shareholderError).forEach(([field, message]) => {
                                                        const shareholderName = values.currentShareholders[index]?.name || `${t('KnowYourselfWizard.shareholder')} ${index + 1}`;
                                                        const fieldTranslations = {
                                                            name: t('KnowYourselfWizard.name'),
                                                            shares: t('KnowYourselfWizard.shares'),
                                                            type: t('KnowYourselfWizard.type'),
                                                            fundingRound: t('KnowYourselfWizard.fundingRound')
                                                        };
                                                        errorMessages.push(`${shareholderName} - ${fieldTranslations[field]}: ${message}`);
                                                    });
                                                }
                                            });
                                        }
                                    }

                                    // Handle targeted shareholders errors
                                    if (errors.targetedShareholders) {
                                        if (typeof errors.targetedShareholders === 'string') {
                                            // This is our custom target shares validation error
                                            errorMessages.push(t('KnowYourselfWizard.targetedShareholdersTotal'));
                                        } else {
                                            errors.targetedShareholders.forEach((shareholderError, index) => {
                                                if (typeof shareholderError === 'object') {
                                                    Object.entries(shareholderError).forEach(([field, message]) => {
                                                        const shareholderName = values.targetedShareholders[index]?.name || `${t('KnowYourselfWizard.targetShareholder')} ${index + 1}`;
                                                        const fieldTranslations = {
                                                            name: t('KnowYourselfWizard.name'),
                                                            shares: t('KnowYourselfWizard.shares'),
                                                            type: t('KnowYourselfWizard.type'),
                                                            fundingRound: t('KnowYourselfWizard.fundingRound')
                                                        };
                                                        errorMessages.push(`${shareholderName} - ${fieldTranslations[field]}: ${message}`);
                                                    });
                                                }
                                            });
                                        }
                                    }

                                    setNotification({
                                        show: true,
                                        type: 'error',
                                        message: t('Failed to save data due to validation errors:'),
                                        errors: errorMessages
                                    });
                                }
                            };

                            const fundingRounds = userData?.entity?.know_yourself_response?.draft?.fundingRounds?.fundingRounds ||
                                userData?.entity?.know_yourself_response?.published?.fundingRounds?.fundingRounds || [];
                            const targetRoundObj = fundingRounds.find((round) => round.isTargetRound === true);
                            const targetRound = targetRoundObj?.type || '';
                            const preMoneyValuation = targetRoundObj?.preMoneyValuation || 0;
                            const investmentAmount = targetRoundObj?.investmentAmount || 0;
                            const totalShares = Math.floor(values.shareCapital / values.nominalShareValue);
                            const totalTargetShares = values.targetedShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);
                            const equityPercentage = (investmentAmount / (preMoneyValuation + investmentAmount)) * 100;
                            const postMoneyShares = Math.floor(totalShares / (1 - equityPercentage / 100));
                            const requiredNewShares = postMoneyShares - totalShares;
                            const totalPostMoneyShares = totalShares + requiredNewShares;
                            const postMoneyValuation = preMoneyValuation + investmentAmount;
                            const sharePrice = postMoneyValuation / totalPostMoneyShares;

                            // Add a format currency helper function
                            const formatCurrency = (value) => {
                                const currency = userData?.entity?.know_yourself_response?.draft?.fundingRounds?.currency ||
                                    userData?.entity?.know_yourself_response?.published?.fundingRounds?.currency || 'EUR';
                                return new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: currency
                                }).format(value);
                            };

                            const calculateEquitableShares = (count) => {
                                const equityPercentage = (investmentAmount / (preMoneyValuation + investmentAmount)) * 100;
                                const targetSharesNeeded = Math.floor(totalShares / (1 - equityPercentage / 100) - totalShares);
                                const sharesPerShareholder = Math.floor(targetSharesNeeded / count);
                                const remainder = targetSharesNeeded - (sharesPerShareholder * count);

                                return Array(count).fill(null).map((_, index) => ({
                                    id: `target-${Date.now()}-${Math.random().toString(36).substr(2, 9)}-${index}`,
                                    type: 'natural',
                                    name: '',
                                    fundingRound: targetRound,
                                    shares: sharesPerShareholder + (index === 0 ? remainder : 0)
                                }));
                            };

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <div className="space-y-8">
                                        {/* Share Capital Configuration */}
                                        <div className="bg-white p-6 rounded-lg shadow">
                                            <h2 className="text-xl font-bold mb-2">{t('Share Capital Configuration')}</h2>
                                            <label htmlFor="shareCurrency" className="block text-sm font-medium text-gray-700 mb-1">
                                                <div className="flex items-center">
                                                    {t('Select official currency of your share capital')}
                                                    <button
                                                        type="button"
                                                        onClick={() => toggleDrawer(
                                                            t('OfficialCurrencyTitle'),
                                                            t('OfficialCurrencyText')
                                                        )}
                                                        className="ml-2 text-gray-400 hover:text-gray-500"
                                                    >
                                                        <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </label>
                                            <select
                                                name="shareCurrency"
                                                value={values.shareCurrency}
                                                onChange={(e) => setFieldValue('shareCurrency', e.target.value)}
                                                className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                                            >
                                                <option value="USD">💵 USD - US Dollar</option>
                                                <option value="EUR">💶 EUR - Euro</option>
                                                <option value="JPY">💴 JPY - Japanese Yen</option>
                                                <option value="GBP">💷 GBP - British Pound</option>

                                                <option disabled>──────────</option> {/* Divider */}

                                                <option value="AUD">AUD - Australian Dollar</option>
                                                <option value="BRL">BRL - Brazilian Real</option>
                                                <option value="CAD">CAD - Canadian Dollar</option>
                                                <option value="CHF">CHF - Swiss Franc</option>
                                                <option value="CNY">CNY - Chinese Yuan/Renminbi</option>
                                                <option value="HKD">HKD - Hong Kong Dollar</option>
                                                <option value="INR">INR - Indian Rupee</option>
                                                <option value="KRW">KRW - South Korean Won</option>
                                                <option value="MXN">MXN - Mexican Peso</option>
                                                <option value="NOK">NOK - Norwegian Krone</option>
                                                <option value="NZD">NZD - New Zealand Dollar</option>
                                                <option value="RUB">RUB - Russian Ruble</option>
                                                <option value="SEK">SEK - Swedish Krona</option>
                                                <option value="SGD">SGD - Singapore Dollar</option>
                                                <option value="TRY">TRY - Turkish Lira</option>
                                                <option value="ZAR">ZAR - South African Rand</option>
                                            </select>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        {t('Share Capital')}
                                                    </label>
                                                    <div className="flex">
                                                        <span className="mt-1 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-sm text-gray-500">
                                                            {(() => {
                                                                const currency = values.shareCurrency || 'EUR';
                                                                switch (currency) {
                                                                    case 'USD': return '$';
                                                                    case 'EUR': return '€';
                                                                    case 'JPY': return '¥';
                                                                    case 'GBP': return '£';
                                                                    case 'AUD': return 'A$';
                                                                    case 'BRL': return 'R$';
                                                                    case 'CAD': return 'C$';
                                                                    case 'CHF': return 'Fr';
                                                                    case 'CNY': return '¥';
                                                                    case 'HKD': return 'HK$';
                                                                    case 'INR': return '₹';
                                                                    case 'KRW': return '₩';
                                                                    case 'MXN': return 'Mex$';
                                                                    case 'NOK': return 'kr';
                                                                    case 'NZD': return 'NZ$';
                                                                    case 'RUB': return '₽';
                                                                    case 'SEK': return 'kr';
                                                                    case 'SGD': return 'S$';
                                                                    case 'TRY': return '₺';
                                                                    case 'ZAR': return 'R';
                                                                    default: return '€';
                                                                }
                                                            })()}
                                                        </span>
                                                        <Field
                                                            type="number"
                                                            name="shareCapital"
                                                            className="mt-1 block w-full rounded-none rounded-r-md border-gray-300 px-3 py-2 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        {t('Nominal Share Value')}
                                                    </label>
                                                    <div className="flex">
                                                        <span className="mt-1 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-sm text-gray-500">
                                                            {(() => {
                                                                const currency = values.shareCurrency || 'EUR';
                                                                switch (currency) {
                                                                    case 'USD': return '$';
                                                                    case 'EUR': return '€';
                                                                    case 'JPY': return '¥';
                                                                    case 'GBP': return '£';
                                                                    case 'AUD': return 'A$';
                                                                    case 'BRL': return 'R$';
                                                                    case 'CAD': return 'C$';
                                                                    case 'CHF': return 'Fr';
                                                                    case 'CNY': return '¥';
                                                                    case 'HKD': return 'HK$';
                                                                    case 'INR': return '₹';
                                                                    case 'KRW': return '₩';
                                                                    case 'MXN': return 'Mex$';
                                                                    case 'NOK': return 'kr';
                                                                    case 'NZD': return 'NZ$';
                                                                    case 'RUB': return '₽';
                                                                    case 'SEK': return 'kr';
                                                                    case 'SGD': return 'S$';
                                                                    case 'TRY': return '₺';
                                                                    case 'ZAR': return 'R';
                                                                    default: return '€';
                                                                }
                                                            })()}
                                                        </span>
                                                        <Field
                                                            type="number"
                                                            name="nominalShareValue"
                                                            step="0.01"
                                                            className="mt-1 block w-full rounded-none rounded-r-md border-gray-300 px-3 py-2 text-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        {t('Total Number of Shares')}
                                                    </label>
                                                    <div className="mt-1 block w-full rounded-md border border-gray-300 bg-gray-50 text-sm px-3 py-2">
                                                        {totalShares}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Current Cap Table */}
                                        <div className="bg-white p-6 rounded-lg shadow">
                                            <div className="flex justify-between items-center mb-4">
                                                <h2 className="text-xl font-bold">{t('Current Cap Table')}</h2>
                                                {historyDataExists && (
                                                    <button
                                                        onClick={(e) => handleSyncWithHistory(e)}
                                                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-color hover:bg-secondary-color/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-color transition-colors duration-200 group"
                                                    >
                                                        <ArrowPathIcon className="mr-2 h-4 w-4 transition-transform duration-700 ease-in-out group-hover:rotate-360" />
                                                        {t('Sync with Cap Table History')}
                                                    </button>
                                                )}
                                            </div>
                                            <ShareholderTable
                                                shareholders={values.currentShareholders}
                                                onDelete={(index) => {
                                                    const newShareholders = [...values.currentShareholders];
                                                    newShareholders.splice(index, 1);
                                                    setFieldValue('currentShareholders', newShareholders);
                                                }}
                                                setFieldValue={setFieldValue}
                                                tableIndex="currentShareholders"
                                                totalShares={totalShares}
                                                values={values}
                                                formatCurrency={formatCurrency}
                                                isTargetTable={false}
                                                investmentAmount={investmentAmount}
                                                sharePrice={sharePrice}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const newShareholder = {
                                                        id: `current-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
                                                        type: 'natural',
                                                        name: '',
                                                        fundingRound: 'Founding',
                                                        shares: 0
                                                    };
                                                    setFieldValue('currentShareholders', [...values.currentShareholders, newShareholder]);
                                                }}
                                                className="mt-4 px-4 py-2 bg-secondary-color text-white rounded hover:bg-secondary-color/80"
                                            >
                                                {t('Add Shareholder')}
                                            </button>
                                            {/* Share Allocation Warning */}
                                            {(() => {
                                                const totalShares = Math.floor(values.shareCapital / values.nominalShareValue);
                                                const currentTotalShares = values.currentShareholders.reduce((sum, s) => sum + (s.shares || 0), 0);

                                                if (currentTotalShares === totalShares) {
                                                    return (
                                                        <div className="mt-4 rounded-md bg-green-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-green-800">
                                                                        {t('Shares correctly allocated')}
                                                                    </h3>
                                                                    <div className="mt-2 text-sm text-green-700">
                                                                        <p>
                                                                            {t('All {{shares}} current shares have been correctly allocated', {
                                                                                shares: currentTotalShares
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <div className="mt-4 rounded-md bg-red-50 p-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">
                                                                    {t('Incorrect share allocation')}
                                                                </h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <p>
                                                                        {currentTotalShares < totalShares ?
                                                                            t('{{remaining}} current shares still need to be allocated to match share capital', {
                                                                                remaining: totalShares - currentTotalShares
                                                                            }) :
                                                                            t('{{excess}} current shares need to be removed to match share capital', {
                                                                                excess: currentTotalShares - totalShares
                                                                            })
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>

                                        {/* Target Cap Table */}
                                        <div className="bg-white p-6 rounded-lg shadow">
                                            <h2 className="text-xl font-bold mb-4">{t('Target Cap Table')}</h2>
                                            <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Target Round')}:</span>
                                                        <span className="ml-2">{targetRound || '-'}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Pre-Money Valuation')}:</span>
                                                        <span className="ml-2">{formatCurrency(preMoneyValuation)}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Investment Sought')}:</span>
                                                        <span className="ml-2">{formatCurrency(investmentAmount)}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Post-Money Valuation')}:</span>
                                                        <span className="ml-2">{formatCurrency(preMoneyValuation + investmentAmount)}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Equity Offered')}:</span>
                                                        <span className="ml-2">{((investmentAmount / (preMoneyValuation + investmentAmount)) * 100).toFixed(2)}%</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-sm font-medium text-gray-500">{t('Price per Share')}:</span>
                                                        <span className="ml-2">{formatCurrency(sharePrice)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                                    {t('How many new shareholders do you pursue in this target round?')}
                                                </label>
                                                <OneSlider
                                                    name="targetedNewShareholderCount"
                                                    leftLabel={`${values.targetedNewShareholderCount} ${t('new shareholders')}`}
                                                    rightLabel=""
                                                    min={1}
                                                    max={20}
                                                    step={1}
                                                    value={values.targetedNewShareholderCount}
                                                    onChange={(value) => {
                                                        const count = value;
                                                        setFieldValue('targetedNewShareholderCount', count);
                                                        const newShareholders = calculateEquitableShares(count);
                                                        setFieldValue('targetedShareholders', newShareholders);
                                                    }}
                                                />
                                            </div>

                                            <ShareholderTable
                                                shareholders={values.targetedShareholders}
                                                onDelete={(index) => {
                                                    const newShareholders = [...values.targetedShareholders];
                                                    newShareholders.splice(index, 1);
                                                    setFieldValue('targetedShareholders', newShareholders);
                                                    setFieldValue('targetedNewShareholderCount', newShareholders.length);
                                                }}
                                                setFieldValue={setFieldValue}
                                                tableIndex="targetedShareholders"
                                                showFundingRound={false}
                                                totalShares={totalShares}
                                                values={values}
                                                formatCurrency={formatCurrency}
                                                isTargetTable={true}
                                                investmentAmount={investmentAmount}
                                                sharePrice={sharePrice}
                                            />

                                            {/* Share Distribution Warning */}
                                            {(() => {
                                                const postMoneyShares = Math.floor(totalShares / (1 - equityPercentage / 100));
                                                const requiredNewShares = postMoneyShares - totalShares;

                                                if (totalTargetShares === requiredNewShares) {
                                                    return (
                                                        <div className="rounded-md bg-green-50 p-4 mt-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-green-800">
                                                                        {t('Shares correctly allocated')}
                                                                    </h3>
                                                                    <div className="mt-2 text-sm text-green-700">
                                                                        <p>
                                                                            {t('All {{shares}} new shares have been correctly allocated', {
                                                                                shares: totalTargetShares
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                if (totalTargetShares < requiredNewShares) {
                                                    return (
                                                        <div className="rounded-md bg-yellow-50 p-4 mt-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">
                                                                        {t('Shares not fully allocated')}
                                                                    </h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {t('{{remaining}} new shares still need to be allocated.', {
                                                                                remaining: requiredNewShares - totalTargetShares
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <div className="rounded-md bg-red-50 p-4 mt-4">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h3 className="text-sm font-medium text-red-800">
                                                                    {t('Too many shares allocated')}
                                                                </h3>
                                                                <div className="mt-2 text-sm text-red-700">
                                                                    <p>
                                                                        {t('{{excess}} new shares need to be removed.', {
                                                                            excess: totalTargetShares - requiredNewShares
                                                                        })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>

                                        {/* Summary Section */}
                                        <div className="bg-white p-6 rounded-lg shadow">
                                            <h2 className="text-xl font-bold mb-4">{t('Summary')}</h2>

                                            <DistributionCharts
                                                currentShareholders={values.currentShareholders}
                                                targetedShareholders={values.targetedShareholders}
                                                totalShares={totalShares}
                                                t={t}
                                            />

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                                                <div>
                                                    <div className="space-y-2 text-center">
                                                        <p>
                                                            {t('Total Shareholders')}: {values.currentShareholders.length}
                                                        </p>
                                                        <p>
                                                            {t('Total Shares')}: {values.currentShareholders.reduce((sum, s) => sum + s.shares, 0)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="space-y-2 text-center">
                                                        <p>
                                                            {t('Total Shareholders')}: {values.currentShareholders.length + values.targetedShareholders.length}
                                                        </p>
                                                        <p>
                                                            {t('Total Shares')}: {values.currentShareholders.reduce((sum, s) => sum + s.shares, 0) + values.targetedShareholders.reduce((sum, s) => sum + s.shares, 0)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="flex justify-end gap-x-4">
                                            <button
                                                type="button"
                                                onClick={() => navigate('/funding/')}
                                                className="text-sm text-gray-900"
                                            >
                                                {t('Cancel')}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                                disabled={isSubmitting}
                                                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600"
                                            >
                                                {t('Save as Draft')}
                                            </button>
                                            <button
                                                type="submit"  // Just leave this as type="submit"
                                                disabled={isSubmitting}
                                                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color"
                                            >
                                                {isSubmitting ? t('Saving...') : t('Save')}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <WizardDrawer
                isOpen={drawerContent.isOpen}
                onClose={() => setDrawerContent(prev => ({ ...prev, isOpen: false }))}
                title={drawerContent.title}
                content={drawerContent.content}
            />
        </>
    );
};

export default KnowYourselfWizardCaptable;