import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  RocketLaunchIcon,
  ClockIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  InformationCircleIcon,
  UserGroupIcon,
  ChartBarIcon,
  BuildingOffice2Icon,
  ChatBubbleBottomCenterTextIcon,
  FireIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import MarketMap from './MarketMap';
import { countryData } from '../constants/countryData';
import DistributionCharts from '../components/wizard_components/DistributionCharts';
import CashflowChart from '../components/wizard_components/CashFlowChart';
import KnowYourselfWizardValuationDevelopment from './KnowYourselfWizardValuationDevelopment';

const HeroIcons = {
  RocketLaunchIcon,
  ClockIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  FireIcon
};

const stageColors = {
  'Product/Services in development': 'bg-orange-100 text-orange-800',
  'Proof of concept for Product/Services launched': 'bg-yellow-100 text-yellow-800',
  'Product/Services fully on-the-market': 'bg-green-100 text-green-800',
};

const KnowYourselfOverviewDisplay = ({ data, generalInfo, pages, logoUrl }) => {
  const { t } = useTranslation();
  const [logoPreview, setLogoPreview] = useState(null);

  useEffect(() => {
    if (logoUrl) {
        setLogoPreview(logoUrl);
    }
}, [logoUrl]);

  const formatCurrency = (amount, currency) => {
    if (!amount) return t('Not specified');
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const renderProducts = (products) => {
    if (!products || products.length === 0) return t('Not specified');
    return (
      <div className="space-y-6">
        {products.map((product, index) => (
          <div key={index} className="bg-white rounded-lg p-4 border border-gray-200">
            <h4 className="text-lg font-semibold mb-2">{product.name}</h4>
            <div className="flex items-center gap-2 mt-2">
              {product.devStage && (
                <span
                  className={`inline-flex items-center gap-1.5 rounded-md px-2 py-0.5 text-xs font-medium ${stageColors[product.devStage] || 'bg-gray-100 text-gray-800'
                    }`}
                >
                  <CheckCircleIcon className="h-4 w-4" aria-hidden="true" />
                  {t(`KnowYourselfWizard.${product.devStage}`)}
                </span>
              )}

              {product.month && product.year && (
                <span className="inline-flex items-center gap-1.5 rounded-md bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-800">
                  📅 Release: {`${t(product.month)} ${product.year}`}
                </span>
              )}
            </div>
            <p className="text-sm text-gray-700">
              {product.description}
            </p>
            {product.usp && product.usp.length > 0 && (
              <div className="mt-2">
                <p className="text-sm text-gray-600">
                  {t('KnowYourselfWizard.Its USPs are')} {product.usp.map(usp => t(`KnowYourselfWizard.${usp}`)).join(', ')}.
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const getResponsibilitiesForPerson = (person) => {
    return Object.entries(data.responsibilities || {})
      .filter(([_, responsible]) => responsible === `${person.firstName} ${person.lastName}`)
      .map(([resp]) => t(`${resp}`));
  };

  const renderTeamMembers = () => {
    const allMembers = [
      ...(data.founders || []).map(f => ({ ...f, isFounder: true })),
      ...(data.keyTeamMembers || [])
    ];

    if (allMembers.length === 0) return t('Not specified');

    const totalEmployees = (data.offices || []).reduce((sum, office) => sum + (office.employees || 0), 0);
    const officeCount = (data.offices || []).length - 1;

    return (
      <div className="space-y-6">
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
          {allMembers.map((member, index) => {
            const responsibilities = getResponsibilitiesForPerson(member);
            return (
              <div key={index} className="bg-white rounded-lg p-4 border border-gray-200">
                <div className="flex items-start">
                  <div className="flex-1">
                    <h4 className="text-lg font-semibold">
                      {member.firstName} {member.lastName}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {member.role}
                      {member.yearInCompany && <span className="text-gray-500"> ({t('since')} {member.yearInCompany})</span>}
                      {member.isFounder && <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">{t('Founder')}</span>}
                    </p>
                    {responsibilities.length > 0 && (
                      <p className="mt-2 text-sm text-gray-600">
                        {t('Responsible for')}: {responsibilities.join(', ')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-4 bg-gray-50 rounded-lg p-4">
          <p className="text-sm text-gray-600">
            {t('The full team consists of {{totalEmployees}} employees and is spread across {{officeCount}} offices.', { totalEmployees, officeCount })}
          </p>
        </div>
      </div>
    );
  };

  const renderCashflowSection = (data) => {
    if (!data.fundingRounds?.cashflow) return null;

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t('Cashflow Overview')}</dt>
        <dd className="mt-1 text-sm text-gray-700 sm:col-span-4 sm:mt-0">
          <CashflowChart
            data={data.fundingRounds.cashflow}
            currency={data.fundingCurrency || 'EUR'}
          />
        </dd>
      </div>
    );
  };

  const renderNextFundingSection = (data) => {
    const {
      targetFundingRound,
      planningHorizon,
      avgCashBurn,
      capitalRequired,
      fundingCurrency,
      fundingTime,
      investmentNotes,
      captable = {}
    } = data;

    const fundingDetails = [
      {
        icon: "RocketLaunchIcon",
        label: t('Target Funding Round'),
        value: targetFundingRound
      },
      {
        icon: "ClockIcon",
        label: t('Planning Horizon'),
        value: `${planningHorizon} ${t('months')}`
      },
      {
        icon: "FireIcon",
        label: t('Average Monthly Burn'),
        value: formatCurrency(avgCashBurn, fundingCurrency)
      },
      {
        icon: "BanknotesIcon",
        label: t('Capital Required'),
        value: formatCurrency(capitalRequired, fundingCurrency)
      },
      {
        icon: "CalendarIcon",
        label: t('Funding Round Timing'),
        value: `${t(fundingTime.month)}/${fundingTime.year}`
      }
    ];

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t('Next Funding Round')}</dt>
        <dd className="mt-1 text-sm text-gray-700 sm:col-span-4 sm:mt-0">
          <div className="bg-white rounded-lg overflow-hidden border border-gray-200">
            <div className="divide-y divide-gray-200">
              {fundingDetails.map((detail, index) => {
                const IconComponent = HeroIcons[detail.icon];
                return (
                  <div key={index} className="flex items-center p-4 hover:bg-gray-50">
                    <div className="flex-shrink-0">
                      <IconComponent className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-4 flex-1">
                      <div className="text-sm font-medium text-gray-400">{detail.label}</div>
                      <div className="text-sm text-gray-900">{detail.value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {investmentNotes && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex space-x-3">
                <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <p className="text-sm text-gray-600 italic">"{investmentNotes}"</p>
              </div>
            </div>
          )}

          {/* Cap table distribution charts */}
          {captable.currentShareholders && captable.targetedShareholders && (
            <div className="mt-6">
              <h4 className="font-medium mb-4">{t('Shareholder Distribution')}</h4>
              <div className="bg-gray-50 rounded-lg p-4">
                <DistributionCharts
                  currentShareholders={captable.currentShareholders}
                  targetedShareholders={captable.targetedShareholders}
                  totalShares={Math.floor(captable.shareCapital / captable.nominalShareValue)}
                  t={t}
                />
              </div>
            </div>
          )}
        </dd>
      </div>
    );
  };

  const renderValuationDevelopmentSection = (data) => {
    if (!data.fundingRounds?.fundingRounds) return null;

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t('Valuation Projection')}</dt>
        <dd className="mt-1 text-sm text-gray-700 sm:col-span-4 sm:mt-0">
          <KnowYourselfWizardValuationDevelopment isEmbedded={true} />
        </dd>
      </div>
    );
  };


  const renderConsolidatedInfo = (generalInfo, data) => {
    const { 'Company Name': name, Website: website, Country: countryCode, 'Year Founded': yearFounded } = generalInfo;
    const { industry, sector, otherIndustry, otherSector, regions, offices = [] } = data;

    const country = countryData.find(c => c.code === countryCode);
    const countryName = country ? country.name : countryCode;  // Fallback to code if not found
    const totalEmployees = offices.reduce((sum, office) => sum + (office.employees || 0), 0);
    const officeCount = offices.length - 1;

    const regionText = regions.length > 1
      ? t('consolidatedInfoMultipleRegions', { regions: regions.map(r => t(`Regions.${r}`)).join(t('andSeparator')) })
      : t('consolidatedInfoSingleRegion', { region: t(`Regions.${regions[0]}`) });

    let text = '';

    // Start of the text with industry information
    if (industry === 'Other') {
      text += t('consolidatedInfoPartOther', { name, website, yearFounded, otherIndustry });
    } else {
      text += t('consolidatedInfoPart1', { name, website, industry: t(`Industries.${industry}`), yearFounded });
    }

    // Country and regions
    text += ' ' + t('consolidatedInfoLocation', {
      country: t(`Countries.${countryName}`),  // Translate the country name
      regionText,
      marketPlural: regions.length > 1 ? t('markets') : t('market')
    });

    // Sector and team size
    if (industry === 'Other') {
      text += ' ' + t('consolidatedInfoTeamSizeNoSector', { totalEmployees, officeCount });
    } else if (sector === 'Other') {
      text += ' ' + t('consolidatedInfoTeamSizeOtherSector', { totalEmployees, officeCount, otherSector });
    } else {
      text += ' ' + t('consolidatedInfoTeamSizeWithSector', { sector: t(`Sectors.${sector}`), totalEmployees, officeCount });
    }

    return (
      <p className="text-sm mt-0">
        <Trans components={[<strong />, <strong />]}>
          {text}
        </Trans>
      </p>
    );
  };

  const renderMarketSection = (data) => {
    return (
      <div>
        <div className="px-4 py-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">{t('Market')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0"></dd>
        </div>
        <div className="px-4 sm:px-6">
          <div className="sm:col-span-3">
            <MarketMap subregions={data.subregions} />
            {data.marketNotes && (
              <p className="mt-4 text-sm text-gray-500 italic">
                {data.marketNotes}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBusinessModelSection = (data) => {
    const {
      clientele = [],
      businessSectors = [],
      revenueCategories = {},
      modelNotes = '',
      clienteleAgeGroup,
      clienteleGenders,
      clienteleIncome = []
    } = data;

    const formatList = (items) => (
      <div className="flex flex-col space-y-1">
        {items.map((item, index) => (
          <div key={index} className="flex items-start">
            <span className="mr-2">•</span>
            <span>{item}</span>
          </div>
        ))}
      </div>
    );

    const formatIncome = (incomes) => {
      if (incomes.includes('All incomes')) return t('KnowYourselfWizard.All incomes');
      const sortOrder = ['Low-income', 'Middle-income', 'High-income', 'Super-high-income'];
      const sorted = incomes.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
      return sorted.length > 1
        ? `${t(`KnowYourselfWizard.${sorted[0]}`)} ${t('KnowYourselfWizard.to')} ${t(`KnowYourselfWizard.${sorted[sorted.length - 1]}`)}`
        : t(`KnowYourselfWizard.${sorted[0]}`);
    };

    const hasEndCustomers = clientele.some(c =>
      ['Business-to-Customer (B2C)', 'Customer-to-Customer (C2C)', 'Business-to-Business-to-Customer (B2B2C)'].includes(c)
    );

    const businessDetails = [
      {
        icon: UserGroupIcon,
        label: t('Customer Segments'),
        value: formatList(clientele.map(c => t(`KnowYourselfWizard.${c}`)))
      },
      {
        icon: CurrencyDollarIcon,
        label: t('Revenue Streams'),
        value: (
          <div className="flex flex-col space-y-2">
            {Object.entries(revenueCategories)
              .filter(([, models]) => models.length > 0)
              .map(([category, models], idx) => (
                <div key={idx} className="flex items-start">
                  <span className="mr-2">•</span>
                  <span>
                    <span className="font-medium">{t(`KnowYourselfWizard.${category}`)}</span>
                    <div className="pl-4 flex flex-col space-y-1">
                      {models.map((m, index) => (
                        <div key={index} className="flex items-start">
                          <span className="mr-2">-</span>
                          <span>{t(`KnowYourselfWizard.${m}`)}</span>
                        </div>
                      ))}
                    </div>
                  </span>
                </div>
              ))}
          </div>
        )
      }
    ];

    if (hasEndCustomers && clienteleAgeGroup && clienteleGenders) {
      businessDetails.push({
        icon: ChartBarIcon,
        label: t('Target Demographics'),
        value: `${clienteleAgeGroup[0]}–${clienteleAgeGroup[1]} ${t('years old')}, ${clienteleGenders === 'All'
          ? t('KnowYourselfWizard.with a focus on all genders')
          : t(`KnowYourselfWizard.${clienteleGenders}`)}${clienteleIncome.length > 0
            ? `, ${formatIncome(clienteleIncome)}`
            : ''
          }`
      });
    }

    if (businessSectors.length > 0) {
      businessDetails.push({
        icon: BuildingOffice2Icon,
        label: t('Client Industries'),
        value: (
          <div className="flex flex-col space-y-1">
            {businessSectors.map((sector, index) => (
              <div key={index} className="flex items-start">
                <span className="mr-2">•</span>
                <span>{t(`Industries.${sector}`)}</span>
              </div>
            ))}
          </div>
        )
      });
    }

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t('businessModel')}</dt>
        <dd className="mt-1 text-sm text-gray-700 sm:col-span-4 sm:mt-0">
          <div className="bg-white rounded-lg overflow-hidden border border-gray-200">
            <div className="divide-y divide-gray-200">
              {businessDetails.map((detail, index) => {
                const IconComponent = detail.icon;
                return (
                  <div key={index} className="flex items-center p-4 hover:bg-gray-50">
                    <div className="flex-shrink-0">
                      <IconComponent className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <div className="ml-4 flex-1">
                      <div className="text-sm font-medium mb-2 text-gray-400">{detail.label}</div>
                      <div className="text-sm text-gray-900">{detail.value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {modelNotes && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex space-x-3">
                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <p className="text-sm text-gray-600 italic">"{modelNotes}"</p>
              </div>
            </div>
          )}
        </dd>
      </div>
    );
  };

  const renderSection = (title, content) => {
    if (!content || Object.keys(content).length === 0) return null;

    if (title === 'Industry' || title === 'Market' || title === t('KnowYourselfProgress.businessModel')) return null;

    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t(title)}</dt>
        <dd className="text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
          {title === 'General Information' ? (
            renderConsolidatedInfo(generalInfo, data)
          ) : (
            <>
              {title === t('KnowYourselfProgress.team') && renderTeamMembers()}
              {title === t('KnowYourselfProgress.productsServices') && renderProducts(data.products)}
            </>
          )}
        </dd>
      </div>
    );
  };

  return (
    <div id="funding-overview" className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
        <div className="flex items-center">
          {logoPreview ? (
            <img src={logoPreview} alt="Company Logo" className="h-24 w-auto max-w-full object-contain" />
          ) : (
            <span className="text-gray-400">{t('No Logo Uploaded')}</span>
          )}
        </div>
      </div>

      <div className="border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          {renderSection('General Information', { ...generalInfo, ...data })}
        </dl>
        <div className="w-full">
          {renderMarketSection(data)}
        </div>
        <dl className="divide-y divide-gray-200">
          {renderBusinessModelSection(data)}
          {renderSection(t('KnowYourselfProgress.productsServices'), { products: data.products })}
          {renderSection(t('KnowYourselfProgress.team'), data)}
          {renderCashflowSection(data)}
          {renderNextFundingSection(data)}
          {renderValuationDevelopmentSection(data)}
        </dl>
      </div>
    </div>
  );
};

export default KnowYourselfOverviewDisplay;