import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { PlusIcon, TrashIcon, AcademicCapIcon } from '@heroicons/react/24/outline';
import Progress from '../../../components/wizard_components/KnowYourselfProgress';
import { useUserData } from '../../../hooks/useUserData';
import { useFormSubmit } from '../../../hooks/useFormSubmit';
import Notification from '../../../components/Notification';
import { Menu } from '@headlessui/react';

const CompetitionTable = ({ values, setFieldValue }) => {
    const { t } = useTranslation();

    const ratings = [
        { value: 0, label: t('n/a'), color: 'bg-gray-100 text-gray-800' },
        { value: 1, label: t('Very Poor'), color: 'bg-red-100 text-red-800' },
        { value: 2, label: t('Poor'), color: 'bg-orange-100 text-orange-800' },
        { value: 3, label: t('Average'), color: 'bg-yellow-100 text-yellow-800' },
        { value: 4, label: t('Good'), color: 'bg-lime-100 text-lime-800' },
        { value: 5, label: t('Excellent'), color: 'bg-green-100 text-green-800' }
    ];

    const RatingMenu = ({ rating, onChange, isCompany }) => {
        return (
            <Menu as="div" className="relative inline-block text-left0">
                <Menu.Button className="w-full">
                    <span className={`inline-block w-full px-2 py-1 rounded text-xs font-medium ${ratings.find(r => r.value === rating)?.color}`}>
                        {ratings.find(r => r.value === rating)?.label}
                    </span>
                </Menu.Button>
                <Menu.Items className="absolute z-50 mt-1 w-36 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {ratings.map((r) => (
                            <Menu.Item key={r.value}>
                                {({ active }) => (
                                    <button
                                        className={`${active ? 'bg-gray-50' : ''} w-full text-left px-4 py-2 text-xs`}
                                        onClick={() => onChange(r.value)}
                                    >
                                        <span className={`px-2 py-0.5 rounded ${r.color}`}>
                                            {r.label}
                                        </span>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>
        );
    };

    const addCompetitor = () => {
        const updatedCompetitors = [...values.competitors];
        updatedCompetitors.push({
            name: '',
            ratings: values.aspects.map(() => 3)
        });
        setFieldValue('competitors', updatedCompetitors);
    };

    const removeCompetitor = (index) => {
        const updatedCompetitors = [...values.competitors];
        updatedCompetitors.splice(index, 1);
        setFieldValue('competitors', updatedCompetitors);
    };

    const addAspect = () => {
        const updatedAspects = [...values.aspects];
        updatedAspects.push({ name: '', description: '' });
        setFieldValue('aspects', updatedAspects);

        // Add a default rating for this aspect to each competitor
        const updatedCompetitors = values.competitors.map(competitor => ({
            ...competitor,
            ratings: [...competitor.ratings, 3]
        }));
        setFieldValue('competitors', updatedCompetitors);
    };

    const removeAspect = (index) => {
        const updatedAspects = [...values.aspects];
        updatedAspects.splice(index, 1);
        setFieldValue('aspects', updatedAspects);

        // Remove this aspect's ratings from each competitor
        const updatedCompetitors = values.competitors.map(competitor => ({
            ...competitor,
            ratings: competitor.ratings.filter((_, i) => i !== index)
        }));
        setFieldValue('competitors', updatedCompetitors);
    };

    return (
        <>
            <thead className="border-b border-gray-300">
                <tr>
                    <th className="px-3 py-2 text-left text-xs font-semibold text-gray-900">
                    </th>
                    {values.aspects.map((aspect, aspectIndex) => (
                        <th key={aspectIndex} className="px-3 py-2">
                            <div className="flex flex-col space-y-2">
                                <button
                                    type="button"
                                    onClick={() => removeAspect(aspectIndex)}
                                    className="text-red-600 hover:text-red-800"
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </button>
                                <input
                                    type="text"
                                    value={aspect}
                                    onChange={(e) => {
                                        const updatedAspects = [...values.aspects];
                                        updatedAspects[aspectIndex] = e.target.value;
                                        setFieldValue('aspects', updatedAspects);
                                    }}
                                    placeholder={aspectIndex === 0 ? t('Product Quality') :
                                        aspectIndex === 1 ? t('Innovation') :
                                            aspectIndex === 2 ? t('Market Share') :
                                                t('Pricing')}
                                    className="w-full border rounded font-semibold px-2 py-1 text-xs"
                                />
                                <input
                                    type="text"
                                    placeholder={aspectIndex === 0 ? t('Overall quality of products/services') :
                                        aspectIndex === 1 ? t('Level of innovation and R&D') :
                                            aspectIndex === 2 ? t('Current market presence') :
                                                t('Price competitiveness')}
                                    className="w-full border rounded font-normal px-2 py-1 text-xs"
                                />
                            </div>
                        </th>
                    ))}
                    <th className="px-3 py-2">
                        <button
                            type="button"
                            onClick={addAspect}
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-primary-color hover:bg-secondary-color"
                        >
                            <PlusIcon className="h-4 w-4 mr-1" />
                            {t('Add Aspect')}
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
                {values.competitors.map((competitor, compIndex) => (
                    <tr key={compIndex}>
                        <td className="px-3 py-2">
                            {competitor.isCompany ? (
                                <span className="text-xs font-semibold">{competitor.name}</span>
                            ) : (
                                <input
                                    type="text"
                                    value={competitor.name}
                                    onChange={(e) => {
                                        const updatedCompetitors = [...values.competitors];
                                        updatedCompetitors[compIndex].name = e.target.value;
                                        setFieldValue('competitors', updatedCompetitors);
                                    }}
                                    placeholder={t('Competitor name')}
                                    className="border rounded text-xs font-semibold px-2 py-1 w-full"
                                />
                            )}
                        </td>
                        {competitor.ratings.map((rating, aspectIndex) => (
                            <td key={aspectIndex} className="px-3 py-2">
                                <RatingMenu
                                    rating={rating}
                                    onChange={(value) => {
                                        const updatedCompetitors = [...values.competitors];
                                        updatedCompetitors[compIndex].ratings[aspectIndex] = value;
                                        setFieldValue('competitors', updatedCompetitors);
                                    }}
                                    isCompany={competitor.isCompany}
                                />
                            </td>
                        ))}
                        <td className="px-3 py-2">
                            {compIndex !== 0 && (
                                <button
                                    type="button"
                                    onClick={() => removeCompetitor(compIndex)}
                                    className="text-red-600 hover:text-red-800"
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
            <div className="mt-4">
                <button
                    type="button"
                    onClick={addCompetitor}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-color hover:bg-secondary-color"
                >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    {t('Add Competitor')}
                </button>
            </div>
        </>
    );
};

const KnowYourselfWizardCompetition = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useUserData();
    const { handleSubmit, handleDraftSubmit } = useFormSubmit('/funding/competition');
    const [notification, setNotification] = useState({ show: false, type: '', message: '', errors: [] });
    const [recommendationsOpen, setRecommendationsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState('new');

    const [initialValues, setInitialValues] = useState({
        aspects: ['', '', '', ''],
        competitors: [{
            name: userData?.entity?.name || '',
            ratings: [3, 3, 3, 3],
            isCompany: true
        }],
        competitionNotes: ''
    });

    useEffect(() => {
        if (!userData?.entity?.know_yourself_response) return;

        const { published, draft } = userData.entity.know_yourself_response;

        // 1. Coalesce each chunk to the same shape as initialValues
        const coalesceCompetitionData = (raw, userEntityName) => {
            // Fallback to your default aspects if undefined or empty
            let aspects = raw?.aspects;
            if (!Array.isArray(aspects) || aspects.length === 0) {
                aspects = ['', '', '', ''];
            }

            // Make sure we have at least the “company competitor”
            let comps = Array.isArray(raw?.competitors) ? [...raw.competitors] : [];
            const companyIndex = comps.findIndex(c => c.isCompany);
            if (companyIndex === -1) {
                // Insert your default “company competitor”
                comps.unshift({
                    name: userEntityName || '',
                    ratings: [3, 3, 3, 3],
                    isCompany: true
                });
            } else {
                // Ensure its ratings array at least matches “four aspects”
                comps[companyIndex] = {
                    name: userEntityName || '',
                    ratings: comps[companyIndex].ratings?.length
                        ? comps[companyIndex].ratings
                        : [3, 3, 3, 3],
                    isCompany: true
                };
            }

            // If any other competitor’s ratings are missing or shorter,
            // fill them with [3, 3, 3, 3]
            comps = comps.map(c => {
                if (c.isCompany) return c; // skip your own row
                if (!Array.isArray(c.ratings) || c.ratings.length !== aspects.length) {
                    return {
                        ...c,
                        ratings: aspects.map(() => 3)
                    };
                }
                return c;
            });

            const competitionNotes = raw?.competitionNotes ?? '';

            return {
                aspects,
                competitors: comps,
                competitionNotes
            };
        };

        const defaultValues = {
            aspects: ['', '', '', ''],
            competitors: [
                {
                    name: userData.entity.name || '',
                    ratings: [3, 3, 3, 3],
                    isCompany: true
                }
            ],
            competitionNotes: ''
        };

        // Transform both draft and published to match your local shape
        const coalescedDraft = coalesceCompetitionData(draft?.competition, userData.entity.name);
        const coalescedPublished = coalesceCompetitionData(published?.competition, userData.entity.name);

        // 2. Check if data is the same as default → "new"
        const isDefault = (data) => JSON.stringify(data) === JSON.stringify(defaultValues);

        if (isDefault(coalescedDraft)) {
            setDataStatus('new');
        } else if (JSON.stringify(coalescedDraft) !== JSON.stringify(coalescedPublished)) {
            setDataStatus('draft');
        } else {
            // If it's not default, but draft == published, we say "published"
            setDataStatus('published');
        }

        // 3. Whichever final data you want in the form:
        // if we DO have non-default draft data, use that
        // else if we have non-default published, use that
        if (!isDefault(coalescedDraft)) {
            setInitialValues(coalescedDraft);
        } else if (!isDefault(coalescedPublished)) {
            setInitialValues(coalescedPublished);
        } else {
            setInitialValues(defaultValues);
        }

    }, [userData]);

    const validationSchema = Yup.object({
        aspects: Yup.array().of(
            Yup.string().required(t('KnowYourselfWizard.aspectRequired'))
        ).min(1, t('KnowYourselfWizard.minimumOneAspect')),
        competitors: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('KnowYourselfWizard.competitorNameRequired')),
                ratings: Yup.array().of(
                    Yup.number()
                        .min(0, t('KnowYourselfWizard.ratingMinValue'))
                        .max(5, t('KnowYourselfWizard.ratingMaxValue'))
                        .required(t('KnowYourselfWizard.ratingRequired'))
                )
            })
        ).min(1, t('KnowYourselfWizard.minimumOneCompetitor')),
        competitionNotes: Yup.string()
            .max(2000, t('KnowYourselfWizard.notesMaxLength'))
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await handleSubmit({ responses: { competition: values } });
            setNotification({ show: true, type: 'success', message: t('Data saved and published successfully!') });
            setDataStatus('published');
            await handleDraftSubmit({ responses: { competition: values } });

            setTimeout(() => {
                navigate('/funding');
            }, 1000);
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save data'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    const onDraftSubmit = async (values, { setSubmitting }) => {
        try {
            await handleDraftSubmit({ responses: { competition: values } });
            setNotification({ show: true, type: 'success', message: t('Draft saved successfully') });
            setDataStatus('draft');
        } catch (error) {
            setNotification({
                show: true,
                type: 'error',
                message: t('Failed to save draft'),
                errors: [error.message]
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 lg:py-12 md:py-12">
            <Notification
                show={notification.show}
                setShow={(show) => setNotification({ ...notification, show })}
                type={notification.type}
                message={notification.message}
                errors={notification.errors}
            />
            <Progress />
            <div className="data-status-banner mt-4 mb-4">
                {dataStatus === 'published' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is the last saved and published version.')}
                    </span>
                )}
                {dataStatus === 'draft' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-yellow-500">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('This is a draft version. Some of the information on this page might not have been published yet.')}
                    </span>
                )}
                {dataStatus === 'new' && (
                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-gray-400">
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {t('There is no prior version of this page. Please manually save or save as draft below.')}
                    </span>
                )}
            </div>
            <h2 className="text-primary-color">{t('Competition')}</h2>
            <p className="mb-8">{t('KnowYourselfWizard.competitionIntro')}</p>
            {/* Recommendations Section */}
            <div className="bg-gray-50 rounded-lg border border-gray-200 mb-6">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setRecommendationsOpen(!recommendationsOpen);
                    }}
                    className="w-full flex items-center justify-between p-4 text-left font-semibold text-gray-900 hover:bg-gray-100 transition-colors"
                >
                    <div className="flex items-center">
                        <AcademicCapIcon className="h-6 w-6 mr-3 text-primary-color" />
                        <span className="text-primary-color">{t('Recommendations')}</span>
                    </div>
                    <svg
                        className={`h-5 w-5 transform transition-transform ${recommendationsOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>

                {recommendationsOpen && (
                    <div className="p-4 bg-white rounded-b-lg border-t">
                        <div className="space-y-2 text-gray-700">
                            <h4 className="text-sm font-semibold text-gray-900">
                                {t('competition.recommendations.competitorResearch.mainTitle')}
                            </h4>
                            <ul className="text-sm list-disc list-inside">
                                {t('competition.recommendations.competitorResearch.tips', { returnObjects: true }).map((tip, index) => (
                                    <li key={index}>{tip}</li>
                                ))}
                            </ul>

                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                {t('competition.recommendations.competitiveAnalysis.mainTitle')}
                            </h4>
                            <p className="text-sm">
                                {t('competition.recommendations.competitiveAnalysis.strategicApproach')}
                            </p>

                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                {t('competition.recommendations.competitiveAnalysis.aspectSelection.mainTitle')}
                            </h4>
                            <p className="text-sm">
                                {t('competition.recommendations.competitiveAnalysis.aspectSelection.description')}
                            </p>

                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                {t('competition.recommendations.possibleAspects')}
                            </h4>
                            <ul className="text-sm list-disc list-inside">
                                {t('competition.recommendations.competitiveAnalysis.aspectSelection.examples', { returnObjects: true }).map((example, index) => (
                                    <li key={index}>
                                        <span className="font-semibold">{example.name}:</span> {example.description}
                                    </li>
                                ))}
                            </ul>

                            <p className="text-sm italic pt-2">
                                {t('competition.recommendations.competitiveAnalysis.userCustomization')}
                            </p>

                            <h4 className="text-sm font-semibold text-gray-900 pt-2">
                                {t('competition.recommendations.notesSection.mainTitle')}
                            </h4>
                            <p className="text-sm">
                                {t('competition.recommendations.notesSection.purpose')}
                            </p>
                            <ul className="text-sm list-disc list-inside">
                                {t('competition.recommendations.notesSection.whatToInclude', { returnObjects: true }).map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>

                            <p className="text-sm italic text-gray-600 pt-2">
                                {t('competition.recommendations.selectionTip')}
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue, validateForm }) => (
                    <Form>
                        <div className="bg-white shadow rounded-lg p-6">
                            <p className="text-gray-600 mb-6">{t('Compare your company with competitors across key aspects.')}</p>
                            <div className="overflow-visible">
                                <table className="min-w-full">
                                    <CompetitionTable
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                </table>
                            </div>
                        </div>

                        <div className="mt-8">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                {t('Additional Notes')}
                            </label>
                            <textarea
                                value={values.competitionNotes}
                                onChange={(e) => setFieldValue('competitionNotes', e.target.value)}
                                rows={4}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm"
                            />
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => navigate('/funding')}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                type="button"
                                onClick={() => onDraftSubmit(values, { setSubmitting: () => { } })}
                                disabled={isSubmitting}
                                className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            >
                                {t('Save as Draft')}
                            </button>
                            <button
                                type="submit"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    const errors = await validateForm(values);
                                    if (Object.keys(errors).length === 0) {
                                        // No validation errors, proceed with submission
                                        onSubmit(values, { setSubmitting: () => { } });
                                    } else {
                                        // Show validation errors in notification
                                        const errorMessages = Object.entries(errors).map(([field, message]) => {
                                            // Map field names to their translated versions
                                            const fieldTranslations = {
                                                aspects: t('KnowYourselfWizard.aspects'),
                                                'competitors[0].name': t('KnowYourselfWizard.yourCompany'),
                                                competitors: t('KnowYourselfWizard.competitors'),
                                                competitionNotes: t('KnowYourselfWizard.notes')
                                            };
                                            const fieldName = fieldTranslations[field] || field;
                                            return `${fieldName}: ${message}`;
                                        });
                                        setNotification({
                                            show: true,
                                            type: 'error',
                                            message: t('Failed to save data due to validation errors:'),
                                            errors: errorMessages
                                        });
                                    }
                                }}
                                disabled={isSubmitting}
                                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
                            >
                                {isSubmitting ? t('saving') : t('save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik >
        </div>
        </>
    );
};

export default KnowYourselfWizardCompetition;