// src/pages/wizards/DataPrivacyWizard/utils/transfersHelpers.js

/**
 * Extract unique recipients (internal or external) from processing activities
 * with their connected activities
 * 
 * @param {Array} processingActivities - The array of processing activities
 * @param {string} type - Either 'internal' or 'external'
 * @returns {Array} Array of unique recipients with connected activities
 */
export const extractUniqueRecipients = (processingActivities, type) => {
  const fieldName = type === 'internal' ? 'internalRecipients' : 'externalRecipients';

  // Step 1: Collect all recipients with their associated processing activities
  const recipientsMap = new Map();

  if (!processingActivities || !Array.isArray(processingActivities)) {
    return [];
  }

  processingActivities.forEach(activity => {
    if (activity[fieldName] && Array.isArray(activity[fieldName])) {
      activity[fieldName].forEach(recipient => {
        if (recipient && recipient.id) {
          if (!recipientsMap.has(recipient.id)) {
            // Create a new entry with the connected activity
            recipientsMap.set(recipient.id, {
              ...recipient,
              connectedActivities: [{
                id: activity.id || `activity-${Math.random().toString(36).substr(2, 9)}`,
                name: activity.name
              }]
            });
          } else {
            // Add to existing entry's connected activities
            const existingRecipient = recipientsMap.get(recipient.id);
            existingRecipient.connectedActivities.push({
              id: activity.id || `activity-${Math.random().toString(36).substr(2, 9)}`,
              name: activity.name
            });
            recipientsMap.set(recipient.id, existingRecipient);
          }
        }
      });
    }
  });

  // Convert map to array
  return Array.from(recipientsMap.values());
};

/**
 * Check if a country is a third country (non-GDPR and non-GDPRlike compliant)
 * 
 * @param {string} countryCode - The country code to check
 * @param {Array} countries - List of countries with GDPR and GDPRlike properties
 * @returns {boolean} True if the country is a third country
 */
export const isThirdCountry = (countryCode, countries) => {
  if (!countryCode || !countries || !Array.isArray(countries) || countries.length === 0) return false;

  // Find the country by code
  const country = countries.find(c => c.code === countryCode);
  if (!country) return false;

  // Use the same approach as in the introduction page
  const isGDPR = String(country.gdpr).toLowerCase() === 'true';
  const isGDPRlike = String(country.gdprlike).toLowerCase() === 'true';

  // A third country is one that has NEITHER GDPR NOR GDPRlike set to true
  return !(isGDPR || isGDPRlike);
};

/**
 * Get country compliance status
 * 
 * @param {string} countryCode - The country code to check
 * @param {Array} countries - List of countries with GDPR and GDPRlike properties
 * @returns {string} 'gdpr', 'gdpr-like', or 'third-country'
 */
export const getCountryComplianceStatus = (countryCode, countries) => {
  if (!countryCode || !countries || !Array.isArray(countries) || countries.length === 0) return 'unknown';

  // Find the country by code
  const country = countries.find(c => c.code === countryCode);
  if (!country) return 'unknown';

  // Use the same approach as in the introduction page
  if (String(country.gdpr).toLowerCase() === 'true') {
    return 'gdpr';
  }

  if (String(country.gdprlike).toLowerCase() === 'true') {
    return 'gdpr-like';
  }

  return 'third-country';
};

/**
 * Get category icon URL for display
 * 
 * @param {Object} category - Category object with icons field
 * @returns {string|null} URL to the category icon or null
 */
export const getCategoryIcon = (category) => {
  if (!category || !category.icons) return null;
  const iconPath = `/is_icons/${category.icons}`;
  return iconPath;
};

/**
 * Calculate compliance status for a transfer
 * 
 * @param {Object} transfer - The transfer object to check
 * @param {string} type - Either 'internal' or 'external'
 * @param {Array} countries - List of countries with GDPR and GDPRlike properties
 * @returns {string} Compliance status: 'compliant', 'non-compliant', or 'pending'
 */
export const getComplianceStatus = (transfer, type, countries) => {
  // For external transfers, check DPA status
  if (type === 'external') {
    // Mark 'missing' and 'pending' DPA status as non-compliant
    if (transfer.dpaStatus === 'missing' || transfer.dpaStatus === 'pending') {
      return 'non-compliant';
    }
  }

  // Check if third country transfer requires compliance mechanism
  const hasThirdCountry =
    (transfer.multipleCountries && transfer.thirdCountries) ||
    isThirdCountry(transfer.country, countries);

  if (hasThirdCountry && (!transfer.complianceMechanism || transfer.complianceMechanism === 'none')) {
    return 'non-compliant';
  }

  // If third country but has compliance mechanism
  if (hasThirdCountry && transfer.complianceMechanism && transfer.complianceMechanism !== 'none') {
    return 'compliant';
  }

  // If not third country transfer
  if (!hasThirdCountry) {
    return 'compliant';
  }

  return 'pending';
};

/**
 * Get the DPA status badge color
 * 
 * @param {string} status - The DPA status value
 * @param {Array} statusOptions - Array of status options with colors
 * @returns {string} CSS class for the badge color
 */
export const getDpaStatusBadge = (status, statusOptions) => {
  const option = statusOptions.find(opt => opt.value === status);
  return option ? option.color : 'bg-gray-100 text-gray-800';
};

/**
 * Get country name in the correct language
 * 
 * @param {string} countryCode - The country code
 * @param {Array} countries - List of countries
 * @returns {string} Localized country name
 */
export const getCountryName = (countryCode, countries) => {
  if (!countryCode || !countries || !countries.length) return '';

  const country = countries.find(c => c.code === countryCode);
  if (!country) return countryCode || '';

  return country.name || countryCode || '';
};