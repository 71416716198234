import React, { useState, useEffect, useContext } from 'react';
import axios from '../axios';
import { TaskContext } from '../context/TaskContext';
import { useTranslation } from 'react-i18next';
import { ClipboardIcon } from '@heroicons/react/24/outline';

const CreateSystemTaskButton = ({ taskName, section, additionalData = {} }) => {
  const [isCreated, setIsCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { incrementNewTasks } = useContext(TaskContext);
  const { t } = useTranslation();

  useEffect(() => {
    checkExistingTask();
  }, [taskName]);

  const checkExistingTask = async () => {
    try {
      const response = await axios.get('/api/v1/tasks', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      
      const taskExists = response.data.some(task => 
        task.name === taskName && 
        task.other_data?.origin === 'system' &&
        task.other_data?.section === section
      );
      
      setIsCreated(taskExists);
      setIsLoading(false);
    } catch (error) {
      console.error('Error checking existing task:', error);
      setIsLoading(false);
    }
  };

  const createTask = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      
      await axios.post('/api/v1/tasks', 
        {
          task: {
            name: taskName,
            status: 'backlog',
            priority: 'normal',
            area: 'organization',
            created_by_id: currentUser.id,
            other_data: {
              origin: 'system',
              section: section,
              created_at: new Date().toISOString(),
              wizardInstructions: true,
              ...additionalData
            }
          }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
      );
  
      setIsCreated(true);
      incrementNewTasks();
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {/* Mobile version - Icon only */}
      <button
        onClick={createTask}
        disabled={isCreated}
        className={`lg:hidden ${
          isCreated 
            ? 'text-gray-400 cursor-not-allowed' 
            : 'text-primary-color hover:text-secondary-color'
        }`}
        title={isCreated ? t('TasksButton.taskCreated') : t('TasksButton.createTask')}
      >
        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
      </button>

      {/* Desktop version - Full button */}
      <button
        onClick={createTask}
        disabled={isCreated}
        className={`hidden lg:inline-flex items-center px-3 py-1 rounded-full border ${
          isCreated 
            ? 'border-gray-400 text-gray-400 cursor-not-allowed' 
            : 'border-secondary-color text-secondary-color hover:text-white hover:bg-secondary-color'
        } text-sm font-medium`}
      >
        {isCreated ? t('TasksButton.taskCreated') : t('TasksButton.createTask')}
      </button>
    </>
  );
};

export default CreateSystemTaskButton;