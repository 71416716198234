import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SearchSection from './SearchSection';
import AssetCategoriesSection from './AssetCategoriesSection';
import AssetClassesSection from './AssetClassesSection';
import AssetTypesSection from './AssetTypesSection';
import AssetDetailsSection from './AssetDetailsSection';
import { XMarkIcon } from '@heroicons/react/24/outline';

/**
 * Component for creating or editing an asset
 * 
 * @param {Object} props
 * @param {Object} props.currentAsset - Current asset for editing mode
 * @param {Function} props.onSave - Callback when asset is saved
 * @param {Function} props.onCancel - Callback when creation is canceled
 * @param {Object} props.filter - Optional filter configuration
 * @param {Object} props.hierarchyData - Asset hierarchy data
 * @param {string} props.userLang - User's language preference (default: 'en')
 */
const AssetCreationView = ({
    currentAsset = null,
    onSave,
    onCancel,
    filter = null,
    hierarchyData,
    userLang = 'en'
}) => {
    const { t } = useTranslation();

    // Destructure hierarchy data
    const {
        categories,
        allAssetClasses,
        allAssetTypes,
        loading,
        error,
        setError,
        getFilteredClassesForCategory,
        getFilteredTypesForClass,
        fetchAssetTypeDetails
    } = hierarchyData || {};

    // State for asset selection
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [classes, setClasses] = useState([]);
    const [types, setTypes] = useState([]);

    // State for form data
    const [assetName, setAssetName] = useState('');
    const [assetDescription, setAssetDescription] = useState('');
    const [assetLocation, setAssetLocation] = useState('');
    const [assetCriticality, setAssetCriticality] = useState(2);
    const [assetOwner, setAssetOwner] = useState('');
    const [assetCustodian, setAssetCustodian] = useState('');

    // State for search functionality
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    // State for UI
    const [expandedSections, setExpandedSections] = useState(() => {
        // If we have any kind of filter (categoryId, classId, or classIds), 
        // don't expand categories initially
        const hasFilter = filter && (filter.categoryId || filter.classId || (filter.classIds && filter.classIds.length));

        console.log("Initial expandedSections calculation:", {
            hasFilter,
            filter,
            shouldExpandCategories: !hasFilter
        });

        return {
            categories: !hasFilter, // Only expand if no filter
            classes: false,
            types: false,
            details: false
        };
    });

    const handleClassSelect = (assetClass) => {
        console.log("Selected class:", assetClass);
        setSelectedClass(assetClass);

        // If "Other" is selected, automatically set type to "Other" too
        if (assetClass.id === 'other') {
            const otherName = userLang === 'de' ? 'Andere' : 'Other';

            const otherType = {
                id: 'other',
                asset_type: otherName,
                werttyp: otherName,
                asset_class: getClassValue(assetClass),
                wertklasse: getClassValue(assetClass),
                asset_category: getCategoryValue(selectedCategory)
            };

            setSelectedType(otherType);

            // Skip to details section
            setExpandedSections({
                categories: false,
                classes: false,
                types: false,
                details: true
            });
            return;
        }

        setSelectedType(null);

        // Get class ID and name for filtering types
        const classId = assetClass.id;
        const classEnglishValue = assetClass.asset_class;

        if (classId || classEnglishValue) {
            console.log("Filtering types by class:", classEnglishValue || classId);

            const filteredTypes = getFilteredTypesForClass(classId, classEnglishValue);
            console.log("Filtered types:", filteredTypes);

            if (filteredTypes.length > 0) {
                setTypes(filteredTypes);

                // Automatically collapse categories AND classes, then expand types
                setExpandedSections(prev => ({
                    ...prev,
                    categories: false,
                    classes: false, // Close the classes section
                    types: true
                }));

                // If there's only one type due to filtering, select it automatically
                if (filteredTypes.length === 1) {
                    console.log("Auto-selecting the only available type:", filteredTypes[0]);
                    handleTypeSelect(filteredTypes[0]);
                }
            } else {
                setError(userLang === 'de'
                    ? 'Keine Asset-Typen für diese Klasse gefunden'
                    : 'No asset types found for this class'
                );
                setTypes([]);
            }
        }
    };

    // Filter initialization logic for multiple classes potentially in different categories
    useEffect(() => {
        // First, ensure we have all the data loaded
        if (!filter || categories.length === 0 || allAssetClasses.length === 0) return;

        console.log("Initializing with filter:", filter);

        // Handle multiple classes filter (classIds array)
        if (filter.classIds && filter.classIds.length > 0 && !filter.categoryId) {
            console.log("Multiple classes filter - finding relevant categories");

            // Get all the classes that match any of the classIds
            const targetClasses = filter.classIds
                .map(classId => allAssetClasses.find(cls => cls.id === classId))
                .filter(Boolean); // Filter out any undefined values

            console.log("Found target classes:", targetClasses);

            if (targetClasses.length > 0) {
                // Extract category information from classes
                // Try both asset_category_id and looking up category based on asset_category name
                const categoryInfo = targetClasses.map(cls => {
                    // First try direct asset_category_id
                    if (cls.asset_category_id) {
                        return { id: cls.asset_category_id, name: cls.asset_category };
                    }

                    // If no direct ID, try to find the category by name
                    if (cls.asset_category) {
                        const matchingCategory = categories.find(cat =>
                            cat.asset_categories === cls.asset_category
                        );
                        if (matchingCategory) {
                            return { id: matchingCategory.id, name: matchingCategory.asset_categories };
                        }
                    }

                    return null;
                }).filter(Boolean);

                // Get unique category IDs
                const uniqueCategoryInfo = Array.from(
                    new Set(categoryInfo.map(info => info.id))
                ).map(id =>
                    categoryInfo.find(info => info.id === id)
                );

                console.log("Target classes span these categories:", uniqueCategoryInfo);

                // If there's only one category, pre-select it
                if (uniqueCategoryInfo.length === 1) {
                    const categoryId = uniqueCategoryInfo[0].id;
                    const commonCategory = categories.find(cat => cat.id === categoryId);

                    if (commonCategory) {
                        console.log("Found common parent category:", commonCategory);

                        // Set the category
                        setSelectedCategory(commonCategory);

                        // Get classes for this category that are in the filter.classIds array
                        const filteredClasses = allAssetClasses.filter(cls =>
                            (cls.asset_category_id === categoryId || cls.asset_category === commonCategory.asset_categories) &&
                            filter.classIds.includes(cls.id)
                        );

                        console.log("Filtered classes for this category:", filteredClasses);
                        setClasses(filteredClasses);

                        // Open classes section
                        setExpandedSections(prev => ({
                            ...prev,
                            categories: false,
                            classes: true
                        }));
                    }
                } else if (uniqueCategoryInfo.length > 1) {
                    // Multiple categories - show categories section
                    console.log("Classes span multiple categories - showing relevant categories");

                    // Don't pre-select any category, but expand categories section
                    setExpandedSections(prev => ({
                        ...prev,
                        categories: true
                    }));
                } else {
                    // No categories found - something is wrong with the data
                    console.error("Could not determine categories for the specified classes");
                }
            }
        }
        // Handle single class filter
        else if (filter.classId && !filter.categoryId) {
            console.log("Single class filter - looking up the parent category");

            // Find the relevant class
            const targetClass = allAssetClasses.find(cls => cls.id === filter.classId);

            if (targetClass) {
                console.log("Found target class:", targetClass);

                // Find its category
                const parentCategory = categories.find(cat =>
                    cat.id === targetClass.asset_category_id ||
                    cat.asset_categories === targetClass.asset_category
                );

                if (parentCategory) {
                    console.log("Found parent category:", parentCategory);

                    // Set the category
                    setSelectedCategory(parentCategory);

                    // Force categories section to be closed
                    setExpandedSections(prev => ({
                        ...prev,
                        categories: false
                    }));

                    // Set the class
                    setSelectedClass(targetClass);

                    // Fetch types for this class
                    const classId = targetClass.id;
                    const className = targetClass.asset_class;
                    if (classId || className) {
                        console.log("Auto-fetching types for class:", className || classId);
                        const filteredTypes = getFilteredTypesForClass(classId, className);
                        console.log("Auto-fetched types:", filteredTypes);
                        if (filteredTypes.length > 0) {
                            setTypes(filteredTypes);
                            // Expand the types section
                            setExpandedSections(prev => ({
                                ...prev,
                                categories: false,
                                classes: false,
                                types: true
                            }));
                        }
                    }
                }
            }
        }
        // Handle category filter
        else if (filter.categoryId) {
            const targetCategory = categories.find(cat => cat.id === filter.categoryId);
            if (targetCategory) {
                setSelectedCategory(targetCategory);
                setExpandedSections(prev => ({
                    ...prev,
                    categories: false
                }));

                // Get classes for this category
                if (filter.classIds && filter.classIds.length) {
                    // Get all classes for this category that are in filter.classIds
                    const filteredClasses = allAssetClasses.filter(cls =>
                        cls.asset_category_id === targetCategory.id &&
                        filter.classIds.includes(cls.id)
                    );
                    setClasses(filteredClasses);
                    setExpandedSections(prev => ({
                        ...prev,
                        classes: true
                    }));
                }
                else if (filter.classId) {
                    // Find the specific class
                    const targetClass = allAssetClasses.find(cls =>
                        cls.id === filter.classId &&
                        cls.asset_category_id === targetCategory.id
                    );
                    if (targetClass) {
                        setSelectedClass(targetClass);
                        handleClassSelect(targetClass);
                    }
                }
                else {
                    // Get all classes for this category
                    const categoryClasses = getFilteredClassesForCategory(
                        targetCategory.id,
                        targetCategory.asset_categories
                    );
                    setClasses(categoryClasses);
                    setExpandedSections(prev => ({
                        ...prev,
                        classes: true
                    }));
                }
            }
        }
    }, [filter, categories, allAssetClasses, getFilteredClassesForCategory, getFilteredTypesForClass]);

    // Initialize form data with current asset if provided
    useEffect(() => {
        if (currentAsset) {
            setAssetName(currentAsset.name || '');
            setAssetDescription(currentAsset.description || '');
            setAssetLocation(currentAsset.location || '');
            setAssetCriticality(currentAsset.criticality || 2);
            setAssetOwner(currentAsset.owner_id || '');
            setAssetCustodian(currentAsset.custodian_id || '');

            // Fetch asset type details to get the category and class
            if (currentAsset.asset_type_id && fetchAssetTypeDetails) {
                loadAssetTypeDetails(currentAsset.asset_type_id);
            }
        }
    }, [currentAsset]);

    // Fetch asset type details to populate category, class, and type
    const loadAssetTypeDetails = async (typeId) => {
        try {
            const typeDetails = await fetchAssetTypeDetails(typeId);

            // Find matching category and class
            if (typeDetails) {
                // Set selected type
                setSelectedType(typeDetails);

                // Find and set class
                const matchingClass = allAssetClasses.find(cls =>
                    cls.asset_class === typeDetails.asset_class
                );

                if (matchingClass) {
                    setSelectedClass(matchingClass);

                    // Find and set category
                    const matchingCategory = categories.find(cat =>
                        cat.asset_categories === matchingClass.asset_category
                    );

                    if (matchingCategory) {
                        setSelectedCategory(matchingCategory);
                    }
                }
            }
        } catch (err) {
            console.error('Error fetching asset type details:', err);
            setError('Failed to load asset type details');
        }
    };

    // Helper functions for getting localized field values
    const getCategoryValue = (category) => {
        if (!category) return '';
        return userLang === 'de' && category.wertkategorie
            ? category.wertkategorie
            : category.asset_categories;
    };

    const getClassValue = (assetClass) => {
        if (!assetClass) return '';
        return userLang === 'de' && assetClass.wertklasse
            ? assetClass.wertklasse
            : assetClass.asset_class;
    };

    const getTypeValue = (assetType) => {
        if (!assetType) return '';
        return userLang === 'de' && assetType.werttyp
            ? assetType.werttyp
            : assetType.asset_type;
    };

    // Selection handler functions
    const handleCategorySelect = (category) => {
        console.log("Selected category:", category);
        setSelectedCategory(category);

        // If "Other" is selected, automatically set class and type to "Other" too
        if (category.id === 'other') {
            const otherName = userLang === 'de' ? 'Andere' : 'Other';
            const otherDescName = userLang === 'de'
                ? 'Benutzerdefinierte Asset-Klasse'
                : 'Custom asset class';

            const otherClass = {
                id: 'other',
                asset_class: otherName,
                wertklasse: otherName,
                description: otherDescName,
                beschreibung: otherDescName,
                asset_category: otherName
            };

            const otherType = {
                id: 'other',
                asset_type: otherName,
                werttyp: otherName,
                asset_class: otherName,
                wertklasse: otherName,
                asset_category: otherName
            };

            setSelectedClass(otherClass);
            setSelectedType(otherType);

            // Skip to details section
            setExpandedSections({
                categories: false,
                classes: false,
                types: false,
                details: true
            });
            return;
        }

        setSelectedClass(null);
        setSelectedType(null);

        // Get the English category name for filtering relationships
        const categoryEnglishValue = category.asset_categories;
        const categoryId = category.id;

        if (categoryEnglishValue || categoryId) {
            console.log("Filtering classes by category:", categoryEnglishValue || categoryId);

            // Get filtered classes for this category
            let filteredClasses = getFilteredClassesForCategory(categoryId, categoryEnglishValue);

            // If we have a classIds filter, only show classes that match those IDs
            if (filter && filter.classIds && filter.classIds.length > 0) {
                filteredClasses = filteredClasses.filter(cls =>
                    filter.classIds.includes(cls.id)
                );
                console.log("Further filtered classes by classIds:", filteredClasses);
            }

            if (filteredClasses.length > 0) {
                setClasses(filteredClasses);
                // Automatically collapse categories and expand classes
                setExpandedSections(prev => ({
                    ...prev,
                    categories: false,
                    classes: true
                }));

                // If there's only one class after filtering, select it automatically
                if (filteredClasses.length === 1 && filter) {
                    console.log("Auto-selecting the only available class:", filteredClasses[0]);
                    handleClassSelect(filteredClasses[0]);
                }
            } else {
                setError(userLang === 'de'
                    ? 'Keine Asset-Klassen für diese Kategorie gefunden'
                    : 'No asset classes found for this category'
                );
                setClasses([]);
            }
        }
    };

    const filteredCategories = filter?.classIds && filter.classIds.length > 0
        ? categories.filter(category => {
            // For each category, check if any of its classes are in filter.classIds
            const categoryHasFilteredClasses = allAssetClasses.some(cls =>
                (cls.asset_category_id === category.id || cls.asset_category === category.asset_categories) &&
                filter.classIds.includes(cls.id)
            );
            return categoryHasFilteredClasses;
        })
        : categories;

    const handleTypeSelect = (type) => {
        console.log("Selected type:", type);
        setSelectedType(type);

        // Automatically collapse both classes and types sections and expand details
        setExpandedSections(prev => ({
            ...prev,
            classes: false, // Make sure classes section is collapsed
            types: false,   // Collapse types section
            details: true   // Expand details section
        }));
    };

    const handleSearchResultSelect = (result) => {
        // Create an enriched version of the result with complete data
        let enrichedResult = { ...result };

        // Find matching category if missing
        if (!result.asset_category && !result.wertkategorie) {
            const matchingCategory = categories.find(cat =>
                result.asset_category_id && cat.id === result.asset_category_id
            );
            if (matchingCategory) {
                enrichedResult.asset_category = matchingCategory.asset_categories;
                enrichedResult.wertkategorie = matchingCategory.wertkategorie;
            }
        }

        // Find matching class if missing
        if (!result.asset_class && !result.wertklasse) {
            const matchingClass = allAssetClasses.find(cls =>
                result.asset_class_id && cls.id === result.asset_class_id
            );
            if (matchingClass) {
                enrichedResult.asset_class = matchingClass.asset_class;
                enrichedResult.wertklasse = matchingClass.wertklasse;
            }
        }

        // Continue with the enriched result
        // Find category matching the result's category
        const matchingCategory = categories.find(cat =>
            cat.asset_categories === enrichedResult.asset_category
        );

        if (matchingCategory) {
            setSelectedCategory(matchingCategory);

            // Find matching class
            const matchingClass = allAssetClasses.find(cls =>
                cls.asset_class === result.asset_class &&
                cls.asset_category === result.asset_category
            );

            if (matchingClass) {
                setSelectedClass(matchingClass);

                // Set the selected type to the search result
                setSelectedType(result);

                // Move to the details section
                setExpandedSections({
                    categories: false,
                    classes: false,
                    types: false,
                    details: true
                });
            }
        }

        // Clear search after selection
        setSearchQuery('');
        setSearchResults([]);
    };

    // UI interaction functions
    const toggleSection = (section) => {
        // Don't allow toggling categories section at all if any filter is present
        if (section === 'categories' && filter) {
            console.log("Blocking category toggle due to filter presence");
            return;
        }

        // Don't allow toggling classes section if only one class is allowed by filter
        if (section === 'classes' && (filter?.classId || (filter?.classIds && filter.classIds.length === 1))) {
            console.log("Blocking class toggle due to single-class filter");
            return;
        }

        // Proceed with normal toggling
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const shouldShowSimplifiedCategoryView = filter && (
        (filter.categoryId && selectedCategory) ||
        (filter.classId && selectedCategory) ||
        (filter.classIds && filter.classIds.length && selectedCategory)
    );

    const handleSave = async () => {
        if (!selectedType || !assetName.trim()) {
            setError(userLang === 'de'
                ? 'Asset-Name und -Typ sind erforderlich'
                : 'Asset name and type are required');
            return;
        }

        try {
            const assetData = {
                name: assetName,
                description: assetDescription,
                asset_type_id: selectedType.id === 'other' ? null : selectedType.id,
                location: assetLocation,
                criticality: parseInt(assetCriticality),
                owner_id: null,
                custodian_id: null,
                custom_type: selectedType.id === 'other' ? assetName : null,
                custom_owner: assetOwner ?
                    (assetOwner.id ? null : assetOwner.name) : null,
                owner_asset_id: assetOwner && assetOwner.id ? assetOwner.id : null,
                custom_custodian: assetCustodian ?
                    (assetCustodian.id ? null : assetCustodian.name) : null,
                custodian_asset_id: assetCustodian && assetCustodian.id ? assetCustodian.id : null
            };

            let response;
            if (currentAsset) {
                // Update existing asset
                response = await axios.put(`/api/v1/asset_manager/${currentAsset.id}`, { asset: assetData });
            } else {
                // Create new asset
                response = await axios.post('/api/v1/asset_manager', { asset: assetData });
            }

            // Call the save callback with the response data
            // Always return an array for consistency
            onSave([response.data]);
        } catch (err) {
            setError(err.response?.data?.errors?.join(', ') || (userLang === 'de'
                ? 'Fehler beim Speichern des Assets'
                : 'Failed to save asset'));
        }
    };

    // Get category icon for display
    const getCategoryIcon = (category) => {
        if (!category || !category.icons) return null;
        const iconPath = `/is_icons/${category.icons}`;
        return iconPath;
    };

    return (
        <div>
            <SearchSection
                handleSearchResultSelect={handleSearchResultSelect}
                currentAsset={currentAsset}
                filter={filter}
                userLang={userLang}
                categories={categories}
                assetClasses={allAssetClasses}
                allAssetTypes={allAssetTypes}
            />

            <div className="px-6 py-4 overflow-y-auto max-h-[65vh]">
                {error && (
                    <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md flex items-start">
                        <XMarkIcon className="h-5 w-5 text-red-400 mr-2 flex-shrink-0" />
                        <span>{error}</span>
                    </div>
                )}

                {/* Only show categories section if there's no category filter or if it hasn't been selected yet */}
                {!shouldShowSimplifiedCategoryView ? (
                    <AssetCategoriesSection
                        categories={filteredCategories} // Use filtered categories instead of all categories
                        selectedCategory={selectedCategory}
                        handleCategorySelect={handleCategorySelect}
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                        loading={loading}
                        getCategoryIcon={getCategoryIcon}
                        userLang={userLang}
                        getCategoryValue={getCategoryValue}
                        disabled={!!filter && filteredCategories.length === 1} // Only disable if there's exactly one relevant category
                    />
                ) : (
                    // Show simplified view for pre-selected category
                    <div className="mb-4 p-3 bg-gray-50 rounded-md">
                        <div className="flex justify-between items-center">
                            <h3 className="text-base font-medium text-gray-700 flex items-center">
                                {t('Asset Category')}
                                <span className="ml-2 text-secondary-color">
                                    {selectedCategory ? getCategoryValue(selectedCategory) : ''}
                                </span>
                            </h3>
                        </div>
                    </div>
                )}

                {selectedCategory && (!filter?.classId && !filter?.classIds || !selectedClass) ? (
                    <AssetClassesSection
                        selectedCategory={selectedCategory}
                        classes={classes}
                        selectedClass={selectedClass}
                        handleClassSelect={handleClassSelect}
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                        loading={loading}
                        userLang={userLang}
                        getClassValue={getClassValue}
                        getCategoryValue={getCategoryValue}
                        // Only disable if there's exactly one class in the filter
                        disabled={filter?.classId || (filter?.classIds && filter.classIds.length === 1)}
                    />
                ) : (
                    // If class is filtered and selected, show a simplified view
                    selectedCategory && selectedClass && (
                        <div className="mb-4 p-3 bg-gray-50 rounded-md">
                            <div className="flex justify-between items-center">
                                <h3 className="text-base font-medium text-gray-700">
                                    {t('Asset Class')}
                                    <span className="ml-2 text-secondary-color">
                                        {getClassValue(selectedClass)}
                                    </span>
                                </h3>
                            </div>
                        </div>
                    )
                )}

                {/* Always show types section when a class is selected */}
                {selectedClass && (
                    <AssetTypesSection
                        selectedClass={selectedClass}
                        selectedCategory={selectedCategory}
                        types={types}
                        selectedType={selectedType}
                        handleTypeSelect={handleTypeSelect}
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                        loading={loading}
                        userLang={userLang}
                        getTypeValue={getTypeValue}
                    />
                )}

                <AssetDetailsSection
                    selectedType={selectedType}
                    expandedSections={expandedSections}
                    toggleSection={toggleSection}
                    assetName={assetName}
                    setAssetName={setAssetName}
                    assetDescription={assetDescription}
                    setAssetDescription={setAssetDescription}
                    assetLocation={assetLocation}
                    setAssetLocation={setAssetLocation}
                    assetCriticality={assetCriticality}
                    setAssetCriticality={setAssetCriticality}
                    assetOwner={assetOwner}
                    setAssetOwner={setAssetOwner}
                    assetCustodian={assetCustodian}
                    setAssetCustodian={setAssetCustodian}
                    userLang={userLang}
                />
            </div>

            <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-3 border-t mt-auto">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                >
                    {userLang === 'de' ? 'Abbrechen' : 'Cancel'}
                </button>

                <button
                    type="button"
                    onClick={handleSave}
                    disabled={!selectedType || !assetName.trim()}
                    className={`px-4 py-2 text-sm font-medium text-white rounded-md shadow-sm ${!selectedType || !assetName.trim()
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-secondary-color hover:opacity-80'
                        }`}
                >
                    {t(currentAsset ? 'Update Asset' : 'Save Asset')}
                </button>
            </div>
        </div>
    );
};

export default AssetCreationView;