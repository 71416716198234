import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const VestingScheduleChart = ({ vestingCliff, vestingSchedule }) => {
    const { t } = useTranslation();

    // Destructure the vesting parameters
    const { months: cliffMonths, percentage: cliffPercentage } = vestingCliff;
    const { totalDuration, remainingPercentage, vestingCadence } = vestingSchedule;

    // Calculate the number of vesting intervals after the cliff
    const totalIntervals = (totalDuration - cliffMonths) / vestingCadence;

    // Build data for each month
    const categories = [];
    const data = [];

    for (let m = 0; m <= totalDuration; m++) {
        categories.push(`${m}`);
        let vested = 0;
        if (m < cliffMonths) {
            vested = 0;
        } else {
            const intervalsPassed = Math.floor((m - cliffMonths) / vestingCadence);
            vested = cliffPercentage + (intervalsPassed / totalIntervals) * remainingPercentage;
            vested = Math.min(vested, 100);
            vested = Math.round(vested * 100) / 100;
        }
        data.push(vested);
    }

    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
                colors: {
                    ranges: [{
                        from: 0,
                        to: 100,
                        color: '#2A9D8F'
                    }]
                }
            }
        },
        dataLabels: {
            enabled: false,
            formatter: (val) => `${val}%`
        },
        annotations: {
            xaxis: [{
                x: "0",
                x2: `${cliffMonths}`,
                fillColor: '#DDC958',
                opacity: 0.2,
                label: {
                    text: 'Cliff Period',
                    style: {
                        fontSize: '12px',
                        color: '#2A9D8F'
                    }
                }
            }]
        },
        xaxis: {
            title: { text: 'Month' },
            categories: categories,
            labels: { rotate: 315, hideOverlappingLabels: false }
        },
        yaxis: {
            title: { text: 'Vested (%)' },
            min: 0,
            max: 100,
            labels: {
                formatter: (val) => `${val}%`
            }
        },
        tooltip: {
            y: {
                formatter: (val) => `${val}%`
            },
            x: {
                formatter: (val) => `${t('month')} ${val}`
            }
        },
        colors: ['#2A9D8F']  // Set the bar color
    };

    const series = [{
        name: 'Vested %',
        data: data
    }];

    return (
        <div>
            <Chart options={options} series={series} type="bar" height={350} />
        </div>
    );
};

export default VestingScheduleChart;